import React from "react"
import styles from "./ToggleButton.styles"
import ButtonBase from "@material-ui/core/ButtonBase"
import { useTheme } from "@basset-la/themed-components"
import { AdjustType } from "./types"

export interface Props {
  value: AdjustType
  onChange: (v: AdjustType) => void
}

const ToggleButton: React.FC<Props> = ({ onChange, value }) => {
  const theme = useTheme()
  return (
    <div className={styles.root}>
      <ButtonBase
        disabled={value === "SUB"}
        onClick={() => onChange("SUB")}
        className={`${styles.button(theme)} ${styles.leftButton}`}
      >
        -
      </ButtonBase>
      <ButtonBase
        disabled={value === "ADD"}
        onClick={() => onChange("ADD")}
        className={`${styles.button(theme)} ${styles.rightButton}`}
      >
        +
      </ButtonBase>
    </div>
  )
}

export default ToggleButton
