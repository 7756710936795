import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  roomContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  roomGuestContainer: css({
    borderBottom: "solid 1px #ccc",
    paddingBottom: "16px",
    marginBottom: "16px",
    "&:last-child": {
      borderBottom: 0,
      paddingBottom: 0,
      marginBottom: 0
    }
  }),
  disclaimerText: css({
    fontSize: "12px",
    fontWeight: "300",
    lineHeight: "1.33",
    margin: 0,
    color: "#4a4a4a",
    marginBottom: "16px"
  }),
  sectionTitle: (theme: Theme) =>
    css({
      fontSize: "14px",
      margin: 0,
      fontWeight: "500",
      lineHeight: "1.71",
      color: theme.colors.brand_primary
    }),
  guestDataContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    " > div": {
      marginBottom: "16px"
    },
    " > div:last-child": {
      marginBottom: 0
    },
    "@media(min-width:1200px)": {
      flexDirection: "row",
      " > div": {
        marginRight: "16px",
        marginBottom: 0
      },
      " > div:last-child": {
        marginRight: 0
      }
    }
  }),
  documentFieldContainer: css({
    flex: "3 1 0%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start"
  }),

  documentTypeContainer: css({
    marginRight: "8px"
  }),
  documentNumberContainer: css({
    " > div:first-child": {
      height: "32px"
    }
  }),
  fieldsContainer: css({
    display: "flex",
    flexDirection: "row",
    marginTop: "8px",
    gap: "8px",
    justifyContent: "space-between"
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  textFieldTitle: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontWeight: 300
    }),
  textField: (t: Theme) =>
    css({
      "&.MuiTextField-root .MuiOutlinedInput-root": {
        fontSize: "14px",
        height: "32px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ccc !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: `${t.brand_colors.click_color} !important`
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${t.brand_colors.click_color} !important`
        }
      },
      "&.MuiTextField-root .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: `${t.colors.error} !important`
      },
      "&.MuiInputBase-root": {
        fontWeight: 300
      }
    }),
  textError: css({
    fontWeight: 400,
    fontSize: "0.75rem",
    margin: "3px 14px 0px 14px"
  }),
  select: css({
    ".MuiSelect-iconOutlined": {
      width: "20px",
      height: "20px",
      top: "calc(50% - 10px)"
    },
    "&.MuiTextField-root .MuiOutlinedInput-root": {
      height: "32px !important"
    },
    "&.MuiTextField-root .MuiOutlinedInput-root.Mui-error": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important"
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "red !important"
      }
    }
  }),
  grow: (percentage: number) =>
    css({
      flexBasis: `${percentage}%`,
      flexGrow: percentage
    }),
  companionContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    borderBottom: "solid 1px #ccc",
    paddingBottom: "16px",
    marginBottom: "16px",
    "&:last-child": {
      borderBottom: 0,
      paddingBottom: 0,
      marginBottom: 0
    }
  }),
  addCompanionButton: css({
    ".MuiButton-root": {
      padding: "0px",
      minWidth: "auto"
    }
  }),
  addCompanionTitle: css({
    fontSize: "16px",
    marginLeft: "8px"
  }),
  closeIcon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "20px",
        color: t.colors.brand_primary
      }
    }),
  sectionTitleContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    "@media(min-width:768px)": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  }),
  deleteAllText: css({
    fontSize: "14px",
    margin: 0,
    fontWeight: "500",
    lineHeight: "1.71",
    marginLeft: "4px",
    marginTop: "4px"
  })
}
