import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    fontFamily: "Roboto"
  }),
  items: (filesCount: number) =>
    css({
      width: "100%",
      display: "flex",
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      cursor: "pointer",
      boxSizing: "border-box",
      flexWrap: "wrap",
      maxHeight: "180px",
      overflowY: "auto",
      "> *": {
        margin: "16px 0",
        minWidth: "138px",
        ":first-child": {
          flexGrow: filesCount < 1 ? 1 : "initial"
        }
      },
      "@media(max-width: 420px)": {
        justifyContent: "center"
      }
    })
}
