import React from "react"
import { SegmentOption } from "../../model"
import { daysDifference } from "../../utils/helpers"
import AirlineLogo from "../AirlineLogo"
import StopsGraphic from "../StopsGraphic"
import styles from "./SegmentOptionBrief.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import Tooltip from "@material-ui/core/Tooltip"
import { getAirlineLogo } from "../../utils/helpers/airlines"

export interface Props {
  segmentOption: SegmentOption
  /**
   * set to `true` to force mobile L&F
   * @param {boolean} mini
   * @default false
   */
  showAirlineCode?: boolean
  mini?: boolean
}

const SegmentOptionBrief: React.FC<Props> = ({ segmentOption, mini, showAirlineCode = false }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const airline = getAirlineLogo(segmentOption.legs, t("SegmentOptionBrief.multipleAirlines"))
  const lastLeg = segmentOption.legs[segmentOption.legs.length - 1]
  const originAirport = segmentOption.legs[0].origin.code
  const departureTime = segmentOption.legs[0].departure_time
  const departureDate = segmentOption.legs[0].departure_date
  const destinationAirport = lastLeg.destination.code
  const arrivalTime = lastLeg.arrival_time
  const arrivalDate = lastLeg.arrival_date
  const daysDiff = daysDifference(departureDate, arrivalDate)
  const stopsCount = segmentOption.legs.length - 1

  return (
    <div className={styles.root(theme, mini)}>
      <div className={styles.airlineContainer(mini)}>
        <div id="segment-option-brief-name-container" className={styles.airline(theme, mini, showAirlineCode)}>
          <AirlineLogo airlineCode={airline.code} aircraftType={airline.aircraftType} />
          {!showAirlineCode && <p>{airline.name}</p>}
          {showAirlineCode && (
            <Tooltip title={airline.name}>
              <p>{airline.code}</p>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.departureArrival}>
        <p className={styles.airportTime(theme)}>
          <span>{originAirport}</span>
          <span className={styles.hour}>{departureTime}</span>
        </p>
        <div className={styles.legGraphic}>
          <StopsGraphic stops={stopsCount} />
        </div>
        <p className={styles.airportTime(theme)}>
          <span>{destinationAirport}</span>
          <span className={styles.hour}>{arrivalTime}</span>
          {daysDiff > 0 && <span className={styles.daysDiff}>{`+${daysDiff}`}</span>}
        </p>
      </div>
    </div>
  )
}

export default SegmentOptionBrief
