import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const FlightClassIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    {...rest}
  >
    <defs>
      <path
        id="prefix__as"
        d="M1.333 6.21V.875c0-.367-.3-.667-.667-.667C.3.21 0 .51 0 .876v5.333c0 1.84 1.493 3.334 3.333 3.334h3.333c.367 0 .667-.3.667-.667 0-.367-.3-.667-.667-.667H3.333c-1.107 0-2-.893-2-2zm12.553 3.493c-.253-.48-.86-.647-1.353-.42l-.727.333-2.273-4.653c-.227-.454-.687-.754-1.193-.754H6v-4H2v5.334c0 1.106.893 2 2 2h4.666l1.707 3.5c.32.653 1.093.926 1.753.626l1.3-.593c.507-.24.727-.867.46-1.373z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1 2)">
      <mask id="prefix__bs" fill="#fff">
        <use xlinkHref="#prefix__as" />
      </mask>
      <g fill={color} mask="url(#prefix__bs)">
        <path d="M0 0H16V16H0z" transform="translate(-1 -2)" />
      </g>
    </g>
  </svg>
)
