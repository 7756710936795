import React from "react"
import { LocationIcon } from "../../icons/LocationIcon"
import styles from "./Address.styles"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  address: string
  color: string
  location?: {
    latitude: number
    longitude: number
  }
  onViewMap?: () => void
}

const Address: React.FunctionComponent<Props> = ({ address, color, location, onViewMap }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.root}>
      <LocationIcon color={color} />
      <p className={styles.address(color)}>
        <span>{address} </span>
        {!onViewMap && location && (
          <a
            className={styles.link}
            target="_blank"
            href={`http://maps.google.com/maps?z=12&t=m&q=loc:${location.latitude}+${location.longitude}`}
            rel="noreferrer"
          >
            {t("Address.viewMap")}
          </a>
        )}
        {onViewMap && location && (
          <button className={styles.buttonLink(theme)} onClick={onViewMap}>
            {t("Address.viewMap")}
          </button>
        )}
      </p>
    </div>
  )
}

export default Address
