import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 1,
      height: "100%",
      boxSizing: "border-box",
      padding: "16px 24px",
      "@media screen and (max-width: 1024px)": {
        width: "100%",
        padding: "8px",
        justifyContent: "space-between"
      }
    }),
  titleContainer: css({
    display: "flex",
    flex: 1
  }),
  title: css({
    fontSize: "18px",
    fontWeight: 300,
    lineHeight: "1.33"
  }),
  checkinCheckout: css({
    display: "flex",
    flexDirection: "row",
    flex: 2
  }),
  hourContainer: (isLast: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: isLast ? "end" : "center",
      flex: 1,
      "> span": {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        textAlign: "center",
        ":last-of-type": {
          fontSize: "12px",
          fontWeight: 300,
          lineHeight: "1.33"
        }
      }
    })
}
