import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto"
    }),

  title: (t: Theme) =>
    css({
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      borderBottom: `dashed 0.5px ${t.colors.background_darker}`,
      margin: "0 0 8px 0",
      padding: "8px 0",
      color: t.colors.text_primary
    }),

  content: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
    margin: "0 0 8px 0"
  })
}
