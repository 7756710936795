import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { AutocompleteGroupedOption } from "./types"
import CircularProgress from "@material-ui/core/CircularProgress"
import styles from "./OptionList.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  groupedOptions: AutocompleteGroupedOption[]
  renderOption: (option: any) => React.ReactNode
  onSelectOption: (option: any) => void

  renderOptionTitle?: (type: string, label: string) => React.ReactNode
  renderOptionHeader?: () => React.ReactNode
  fetchingData?: boolean
}

const OptionList: React.FC<Props> = ({
  groupedOptions,
  renderOption,
  onSelectOption,
  renderOptionTitle,
  renderOptionHeader,
  fetchingData
}) => {
  const theme = useTheme()

  return (
    <List className={styles.root(theme)}>
      {fetchingData && (
        <ListItem>
          <div className={styles.loading(theme)}>
            <CircularProgress size={24} />
          </div>
        </ListItem>
      )}
      {!fetchingData && (
        <>
          {renderOptionHeader && <ListItem key="grOpt.header">{renderOptionHeader()}</ListItem>}
          {groupedOptions.map((grOpt, i) => {
            return (
              <div key={`group_id_${i}`}>
                {grOpt.type && grOpt.label && renderOptionTitle && (
                  <ListItem key={`${grOpt.type}_${i}`}>{renderOptionTitle(grOpt.type, grOpt.label)}</ListItem>
                )}
                {grOpt.options.map((opt, idx) => {
                  return (
                    <ListItem button onClick={() => onSelectOption(opt)} key={`opt_${grOpt.type}_${i}_${idx}`}>
                      {renderOption(opt)}
                    </ListItem>
                  )
                })}
              </div>
            )
          })}
        </>
      )}
    </List>
  )
}

export default OptionList
