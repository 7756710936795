import Checkbox from "@basset-la/component-material-ui-wrapper/dist/Checkbox"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import React from "react"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"
import styles from "./CheckboxFilter.styles"

export interface Props {
  title?: string
  subTitle?: string
  options: Option[]
  onChange: (value: unknown, checked: boolean) => void
  /**
   * max items to show
   * @default 4
   */
  maxItems?: number
  onDone?: () => void
  id?: {
    checkbox?: string
    doneBtn?: string
  }
}

export type Option = {
  title: string
  value: unknown
  icon?: React.ReactNode
  selected?: boolean
}

const ROW_HEIGHT = 32

const CheckboxFilter: React.FunctionComponent<Props> = ({
  title,
  subTitle,
  options,
  onChange,
  maxItems = 4,
  onDone,
  id
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [seeMore, setSeeMore] = React.useState<"more" | "less">("more")

  function onSeeMore() {
    setSeeMore(seeMore === "more" ? "less" : "more")
  }

  const handleChange = (value: unknown) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value, event.target.checked)
  }

  let height: string | number = "auto"

  if (options.length > maxItems) {
    if (seeMore === "more") {
      height = maxItems * ROW_HEIGHT // removing margin
    }
  }

  return (
    <div className={styles.root(theme)}>
      {title && <p className={styles.title}>{title}</p>}
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
      <AnimateHeight height={height} duration={300}>
        {options.map((o, i) => (
          <div className={styles.row} key={i}>
            <Checkbox
              id={id?.checkbox}
              backgroundColor={theme.colors.brand_primary}
              value={o.value}
              checked={!!o.selected}
              onChange={handleChange(o.value)}
            />
            {o.icon && <span>{o.icon}</span>}
            <span className={styles.rowDescription}>{o.title}</span>
          </div>
        ))}
      </AnimateHeight>
      <div className={styles.buttonsContainer}>
        <div>
          {options.length > maxItems && (
            <Button className={styles.link(theme)} onClick={onSeeMore}>
              {t("CheckboxFilter.see", { context: seeMore })}
            </Button>
          )}
        </div>
        {onDone && (
          <Button id={id?.doneBtn} className={styles.link(theme)} onClick={onDone}>
            {t("CheckboxFilter.done")}
          </Button>
        )}
      </div>
    </div>
  )
}

export default CheckboxFilter
