import React from "react"
import styles from "./Text.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  children: React.ReactNode
  variant?: "bold" | "regular" | "light"
  size?: 10 | 12 | 14 | 16 | 18 | 24 | 32
  color?: "primary" | "variant" | "white" | "error"
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span"
  className?: string
  id?: string
}

const Text: React.FC<Props> = ({
  children,
  variant = "regular",
  size = 16,
  color = "primary",
  component = "span",
  className,
  id
}) => {
  const theme = useTheme()
  const Component: React.ElementType = component

  return (
    <Component
      id={id}
      className={`${styles.root} ${styles[size]} ${styles[variant]} ${styles[color](theme)} ${className}`}
    >
      {children}
    </Component>
  )
}

export default Text
