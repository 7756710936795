import React from "react"
import styles from "./SnackbarMessage.styles"
import { Action, actionIcon } from "./types"

import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  /**
   * Param to manange the message display
   * @param open
   * @value boolean
   * @required true
   */
  open: boolean
  /**
   * Message to show
   * @param message
   * @value string
   * @required true
   */
  message: string
  /**
   * Action style for message
   * @param action
   * @value "success" | "warning" | "error" | "info"
   * @required true
   */
  action: Action
  /**
   * Function to execute onClose message
   * @param onClose
   * @value function
   * @required true
   */
  onClose?: () => void
}

const SnackbarMessage: React.FunctionComponent<Props> = ({ open, message, action, onClose }) => {
  const Icon = actionIcon[action]
  const theme = useTheme()

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      className={`${styles.root}`}
    >
      <SnackbarContent
        className={`${styles.content} ${styles[action](theme)}`}
        aria-describedby="message"
        message={
          <span id="message" className={`${styles.message}`}>
            <Icon className={`${styles.icon}`} />
            {message}
          </span>
        }
        action={[
          onClose && (
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )
        ]}
      />
    </Snackbar>
  )
}

export default SnackbarMessage
