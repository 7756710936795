import React from "react"
import styles from "./RateDetail.styles"
import i18next from "i18next"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { I18N_NS } from "../../utils/constants"
import { RoomClusterRate } from "../../model"
import ErrorIcon from "../../icons/ErrorIcon"
import CheckCircleIcon from "../../icons/CheckCircleIcon"
import NoLocalCafeIcon from "../../icons/NoLocalCafeIcon"
import LocalCafeIcon from "../../icons/LocalCafeIcon"

export interface Props {
  rate: RoomClusterRate
}

const RateDetail = ({ rate }: Props) => {
  const { t } = useTranslation(I18N_NS)

  const getCancelPolicyText = (rate: RoomClusterRate) => {
    if (!rate.refundable) {
      return (
        <div className={styles.item}>
          <ErrorIcon />
          <span style={{ color: "#D0021B" }}>{t("RateDetail.cannot_cancel")}</span>
        </div>
      )
    }

    let date = moment(rate.expiration_date).format("DD-MM-YYYY")
    if (rate.reseller_expiration_date) date = moment(rate.reseller_expiration_date).format("DD-MM-YYYY")
    if (rate.custom_expiration_date) date = moment(rate.custom_expiration_date).format("DD-MM-YYYY")

    let time = moment(rate.expiration_date).format("HH:mm A")
    if (rate.reseller_expiration_date) time = moment(rate.reseller_expiration_date).format("HH:mm A")
    if (rate.custom_expiration_date) time = moment(rate.custom_expiration_date).format("HH:mm A")

    return (
      <div className={styles.item}>
        <CheckCircleIcon style={{ width: "16px", height: "14px" }} color="#579B08" />
        <span style={{ color: "#579B08" }}>
          {t("RateDetail.free_cancel_until", {
            date: date,
            time: time
          })}
        </span>
      </div>
    )
  }

  const getMealPlanText = (mealPlan: string, t: i18next.TFunction) => {
    if (mealPlan && mealPlan === "ROOM_ONLY") {
      return (
        <div className={styles.item}>
          <CheckCircleIcon color="#9b9b9b" />
          <NoLocalCafeIcon style={{ width: "14px", height: "14px" }} />
          <span id="rate-detail-mealplan-not-included" style={{ color: "#9b9b9b" }}>
            {t("RateDetail.mealplan_not_included")}
          </span>
        </div>
      )
    }
    return (
      <div className={styles.item}>
        <CheckCircleIcon color="#579b08" />
        <LocalCafeIcon style={{ width: "14px", height: "14px" }} />
        <span id="rate-detail-mealplan-included" style={{ color: "#1134D7" }}>
          {t(`RateDetail.mealPlan.${mealPlan}`)}
        </span>
      </div>
    )
  }

  const getPromotions = (rate: RoomClusterRate) => {
    return (
      <>
        {rate.promotions?.map((promotion, index) => (
          <div key={index} className={styles.item}>
            <span className={styles.promotionName}>{promotion.name}</span>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={styles.container}>
      {getMealPlanText(rate.meal_plan, t)}
      {getCancelPolicyText(rate)}
      {getPromotions(rate)}
    </div>
  )
}

export default RateDetail
