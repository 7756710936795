import * as React from "react"
import {
  Airport,
  FlightType,
  FlightsClusterAvailableFilters,
  FlightClusterFilters,
  FlightsClusterAvailableAirportFilter
} from "../../model/types"
import { useTranslation } from "react-i18next"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { CHECK_ALL, DEFAULT_DEPARTURE_TIME } from "./utils"
import _ from "lodash"
import ActiveFilters from "./ActiveFilters"
import FlightsFiltersMobileBar from "./FlightsFiltersMobileBar"
import i18next from "i18next"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import StopsFilter from "../StopsFilter"
import AirlineFilter from "../AirlineFilter/AirlineFilter"
import AirportFilter, { FilterType } from "../AirportFilter"
import TimePickerFilter from "../TimePickerFilter"
import { Hours } from "../TimePickerFilter/TimePickerFilter"
import moment from "moment"
import Divider from "@material-ui/core/Divider"
import { I18N_NS } from "../../utils/constants"
import SourceTypeFilters from "../SourceTypeFilters"

export interface Props {
  availableFilters: FlightsClusterAvailableFilters
  appliedFilters: FlightClusterFilters
  flightType: FlightType
  onSubmitFilters: (f: FlightClusterFilters) => void
}

const FlightsFilters: React.FC<Props> = props => {
  const { t } = useTranslation(I18N_NS)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState<FlightClusterFilters>(props.appliedFilters)

  React.useEffect(() => {
    setState(props.appliedFilters)
  }, [props.appliedFilters])

  const handleOpenFilters = () => {
    setOpen(true)
  }

  const formatAirports = (airports: FlightsClusterAvailableAirportFilter | null) => {
    return _.mapValues(airports, (val: any) =>
      val.map((_airport: Airport) => ({
        name: `${_airport.code} - ${airport(_airport.name)}`,
        code: _airport.code,
        country_code: ""
      }))
    )
  }

  const stopLabel = (stopsQty: any, t: i18next.TFunction) => {
    return stopsQty === 0 ? t("FlightsFilters.nonstop") : t("FlightsFilters.stops_count", { count: stopsQty })
  }

  const formatStops = (stops: any) => {
    return stops.sort().map((stop: any) => ({
      value: stop,
      label: stopLabel(stop, t)
    }))
  }

  const airport = (locationName: string) => {
    if (typeof locationName !== "string") {
      console.warn(`invalid locationName: ${JSON.stringify(locationName)} is not a string`)
      return ""
    }
    const match = locationName.match(/\([\w\d\s]+-\s*(.+)\)/)
    return (match ? match[1] : locationName).trim()
  }

  const handleChangeAirline = (value: string, checked: boolean) => {
    const airlines = state.airlines
    if (checked) airlines.add(`${value}`)
    else airlines.delete(`${value}`)

    const f = { ...state, airlines }
    setState(f)
  }

  const handleClickAirline = () => {
    handleFilters(state)
  }

  const handleRemoveAirline = (codes: Array<string>) => {
    const airlines = state.airlines
    if (codes[0] === CHECK_ALL) airlines.clear()
    else codes.forEach(code => airlines.delete(code))
    const f = { ...state, airlines }
    setState(f)
  }

  const handleChangeSourceType = (value: string, checked: boolean) => {
    const sourceType = state.sourceType

    if (checked) sourceType.add(`${value}`)
    else sourceType.delete(`${value}`)
    const f = { ...state, sourceType }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveSourceType = (sourcesType: Array<string>) => {
    const sourceType = state.sourceType

    if (sourcesType[0] === CHECK_ALL) sourceType.clear()
    else sourcesType.forEach(source => sourceType.delete(source))
    const f = { ...state, sourceType }
    setState(f)
    handleFilters(f)
  }

  const handleChangeStop = (value: number, checked: boolean) => {
    const stops = state.stops

    if (checked) stops.add(`${value}`)
    else stops.delete(`${value}`)

    const f = { ...state, stops }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveStop = (values: string[]) => {
    const stops = state.stops
    values.forEach(value => stops.delete(value))
    const f = { ...state, stops }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveOutboundAirports = (type: string, code: any) => {
    if (type === "outbound") {
      const airports = state.outboundDepartureAirports
      airports.delete(code)
      const f = { ...state, outboundDepartureAirports: airports }
      setState(f)
      handleFilters(f)
    } else if (type === "inbound") {
      const airports = state.outboundArrivalAirports
      airports.delete(code)
      const f = { ...state, outboundArrivalAirports: airports }
      setState(f)
      handleFilters(f)
    }
  }

  const handleChangeAirport = (value: string, checked: boolean, filterType: FilterType) => {
    if (filterType === "OutboundFrom") {
      const airports = state.outboundDepartureAirports
      if (checked) airports.add(value)
      else airports.delete(value)

      const f = { ...state, outboundDepartureAirports: airports }
      setState(f)
      handleFilters(f)
    } else if (filterType === "OutboundTo") {
      const airports = state.outboundArrivalAirports
      if (checked) airports.add(value)
      else airports.delete(value)

      const f = { ...state, outboundArrivalAirports: airports }
      setState(f)
      handleFilters(f)
    } else if (filterType === "InboundFrom") {
      const airports = state.inboundDepartureAirports
      if (checked) airports.add(value)
      else airports.delete(value)

      const f = { ...state, inboundDepartureAirports: airports }
      setState(f)
      handleFilters(f)
    } else if (filterType === "InboundTo") {
      const airports = state.inboundArrivalAirports
      if (checked) airports.add(value)
      else airports.delete(value)

      const f = { ...state, inboundArrivalAirports: airports }
      setState(f)
      handleFilters(f)
    }
  }

  const handleRemoveInboundAirports = (type: string, code: any) => {
    if (type === "outbound") {
      const airports = state.inboundDepartureAirports
      airports.delete(code)
      const f = { ...state, inboundDepartureAirports: airports }
      setState(f)
      handleFilters(f)
    } else if (type === "inbound") {
      const airports = state.inboundArrivalAirports
      airports.delete(code)
      const f = { ...state, inboundArrivalAirports: airports }
      setState(f)
      handleFilters(f)
    }
  }

  const dateToTime = (d: Date) => {
    const m = moment(d)
    return m.hour() * 60 + m.minute()
  }

  const timeToDate = (t: number) => {
    return moment()
      .startOf("day")
      .add(t, "minutes")
      .toDate()
  }

  const handleChangeTime = (departure: Hours, returning?: Hours) => {
    const outbound = [dateToTime(departure.from), dateToTime(departure.to)]
    const inbound = returning ? [dateToTime(returning.from), dateToTime(returning.to)] : state.inboundDepartureTime

    const f = {
      ...state,
      outboundDepartureTime: outbound,
      inboundDepartureTime: inbound
    }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveOutboundDepartureTime = () => {
    const f = { ...state, outboundDepartureTime: DEFAULT_DEPARTURE_TIME }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveInboundDepartureTime = () => {
    const f = { ...state, inboundDepartureTime: DEFAULT_DEPARTURE_TIME }
    setState(f)
    handleFilters(f)
  }

  const handleRemoveActiveFilter = (key: string, val: any) => {
    switch (key) {
      case "airlines":
        handleRemoveAirline([val])
        break
      case "sourceType":
        handleRemoveSourceType([val])
        break
      case "stops":
        handleRemoveStop([val])
        break
      case "outboundDepartureAirports":
        handleRemoveOutboundAirports("outbound", val)
        break
      case "outboundArrivalAirports":
        handleRemoveOutboundAirports("inbound", val)
        break
      case "inboundDepartureAirports":
        handleRemoveInboundAirports("outbound", val)
        break
      case "inboundArrivalAirports":
        handleRemoveInboundAirports("inbound", val)
        break
      case "outboundDepartureTime":
        handleRemoveOutboundDepartureTime()
        break
      case "inboundDepartureTime":
        handleRemoveInboundDepartureTime()
        break
      default:
        break
    }
  }

  const handleFilters = (f: FlightClusterFilters) => {
    const applyFiltersAutomatically = !isMobile
    if (applyFiltersAutomatically) {
      applyFilters(f)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const applyFilters = (f: FlightClusterFilters) => {
    setOpen(false)
    props.onSubmitFilters(f)
  }

  const buildFilters = () => {
    const availableAirlines = [{ code: CHECK_ALL, name: t("FlightsFilters.all_airlines") }].concat(
      props.availableFilters.airlines
    )
    const sourcesType = props.availableFilters.source_type ?? []
    const availableSourceType = [CHECK_ALL, ...sourcesType]

    const availableStops = [{ value: CHECK_ALL, label: t("FlightsFilters.all_stops") }].concat(
      formatStops(props.availableFilters.stops)
    )
    const availableOutboundAirports = formatAirports(props.availableFilters.outbound_airports)
    const availableInboundAirports = formatAirports(props.availableFilters.inbound_airports)

    return (
      <div>
        <div className="filters-content">
          <ActiveFilters
            availableFilters={{
              airlines: availableAirlines,
              stops: availableStops,
              outbound_airports: availableOutboundAirports,
              inbound_airports: availableInboundAirports,
              sourcesType: availableSourceType
            }}
            activeFilters={state}
            onRemoveFilter={handleRemoveActiveFilter}
          />

          {props.availableFilters.airlines && (
            <>
              <Divider />
              <AirlineFilter
                collapsible={true}
                options={props.availableFilters.airlines.map(a => {
                  return { airline: a, selected: state.airlines.has(`${a.code}`) }
                })}
                onChange={handleChangeAirline}
                onClick={handleClickAirline}
              />
            </>
          )}

          {props.availableFilters.source_type && (
            <>
              <Divider />
              <SourceTypeFilters
                options={props.availableFilters.source_type.map(a => {
                  return { type: a, selected: state.sourceType.has(`${a}`) }
                })}
                onChange={handleChangeSourceType}
              />
            </>
          )}

          {props.availableFilters.stops && (
            <>
              <Divider />
              <StopsFilter
                collapsible={true}
                options={props.availableFilters.stops.map(s => {
                  return { stops: s, selected: state.stops.has(`${s}`) }
                })}
                onChange={handleChangeStop}
              />
            </>
          )}

          {props.availableFilters.outbound_airports && (
            <>
              <Divider />
              <AirportFilter
                collapsible={true}
                outboundAirports={{
                  from: props.availableFilters.outbound_airports.outbound.map(a => {
                    return { airport: a, selected: state.outboundDepartureAirports.has(`${a.code}`) }
                  }),
                  to: props.availableFilters.outbound_airports.inbound.map(a => {
                    return { airport: a, selected: state.outboundArrivalAirports.has(`${a.code}`) }
                  })
                }}
                inboundAirports={
                  props.availableFilters.inbound_airports
                    ? {
                        from: props.availableFilters.inbound_airports.outbound.map(a => {
                          return { airport: a, selected: state.inboundDepartureAirports.has(`${a.code}`) }
                        }),
                        to: props.availableFilters.inbound_airports.inbound.map(a => {
                          return { airport: a, selected: state.inboundArrivalAirports.has(`${a.code}`) }
                        })
                      }
                    : undefined
                }
                onChange={handleChangeAirport}
              />
            </>
          )}

          <Divider />
          <TimePickerFilter
            initialDepartureHours={{
              from: timeToDate(state.outboundDepartureTime[0] || DEFAULT_DEPARTURE_TIME[0]),
              to: timeToDate(state.outboundDepartureTime[1] || DEFAULT_DEPARTURE_TIME[1])
            }}
            initialReturningHours={{
              from: timeToDate(state.inboundDepartureTime[0] || DEFAULT_DEPARTURE_TIME[0]),
              to: timeToDate(state.inboundDepartureTime[1] || DEFAULT_DEPARTURE_TIME[1])
            }}
            collapsible={true}
            flightType={props.flightType}
            onApply={handleChangeTime}
          />
        </div>
      </div>
    )
  }

  if (_.isEmpty(props.availableFilters)) {
    return <></>
  }

  if (isMobile) {
    return (
      <div>
        <FlightsFiltersMobileBar onToggle={handleOpenFilters} />
        <FullScreenDialog
          open={open}
          actionButtonText={t("FlightsFilters.apply_filters")}
          onClose={handleClose}
          onAction={() => applyFilters(state)}
          id={{
            actionButton: "flights-filter-apply-btn"
          }}
        >
          {buildFilters()}
        </FullScreenDialog>
      </div>
    )
  }

  return buildFilters()
}

export default FlightsFilters
