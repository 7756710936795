import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const GenericServiceIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.39023 9.66334H7.43023C7.86356 9.66334 8.27023 9.45667 8.5169 9.10334L11.1502 5.39C11.3169 5.15667 11.3169 4.85 11.1502 4.61667L8.51023 0.89667C8.27023 0.543337 7.86356 0.33667 7.43023 0.33667H1.39023C0.850231 0.33667 0.536898 0.95667 0.850231 1.39667L3.43023 5.00334L0.850231 8.61C0.536898 9.05 0.850231 9.66334 1.39023 9.66334Z"
      fill={color}
    />
  </svg>
)
