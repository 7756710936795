import { css } from "@emotion/css"

export default {
  buttonContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "88px",
    justifyContent: "center"
  }),
  priceContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "149px",
    borderRight: "1px solid #eaeaea",
    justifyContent: "center",
    " > p": {
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "0.7px",
      textAlign: "left",
      fontWeight: "300",
      padding: "16px"
    },
    " > p > span, > p > small > span": {
      fontWeight: "400"
    },
    " > p > small": {
      fontSize: "12px",
      display: "block",
      marginTop: "8px"
    }
  }),
  ratesContainerStyle: css({
    borderLeft: "1px solid #eaeaea",
    display: "flex",
    flexDirection: "column"
  }),
  rateContainerStyle: (selected: boolean, ratesLength: number) =>
    css({
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      borderBottom: "1px dashed #eaeaea",
      "&:hover": {
        backgroundColor: "rgba(18, 106, 255, 0.1)"
      },
      height: ratesLength === 1 ? "100%" : "128px",
      "&:last-child": {
        borderBottom: "none"
      },
      backgroundColor: selected ? "rgba(18, 106, 255, 0.1)" : ""
    }),
  rateDetailContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px",
    justifyContent: "center",
    height: "100%",
    width: "358px",
    borderRight: "1px solid #eaeaea"
  }),
  containerStyle: css({
    display: "flex",
    flexDirection: "row",
    borderRadius: "3px",
    backgroundColor: "#fff"
  }),
  roomsDescriptionContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    padding: "32px 16px",
    width: "256px"
  }),
  icon: (color: string) =>
    css({
      color: color
    })
}
