import { css } from "@emotion/css"

export default {
  content: css({
    display: "flex",
    flexDirection: "column"
  }),
  title: css({
    marginBottom: "8px !important"
  })
}
