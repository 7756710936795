import { css } from "@emotion/css"

export default {
  linearStyle: css({
    width: "250px",
    height: "8px !important",
    borderRadius: "4px",
    " > div": {
      backgroundColor: "#03a9f4 !important"
    }
  }),
  containerStyle: (variant: string) =>
    css({
      display: "flex",
      width: "100%",
      height: variant === "DESKTOP" ? "100vh" : undefined,
      padding: variant === "WEB" ? "128px 0" : undefined,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }),
  mainContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }),
  imgContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "98px",
    height: "98px",
    borderRadius: "50%",
    backgroundColor: "#00a5f4",
    " > img": {
      width: "58px",
      height: "65px"
    },
    marginBottom: "24px"
  }),
  text: (isMobile?: boolean) =>
    css({
      display: "flex",
      marginTop: "16px",
      justifyContent: "center",
      paddingLeft: isMobile ? "32px" : undefined,
      paddingRight: isMobile ? "32px" : undefined
    })
}
