import { css } from "@emotion/css"

const styles = {
  developmentAlert: (backgroundColor: string) =>
    css({
      display: "flex",
      justifyContent: "center",
      backgroundColor: backgroundColor,
      padding: "16px",
      marginTop: "8px"
    }),
  developmentAlertIcon: css({
    "&.MuiSvgIcon-root": {
      marginRight: "8px"
    }
  }),
  checkout: css({
    boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)"
  }),
  container: css({
    display: "flex",
    flexFlow: "column",
    fontFamily: "Roboto"
  }),
  content: (backgroundColor: string) =>
    css({
      display: "flex",
      flexFlow: "row wrap",
      padding: "8px 16px",
      backgroundColor: backgroundColor,
      justifyContent: "center"
    }),
  innerContentTop: css({
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1344px"
  }),
  contactContainer: (color: string) =>
    css({
      display: "flex",
      flexDirection: "column",
      alignItems: "end",
      gap: "8px",
      color: color
    }),
  contact: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center"
  }),
  phoneLink: (color: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "4px",
      color: `${color} !important`
    }),
  socialMenuIconButton: (color: string) =>
    css({
      color: color,
      display: "none",
      cursor: "pointer",
      "@media(max-width: 1024px)": {
        display: "block"
      }
    }),
  social: css({
    display: "flex",
    flexDirection: "row-reverse",
    gap: "8px",
    "@media(max-width: 1024px)": {
      display: "none"
    }
  }),
  contactText: (color: string) =>
    css({
      color: `${color} !important`,
      "@media(max-width: 1024px)": {
        display: "none"
      }
    }),
  innerContent: css({
    flex: 2,
    display: "flex",
    alignItems: "center",
    maxWidth: "1344px",
    "@media(max-width: 1024px)": {
      justifyContent: "space-between"
    }
  }),
  auth: css({
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "0 24px",
    "@media(max-width: 1024px)": {
      display: "none"
    }
  }),
  logo: css({
    maxWidth: "128px",
    maxHeight: "40px"
  }),
  nav: (backgroundColor: string) =>
    css({
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
      backgroundColor: backgroundColor
    }),
  link: (textColor: string, hoverColor: string, selectedColor: string, selected: boolean) =>
    css({
      "&.MuiLink-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        gap: "8px",
        fontWeight: 300,
        lineHeight: 1.44,
        color: textColor,
        backgroundColor: selected ? selectedColor : "inherit",
        padding: "8px 24px",
        ":hover": {
          backgroundColor: selected ? selectedColor : hoverColor
        },
        "@media(max-width: 1024px)": {
          width: "100%"
        },
        "@media(max-width: 668px)": {
          flex: 1,
          "& > span": {
            display: "none"
          }
        }
      }
    }),
  linkIcon: css({
    "&.MuiSvgIcon-root": {
      width: "14px"
    }
  })
}

export default styles
