import React from "react"
import { Cluster as ClusterModel, Service } from "../../model/types"
import { withTranslation, WithTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"
import styles from "./Cluster.style"
import { getDefaultLanguage } from "../../utils/helpers"
import moment from "moment"
import { theme, ThemeProps } from "@basset-la/themed-components"
import { AirplaneIcon } from "../../icons/AirplaneIcon"
import SegmentOption from "../SegmentOption/SegmentOption"
import Button from "@basset-la/components-commons/dist/components/Button"

export interface OwnProps {
  cluster: ClusterModel
  onSelect?: (segmentIndex: number, optionIndex: number) => void
  /**
   * Array of the length of `cluster.segments` where index is
   * the inxdex of the `Segment` and the value represents
   * the `SegmentOption` selected
   */
  selectedOptions?: number[]
  unfoldSegment?: boolean
  variant?: "B2C" | "B2B"
  mini?: boolean
  selectedBrand?: number
  onOpenBrandSelectionDialog?: () => void
}

interface Props extends WithTranslation, ThemeProps, OwnProps {}

interface State {
  openedOptions: boolean[][]
  enableBrandButton: boolean
}

class Cluster extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    if (props.selectedOptions) {
      if (props.selectedOptions.length !== props.cluster.segments.length) {
        throw new Error("selectedOptions length should be the same of cluster.segments")
      }
    }
    let openedOptions: boolean[][] = []

    for (let segment of props.cluster.segments) {
      let options: boolean[] = []
      for (let i = 0; i < segment.options.length; i++) {
        options.push(props.unfoldSegment ? true : false)
      }
      openedOptions.push(options)
    }
    this.state = {
      openedOptions: openedOptions,
      enableBrandButton: true
    }
  }

  componentWillReceiveProps = (nextProps: Props) => {
    const { cluster } = this.props

    if (nextProps.cluster !== cluster) {
      this.setState({ enableBrandButton: true })
    }
  }

  setOpenedOption = (segmentIndex: number, optionIndex: number, value: boolean) => {
    const { openedOptions } = this.state
    this.setState({
      openedOptions: openedOptions.map((e, i) => {
        if (i === segmentIndex) {
          return e.map((e2, i2) => {
            if (i2 === optionIndex) {
              return value
            }
            return e2
          })
        }
        return e
      })
    })
  }

  handleOpenBrandsDialog = async () => {
    const { onOpenBrandSelectionDialog } = this.props

    this.setState({ enableBrandButton: false })
    if (onOpenBrandSelectionDialog) onOpenBrandSelectionDialog()
  }

  render() {
    const {
      cluster,
      selectedOptions,
      selectedBrand,
      onSelect,
      t,
      i18n,
      theme,
      variant,
      mini,
      unfoldSegment
    } = this.props
    const { openedOptions, enableBrandButton } = this.state

    return (
      <div className={styles.root(theme, mini)}>
        {cluster.segments.map((s, i) => {
          const lng = getDefaultLanguage(i18n)
          const date = moment(s.departure_date)
            .locale(lng)
            .format(t("Cluster.dateFormat"))
          let title = ""
          let icon = <AirplaneIcon color={theme.colors.brand_darker} />
          switch (cluster.flight_type) {
            case "ROUND_TRIP":
              if (i === 0) {
                title = t("Cluster.departure")
              } else {
                title = t("Cluster.return")
                icon = <AirplaneIcon className={styles.rotated} color={theme.colors.brand_darker} />
              }
              break
            case "ONE_WAY":
              title = t("Cluster.departure")
              break
            default:
              title = t("Cluster.segmentNumber", { number: i + 1 })
              break
          }

          const price =
            selectedBrand !== undefined && cluster.upsell_fares
              ? cluster.upsell_fares[selectedBrand].price
              : cluster.price

          return (
            <div key={`segments_${i}`} className={styles.segment}>
              <div className={styles.segmentTitle}>
                <div className={styles.titleContainer}>
                  {icon}
                  <p className={styles.title}>{title}</p>
                </div>
                <p className={styles.date}>{date}</p>
              </div>
              <div>
                {s.options.map((o, optIdx) => {
                  const opt = { ...o }
                  let services: Service[] | undefined = undefined
                  if (selectedBrand !== undefined && cluster.upsell_fares) {
                    opt.baggage_allowances = cluster.upsell_fares[selectedBrand].segments[i].baggage_allowances
                    opt.legs[0].brand = cluster.upsell_fares[selectedBrand].segments[i].legs[0].brand
                    services = cluster.upsell_fares[selectedBrand].segments[i].legs[0].brand.services
                  }

                  const selected = selectedOptions ? selectedOptions[i] === optIdx : undefined

                  const segmentOptionSelected = selected ? "selected" : "unselected"

                  return (
                    <div
                      className={`${styles.segmentOption(theme)} ${openedOptions[i][optIdx] &&
                        styles.openedSegmentOption}`}
                      key={`segmentOpt_${optIdx}`}
                    >
                      <SegmentOption
                        rootContainerId={`flights-segment-option-${segmentOptionSelected}`}
                        unfoldSegment={unfoldSegment}
                        segmentOption={opt}
                        mini={mini}
                        variant={variant}
                        type={onSelect ? "selectable" : "detail"}
                        selected={selected}
                        onSelect={
                          onSelect
                            ? () => {
                                onSelect(i, optIdx)
                              }
                            : undefined
                        }
                        price={price}
                        services={services}
                        onOpen={(value: boolean) => this.setOpenedOption(i, optIdx, value)}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
        {selectedOptions !== undefined && selectedBrand !== undefined && (
          <div className={styles.brand}>
            <Button
              variant="outlined"
              shape="rounded"
              disabled={!cluster.upsell || !enableBrandButton}
              onClick={this.handleOpenBrandsDialog}
            >
              {t("Cluster.changeBrand")}
            </Button>
          </div>
        )}
      </div>
    )
  }
}

export default theme(withTranslation(I18N_NS)(Cluster))
