import * as React from "react"
import { css } from "@emotion/css"
import MaterialUIChip, { ChipProps } from "@material-ui/core/Chip"

export interface Props extends ChipProps {}

const Chip = (props: Props) => {
  return <MaterialUIChip classes={{ root: root }} {...props} />
}

const root = css({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  padding: "8px",
  margin: "0 8px 8px 0"
})

export default Chip
