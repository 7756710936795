import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: t.colors.text_primary
    }),
  breakdownRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    lineHeight: "1.33"
  }),
  total: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px",
    lineHeight: "1.71",
    fontWeight: 500
  }),
  divider: css({
    "&.MuiDivider-root": {
      margin: "8px 0px"
    }
  })
}
