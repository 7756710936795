import React from "react"
import { css } from "@emotion/css"
import { I18N_NS } from "../../utils/constants/constants"
import { AirplaneIcon } from "../../icons/AirplaneIcon"
import { useTheme, Theme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"

interface Props {
  origin: string
  destination: string
}

const QuotedPDFSegmentTitle: React.FC<Props> = ({ origin, destination }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.container(theme)}>
      <span className={styles.icon}>
        <AirplaneIcon color={theme.colors.brand_darker} />{" "}
      </span>
      <span className={styles.title}>{t("QuotedPDFSegmentTitle.title")}</span>
      <span className={styles.OD}>
        {origin} - {destination}
      </span>
    </div>
  )
}

const styles = {
  container: (theme: Theme) =>
    css({
      display: "flex",
      flexFLow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "32px",
      color: theme.colors.brand_darker,
      backgroundColor: theme.colors.background_variant,
      padding: "8px 16px"
    }),
  icon: css({
    display: "inline-flex",
    marginRight: "8px",
    alignItems: "center"
  }),
  title: css({
    display: "inline-flex",
    marginRight: "4px",
    alignItems: "center"
  }),
  OD: css({
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end"
  })
}

export default QuotedPDFSegmentTitle
