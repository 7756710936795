import React from "react"
import styles from "./Counter.styles"
import IconButton from "@material-ui/core/IconButton"
import AddIcon from "@material-ui/icons/AddRounded"
import RemoveIcon from "@material-ui/icons/RemoveRounded"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  value: number
  minValue: number
  maxValue: number
  onChange: (value: number) => void
}

const Counter: React.FC<Props> = ({ value, maxValue, minValue, onChange }) => {
  const theme = useTheme()
  return (
    <div className={styles.root(theme)}>
      <IconButton className={styles.button(theme)} onClick={() => onChange(value - 1)} disabled={value <= minValue}>
        <RemoveIcon />
      </IconButton>
      <span>{value}</span>
      <IconButton className={styles.button(theme)} onClick={() => onChange(value + 1)} disabled={value >= maxValue}>
        <AddIcon />
      </IconButton>
    </div>
  )
}

export default Counter
