import React from "react"

import { useTheme } from "@basset-la/themed-components"
import MaterialMenuItem from "@material-ui/core/MenuItem"
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput/OutlinedInput"
import MaterialTextField from "@material-ui/core/TextField"
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteGetTagProps
} from "@material-ui/lab/Autocomplete/Autocomplete"

import Checkbox from "../Checkbox"
import Chip from "../Chip"
import styles from "./Select.styles"

export type Value = {
  code: string
  name: string
}

export interface Props {
  values: Value[]
  onSelect: (v: string[]) => void
  selected?: string[]
  multiple?: boolean
  variant?: "checkbox" | "item"
  fullWidth?: boolean
  label?: string
  disabled?: boolean
  className?: string
  error?: boolean
  required?: boolean
  helperText?: string
  link?: React.ReactNode
  id?: string
  menuItemId?: string
  shrink?: boolean
  type?: "select" | "autocomplete"
  limitTags?: number
  InputProps?: Partial<OutlinedInputProps>
}

const Select: React.FC<Props> = ({
  values,
  selected = [],
  onSelect,
  multiple = false,
  fullWidth = false,
  label = "",
  disabled = false,
  variant = "checkbox",
  className,
  error,
  helperText,
  required,
  link,
  id,
  menuItemId,
  shrink = true,
  type = "select",
  limitTags = 2,
  InputProps = {}
}) => {
  const theme = useTheme()

  const getAutocompleteSelected = (selected: string[]): Value[] | Value | null => {
    if (type === "autocomplete") {
      if (multiple) {
        if (selected.length) {
          return values.filter(item => selected.find(s => s === item.code))
        }
        return []
      } else {
        if (selected.length) {
          const selectedValue = values.find(item => item.code === selected[0])
          if (selectedValue) {
            return selectedValue
          }
        }
      }
    }
    return null
  }

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    if (multiple) onSelect(event.target.value as string[])
    else onSelect([event.target.value as string])
  }

  const handleAutocompleteChange = (
    _event: React.ChangeEvent<{}>,
    value: (Value | Value[] | null)[],
    _reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<Value | Value[] | null>
  ) => {
    const finalValue = getAutocompleteValue(value)
    if (Array.isArray(finalValue)) {
      return onSelect(finalValue.map(item => item.code))
    }
    return onSelect([finalValue.code])
  }

  const renderValue = (value: unknown): React.ReactNode => {
    let label = ""
    const list = value as string[]

    if (multiple) {
      for (const i in list) {
        const s = values.find(e => e.code === list[i])
        if (s) label += `${s.name}`
        if (parseInt(i) < list.length - 1) label += `, `
      }

      return label
    } else {
      const selected = values.find(e => e.code === (value as string[]).join(""))

      if (selected) return selected.name

      return ""
    }
  }

  const renderOption = (option: Value | Value[] | null, selected: boolean): React.ReactNode => {
    if (!option) {
      return <React.Fragment />
    }

    if (Array.isArray(option)) {
      return option.map(item => {
        return (
          <React.Fragment>
            {variant === "checkbox" && <Checkbox checked={selected} className={styles.checkbox} />}
            {item.name}
          </React.Fragment>
        )
      })
    }

    return (
      <React.Fragment>
        {variant === "checkbox" && <Checkbox checked={selected} className={styles.checkbox} />}
        {option.name}
      </React.Fragment>
    )
  }

  const renderTags = (value: (Value | Value[] | null)[], getTagProps: AutocompleteGetTagProps): React.ReactNode => {
    const finalValue = getAutocompleteValue(value)

    if (Array.isArray(finalValue)) {
      const numTags = finalValue.length
      const finalLimitTags = limitTags === -1 ? numTags : limitTags
      return (
        <React.Fragment>
          {finalValue.slice(0, finalLimitTags).map((item, index) => (
            <Chip size="small" variant="outlined" label={item.name} {...getTagProps({ index })} />
          ))}
          {numTags > finalLimitTags && ` +${numTags - finalLimitTags}`}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Chip size="small" variant="outlined" label={finalValue.name} {...getTagProps({ index: 0 })} />
      </React.Fragment>
    )
  }

  const getOptionLabel = (option: Value | Value[] | null): string => {
    if (!option) {
      return ""
    }

    if (Array.isArray(option)) {
      return option.map(item => item.name).join(",")
    }

    return option.name
  }

  const getOptionSelected = (option: Value | Value[], value: Value | Value[]): boolean => {
    if (Array.isArray(option)) {
      return !option.map(item => values.find(value => value.code === item.code)).find(b => !b)
    }
    return (option as Value).code === (value as Value).code
  }

  const getAutocompleteValue = (value: (Value | Value[] | null)[] | Value | Value[] | null): Value | Value[] => {
    if (!value) {
      return []
    }

    if (Array.isArray(value)) {
      const result: Value[] = []
      value.forEach(item => {
        const finalValue = getAutocompleteValue(item)
        if (Array.isArray(finalValue)) {
          result.push(...finalValue)
        } else {
          result.push(finalValue)
        }
      })
      return result
    }

    return value
  }

  return type === "select" ? (
    <MaterialTextField
      id={id}
      variant="outlined"
      select
      label={label}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      required={required}
      value={selected}
      className={`${styles.select(theme, fullWidth, selected.length > 0)} ${className}`}
      disabled={disabled}
      InputLabelProps={{ shrink: shrink && selected.length > 0 }}
      InputProps={InputProps}
      SelectProps={{ multiple: multiple, onChange: handleChange, renderValue: renderValue }}
    >
      {values.map((v, i) => (
        <MaterialMenuItem key={i} value={v.code} id={menuItemId}>
          {variant === "checkbox" && (
            <Checkbox checked={selected.findIndex(e => e === v.code) > -1} className={styles.checkbox} />
          )}
          {v.name}
        </MaterialMenuItem>
      ))}
      {link && <MaterialMenuItem>{link}</MaterialMenuItem>}
    </MaterialTextField>
  ) : (
    <Autocomplete
      multiple={multiple}
      className={`${styles.autocomplete(theme, fullWidth)} ${className}`}
      disabled={disabled}
      options={values}
      value={getAutocompleteSelected(selected)}
      disableCloseOnSelect={multiple}
      onChange={handleAutocompleteChange}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      limitTags={limitTags}
      renderOption={(option, { selected }) => renderOption(option, selected)}
      renderTags={renderTags}
      renderInput={params => {
        const { InputProps: localInputProps, ...otherParams } = params
        return (
          <MaterialTextField
            {...otherParams}
            required={required}
            variant="outlined"
            label={label}
            fullWidth={fullWidth}
            error={error}
            helperText={helperText}
            InputProps={{
              ...localInputProps,
              ...InputProps
            }}
          />
        )
      }}
    />
  )
}

export default Select
