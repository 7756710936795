import React from "react"
import Chip from "@material-ui/core/Chip"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import styles from "./FilterBarButton.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  children: string
  onClick: React.ReactEventHandler<{}>
}

const FilterBarButton: React.FC<Props> = ({ children, onClick }) => {
  const theme = useTheme()

  return (
    <Chip
      className={styles.chip(theme)}
      onClick={onClick}
      label={
        <span>
          {children} <KeyboardArrowDown />
        </span>
      }
    ></Chip>
  )
}

export default FilterBarButton
