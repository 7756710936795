import Button from "@material-ui/core/Button"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { AdjustType } from "@basset-la/components-products/dist/components/CostEffectivenessDetail"
import { I18N_NS } from "../../utils/constants"
import styles from "./RateTotal.styles"
import { RoomClusterRate } from "../../model"
import React from "react"
import Sticky from "react-stickynode"
import StrikethroughPrice from "./StrikethroughPrice"
import DiscountLabel from "@basset-la/components-products/dist/components/DiscountLabel"
import DesktopDetailClusterPrice from "../DesktopDetailClusterPrice"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"
import { BookingPolicy } from "../../model"

interface Props {
  rate: RoomClusterRate
  totalGuests: number
  bottomBoundary?: string | number
  top?: string | number
  onSelect: () => void
  channel?: string
  taxesDisclaimers?: string[]
  onAdjust: (v: number) => void
  onAddToQuote?: () => void
  customButtons?: CustomButtonText[]
}

const PACKAGE_TYPE = "PACKAGE_OPAQUE"

const RateTotal: React.FC<Props> = ({
  rate,
  totalGuests,
  top,
  bottomBoundary,
  onSelect,
  channel,
  taxesDisclaimers,
  onAdjust,
  onAddToQuote,
  customButtons
}: Props) => {
  const { t, i18n } = useTranslation(I18N_NS)

  const theme = useTheme()

  const reserveCustomButton = customButtons?.find(customButton => customButton.type === "RESERVE")

  const getBookingButtonText = (bookingPolicy?: BookingPolicy, customButton?: CustomButtonText): string => {
    if (!bookingPolicy) {
      return getReserveText(customButton)
    }

    if (bookingPolicy.status === "BOOKABLE") {
      return getReserveText(customButton)
    }

    return t("RateTotal.request")
  }

  const getReserveText = (customButton?: CustomButtonText): string => {
    if (!customButton) {
      return t("RateTotal.reserve")
    }

    return customButton.value
  }

  if (rate.loading) {
    return (
      <Sticky top={top} innerZ={500} bottomBoundary={bottomBoundary}>
        <div className={`${styles.containerStyle}`}>
          <div className={`${styles.breakdownContainer}`}>
            <DesktopDetailClusterPrice loading onAdjust={onAdjust} />
          </div>

          <Button
            id="rate-total-reserve-btn"
            className={styles.buttonStyle(theme, false)}
            disabled={false}
            variant="contained"
            onClick={onSelect}
          >
            {getBookingButtonText(rate.booking_policy, reserveCustomButton)}
          </Button>

          {onAddToQuote && (
            <Button disabled onClick={onAddToQuote} className={styles.quotedButton(theme)} variant="outlined">
              {t("RateTotal.quote")}
            </Button>
          )}
        </div>
      </Sticky>
    )
  }

  const checkin = moment(rate.checkin)
  const checkout = moment(rate.checkout)
  const nights = checkout.diff(checkin, "days")
  const roomsCount = rate.rooms?.length || 0
  const isPackageRate = rate.type === PACKAGE_TYPE
  const locale = getLocale(i18n)

  const handleOnAdjust = (v: number, t: AdjustType) => {
    const value = t === "SUB" ? v * -1 : v

    onAdjust(value)
  }

  return (
    <Sticky top={top} innerZ={500} bottomBoundary={bottomBoundary}>
      <div className={`${styles.containerStyle}`}>
        {channel === "DESKTOP" && (
          <div className={`${styles.priceDetailStyle}`}>
            {rate.fare?.discount && (
              <div className={`${styles.discountStyle}`}>
                <DiscountLabel value={rate.fare.discount.discount} />
              </div>
            )}
            {isPackageRate && <p className={`${styles.packageRateStyle(theme)}`}>{t("RateTotal.package_rate")}</p>}
            <div className={`${styles.breakdownContainer}`}>
              <DesktopDetailClusterPrice
                loading={false}
                nightsCount={nights}
                guestsCount={totalGuests}
                roomsCount={roomsCount}
                accommodationFare={rate.fare!}
                onAdjust={handleOnAdjust}
              />
            </div>
          </div>
        )}
        {channel !== "DESKTOP" && (
          <>
            <p className={`${styles.nightsTextStyle}`}>
              {t("RateTotal.price_for_nights_and_guests", { nights: nights, guests: totalGuests })}
            </p>
            {rate.fare?.discount && (
              <StrikethroughPrice
                bold
                value={`${formatPrice(rate.fare.discount.base_rate, locale, rate.fare.currency)}`}
              />
            )}
            {rate.fare && (
              <p id="accommodation-total-price" className={`${styles.priceStyle}`}>
                {formatPrice(rate.fare.total, locale, rate.fare.currency)}
              </p>
            )}
            {rate.fare?.discount && (
              <div className={`${styles.discountLabel}`}>
                <DiscountLabel value={rate.fare.discount.discount} />
              </div>
            )}
          </>
        )}

        {!!taxesDisclaimers &&
          taxesDisclaimers.map((d: string, index: number) => (
            <span key={index} className={`${styles.disclaimerContainer}`}>
              {d}
            </span>
          ))}

        <Button
          id="rate-total-reserve-btn"
          className={styles.buttonStyle(theme, false)}
          disabled={false}
          variant="contained"
          onClick={onSelect}
        >
          {getBookingButtonText(rate.booking_policy, reserveCustomButton)}
        </Button>

        {onAddToQuote && (
          <Button onClick={onAddToQuote} className={styles.quotedButton(theme)} variant="outlined">
            {t("RateTotal.quote")}
          </Button>
        )}
      </div>
    </Sticky>
  )
}

export default RateTotal
