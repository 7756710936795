import React from "react"
import styles from "./LegDetailSection.styles"

const DEFAULT_BG_COLOR = "transparent"

interface Props {
  firstSection: React.ReactNode

  /** set to `true` to force mobile L&F */
  mini?: boolean
  className?: string
  backgroundColor?: string
  icon?: React.ReactNode
  secondSection?: React.ReactNode
  thirdSection?: React.ReactNode
  fourthSection?: React.ReactNode
}

const LegDetailSection: React.FunctionComponent<Props> = ({
  icon,
  firstSection,
  secondSection,
  thirdSection,
  fourthSection,
  backgroundColor = DEFAULT_BG_COLOR,
  className,
  mini = false
}) => {
  return (
    <div className={`${styles.root(backgroundColor)} ${className}`}>
      <div className={styles.iconContainer}>{icon}</div>
      <div className={styles.sectionsContainer(mini)}>
        <div>{firstSection}</div>
        {secondSection && <div>{secondSection}</div>}
        {thirdSection && <div>{thirdSection}</div>}
        {fourthSection && <div>{fourthSection}</div>}
      </div>
    </div>
  )
}

export default LegDetailSection
