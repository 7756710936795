import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const RefundableAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11.0002C0 7.32016 2.98667 4.3335 6.66667 4.3335C10.3467 4.3335 13.3333 7.32016 13.3333 11.0002C13.3333 14.6802 10.3467 17.6668 6.66667 17.6668C2.98667 17.6668 0 14.6802 0 11.0002ZM7.60667 15.4468V15.0602C8.76667 14.8402 9.68 14.1735 9.69333 12.9535C9.69333 11.2735 8.25333 10.6935 6.90667 10.3468C5.56 10.0002 5.12667 9.6335 5.12667 9.0735C5.12667 8.4335 5.72667 7.98016 6.72667 7.98016C7.56667 7.98016 7.98667 8.30683 8.14 8.8135C8.20667 9.0535 8.40667 9.22683 8.65333 9.22683H8.82667C9.22 9.22683 9.5 8.84016 9.36667 8.4735C9.11333 7.76683 8.53333 7.1935 7.61333 6.96683V6.5535C7.61333 6.06683 7.21333 5.66683 6.72667 5.66683H6.72C6.23333 5.66683 5.83333 6.06683 5.83333 6.5535V6.9535C4.68667 7.20016 3.76 7.94683 3.76 9.0935C3.76 10.4602 4.89333 11.1402 6.54667 11.5402C8.03333 11.8935 8.32667 12.4135 8.32667 12.9668C8.32667 13.3735 8.03333 14.0268 6.72667 14.0268C5.75333 14.0268 5.24667 13.6802 5.05333 13.1802C4.96 12.9468 4.76 12.7802 4.51333 12.7802H4.35333C3.95333 12.7802 3.66667 13.1868 3.82 13.5535C4.15333 14.3735 4.94 14.8602 5.82667 15.0468V15.4468C5.82667 15.9335 6.22667 16.3335 6.71333 16.3335H6.72C7.20667 16.3335 7.60667 15.9335 7.60667 15.4468Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.235 10.2519H13.765C14.535 10.2519 15.015 9.41692 14.63 8.75192L10.865 2.24692C10.48 1.58192 9.52 1.58192 9.135 2.24692L5.37 8.75192C4.985 9.41692 5.465 10.2519 6.235 10.2519ZM10 6.75192C9.725 6.75192 9.5 6.52692 9.5 6.25192V5.25192C9.5 4.97692 9.725 4.75192 10 4.75192C10.275 4.75192 10.5 4.97692 10.5 5.25192V6.25192C10.5 6.52692 10.275 6.75192 10 6.75192ZM9.5 8.75192H10.5V7.75192H9.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
