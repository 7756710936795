import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      padding: "24px 16px",
      backgroundColor: t.colors.background_darker,
      borderRadius: 10,
      width: "100%"
    }),

  content: css({
    display: "flex",
    flexDirection: "column"
  }),

  selectedItem: css({
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
    height: 24,
    width: 200,
    flex: 1
  }),

  item: (t: Theme) =>
    css({
      flex: 5,
      borderBottom: "1px solid rgba(0, 0, 0, 0.14)",
      backgroundColor: t.colors.background_primary,
      height: 24
    })
}
