import * as React from "react"
import Button from "@material-ui/core/Button"
import TuneIcon from "@material-ui/icons/Tune"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import styles from "./FlightsFiltersMobileBar.styles"
import { I18N_NS } from "../../utils/constants"

interface Props {
  onToggle: () => void
}

const FlightsFiltersMobileBar: React.FC<Props> = ({ onToggle }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const handleToggle = () => {
    onToggle()
  }

  return (
    <div className={styles.root(theme)}>
      <Button className={styles.button(theme)} onClick={handleToggle} fullWidth={true}>
        <TuneIcon className={styles.icon(theme)} />
        <strong>{t("FlightsFiltersMobileBar.filter")}</strong>
      </Button>
    </div>
  )
}

export default FlightsFiltersMobileBar
