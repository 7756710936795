import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  container: css({
    display: "flex",
    flexDirection: "column",
    margin: "8px"
  }),
  noOptions: css({
    paddingLeft: "40px",
    paddingTop: "8px"
  }),
  link: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        color: theme.colors.brand_primary,
        fontSize: "16px",
        lineHeight: "1.5",
        cursor: "pointer",
        textTransform: "none",
        fontWeight: "normal"
      }
    }),
  row: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "8px",
    ":last-child": {
      marginBottom: 0
    }
  })
}
