import React from "react"
import styles from "./FixedMessage.styles"
import { Action, actionIcon } from "./types"

import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { Fade } from "@material-ui/core"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  /**
   * Param to manange the message display
   * @param open
   * @value boolean
   * @required true
   */
  open: boolean
  /**
   * Message to show
   * @param message
   * @value string
   * @required true
   */
  message: string
  /**
   * Action style for message
   * @param action
   * @value "success" | "warning" | "error" | "info"
   * @required true
   */
  action: Action
  /**
   * Function to execute onClose message
   * @param onClose
   * @value function
   * @required false
   */
  onClose?: () => void
}

const FixedMessage: React.FunctionComponent<Props> = ({ open, message, action, onClose }) => {
  const Icon = actionIcon[action]
  const theme = useTheme()

  if (open) {
    return (
      <Fade in={open}>
        <div className={`${styles.root} ${styles[action](theme)}`}>
          <div className={styles.icon}>
            <Icon />
          </div>
          <div className={styles.message(theme)}>{message}</div>
          {onClose && (
            <div className={styles.action}>
              <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
      </Fade>
    )
  } else {
    return null
  }
}

export default FixedMessage
