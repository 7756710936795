import { css } from "@emotion/css"

export default {
  root: css({
    border: "1px solid #E9EFF4",
    boxSizing: "border-box",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff"
  }),
  header: css({
    display: "flex",
    flexDirection: "row",
    paddingBottom: "8px",
    cursor: "pointer"
  }),
  headerComponent: css({
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  }),
  text: css({
    color: `#1134D7 !important`
  }),
  ArrowIcon: css({
    fill: "#1134D7"
  }),

  providerID: css({
    marginLeft: "8px"
  }),

  showIcon: css({
    marginLeft: "8px",
    "&.MuiSvgIcon-root": {
      color: "#4a4a4a"
    }
  }),

  pnr: css({
    marginLeft: "32px"
  })
}
