import { css } from "@emotion/css"
import { darken } from "@material-ui/core/styles"

export default {
  root: (textColor: string, backgroundColor: string) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: textColor,
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ".MuiSvgIcon-root": {
        fontSize: "20px"
      }
    }),
  content: css({
    display: "flex",
    flexDirection: "column",
    padding: "16px 16px 8px 16px"
  }),
  childrenContainer: css({
    paddingTop: "8px",
    ".components-products-PriceboxRow": {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      ".components-products-PriceboxRow": {
        fontSize: "12px",
        letterSpacing: "0.13px",
        lineHeight: "1.33",
        ".components-products-PriceboxRow": {
          fontWeight: 300
        }
      }
    }
  }),
  arrow: css({
    width: "16px",
    height: "16px"
  }),
  title: css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "0.17px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer"
  }),
  total: (backgroundColor: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px 16px",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "1",
      letterSpacing: "0.17px",
      backgroundColor: darken(backgroundColor, 0.2),
      borderBottomLeftRadius: "3px",
      borderBottomRightRadius: "3px"
    }),
  taxesDisclaimer: (backgroundColor: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px 16px",
      paddingTop: "0",
      fontSize: "12px",
      fontWeight: 300,
      lineHeight: "1",
      letterSpacing: "0.17px",
      backgroundColor: darken(backgroundColor, 0.2)
    })
}
