import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      color: t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary,
      backgroundColor: t.colors.searchbox ? t.colors.searchbox.background : t.colors.background_primary,
      minWidth: "320px",
      boxSizing: "border-box",
      borderRadius: "4px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ".MuiFormControl-root": {
        backgroundColor: t.colors.background_primary,
        borderRadius: "4px"
      },
      ".MuiInputLabel-root": {
        backgroundColor: t.colors.background_primary,
        padding: "2px 4px",
        borderRadius: "4px"
      },
      "@media(max-width:1024px)": {
        padding: "16px"
      }
    }),
  title: css({
    fontSize: "20px",
    lineHeight: "1.2",
    marginBottom: "16px"
  }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  mainContent: css({
    display: "flex",
    flexDirection: "column"
  }),
  legsContainer: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "16px"
  }),
  leg: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  legTitleContainer: css({
    display: "flex",
    flexDirection: "row",
    marginBottom: "16px"
  }),
  legTitle: css({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    letterSpacing: "0.15px",
    marginRight: "8px"
  }),
  legMainContent: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    columnGap: "16px",
    width: "100%"
  }),
  legOriginDestination: css({
    flex: "1",
    marginBottom: "16px",
    minWidth: "288px",
    "> *": {
      width: "100%"
    },
    "@media(max-width:1024px)": {
      flex: "1 1 auto"
    }
  }),
  legDates: css({
    flex: "1",
    minWidth: "288px",
    marginBottom: "16px",
    "@media(min-width:960px)": {
      maxWidth: "315px"
    },
    "@media(max-width:1024px)": {
      flex: "1 1 auto"
    }
  }),
  addFlightButton: (t: Theme) =>
    css({
      "&.MuiButton-root": {
        color: t.colors.brand_primary,
        width: "fit-content",
        minWidth: 0,
        fontSize: "15px",
        textTransform: "none",
        lineHeight: "1.5",
        marginTop: "8px",
        marginBottom: "16px",
        fontWeight: "normal",
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary,
          marginRight: "8px"
        }
      }
    }),
  divider: css({
    "&.MuiDivider-root": {
      marginBottom: "22px"
    }
  }),
  passengersContainer: css({
    flex: "1 1 auto"
  }),
  stopsContainer: css({
    flex: "1 1 auto"
  }),
  includeBaggageContainer: (t: Theme) =>
    css({
      flex: "1 1 auto",
      flexDirection: "row",
      width: "288px",
      marginBottom: "16px",
      " .MuiFormControlLabel-root": {
        width: "100%",
        margin: 0
      },
      " .MuiCheckbox-root": {
        padding: 0,
        marginRight: "8px",
        marginLeft: "-3px",
        borderColor: t.colors.brand_primary,
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary
        }
      },
      "@media(max-width:1024px)": {
        marginBottom: "16px"
      },
      "#corporateRatesInput": {
        marginLeft: "30px !important"
      }
    }),
  secondSection: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  }),
  footer: css({
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "space-between"
  }),
  buttonContainer: css({
    marginLeft: "auto",
    minWidth: "288px",
    "@media(min-width:1024px)": {
      maxWidth: "288px"
    },
    " div": {
      width: "100%"
    }
  }),
  helper: css({
    display: "flex",
    flexDirection: "column"
  }),
  paxStopContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "1 1 auto",
    gap: "16px"
  }),
  mobileContent: css({
    padding: "16px"
  }),
  datePickerInput: css({
    ".MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    },
    ".MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    ".MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    }
  }),
  flexVerticalSeparatorHelper: css({
    flexBasis: "100%",
    height: 0
  }),
  inputCorporateRate: css({
    marginLeft: "30px"
  }),
  searchButtonVerticalMarginHelper: css({
    marginTop: "60px"
  })
}
