import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiAppBar-root": {
        backgroundColor: t.colors.background_primary,
        padding: "0",
        width: "100%"
      }
    }),
  toolbar: (t: Theme) =>
    css({
      "&.MuiToolbar-root": {
        color: t.colors.text_primary,
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0",
        minHeight: "40px",
        width: "100%"
      }
    }),
  title: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 32px",
    width: "100%"
  }),
  children: (t: Theme) =>
    css({
      display: "flex",
      alignItems: "center",
      width: "100%",
      borderTop: `solid 1px ${t.colors.background_darker}`
    })
}
