import { css } from "@emotion/css"

export default {
  dialog: css({
    ".MuiDialog-paperWidthMd": {
      maxWidth: "1060px"
    }
  }),

  dialogTitle: css({
    "&.MuiDialogTitle-root": {
      borderBottom: "1px solid #eaeaea",
      " .MuiTypography-root": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center"
      }
    }
  }),

  titleText: css({
    fontWeight: 500,
    fontSize: "18px"
  }),

  content: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px",
    gap: "40px"
  })
}
