import React from "react"
import { ServiceType } from "../../model"
import { ServiceState } from "../../utils/helpers/brands"
import { BagIconNotFounded } from "../../icons/BagIconNotFounded"
import { BagIcon } from "../../icons/BagIcon"
import { SeatIcon } from "../../icons/SeatIcon"
import { SeatAlertIcon } from "../../icons/SeatAlertIcon"
import { ChangeIcon } from "../../icons/ChangeIcon"
import { ChangeAlertIcon } from "../../icons/ChangeAlertIcon"
import { RefundableIcon } from "../../icons/RefundableIcon"
import { RefundableAlertIcon } from "../../icons/RefundableAlertIcon"
import { MealIcon } from "../../icons/MealIcon"
import { MealAlertIcon } from "../../icons/MealAlertIcon"
import { MileIcon } from "../../icons/MileIcon"
import { MileAlertIcon } from "../../icons/MileAlertIcon"
import { InternetIcon } from "../../icons/InternetIcon"
import { InternetAlertIcon } from "../../icons/InternetAlertIcon"
import { GenericServiceIcon } from "../../icons/GenericServiceIcon"
import { GenericServiceAlertIcon } from "../../icons/GenericServiceAlertIcon"
import { CarryOnIcon } from "../../icons/CarryOnIcon"
import { CarryOnIconNotFounded } from "../../icons/CarryOnIconNotFounded"
import { CheckInIcon } from "../../icons/CheckInIcon"
import { CheckInAlertIcon } from "../../icons/CheckInAlertIcon"
import { EntertainmentIcon } from "../../icons/EntertainmentIcon"
import { EntertainmentAlertIcon } from "../../icons/EntertainmentAlertIcon"
import { BoardingIcon } from "../../icons/BoardingIcon"
import { BoardingAlertIcon } from "../../icons/BoardingAlertIcon"
import { useTheme } from "@basset-la/themed-components"
import { BaggageIcon } from "../../icons/BaggageIcon"
import { BaggageAlertIcon } from "../../icons/BaggageAlertIcon"
import { BagAlertIcon } from "../../icons/BagAlertIcon"
import { BaggageIconNotFounded } from "../../icons/BaggageIconNotFounded"
import { CarryOnAlertIcon } from "../../icons/CarryOnAlertIcon"
import { SeatIconNotFound } from "../../icons/SeatIconNotFound"
import { ChangeIconNotFound } from "../../icons/ChangeIconNotFound"
import { RefundableIconNotFound } from "../../icons/RefundableIconNotFound"
import { MealIconNotFound } from "../../icons/MealIconNotFound"
import { MileIconNotFound } from "../../icons/MileIconNotFound"
import { InternetIconNotFound } from "../../icons/InternetIconNotFound"

export interface Props {
  type: ServiceType
  state: ServiceState
}

const BrandServiceIcon: React.FC<Props> = ({ type, state }) => {
  const theme = useTheme()

  switch (type) {
    case "HAND_ON_BAGGAGE":
      if (state === "NOT_INCLUDED") return <BagIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <BagAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <BagIconNotFounded color={theme.colors.text_variant} />
      return <BagIcon color={theme.colors.brand_primary} />

    case "CHECKED_BAGGAGE":
    case "CHECKED_EXTRA_BAGGAGE":
    case "PREPAID_BAGGAGE":
    case "BAGGAGE_DELIVERY_PRIORITY":
    case "BAGGAGE_SUBSCRIPTION":
    case "BAGGAGE_SPECIAL_CABIN":
      if (state === "NOT_INCLUDED") return <BaggageIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <BaggageAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <BaggageIconNotFounded color={theme.colors.text_variant} />
      return <BaggageIcon color={theme.colors.brand_primary} />

    case "CARRY_ON_BAGGAGE":
      if (state === "NOT_INCLUDED") return <CarryOnIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <CarryOnAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <CarryOnIconNotFounded color={theme.colors.text_variant} />
      return <CarryOnIcon color={theme.colors.brand_primary} />

    case "BASIC_SEAT_RESERVATION":
    case "PREMIUM_SEAT_RESERVATION":
    case "SEAT_SELECTION":
    case "SEAT_XL":
    case "PREMIUM_SEAT":
    case "PRERESERVED_SEAT":
    case "LIE_FLAT_SEAT":
      if (state === "NOT_INCLUDED") return <SeatIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <SeatAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <SeatIconNotFound color={theme.colors.text_variant} />
      return <SeatIcon color={theme.colors.brand_primary} />

    case "CHANGE_ANY_TIME":
    case "CHANGE_AFTER_DEPARTURE":
    case "CHANGE_BEFORE_DEPARTURE":
    case "SAME_DAY_CHANGE":
    case "CHANGEABLE_TICKET":
      if (state === "NOT_INCLUDED") return <ChangeIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <ChangeAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <ChangeIconNotFound color={theme.colors.text_variant} />
      return <ChangeIcon color={theme.colors.brand_primary} />

    case "REFUND_AFTER_DEPARTURE":
    case "REFUND_BEFORE_DEPARTURE":
    case "REFUNDABLE_TICKET":
      if (state === "NOT_INCLUDED") return <RefundableIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <RefundableAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <RefundableIconNotFound color={theme.colors.text_variant} />
      return <RefundableIcon color={theme.colors.brand_primary} />

    case "MEAL":
    case "ALCOHOLIC_DRINK":
    case "SNACK":
    case "BEVERAGE":
      if (state === "NOT_INCLUDED") return <MealIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <MealAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <MealIconNotFound color={theme.colors.text_variant} />
      return <MealIcon color={theme.colors.brand_primary} />

    case "MILEAGE_ACCRUAL":
      if (state === "NOT_INCLUDED") return <MileIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <MileAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <MileIconNotFound color={theme.colors.text_variant} />
      return <MileIcon color={theme.colors.brand_primary} />

    case "POCKET_WIFI":
    case "INTERNET_ACCESS":
    case "WIFI_ACCESS":
      if (state === "NOT_INCLUDED") return <InternetIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <InternetAlertIcon color={theme.colors.text_variant} />
      if (state === "VERIFY") return <InternetIconNotFound color={theme.colors.text_variant} />
      return <InternetIcon color={theme.colors.brand_primary} />

    case "LATE_CHECK_IN":
    case "PRIORITY_CHECKIN":
      if (state === "NOT_INCLUDED" || state === "VERIFY") return <CheckInIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <CheckInAlertIcon color={theme.colors.text_variant} />
      return <CheckInIcon color={theme.colors.brand_primary} />

    case "STREAMING_VIDEO":
    case "IN_FLIGHT_ENTERTAIMENT":
      if (state === "NOT_INCLUDED" || state === "VERIFY") return <EntertainmentIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <EntertainmentAlertIcon color={theme.colors.text_variant} />
      return <EntertainmentIcon color={theme.colors.brand_primary} />

    case "LAST_TO_BOARD":
    case "PRIORITY_BOARDING":
    case "STANDARD_BOARDING":
      if (state === "NOT_INCLUDED" || state === "VERIFY") return <BoardingIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <BoardingAlertIcon color={theme.colors.text_variant} />
      return <BoardingIcon color={theme.colors.brand_primary} />

    default:
      if (state === "NOT_INCLUDED" || state === "VERIFY")
        return <GenericServiceIcon color={theme.colors.text_variant} />
      if (state === "PAID") return <GenericServiceAlertIcon color={theme.colors.text_variant} />
      return <GenericServiceIcon color={theme.colors.brand_primary} />
  }
}

export default BrandServiceIcon
