import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Text from "@basset-la/components-commons/dist/components/Text"
import { SearchboxTextField } from "@basset-la/components-products"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import SearchIcon from "../../icons/SearchIcon"
import { I18N_NS } from "../../utils/constants"
import styles from "./AmenitiesInput.styles"
import AmenitiesOptionList from "./AmeniitesOptionsList"
import { Option } from "./AmenitiesFilter"

interface AmenitiesSearchInputProps {
  items: Option[]
  selectedItems: Option[]
  isMobile?: boolean
  inputValue?: string
  onInputChange?: (value: string) => void
  onChange?: (value: Option[]) => void
  onDone?: () => void
  onClearAll?: () => void
  onSelectAll?: (items: Option[]) => void
  countSelectedInSearch?: number
  onCountSelectedInSearchChange?: (value: number) => void
  onClickAway?: () => void
  loading?: boolean
  multiple?: boolean
}

const AmenitiesSearchInput = React.forwardRef<HTMLDivElement, AmenitiesSearchInputProps>(
  (
    {
      items,
      selectedItems,
      isMobile = false,
      inputValue,
      onInputChange,
      onChange,
      onDone,
      multiple = false,
      onSelectAll,
      onClearAll,
      countSelectedInSearch = 0,
      loading = false,
      onCountSelectedInSearchChange,
      onClickAway
    },
    ref
  ) => {
    const { t } = useTranslation(I18N_NS)
    const [disableSelectAll, setDisableSelectAll] = useState(!multiple)

    useEffect(() => {
      if (multiple) {
        setDisableSelectAll(!items.length)
      }
    }, [multiple, items])

    useEffect(() => {
      setDisableSelectAll(countSelectedInSearch === items.length)
    }, [items, countSelectedInSearch])

    const internalHandleOnInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onInputChange) {
        onInputChange(event.target.value)
      }
    }

    const handleOnSelectItem = (item: Option) => {
      if (onChange) {
        if (multiple) {
          const filter = selectedItems.filter(i => i.value !== item.value)
          if (filter.length === selectedItems.length) {
            onChange && onChange([...selectedItems, item])
            if (onCountSelectedInSearchChange) {
              onCountSelectedInSearchChange(countSelectedInSearch + 1)
            }
            return
          }
          onChange(filter)
          if (onCountSelectedInSearchChange) {
            onCountSelectedInSearchChange(countSelectedInSearch - 1)
          }
          return
        }
        onChange([item])
      }
    }

    const handleOnClickAway = (_event: React.MouseEvent<Document>) => {
      if (onClickAway) {
        onClickAway()
      }
    }

    return (
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <div className={styles.container}>
          <SearchboxTextField
            onChange={internalHandleOnInputChange}
            value={inputValue}
            placeholder={t("AmenitiesSelect.searchAmenities")}
            leftIcon={<SearchIcon />}
            inputRef={ref}
            fullWidth
          />
          {!inputValue || inputValue.length < 2 ? (
            <Text className={styles.noOptions} size={14} variant="light">
              {t<string>("AmenitiesSelect.helper_text")}
            </Text>
          ) : (
            <AmenitiesOptionList
              isMobile={isMobile}
              loading={loading}
              items={items}
              onSelectAll={onSelectAll}
              onDone={onDone}
              onClearAll={onClearAll}
              disableClearAll={selectedItems.length === 0}
              disableSelectAll={disableSelectAll}
              onSelectItem={handleOnSelectItem}
              multiple={multiple}
            />
          )}
        </div>
      </ClickAwayListener>
    )
  }
)

export default AmenitiesSearchInput
