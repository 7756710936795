import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (t: Theme, variant: "column" | "row") =>
    css({
      display: "flex",
      flexDirection: variant,
      justifyContent: "space-between",
      color: variant === "row" ? t.colors.background_primary : t.colors.brand_primary,
      backgroundColor: variant === "column" ? t.colors.background_primary : t.colors.brand_primary,
      cursor: "pointer",
      fontSize: "14px"
    }),

  item: (t: Theme, variant: "column" | "row") =>
    css({
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
      "&.MuiListItem-root": {
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: 1.44
      },
      "&.MuiListItem-button:hover": {
        backgroundColor: variant === "row" ? t.colors.brand_variant : undefined
      }
    })
}

export default styles
