import React, { useEffect, useState } from "react"
import { AirplaneIcon } from "../../icons/AirplaneIcon"
import styles from "./AirlineLogo.styles"

export interface Props {
  airlineCode: string | "multiple"
  className?: string
  aircraftType?: string
}

const AirlineLogo = ({ airlineCode, className, aircraftType }: Props) => {
  const [loadError, setLoadError] = useState(false)

  useEffect(() => {
    setLoadError(false)
  }, [airlineCode])

  const handleError = () => {
    setLoadError(true)
  }

  if (loadError) return <AirplaneIcon />

  const getSrcByAircraftType = (aircraftType?: string): string => {
    switch (aircraftType) {
      case "BUS":
        return "https://statics.basset.la/flights/location/bus.png"

      case "TRAIN":
        return "https://statics.basset.la/flights/location/train.png"

      case "OTHER":
        return "https://statics.basset.la/flights/location/generic.png"

      default:
        return `https://statics.basset.la/airlines/${airlineCode}-ISO.svg`
    }
  }

  return (
    <img
      alt={`${airlineCode} airline`}
      className={`${styles.root} ${className}`}
      src={getSrcByAircraftType(aircraftType)}
      onError={handleError}
    />
  )
}

export default AirlineLogo
