import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, available?: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "8px",
      color: t.colors.text_primary,
      fontFamily: "Roboto",
      width: "inherit",
      backgroundColor: available ? `${t.colors.brand_darker}05` : t.colors.background_variant,
      ">p": {
        margin: 0,
        color: available ? t.colors.brand_darker : t.colors.text_variant,
        fontSize: "12px",
        lineHeight: "1.33",
        letterSpacing: "0.13px"
      },
      ">div": {
        display: "flex",
        flexDirection: "column"
      }
    }),
  psgLabel: css({
    display: "flex",
    flexDirection: "row",
    fontSize: "10px",
    lineHeight: "1.2",
    letterSpacing: "0.11px",
    ">span:first-of-type": {
      fontWeight: 500,
      marginRight: "2px"
    }
  })
}
