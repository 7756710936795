import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: "16px",
    "@media screen and (max-width: 1395px)": {
      gap: "8px"
    }
  })
}
