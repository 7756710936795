import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const StarIcon: React.FunctionComponent<Props> = ({ ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 17.5196L16.15 20.0296C16.91 20.4896 17.84 19.8096 17.64 18.9496L16.54 14.2296L20.21 11.0496C20.88 10.4696 20.52 9.3696 19.64 9.2996L14.81 8.8896L12.92 4.4296C12.58 3.6196 11.42 3.6196 11.08 4.4296L9.19001 8.8796L4.36001 9.2896C3.48001 9.3596 3.12001 10.4596 3.79001 11.0396L7.46001 14.2196L6.36001 18.9396C6.16001 19.7996 7.09001 20.4796 7.85001 20.0196L12 17.5196V17.5196Z"
      fill="#F19202"
    />
  </svg>
)
