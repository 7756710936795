import React from "react"
import styles from "./Card.styles"
import { useTheme } from "@basset-la/themed-components"

import MaterialCard, { CardProps } from "@material-ui/core/Card"

export interface Props extends CardProps {
  children: React.ReactNode
  selected?: boolean
}

const Card: React.FC<Props> = ({ children, selected, variant = "elevation", className, ...MaterialProps }) => {
  const theme = useTheme()

  return (
    <MaterialCard
      variant={variant}
      className={`${styles.root(theme)} ${selected && styles.selected[variant](theme)} ${className}`}
      {...MaterialProps}
    >
      {children}
    </MaterialCard>
  )
}

export default Card
