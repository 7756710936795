import React from "react"
import ModularBanner from "./ModularBanner"
import FullSizeBanner from "./FullSizeBanner"
import CarouselBanner from "./CarouselBanner"
import { BannerPositionInfo } from "./types"

export interface Props {
  width?: string
  banner: BannerPositionInfo
  onClick: (url: string) => void
}

const Advertising: React.FC<Props> = ({ width, banner, onClick }) => {
  return (
    <>
      {banner.type && banner.type === "SLIDER" && <FullSizeBanner images={banner.banners} onClick={onClick} />}
      {banner.type && banner.type === "MODULAR" && <ModularBanner images={banner.banners} onClick={onClick} />}
      {banner.type && banner.type === "CARROUSEL" && (
        <CarouselBanner width={width || "100%"} images={banner.banners} onClick={onClick} />
      )}
    </>
  )
}

export default Advertising
