import React from "react"

export const OpenBarIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#FFF"
      strokeWidth=".7"
      d="M19.55 3C20.35 3 21 3.65 21 4.45C21 4.8 20.87 5.15 20.63 5.41L13 14V19H17C17.55 19 18 19.45 18 20C18 20.55 17.55 21 17 21H7C6.45 21 6 20.55 6 20C6 19.45 6.45 19 7 19H11V14L3.37 5.41C3.13 5.15 3 4.8 3 4.45C3 3.65 3.65 3 4.45 3H19.55ZM5.66 5L7.43 7H16.57L18.35 5H5.66Z"
    />
  </svg>
)
