import {
  AccommodationFare,
  AccommodationFareCharge,
  AccommodationFee,
  Charge,
  CostEffectiveness,
  CostEffectivenessBreakdown,
  CostPayment,
  PriceBreakdown,
  Tax,
  Taxes
} from "../../model/types"

import CurrencyJS from "currency.js"

type ChargeType = string
type FeeType = string
type CollectorType = string

//const ChargeTypeBaseRate: ChargeType = "base_rate"
//const ChargeTypeTaxAndServiceFee: ChargeType = "tax_and_service_fee"
const ChargeTypeRoomTax: ChargeType = "room_tax"
// const ChargeTypeExtraPersonFee: ChargeType = "extra_person_fee"
// const ChargeTypePropertyFee: ChargeType = "property_fee"
// const ChargeTypeSalesTax: ChargeType = "sales_tax"
// const ChargeTypeAdjustment: ChargeType = "adjustment"
const ChargeTypeOperatingCost: ChargeType = "operating_cost"
const ChargeTypeAdminCost: ChargeType = "admin_cost"
const ChargeTypeCommission: ChargeType = "commission"
const ChargeTypeMarkup: ChargeType = "markup"

const CollectorTypeAgency: CollectorType = "AGENCY"
const CollectorTypeReseller: CollectorType = "RESELLER"
const CollectorTypeProvider: CollectorType = "PROVIDER"

const USER_PRICE_ADJUSTMENT = "USER_PRICE_ADJUSTMENT"

//const FeeTypeResortFee: FeeType = "resort_fee"
//const FeeTypeMandatoryFee: FeeType = "mandatory_fee"
const FeeTypePAIS: FeeType = "PAIS"
const FeeTypeRG4815: FeeType = "RG4815/20"
const FeeTypeRG5272: FeeType = "RG5272/22"
const FeeTypeAdditional: FeeType = "ADDITIONAL_FEE"

export const convertAccommodationFareToAgencyPriceBreakdown = (
  accommodationFare: AccommodationFare,
  rooms: number,
  guests: number
): PriceBreakdown => {
  const { net_rate, currency, total, charges, fees, discount, taxes } = accommodationFare

  let agencyVAT = CurrencyJS(0)
  let resellerVAT = CurrencyJS(0)
  let roomVAT = CurrencyJS(0)
  let commission = CurrencyJS(0)
  let cededCommission = CurrencyJS(0)
  let markup = CurrencyJS(0)

  const priceAdjustment = calculateAdjustment(accommodationFare)

  let resultAgencyCharges: Charge = {
    markup: 0,
    operationCosts: 0,
    adminCosts: 0,
    adjustment: priceAdjustment,
    additionalFee: 0,
    total: priceAdjustment
  }
  let resultResellerCharges: Charge = {
    markup: 0,
    operationCosts: 0,
    adminCosts: 0,
    additionalFee: 0,
    total: 0
  }
  if (charges) {
    const agencyCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeAgency)
    if (agencyCharges) {
      const agencyMarkup = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const agencyOperatingCost = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost)
      const agencyAdminCost = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeAdminCost)
      const agencyCededCommission = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      cededCommission = agencyCededCommission ? CurrencyJS(agencyCededCommission.amount) : CurrencyJS(0)
      markup = agencyMarkup ? CurrencyJS(agencyMarkup.amount) : CurrencyJS(0)

      agencyVAT = agencyVAT
        .add(agencyMarkup ? CurrencyJS(agencyMarkup.vat) : CurrencyJS(0))
        .add(agencyOperatingCost ? CurrencyJS(agencyOperatingCost.vat) : CurrencyJS(0))
        .add(agencyAdminCost ? CurrencyJS(agencyAdminCost.vat) : CurrencyJS(0))
        .add(agencyCededCommission ? CurrencyJS(agencyCededCommission.vat) : CurrencyJS(0))

      // resultAgencyCharges.markup = agencyMarkup ? agencyMarkup.amount : 0
      resultAgencyCharges.operationCosts = agencyOperatingCost ? agencyOperatingCost.amount : 0
      resultAgencyCharges.adminCosts = agencyAdminCost ? agencyAdminCost.amount : 0
      resultAgencyCharges.total = CurrencyJS(resultAgencyCharges.total)
        // .add(resultAgencyCharges.markup)
        .add(CurrencyJS(resultAgencyCharges.operationCosts))
        .add(CurrencyJS(resultAgencyCharges.adminCosts)).value
    }

    const resellerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeReseller)
    if (resellerCharges) {
      const resellerMarkup = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const resellerOperatingCost = resellerCharges.find(
        (c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost
      )
      const resellerAdminCost = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeAdminCost)

      resellerVAT = resellerVAT
        .add(resellerMarkup ? CurrencyJS(resellerMarkup.vat) : CurrencyJS(0))
        .add(resellerOperatingCost ? CurrencyJS(resellerOperatingCost.vat) : CurrencyJS(0))
        .add(resellerAdminCost ? CurrencyJS(resellerAdminCost.vat) : CurrencyJS(0))

      resultResellerCharges.operationCosts = resellerOperatingCost ? resellerOperatingCost.amount : 0
      resultResellerCharges.adminCosts = resellerAdminCost ? resellerAdminCost.amount : 0
      resultResellerCharges.total = CurrencyJS(resultResellerCharges.operationCosts).add(
        CurrencyJS(resultResellerCharges.adminCosts)
      ).value
    }

    const providerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeProvider)
    if (providerCharges) {
      const providerVAT = providerCharges
        .filter((c: AccommodationFareCharge) => c.type === ChargeTypeRoomTax)
        .map((c: AccommodationFareCharge) => c.vat)
        .reduce((value, vat) => value + vat, 0)
      const providerCommission = providerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      commission = commission.add(providerCommission ? CurrencyJS(providerCommission.amount) : CurrencyJS(0))
      roomVAT = roomVAT.add(CurrencyJS(providerVAT))
    }
  }

  const additionalAgencyFee = getAdditionalAgencyFee(fees)
  const additionalResellerFee = getAdditionalResellerFee(fees)

  const additionalAgencyFeeCurrency = CurrencyJS(additionalAgencyFee)
  const additionalResellerFeeCurrency = CurrencyJS(additionalResellerFee)

  resultAgencyCharges.additionalFee =
    additionalAgencyFeeCurrency.value > 0 ? additionalAgencyFeeCurrency.value : undefined
  resultResellerCharges.additionalFee =
    additionalResellerFeeCurrency.value > 0 ? additionalResellerFeeCurrency.value : undefined

  resultAgencyCharges.total = CurrencyJS(resultAgencyCharges.total).add(additionalAgencyFeeCurrency).value
  resultResellerCharges.total = CurrencyJS(resultResellerCharges.total).add(additionalResellerFeeCurrency).value

  const paisTax = getPAISTax(fees)

  const rg4815Tax = getRG4815Tax(fees)

  const rg5272Tax = getRG5272Tax(fees)

  const totalFeeCharges = additionalAgencyFeeCurrency.add(additionalResellerFeeCurrency).value

  const totalTaxes = getTotalTaxes(taxes)

  const fareTaxes = {
    agencyVAT: agencyVAT.value > 0 ? agencyVAT.value : undefined,
    resellerVAT: resellerVAT.value > 0 ? resellerVAT.value : undefined,
    roomVAT: roomVAT.value,
    pais: paisTax > 0 ? paisTax : undefined,
    rg4815: rg4815Tax > 0 ? rg4815Tax : undefined,
    rg5272: rg5272Tax > 0 ? rg5272Tax : undefined,
    taxes: taxes,
    total: agencyVAT
      .add(resellerVAT)
      .add(roomVAT)
      .add(totalTaxes)
      .add(paisTax)
      .add(rg4815Tax)
      .add(rg5272Tax).value
  } as Taxes

  //TODO: Fee vat

  const totalCharges = CurrencyJS(resultAgencyCharges.total).add(CurrencyJS(resultResellerCharges.total))

  const baseRate = CurrencyJS(net_rate)
    .add(CurrencyJS(commission))
    .add(cededCommission)
    .add(markup).value

  let discountTotal: number | undefined = undefined

  if (discount?.discount) {
    discountTotal = total / ((100 - discount.discount) / 100)
  }

  return {
    total: total,
    currency: currency,
    baseRate: baseRate,
    extraCost: CurrencyJS(fareTaxes.total).add(totalCharges).value,
    rooms: rooms,
    guests: guests,
    taxes: fareTaxes,
    charges: {
      resellerCharge: resultResellerCharges.total > 0 ? resultResellerCharges : undefined,
      agencyCharge: resultAgencyCharges.total > 0 ? resultAgencyCharges : undefined,
      totalFeeCharges: totalFeeCharges > 0 ? totalFeeCharges : undefined,
      total: totalCharges.value
    },
    ...(discountTotal !== undefined && { discountTotal })
  } as PriceBreakdown
}

export const getPAISTax = (fees: AccommodationFee[] | undefined): number => {
  if (fees) {
    return fees
      .filter(f => f.type === FeeTypePAIS)
      .map(f => f.amount)
      .reduce((v, t) => t + v, 0)
  }

  return 0
}

export const getRG4815Tax = (fees: AccommodationFee[] | undefined): number => {
  if (fees) {
    return fees
      .filter(f => f.type === FeeTypeRG4815)
      .map(f => f.amount)
      .reduce((v, t) => t + v, 0)
  }

  return 0
}

export const getRG5272Tax = (fees: AccommodationFee[] | undefined): number => {
  if (fees) {
    return fees
      .filter(f => f.type === FeeTypeRG5272)
      .map(f => f.amount)
      .reduce((v, t) => t + v, 0)
  }

  return 0
}

export const getAdditionalAgencyFee = (fees: AccommodationFee[] | undefined): number => {
  if (fees) {
    return fees
      .filter(f => f.type === FeeTypeAdditional && f.collector === CollectorTypeAgency)
      .map(f => f.amount)
      .reduce((v, t) => t + v, 0)
  }
  return 0
}

export const getAdditionalResellerFee = (fees: AccommodationFee[] | undefined): number => {
  if (fees) {
    return fees
      .filter(f => f.type === FeeTypeAdditional && f.collector === CollectorTypeReseller)
      .map(f => f.amount)
      .reduce((v, t) => t + v, 0)
  }
  return 0
}

export const getTotalTaxes = (taxes?: Tax[]): number => {
  if (!taxes) {
    return 0
  }

  const total = taxes?.reduce((acc, tax) => {
    return acc.add(CurrencyJS(tax.amount))
  }, new CurrencyJS(0))

  return total ? total.value : 0
}

export const convertAccommodationFareToResellerPriceBreakdown = (
  accommodationFare: AccommodationFare,
  rooms: number,
  guests: number
): PriceBreakdown => {
  const { net_rate, currency, charges, fees, taxes } = accommodationFare

  let agencyVAT = CurrencyJS(0)
  let resellerVAT = CurrencyJS(0)
  let roomVAT = CurrencyJS(0)
  let commission = CurrencyJS(0)
  let cededCommission = CurrencyJS(0)
  let resultResellerCharges: Charge = {
    markup: 0,
    operationCosts: 0,
    adminCosts: 0,
    total: 0,
    additionalFee: 0
  }
  if (charges) {
    const agencyCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeAgency)
    if (agencyCharges) {
      const resellerCededCommission = agencyCharges.find(
        (c: AccommodationFareCharge) => c.type === ChargeTypeCommission
      )

      cededCommission = resellerCededCommission ? CurrencyJS(resellerCededCommission.amount) : CurrencyJS(0)

      agencyVAT = agencyVAT.add(resellerCededCommission ? CurrencyJS(resellerCededCommission.vat) : CurrencyJS(0))
    }

    const resellerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeReseller)
    if (resellerCharges) {
      const resellerMarkup = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const resellerOperatingCost = resellerCharges.find(
        (c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost
      )
      const resellerAdminCost = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeAdminCost)

      resellerVAT = resellerVAT
        .add(resellerMarkup ? CurrencyJS(resellerMarkup.vat) : CurrencyJS(0))
        .add(resellerOperatingCost ? CurrencyJS(resellerOperatingCost.vat) : CurrencyJS(0))
        .add(resellerAdminCost ? CurrencyJS(resellerAdminCost.vat) : CurrencyJS(0))

      resultResellerCharges.operationCosts = resellerOperatingCost ? resellerOperatingCost.amount : 0
      resultResellerCharges.adminCosts = resellerAdminCost ? resellerAdminCost.amount : 0
      resultResellerCharges.total = CurrencyJS(resultResellerCharges.operationCosts).add(
        CurrencyJS(resultResellerCharges.adminCosts)
      ).value
    }

    const providerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeProvider)
    if (providerCharges) {
      const providerVAT = providerCharges
        .filter((c: AccommodationFareCharge) => c.type === ChargeTypeRoomTax)
        .map((c: AccommodationFareCharge) => c.vat)
        .reduce((value, vat) => value + vat, 0)
      const providerCommission = providerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      commission = commission.add(providerCommission ? CurrencyJS(providerCommission.amount) : CurrencyJS(0))
      roomVAT = roomVAT.add(CurrencyJS(providerVAT))
    }
  }

  const additionalResellerFee = getAdditionalResellerFee(fees)
  const additionalResellerFeeCurrency = CurrencyJS(additionalResellerFee)

  resultResellerCharges.additionalFee =
    additionalResellerFeeCurrency.value > 0 ? additionalResellerFeeCurrency.value : undefined
  resultResellerCharges.total = CurrencyJS(resultResellerCharges.total).add(additionalResellerFeeCurrency).value

  const paisTax = getPAISTax(fees)

  const rg4815Tax = getRG4815Tax(fees)

  const rg5272Tax = getRG5272Tax(fees)

  let totalTaxes = getTotalTaxes(taxes)

  const fareTaxes = {
    agencyVAT: agencyVAT.value > 0 ? agencyVAT.value : undefined,
    resellerVAT: resellerVAT.value,
    roomVAT: roomVAT.value,
    pais: paisTax > 0 ? paisTax : undefined,
    rg4815: rg4815Tax > 0 ? rg4815Tax : undefined,
    rg5272: rg5272Tax > 0 ? rg5272Tax : undefined,
    taxes: taxes,
    total: agencyVAT
      .add(resellerVAT)
      .add(roomVAT)
      .add(totalTaxes)
      .add(paisTax)
      .add(rg4815Tax)
      .add(rg5272Tax).value
  } as Taxes

  //TODO: Fee vat

  const totalCharges = CurrencyJS(resultResellerCharges.total)

  return {
    total: CurrencyJS(net_rate)
      .add(CurrencyJS(resultResellerCharges.total))
      .add(CurrencyJS(fareTaxes.total))
      .add(commission)
      .add(cededCommission).value,
    currency: currency,
    baseRate: CurrencyJS(net_rate)
      .add(commission)
      .add(cededCommission).value,
    extraCost: CurrencyJS(fareTaxes.total).add(totalCharges).value,
    rooms: rooms,
    guests: guests,
    taxes: fareTaxes,
    charges: {
      resellerCharge: resultResellerCharges,
      totalFeeCharges: resultResellerCharges.additionalFee,
      total: totalCharges.value
    }
  } as PriceBreakdown
}

const calculateAdjustment = (fare: AccommodationFare): number => {
  let priceAdjustment = 0

  if (fare.fees) {
    priceAdjustment = fare.fees
      .filter(f => f.type === USER_PRICE_ADJUSTMENT)
      .map(f => f.amount)
      .reduce((a, v) => a + v, 0)
  }

  return priceAdjustment
}

export const convertToAgencyCostEffectivenessBreakdown = (
  accommodationFare: AccommodationFare
): CostEffectivenessBreakdown => {
  const { currency, charges, fees } = accommodationFare

  let resellerCededComission = CurrencyJS(0)
  let commission = CurrencyJS(0)
  const priceAdjustment = calculateAdjustment(accommodationFare)

  let ownCostEffectiveness: CostEffectiveness = {
    total: 0,
    markup: 0,
    operatingCost: 0,
    adminCost: 0,
    additionalFee: 0,
    adjustment: priceAdjustment
  }
  if (charges) {
    const agencyCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeAgency)
    if (agencyCharges) {
      const agencyMarkup = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const agencyOperatingCost = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost)
      const agencyCededCommission = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      resellerCededComission = resellerCededComission.add(
        agencyCededCommission ? CurrencyJS(agencyCededCommission.amount) : CurrencyJS(0)
      )

      ownCostEffectiveness.markup = agencyMarkup ? agencyMarkup.amount : 0
      ownCostEffectiveness.operatingCost = agencyOperatingCost ? agencyOperatingCost.amount : 0
      ownCostEffectiveness.total = CurrencyJS(ownCostEffectiveness.total)
        .add(ownCostEffectiveness.markup)
        .add(CurrencyJS(ownCostEffectiveness.operatingCost)).value
    }

    const providerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeProvider)
    if (providerCharges) {
      const providerCommission = providerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      commission = commission.add(providerCommission ? CurrencyJS(providerCommission.amount) : CurrencyJS(0))
    }
  }

  const additionalAgencyFee = getAdditionalAgencyFee(fees)
  const additionalAgencyFeeCurrency = CurrencyJS(additionalAgencyFee)

  ownCostEffectiveness.additionalFee =
    additionalAgencyFeeCurrency.value > 0 ? additionalAgencyFeeCurrency.value : undefined
  ownCostEffectiveness.total = CurrencyJS(ownCostEffectiveness.total).add(additionalAgencyFeeCurrency).value

  return {
    ownCostEffectiveness: ownCostEffectiveness,
    commission: commission.value > 0 ? commission.value : undefined,
    currency: currency,
    total: CurrencyJS(ownCostEffectiveness.total).add(resellerCededComission).value,
    resellerCeded: resellerCededComission.value > 0 ? resellerCededComission.value : undefined
  }
}

export const convertToResellerCostEffectivenessBreakdown = (
  accommodationFare: AccommodationFare
): CostEffectivenessBreakdown => {
  const { currency, charges, fees } = accommodationFare

  let resellerCededCommission = CurrencyJS(0)
  let commission = CurrencyJS(0)
  let ownCostEffectiveness: CostEffectiveness = {
    total: 0,
    markup: 0,
    operatingCost: 0,
    additionalFee: 0,
    adminCost: 0
  }
  if (charges) {
    const resellerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeReseller)
    if (resellerCharges) {
      const resellerMarkup = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const resellerOperatingCost = resellerCharges.find(
        (c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost
      )

      ownCostEffectiveness.markup = resellerMarkup ? resellerMarkup.amount : 0
      ownCostEffectiveness.operatingCost = resellerOperatingCost ? resellerOperatingCost.amount : 0
      ownCostEffectiveness.total = CurrencyJS(ownCostEffectiveness.markup).add(
        CurrencyJS(ownCostEffectiveness.operatingCost)
      ).value
    }

    const agencyCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeAgency)
    if (agencyCharges) {
      const agencyCededCommission = agencyCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      resellerCededCommission = resellerCededCommission.add(
        agencyCededCommission ? CurrencyJS(agencyCededCommission.amount) : CurrencyJS(0)
      )

      ownCostEffectiveness.markup = CurrencyJS(ownCostEffectiveness.markup).add(resellerCededCommission).value
      ownCostEffectiveness.total = CurrencyJS(ownCostEffectiveness.total).add(CurrencyJS(resellerCededCommission)).value
    }

    const providerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeProvider)
    if (providerCharges) {
      const providerCommission = providerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      commission = commission.add(providerCommission ? CurrencyJS(providerCommission.amount) : CurrencyJS(0))
    }
  }

  const additionalResellerFee = getAdditionalResellerFee(fees)
  const additionalResellerFeeCurrency = CurrencyJS(additionalResellerFee)

  ownCostEffectiveness.additionalFee =
    additionalResellerFeeCurrency.value > 0 ? additionalResellerFeeCurrency.value : undefined
  ownCostEffectiveness.total = CurrencyJS(ownCostEffectiveness.total).add(additionalResellerFeeCurrency).value

  return {
    ownCostEffectiveness: ownCostEffectiveness,
    commission: commission.value > 0 ? commission.value : undefined,
    currency: currency,
    total: CurrencyJS(ownCostEffectiveness.total)
      .subtract(ownCostEffectiveness.adminCost)
      .subtract(resellerCededCommission).value,
    resellerCeded: resellerCededCommission.value > 0 ? resellerCededCommission.value : undefined
  }
}

export const calculateAgencyPayment = (accommodationFare: AccommodationFare): CostPayment => {
  const { net_rate, charges, currency, fees } = accommodationFare

  let resellerVAT = CurrencyJS(0)
  let commission = CurrencyJS(0)
  let roomTax = CurrencyJS(0)
  let resultResellerCharges: Charge = {
    markup: 0,
    operationCosts: 0,
    adminCosts: 0,
    additionalFee: 0,
    total: 0
  }

  if (charges) {
    const resellerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeReseller)
    if (resellerCharges) {
      const resellerMarkup = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeMarkup)
      const resellerOperatingCost = resellerCharges.find(
        (c: AccommodationFareCharge) => c.type === ChargeTypeOperatingCost
      )
      const resellerAdminCost = resellerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeAdminCost)

      resellerVAT = resellerVAT
        .add(resellerMarkup ? CurrencyJS(resellerMarkup.vat) : CurrencyJS(0))
        .add(resellerOperatingCost ? CurrencyJS(resellerOperatingCost.vat) : CurrencyJS(0))
        .add(resellerAdminCost ? CurrencyJS(resellerAdminCost.vat) : CurrencyJS(0))

      resultResellerCharges.operationCosts = resellerOperatingCost ? resellerOperatingCost.amount : 0
      resultResellerCharges.adminCosts = resellerAdminCost ? resellerAdminCost.amount : 0
      resultResellerCharges.total = CurrencyJS(resultResellerCharges.operationCosts).add(
        CurrencyJS(resultResellerCharges.adminCosts)
      ).value
    }

    const providerCharges = charges.filter((c: AccommodationFareCharge) => c.collector === CollectorTypeProvider)
    if (providerCharges) {
      const providerCommission = providerCharges.find((c: AccommodationFareCharge) => c.type === ChargeTypeCommission)

      commission = commission.add(providerCommission ? CurrencyJS(providerCommission.amount) : CurrencyJS(0))

      const providerVAT = providerCharges
        .filter((c: AccommodationFareCharge) => c.type === ChargeTypeRoomTax)
        .map((c: AccommodationFareCharge) => c.vat)
        .reduce((value, vat) => value + vat, 0)

      roomTax = roomTax.add(CurrencyJS(providerVAT))
    }
  }

  const additionalResellerFee = getAdditionalResellerFee(fees)
  const additionalResellerFeeCurrency = CurrencyJS(additionalResellerFee)

  resultResellerCharges.additionalFee =
    additionalResellerFeeCurrency.value > 0 ? additionalResellerFeeCurrency.value : undefined
  resultResellerCharges.total = CurrencyJS(resultResellerCharges.total).add(additionalResellerFeeCurrency).value

  return {
    currency: currency,
    total: CurrencyJS(net_rate)
      .add(commission)
      .add(roomTax)
      .add(resellerVAT)
      .add(CurrencyJS(resultResellerCharges.total)).value,
    fare: 0,
    taxesAndCharges: 0,
    commision: 0
  }
}

export const calculateResellerPayment = (accommodationFare: AccommodationFare): CostPayment => {
  const { base_rate, currency } = accommodationFare
  let roomTax = CurrencyJS(0)

  if (accommodationFare.charges) {
    const providerCharges = accommodationFare.charges.filter(
      (c: AccommodationFareCharge) => c.collector === CollectorTypeProvider
    )

    if (providerCharges) {
      const providerVAT = providerCharges
        .filter((c: AccommodationFareCharge) => c.type === ChargeTypeRoomTax)
        .map((c: AccommodationFareCharge) => c.vat)
        .reduce((value, vat) => value + vat, 0)
      roomTax = roomTax.add(CurrencyJS(providerVAT))
    }
  }

  return {
    currency: currency,
    total: CurrencyJS(base_rate).add(roomTax).value,
    fare: 0,
    taxesAndCharges: 0,
    commision: 0
  }
}

export const mapAccommodationFareToAnotherCurrency = (
  fare: AccommodationFare,
  newCurrency: string,
  newRatio: number
) => {
  fare.currency = newCurrency
  fare.base_rate *= newRatio
  fare.net_rate *= newRatio
  fare.nightly_basis *= newRatio
  if (fare.discount) {
    fare.discount.base_rate *= newRatio
    fare.discount.nightly_basis *= newRatio
    fare.discount.total *= newRatio
  }
  if (fare.charges?.length) {
    fare.charges = (fare.charges as AccommodationFareCharge[]).map((charge: AccommodationFareCharge) => ({
      ...charge,
      amount: charge.amount * newRatio,
      vat: charge.vat * newRatio,
      currency: newCurrency
    })) as AccommodationFareCharge[]
  }

  if (newCurrency === "USD") {
    fare.taxes = []
  }

  if (fare.taxes?.length) {
    fare.taxes = (fare.taxes as Tax[]).map((t: Tax) => {
      fare.total += t.amount
      const amount: number = t.amount * newRatio
      return {
        type: t.type,
        amount: amount,
        collector: t.collector,
        currency: t.currency
      }
    }) as Tax[]
  }

  if (fare.fees?.length) {
    const newFees: AccommodationFee[] = []
    fare.fees.forEach((fee: AccommodationFee) => {
      if (newCurrency === "ARS") {
        newFees.push(fee)
      } else {
        if (fee.type === "PAIS" || fee.type.startsWith("RG")) {
          fare.total -= fee.amount
        } else {
          newFees.push(fee)
        }
      }
    })
    fare.fees = (newFees as AccommodationFee[]).map((fee: AccommodationFee) => {
      if (fee.type === "USER_PRICE_ADJUSTMENT") {
        return {
          ...fee,
          amount: fee.amount * newRatio,
          currency: newCurrency
        }
      }
      return {
        ...fee,
        amount: fee.amount * newRatio,
        currency: newCurrency
      }
    }) as AccommodationFee[]
  }
  fare.total *= newRatio
}
