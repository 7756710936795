import React, { useEffect } from "react"
import { FlightType } from "../../model/types"
import TimePicker from "./TimePicker"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"
import moment from "moment"
import Button from "@material-ui/core/Button"
import styles from "./TimePickerFilter.styles"
import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"
import FlightsFiltersCollapsePanel from "../FlightsFiltersCollapsePanel"

export interface Props {
  initialDepartureHours?: Hours
  initialReturningHours?: Hours
  collapsible?: boolean
  flightType: FlightType
  onApply: (departure: Hours, returning?: Hours) => void
}

export type Hours = {
  from: Date
  to: Date
}

const DATE_FORMAT = "HH:mm"
const DEFAULT_HOURS: Hours = {
  from: moment("00:00", DATE_FORMAT).toDate(),
  to: moment("23:59", DATE_FORMAT).toDate()
}

const TimePickerFilter: React.FC<Props> = ({
  initialDepartureHours,
  initialReturningHours,
  flightType,
  collapsible,
  onApply
}) => {
  const [departureHours, setDepartureHours] = React.useState<Hours>(DEFAULT_HOURS)
  const [returningHours, setReturningHours] = React.useState<Hours>(DEFAULT_HOURS)
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  useEffect(() => {
    if (initialDepartureHours) setDepartureHours(initialDepartureHours)
    if (initialReturningHours) setReturningHours(initialReturningHours)
  }, [initialDepartureHours, initialReturningHours])

  const handleDepartureChange = (from: Date, to: Date) => {
    setDepartureHours({ from: from, to: to })
  }

  const handleReturningChange = (from: Date, to: Date) => {
    setReturningHours({ from: from, to: to })
  }

  const handleOnClick = () => {
    let returning = undefined
    if (flightType === "ROUND_TRIP") {
      returning = returningHours
    }
    onApply(departureHours, returning)
  }

  const component = (
    <div className={styles.root}>
      <div className={styles.section(collapsible || false)}>
        <TimePicker
          from={departureHours.from}
          to={departureHours.to}
          title={!collapsible ? t("TimePickerFilter.departureTime") : ""}
          subtitle={collapsible ? t("TimePickerFilter.departureTime") : ""}
          onChange={handleDepartureChange}
          id="time-picker-departure-filter-slider"
        />
      </div>
      {flightType === "ROUND_TRIP" && (
        <>
          <Divider />
          <div className={styles.section(collapsible || false)}>
            <TimePicker
              from={returningHours.from}
              to={returningHours.to}
              title={!collapsible ? t("TimePickerFilter.returningTime") : ""}
              subtitle={collapsible ? t("TimePickerFilter.returningTime") : ""}
              onChange={handleReturningChange}
              id="time-picker-returning-filter-slider"
            />
          </div>
        </>
      )}
      <Button id="time-picker-apply-filter-btn" onClick={handleOnClick} className={styles.button(theme)}>
        {t("TimePickerFilter.applyFilter")}
      </Button>
    </div>
  )

  return !collapsible ? (
    component
  ) : (
    <FlightsFiltersCollapsePanel
      renderSummary={<>{t("TimePickerFilter.time")}</>}
      renderDetails={<div className={styles.container}>{component}</div>}
    />
  )
}

export default TimePickerFilter
