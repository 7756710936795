import React from "react"
import { Cluster as ClusterModel } from "../../model/types"
import styles from "./CheckoutBaggageAllowance.styles"
import CheckoutBaggageIcon from "../../icons/CheckoutBaggageIcon"
import { useTheme } from "@basset-la/themed-components"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Segment from "./Segment"

export interface Props {
  cluster: ClusterModel
  selectedBrand?: number
}

const CheckoutBaggageAllowance: React.FC<Props> = ({ cluster, selectedBrand }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const segments = cluster.segments

  const checkBaggage = (): boolean => {
    for (let i = 0; i < segments.length; i++) {
      const checkedBaggages = segments[i].options[0].baggage_allowances?.filter((b: any) => {
        return b.type === "CHECKED_BAGGAGE"
      })

      if (!checkedBaggages || checkedBaggages.length === 0) {
        return false
      }
    }

    return true
  }

  return (
    <div className={styles.root}>
      <div className={styles.messageContainer}>
        <CheckoutBaggageIcon color={theme.colors.brand_primary} />

        <Text className={styles.baggageMessage(theme.colors.brand_primary)} size={16}>
          {checkBaggage() ? t("BaggageAllowance.included") : t("BaggageAllowance.notIncluded")}
        </Text>
      </div>

      <div className={styles.segmentsContainers}>
        {segments.map((_, idx) => {
          return <Segment cluster={cluster} segmentIdx={idx} selectedBrand={selectedBrand} key={`segment_${idx}`} />
        })}
      </div>
    </div>
  )
}
export default CheckoutBaggageAllowance
