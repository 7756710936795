import React from "react"

import { useTranslation } from "react-i18next"

import { I18N_NS } from "../../utils/constants"
import Tooltip from "@material-ui/core/Tooltip/Tooltip"

import Icon from "../../icons/Icon"
import { MultipleAirlinesIcon } from "../../icons/MultipleAirlineIcon"
import { Airline } from "../../model"

interface DatesMatrixCellAirlineIconProps {
  airlines: Airline[] | undefined | null
}

const DatesMatrixCellAirlineIcon: React.FC<DatesMatrixCellAirlineIconProps> = ({
  airlines
}: DatesMatrixCellAirlineIconProps) => {
  const { t } = useTranslation(I18N_NS)

  if (!airlines || airlines.length === 0) {
    return null
  }
  if (airlines.length > 1) {
    return (
      <Tooltip title={t("DatesMatrixCellAirlineIcon.multipleAirlines") as string}>
        <MultipleAirlinesIcon />
      </Tooltip>
    )
  }
  return (
    <Tooltip title={airlines[0].name}>
      <Icon icon={airlines[0].code}></Icon>
    </Tooltip>
  )
}

export default DatesMatrixCellAirlineIcon
