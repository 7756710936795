import { InstallmentsBank } from "@basset-la/components-products/dist/components/Installments"
import { InstallmentsData } from "@basset-la/components-products/dist/components/Installments/Installments"
import { FormOfPayment } from "../../model/types"

interface TmpMap {
  free: Record<string, Record<string, string[]>>
  fixed: Record<string, Record<string, string[]>>
}

function createDataMap(tmpMap: TmpMap, type: "free" | "fixed"): Record<string, InstallmentsBank[]> {
  const dataMap: Record<string, InstallmentsBank[]> = {}
  for (const installment in tmpMap[type]) {
    if (!dataMap[installment]) {
      dataMap[installment] = []
    }

    for (const bank in tmpMap[type][installment]) {
      dataMap[installment].push({
        id: bank,
        creditCards: tmpMap[type][installment][bank]
      })
    }
  }
  return dataMap
}

export function getInstallmentsDataMap(formOfPayments: FormOfPayment[]): [InstallmentsData, number] {
  const tmpMap: TmpMap = {
    free: {},
    fixed: {}
  }
  let max = 0

  for (const fp of formOfPayments) {
    for (const i of fp.installments) {
      if (i > max) max = i

      const type = fp.coefficient === 1 ? "free" : "fixed"

      if (!tmpMap[type][i.toString()]) {
        tmpMap[type][i.toString()] = { [fp.bank]: [fp.card_description] }
      } else {
        if (!tmpMap[type][i.toString()][fp.bank]) {
          tmpMap[type][i.toString()][fp.bank] = [fp.card_description]
        } else {
          if (!tmpMap[type][i.toString()][fp.bank].includes(fp.card_description)) {
            tmpMap[type][i.toString()][fp.bank].push(fp.card_description)
          }
        }
      }
    }
  }

  return [
    {
      free: createDataMap(tmpMap, "free"),
      fixed: createDataMap(tmpMap, "fixed")
    },
    max
  ]
}
