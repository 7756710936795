import React from "react"
import MuiLinearProgress from "@material-ui/core/LinearProgress"
import { useTheme } from "@basset-la/themed-components"
import styles from "./LinearProgress.styles"

const LinearProgress: React.FC = () => {
  const theme = useTheme()

  return <MuiLinearProgress className={styles.root(theme)} />
}

export default LinearProgress
