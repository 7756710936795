import React from "react"
import { Policy } from "../../model/types"
import styles from "./PolicySection.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { TFunction } from "i18next"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"

interface Props {
  type: "BEFORE" | "AFTER"
  adults?: Policy
  infants?: Policy
  children?: Policy
  disabled?: Policy
  senior?: Policy
  currency: string
}

const passangerMessage = (pass: Policy, label: string, currency: string, t: TFunction, locale: string) => {
  let msg = ""
  if (pass.amount > 0) {
    msg = formatPrice(pass.amount, locale, currency)
  } else {
    msg = t("PolicySection.percentage", { percentage: pass.percentage })
  }

  return (
    <span className={styles.psgLabel}>
      {pass.valid && (
        <>
          <span>{label}</span>
          <span> - {msg}</span>
        </>
      )}
      {!pass.valid && <>{t("PolicySection.notValid")}</>}
    </span>
  )
}

const PolicySection: React.FC<Props> = ({ type, currency, adults, infants, children, disabled, senior }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  let available =
    (adults && adults.valid) ||
    (infants && infants.valid) ||
    (children && children.valid) ||
    (disabled && disabled.valid) ||
    (senior && senior.valid)
  return (
    <div className={styles.root(theme, available)}>
      <p>{t(`PolicySection.${type}`)}</p>
      <div>
        {adults && passangerMessage(adults, t("PolicySection.adults"), currency, t, locale)}
        {infants && passangerMessage(infants, t("PolicySection.infants"), currency, t, locale)}
        {children && passangerMessage(children, t("PolicySection.children"), currency, t, locale)}
        {disabled && passangerMessage(disabled, t("PolicySection.disabled"), currency, t, locale)}
        {senior && passangerMessage(senior, t("PolicySection.senior"), currency, t, locale)}
      </div>
    </div>
  )
}

export default PolicySection
