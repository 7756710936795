import React from "react"

export const FastFoodIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#FFF"
      strokeWidth=".7"
      d="M12.035 3.33341H14.635C15.0283 3.33341 15.335 3.67341 15.295 4.06675L14.2683 14.3534C14.2017 14.9001 13.7417 15.3334 13.1817 15.3334H12.0483L12.035 10.0001C12.035 7.18008 9.96833 5.44008 7.51499 4.87341L7.43499 4.06675C7.40166 3.67341 7.70833 3.33341 8.10166 3.33341H10.7017V1.33341C10.7017 0.966748 11.0017 0.666748 11.3683 0.666748C11.735 0.666748 12.035 0.966748 12.035 1.33341V3.33341ZM10.035 14.0001C10.4017 14.0001 10.7017 14.3001 10.7017 14.6667C10.7017 15.0334 10.4017 15.3334 10.035 15.3334H1.36833C1.00166 15.3334 0.70166 15.0334 0.70166 14.6667C0.70166 14.3001 1.00166 14.0001 1.36833 14.0001H10.035ZM9.96833 10.0001C10.3817 10.0001 10.7083 9.62675 10.6283 9.22675C10.195 7.07341 7.94833 5.99341 5.70166 5.99341C3.45499 5.99341 1.21499 7.07341 0.774994 9.22675C0.694994 9.62675 1.02833 10.0001 1.43499 10.0001H9.96833ZM0.70166 12.0001C0.70166 11.6334 1.00166 11.3334 1.36833 11.3334H10.035C10.4017 11.3334 10.7017 11.6334 10.7017 12.0001C10.7017 12.3667 10.4017 12.6667 10.035 12.6667H1.36833C1.00166 12.6667 0.70166 12.3667 0.70166 12.0001Z"
    />
  </svg>
)
