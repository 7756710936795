import React from "react"
import { AccommodationFare } from "../../model"
import AccommodationDesktopPrice from "../AccommodationDesktopPrice"
import { convertToAgencyCostEffectivenessBreakdown } from "../../utils/helpers/helpersV2"
import styles from "./DesktopDeetailClusterPrice.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import CostEffectivenessDetail, {
  AdjustType
} from "@basset-la/components-products/dist/components/CostEffectivenessDetail"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"

type LoadingTrueProps = {
  loading: true
  nightsCount?: number
  guestsCount?: number
  roomsCount?: number
  accommodationFare?: AccommodationFare
}

type LoadingFalseProps = {
  loading?: false
  nightsCount: number
  guestsCount: number
  roomsCount: number
  accommodationFare: AccommodationFare
}

export type Props = {
  onAdjust: (v: number, t: AdjustType) => void
} & (LoadingTrueProps | LoadingFalseProps)

const DesktopDetailClusterPrice: React.FC<Props> = ({
  loading = false,
  nightsCount,
  guestsCount,
  accommodationFare,
  roomsCount,
  onAdjust
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  if (loading) {
    return (
      <div className={styles.root}>
        <div className={styles.breakdownContainer}>
          <AccommodationDesktopPrice loading />
        </div>
        <Skeleton className={styles.skeleton} variant="rect" height="100px" width="100%" />
      </div>
    )
  }

  const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(accommodationFare!)

  const { adjustment = 0 } = costEffectivenessBreakdown.ownCostEffectiveness

  return (
    <div className={styles.root}>
      <div className={styles.breakdownContainer}>
        <AccommodationDesktopPrice
          nightsCount={nightsCount!}
          guestsCount={guestsCount!}
          roomsCount={roomsCount!}
          accommodationFare={accommodationFare!}
        />
        <span className={styles.taxesIncluded(theme)}>{t("DesktopDetailClusterPrice.taxesIncluded")}</span>
      </div>
      <div>
        <CostEffectivenessDetail
          total={costEffectivenessBreakdown.total}
          currency={costEffectivenessBreakdown.currency}
          adjustValue={adjustment}
          adjustType={adjustment >= 0 ? "ADD" : "SUB"}
          onAdjust={onAdjust}
        />
      </div>
    </div>
  )
}

export default DesktopDetailClusterPrice
