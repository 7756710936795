import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  info: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "14px",
        width: "14px",
        height: "14px",
        color: t.colors.brand_primary
      }
    }),
  infoContainer: css({
    flex: "1 !important"
  }),
  rootTooltip: css({
    backgroundColor: "transparent !important"
  }),
  tooltip: (t: Theme) =>
    css({
      position: "relative",
      padding: "8px !important",
      backgroundColor: `${t.colors.background_primary} !important`,
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
    }),
  arrow: css({
    position: "absolute",
    fontSize: 6,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  }),
  popper: (t: Theme) =>
    css({
      backgroundColor: "transparent !important",
      '&[x-placement*="bottom"] > div > .arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.95em",
        width: "2em",
        height: "1em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${t.colors.background_primary} transparent`
        }
      },
      '&[x-placement*="top"] > div > .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.95em",
        width: "2em",
        height: "1em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${t.colors.background_primary} transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] > div > .arrow': {
        left: 0,
        marginLeft: "-0.95em",
        height: "2em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${t.colors.background_primary} transparent transparent`
        }
      },
      '&[x-placement*="left"] > div > .arrow': {
        right: 0,
        marginRight: "-0.95em",
        height: "2em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${t.colors.background_primary}`
        }
      }
    })
}
