import React from "react"

const InsurancesLoaderIcon = () => {
  return (
    <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6662 9.5L25.3329 15.8333V22.1667H15.8329C12.1912 22.1667 9.87955 25.3333 9.49955 28.5L6.33288 60.1667C5.95288 63.3333 8.04288 66.5 12.6662 66.5H63.3329C67.9562 66.5 70.0462 63.3333 69.6662 60.1667L66.4995 28.5C66.1195 25.3333 63.5229 22.1667 60.1662 22.1667H50.6662V15.8333L44.3329 9.5H31.6662ZM31.6662 15.8333H44.3329V22.1667H31.6662V15.8333ZM34.8329 31.6667H41.1662V41.1667H50.6662V47.5H41.1662V57H34.8329V47.5H25.3329V41.1667H34.8329V31.6667Z"
        fill="white"
      />
    </svg>
  )
}

export default InsurancesLoaderIcon
