import { useTheme } from "@basset-la/themed-components"
import React, { useState } from "react"
import styles from "./FlightBrand.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"
import { mapChargeType, MergedBrand } from "../../utils/helpers/brands"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import BrandServiceIcon from "../BrandServiceIcon"
import BrandService from "../BrandService"

export interface Props {
  brand: MergedBrand
  showAllServices: boolean
  isSingleCategory: boolean
}

const FlightBrand: React.FC<Props> = ({ brand, showAllServices, isSingleCategory }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [openAllServices, setOpenAllServices] = useState(false)

  const mappedServiceTypes = [
    brand.mappedServices.handOnBaggageState.type,
    brand.mappedServices.carryOnBaggageState.type,
    brand.mappedServices.baggageState.type,
    brand.mappedServices.seatState.type,
    brand.mappedServices.refundsState.type,
    brand.mappedServices.mealsState.type,
    brand.mappedServices.changesState.type,
    brand.mappedServices.mileageState.type,
    brand.mappedServices.internetState.type
  ]
  const notMappedServices = brand.services
    .filter(s => mappedServiceTypes.find(t => t === s.type) == null)
    .sort((s1, s2) => (s1.type < s2.type ? -1 : 1))

  const handleOpenAllServices = () => {
    setOpenAllServices(true)
  }

  const handleCloseAllServices = () => {
    setOpenAllServices(false)
  }

  const getTitle = () => {
    const title = brand.name

    if (!isSingleCategory) {
      return title
    }

    return `${title} ${t("ClusterBrands.singleCategory")}`
  }

  return (
    <div className={styles.root(isSingleCategory)}>
      <div className={styles.title(theme)}>{getTitle()}</div>

      <div className={styles.content}>
        {brand.mappedServices.handOnBaggageState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.handOnBaggageState.type}
              state={brand.mappedServices.handOnBaggageState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.handOnBaggage.${brand.mappedServices.handOnBaggageState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.carryOnBaggageState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.carryOnBaggageState.type}
              state={brand.mappedServices.carryOnBaggageState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.carryOnBaggage.${brand.mappedServices.carryOnBaggageState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.baggageState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.baggageState.type}
              state={brand.mappedServices.baggageState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.baggage.${brand.mappedServices.baggageState.state}`, {
                count: brand.mappedServices.baggageState.count
              })}
            </Text>
          </div>
        )}
        {brand.mappedServices.seatState.show && (
          <div className={styles.item}>
            <BrandServiceIcon type={brand.mappedServices.seatState.type} state={brand.mappedServices.seatState.state} />
            <Text size={14} variant="regular">
              {t(`FlightBrand.seat.${brand.mappedServices.seatState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.changesState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.changesState.type}
              state={brand.mappedServices.changesState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.changes.${brand.mappedServices.changesState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.refundsState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.refundsState.type}
              state={brand.mappedServices.refundsState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.refunds.${brand.mappedServices.refundsState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.mealsState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.mealsState.type}
              state={brand.mappedServices.mealsState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.meals.${brand.mappedServices.mealsState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.mileageState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.mileageState.type}
              state={brand.mappedServices.mileageState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.mileage.${brand.mappedServices.mileageState.state}`)}
            </Text>
          </div>
        )}
        {brand.mappedServices.internetState.show && (
          <div className={styles.item}>
            <BrandServiceIcon
              type={brand.mappedServices.internetState.type}
              state={brand.mappedServices.internetState.state}
            />
            <Text size={14} variant="regular">
              {t(`FlightBrand.internet.${brand.mappedServices.internetState.state}`)}
            </Text>
          </div>
        )}

        {showAllServices && !openAllServices && notMappedServices.length > 0 && (
          <div className={styles.buttonContainer} onClick={handleOpenAllServices}>
            <AddCircleOutlineIcon className={styles.buttonIcon(theme)} />
            <Text className={styles.buttonText(theme)} size={14} variant="regular">
              {t("FlightBrand.showMore")}
            </Text>
          </div>
        )}
        {showAllServices && openAllServices && (
          <>
            {notMappedServices.map((s, idx) => {
              return (
                <BrandService
                  key={`service_${idx}`}
                  type={s.type}
                  state={mapChargeType(s.charge_type)}
                  showBorder
                  fontSize={14}
                />
              )
            })}
            <div className={styles.buttonContainer} onClick={handleCloseAllServices}>
              <RemoveCircleOutlineIcon className={styles.buttonIcon(theme)} />
              <Text className={styles.buttonText(theme)} size={14} variant="regular">
                {t("FlightBrand.showLess")}
              </Text>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default FlightBrand
