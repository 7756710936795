import React from "react"
import { SegmentOption } from "../../model/types"
import { formatDuration, getBaggageConfiguration } from "../../utils/helpers"
import BaggagesWithTooltip from "../BaggagesWithTooltip/BaggagesWithTooltip"
import SegmentOptionBrief from "../SegmentOptionBrief/SegmentOptionBrief"
import styles from "./SegmentOptionSummary.styles"
import { useTheme } from "@basset-la/themed-components"
import { Price } from "../../model/types"
import InfoIconWithPolicyTooltip from "../InfoIconWithPolicyTooltip"
import LastPlaces from "../LastPlaces"

export interface Props {
  segmentOption: SegmentOption
  /**
   * set to `true` to force mobile L&F
   * @param {boolean} mini
   * @default false
   */
  mini?: boolean
  /**
   * set to `true` to force
   * @param {boolean} max
   * @default false
   */
  max?: boolean
  /**
   * set to `true` to highlight flight duration
   * @param {boolean} highlightDuration
   * @default false
   */
  highlightDuration?: boolean
  price?: Price
}

const SegmentOptionSummary: React.FC<Props> = ({ segmentOption, mini, max, highlightDuration, price }) => {
  const duration = formatDuration(segmentOption.duration)
  const lastPlaces = Math.min(...segmentOption.legs.map(l => l.cabin_type.quantity))
  const theme = useTheme()

  const { bagIncluded, baggagesAmount, baggagesWeight, carryOnIncluded } = getBaggageConfiguration(
    segmentOption.baggage_allowances
  )

  const hasPolicyInfo =
    (price && price.adults && price.adults.exchange_policy) ||
    (price && price.infants && price.infants.exchange_policy) ||
    (price && price.children && price.children.exchange_policy) ||
    (price && price.seniors && price.seniors.exchange_policy) ||
    (price && price.disabled && price.disabled.exchange_policy)

  return (
    <div className={styles.root(theme, mini, max)}>
      <div className={styles.brief(mini, max)}>
        <SegmentOptionBrief segmentOption={segmentOption} mini={mini} />
      </div>
      <div className={styles.durationBaggage(mini, max)}>
        <div className={styles.durationContainer(mini, max)}>
          <p
            id="segment-option-summary-total-hours"
            className={`${highlightDuration && styles.durationHighlighted} ${styles.duration}`}
          >
            {duration}
          </p>
        </div>
        <div className={styles.baggagesLastPlaces(mini)}>
          <div className={styles.baggagesContainer(max)}>
            <BaggagesWithTooltip
              bagIncluded={bagIncluded}
              baggageAmount={baggagesAmount}
              carryOnIncluded={carryOnIncluded}
              baggageWeight={baggagesWeight}
            />
          </div>
          <div className={styles.lastPlaceContainer(mini, max)}>
            <LastPlaces variant="tooltip" places={lastPlaces} />
          </div>
          <div className={styles.infoContainer}>
            {hasPolicyInfo && <InfoIconWithPolicyTooltip price={price as Price} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SegmentOptionSummary
