import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "480px"
  }),
  mainContent: css({
    display: "flex",
    borderRadius: "4px",
    flexDirection: "column",
    backgroundColor: colors.LIGHTEST_GREEN,
    fontFamily: "Roboto",
    color: colors.GREEN3,
    boxSizing: "border-box",
    padding: "8px 0px"
  }),
  titleContainer: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "space-between",
    padding: "0px 8px"
  }),
  title: css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5"
  }),
  table: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    padding: "0px 8px"
  }),
  tableRow: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    fontSize: "12px",
    lineHeight: "1.33",
    marginBottom: "8px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  totalRow: css({
    display: "flex",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "8px"
  }),
  adjustRow: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    " .MuiSvgIcon-root": {
      width: "12px",
      height: "12px"
    }
  }),
  buttonContainer: css({
    width: "100%",
    padding: "0 8px",
    boxSizing: "border-box",
    marginTop: "8px"
  }),
  iconButton: css({
    "&.MuiIconButton-root": {
      padding: 0,
      width: "24px",
      height: "24px",
      " .MuiSvgIcon-root": {
        fontSize: "16px",
        color: colors.GREEN3
      }
    }
  }),
  showIconButton: css({
    "&.MuiIconButton-root": {
      padding: 0,
      width: "24px",
      height: "24px",
      " .MuiSvgIcon-root": {
        fontSize: "16px",
        color: colors.GREEN3
      }
    }
  }),
  showButtonContainer: (t: Theme) =>
    css({
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      backgroundColor: t.colors.background_primary,
      alignSelf: "flex-end"
    }),
  accordion: css({
    "&.MuiAccordion-root": {
      width: "100%",
      backgroundColor: colors.LIGHTEST_GREEN,
      boxShadow: "unset",
      borderBottom: `1px solid ${colors.GREEN3}`
    }
  }),
  accordionSummary: css({
    "&.MuiAccordionSummary-root": {
      padding: "0px",
      minHeight: "0px",
      " .MuiAccordionSummary-content": {
        margin: "0px"
      }
    },
    "&.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "0px"
    }
  }),
  accordionDetails: css({
    "&.MuiAccordionDetails-root": {
      padding: "0px 0px 16px"
    }
  })
}
