import React from "react"
import { Leg } from "../../model/types"
import LegDetail from "./LegDetail"
import LegDetailSection from "./LegDetailSection"
import { colors, I18N_NS } from "../../utils/constants/constants"
import { calculateStopDuration, sanitizeAirportName } from "../../utils/helpers"
import styles from "./LegStopDetail.styles"
import { AlertIcon } from "../../icons/AlertIcon"
import { StopIcon } from "../../icons/StopIcon"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  leg: Leg
  nextLeg: Leg | undefined

  /** set to `true` to force mobile L&F */
  mini?: boolean
}

const LegStopDetail: React.FunctionComponent<Props> = ({ leg, nextLeg, mini = false }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  return (
    <div className={styles.root}>
      <LegDetail mini={mini} leg={leg} />
      {nextLeg && (
        <div>
          <div className={styles.stop(theme)}>
            <LegDetailSection
              mini={mini}
              backgroundColor={colors.LIGHTEST_BLUE}
              icon={<StopIcon />}
              firstSection={
                <p>
                  {t("LegStopDetail.stopIn", { stop: sanitizeAirportName(leg.destination.name) })}{" "}
                  <span className={styles.bold}>{`(${leg.destination.code})`}</span>
                </p>
              }
              secondSection={<p className={styles.stopDuration(mini)}>{calculateStopDuration(leg, nextLeg)}</p>}
              thirdSection={<div />}
              fourthSection={<div />}
            />
          </div>
          {leg.destination.code !== nextLeg.origin.code && (
            <div>
              <LegDetailSection
                mini={mini}
                backgroundColor={colors.LIGHT_YELLOW}
                className={styles.airportChangeContainer}
                icon={<AlertIcon />}
                firstSection={
                  <div className={styles.airportChange}>
                    <p className={styles.airportChangeTitle}>{t("LegStopDetail.airportChangeTitle")}</p>
                    <p className={styles.airportChangeFromTo(theme)}>
                      {t("LegStopDetail.changeAirportFromTo", {
                        from: `${sanitizeAirportName(leg.destination.name)} (${leg.destination.code})`,
                        to: `${sanitizeAirportName(nextLeg.origin.name)} (${nextLeg.origin.code})`
                      })}
                    </p>
                    <p className={styles.airportChangeDisclamer(theme)}>{t("LegStopDetail.changeAirportDisclamer")}</p>
                  </div>
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LegStopDetail
