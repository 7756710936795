import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: t.colors.background_darker,
      color: t.colors.text_primary,
      fontFamily: "Roboto"
    }),
  detail: css({
    display: "flex",
    flexDirection: "column",
    padding: "16px 24px",
    flex: 3
  }),
  price: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      justifyContent: "space-between",
      flex: 1,
      borderLeft: `1px solid ${t.colors.background_primary}`
    }),
  originDest: css({
    fontSize: "10px",
    lineHeight: "1.6"
  }),
  icon: (theme: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "14px",
        width: "14px",
        height: "14px",
        color: theme.colors.brand_primary
      }
    }),
  dateContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 0
  }),
  date: css({
    fontSize: "10px",
    lineHeight: "1.6",
    marginLeft: "8px"
  }),
  passengers: css({
    fontSize: "10px",
    lineHeight: "1.6",
    marginLeft: "8px"
  }),
  datePassContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap"
  }),
  passengersContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "56px"
  }),
  total: css({
    fontSize: "18px",
    fontWeight: 500,
    letterSpacing: "0.19px",
    textAlign: "right",
    lineHeight: "1.33"
  }),
  currency: css({
    fontSize: "12px",
    letterSpacing: "0.13px"
  }),
  totalLabel: css({
    fontSize: "10px",
    fontWeight: 300,
    lineHeight: "1.6",
    letterSpacing: "0.11px",
    textAlign: "right"
  }),
  title: css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5",
    letterSpacing: "0.17px"
  })
}
