import * as React from "react"
import styles from "./SortingOptions.styles"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@basset-la/components-commons/dist/components/Radio"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Select from "@basset-la/components-commons/dist/components/Select"
import { AvailableSortingOptions } from "./types"
import { FullScreenDialog } from "@basset-la/components-products"

export interface Props {
  isMobile: boolean
  openDialog: boolean
  onCloseDialog: () => void
  availableSortingOptions: AvailableSortingOptions
  appliedSortingOption: string
  onFilterChange: (fk: string, afs: string[]) => void
}

const SortingOptions: React.FC<Props> = ({
  isMobile,
  openDialog,
  onCloseDialog,
  availableSortingOptions,
  appliedSortingOption,
  onFilterChange
}) => {
  const { t } = useTranslation(I18N_NS)

  const handleOptionChange = (value: string) => {
    if (appliedSortingOption !== value) onFilterChange("order_by", [value])
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  if (isMobile) {
    return (
      <FullScreenDialog open={openDialog} onClose={handleCloseDialog}>
        <RadioGroup className={styles.radioGroup}>
          {availableSortingOptions.options.map((option, i) => (
            <div className={styles.radio}>
              <Radio
                key={i}
                label={t(`SortingOptions.sorting.${option}`)}
                checked={option === appliedSortingOption}
                onCheck={() => handleOptionChange(option)}
              />
            </div>
          ))}
        </RadioGroup>
      </FullScreenDialog>
    )
  }

  return (
    <Select
      variant="item"
      values={availableSortingOptions.options.map(option => ({
        code: option,
        name: t(`SortingOptions.sorting.${option}`)
      }))}
      selected={[appliedSortingOption]}
      onSelect={values => handleOptionChange(values[0])}
      id="sorting-options-select-filter"
      menuItemId="sorting-options-select-filter-menu-item"
    />
  )
}

export default SortingOptions
