import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto"
    }),
  title: (t: Theme) =>
    css({
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      borderBottom: `dashed 0.5px ${t.colors.background_darker}`,
      margin: "8px 0",
      marginTop: "0",
      padding: "8px 0",
      color: t.colors.text_primary
    }),
  rowsContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  row: (t: Theme) =>
    css({
      marginBottom: "8px",
      display: "flex",
      flexDirection: "row",
      ":last-child": {
        marginBottom: "0px"
      },
      "> svg": {
        width: "16px",
        height: "16px"
      },
      "> div > p": {
        margin: "auto 0",
        marginLeft: "8px",
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: 1.33,
        letterSpacing: "0.13px",
        color: t.colors.text_primary
      }
    }),
  disclamer: (t: Theme) =>
    css({
      color: `${t.colors.text_variant} !important`
    }),
  highlight: (theme: Theme) =>
    css({
      color: `${theme.colors.brand_darker} !important`
    })
}
