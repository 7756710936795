import React from "react"
import CheckboxFilter, { CheckboxFilterOption } from "@basset-la/components-products/dist/components/CheckboxFilter"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import i18n from "i18next"
import styles from "./StopsFilter.styles"
import FlightsFiltersCollapsePanel from "../FlightsFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  options: Option[]
  onChange: (value: number, checked: boolean) => void
  onDone?: () => void
}

export type Option = {
  stops: number
  selected?: boolean
}

const StopsFilter: React.FC<Props> = ({ options, collapsible, onChange, onDone }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root}>
      {!collapsible ? (
        <>
          <CheckboxFilter
            onChange={onChange}
            title={t("StopsFilter.title")}
            maxItems={options.length}
            options={mapToCheckboxOptions(options, t)}
            onDone={onDone}
            id={{
              checkbox: "stops-filter-checkbox"
            }}
          />
        </>
      ) : (
        <>
          <FlightsFiltersCollapsePanel
            renderSummary={<>{t("StopsFilter.title")}</>}
            renderDetails={
              <div className={styles.container}>
                <CheckboxFilter
                  onChange={onChange}
                  maxItems={options.length}
                  options={mapToCheckboxOptions(options, t)}
                  onDone={onDone}
                  id={{
                    checkbox: "stops-filter-checkbox"
                  }}
                />
              </div>
            }
          />
        </>
      )}
    </div>
  )
}

const mapToCheckboxOptions = (options: Option[], t: i18n.TFunction): CheckboxFilterOption[] => {
  return options.map(o => ({
    title: o.stops === 0 ? t("StopsFilter.nonStop") : t("StopsFilter.stops", { count: o.stops }),
    value: o.stops,
    selected: o.selected
  }))
}

export default StopsFilter
