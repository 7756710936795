import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    fontFamily: "Roboto",
    fontStretch: "normal",
    fontStyle: "normal",
    margin: 0,
    padding: 0
  }),
  10: css({
    fontSize: "10px",
    lineHeight: 1.6,
    letterSpacing: 0.11
  }),
  12: css({
    fontSize: "12px",
    lineHeight: 1.33,
    letterSpacing: 0.13
  }),
  14: css({
    fontSize: "14px",
    lineHeight: 1.14,
    letterSpacing: 0.15
  }),
  16: css({
    fontSize: "16px",
    lineHeight: 1.5,
    letterSpacing: 0.17
  }),
  18: css({
    fontSize: "18px",
    lineHeight: 1.33,
    letterSpacing: 0.19
  }),
  24: css({
    fontSize: "24px",
    lineHeight: 1.33,
    letterSpacing: 0.25
  }),
  32: css({
    fontSize: "32px",
    lineHeight: 1.25,
    letterSpacing: 0.34
  }),
  bold: css({
    fontWeight: 500
  }),
  regular: css({
    fontWeight: 400
  }),
  light: css({
    fontWeight: 300
  }),
  primary: (theme: Theme) =>
    css({
      color: theme.colors.text_primary
    }),
  variant: (theme: Theme) =>
    css({
      color: theme.colors.text_variant
    }),
  white: (theme: Theme) =>
    css({
      color: theme.colors.text_white
    }),
  error: (theme: Theme) =>
    css({
      color: theme.colors.error
    })
}
