import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import styles from "./ClusterButtonWeb.styles"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  onSelectItinerary: React.MouseEventHandler<HTMLButtonElement>
}

const ClusterButtonWeb: React.FC<Props> = ({ onSelectItinerary }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <Button variant="contained" className={styles.buyButton(theme)} onClick={onSelectItinerary}>
      {t("ClusterCombinedPriceWeb.select")}
    </Button>
  )
}

export default ClusterButtonWeb
