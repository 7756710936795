import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  root: css({
    display: "flex",
    flexFlow: "column",
    padding: "8px"
  }),

  container: css({
    display: "flex",
    flexFlow: "row",
    flexWrap: "wrap",
    paddingTop: "8px"
  }),

  chip: (theme: Theme) =>
    css({
      margin: "0 6px 8px 0 !important",
      border: "1px solid " + theme.brand_colors.click_color + " !important",
      backgroundColor: "transparent !important",
      maxWidth: "100%"
    }),

  iconChip: (theme: Theme) =>
    css({
      color: theme.brand_colors.click_color + " !important"
    }),

  labelChip: (theme: Theme) =>
    css({
      fill: theme.brand_colors.click_color + " !important",
      color: theme.brand_colors.click_color + " !important"
    })
}

export default styles
