import React from "react"
import styles from "./PriceboxBreakdownVoucherDetail.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import SaveAltRounded from "@material-ui/icons/SaveAltRounded"
import RefreshRounded from "@material-ui/icons/RefreshRounded"
import Tooltip from "@material-ui/core/Tooltip"
import { ReservationProduct } from "./types"

export interface Props {
  reservationProducts?: ReservationProduct
  isVoucherBlocked: (reservationProducts?: ReservationProduct) => boolean
  onGenerateVoucher?: () => void
}
const PriceboxBreakdownVoucherDetail: React.FC<Props> = ({
  reservationProducts,
  isVoucherBlocked,
  onGenerateVoucher
}) => {
  const { t } = useTranslation(I18N_NS)

  const getFileNameFromUrl = (url: string): string => {
    const segments = url.split("/")
    const lastSegment = segments.pop()

    return lastSegment || ""
  }

  if (!reservationProducts) return <></>

  if (!onGenerateVoucher) return <></>

  if (!isVoucherBlocked(reservationProducts)) return <></>

  if (!reservationProducts?.voucher_url)
    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <div className={styles.textContainer}>{t("PriceboxBreakdownVoucherDetail.title")}</div>
          <div className={styles.innerContainer}>
            <div className={styles.text}></div>
            <div className={styles.actionContainer}>
              <Tooltip title={<>{t("PriceboxBreakdownVoucherDetail.refresh")}</>} placement="top">
                <RefreshRounded className={styles.refresh} onClick={() => onGenerateVoucher()} />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.textContainer}>{t("PriceboxBreakdownVoucherDetail.title")}</div>
        <div className={styles.innerContainer}>
          <div className={styles.text}>
            <div className={styles.text}>{getFileNameFromUrl(reservationProducts.voucher_url)}</div>
          </div>
          <div className={styles.actionContainer}>
            <Tooltip title={<>{t("PriceboxBreakdownVoucherDetail.refresh")}</>} placement="top">
              <RefreshRounded className={styles.refresh} onClick={() => onGenerateVoucher()} />
            </Tooltip>
            <Tooltip title={<>{t("PriceboxBreakdownVoucherDetail.download")}</>} placement="top">
              <a href={reservationProducts.voucher_url} target="_blank" rel="noopener noreferrer">
                <SaveAltRounded />
              </a>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PriceboxBreakdownVoucherDetail
