import React from "react"
import { CancelPenalty } from "../../model"
import moment from "moment"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./CancelPenalties.styles"

interface Props {
  cancelPenalties: CancelPenalty[]
  refundable: boolean
  expirationDate?: string
}

const CancelPenalties: React.FC<Props> = ({ cancelPenalties, refundable, expirationDate }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  if (!refundable) {
    return <span className={styles.nonRefundable(theme)}>{t("RoomBrief.CancelPenalties.nonRefundable")}</span>
  }

  return (
    <span className={styles.refundable(theme)}>
      {expirationDate
        ? t("RoomBrief.CancelPenalties.refundable", {
            date: moment(expirationDate).format("DD/MM/YYYY"),
            time: moment(expirationDate).format("hh:mm A")
          })
        : t("RoomBrief.CancelPenalties.refundable", {
            date: moment(cancelPenalties[0].start).format("DD/MM/YYYY"),
            time: moment(cancelPenalties[0].start).format("hh:mm A")
          })}
    </span>
  )
}

export default CancelPenalties
