import React from "react"

const FlightsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="98" viewBox="0 0 98 98">
      <defs>
        <path id="a" d="M0 0h98v98H0z" />
      </defs>
      <g fill="none" fillRule="nonzero">
        <g transform="rotate(-44 49 49)">
          <mask id="b" fill="#fff">
            <use />
          </mask>
          <path
            fill="#00A5F4"
            d="M14.352 14.352c19.135-19.137 50.16-19.137 69.297 0 19.134 19.135 19.134 50.16 0 69.297-19.137 19.135-50.162 19.135-69.297 0-19.137-19.137-19.137-50.162 0-69.297"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#FFF"
          d="M35.91 39.456c-1.451-1.502.74-5.994 2.241-7.444l4.655-4.495c1.502-1.45 3.2-.714 4.65.788 1.451 1.501 2.128 3.225.626 4.675l-4.655 4.495c-1.502 1.45-6.067 3.483-7.518 1.98M58.08 62.415c1.451 1.502 6.017-.53 7.519-1.98l4.654-4.496c1.502-1.45.828-3.171-.625-4.675-1.45-1.502-3.147-2.236-4.65-.788l-4.655 4.495c-1.502 1.45-3.693 5.942-2.242 7.444"
        />
        <path
          fill="#FFF"
          d="M31.82 43.405c-1.45-1.502.74-5.994 2.242-7.444l4.654-4.495c1.502-1.45 3.2-.714 4.651.788 1.45 1.502 2.127 3.225.625 4.675l-4.654 4.495c-1.502 1.45-6.068 3.483-7.518 1.981M53.991 66.364c1.452 1.504 6.016-.53 7.518-1.98l4.655-4.495c1.502-1.45.825-3.174-.625-4.676-1.45-1.502-3.15-2.238-4.651-.788l-4.655 4.495c-1.5 1.452-3.692 5.942-2.242 7.444"
        />
        <path
          fill="#E6E8E9"
          d="M46.346 51.562L18.31 30.027c-1.103-.903-.518-1.892.508-2.883l.294-.284a2.384 2.384 0 0 1 2.51-.512L60.77 37.633l9.914 39.52a2.389 2.389 0 0 1-.6 2.49l-.293.284c-1.027.991-2.037 1.54-2.901.406l-20.544-28.77zM26.592 71.16l-11.99-9.035c-.492-.403-.23-.845.224-1.284l.133-.129c1.103-1.064 2.263-1.458 3.77-1.285 5.17.589 10.321 1.707 15.452 2.572l1.617.27-9.206 8.89z"
        />
        <path
          fill="#E6E8E9"
          d="M26.071 70.62l8.61 12.298c.387.506.836.261 1.294-.181l.13-.127c1.103-1.064 1.54-2.208 1.417-3.721-.408-5.188-1.343-10.374-2.03-15.532-.072-.542-.142-1.084-.215-1.627l-9.206 8.89z"
        />
        <path
          fill="#FFF"
          d="M21.253 75.794a.687.687 0 0 1-.24-.162c-2.06-2.135 11.262-18.38 12.919-19.98L68.273 22.49c1.657-1.6 7.916-4.265 9.977-2.13.068.07.127.142.181.218.074.057.144.118.212.189 2.062 2.134-.82 8.296-2.477 9.896l-34.34 33.163c-1.657 1.6-18.36 14.35-20.419 12.213a.687.687 0 0 1-.154-.245"
        />
        <path
          fill="#0085C2"
          d="M74.85 28.032a4.86 4.86 0 0 0-3.99-4.132c-.902-.162-1.262-.413-.708-.948.545-.53 1.809-1.149 2.71-.987a4.86 4.86 0 0 1 3.99 4.132c.13.905-.533 2.147-1.08 2.675-.554.535-.794.165-.922-.74"
        />
      </g>
    </svg>
  )
}

export default FlightsIcon
