import { Theme } from "@basset-la/themed-components"
import { css, keyframes } from "@emotion/css"
import { CardMovement } from "./Carousel"

export const CARD_GAP = 24

const slidePrev = (width: number) =>
  keyframes({
    "0%": {
      transform: `translateX(${-width}px)`
    },
    "100%": {
      transform: "translateX(0)"
    }
  })

const slideNext = (width: number) =>
  keyframes({
    "0%": {
      transform: "translateX(0)"
    },
    "100%": {
      transform: `translateX(${-(width + CARD_GAP)}px)`
    }
  })

const styles = {
  root: (width: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
      //width: "100%",
      maxWidth: width
    }),
  prevButton: (top: number, left: number) =>
    css({
      display: "flex",
      alignItems: "center",
      position: "fixed",
      cursor: "pointer",
      zIndex: 100,
      padding: "8px 2px 8px 14px",
      background: "rgba(249, 249, 249, 0.8)",
      boxShadow: "0px 4px 8px rgba(74, 74, 74, 0.2)",
      borderRadius: "99px",
      left: `${left}px`,
      top: `${top}px`
    }),
  nextButton: (top: number, left: number) =>
    css({
      display: "flex",
      alignItems: "center",
      position: "fixed",
      cursor: "pointer",
      zIndex: 100,
      padding: "8px",
      background: "rgba(249, 249, 249, 0.8)",
      boxShadow: "0px 4px 8px rgba(74, 74, 74, 0.2)",
      borderRadius: "99px",
      left: `${left}px`,
      top: `${top}px`
    }),
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),
  content: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: `${CARD_GAP}px`,
    overflowX: "clip",
    paddingLeft: "1px",
    width: "100%"
  }),
  card: (movement: CardMovement, width: number, timeout: number) => {
    return css({
      animation:
        movement !== "no"
          ? movement === "next"
            ? `${slideNext(width)} ${timeout}ms`
            : `${slidePrev(width)} ${timeout}ms`
          : undefined
    })
  }
}

export default styles
