import CurrencySelector from "@basset-la/components-products/dist/components/CurrencySelector"
import ExchangeRateText from "@basset-la/components-products/dist/components/ExchangeRateText"
import * as React from "react"
import { Currency } from "../../model"

import { RoomCluster as RoomClusterType, RoomClusterRate } from "../../model/roomCluster"
import { getProviderName } from "../../utils/helpers/helpers"
import MobileRoomCluster from "./MobileRoomCluster"
import RateTotal from "./RateTotal"
import RoomCluster from "./RoomCluster"
import styles from "./RoomClusters.styles"
import RoomsHeader from "./RoomsHeader"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"

export interface Props {
  roomClusters: RoomClusterType[]
  totalGuests: number
  selectedRate: RoomClusterRate
  channel?: string
  id?: string
  showProvider?: boolean
  taxesDisclaimers?: string[]
  onRateSelection: (ri: string, trackingId?: string) => void
  onAdjustRate: (v: number) => void
  onRateSelect: (ci: number, ri: number) => void
  onAddToQuote?: () => void
  currencies?: string[]
  selectedCurrency?: string
  onSelectCurrency?: (newCurrency: string) => void
  currencySwitcher?: Currency
  customButtons?: CustomButtonText[]
}

class RoomClusters extends React.PureComponent<Props, any> {
  onRoomClusterSelect = () => {
    const { selectedRate } = this.props
    this.props.onRateSelection(selectedRate.id, selectedRate.tracking_id)
  }

  render() {
    const {
      channel,
      totalGuests,
      onRateSelection,
      onRateSelect,
      selectedRate,
      roomClusters,
      showProvider,
      taxesDisclaimers,
      onAdjustRate,
      onAddToQuote,
      currencies,
      onSelectCurrency,
      selectedCurrency,
      currencySwitcher,
      customButtons
    } = this.props

    const isMobile = window.innerWidth <= 425

    const { id } = this.props
    if (isMobile) {
      return (
        <div id={id}>
          <MobileRoomCluster
            totalGuests={totalGuests}
            roomClusters={roomClusters}
            onRateSelection={onRateSelection}
            taxesDisclaimers={taxesDisclaimers}
            customButtons={customButtons}
          />
        </div>
      )
    }

    return (
      <div className={styles.containerStyle} id={id}>
        <RoomsHeader top={0} bottomBoundary={"#roomClustersMainContainer"} />
        <div className={styles.mainContainerStyle} id={"roomClustersMainContainer"}>
          <div className={styles.roomClustersContainerStyle}>
            {roomClusters.map((e, i) => (
              <RoomCluster onSelect={onRateSelect} clusterIndex={i} key={i} rates={e.rates} rooms={e.rooms} />
            ))}
          </div>
          <div className={styles.totalContainer}>
            <div className={styles.currencyContainer}>
              {onSelectCurrency && (
                <CurrencySelector
                  containerClassName={styles.currencySelectorContainer}
                  selectedCurrency={selectedCurrency}
                  onSelectCurrency={onSelectCurrency}
                  currencies={currencies}
                />
              )}
              {!selectedRate.loading &&
                currencySwitcher &&
                currencySwitcher.currency !== currencySwitcher.originalCurrency.currency_code && (
                  <ExchangeRateText
                    currency={currencySwitcher.currency}
                    originalCurrency={currencySwitcher.originalCurrency}
                    direction="row"
                    containerClassName={styles.exchangeRateContainer}
                    labelClassName={styles.exchangeRateText}
                    infoClassName={styles.exchangeRateText}
                  />
                )}
            </div>
            {selectedRate && showProvider && selectedRate.provider && <p>{getProviderName(selectedRate.provider)}</p>}
            <RateTotal
              onSelect={this.onRoomClusterSelect}
              rate={selectedRate}
              totalGuests={totalGuests}
              top={48}
              bottomBoundary={"#roomClustersMainContainer"}
              channel={channel}
              taxesDisclaimers={taxesDisclaimers}
              onAdjust={onAdjustRate}
              onAddToQuote={onAddToQuote}
              customButtons={customButtons}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default RoomClusters
