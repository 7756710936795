import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const MileIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99999 9.6797L8.76665 11.353C9.27332 11.6597 9.89332 11.2064 9.75999 10.633L9.02665 7.48637L11.4733 5.36637C11.92 4.9797 11.68 4.24637 11.0933 4.1997L7.87332 3.92637L6.61332 0.953035C6.38665 0.413035 5.61332 0.413035 5.38665 0.953035L4.12665 3.9197L0.906654 4.19303C0.319987 4.2397 0.0799874 4.97303 0.526654 5.3597L2.97332 7.4797L2.23999 10.6264C2.10665 11.1997 2.72665 11.653 3.23332 11.3464L5.99999 9.6797Z"
      fill={color}
    />
  </svg>
)
