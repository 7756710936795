import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      display: "flex",
      flex: 1,
      border: `dashed 1px ${theme.colors.brand_primary}`,
      borderRadius: "2px",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      boxSizing: "border-box",
      paddingTop: "24px",
      paddingBottom: "24px",
      height: "149px"
    }),
  content: css({
    color: colors.LIGHTER_GREY,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "> p": {
      fontSize: "14px",
      fontWeight: 300,
      textAlign: "center"
    }
  })
}
