import * as React from "react"
import AnimatedBackgroundEffect from "@basset-la/components-commons/dist/components/AnimatedBackgroundEffect"
import styles from "./PricesMatrixPlaceholder.styles"
import { useTheme } from "@basset-la/themed-components"

const PricesMatrixPlaceholder = () => {
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div className={styles.content}>
        {Array.apply(null, Array(4)).map((x: any, i: number) => (
          <div key={i} style={{ display: "flex" }}>
            <AnimatedBackgroundEffect>
              <div className={styles.selectedItem} />
            </AnimatedBackgroundEffect>
            <div className={styles.item(theme)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default PricesMatrixPlaceholder
