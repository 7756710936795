import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  }),

  title: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontWeight: 300
    }),

  content: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px"
  }),

  item: css({
    flex: "1 1 auto"
  }),

  select: css({
    "& .MuiSelect-root": {
      minWidth: "104px !important",
      maxWidth: "192px !important"
    },
    "&.MuiTextField-root .MuiOutlinedInput-root": {
      height: "32px !important"
    }
  }),

  textField: (t: Theme, error: boolean) =>
    css({
      "&.MuiTextField-root .MuiOutlinedInput-root": {
        height: "32px",
        maxWidth: "192px !important"
      },
      "&.MuiTextField-root .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: error ? t.colors.error : "rgba(0, 0, 0, 0.23) !important"
      },
      "&.MuiInputBase-root": {
        fontWeight: 300
      },
      "&.MuiTextField-root .MuiInputLabel-root": {
        lineHeight: "4px"
      }
    }),

  errors: css({
    display: "flex",
    flexDirection: "column"
  }),

  error: (t: Theme) =>
    css({
      color: t.colors.error
    })
}
