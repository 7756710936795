import React from "react"

export const FreeBrunchIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      stroke="#FFF"
      strokeWidth=".7"
      d="M19.8495 10.1421C18.2595 11.7321 16.1095 12.2321 14.5795 11.5221L13.1095 12.9921L19.2895 19.1721C19.6795 19.5621 19.6795 20.1921 19.2895 20.5821C18.8995 20.9721 18.2695 20.9721 17.8795 20.5821L11.6995 14.4021L5.50947 20.5721C5.11947 20.9621 4.48947 20.9621 4.09947 20.5721C3.70947 20.1821 3.70947 19.5521 4.09947 19.1621L13.1595 10.1021C12.4495 8.57208 12.9495 6.42208 14.5395 4.83208C16.4595 2.92208 19.1995 2.56208 20.6595 4.02208C22.1295 5.49208 21.7595 8.23208 19.8495 10.1421ZM10.6295 10.5021L7.79947 13.3321L3.60947 9.15208C2.37947 7.91208 2.11947 6.08208 2.82947 4.59208C3.12947 3.97208 3.95947 3.84208 4.43947 4.32208L10.6295 10.5021Z"
    />
  </svg>
)
