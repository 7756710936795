import { css } from "@emotion/css"

export default {
  containerStyle: css({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.71",
    color: "#4a4a4a"
  }),
  rooms: css({
    width: "256px"
  }),
  options: css({
    width: "358px",
    padding: "0px 24px"
  }),
  perNight: css({
    width: "200px",
    textAlign: "center"
  }),
  choose: css({
    width: "88px",
    textAlign: "center"
  }),
  total: css({
    width: "280px",
    textAlign: "center"
  })
}
