import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiLinearProgress-root": {
        color: t.colors.brand_primary,
        width: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 9999
      },
      ".MuiLinearProgress-bar": {
        backgroundColor: t.colors.brand_primary
      }
    })
}
