import React from "react"
import DiscountLabel from "@basset-la/components-products/dist/components/DiscountLabel"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import styles from "./AccommodationClusterDesktopPriceBreakdown.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { AccommodationFare, Promotion } from "../../model"
import { convertToAgencyCostEffectivenessBreakdown } from "../../utils/helpers/helpersV2"
import AccommodationDesktopPrice from "../AccommodationDesktopPrice"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"

type LoadingTrueProps = {
  loading: true
  provider?: string
  nightsCount?: number
  guestsCount?: number
  roomsCount?: number
  accommodationFare?: AccommodationFare
}

type LoadingFalseProps = {
  loading?: false
  provider: string
  nightsCount: number
  guestsCount: number
  roomsCount: number
  accommodationFare: AccommodationFare
}

export type Props = { hideCostEffectiveness?: boolean; promotions?: Promotion[] } & (
  | LoadingTrueProps
  | LoadingFalseProps
)

const AccommodationClusterDesktopPriceBreakdown: React.FC<Props> = ({
  loading = false,
  provider,
  nightsCount,
  guestsCount,
  roomsCount,
  accommodationFare,
  hideCostEffectiveness = false,
  promotions
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  if (loading) {
    return (
      <div className={styles.root(theme)}>
        <span className={styles.providerName}>{provider}</span>
        <AccommodationDesktopPrice loading />
        <Skeleton className={styles.skeleton} variant="text" />
      </div>
    )
  }

  const { currency, discount } = accommodationFare!

  const costEffectivenessBreakdown = hideCostEffectiveness
    ? undefined
    : convertToAgencyCostEffectivenessBreakdown(accommodationFare!)

  return (
    <div className={styles.root(theme)}>
      <div className={styles.providerContainer}>
        <span className={styles.providerName}>{provider}</span>
        {discount && <DiscountLabel value={discount.discount} />}
      </div>
      <AccommodationDesktopPrice
        nightsCount={nightsCount!}
        guestsCount={guestsCount!}
        roomsCount={roomsCount!}
        accommodationFare={accommodationFare!}
      />
      {promotions?.map((promotion, index) => (
        <div key={index} className={styles.item}>
          <span className={styles.promotionName}>{promotion.name}</span>
        </div>
      ))}
      {!hideCostEffectiveness && costEffectivenessBreakdown && costEffectivenessBreakdown.total > 0 && (
        <div className={styles.costEffectiveness}>
          <span>{t("AccommodationClusterDesktop.costEffectiveness")}</span>
          <span>{formatPrice(costEffectivenessBreakdown.total, locale, currency)}</span>
        </div>
      )}
    </div>
  )
}

export default AccommodationClusterDesktopPriceBreakdown
