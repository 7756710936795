import React from "react"
import { css } from "@emotion/css"

interface Props {
  children: React.ReactNode
  styles?: string
}

const Row: React.FC<Props> = ({ children, styles }) => (
  <table className={`${ownStyles} ${styles}`}>
    <tr>{children}</tr>
  </table>
)

const ownStyles = css({
  margin: "auto",
  border: "none",
  borderCollapse: "collapse",
  width: "100%"
})

export default Row
