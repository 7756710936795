import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexFlow: "row wrap",
    width: "fit-content",
    "> svg": {
      width: "20px",
      height: "20px"
    }
  })
}
