import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  part: (t: Theme, highlight: boolean) =>
    css({
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "1.14",
      color: t.colors.text_primary,
      backgroundColor: highlight ? t.colors.background_darker : "transparent"
    }),
  optionTitle: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 300,
      lineHeight: "1.33",
      color: t.colors.text_variant,
      borderTop: `1px solid ${t.colors.background_darker}`,
      ":first-of-type": {
        borderTop: "none"
      },
      "> .MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.text_variant,
        marginRight: "8px"
      }
    }),
  helperText: (t: Theme) =>
    css({
      fontSize: "14px",
      color: t.colors.text_primary,
      fontWeight: 300,
      lineHeight: 1.33,
      fontFamily: "Roboto"
    })
}
