import React from "react"
import styles from "./Chip.styles"

import MaterialChip, { ChipProps } from "@material-ui/core/Chip"
import Cancel from "@material-ui/icons/Cancel"
import Error from "@material-ui/icons/Error"
import { useTheme } from "@basset-la/themed-components"

export interface Props extends ChipProps {
  error?: boolean
}

const Chip: React.FC<Props> = ({ className, error, ...rest }) => {
  const theme = useTheme()

  return (
    <MaterialChip
      {...rest}
      className={`${styles.root(theme)} ${className} ${error ? styles.errorChip : ""}`}
      deleteIcon={error ? <Error /> : <Cancel />}
    />
  )
}

export default Chip
