import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontFamily: "Roboto",
      letterSpacing: "0.11px",
      fontSize: "10px",
      fontWeight: 300,
      display: "flex",
      flexDirection: "column"
    }),
  mainTitle: (t: Theme) =>
    css({
      display: "flex",
      width: "100%",
      height: "100%",
      boxSizing: "border-box",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: "8px",
      position: "relative",
      backgroundColor: t.colors.background_variant
    }),
  mainTitleBackground: (t: Theme) =>
    css({
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: `linear-gradient(to bottom left, transparent calc(50% - 1px), ${t.colors.background_darker} calc(50% - 1px), ${t.colors.background_darker} 50%, transparent 50%)`
    }),
  from: css({
    width: "100%",
    textAlign: "right"
  }),
  to: css({
    width: "100%",
    textAlign: "left"
  }),
  table: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      padding: 0,
      margin: 0,
      tr: {
        padding: 0,
        margin: 0,
        verticalAlign: "middle"
      },
      "&, th": {
        border: `1px solid ${t.colors.background_darker}`,
        borderCollapse: "collapse",
        boxSizing: "border-box",
        fontWeight: 300,
        padding: 0,
        verticalAlign: "middle"
      },
      th: {
        width: "120px",
        height: "44px"
      },
      td: {
        width: "120px",
        height: "44px",
        border: `1px solid ${t.colors.background_darker}`,
        verticalAlign: "middle"
      }
    }),
  link: (t: Theme) =>
    css({
      "&.MuiLink-root": {
        color: t.colors.brand_primary,
        fontSize: "16px",
        lineHeight: "1.5",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
      }
    }),
  leftArrow: css({
    "&.MuiSvgIcon-root": {
      marginRight: "16px"
    }
  }),
  rightArrow: css({
    "&.MuiSvgIcon-root": {
      marginLeft: "16px"
    }
  }),
  week: css({
    fontSize: "10px",
    lineHeight: "1.6",
    letterSpacing: "0.11px",
    width: "fit-content"
  }),
  leftWeek: css({
    marginLeft: "40px"
  }),
  rightWeek: css({
    marginRight: "40px",
    textAlign: "right",
    alignSelf: "flex-end"
  }),
  weeksButtonsContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "8px"
  }),
  weekInfo: css({
    display: "flex",
    flexDirection: "column"
  })
}
