import React from "react"
import { DayPickerRangeController, FocusedInputShape } from "react-dates"
import { Moment } from "moment"

import { datepickerStyle } from "../datepickerStyle"

import AbstractDatePicker, { AbstractDatePickerProps, ColorProps } from "../AbstractDatePicker"

export interface Props extends AbstractDatePickerProps {
  selectedFromDate?: Moment | null
  selectedToDate?: Moment | null
  minimumNights?: number
  maximumNights?: number
  onDatesChange: (arg: { startDate: Moment | null; endDate: Moment | null }) => void
  onFocusChange: (focusedInput: FocusedInputShape | null) => void
  focusedInput: FocusedInputShape
  colors?: ColorProps
}

class RangeDatePicker extends AbstractDatePicker<Props> {
  static defaultProps = {
    ...AbstractDatePicker.defaultProps,
    selectedFromDate: null,
    selectedToDate: null
  }

  // Hackish way to access super arrow function
  // For more info: https://basarat.gitbooks.io/typescript/docs/arrow-functions.html#tip-arrow-functions-and-inheritance
  private superIsOutisdeRange = this.isOutsideRange

  isOutsideRange = (day: Moment): boolean => {
    const { maximumNights, focusedInput, selectedFromDate, selectedToDate, maxDate, minDate } = this.props

    const absDay = day.startOf("day")

    if (maximumNights) {
      if (focusedInput === "startDate") {
        const maximumDate = maxDate?.startOf("day")
        const minimumDate = minDate?.startOf("day")

        return absDay.isBefore(minimumDate) || absDay.isAfter(maximumDate)
      }
      if (focusedInput === "endDate") {
        const maximumDate = selectedFromDate
          ? selectedFromDate
              .clone()
              .add(maximumNights, "days")
              .startOf("day")
          : maxDate?.startOf("day")
        const minimumDate = selectedToDate
          ? selectedToDate
              .clone()
              .add(-maximumNights, "days")
              .startOf("day")
          : minDate?.startOf("day")

        return absDay.isBefore(minimumDate) || absDay.isAfter(maximumDate)
      }
    }

    return this.superIsOutisdeRange(day)
  }

  render() {
    const {
      onDatesChange,
      colors,
      onFocusChange,
      selectedFromDate,
      selectedToDate,
      focusedInput,
      minimumNights
    } = this.props

    return (
      <div className={`${datepickerStyle(colors)}`}>
        <DayPickerRangeController
          startDate={selectedFromDate!}
          endDate={selectedToDate!}
          focusedInput={focusedInput}
          minimumNights={minimumNights}
          numberOfMonths={this.getNumberOfMonths()}
          onFocusChange={onFocusChange}
          orientation={this.getOrientation()}
          isOutsideRange={this.isOutsideRange}
          onDatesChange={onDatesChange}
          hideKeyboardShortcutsPanel={true}
        />
      </div>
    )
  }
}

export default RangeDatePicker
