import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

const styles = {
  marker: (t: Theme, width: number, selected: boolean) =>
    css({
      padding: "16px 8px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      border: "solid 1px #ccc",
      borderRadius: "4px",
      width: `${width}px`,
      height: "24px",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      transform: "translate(-50%, -50%)",
      backgroundColor: selected ? t.colors.brand_primary : t.colors.background_primary,
      color: selected ? t.colors.background_primary : t.colors.text_primary,
      " > p": {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16px",
        margin: "0 auto",
        textAlign: "center"
      }
    })
}

export default styles
