import React from "react"
import styles from "./Alert.styles"
import MaterialAlert from "@material-ui/lab/Alert"
import IconError from "@material-ui/icons/ErrorRounded"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  children: React.ReactNode
  onClose: () => void
}

const Alert: React.FC<Props> = ({ children, onClose }) => {
  const theme = useTheme()

  return (
    <>
      <MaterialAlert
        className={`${styles.root} ${styles.outlined(theme)} ${styles.text(theme)}`}
        variant="outlined"
        color="warning"
        icon={<IconError className={styles.icon(theme)} />}
        onClose={onClose}
      >
        {children}
      </MaterialAlert>
    </>
  )
}

export default Alert
