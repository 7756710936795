import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiChip-root": {
        color: t.colors.text_primary
      },
      "& .MuiChip-deleteIcon": {
        color: t.colors.text_primary
      },
      "&.MuiChip-outlined": {
        color: t.colors.brand_primary,
        borderColor: t.colors.brand_primary
      },
      "&.MuiChip-outlined .MuiChip-deleteIcon": {
        color: t.colors.brand_primary
      }
    }),
  errorChip: css({
    "&.MuiChip-root": {
      backgroundColor: "hsla(353, 98%, 72%, 0.3) !important",
      color: "#D0021B !important"
    },
    "& .MuiChip-deleteIcon": {
      color: "rgba(208,02,27) !important"
    }
  })
}
