import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  chip: (theme: Theme) =>
    css({
      "&.MuiChip-root": {
        backgroundColor: theme.colors.background_primary,
        border: `1px solid ${theme.colors.background_darker}`,
        "> span > span": {
          fontSize: "16px",
          fontFamily: "Roboto",
          fontWeight: "normal",
          lineHeight: "1.5",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: theme.colors.text_primary
        },
        "> span > span > .MuiSvgIcon-root": {
          color: theme.colors.brand_primary
        },
        ":hover": {
          backgroundColor: theme.colors.background_darker
        },
        ":focus": {
          backgroundColor: theme.colors.background_primary
        },
        ":active": {
          backgroundColor: theme.colors.background_primary
        }
      }
    })
}
