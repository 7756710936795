import React from "react"
import { CancelPenalty, MealPlan as MealPlanType } from "../../model"
import styles from "./RoomBrief.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import CancelPenalties from "./CancelPenalties"

export interface Props {
  roomCount: number
  roomName: string
  mealPlan: MealPlanType
  cancelPenalties: CancelPenalty[]
  refundable: boolean
  expirationDate?: string
}

const RoomBrief: React.FC<Props> = ({ cancelPenalties, mealPlan, roomCount, refundable, roomName, expirationDate }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  return (
    <div className={styles.root(theme)}>
      <div className={styles.roomContainer}>
        <span>
          {roomCount} x {roomName}
        </span>
      </div>
      <div className={styles.amenitiesContainer}>
        <span>
          <span>• </span>
          <span className={styles.mealplan(mealPlan === "ROOM_ONLY")}>{t(`RoomBrief.mealPlan.${mealPlan}`)}</span>
        </span>
        <span>
          <span>• </span>
          <CancelPenalties cancelPenalties={cancelPenalties} refundable={refundable} expirationDate={expirationDate} />
        </span>
      </div>
    </div>
  )
}

export default RoomBrief
