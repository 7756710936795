import { useTheme } from "@basset-la/themed-components"
import * as React from "react"
import styles from "./AnimatedBackgroundEffect.styles"

export interface Props {
  baseColor?: string
  effectColor?: string
  children?: React.ReactNode
  style?: any
}

const AnimatedBackgroundEffect = ({ baseColor, effectColor, children, style }: Props) => {
  const theme = useTheme()

  return (
    <div
      className={styles.container(
        baseColor || theme.colors.background_variant,
        effectColor || theme.colors.background_darker,
        children !== undefined
      )}
      style={style}
    >
      {children}
    </div>
  )
}

export default AnimatedBackgroundEffect
