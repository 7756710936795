import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "inherit"
  }),
  section: (collapsible: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: !collapsible ? "0px 16px" : "0px",
      ":last-of-type": {
        borderBottom: "none"
      },
      "> div": {
        padding: "16px 0",
        backgroundColor: "inherit",
        ":last-of-type": {
          borderBottom: "none"
        }
      }
    })
}
