import React from "react"
import { Region } from "../../model/accommodationDetail"
import CheckRadioButton from "../CheckRadioButton/CheckRadioButton"
import styles from "./AccommodationPOISection.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  title: string
  checkColor: string
  regions: Region[]
}

const AccommodationPOISection: React.FC<Props> = ({ title, regions, checkColor }) => {
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div className={styles.titleContainer}>
        <CheckRadioButton backgroundColor={checkColor} checked />
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.regionsContainer}>
        {regions.map((e, i) => (
          <div key={`reg_${i}`} className={styles.region}>
            <span className={styles.regionName}>{e.name}</span>
            <span className={styles.distance}>{e.distance}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AccommodationPOISection
