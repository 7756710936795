import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  }),

  title: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontWeight: 300
    }),

  content: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px"
  }),

  item: css({
    flex: "1 1 auto"
  }),

  select: css({
    "& .MuiSelect-root": {
      minWidth: "104px !important"
    },
    "&.MuiTextField-root .MuiOutlinedInput-root": {
      height: "32px !important"
    },

    "& .MuiFormLabel-root[data-shrink='false']": {
      top: "-5px"
    }
  }),

  errors: css({
    display: "flex",
    flexDirection: "column"
  }),

  error: (t: Theme) =>
    css({
      color: t.colors.error
    })
}
