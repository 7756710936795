import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    "> svg": {
      width: "12px",
      height: "12px"
    }
  })
}
