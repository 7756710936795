import { css, keyframes } from "@emotion/css"

const placeHolderShimmer = keyframes({
  "0%": {
    backgroundPosition: "-468px 0"
  },
  "100%": {
    backgroundPosition: "468px 0"
  }
})

const styles = {
  button: css({
    borderRadius: "1px",
    height: "32px",
    width: "100%",
    margin: "16px 0",
    "@media(min-width:1024px)": {
      width: "168px"
    }
  }),

  animatedBg: css({
    animationDuration: "1s",
    animationFillMode: "forwards",
    animationIterationCount: "infinite",
    animationName: `${placeHolderShimmer}`,
    animationTimingFunction: "linear",
    background: "linear-gradient(to right, #dedede 8%, #ccc 18%, #dedede 33%)",
    backgroundSize: "800px 104px"
  }),

  loadingBar: css({
    height: "16px",
    borderRadius: "8px",
    width: "168px",
    margin: "8px 0"
  })
}

export default styles
