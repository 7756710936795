import React from "react"
import styles from "./Label.styles"
import { useTheme } from "@basset-la/themed-components"
import Text from "../Text"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"

export interface Props {
  label: string
  description: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Label: React.FC<Props> = ({ label, description, onClick }) => {
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <Text variant="light" size={14} color="variant" className={styles.label}>
        {label}:
      </Text>

      {onClick && (
        <div onClick={onClick} className={styles.onClick}>
          <Text size={14} className={styles.description}>
            {description}
          </Text>
          <KeyboardArrowDown className={styles.icon(theme)} />
        </div>
      )}

      {!onClick && (
        <Text size={14} className={styles.description}>
          {description}
        </Text>
      )}
    </div>
  )
}

export default Label
