import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"
import Person from "@material-ui/icons/Person"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import React from "react"
import { I18N_NS } from "../../utils/constants"
import styles from "./SearchboxMobileBar.styles"
import { SearchboxParams } from "./types"
import { useTranslation } from "react-i18next"

export interface Props {
  params: SearchboxParams
  onClick?: () => void
}

const SearchboxMobileBar: React.FC<Props> = ({ params, onClick }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleClick = () => {
    if (onClick) onClick()
  }

  const passengers =
    params.passengers.adults +
    params.passengers.children.length +
    params.passengers.disableds +
    params.passengers.seniors

  return (
    <div className={styles.root(theme)} onClick={handleClick}>
      <div className={styles.column}>
        {params.legs.length === 1 && (
          <>{`${params.legs[0].origin?.iata_code || ""} - ${params.legs[0].destination?.iata_code || ""}`}</>
        )}
        {params.legs.length > 1 && <>{t("FlightTypeSelect.multipleDestinations")}</>}
      </div>
      <div className={styles.divider}>
        <Divider orientation="vertical" />
      </div>
      <div className={styles.column}>
        {params.legs.length > 0 && (
          <>
            {params.legs[0].from
              ?.format("DD MMM")
              .substr(0, 6)
              .toUpperCase() || ""}
            {params.legs[params.legs.length - 1].to && " - "}
            {params.legs[params.legs.length - 1].to
              ?.format("DD MMM")
              .substr(0, 6)
              .toUpperCase() || ""}
          </>
        )}
      </div>
      <div className={styles.divider}>
        <Divider orientation="vertical" />
      </div>
      <div className={styles.column}>
        <div className={styles.passengers}>
          <Person className={styles.icon(theme)} />
          {passengers}
        </div>
        <KeyboardArrowDown className={styles.icon(theme)} />
      </div>
    </div>
  )
}

export default SearchboxMobileBar
