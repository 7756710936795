import React from "react"
import { Reservation } from "../../model/types"
import styles from "./ReservationDetails.styles"
import { I18N_NS } from "../../utils/constants/constants"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Divider } from "@material-ui/core"
import FlightDetail from "../FlightDetail"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import EventIcon from "@material-ui/icons/Event"
import PersonIcon from "@material-ui/icons/Person"
import InfoIcon from "@material-ui/icons/Info"
import { getProviderShortName } from "../../utils/helpers/helpers"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  reservation: Reservation
  logo: string
}

const ReservationDetails: React.FC<Props> = ({ reservation, logo }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const chd = reservation.passengers.filter(p => p.type === "CHD" || p.type === "INF").length
  const adt = reservation.passengers.length - chd

  return (
    <div className={styles.root(theme)}>
      <div className={styles.header(theme)}>
        <div className={styles.headerLogo}>
          <img src={logo} alt="" />
        </div>
      </div>
      <div className={styles.startBox}>
        <div className={styles.rowMain}>
          <div className={styles.col}>
            <div className={styles.row}>
              <p className={styles.tittle}>
                {t(
                  `Provider.${getProviderShortName(
                    reservation.cluster.provider_id,
                    reservation.cluster.source,
                    reservation.cluster.source_type
                  )}`
                )}
              </p>
            </div>
            <div className={styles.row}>
              <p>
                {`${reservation.cluster.segments[0].origin.name
                  .split("(")[0]
                  .trim()} - ${reservation.cluster.segments[0].destination.name.split("(")[0].trim()}`}
              </p>
            </div>
            <div className={styles.row}>
              <p>
                {`(${reservation.cluster.segments[0].origin.name
                  .split("(")[1]
                  .trim()} - (${reservation.cluster.segments[0].destination.name.split("(")[1].trim()}`}
              </p>
            </div>
            <div className={styles.row}>
              <EventIcon className={styles.icon} />
              <p className={styles.smallLetters}>
                {moment(reservation.created_at)
                  .utc()
                  .format("DD•MM•YYYY - H:mm A")}
              </p>
              <PersonIcon className={styles.icon} />
              <p className={styles.smallLetters}>
                {t("ReservationDetails.adults", { count: adt })}
                {chd > 0 && <>, {t("ReservationDetails.children", { count: chd })}</>}
              </p>
            </div>
          </div>
          <div className={styles.col}>
            <p className={styles.total}>{t("ReservationDetails.total")}</p>
            <div className={styles.row}>
              {reservation.cluster.price.currency !== "CLP" && (
                <p className={styles.currency}>{reservation.cluster.price.currency}</p>
              )}

              <p className={styles.total}>
                {formatPrice(reservation.cluster.price.total, locale, reservation.cluster.price.currency, {
                  hideCurrency: true
                })}
              </p>
            </div>
          </div>
        </div>
        <Divider className={styles.divider(theme)} />
        {reservation.passengers.map(p => (
          <p className={styles.titular}>{`${p.firstname} ${p.lastname} | ${p.document.type} ${p.document.number}`}</p>
        ))}
        <Divider className={styles.divider(theme)} />
      </div>

      <div className={styles.alert}>
        <div className={styles.row}>
          <InfoIcon className={styles.alertIcon} />
          <span>
            {t("ReservationDetails.validUntil")}{" "}
            {moment(reservation.expiration_date)
              .utc()
              .format("DD•MM•YYYY - H:mm A")}
          </span>
        </div>
      </div>

      <FlightDetail
        cluster={reservation.cluster}
        origin={reservation.cluster.segments[0].origin.code}
        destination={reservation.cluster.segments[0].destination.code}
        pnr={reservation.pnr}
        unfoldSegment={true}
      />
    </div>
  )
}

export default ReservationDetails
