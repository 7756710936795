import { useTheme } from "@basset-la/themed-components"
import React from "react"
import styles from "./Marker.styles"

export interface Props {
  lat: number
  lng: number
  width: number
  label: string
  selected: boolean
  onClick: () => void
}

const Marker: React.FC<Props> = ({ width, label, selected, onClick }) => {
  const theme = useTheme()

  return (
    <div className={styles.marker(theme, width, selected)} onClick={onClick}>
      <p>{label}</p>
    </div>
  )
}

export default Marker
