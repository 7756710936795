import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

const SearchIcon: React.FC<Props> = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="17"
      height="17"
      viewBox="0 0 17 17"
    >
      <defs>
        <path
          id="prefix__c"
          d="M8.651 7.648h-.527l-.186-.18c.8-.934 1.213-2.207.986-3.56C8.611 2.054 7.064.574 5.198.348 2.378 0 .004 2.374.35 5.194c.227 1.867 1.707 3.414 3.56 3.727 1.353.227 2.627-.187 3.56-.987l.18.187v.527l2.833 2.833c.274.273.72.273.994 0 .273-.273.273-.72 0-.993L8.65 7.648zm-4 0c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
        />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(2.25 2.125)">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask>
        <g fill="#000" fillOpacity=".54" mask="url(#prefix__d)">
          <path d="M0 0H16V16H0z" transform="translate(-2 -2)" />
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
