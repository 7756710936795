import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 2px rgba(0, 0, 0, 0.12)"
  }),

  item: (t: Theme, selected: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      height: "48px",
      minWidth: "184px",
      padding: "16px 32px",
      cursor: "pointer",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: selected ? `3px solid ${t.colors.brand_primary}` : undefined
    }),

  name: (t: Theme, selected: boolean) =>
    css({
      color: selected ? t.colors.brand_primary : t.colors.text_variant,
      fontWeight: 400,
      fontSize: "14px"
    })
}
