import { css } from "@emotion/css"

export const ArrowDropDownStyle = (color: string) => {
  return {
    rootStyle: css({
      color: `${color} !important`,
      padding: "0 !important"
    })
  }
}
