import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiOutlinedInput-root": {
        height: "32px",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "104px",
        backgroundColor: t.colors.background_variant,
        fontSize: "14px",
        lineHeight: "1.14",
        color: t.colors.text_primary,
        ">.MuiSelect-root": {
          padding: "6.5px 25px 6.5px 8px"
        },
        ">.MuiSelect-icon": {
          color: t.colors.brand_primary
        },
        ">.MuiOutlinedInput-notchedOutline": {
          border: "none"
        }
      }
    })
}
