import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Baggages from "../Baggages/Baggages"
import BaggagesInfo from "../BaggagesInfo/BaggagesInfo"
import styles from "./BaggagesWIthTooltip.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  bagIncluded?: number
  carryOnIncluded?: number
  baggageAmount?: number
  /** if `baggageAmount` > 0 `baggageWeight` is mandatory */
  baggageWeight?: number
}

const BaggagesWithTooltip: React.FunctionComponent<Props> = ({
  bagIncluded,
  carryOnIncluded,
  baggageAmount,
  baggageWeight
}) => {
  if (baggageAmount && baggageAmount > 0 && baggageWeight === undefined) {
    throw new Error("if baggageAmount is greater than 0 baggageWeight is mandatory")
  }

  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null)
  const theme = useTheme()

  return (
    <Tooltip
      className={styles.rootTooltip}
      classes={{
        tooltip: styles.tooltip(theme),
        popper: styles.popper(theme)
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            arrow: {
              enabled: Boolean(arrowRef),
              element: arrowRef
            }
          }
        }
      }}
      title={
        <React.Fragment>
          <div className={styles.content}>
            <BaggagesInfo
              bagIncluded={bagIncluded}
              baggageAmount={baggageAmount}
              baggageWeight={baggageWeight}
              carryOnIncluded={carryOnIncluded}
            />
          </div>
          <span className={[styles.arrow, "arrow"].join(" ")} ref={setArrowRef} />
        </React.Fragment>
      }
    >
      <div className={styles.baggageTooltip}>
        <Baggages bagIncluded={bagIncluded} carryOnIncluded={carryOnIncluded} baggageAmount={baggageAmount} />
      </div>
    </Tooltip>
  )
}

export default BaggagesWithTooltip
