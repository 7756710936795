import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const MealIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0349 3.33329H14.6349C15.0282 3.33329 15.3349 3.67329 15.2949 4.06663L14.2682 14.3533C14.2015 14.9 13.7415 15.3333 13.1815 15.3333H12.0482L12.0349 9.99996C12.0349 7.17996 9.9682 5.43996 7.51487 4.87329L7.43487 4.06663C7.40154 3.67329 7.7082 3.33329 8.10154 3.33329H10.7015V1.33329C10.7015 0.966626 11.0015 0.666626 11.3682 0.666626C11.7349 0.666626 12.0349 0.966626 12.0349 1.33329V3.33329ZM10.0349 14C10.4015 14 10.7015 14.3 10.7015 14.6666C10.7015 15.0333 10.4015 15.3333 10.0349 15.3333H1.3682C1.00154 15.3333 0.701538 15.0333 0.701538 14.6666C0.701538 14.3 1.00154 14 1.3682 14H10.0349ZM9.96821 9.99996C10.3815 9.99996 10.7082 9.62663 10.6282 9.22663C10.1949 7.07329 7.9482 5.99329 5.70154 5.99329C3.45487 5.99329 1.21487 7.07329 0.774871 9.22663C0.694871 9.62663 1.0282 9.99996 1.43487 9.99996H9.96821ZM0.701538 12C0.701538 11.6333 1.00154 11.3333 1.3682 11.3333H10.0349C10.4015 11.3333 10.7015 11.6333 10.7015 12C10.7015 12.3666 10.4015 12.6666 10.0349 12.6666H1.3682C1.00154 12.6666 0.701538 12.3666 0.701538 12Z"
      fill={color}
    />
  </svg>
)
