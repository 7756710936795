import { css } from "@emotion/css"

const styles = {
  image: (link: string) =>
    css({
      objectFit: "fill",
      width: "368px",
      height: "216px",
      borderRadius: "4px",
      cursor: !!link ? "pointer" : undefined
    })
}

export default styles
