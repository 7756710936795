import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./RoomSection.styles"
import { DocumentOption, RoomGuestData } from "../../model/roomSection"
import Text from "@basset-la/components-commons/dist/components/Text"
import DocumentField from "./DocumentField"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import TextField from "@basset-la/components-commons/dist/components/TextField"

interface Props {
  index: number
  room: RoomGuestData
  onChange: (index: number, field: string, value: string) => void
  checkoutConfig?: any
}

const GuestDataContainer: React.FC<Props> = ({ index, room, onChange, checkoutConfig }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const onChangeWithField = (e: any) => {
    const field = e.target.name.split("-")[0]
    const i = Number(e.target.name.split("-")[1])
    onChange(i, field, e.target.value)
  }

  const documentTypes: string[] | undefined = checkoutConfig && checkoutConfig["FLIGHTS"].passengers.documentTypes
  const documentOptions: DocumentOption[] | undefined =
    documentTypes &&
    documentTypes.map((type: string) => {
      return {
        code: type,
        name: t(`DocumentField.documentType.${type}`)
      }
    })

  return (
    <div className={styles.guestDataContainer}>
      <div className={styles.field}>
        <Text className={styles.textFieldTitle(theme)} size={14} variant="regular">
          {t("RoomSection.firstNameField.title")}
        </Text>
        <TextField
          variant="text"
          className={styles.textField(theme)}
          placeholder={t("RoomSection.firstNameField.placeholder")}
          value={room.firstName}
          onChange={e => onChange(index, "firstName", e.target.value)}
          error={!!room.firstName_error}
          helperText={room.firstName_error && room.firstName_error}
        />
      </div>
      <div className={styles.field}>
        <Text className={styles.textFieldTitle(theme)} size={14} variant="regular">
          {t("RoomSection.lastNameTextField.title")}
        </Text>
        <TextField
          variant="text"
          className={styles.textField(theme)}
          placeholder={t("RoomSection.lastNameTextField.placeholder")}
          value={room.lastName}
          onChange={e => onChange(index, "lastName", e.target.value)}
          error={!!room.lastName_error}
          helperText={room.lastName_error && room.lastName_error}
        />
      </div>
      <DocumentField
        documentOptions={documentOptions}
        documentNumberName={`documentNumber-${index}`}
        documentNumberErrorText={room.documentNumber_error}
        documentNumberValue={room.documentNumber}
        documentTypeName={`documentType-${index}`}
        documentTypeValue={room.documentType}
        documentTypeErrorText={room.documentType_error}
        onChange={onChangeWithField}
      />
    </div>
  )
}

export default GuestDataContainer
