import React from "react"
import styles from "./POISection.styles"
import CheckRadioButton from "../CheckRadioButton/CheckRadioButton"
import { Region } from "../../model/accommodationDetail"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  title: string
  checked: boolean
  pois: Region[]
  onClick: () => void
  checkColor: string
}

const POISection: React.FunctionComponent<Props> = ({ title, checked, pois, onClick, checkColor }) => {
  const theme = useTheme()

  return (
    <div>
      <div className={styles.sectionTitle(theme)}>
        <CheckRadioButton backgroundColor={checkColor} checked={checked} onClick={onClick} />
        <p>{title}</p>
      </div>
      <div className={styles.pois(theme)}>
        {pois.map((e, i) => (
          <p key={i}>
            {`${e.name} - `}
            <span>{e.distance}</span>
          </p>
        ))}
      </div>
    </div>
  )
}

export default POISection
