import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import moment from "moment"
import { Config } from "../../models/config"

export type ConfigType = {
  config: Config
  availableProducts: string[]
}

export const ConfigContext = React.createContext<ConfigType>({} as ConfigType)

export interface Props {
  getClientConfig: (language: string) => Promise<Config>
  getAvailableProducts: (agencyId: string) => Promise<string[]>
  fallback?: React.ReactNode
  children?: React.ReactNode
}

const ConfigProvider: React.FC<Props> = ({ fallback, children, getClientConfig, getAvailableProducts }) => {
  const { i18n, ready } = useTranslation(I18N_NS)
  const [config, setConfig] = useState<Config>()
  const [availableProducts, setAvailableProducts] = useState<string[]>([])

  useEffect(() => {
    if (!ready) {
      return
    }

    const load = async () => {
      const language = i18n.language && i18n.language !== "dev" ? i18n.language : "es"
      const cfg = await getClientConfig(language)
      const products = await getAvailableProducts(cfg.agency_id)

      if (!i18n.language || i18n.language === "dev") {
        const lang = cfg.language && cfg.language !== "" ? cfg.language : "es"
        i18n.changeLanguage(lang + "-" + cfg.country)
      }

      moment.locale(cfg.language.substr(0, 2))

      setAvailableProducts(products)
      setConfig(cfg)
    }

    if (!config) {
      load()
    }
  }, [i18n, ready, config, getClientConfig, getAvailableProducts])

  if (!config) {
    return <>{fallback}</>
  }

  const result: ConfigType = { config: config, availableProducts: availableProducts }
  return <ConfigContext.Provider value={result}>{children}</ConfigContext.Provider>
}

export const useConfig = () => useContext(ConfigContext)

export const withConfig = (Component: any) => {
  return function configComponent(props: any) {
    return <ConfigContext.Consumer>{cfg => <Component {...props} {...cfg} />}</ConfigContext.Consumer>
  }
}

export default ConfigProvider
