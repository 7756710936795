import { Leg } from "../../model"

export const getAirlineLogo = (
  legs: Leg[],
  textMultiple: string
): { code: string | "MUL"; name: string; aircraftType?: string } => {
  for (let leg of legs) {
    if (leg.marketing_carrier.code !== legs[0].marketing_carrier.code) {
      return { code: "MUL", name: textMultiple }
    }
  }
  return {
    code: legs[0].marketing_carrier.code,
    name: legs[0].marketing_carrier.name,
    aircraftType: legs[0].aircraft_type
  }
}
