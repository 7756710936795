import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiCheckbox-colorSecondary, &.MuiCheckbox-colorSecondary.Mui-checked": {
        padding: 0,
        color: t.colors.brand_primary
      },
      "&.MuiCheckbox-colorSecondary.Mui-disabled": {
        padding: 0,
        color: t.colors.background_darker
      }
    }),
  control: (labelPlacement: "end" | "start" | "top" | "bottom") =>
    css({
      "&.MuiFormControlLabel-root": {
        margin: 0
      },
      "& .MuiFormControlLabel-label": {
        marginLeft: labelPlacement === "end" ? "12px !important" : 0,
        marginRight: labelPlacement === "start" ? "12px" : 0
      }
    })
}
