import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const ArrivalIcon: React.FunctionComponent<Props> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="16px" viewBox="0 0 24 16" {...props}>
    <path
      fill="#4A4A4A"
      fillRule="nonzero"
      d="M4 14.316h16V16H4v-1.684zm14.181-2.653a1.27 1.27 0 0 0 1.55-.892 1.27 1.27 0 0 0-.893-1.55l-4.472-1.196L12.042.43 10.417 0v6.973l-4.185-1.12-.784-1.954-1.22-.328v4.353l13.953 3.74z"
    />
  </svg>
)
