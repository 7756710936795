import React, { useState } from "react"
import { TextFieldProps } from "@material-ui/core/TextField"
import { AutocompleteGroupedOption } from "./types"
import DesktopOptions from "./DesktopOptions"
import MobileOptions from "./MobileOptions"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export type TextFieldParams = Pick<
  TextFieldProps,
  "autoFocus" | "value" | "onChange" | "onFocus" | "onClick" | "placeholder"
>

export interface MobileDialogParams {
  open: boolean
  inputValue: string
  children: React.ReactNode
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onClose: () => void
}

export interface Props {
  groupedOptions: AutocompleteGroupedOption[]
  renderOption: (option: any) => React.ReactNode
  onSelectOption: (option: any) => void
  inputValue: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  renderInput: (params: TextFieldParams) => React.ReactNode

  placeholder?: string
  renderOptionHeader?: () => React.ReactNode
  renderOptionTitle?: (type: string, label: string) => React.ReactNode
  fetchingData?: boolean
  renderMobileDialog?: (params: MobileDialogParams) => React.ReactNode
}

const Autocomplete: React.FC<Props> = ({
  groupedOptions,

  renderOptionHeader,
  renderOption,
  renderOptionTitle,
  onSelectOption,
  inputValue,
  onInputChange,
  renderInput,
  placeholder,
  fetchingData,
  renderMobileDialog
}) => {
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [openMobileDialog, setOpenMobileDialog] = useState(false)

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (isMobile && !openMobileDialog) {
      setOpenMobileDialog(true)
    }
    setAnchorEl(event.currentTarget)
    onInputChange(event)
  }

  const handleOnClickTextField = () => {
    if (isMobile) {
      setOpenMobileDialog(true)
    }
  }

  const handleOnClickAway = (event: React.MouseEvent<Document>) => {
    if (event.target !== anchorEl) {
      setAnchorEl(null)
    }
  }

  const handleOnOptionCliclk = (opt: any) => {
    onSelectOption(opt)
    setAnchorEl(null)
  }

  const handleOnCloseMobileDialog = () => {
    setOpenMobileDialog(false)
  }

  return (
    <>
      {renderInput({
        value: inputValue,
        onChange: handleOnChange,
        onFocus: handleOnFocus,
        onClick: handleOnClickTextField,
        placeholder: placeholder
      })}
      {isMobile && (
        <MobileOptions
          groupedOptions={groupedOptions}
          inputValue={inputValue}
          onClose={handleOnCloseMobileDialog}
          onInputChange={onInputChange}
          onSelectOption={onSelectOption}
          renderOptionHeader={renderOptionHeader}
          renderOption={renderOption}
          renderOptionTitle={renderOptionTitle}
          open={openMobileDialog}
          fetchingData={fetchingData}
          renderMobileDialog={renderMobileDialog}
        />
      )}
      {!isMobile && (
        <DesktopOptions
          anchorEl={anchorEl}
          groupedOptions={groupedOptions}
          onClickAway={handleOnClickAway}
          renderOptionHeader={renderOptionHeader}
          onSelectOption={handleOnOptionCliclk}
          renderOption={renderOption}
          renderOptionTitle={renderOptionTitle}
          fetchingData={fetchingData}
        />
      )}
    </>
  )
}

export default Autocomplete
