import React from "react"
import styles from "./TextField.styles"

import MaterialTextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput"
import { useTheme } from "@basset-la/themed-components"
import { InputBaseComponentProps } from "@material-ui/core/InputBase"

type Variant = "text" | "multiline" | "password" | "email" | "number" | "tel" | "url" | "time"
type Adornment = "start" | "end" | "none"

export interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  variant?: Variant
  label?: string
  placeholder?: string
  value?: string | number
  disabled?: boolean
  error?: boolean
  helperText?: string
  adornment?: Adornment
  inputComponent?: React.ElementType<InputBaseComponentProps>
  AdornmentComp?: React.ComponentClass | React.FC | string
  autofocus?: boolean
  fullWidth?: boolean
  className?: string
  required?: boolean
  maxRows?: number
  minRows?: number
  onKeyPress?: (e: any) => void
  inputComponentPros?: Record<string, any>
}

const TextField: React.FC<Props> = ({
  onChange,
  variant = "text",
  label = "",
  placeholder = "",
  value = "",
  disabled = false,
  autofocus = false,
  fullWidth = false,
  error = false,
  helperText = "",
  adornment = "none",
  AdornmentComp,
  className,
  required = false,
  onKeyPress,
  inputComponent,
  maxRows,
  minRows,
  inputComponentPros = {}
}) => {
  const theme = useTheme()
  let inputProps: Partial<OutlinedInputProps>

  switch (adornment) {
    case "start":
      inputProps = {
        startAdornment: (
          <InputAdornment position={"start"} className={styles.icon(theme)}>
            {AdornmentComp && typeof AdornmentComp == "string" ? AdornmentComp : AdornmentComp && <AdornmentComp />}
          </InputAdornment>
        )
      }
      break
    case "end":
      inputProps = {
        endAdornment: (
          <InputAdornment position={"start"} className={styles.icon(theme)}>
            {AdornmentComp && typeof AdornmentComp == "string" ? AdornmentComp : AdornmentComp && <AdornmentComp />}
          </InputAdornment>
        )
      }
      break
    default:
      inputProps = {}
      break
  }

  if (inputComponent && variant !== "time") {
    inputProps = {
      ...inputProps,
      inputComponent: inputComponent,
      inputProps: {
        ...inputComponentPros
      }
    }
  }

  return (
    <MaterialTextField
      size="small"
      placeholder={placeholder}
      onChange={onChange}
      type={variant === "password" ? "password" : variant === "time" ? "time" : "text"}
      label={label}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      autoFocus={autofocus}
      error={error}
      helperText={helperText}
      InputProps={inputProps}
      maxRows={maxRows}
      minRows={minRows}
      className={`${styles.textfield(theme)} ${className}`}
      required={required}
      name={variant}
      multiline={variant === "multiline"}
      onKeyPress={onKeyPress}
      variant="outlined"
    />
  )
}

export default TextField
