import React from "react"
import { AccommodationCatalog } from "../../model/accommodationDetail"
import styles from "./AccommodationDetailPDF.styles"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import Checkin from "../AccommodationDetail/Checkin"
import AccommodationInfo from "../AccommodationDetail/AccommodationInfo"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AmenitiesDescription from "../AccommodationDetail/AmenitiesDescription"
import AccommodationZone from "../AccommodationDetail/AccommodationZone"
import { calculateHighlightedAmenities } from "../../utils/helpers/helpers"
import { useTheme } from "@basset-la/themed-components"
import AccommodationCarouselImage from "../AccommodationCarousel/AccommodationCarouselImage"
import AccommodationDetailPDFRooms from "./AccommodationDetailPDFRooms"
import { ClusterRoom, CancelPenalty, MealPlan as MealPlanType } from "../../model"

export interface Props {
  accomomdation: AccommodationCatalog
  rooms?: ClusterRoom[]
  mealPlan?: MealPlanType
  cancelPolicies?: CancelPenalty[]
  refundable?: boolean
  infoComponent?: React.ReactNode
  detailComponent?: React.ReactNode
}

const AccommodationDetailPDF: React.FC<Props> = ({
  accomomdation,
  infoComponent,
  detailComponent,
  rooms,
  mealPlan,
  cancelPolicies,
  refundable
}) => {
  const { t } = useTranslation(I18N_NS)
  const { address, name, stars, description, amenities, location, zone, images, checkin, checkout } = accomomdation

  const highlightedAmenities = calculateHighlightedAmenities(amenities)

  let checkinInstructions = ""

  if (checkin) {
    if (checkin.instructions) checkinInstructions = checkinInstructions + checkin.instructions
    if (checkin.special_instructions) checkinInstructions = checkinInstructions + checkin.special_instructions
  }

  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div className={styles.content}>
        <div className={styles.accommodationInfo}>
          <div className={styles.photos}>
            <AccommodationCarouselImage img={images[0].url} />
          </div>
          <div className={styles.infoContainer(theme)}>
            <div>
              <div className={styles.info}>
                <SimpleAccommodationInfo address={address} stars={stars} name={name} {...highlightedAmenities} />
              </div>
              <div className={styles.infoComponent}>{infoComponent}</div>
            </div>
            <div className={styles.checkinCheckout}>
              <Checkin
                checkinTime={checkin && checkin.time ? checkin.time : ""}
                checkoutTime={checkout && checkout.time ? checkout.time : ""}
              />
            </div>
          </div>
        </div>
        {rooms && cancelPolicies && mealPlan && (
          <AccommodationDetailPDFRooms
            rooms={rooms}
            mealPlan={mealPlan}
            cancelPolicies={cancelPolicies}
            refundable={refundable}
          />
        )}
        {detailComponent}
        {description && (
          <div className={styles.section(theme)}>
            <AccommodationInfo
              description={description}
              title={t("AccommodationDetail.descriptionTitle")}
              isMobile={false}
            />
          </div>
        )}
        <div id={"amenitiesDescription"} className={styles.section(theme)} style={{ borderBottom: "unset" }}>
          <AmenitiesDescription amenities={amenities} isMobile={false} />
        </div>
        {zone && (
          <div className={styles.zone(theme)}>
            <AccommodationZone
              address={address}
              hotelName={name}
              hotelCoordinates={location}
              hotelImg={images[0].url}
              zone={zone}
              isMobile={false}
            />
          </div>
        )}
        {checkinInstructions !== "" && (
          <div className={styles.section(theme)}>
            <AccommodationInfo
              description={checkinInstructions}
              title={t("AccommodationDetail.checkinInstructions")}
              isMobile={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AccommodationDetailPDF
