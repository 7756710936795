import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import Slider from "@material-ui/core/Slider"
import Button from "@material-ui/core/Button"
import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./PriceFilter.styles"
import { useTheme } from "@basset-la/themed-components"
import AccommodationFiltersCollapsePanel from "../AccommodationFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  max: number
  min: number
  currency: string
  value?: Value
  onApply: (value: Value) => void
}

export type Value = [number, number]

const PriceFilter: React.FC<Props> = ({ collapsible, max, min, currency, value, onApply }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const theme = useTheme()

  const locale = getLocale(i18n)

  const [currentValue, setCurrentValue] = React.useState<Value>(value ? value : [min, max])

  const handleChange = (event: React.ChangeEvent<{}>, value: Value) => {
    setCurrentValue(value)
  }

  const handleClick = () => {
    onApply(currentValue)
  }

  const component = (
    <div className={styles.content}>
      <div className={styles.prices}>
        <span>
          {formatPrice(currentValue[0], locale, currency, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </span>
        <span>
          {formatPrice(currentValue[1], locale, currency, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </span>
      </div>
      <Slider
        classes={{
          rail: styles.rail(theme),
          track: styles.track(theme),
          thumb: styles.thumb(theme)
        }}
        max={max}
        min={min}
        value={currentValue}
        onChange={handleChange}
      />
      <Button id="price-filter-apply-btn" onClick={handleClick} className={styles.button(theme)}>
        {t("PriceFilter.applyFilter")}
      </Button>
    </div>
  )

  return (
    <div className={styles.root(theme)}>
      {!collapsible && (
        <>
          <p className={styles.title}>{t("PriceFilter.title")}</p>
          {component}
        </>
      )}
      {collapsible && (
        <AccommodationFiltersCollapsePanel renderSummary={<>{t("PriceFilter.title")}</>} renderDetails={component} />
      )}
    </div>
  )
}

export default PriceFilter
