import { css } from "@emotion/css"

export default {
  root: css({
    marginBottom: "24px"
  }),

  image: (hide: boolean) =>
    css({
      opacity: hide ? 0 : 1,
      width: hide ? "0px" : "100%",
      height: hide ? "0px" : "100%",
      transition: "opacity 0.75s linear, width 0s, height 0s",
      overflow: "hidden"
    })
}
