import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  textField: css({
    "&.MuiTextField-root": {
      width: "100%"
    },
    "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
      cursor: "pointer"
    },
    ".MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    },
    ".MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    ".MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    },
    ".MuiSvgIcon-root": {
      height: "24px",
      width: "24px"
    }
  }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  menu: css({
    ".MuiList-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: "8px"
    },
    ".MuiList-root > .MuiMenuItem-root": {
      fontSize: "14px",
      lineHeight: "1.14",
      width: "100%",
      padding: "8px 0",
      justifyContent: "start"
    }
  }),
  itemMobile: css({
    padding: "16px 16px 0 16px",
    cursor: "pointer"
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    })
}
