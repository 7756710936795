import React from "react"
import styles from "./SocialLink.styles"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import YouTubeIcon from "@material-ui/icons/YouTube"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import { SocialLinkItem } from "../../models/types"
import TwitterIcon from "../../icons/Twitter"
import TikTokIcon from "../../icons/TikTok"

export interface Props {
  color: string
  item: SocialLinkItem
  onClick: (url: string) => void
}

const SocialLink: React.FC<Props> = ({ color, item, onClick }) => {
  const handleClick = (url: string) => {
    onClick(url)
  }

  const isFacebook = item.type === "facebook"
  const isTwitter = item.type === "twitter"
  const isInstagram = item.type === "instagram"
  const isYoutube = item.type === "youtube"
  const isLinkedIn = item.type === "linkedin"
  const isTikTok = item.type === "tiktok"

  return (
    <div className={styles.link(color)} onClick={() => handleClick(item.url)}>
      {isFacebook && <FacebookIcon />}
      {isTwitter && <TwitterIcon />}
      {isInstagram && <InstagramIcon />}
      {isYoutube && <YouTubeIcon />}
      {isLinkedIn && <LinkedInIcon />}
      {isTikTok && <TikTokIcon />}
    </div>
  )
}

export default SocialLink
