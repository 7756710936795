import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "8px 16px",
      gap: "8px",
      backgroundColor: t.colors.background_primary,
      cursor: "pointer"
    }),

  priceContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "baseline"
  }),

  price: (t: Theme) =>
    css({
      padding: "0 8px",
      backgroundColor: `${t.colors.brand_primary}20`
    })
}

export default styles
