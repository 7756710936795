import React from "react"
import styles from "./Checkin.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  checkinTime: string
  checkoutTime: string
}

const Checkin = ({ checkinTime, checkoutTime }: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  if (!checkinTime && !checkoutTime) return <></>

  return (
    <div className={styles.root(theme)}>
      <div className={styles.titleContainer}>
        <span className={styles.title}>{t("Checkin.time_schedule")}</span>
      </div>
      <div className={styles.checkinCheckout}>
        {checkinTime && (
          <div className={styles.hourContainer(false)}>
            <span>{checkinTime}</span>
            <span>{t("Checkin.checkin")}</span>
          </div>
        )}
        {checkoutTime && (
          <div className={styles.hourContainer(true)}>
            <span>{checkoutTime}</span>
            <span>{t("Checkin.checkout")}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Checkin
