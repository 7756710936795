import * as React from "react"
import MaterialUIRadio, { RadioProps } from "@material-ui/core/Radio"
import { radioStyle } from "./Radio.styles"

const DEFAULT_COLOR = "#126aff"

export interface Props extends RadioProps {
  backgroundColor?: string
}

const Radio: React.FunctionComponent<Props> = ({ backgroundColor, ...rest }) => {
  const style = radioStyle(backgroundColor ? backgroundColor : DEFAULT_COLOR)
  return <MaterialUIRadio {...rest} classes={{ root: style.root, checked: style.checked }} />
}

export default Radio
