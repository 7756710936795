import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      borderRadius: "4px",
      border: `solid 1px ${theme.colors.background_darker}`,
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      display: "flex"
    }),
  roomsDescriptionContainer: css({
    padding: "8.5px",
    width: "50%"
  }),
  roomDescription: css({
    marginBottom: "10px"
  }),
  roomDescriptionName: css({
    fontSize: "16px",
    lineHeight: 1.5,
    margin: 0
  }),
  roomDescriptionCapacity: css({
    fontSize: "12px",
    lineHeight: 1.33,
    margin: 0
  }),
  mealPlanAndPenalties: (theme: Theme) =>
    css({
      margin: "10px",
      width: "50%",
      borderRadius: "4px",
      border: `solid 1px ${theme.colors.background_darker}`
    })
}
