import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import TextField from "@material-ui/core/TextField"
import Slide from "@material-ui/core/Slide"
import { TransitionProps } from "@material-ui/core/transitions"
import { AutocompleteGroupedOption } from "./types"
import OptionList from "./OptionList"
import styles from "./MobileOptions.styles"
import { useTheme } from "@basset-la/themed-components"
import ClearIcon from "@material-ui/icons/HighlightOffRounded"
import MyLocationIcon from "@material-ui/icons/MyLocation"
import ArrowBack from "@material-ui/icons/ArrowBack"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import { MobileDialogParams } from "./Autocomplete"

export interface Props {
  open: boolean
  onClose: () => void

  groupedOptions: AutocompleteGroupedOption[]
  renderOption: (option: any) => React.ReactNode
  onSelectOption: (option: any) => void
  inputValue: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void

  placeholder?: string
  renderOptionTitle?: (type: string, label: string) => React.ReactNode
  renderOptionHeader?: () => React.ReactNode
  fetchingData?: boolean
  renderMobileDialog?: (params: MobileDialogParams) => React.ReactNode
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const MobileOptions: React.FC<Props> = ({
  onClose,
  onSelectOption,
  onInputChange,
  open,
  inputValue,
  placeholder,
  groupedOptions,
  fetchingData,
  renderOption,
  renderOptionTitle,
  renderOptionHeader,
  renderMobileDialog
}) => {
  const theme = useTheme()

  const handleOnSelectOption = (opt: any) => {
    onClose()
    onSelectOption(opt)
  }

  const handleClearText = () => {
    onInputChange({ target: { value: "" } } as any)
  }

  const buildDefaultMobileDialog = (children: React.ReactNode) => {
    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar className={styles.appBar(theme)}>
          <Toolbar className={styles.toolbar}>
            <IconButton edge="start" onClick={onClose}>
              <ArrowBack />
            </IconButton>
            <TextField
              autoFocus
              className={styles.textField(theme)}
              value={inputValue}
              onChange={onInputChange}
              placeholder={placeholder}
              variant="outlined"
              InputProps={{
                startAdornment: <MyLocationIcon className={styles.locationIcon(theme)} />,
                endAdornment: inputValue.length > 0 && (
                  <InputAdornment position="end">
                    <IconButton className={styles.clearButton} onClick={handleClearText}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Toolbar>
        </AppBar>
        <DialogContent className={styles.content}>{children}</DialogContent>
      </Dialog>
    )
  }

  const optionList = (
    <OptionList
      groupedOptions={groupedOptions}
      onSelectOption={handleOnSelectOption}
      renderOptionHeader={renderOptionHeader}
      renderOption={renderOption}
      renderOptionTitle={renderOptionTitle}
      fetchingData={fetchingData}
    />
  )

  if (renderMobileDialog) {
    return <>{renderMobileDialog({ open, onClose, inputValue, onInputChange, children: optionList })}</>
  }
  return buildDefaultMobileDialog(optionList)
}

export default MobileOptions
