import React from "react"
import styles from "./ProductRateDesktop.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { formatPrice, getLocale } from "../../utils/helpers"
import { ProductRateDesktopModel } from "./types"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import Tooltip from "@material-ui/core/Tooltip"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
export interface Props {
  rate: ProductRateDesktopModel
  onClick: () => void
}

const ProductRateDesktop: React.FC<Props> = ({ rate, onClick }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const applied = rate.fare?.discount?.applied?.[0]

  return (
    <div className={styles.root(theme)} onClick={onClick}>
      <div className={styles.providerContainer}>
        <Text size={10} variant="light">
          {rate.provider}
          {rate.rate_code ? ` - ${rate.rate_code}` : ""}
        </Text>
        {applied && rate?.discounted_rate && (
          <Tooltip placement="top" classes={{ tooltip: styles.tooltip }} title={rate?.discounted_rate}>
            <span className={styles.discount}>
              <LocalOfferIcon className={styles.icon} /> {`${applied.code} - ${applied.value}`}
            </span>
          </Tooltip>
        )}
      </div>
      <div className={styles.priceContainer}>
        <Text className={styles.price(theme)} size={12} variant="regular">
          {formatPrice(rate.fare.daily, locale, rate.fare.currency)}
        </Text>
        <Text size={16} variant="bold">
          {t("ClusterRatesDesktop.total", { price: formatPrice(rate.fare.total, locale, rate.fare.currency) })}
        </Text>
      </div>
      {rate?.change_office && rate?.rate_code && (
        <div className={styles.rateCodeContainer}>
          <InfoOutlinedIcon className={styles.rateCodeIcon(theme)} />
          <div className={styles.rateCodeText(theme)}>{rate.change_office}</div>
        </div>
      )}
    </div>
  )
}

export default ProductRateDesktop
