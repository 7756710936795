import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  title: css({
    fontWeight: 500,
    lineHeight: "1.14",
    fontSize: "14px",
    marginBottom: "16px"
  }),
  root: (t: Theme) =>
    css({
      padding: "16px",
      backgroundColor: t.colors.background_primary,
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: "3px",
      fontFamily: "Roboto",
      fontcolor: t.colors.text_primary
    }),
  checkboxContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      " .MuiFormControlLabel-root": {
        margin: 0,
        fontSize: "14px",
        marginBottom: "8px",
        ":last-of-type": {
          marginBottom: 0
        }
      },
      " .MuiCheckbox-root": {
        padding: 0,
        marginRight: "8px",
        marginLeft: "-3px",
        borderColor: t.colors.brand_primary,
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary
        }
      },
      " .MuiFormControlLabel-label": {
        fontSize: "14px"
      }
    }),
  buttonContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: "8px",
      ">.MuiButton-root": {
        fontSize: "14px",
        color: t.colors.brand_primary,
        alignSelf: "flex-end"
      }
    })
}
