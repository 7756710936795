import React from "react"
import { Price as FlightPrice, Currency } from "../../model/types"
import { convertToAgencyPriceBreakdown } from "../../utils/helpers/helpers"
import PriceboxContainer from "./PriceboxBreakdownContainer"
import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"
import styles from "./PriceboxBreakdownCheckoutDesktop.styles"

export interface Props {
  flightPrice: FlightPrice
  currencySelector?: Currency
}

const PriceboxBreakdownCheckoutDesktop: React.FC<Props> = ({ flightPrice, currencySelector }) => {
  const priceBreakdown = convertToAgencyPriceBreakdown(flightPrice)
  return (
    <div className={styles.root}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <PriceboxContainer priceBreakdown={priceBreakdown} />
    </div>
  )
}

export default PriceboxBreakdownCheckoutDesktop
