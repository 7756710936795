import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  textField: (t: Theme) =>
    css({
      "&.MuiTextField-root": {
        width: "104px",
        height: "32px",
        backgroundColor: t.colors.background_variant
      },
      "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
        cursor: "pointer",
        textAlign: "end"
      },
      "&.MuiTextField-root >.MuiInputBase-root >.MuiOutlinedInput-notchedOutline": {
        borderStyle: "none"
      }
    }),
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),
  menu: css({
    ".MuiList-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start"
    },
    ".MuiList-root > .MuiListItem-root": {
      width: "100%",
      padding: "8px",
      fontSize: "14px",
      lineHeight: "1.14",
      justifyContent: "start"
    }
  })
}
