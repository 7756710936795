import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const MileAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99999 9.6797L8.76665 11.353C9.27332 11.6597 9.89332 11.2064 9.75999 10.633L9.02665 7.48637L11.4733 5.36637C11.92 4.9797 11.68 4.24637 11.0933 4.1997L7.87332 3.92637L6.61332 0.953035C6.38665 0.413035 5.61332 0.413035 5.38665 0.953035L4.12665 3.9197L0.906654 4.19303C0.319987 4.2397 0.0799874 4.97303 0.526654 5.3597L2.97332 7.4797L2.23999 10.6264C2.10665 11.1997 2.72665 11.653 3.23332 11.3464L5.99999 9.6797Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
