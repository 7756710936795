import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    "&.MuiTextField-root": {
      width: "100%"
    },
    "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
      cursor: "pointer"
    },
    ".MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    },
    ".MuiInputAdornment-positionEnd": {
      marginLeft: 0
    },
    ".MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    },
    ".MuiSvgIcon-root": {
      height: "24px",
      width: "24px"
    }
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  popover: (width: number | undefined) =>
    css({
      width: width ? `calc(${width}px)` : 0,
      zIndex: 2000,
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  dialogContainer: css({
    padding: "16px 8px 0 8px"
  })
}
