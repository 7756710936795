import React from "react"
import { Cluster, FlightType } from "../../model"
import SegmentOptionBrief from "../SegmentOptionBrief/SegmentOptionBrief"
import styles from "./ClusterBrief.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import i18next from "i18next"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  cluster: Cluster
}

/**
 * `ClusterBrief` is used to show a brief summary of a selected cluster.
 * It should have only one segmentOption per segment (it will only show the fisrt option)
 */
const ClusterBrief: React.FC<Props> = ({ cluster }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      {cluster.segments.map((s, i) => {
        return (
          <div className={styles.segmentOption} key={i}>
            <p className={styles.title}>{resolveTitle(i, cluster.flight_type, t)}</p>
            <SegmentOptionBrief segmentOption={s.options[0]} mini />
          </div>
        )
      })}
    </div>
  )
}

const resolveTitle = (segmentIndex: number, flightType: FlightType, t: i18next.TFunction): string => {
  switch (flightType) {
    case "MULTIPLE_DESTINATIONS":
      return t("ClusterBrief.segmentNumber", { number: segmentIndex + 1 })
    default:
      if (segmentIndex === 0) {
        return t("ClusterBrief.departure")
      }
      return t("ClusterBrief.return")
  }
}

export default ClusterBrief
