import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      height: "100%",
      width: "100%",
      fontFamily: "Roboto",
      color: theme.colors.text_primary
    }),
  title: css({
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "1.25",
    margin: 0,
    marginBottom: "8px",
    "@media screen and (max-width: 1024px)": {
      lineHeight: "1.33",
      fontSize: "18px",
      padding: "0px 16px"
    }
  }),
  description: css({
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "24px",
    margin: 0,
    textAlign: "justify",
    "> *": {
      margin: 0,
      padding: 0
    },
    "@media screen and (max-width: 1024px)": {
      padding: "0px 16px"
    }
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        fontSize: "14px",
        textTransform: "unset",
        fontWeight: 300,
        lineHeight: "1.71",
        color: theme.colors.brand_primary
      }
    })
}
