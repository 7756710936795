import React from "react"
import { Passengers } from "./types"
import styles from "./PassengerPicker.styles"
import { useTheme } from "@basset-la/themed-components"
import Counter from "@basset-la/components-commons/dist/components/Counter"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AgeSelect from "./AgeSelect"
import MenuItem from "@material-ui/core/MenuItem"

export interface Props {
  passengers: Passengers
  childrenErrors: string[]
  allPassengersError: string | undefined
  onChange: (passengers: Passengers) => void
  maxPassengers: number
  isMobile: boolean
}

const PassengerPicker: React.FC<Props> = ({
  passengers,
  onChange,
  childrenErrors,
  allPassengersError,
  maxPassengers,
  isMobile
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const { adults, children, disableds, seniors } = passengers
  const totalPassengers = adults + children.length + disableds + seniors
  const maxPsgrs = maxPassengers - totalPassengers

  const handleCountChange = (key: keyof Omit<Passengers, "children">) => (v: number) => {
    const psgrs = { ...passengers }
    psgrs[key] = v
    onChange(psgrs)
  }

  const handleChildrenCountChange = (v: number) => {
    const psgrs = { ...passengers }
    if (v > psgrs.children.length) {
      psgrs.children.push("NO_SET")
    } else if (v < psgrs.children.length) {
      psgrs.children = psgrs.children.slice(0, v)
    }

    onChange(psgrs)
  }

  const handleChildrenAgeChange = (idx: number) => (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: any
    }>
  ) => {
    const psgrs = { ...passengers }
    psgrs.children[idx] = event.target.value
    onChange(psgrs)
  }

  return (
    <div className={styles.root(theme, isMobile)}>
      <div className={styles.pickerContainer}>
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("PassengerPicker.adultsTitle")}</span>
            </div>
            <Counter onChange={handleCountChange("adults")} maxValue={maxPsgrs + adults} value={adults} minValue={0} />
          </div>
        </div>
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("PassengerPicker.childrenTitle")}</span>
              <span>{t("PassengerPicker.childrenDisclamer")}</span>
            </div>
            <Counter
              onChange={handleChildrenCountChange}
              maxValue={maxPsgrs + children.length}
              value={children.length}
              minValue={0}
            />
          </div>
        </div>
        {children.map((v, i) => (
          <div key={`chd_${i}`} className={styles.picker}>
            <div className={styles.mainPicker}>
              <div className={styles.pickerLabelContainer(theme)}>
                <span>{t("PassengerPicker.childrenAgePickerTitle", { number: i + 1 })}</span>
                <span>{t("PassengerPicker.childrenAgePickerDisclamer")}</span>
              </div>
              <AgeSelect value={v} onChange={handleChildrenAgeChange(i)}>
                <MenuItem disabled value={"NO_SET"} className={styles.item(theme)}>
                  {t("PassengerPicker.children.NO_SET")}
                </MenuItem>
                <MenuItem className={styles.item(theme)} value={"INFANT_IN_ARMS"}>
                  {t("PassengerPicker.children.INFANT_IN_ARMS")}
                </MenuItem>
                <MenuItem className={styles.item(theme)} value={"INFANT_ON_SEAT"}>
                  {t("PassengerPicker.children.INFANT_ON_SEAT")}
                </MenuItem>
                <MenuItem className={styles.item(theme)} value={"CHILDREN"}>
                  {t("PassengerPicker.children.CHILDREN")}
                </MenuItem>
                {/* <MenuItem className={styles.item(theme)} value={"CHILDREN_ADULT"}>
                  {t("PassengerPicker.children.CHILDREN_ADULT")}
                </MenuItem> */}
              </AgeSelect>
            </div>
            {childrenErrors[i] && <span className={styles.error(theme)}>{childrenErrors[i]}</span>}
          </div>
        ))}
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("PassengerPicker.seniorTitle")}</span>
              <span>{t("PassengerPicker.seniorDisclamer")}</span>
            </div>
            <Counter
              onChange={handleCountChange("seniors")}
              maxValue={maxPsgrs + seniors}
              value={seniors}
              minValue={0}
            />
          </div>
        </div>
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("PassengerPicker.disabledTitle")}</span>
            </div>
            <Counter
              onChange={handleCountChange("disableds")}
              maxValue={maxPsgrs + disableds}
              value={disableds}
              minValue={0}
            />
          </div>
        </div>
      </div>
      {allPassengersError && (
        <span className={styles.error(theme)} style={{ marginTop: "16px" }}>
          {allPassengersError}
        </span>
      )}
    </div>
  )
}

export default PassengerPicker
