import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      width: "100%",
      boxSizing: "border-box",
      backgroundColor: t.colors.background_primary,
      border: `1px solid ${t.colors.background_darker}`,
      borderRadius: "4px"
    }),
  clusterContainer: css({
    display: "flex",
    width: "100%",
    boxSizing: "border-box",
    padding: "0 16px"
  }),
  clusterInfoContainer: css({
    display: "flex",
    flexDirection: "column",
    flex: "2 1 auto",
    padding: "24px 0"
  }),
  providerInfo: css({
    borderTop: "1px solid #EAEAEA",
    paddingTop: "16px",
    marginTop: "16px",
    textTransform: "uppercase"
  }),
  priceContainer: css({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "320px",
    flexDirection: "column",
    flexShrink: 0,
    boxSizing: "border-box",
    padding: " 24px 0px 24px 16px",
    borderLeft: "1px solid #eaeaea",
    marginLeft: "16px"
  }),
  installments: css({
    marginTop: "16px"
  })
}
