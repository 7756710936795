import { useTranslation } from "react-i18next"
import Text from "@basset-la/components-commons/dist/components/Text"
import React from "react"
import { DocumentOption } from "../../model/roomSection"
import { I18N_NS } from "../../utils/constants"
import Select from "@basset-la/components-commons/dist/components/Select"
import styles from "./RoomSection.styles"
import { useTheme } from "@basset-la/themed-components"
import TextField from "@basset-la/components-commons/dist/components/TextField"

interface Props {
  onChange: (e: any) => void
  documentTypeName: string
  documentTypeValue: string
  documentNumberValue: string
  documentNumberName: string
  documentTypeErrorText?: string
  documentNumberErrorText?: string
  documentOptions?: DocumentOption[]
  disabled?: boolean
  className?: string
}

const DocumentField: React.FC<Props> = ({
  onChange,
  documentTypeName,
  documentTypeValue,
  documentNumberValue,
  documentNumberName,
  documentTypeErrorText,
  documentNumberErrorText,
  documentOptions,
  className = ""
}: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const onChangeWithTarget = (name: string, value: string) => {
    onChange({ target: { name: name, value: value } })
  }

  const options: DocumentOption[] = documentOptions !== undefined ? documentOptions : t("DocumentField.documentOptions")
  const docErrorText = documentTypeErrorText ? documentTypeErrorText : documentNumberErrorText

  return (
    <div className={`${styles.documentFieldContainer} ${className}`}>
      <Text className={styles.textFieldTitle(theme)} size={14} variant="regular">
        {t("DocumentField.typeTitle")}
      </Text>
      <div className={styles.fieldsContainer}>
        <Select
          className={`${styles.documentTypeContainer} ${styles.grow(40)} ${styles.textField(theme)} ${styles.select}`}
          variant="item"
          values={options}
          selected={[documentTypeValue]}
          onSelect={values => onChangeWithTarget(documentTypeName, values[0])}
          error={!!documentTypeErrorText}
          fullWidth
        />
        <TextField
          className={`${styles.documentNumberContainer} ${styles.grow(60)} ${styles.textField(theme)}`}
          variant="text"
          placeholder={t("DocumentField.numberPlaceholder")}
          value={documentNumberValue}
          onChange={e => onChangeWithTarget(documentNumberName, e.target.value)}
          error={!!documentNumberErrorText}
          fullWidth
        />
      </div>
      {docErrorText && (
        <Text className={styles.textError} color="error">
          {docErrorText}
        </Text>
      )}
    </div>
  )
}

export default DocumentField
