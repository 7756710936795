import React from "react"
import { Cabin } from "../../model"
import styles from "./CabinType.styles"
import Tooltip from "@material-ui/core/Tooltip"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import ErrorIcon from "@material-ui/icons/Error"
export interface Props {
  variant: "mini" | "full" | "tooltip"
  cabin: Cabin
}

const UNKNOW_CLASS = "Unknow"

const CABIN_DETAIL_COLOR = "#4A81E2"

const CabinType: React.FC<Props> = ({ variant, cabin }) => {
  const theme = useTheme()

  const { t } = useTranslation(I18N_NS)

  const getCabinDetail = (name: string): JSX.Element => {
    if (name !== UNKNOW_CLASS) {
      return <p>{cabin.name}</p>
    }

    return (
      <>
        <p>
          {t("Cabin.title")} <ErrorIcon className={styles.noClassIcon(theme)} />
        </p>
        <p>{t("Cabin.notAvailableTitle")}</p>
      </>
    )
  }

  const getCabinTextColor = (name: string): string | undefined => {
    if (name === UNKNOW_CLASS) {
      return
    }

    return CABIN_DETAIL_COLOR
  }

  const cabinColor = getCabinTextColor(cabin.name)

  const component = (variant: "mini" | "full") => {
    switch (variant) {
      case "mini":
        return (
          <div className={styles.mini}>
            <span>{cabin.code}</span>
          </div>
        )
      case "full":
        return <div className={styles.full(cabinColor)}>{getCabinDetail(cabin.name)}</div>
    }
  }

  if (variant === "mini" || variant === "full") return component(variant)

  return (
    <Tooltip
      className={styles.rootTooltip}
      classes={{
        tooltip: styles.tooltip(theme),
        popper: styles.popper(theme)
      }}
      title={<React.Fragment>{component("full")}</React.Fragment>}
    >
      {component("mini")}
    </Tooltip>
  )
}

export default CabinType
