import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

const StandardBedIcon = (props: Props) => {
  return (
    <svg width="12px" height="10px" viewBox="0 0 12 10" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1.0_Desktop_detalle" transform="translate(-146.000000, -732.000000)" fill="#4A4A4A">
          <g id="habitacion" transform="translate(128.000000, 672.000000)">
            <g id="Group-6" transform="translate(18.000000, 60.000000)">
              <path
                d="M0.920799139,0.077229572 C0.85365517,0.144373541 0.843569567,0.376249027 0.843569567,1.85140856 C0.843569567,3.31157977 0.85365517,3.54452918 0.91580303,3.52090272 C0.956005365,3.50568093 1.15505595,3.43382101 1.36433221,3.35897276 L1.74062793,3.22440467 L1.74062793,2.82074708 C1.74062793,2.35722957 1.79371743,2.24535409 2.08447229,2.09673152 C2.45030887,1.90968093 3.02640225,1.83151751 3.84865906,1.85733852 C4.70584194,1.88423346 5.10641782,1.97080156 5.37863571,2.18810895 C5.52492365,2.30488716 5.55546062,2.36474708 5.56802093,2.55908171 L5.58296256,2.79081712 L6.27569408,2.79081712 L6.27569408,2.58649027 C6.27569408,2.30680156 6.47502482,2.12558755 6.94349175,1.9793463 C7.25819992,1.88105837 7.40196645,1.8688249 8.2442077,1.8688249 C9.09639447,1.8688249 9.22797424,1.88031128 9.55991198,1.98345525 C9.78912599,2.05470817 9.98243338,2.15182879 10.0707291,2.24012451 C10.2052038,2.37459922 10.2127213,2.40462257 10.2127213,2.80328405 L10.2127213,3.22440467 L10.589017,3.35897276 C10.79834,3.43382101 10.9973439,3.50568093 11.0375462,3.52090272 C11.0996941,3.54452918 11.1097797,3.31157977 11.1097797,1.85140856 C11.1097797,0.376249027 11.0996941,0.144373541 11.0325501,0.077229572 C10.9633517,0.0080311284 10.4369859,0 5.97667463,0 C1.51636334,0 0.989997582,0.0080311284 0.920799139,0.077229572"
                id="Fill-1"
              ></path>
              <path
                d="M4.58126606,3.47122179 C2.13928941,3.70501167 0.569437271,4.28063813 0.130339995,5.10322179 C0.0240209288,5.30236576 0.0208925241,5.35736965 0.00688474198,7.28105837 C-0.00955105568,9.54359533 -0.0339245965,9.41901946 0.424923652,9.41901946 C0.898620151,9.41901946 0.893390579,9.42901167 0.893390579,8.52196109 L0.893390579,7.72459144 L11.0599587,7.72459144 L11.0599587,8.52196109 C11.0599587,9.42901167 11.0547291,9.41901946 11.5284256,9.41901946 C11.9872738,9.41901946 11.9629003,9.54359533 11.9464645,7.28105837 C11.9324567,5.35736965 11.9293283,5.30236576 11.8227758,5.10322179 C11.409126,4.32999222 9.92869019,3.74773541 7.78573299,3.51543969 C7.14781859,3.44628794 5.13228552,3.41845914 4.58126606,3.47122179"
                id="Fill-3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default StandardBedIcon
