import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: "row",
      flexWrap: isMobile ? "wrap" : "nowrap",
      width: "100%",
      backgroundColor: t.colors.background_primary,
      "@media(max-width:1024px)": {
        padding: "16px 0px",
        border: "1px solid #00000020"
      }
    }),
  clusterContainer: (mobile: boolean) =>
    css({
      flex: "2 1 auto",
      padding: !mobile ? "16px" : "0 16px"
    }),
  priceContainer: css({
    flex: "1 1 auto",
    padding: "16px"
  }),
  containerPriceMobile: (t: Theme) =>
    css({
      width: "100%",
      padding: "0px 0px 8px 0px",
      backgroundColor: t.colors.background_variant
    }),
  containerButtonMobile: css({
    width: "100%",
    padding: "0 16px"
  }),
  installments: (isMobile: boolean) =>
    css({
      padding: isMobile ? "8px 0px" : "16px 0px",
      display: "flex",
      flexDirection: isMobile ? "row" : "column",
      justifyContent: "space-between"
    })
}
