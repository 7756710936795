import React from "react"
import styles from "./LegTab.styles"
import { MergedLeg } from "../../utils/helpers/brands"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  legs: MergedLeg[]
  selectedLeg: number
  onSelectLeg: (idx: number) => void
}

const LegTab: React.FC<Props> = ({ legs, selectedLeg, onSelectLeg }) => {
  const theme = useTheme()

  return (
    <div className={styles.root}>
      {legs.map((leg, idx) => (
        <div key={`tab_${idx}`} className={styles.item(theme, selectedLeg === idx)} onClick={() => onSelectLeg(idx)}>
          <div
            className={styles.name(theme, selectedLeg === idx)}
          >{`${leg.origin.code} - ${leg.destination.code}`}</div>
        </div>
      ))}
    </div>
  )
}

export default LegTab
