import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  addRoomButton: (t: Theme) =>
    css({
      ":nth-child(4)": {
        marginLeft: 0
      },
      "> .MuiButton-root": {
        color: t.colors.brand_primary,
        width: "fit-content",
        minWidth: 0,
        fontSize: "15px",
        textTransform: "none",
        lineHeight: "1.5",
        height: "40px",
        fontWeight: "normal",
        "&.Mui-disabled": {
          color: t.colors.text_variant,
          " .MuiSvgIcon-root": {
            color: t.colors.text_variant
          }
        },
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary,
          marginRight: "8px"
        }
      }
    }),
  roomsPickerContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  }),
  roomsContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%"
  }),
  roomsRow: css({
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
    alignItems: "end",
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: "0"
    }
  }),
  room: css({
    flex: "1 1 auto",
    width: "100%",
    "@media(min-width: 1024px)": {
      maxWidth: "288px"
    },
    "&:last-of-type": {
      margin: 0
    }
  })
}
