import React from "react"
import styles from "./ClusterRates.styles"
import ProductRate from "./ProductRate"
import { ProductRateModel } from "./types"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  rates: ProductRateModel[]
  onSelectRate: (idx: number) => void
}

const ClusterRates: React.FC<Props> = ({ rates, onSelectRate }) => {
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      {rates.map((r, i) => {
        return <ProductRate key={`rate_${i}`} rate={r} onClick={() => onSelectRate(i)} />
      })}
    </div>
  )
}

export default ClusterRates
