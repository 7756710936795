import React from "react"
import { Price as FlightPrice, Currency } from "../../model/types"
import {
  convertToResellerPriceBreakdown,
  convertToResellerCostEffectivenessBreakdown,
  calculateResellerPayment
} from "../../utils/helpers/helpers"
import PriceboxBreakdown from "../PriceboxBreakdown/PriceboxBreakdown"
import PriceboxBreakdownCostEffectiveness from "../PriceboxBreakdownCostEffectiveness/PriceboxBreakdownCostEffectiveness"
import PriceboxBreakdownPaymentDetail from "../PriceboxBreakdownPaymentDetail/PriceboxBreakdownPaymentDetail"
import styles from "./PriceboxBreakdownReseller.styles"
import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"
import PriceboxBreakdownVoucherDetail from "@basset-la/components-products/dist/components/PriceboxBreakdownVoucherDetail"
import { ReservationProduct } from "@basset-la/components-products/dist/components/PriceboxBreakdownVoucherDetail/types"

export interface Props {
  flightPrice: FlightPrice
  currencySelector?: Currency
  ownerType?: "RESELLER" | "AGENCY"
  reservationProducts?: ReservationProduct
  onGenerateVoucher?: () => void
}

const PriceboxBreakdownReseller: React.FC<Props> = ({
  flightPrice,
  currencySelector,
  ownerType,
  reservationProducts,
  onGenerateVoucher
}) => {
  const priceBreakdown = convertToResellerPriceBreakdown(flightPrice)
  const costEffectivenessBreakdown = convertToResellerCostEffectivenessBreakdown(flightPrice)
  const costDetailBreakdown = calculateResellerPayment(flightPrice)

  const isVoucherBlocked = (p?: ReservationProduct): boolean => {
    return !!p?.statement && !!p?.status && p.statement === "CASHED" && p.status === "ISSUED"
  }

  return (
    <div className={styles.root}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <PriceboxBreakdown priceBreakdown={priceBreakdown} ownerType={ownerType} />
      <PriceboxBreakdownCostEffectiveness costEffectivenessBreakdown={costEffectivenessBreakdown} />
      <PriceboxBreakdownPaymentDetail paymentDetailBreakdown={costDetailBreakdown} />
      <PriceboxBreakdownVoucherDetail
        reservationProducts={reservationProducts}
        isVoucherBlocked={() => isVoucherBlocked(reservationProducts)}
        onGenerateVoucher={onGenerateVoucher}
      />
    </div>
  )
}

export default PriceboxBreakdownReseller
