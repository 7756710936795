import React from "react"
import styles from "./AccommodationInfo.styles"
import AnimateHeight from "react-animate-height"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  title: string
  description: string
  isMobile: boolean
}

const AccommodationInfo: React.FC<Props> = ({ title, description, isMobile }) => {
  const [viewMore, setViewMore] = React.useState(false)
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  let height: string | number = "auto"
  if (isMobile && !viewMore) {
    height = 96
  }

  const handleOnClick = () => {
    setViewMore(!viewMore)
  }

  return (
    <div className={styles.root(theme)}>
      <h3 className={styles.title}>{title}</h3>
      {isMobile ? (
        <AnimateHeight duration={300} height={height}>
          <p dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />
        </AnimateHeight>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />
      )}
      {isMobile && (
        <Button onClick={handleOnClick} className={styles.button(theme)}>
          {t("AccommodationInfo.viewMore", { context: `${!viewMore}` })}
        </Button>
      )}
    </div>
  )
}

export default AccommodationInfo
