import Button from "@material-ui/core/Button"
import moment from "moment"
import * as React from "react"
import { useTranslation } from "react-i18next"
import styles from "./MobileRoomCluster.styles"
import { useTheme } from "@basset-la/themed-components"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import RoomDescription from "./RoomDescription"
import { I18N_NS } from "../../utils/constants"
import { RoomCluster as RoomClusterType } from "../../model/roomCluster"
import RateDetail from "../RateDetail"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"

interface Props {
  roomClusters: RoomClusterType[]
  totalGuests: number
  onRateSelection: (ri: string, trackingId?: string) => void
  taxesDisclaimers?: string[]
  customButtons?: CustomButtonText[]
}

const MobileRoomCluster: React.FC<Props> = ({
  roomClusters,
  totalGuests,
  onRateSelection,
  taxesDisclaimers,
  customButtons
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const theme = useTheme()
  const checkin = moment(roomClusters[0].rates[0].checkin)
  const checkout = moment(roomClusters[0].rates[0].checkout)
  const nights = checkout.diff(checkin, "days")

  const locale = getLocale(i18n)

  const reserveCustomButton = customButtons?.find(customButton => customButton.type === "RESERVE")

  return (
    <div className={`${styles.containerStyle}`}>
      <p className={`${styles.titleStyle}`}>{t("MobileRoomCluster.rooms")}</p>
      <p className={`${styles.rateInformationStyle}`}>
        {t("MobileRoomCluster.price_for_nights_and_guests", { nights: nights, guests: totalGuests })}
      </p>
      <div className={`${styles.roomClustersContainerStyle}`}>
        {roomClusters.map((e, i) => (
          <div key={i} className={[i === 0 && styles.highlightedStyle(theme), styles.roomClusterStyle].join(" ")}>
            <div className={`${styles.roomsContainerStyle}`}>
              {e.rooms.map((room: any, roomIndex: number) => (
                <RoomDescription
                  amount={e.rooms.length}
                  beds={room.bed_configuration}
                  capacity={room.capacity}
                  name={room.name}
                  key={roomIndex}
                />
              ))}
            </div>
            <div className={`${styles.ratesContainerStyle}`}>
              {e.rates.map((rate: any, rateIndex: number) => (
                <div key={rateIndex} className={`${styles.rateStyle}`}>
                  <RateDetail rate={rate} />
                  <div className={`${styles.priceContainerStyle}`}>
                    <div>
                      <p className={`${styles.priceStyle}`}>
                        {rate.fare.currency}{" "}
                        <span>{formatPrice(rate.fare.total, locale, rate.fare.currency, { hideCurrency: true })}</span>
                      </p>
                      {!!taxesDisclaimers &&
                        taxesDisclaimers.map((d: string, index: number) => (
                          <p key={index} className={`${styles.taxDisclamerStyle}`}>
                            {d}
                          </p>
                        ))}
                    </div>
                    <Button
                      variant={"contained"}
                      className={`${styles.buttonStyle(theme)}`}
                      onClick={() => onRateSelection(rate.id, rate.tracking_id)}
                    >
                      {reserveCustomButton ? reserveCustomButton.value : t("MobileRoomCluster.reserve")}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MobileRoomCluster
