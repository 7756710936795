import React from "react"
import { Leg, Airport } from "../../model/types"
import LegDetailSection from "./LegDetailSection"
import AirlineLogo from "../AirlineLogo/AirlineLogo"
import styles from "./LegDetail.styles"
import { formatDuration, sanitizeAirportName } from "../../utils/helpers"
import { I18N_NS } from "../../utils/constants/constants"
import { SquareIcon } from "../../icons/SquareIcon"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import CabinType from "../CabinType"

interface Props {
  leg: Leg

  /** set to `true` to force mobile L&F */
  mini?: boolean
}

const LegDetail: React.FunctionComponent<Props> = ({ leg, mini = false }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const operatingCarrier = leg.operating_carrier || leg.marketing_carrier

  const getAircraftTypeText = (aircraftType: string): string => {
    switch (aircraftType) {
      case "BUS":
        return t("Equipments.bus")

      case "TRAIN":
        return t("Equipments.train")

      case "OTHER":
        return t("Equipments.other")

      default:
        return aircraftType
    }
  }

  return (
    <div className={styles.root(theme)}>
      <LegDetailSection
        key={1}
        mini={mini}
        icon={<AirlineLogo airlineCode={operatingCarrier.code} aircraftType={leg.aircraft_type} />}
        className={styles.airlineContainer}
        firstSection={
          <p id="leg-detail-airline-name" className={styles.airline}>{`${operatingCarrier.name} ${
            leg.flight_number
          } · ${getAircraftTypeText(leg.aircraft_type)}`}</p>
        }
      />
      <LegDetailSection
        key={2}
        mini={mini}
        firstSection={createDestinationInfo(leg.departure_time, leg.origin, mini, "leg-detail-aero-origin")}
        secondSection={<p className={styles.duration(mini)}>{formatDuration(leg.duration)}</p>}
        thirdSection={createDestinationInfo(leg.arrival_time, leg.destination, mini, "leg-detail-aero-destination")}
        fourthSection={<CabinType variant="full" cabin={leg.cabin_type} />}
      />
      {leg.technical_stops &&
        leg.technical_stops.length > 0 &&
        leg.technical_stops.map((e, i) => (
          <LegDetailSection
            key={`technicalStop_${i}`}
            mini={mini}
            icon={
              <div className={styles.technicalStopIcon}>
                <SquareIcon />
              </div>
            }
            className={styles.technicalStop(theme)}
            backgroundColor={theme.colors.background_variant}
            firstSection={
              <p className={styles.technicalStopDescription(mini)}>
                {t("LegDetail.technicalStop", { stop: e.location.name })}{" "}
                <span className={styles.bold}>{`(${e.location.code})`}</span>
              </p>
            }
            secondSection={<p className={styles.technicalStopDuration(mini)}>{formatDuration(e.duration)}</p>}
            thirdSection={<div />}
            fourthSection={<div />}
          />
        ))}
    </div>
  )
}

const createDestinationInfo = (time: string, airport: Airport, mini: boolean | undefined, airportId?: string) => (
  <div className={styles.destinationInfo(mini)}>
    <p className={styles.bold}>{time}</p>
    <p>
      <span id={airportId} className={styles.bold}>
        {airport.code}
      </span>{" "}
      <span>{sanitizeAirportName(airport.name)}</span>
    </p>
  </div>
)

export default LegDetail
