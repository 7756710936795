import React from "react"

const TikTokIcon = () => {
  return (
    <svg className="MuiSvgIcon-root" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2 3.20001H4.80001C3.91601 3.20001 3.20001 3.91601 3.20001 4.80001V19.2C3.20001 20.084 3.91601 20.8 4.80001 20.8H19.2C20.084 20.8 20.8 20.084 20.8 19.2V4.80001C20.8 3.91601 20.0832 3.20001 19.2 3.20001ZM18.1512 10.7792C18.0472 10.7888 17.9424 10.7952 17.8368 10.7952C16.6408 10.7952 15.5896 10.1808 14.9776 9.25041C14.9776 11.6896 14.9776 14.4656 14.9776 14.512C14.9776 16.66 13.236 18.4008 11.0888 18.4008C8.94161 18.4 7.20001 16.6584 7.20001 14.5112C7.20001 12.3632 8.94161 10.6224 11.0888 10.6224C11.1704 10.6224 11.2496 10.6296 11.3288 10.6344V12.5512C11.2488 12.5416 11.1712 12.5272 11.0888 12.5272C9.99281 12.5272 9.10401 13.416 9.10401 14.512C9.10401 15.608 9.99201 16.4968 11.0888 16.4968C12.1856 16.4968 13.1536 15.6328 13.1536 14.5368C13.1536 14.4928 13.1728 5.60081 13.1728 5.60081H15.004C15.176 7.23841 16.4984 8.53121 18.1512 8.64961V10.7792Z" />
    </svg>
  )
}
export default TikTokIcon
