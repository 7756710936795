import React from "react"

import { useTranslation } from "react-i18next"

import { useTheme } from "@basset-la/themed-components"

import { I18N_NS } from "../../utils/constants/constants"
import { formatPrice, getLocale } from "../../utils/helpers"
import { OriginalCurrency } from "../CurrencySwitcher/CurrencySwitcher"
import styles from "./ExchangeRateText.styles"

export interface Props {
  containerClassName?: string
  labelClassName?: string
  infoClassName?: string
  currency: string
  originalCurrency: OriginalCurrency
  direction?: "row" | "column"
}

const ExchangeRateText: React.FC<Props> = ({
  containerClassName,
  labelClassName,
  infoClassName,
  currency,
  originalCurrency,
  direction = "column"
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const containerStyles = containerClassName
    ? `${styles.root(direction)} ${containerClassName}`
    : `${styles.root(direction)}`
  const labelStyles = labelClassName ? `${styles.label(theme)} ${labelClassName}` : `${styles.label(theme)}`
  const infoStyles = infoClassName ? `${styles.info(theme)} ${infoClassName}` : `${styles.info(theme)}`

  return (
    <div className={containerStyles}>
      <span className={labelStyles}>{`${t("CurrencySwitcher.currency_type")}${
        direction === "column" ? "" : ":"
      }`}</span>
      <span className={infoStyles}>
        {formatPrice(1, locale, originalCurrency.currency_code, { hideCurrency: true })}{" "}
        {originalCurrency.currency_code} ={" "}
        {formatPrice(originalCurrency.ratio, locale, currency, { hideCurrency: true })} {currency}
      </span>
    </div>
  )
}

export default ExchangeRateText
