import React, { useState } from "react"
import styles from "./ClusterRatesDesktop.styles"
import ProductRateDesktop from "./ProductRateDesktop"
import { ProductRateDesktopModel } from "./types"
import { useTheme } from "@basset-la/themed-components"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  rates: ProductRateDesktopModel[]
  onSelectRate: (idx: number) => void
}

const SHOW_LESS_RATE = 3

const ClusterRatesDesktop: React.FC<Props> = ({ rates, onSelectRate }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  const [showCount, setShowCount] = useState(SHOW_LESS_RATE)
  const [expanded, setExpanded] = useState(false)

  const handleShowMore = () => {
    setExpanded(!expanded)
    setShowCount(expanded ? SHOW_LESS_RATE : rates.length)
  }

  return (
    <div className={styles.root(theme)}>
      {rates.slice(0, showCount).map((r, i) => (
        <ProductRateDesktop key={`rate_${i}`} rate={r} onClick={() => onSelectRate(i)} />
      ))}
      {rates.length > SHOW_LESS_RATE && (
        <div className={styles.showMore(theme)} onClick={handleShowMore}>
          {expanded ? t("ClusterRatesDesktop.see_less") : t("ClusterRatesDesktop.see_more")}
          {expanded ? (
            <ArrowDropUpIcon htmlColor={theme.colors.brand_primary} />
          ) : (
            <ArrowDropDownIcon htmlColor={theme.colors.brand_primary} />
          )}
        </div>
      )}
    </div>
  )
}

export default ClusterRatesDesktop
