import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: theme.colors.text_primary,
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_primary,
      overflow: "hidden",
      boxSizing: "border-box",
      borderRadius: "4px",
      transition: "box-shadow .3s ease",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ":hover": {
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12)"
      },
      "@media screen and (max-width: 1024px)": {
        flexDirection: "column"
      }
    }),
  infoContainer: css({
    display: "flex",
    minWidth: 0,
    height: "200px",
    flexShrink: 1,
    flexGrow: 1,
    "@media screen and (max-width: 1024px)": {
      height: "auto",
      minHeight: "280px"
    }
  }),
  priceContainer: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      flexGrow: 0,
      minWidth: "200px",
      borderLeft: `1px solid ${theme.colors.background_darker}`,
      padding: "8px 16px",
      justifyContent: "space-between",
      boxSizing: "border-box",
      " > p": {
        margin: 0,
        display: "flex",
        flexDirection: "column"
      },
      "@media screen and (max-width: 1024px)": {
        padding: "16px",
        borderLeft: "none",
        borderTop: `1px solid ${theme.colors.background_darker}`
        //maxHeight: "168px",
        //maxWidth: "unset"
      }
    }),
  taxesDisclaimer: (theme: Theme) =>
    css({
      fontSize: "12px",
      color: theme.colors.text_variant,
      lineHeight: "1.33",
      letterSpacing: "0.13px",
      minHeight: "16px"
    }),
  mobPrice: css({
    padding: "8px"
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.brand_primary,
        textTransform: "unset",
        color: theme.colors.text_white,
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      }
    })
}
