import React, { useState, useRef } from "react"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import TVIcon from "@material-ui/icons/TvRounded"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import styles from "./AmenitiesSelect.styles"
import { SearchboxAmenities } from "../Searchbox/types"
import Popover from "@material-ui/core/Popover"
import AmenitiesPicker from "./AmenitiesPicker"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import Text from "@basset-la/components-commons/dist/components/Text"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  amenities: SearchboxAmenities
  onChange: (v: SearchboxAmenities) => void
}

const AmenitiesSelect: React.FC<Props> = ({ amenities, onChange }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const buildAmenitiesValue = (a: SearchboxAmenities) => {
    if (a.allDayCheckin || a.parking || a.swimmingPool || a.wifi) {
      let parts: string[] = []
      if (a.allDayCheckin) {
        parts.push(t("AmenitiesSelect.allDayCheckin"))
      }
      if (a.parking) {
        parts.push(t("AmenitiesSelect.parking"))
      }
      if (a.swimmingPool) {
        parts.push(t("AmenitiesSelect.swimmingPool"))
      }
      if (a.wifi) {
        parts.push(t("AmenitiesSelect.wifi"))
      }
      return parts.join(", ")
    }
    return t("AmenitiesSelect.NO_VALUE")
  }

  const [open, setOpen] = useState<boolean>(false)
  const [amenitiesValue, setAmenitiesValue] = useState<string>(buildAmenitiesValue(amenities))
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnChange = (a: SearchboxAmenities) => {
    onChange(a)
    setAmenitiesValue(buildAmenitiesValue(a))
  }

  const handleOnDone = (a: SearchboxAmenities) => {
    onChange(a)
    setAmenitiesValue(buildAmenitiesValue(a))
    setOpen(false)
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("AmenitiesSelect.label")}
        </Text>
        <SearchboxTextField
          value={amenitiesValue}
          InputProps={{ disabled: true }}
          leftIcon={<TVIcon className={styles.icon(theme)} />}
          rightIcon={<ArrowIcon />}
          onClick={handleOpen}
          className={styles.textField}
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={open}
          onClose={handleClose}
          anchorEl={inputRef.current}
        >
          <AmenitiesPicker amenities={amenities} onChange={handleOnChange} onDone={handleOnDone} />
        </Popover>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          onClose={handleClose}
          onAction={handleClose}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              InputProps={{ disabled: true }}
              leftIcon={<TVIcon className={styles.icon(theme)} />}
              value={amenitiesValue}
            />
          }
        >
          <AmenitiesPicker amenities={amenities} onChange={handleOnChange} onDone={handleOnDone} />
        </FullScreenDialog>
      )}
    </>
  )
}

export default AmenitiesSelect
