import { css } from "@emotion/css"

const styles = {
  container: (hide: boolean, link: string) =>
    css({
      opacity: hide ? 0 : 1,
      width: hide ? "0px" : "100%",
      height: hide ? "0px" : "100%",
      transition: "opacity 0.75s linear, width 0s, height 0s",
      overflow: "hidden",
      cursor: !!link ? "pointer" : undefined
    }),
  image: css({
    width: "100%"
  })
}

export default styles
