import { BannerPositionInfo } from "@basset-la/components-commons"

export type SourceType = "GDS" | "NDC"

export type Cluster = {
  segments: Array<Segment>
  validating_carrier: Airline
  provider_id: string
  source: string
  source_type: SourceType
  route_type: string
  flight_type: FlightType
  price: Price
  fare_type?: string
  account_codes_used?: string[]
  form_of_payments?: FormOfPayment[]
  payments_url?: string
  tracking_id?: string
  last_ticketing_date?: string
  upsell_fares?: UpSellFare[]
  upsell?: boolean
}

export type RecommendedItinerary = {
  id: string
  duration: string
  price: Price
}

export type OrderByOptions = "recommendation" | "price" | "duration"

export type RecommendationOptions = "recommended" | "cheapest" | "shortest_flight"

export type ItineraryRecommendations = {
  cheapest: RecommendedItinerary
  recommended: RecommendedItinerary
  shortest_flight: RecommendedItinerary
}

export type ChangeFareCluster = Cluster & {
  original_fare: number
}

export type ReservationPassenger = {
  type: "ADT" | "CHD" | "INF" | "SRC" | "DIS"
  firstname: string
  lastname: string
  document: {
    type: string
    number: string
  }
}

export type Reservation = {
  id: string
  reservation_id: string
  cluster: Cluster
  pnr: string
  expiration_date: string
  passengers: Array<ReservationPassenger>
  created_at: string
  provider_id: string
}

export type FlightType = "ONE_WAY" | "ROUND_TRIP" | "MULTIPLE_DESTINATIONS"

export type Segment = {
  origin: Airport
  destination: Airport
  departure_date: string
  options: Array<SegmentOption>
}

export type Airport = {
  code: string
  name: string
  country_code: string
}

export type SegmentOption = {
  id: string
  departure_date: string
  departure_time: string
  arrival_date: string
  arrival_time: string
  duration: string
  legs: Leg[]
  baggage_allowances?: BaggageAllowance[]
}

export type BaggageAllowance = {
  quantity?: number
  weight?: number
  type: string // CHECKED_BAGGAGE, CARRY_ON, PERSONAL_ITEM
  unit?: string
  dimension?: number
  dimension_unit?: string
  chargeable?: boolean
}

export type Leg = {
  origin: Airport
  destination: Airport
  departure_date: string
  departure_time: string
  arrival_date: string
  arrival_time: string
  marketing_carrier: Airline
  operating_carrier: Airline
  duration: string
  flight_number: string
  aircraft_type: string
  technical_stop?: TechnicalStop //@Deprecated : use technicalStops
  technical_stops?: Array<TechnicalStop>
  cabin_type: Cabin
  status?: string
  record_locator?: string
  brand?: Brand
  id: string
  fare_type?: string
}

export type Airline = {
  code: string
  name: string
}

export type Stop = {
  value: string
  label: string
}

export type TechnicalStop = {
  location: Airport
  departure_date: string
  departure_time: string
  arrival_date: string
  arrival_time: string
  duration: string
}

export type Cabin = {
  code: string
  name: string
  quantity: number
}

export type Passenger = {
  name: string
  document: PassengerDocument
  ticketNumber: string
}

export type PassengerDocument = {
  type: string
  number: string
}

export type PriceBreakdown = {
  rate: Rate
  taxes: Taxes
  charges: Charges
  currency: string
  total: number
}

export type Rate = {
  adult?: PassengerFare
  children?: PassengerFare
  infants?: PassengerFare
  seniors?: PassengerFare
  disableds?: PassengerFare
  total: number
}

export type PassengerFare = {
  quantity: number
  total: number
}

export type Taxes = {
  agencyVAT?: number
  resellerVAT: number
  extraTaxes: number
  paisTax?: number
  total: number
}

export type Charges = {
  resellerCharges: Charge
  agencyCharges?: Charge
  total: number
}

export type Charge = {
  markup: number
  operatingCost: number
  fee: number
  adjustment?: number
  total: number
}

export type CostEffectivenessBreakdown = {
  ownCostEffectiveness: CostEffectiveness
  currency: string
  total: number
  resellerCeded?: number
  airlineCeded?: CededCostEffectiveness
  agencyCeded?: CededCostEffectiveness
}

export type CostEffectiveness = {
  total: number
  markup: number
  operatingCost: number
  fees: number
  adjustment?: number
}

export type CededCostEffectiveness = {
  over: number
  commission: number
  total: number
}

export type CostPayment = {
  total: number
  currency: string
  taxesAndCharges: number
  commision: number
  fare: number
  over: number
}

export type Price = {
  adults?: APIPassengerFare
  seniors?: APIPassengerFare
  children?: APIPassengerFare
  infants?: APIPassengerFare
  disabled?: APIPassengerFare
  charges: number
  taxes: number
  total: number
  currency: string
  vat_percentage?: number
  commision_rule_data?: Commission
  over_commision_rule_data?: Commission
  reseller_charges?: APICharge
  agency_charges?: APICharge
  detail?: object // @deprecated
  agency_cost_effectiveness: number
  fees?: ChargeType[]
}

export type APIPassengerFare = {
  fare: number
  taxes?: ChargeType[]
  quantity: number
  refund_policy?: Policy[]
  exchange_policy?: Policy[]
}

export type Policy = {
  applies: "BEFORE" | "AFTER"
  valid: boolean
  amount: number
  percentage: number
  currency?: string
}

export type ChargeType = {
  total: number
  type: string
}

export type APICharge = {
  operating_cost: number
  fees: number
  markup: number
}

export type Commission = {
  currency: string
  ceded_amount: number
  airline_ceded_amount?: number
  agency_amount?: number
  reseller_amount: number
  airline_amount?: number
}

export type OriginalCurrency = {
  currency_code: string
  ratio: number
}

export type Currency = {
  currency: string
  originalCurrency: OriginalCurrency
  enabledSwitch: boolean
  selectedCurrency?: (selected: string) => void
}

export type ClusterList = {
  cluster: JSX.Element
  pricebox: JSX.Element
}

export interface FlightSearch {
  legs: Leg[]
  flightType: string
  adultsQuantity: number
  childs?: string[]
  seniorsQuantity?: number
  disabledQuantity?: number
  cabinType?: string
  baggageType?: string
  stops?: string
}

export type FormOfPayment = {
  card: string
  card_description: string
  bank: string
  bank_description: string
  description: string
  surcharge: number
  installments: number[]
  bins: string[] | null
  coefficient: number
}

export interface FlightClusterFilters {
  airlines: Set<string>
  stops: Set<string>
  outboundDepartureAirports: Set<string>
  outboundArrivalAirports: Set<string>
  inboundDepartureAirports: Set<string>
  inboundArrivalAirports: Set<string>
  outboundDepartureTime: Array<number>
  inboundDepartureTime: Array<number>
  sourceType: Set<string>
}

export interface FlightsClusterAvailableAirportFilter {
  outbound: Array<Airport>
  inbound: Array<Airport>
}

export interface FlightsClusterAvailableFilters {
  airlines: Array<Airline>
  source_type: Array<string>
  outbound_airports: FlightsClusterAvailableAirportFilter
  inbound_airports: FlightsClusterAvailableAirportFilter | null
  stops: Array<number>
}

export interface FlightsClusterActiveAvailableFilters {
  airlines: Array<Airline>
  outbound_airports: { [key: string]: Array<Airport> }
  inbound_airports: { [key: string]: Array<Airport> }
  stops: Array<Stop>
  sourcesType: Array<string>
}

export interface MatrixAirlineModel {
  code: string
  name: string
}

export interface MatrixStopModel {
  currency: string
  total: number
}

export interface MatrixModel {
  airline: MatrixAirlineModel
  stops: any
}

export class FetchError extends Error {
  statusCode: number

  constructor(statusCode: number, message: string) {
    super(message)
    this.statusCode = statusCode

    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export interface FlightResultsModel {
  clusters: Cluster[]
  matrix: MatrixModel[]
  filters: FlightsClusterAvailableFilters
  total: number
  viewAlert: boolean
  error: FetchError | null
}

export interface AdvertisingInfo {
  top?: BannerPositionInfo
  middle?: BannerPositionInfo
  bottom?: BannerPositionInfo
}

export type ServiceCategory =
  | "SEAT"
  | "MEAL_BEVERAGE"
  | "BRANDED_FARES"
  | "UPGRADE"
  | "TRAVEL"
  | "BAGGAGE"
  | "ENTERTAINMENT"
  | "LOUNGE"
  | "STANDBY"
  | "FREQUENT_FLYER"

export type ServiceType =
  | "BASIC_SEAT"
  | "CHANGEABLE_TICKET"
  | "SEAT_SELECTION"
  | "SNACK"
  | "UPGRADE_FLIGHT_UP"
  | "CHANGE_ANY_TIME"
  | "FIRST_CHANGE"
  | "MILEAGE_ACCRUAL"
  | "MEAL"
  | "CHANGE_BEFORE_DEPARTURE"
  | "UPGRADE_ELIGIBILITY"
  | "LATE_CHECK_IN"
  | "PRIORITY_BOARDING"
  | "ALCOHOLIC_DRINK"
  | "ECONOMY_TO_ECONOMY_PLUS"
  | "UNACCOMPANIED_MINOR"
  | "SAME_DAY_CHANGE"
  | "CHECKED_EXTRA_BAGGAGE"
  | "WIFI_ACCESS"
  | "IN_FLIGHT_ENTERTAIMENT"
  | "STREAMING_VIDEO"
  | "LOUNGE"
  | "SAME_DAY_STANDBY"
  | "LAST_TO_BOARD"
  | "CANCELLATION_TO_ECREDIT"
  | "OVERHEAD_BIN_SPACE"
  | "STANDARD_BOARDING"
  | "PREMIUM_SERVICE"
  | "REFUNDABLE_TICKET"
  | "FULL_FLAT_BED"
  | "VOUCHER_WIFI"
  | "CARRY_ON_BAGGAGE"
  | "HAND_ON_BAGGAGE"
  | "CHECKED_BAGGAGE"
  | "SEAT_XL"
  | "POCKET_WIFI"
  | "DAILY_PRESS"
  | "PRIORITY_SECURITY"
  | "UPGRADE_WITH_MILES"
  | "INTERNET_ACCESS"
  | "BASIC_SEAT_RESERVATION"
  | "PREMIUM_SEAT_RESERVATION"
  | "REFUND_BEFORE_DEPARTURE"
  | "PREMIUM_SEAT"
  | "PRIORITY_CHECKIN"
  | "PRIORITY BOARDING"
  | "MOBILE_PAYMENT"
  | "PRIORITY_SERVICES"
  | "CHANGE_AFTER_DEPARTURE"
  | "CHANGE_FEE"
  | "REFUND_AFTER_DEPARTURE"
  | "POINTS_ACCRUAL"
  | "PREPAID_BAGGAGE"
  | "BAGGAGE_INFANT_CART_SEAT"
  | "BAGGAGE_DELIVERY_PRIORITY"
  | "BAGGAGE_STROLLER"
  | "BAGGAGE_SUBSCRIPTION"
  | "BAGGAGE_ASSISTIVE_DEVICE"
  | "BAGGAGE_SPECIAL_CABIN"
  | "CARRY_ON_MUSICAL_INSTRUMENT"
  | "PET_IN_CABIN"
  | "BAGGAGE_INFANT"
  | "CHECKED_MUSICAL_INSTRUMENT"
  | "PET_IN_HOLD"
  | "MILES_BONUS_PURCHASE"
  | "SEAT_POWER"
  | "POWER_AC_110"
  | "HEADSET"
  | "PILLOW_AND_BLANKET"
  | "USB_POWER"
  | "LOUNGE_SLEEPING_ROOM"
  | "EXCLUSIVE_WAITING_AREA"
  | "STRETCHER"
  | "MEDICAL_ASSISTANCE"
  | "MEDICAL_CASE"
  | "OXYGEN"
  | "WHEELCHAIR"
  | "BEVERAGE"
  | "PRERESERVED_SEAT"
  | "CHARTER"
  | "DELAYED_ARRIVAL_WARRANTY"
  | "DENIED_BOARDING_COMPENSATION"
  | "HOLD_BOOKING"
  | "TRIP_INSURANCE"
  | "PASSENGER_ASSISTANCE"
  | "MEET_AND_GREET"
  | "UNACCOMPANIED_SENIOR"
  | "LIE_FLAT_SEAT"
  | "EXTRA_SEAT_LEG_ROOM"

interface BaseService {
  id: string
  category: ServiceCategory
  type: ServiceType
  amount?: number
  description?: string
}

export interface Ancillary extends BaseService {}

export interface Service extends BaseService {
  charge_type: "CHARGEABLE" | "INCLUDED" | "NOT_INCLUDED"
}

export interface Brand {
  id: string
  name: string
  services: Service[]
  ancillaries?: Ancillary[]
}

export interface UpSellPrice {
  amount: number
  percentage: number
}

export interface UpSellFareSegment {
  legs: UpSellFareLeg[]
  baggage_allowances?: BaggageAllowance[]
}

export interface UpSellFareLeg {
  id: string
  brand: Brand
  cabin: Cabin
}

export interface UpSellFare {
  id: string
  upsell_price: UpSellPrice
  price: Price
  segments: UpSellFareSegment[]
}

export interface ReservationProductFlight extends Reservation {
  voucher_url: string
  statement: string
  status: string
}
