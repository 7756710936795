import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { colors } from "../../utils/constants/constants"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    width: "80px",
    height: "40px",
    borderRadius: "3px",
    overflow: "hidden"
  }),
  leftButton: css({
    "&.MuiButtonBase-root": {
      borderTopLeftRadius: "3px",
      borderBottomLeftRadius: "3px",
      borderRight: "none !important"
    }
  }),
  rightButton: css({
    "&.MuiButtonBase-root": {
      borderTopRightRadius: "3px",
      borderBottomRightRadius: "3px",
      borderLeft: "none !important"
    }
  }),
  button: (t: Theme) =>
    css({
      "&.MuiButtonBase-root": {
        fontSize: "14px",
        lineHeight: "1.71",
        width: "50%",
        height: "100%",
        textAlign: "center",
        border: `1px solid ${t.colors.text_variant}`,
        color: t.colors.text_variant,
        backgroundColor: t.colors.background_variant,
        "&.Mui-disabled": {
          color: t.colors.text_white,
          border: `1px solid ${colors.GREEN3}`,
          backgroundColor: colors.GREEN3
        }
      }
    })
}
