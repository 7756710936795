import React from "react"
import styles from "./InfoIconWithPolicyTooltip.styles"
import { useTheme } from "@basset-la/themed-components"
import PolicyInfo from "../PolicyInfo"
import Tooltip from "@material-ui/core/Tooltip"
import SyncIcon from "@material-ui/icons/Sync"
import { Price } from "../../model/types"

export interface Props {
  price: Price
}

const InfoIconWithPolicyTooltip: React.FC<Props> = ({ price }) => {
  const theme = useTheme()
  const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(null)

  return (
    <>
      <Tooltip
        className={styles.rootTooltip}
        classes={{
          tooltip: styles.tooltip(theme),
          popper: styles.popper(theme)
        }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(arrowRef),
                element: arrowRef
              }
            }
          }
        }}
        title={
          <>
            {price && <PolicyInfo price={price} isTooltip={true} />}
            <span className={[styles.arrow, "arrow"].join(" ")} ref={setArrowRef} />
          </>
        }
      >
        <SyncIcon className={styles.info(theme)} />
      </Tooltip>
    </>
  )
}

export default InfoIconWithPolicyTooltip
