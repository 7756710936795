import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      "@media screen and (max-width: 1024px)": {
        padding: "0 16px"
      }
    }),
  title: css({
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: "1.25",
    margin: 0,
    marginBottom: "8px",
    "@media screen and (max-width: 1024px)": {
      lineHeight: "1.33",
      fontSize: "18px"
    }
  }),
  list: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.71",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    flex: 1,
    padding: 0,
    margin: 0,
    listStyleType: "disc",
    listStylePosition: "inside",
    "@media screen and (max-width: 1024px)": {
      flexDirection: "column"
    },
    "> li": {
      width: "calc(25% - 16px)",
      whiteSpace: "nowrap",
      marginRight: "16px",
      height: "24px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "@media screen and (max-width: 1024px)": {
        width: "auto",
        marginRight: 0,
        maxWidth: "100%"
      }
    }
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        fontSize: "14px",
        textTransform: "unset",
        fontWeight: 300,
        lineHeight: "1.71",
        color: theme.colors.brand_primary
      }
    })
}
