import React from "react"
import styles from "./Radio.styles"

import MaterialFormControlLabel from "@material-ui/core/FormControlLabel"
import MaterialRadio from "@material-ui/core/Radio"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  checked?: boolean
  onCheck?: (c: boolean) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  label?: string
  labelPlacement?: "end" | "start" | "top" | "bottom"
  disabled?: boolean
  className?: string
}

const Checkbox: React.FC<Props> = ({
  checked,
  onCheck,
  disabled,
  label,
  labelPlacement = "end",
  className,
  onChange
}) => {
  const theme = useTheme()

  return (
    <MaterialFormControlLabel
      className={`${styles.control} ${className}`}
      control={
        onCheck ? (
          <MaterialRadio
            checked={checked}
            disabled={disabled}
            className={styles.root(theme)}
            onChange={(e, c) => {
              if (onCheck) onCheck(c)
              if (onChange) onChange(e, c)
            }}
          />
        ) : (
          <MaterialRadio checked={checked} disabled={disabled} className={styles.root(theme)} />
        )
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default Checkbox
