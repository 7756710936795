import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  appbar: (t: Theme) =>
    css({
      "&.MuiAppBar-colorPrimary": {
        backgroundColor: t.colors.background_primary
      }
    }),
  toolbar: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "&.MuiToolbar-root": {
      alignItems: "start"
    }
  }),
  content: css({
    "&.MuiDialogContent-root": {
      padding: 8
    }
  }),
  backButton: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
    width: "100%"
  }),
  backButtonIcon: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      cursor: "pointer"
    }),
  toolbarComponent: css({
    marginBottom: "16px",
    width: "100%"
  }),
  actionButton: css({
    padding: "0 16px",
    width: "100%"
  })
}
