import React from "react"
import moment from "moment"
import "moment/locale/es"

import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

import styles from "./Voucher.styles"
import { Accommodation } from "../../model/types"
import Row from "./Row"
import Col from "./Col"
import Rooms from "./Rooms"
import CancelPenalties from "../CancelPenalties/CancelPenalties"
import Disclamer from "./Disclamer"
import CheckinInstructions from "./CheckinInstructions"
import AccommodationRemarks from "../AccommodationRemarks"
import { HotelIcon } from "../../icons/HotelIcon"
import Stars from "../Stars"
import Address from "../Address"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props {
  logoUrl: string
  companyName: string
  reservationId: string
  providerReservationId: string
  accommodation: Accommodation
  extraInformation: string
  showRemarks: boolean
  reconfirmationCode?: string
}

const Voucher: React.FC<Props> = ({
  logoUrl,
  companyName,
  reservationId,
  providerReservationId,
  accommodation,
  extraInformation,
  showRemarks,
  reconfirmationCode
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)

  return (
    <div className={styles.root(theme)}>
      <Row>
        <Col styles={styles.logoContainer}>
          <img className={styles.logo} src={logoUrl} alt={companyName} />
        </Col>
        <Col styles={styles.reservationNo(theme)}>
          <span className={styles.reservationNoTitle(theme)}>{t("Voucher.reservationNo")}</span>
          <b>{reservationId}</b>
        </Col>
        <Col styles={styles.providerReservationNo}>
          <div className={styles.providerReservationNoDetail(theme)}>
            <span className={styles.providerReservationNoTitle(theme)}>{t("Voucher.providerReservationNo")}</span>
            <span className={styles.providerReservationNoId(theme)}>{providerReservationId}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col styles={styles.hotelData(theme)}>
          <div className={styles.hotelDataTitle(theme)}>
            <HotelIcon />
            {t("Voucher.hotelTitle", { place: accommodation.city.name })}
          </div>
          <div className={styles.hotelInfo}>
            <img className={styles.hotelImg} src={accommodation.photo} alt="" />
            <div className={styles.hotelDesc}>
              <Text size={16} variant="bold">
                {accommodation.name}
              </Text>
              <Stars amount={accommodation.stars} />
              <Address
                address={accommodation.address}
                location={accommodation.location}
                color={theme.colors.text_primary}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr className={styles.hr(theme)} />
      <Row>
        <Col styles={styles.width(reconfirmationCode ? "30%" : "40%")}>
          <span className={styles.accommodationSubTitle(theme)}>
            {t("Voucher.roomsQuantity")} <b>{accommodation.rooms.length}</b>
          </span>
        </Col>
        <Col styles={styles.width(reconfirmationCode ? "20%" : "30%")}>
          <span className={styles.accommodationSubTitle(theme)}>
            {t("Voucher.nightsQuantity")}
            <b>{moment(accommodation.rate.checkout).diff(moment(accommodation.rate.checkin), "days")}</b>
          </span>
        </Col>
        <Col styles={styles.width(reconfirmationCode ? "20%" : "30%")}>
          <span className={styles.accommodationSubTitle(theme)}>
            {t("Voucher.reservationProvider", { provider: t(`Voucher.providers.${accommodation.rate.provider}`) })}
          </span>
        </Col>
        {reconfirmationCode && (
          <Col styles={styles.width("30%")}>
            <span className={styles.accommodationSubTitle(theme)}>
              {t("Voucher.reconfirmationCode")}
              <b>{` ${reconfirmationCode}`}</b>
            </span>
          </Col>
        )}
      </Row>
      <hr className={styles.hr(theme)} />
      <Row>
        <Col styles={styles.accommodationData(theme)}>
          <div style={{ marginRight: "10px", padding: "20px 15px" }}>
            <span className={styles.accommodationValue(theme)}>{t("Voucher.accommodationDataTitle")}</span>
            <br />
            <br />
            {accommodation.address && (
              <>
                <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.address")}</span>
                <span className={styles.accommodationValue(theme)}>{accommodation.address}</span>
                <br />
              </>
            )}
            {accommodation.phone && (
              <>
                <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.phone")}</span>
                <span className={styles.accommodationValue(theme)}>{accommodation.phone}</span>
                <br />
              </>
            )}

            <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.mealPlan")}</span>
            <span className={styles.accommodationValue(theme)}>{t(`MealPlan.${accommodation.rate.mealPlan}`)}</span>
            <br />

            {accommodation.emergencyPhone && (
              <>
                <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.emergencyPhone")}</span>
                <span className={styles.accommodationValue(theme)}>{accommodation.emergencyPhone}</span>
              </>
            )}
          </div>
        </Col>

        <Col styles={styles.checkin(theme)}>
          <div>
            <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.checkin")}</span>
            <br />
            <span className={styles.accommodationValue(theme)}>
              {moment(accommodation.rate.checkin)
                .utc()
                .format(t("Voucher.dateFormat"))}
            </span>
            <br />
            {accommodation.checkin && accommodation.checkin.time && (
              <span className={styles.accommodationValue(theme)}>
                {t("Voucher.checkinFrom", { time: accommodation.checkin.time })}
              </span>
            )}
          </div>
        </Col>
        <Col styles={styles.checkout(theme)}>
          <div>
            <span className={styles.accommodationSubTitle(theme)}>{t("Voucher.checkout")}</span>
            <br />
            <span className={styles.accommodationValue(theme)}>
              {moment(accommodation.rate.checkout)
                .utc()
                .format(t("Voucher.dateFormat"))}
            </span>
            <br />
            {accommodation.checkout && accommodation.checkout.time && (
              <span className={styles.accommodationValue(theme)}>
                {t("Voucher.checkoutTo", { time: accommodation.checkout.time })}
              </span>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Rooms accommodation={accommodation} />
        </Col>
      </Row>

      <Row>
        <Col>
          {accommodation.rate.notIncluded && accommodation.rate.notIncluded.length > 0 && (
            <div className={styles.disclamer(theme)}>
              <span className={styles.disclamerTitle(theme)}>{t("Voucher.notIncluded")}</span>
              <br />
              {accommodation.rate.notIncluded.map((fee, i) => (
                <div key={i}>
                  {t(`Voucher.taxes.${fee.type}`, {
                    currency: fee.currency,
                    amount: fee.amount.toLocaleString(i18n.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  })}
                  <br />
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>

      <CancelPenalties
        refundable={accommodation.rate.refundable}
        cancelPenalties={accommodation.rate.cancelPenalties}
        expirationDate={accommodation.rate.expirationDate || ""}
        customExpirationDate={accommodation.rate.customExpirationDate}
        resellerExpirationDate={accommodation.rate.resellerExpirationDate}
      />

      <CheckinInstructions checkin={accommodation.checkin} />

      <Disclamer text={t("Voucher.roomDisclamer")} />

      {extraInformation !== "" && <Disclamer text={extraInformation} />}

      {showRemarks && accommodation.rate.comments && (
        <div className={styles.remarks}>
          <AccommodationRemarks title={t("Voucher.remarks")} remarks={accommodation.rate.comments} />
        </div>
      )}
    </div>
  )
}

export default Voucher
