import React from "react"

import CustomDatePickerInput from "./CustomDatePickerInput"
import TextField from "../TextField"

type Adornment = "start" | "end" | "none"

export interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  label?: string
  placeholder?: string
  value?: string | number
  disabled?: boolean
  error?: boolean
  helperText?: string
  adornment?: Adornment
  AdornmentComp?: React.ComponentClass | React.FC | string
  autofocus?: boolean
  fullWidth?: boolean
  className?: string
  required?: boolean
  onKeyPress?: (e: any) => void
}

const TextDateField: React.FC<Props> = ({
  label = "",
  placeholder = "",
  value = "",
  disabled = false,
  autofocus = false,
  fullWidth = false,
  error = false,
  helperText = "",
  adornment = "none",
  AdornmentComp,
  className,
  required = false,
  onKeyPress,
  onChange
}) => {
  return (
    <TextField
      onChange={onChange}
      variant="text"
      label={label}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      autofocus={autofocus}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      adornment={adornment}
      AdornmentComp={AdornmentComp}
      className={className}
      required={required}
      onKeyPress={onKeyPress}
      inputComponent={CustomDatePickerInput as any}
    />
  )
}

export default TextDateField
