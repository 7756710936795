import { css } from "@emotion/css"

export const checkBoxStyle = (color: string) => {
  return {
    rootStyle: css({
      color: `${color} !important`,
      padding: "0 !important"
    }),
    checkedStyle: css({
      color: `${color} !important`
    })
  }
}
