import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      width: "100%"
    }),
  brief: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1
  }),
  otherInfo: css({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    ">div": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      flex: 2
    }
  }),
  duration: css({
    margin: 0,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: "0.15px",
    textAlign: "center"
  }),
  cabin: css({
    display: "flex",
    flexDirection: "row",
    padding: "1px 8px",
    backgroundColor: "#EEE4F6",
    color: "#a877d3",
    borderRadius: "4px",
    fontSize: "12px",
    alignItems: "center",
    marginRight: "4px",
    ":last-of-type": {
      marginRight: 0
    }
  }),
  info: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "14px",
        color: t.colors.brand_primary
      }
    }),
  infoContainer: css({
    flex: "1 !important"
  })
}
