import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      backgroundColor: theme.colors.background_primary
    }),
  providerContainer: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "16px"
  }),
  providerName: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.14",
    letterSpacing: "0.15px"
  }),

  item: css({
    display: "flex",
    flexDireaction: "row",
    justifyContent: "flex-end",
    gap: "8px",
    alignItems: "center",
    marginTop: "4px",
    fontSize: "12px",
    fontWeight: 500
  }),

  promotionName: css({
    color: "#ffffff",
    padding: "2px 4px",
    display: "inline-block",
    backgroundColor: "#A877D3",
    borderRadius: "4px",
    marginLeft: "5px",
    verticalAlign: "middle"
  }),

  costEffectiveness: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    borderRadius: "4px",
    color: colors.GREEN2,
    backgroundColor: colors.LIGHTEST_GREEN,
    padding: "2px 8px 0px 8px",
    lineHeight: "1.33",
    marginTop: "16px"
  }),
  skeleton: css({
    "&.MuiSkeleton-root": {
      backgroundColor: colors.LIGHTEST_GREEN
    },
    width: "100%"
  })
}
