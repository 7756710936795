import React, { useEffect, useState } from "react"
import { AdvertisingSliderData, SLIDER_ANIMATION_INTERVAL } from "./types"
import AdvImage from "./AdvImage"
import styles from "./AdvSlider.styles"

export interface Props {
  slider: AdvertisingSliderData
}

const AdvSlider: React.FC<Props> = ({ slider }) => {
  const [item, setItem] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      const i = item + 1
      setItem(i < slider.images.length ? i : 0)
    }, SLIDER_ANIMATION_INTERVAL * 1000)
  }, [item, slider.images.length])

  return (
    <div className={styles.root}>
      {slider.images.map((img, idx) => (
        <div className={styles.image(idx !== item)}>
          <AdvImage image={img} />
        </div>
      ))}
    </div>
  )
}

export default AdvSlider
