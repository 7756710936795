import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      fontSize: "12px",
      borderTop: `solid 1px ${theme.colors.background_darker}`,
      borderBottom: `solid 1px ${theme.colors.background_darker}`,
      padding: "8px 0",
      backgroundColor: theme.colors.background_primary
    }),
  switcher: (theme: Theme) =>
    css({
      display: "flex",
      borderRadius: "1px",
      border: `solid 1px ${theme.colors.background_darker}`,
      backgroundColor: theme.colors.background_primary
    }),
  active: (active: boolean, theme: Theme) =>
    css({
      borderRadius: "2px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      backgroundColor: active ? theme.colors.brand_primary : theme.colors.background_primary,
      fontWeight: 500,
      lineHeight: "1.33",
      color: active ? theme.colors.text_white : theme.colors.text_primary,
      padding: "8px 16px",
      cursor: "pointer"
    })
}
