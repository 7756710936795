import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  buyButton: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.brand_primary,
        textTransform: "unset",
        width: "100%",
        marginTop: "8px",
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "0.17px",
        color: theme.colors.background_variant,
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      }
    })
}
