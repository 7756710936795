import React from "react"
import { ClusterRoom, CancelPenalty, MealPlan as MealPlanType } from "../../model"
import styles from "./AccommodationDetailPDFRooms.styles"
import { useTheme } from "@basset-la/themed-components"
import AccommodationDetailPDFRateDetail from "./AccommodationDetailPDFRateDetail"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  rooms: ClusterRoom[]
  mealPlan: MealPlanType
  cancelPolicies: CancelPenalty[]
  refundable?: boolean
}

const AccommodationDetailPDFRooms: React.FC<Props> = ({ rooms, mealPlan, cancelPolicies, refundable }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  let roomMap = {}

  rooms.forEach(r => {
    const key = `${r.id}:${r.name}`

    if (!roomMap[key]) {
      roomMap[key] = [r]
    } else {
      roomMap[key].push(r)
    }
  })

  let roomsToRender = []
  for (const key in roomMap) {
    const room = roomMap[key][0]
    roomsToRender.push(
      <div key={key} className={styles.roomDescription}>
        <p className={styles.roomDescriptionName}>{`${roomMap[key].length} x ${room.name}`}</p>
        <p className={styles.roomDescriptionCapacity}>
          {t("AccommodationDetailPDFRooms.pax_room", { count: room.capacity })}
        </p>
      </div>
    )
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.roomsDescriptionContainer}>{roomsToRender}</div>
      <div className={styles.mealPlanAndPenalties(theme)}>
        <AccommodationDetailPDFRateDetail mealPlan={mealPlan} cancelPolicies={cancelPolicies} refundable={refundable} />
      </div>
    </div>
  )
}

export default AccommodationDetailPDFRooms
