import React from "react"
import styles from "./ModularBanner.styles"
import { BannerInfo } from "./types"

interface Props {
  images: BannerInfo[]
  onClick: (url: string) => void
}

const FixedBanner: React.FC<Props> = ({ images, onClick }) => {
  const handleClick = (url: string) => {
    if (!!url) {
      onClick(url)
    }
  }

  return (
    <div className={styles.root}>
      {images.map((img, idx) => (
        <div key={idx} onClick={() => handleClick(img.link)}>
          <img className={styles.image(img.link)} src={img.image_url} alt={img.alt} />
        </div>
      ))}
    </div>
  )
}

export default FixedBanner
