import { css } from "@emotion/css"

const styles = {
  link: (color: string) =>
    css({
      color: `${color} !important`,
      cursor: "pointer"
    })
}

export default styles
