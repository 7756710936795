import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, mini: boolean | undefined, max: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: mini ? "column" : "row", // forcing mobile view
      width: "100%",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      fontSize: !max ? "14px" : "18px",
      fontWeight: 300,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      backgroundColor: t.colors.background_primary,
      padding: mini ? "0" : "8px",
      "@media screen and (max-width:425px)": {
        flexFlow: "column",
        padding: 0
      }
    }),
  brief: (mini: boolean | undefined, max: boolean | undefined) =>
    css({
      display: "flex",
      flex: 2,
      flexShrink: 0,
      marginBottom: mini ? "4px" : 0,
      "& > *": {
        fontSize: !max ? "14px" : "18px !important"
      },
      "@media screen and (max-width:425px)": {
        marginBottom: "4px"
      }
    }),
  durationBaggage: (mini: boolean | undefined, max: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: mini ? "space-between" : "space-around",
      flex: 1,
      "> *": {
        fontSize: !max ? "14px" : "18px",
        alignSelf: "center"
      },
      "@media screen and (max-width:425px)": {
        justifyContent: "space-between"
      }
    }),
  baggagesContainer: (max: boolean | undefined) =>
    css({
      display: "flex",
      minWidth: "48px",
      alignItems: "center",
      justifyContent: !max ? "center" : "flex-end",
      flexGrow: 1
    }),
  durationContainer: (mini: boolean | undefined, max: boolean | undefined) =>
    css({
      display: "flex",
      flex: mini ? 2 : 1,
      justifyContent: mini ? "flex-start" : !max ? "center" : "flex-end",
      fontSize: !max ? "14px" : "18px",
      "@media screen and (max-width:425px)": {
        justifyContent: "flex-start",
        flex: 2
      }
    }),
  durationHighlighted: css({
    backgroundColor: colors.LIGHT_GREEN
  }),
  lastPlaceContainer: (mini: boolean | undefined, max: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: mini ? "flex-end" : "center",
      width: "36px",
      "@media screen and (max-width:425px)": {
        justifyContent: "flex-end"
      }
    }),
  baggagesLastPlaces: (mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      flex: 1,
      justifyContent: mini ? "flex-end" : "space-around",
      "@media screen and (max-width:425px)": {
        justifyContent: "flex-end"
      }
    }),
  duration: css({
    margin: 0,
    fontWeight: 500,
    padding: "1px 4px",
    marginLeft: "-2px",
    borderRadius: "8px",
    height: "16px",
    textAlign: "center",
    minWidth: "58px" // for the 4px of padding
  }),
  info: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "14px",
        color: t.colors.brand_primary
      }
    }),
  infoContainer: css({
    display: "flex",
    flex: "1 !important",
    justifyContent: "flex-end",
    alignItems: "center"
  })
}
