import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BaggageAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg {...rest} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25719 0.780457C5.9701 0.780457 5.73653 1.00154 5.73653 1.27355V5.32692H10.0729V1.27355C10.0729 1.00154 9.83954 0.780457 9.55244 0.780457H6.25719ZM10.8538 1.23294V5.32692H11.5652C12.0664 5.32692 12.4763 5.71527 12.4763 6.18994V8V8.00002V14.3504C12.4763 14.7932 12.0664 15.1557 11.5652 15.1557H10.8538V15.8697C10.8538 15.9413 10.7876 16 10.7065 16H9.96255C9.88149 16 9.81528 15.9413 9.81528 15.8697V15.1557H5.99435V15.8697C5.99435 15.9413 5.92793 16 5.84687 16H5.10313C5.02207 16 4.95565 15.9413 4.95565 15.8697V15.1557H4.24425C3.74326 15.1557 3.33337 14.7932 3.33337 14.3504V8.00002V8V6.18994C3.33337 5.71527 3.74326 5.32692 4.24425 5.32692H4.95565V1.23294C4.95565 0.553127 5.53946 0 6.25719 0H9.55244C10.27 0 10.8538 0.553127 10.8538 1.23294Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
