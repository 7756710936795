import React, { useState, useRef, useEffect } from "react"
import styles from "./AmenitiesFilter.styles"
import CheckboxFilter, { CheckboxFilterOption } from "@basset-la/components-products/dist/components/CheckboxFilter"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AccommodationFiltersCollapsePanel from "../AccommodationFiltersCollapsePanel"
import { Text } from "@basset-la/components-commons"
import { useTheme } from "@basset-la/themed-components"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import { InfoIcon } from "../../icons/InfoIcon"
import Popper from "@material-ui/core/Popper/Popper"
import AmenitiesSearchInput from "./AmenitiesSearchInput"
import { FullScreenDialog } from "@basset-la/components-products"

export interface Props {
  collapsible?: boolean
  options: Option[]
  onChange: (value?: number, checked?: boolean, newSelectedItems?: Option[]) => void
  onDone?: () => void
  isMobile?: boolean
  multiple?: boolean
  onChangeAutoComplete: (value: Option[]) => void
  selectedItems: Option[]
  onClearAllSelect?: () => void
}

export type Option = {
  label: string
  value: unknown
  selected?: boolean
}

const AmenitiesFilter: React.FC<Props> = ({
  collapsible,
  options,
  onChange,
  onDone,
  isMobile = false,
  multiple = false,
  onChangeAutoComplete,
  selectedItems,
  onClearAllSelect
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const inputRef = useRef<HTMLDivElement>(null)
  const [allOptionsSelected, setAllOptionsSelected] = useState(false)

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const searchInputRef = useRef<HTMLDivElement>(null)
  const [items, setItems] = useState<Option[]>([])
  const [countSelectedInSearch, setCountSelectedInSearch] = useState(0)
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    if (open) {
      searchInputRef?.current?.focus()
    }
  }, [open])

  const fetchAmenities = (searchText: string) => {
    if (searchText.length > 1) {
      setLoading(true)
      setItems([])
      try {
        setItems(options.filter(a => a.label.toLowerCase().includes(searchText.toLowerCase())))
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
  }

  const mapToCheckboxOptions = (options: Option[]): CheckboxFilterOption[] => {
    return [
      {
        title: t("AmenitiesFilter.all"),
        value: undefined,
        selected: allOptionsSelected
      },
      ...options.map(o => ({
        title: o.label,
        value: o.value,
        selected: o.selected
      }))
    ]
  }

  const onFilterChange = (value: number, checked: boolean): void => {
    const newValue = value === undefined
    if (allOptionsSelected !== newValue) {
      setAllOptionsSelected(newValue)
    }
    onChange(value, checked)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const internalHandleOnChange = (value: Option[]) => {
    value.forEach(e => {
      onFilterChange(e.value as number, !!e.selected)
    })
    onChangeAutoComplete(value)
    if (!multiple) {
      setOpen(false)
    }
  }

  const internalClearAll = () => {
    if (onClearAllSelect) {
      onClearAllSelect()
    }
    setCountSelectedInSearch(0)
  }

  const handleOnInputChange = (value: string) => {
    setInputValue(value)
    setCountSelectedInSearch(0)
    fetchAmenities(value)
  }

  const internalSelectAll = (items: Option[]) => {
    const difference = selectedItems.filter(item => !items.find(i => i.value === item.value))
    const allItems = [...difference, ...items]

    onChange(undefined, undefined, items)
    onChangeAutoComplete(items)
    setCountSelectedInSearch(allItems.length)
  }

  const onCountSelectedInSearchChange = (value: number) => {
    setCountSelectedInSearch(value)
  }

  const handleOnClickAway = () => {
    setOpen(false)
  }

  const buildAmenitiesText = (selectedItems: Option[]) => {
    if (selectedItems.length === 0) {
      return t("AmenitiesSelect.allAmenities")
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].label
    }
    return t("AmenitiesSelect.multipleAmenities", { count: selectedItems.length })
  }

  return (
    <div className={styles.root}>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("AmenitiesFilter.title")}
        </Text>
        <SearchboxTextField
          className={styles.root}
          onClick={handleOpen}
          rightIcon={<ArrowIcon />}
          leftIcon={InfoIcon}
          value={buildAmenitiesText(selectedItems)}
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Popper
          className={styles.popover(inputRef.current?.offsetWidth)}
          placement="bottom-start"
          open={open}
          anchorEl={inputRef.current}
          disablePortal
        >
          <AmenitiesSearchInput
            ref={searchInputRef}
            items={items}
            selectedItems={selectedItems}
            countSelectedInSearch={countSelectedInSearch}
            inputValue={inputValue}
            onChange={internalHandleOnChange}
            onDone={onDone}
            onClearAll={internalClearAll}
            onInputChange={handleOnInputChange}
            onSelectAll={internalSelectAll}
            onCountSelectedInSearchChange={onCountSelectedInSearchChange}
            onClickAway={handleOnClickAway}
            loading={loading}
            multiple={multiple}
          />
        </Popper>
      )}

      {isMobile && (
        <FullScreenDialog
          open={open}
          actionButtonText={t("Searchbox.apply")}
          onClose={handleOpen}
          onAction={handleOpen}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              InputProps={{ disabled: true }}
              leftIcon={<InfoIcon color={theme.colors.brand_primary} />}
              value={buildAmenitiesText(selectedItems)}
            />
          }
        >
          <div className={styles.dialogContainer}>
            <AmenitiesSearchInput
              ref={searchInputRef}
              items={items}
              selectedItems={selectedItems}
              countSelectedInSearch={countSelectedInSearch}
              inputValue={inputValue}
              onChange={internalHandleOnChange}
              onDone={onDone}
              onClearAll={internalClearAll}
              onInputChange={handleOnInputChange}
              onSelectAll={internalSelectAll}
              onCountSelectedInSearchChange={onCountSelectedInSearchChange}
              loading={loading}
              multiple={multiple}
              isMobile
            />
          </div>
        </FullScreenDialog>
      )}
      {!collapsible && (
        <div className={styles.divCheckFilter}>
          <CheckboxFilter
            onChange={onFilterChange}
            options={mapToCheckboxOptions(options)}
            onDone={onDone}
            id={{
              doneBtn: "amenities-filter-checkbox-done-btn"
            }}
          />
        </div>
      )}
      {collapsible && (
        <AccommodationFiltersCollapsePanel
          renderDetails={
            <div className={styles.container}>
              <CheckboxFilter
                onChange={onFilterChange}
                options={mapToCheckboxOptions(options)}
                onDone={onDone}
                id={{
                  doneBtn: "amenities-filter-checkbox-filter-done-btn"
                }}
              />{" "}
            </div>
          }
        />
      )}
    </div>
  )
}

export default AmenitiesFilter
