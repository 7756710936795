import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const MealIconNotFound: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0345 8.49985H14.6345C15.0278 8.49985 15.3345 8.80585 15.2945 9.15985L14.2678 18.4179C14.2012 18.9099 13.7412 19.2999 13.1812 19.2999H12.0478L12.0345 14.4999C12.0345 11.9619 9.96784 10.3959 7.51451 9.88585L7.4345 9.15985C7.40117 8.80585 7.70784 8.49985 8.10117 8.49985H10.7012V6.69985C10.7012 6.36985 11.0012 6.09985 11.3678 6.09985C11.7345 6.09985 12.0345 6.36985 12.0345 6.69985V8.49985ZM10.0345 18.0999C10.4012 18.0999 10.7012 18.3699 10.7012 18.6999C10.7012 19.0299 10.4012 19.2999 10.0345 19.2999H1.36784C1.00117 19.2999 0.701172 19.0299 0.701172 18.6999C0.701172 18.3699 1.00117 18.0999 1.36784 18.0999H10.0345ZM1.43451 14.4999H9.96784C10.3812 14.4999 10.7078 14.1639 10.6278 13.8039C10.1945 11.8659 7.94784 10.8939 5.70117 10.8939C3.45451 10.8939 1.21451 11.8659 0.774505 13.8039C0.694505 14.1639 1.02784 14.4999 1.43451 14.4999ZM1.36784 15.6999H10.0345C10.4012 15.6999 10.7012 15.9699 10.7012 16.2999C10.7012 16.6299 10.4012 16.8999 10.0345 16.8999H1.36784C1.00117 16.8999 0.701172 16.6299 0.701172 16.2999C0.701172 15.9699 1.00117 15.6999 1.36784 15.6999Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C6.24 2 4 4.24 4 7C4 9.76 6.24 12 9 12C11.76 12 14 9.76 14 7C14 4.24 11.76 2 9 2ZM9 7.5C8.725 7.5 8.5 7.275 8.5 7V5C8.5 4.725 8.725 4.5 9 4.5C9.275 4.5 9.5 4.725 9.5 5V7C9.5 7.275 9.275 7.5 9 7.5ZM8.5 8.5V9.5H9.5V8.5H8.5Z"
      fill="#D0021B"
    />
  </svg>
)
