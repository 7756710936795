import React from "react"
import styles from "./CarouselBanner.styles"
import { BannerInfo } from "./types"
import Carousel from "../Carousel/Carousel"

interface Props {
  width: string
  images: BannerInfo[]
  onClick: (url: string) => void
}

const SlidingBanner: React.FC<Props> = ({ width, images, onClick }) => {
  const handleClick = (url: string) => {
    if (!!url) {
      onClick(url)
    }
  }

  const getBanners = () => {
    return images.map((img, idx) => (
      <div key={idx} onClick={() => handleClick(img.link)}>
        <img className={styles.image(img.link)} src={img.image_url} alt={img.alt} />
      </div>
    ))
  }

  return <Carousel width={width}>{getBanners()}</Carousel>
}

export default SlidingBanner
