import React, { useState } from "react"
import styles from "./SegmentBrands.styles"
import LegTab from "./LegTab"
import { getAirlineLogo } from "../../utils/helpers/airlines"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AirlineLogo from "../AirlineLogo"
import FlightBrand from "./FlightBrand"
import { MergedSegment } from "../../utils/helpers/brands"
import Carousel from "@basset-la/components-commons/dist/components/Carousel"

export interface Props {
  segment: MergedSegment
  upsellFaresVisible: boolean[]
  selectedOption: number
  firstCarouselBrand: number
  showAllServices: boolean
  isSingleCategory: boolean
}

const SegmentBrands: React.FC<Props> = ({
  segment,
  upsellFaresVisible,
  selectedOption,
  firstCarouselBrand,
  showAllServices,
  isSingleCategory
}) => {
  const { t } = useTranslation(I18N_NS)

  const [selectedLeg, setSelectedLeg] = useState(0)

  const handleSelectLeg = (idxs: number) => {
    setSelectedLeg(idxs)
  }

  const mergedLegs = segment.legs
  const airline = getAirlineLogo(segment.options[selectedOption].legs, t("SegmentOptionBrief.multipleAirlines"))

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.airline}>
          <AirlineLogo airlineCode={airline.code} />
          <span>{`${segment.origin.code} - ${segment.destination.code}`}</span>
        </div>
        <span className={styles.title}>{t("SegmentBrands.title")}</span>
      </div>

      {mergedLegs.length > 1 && <LegTab legs={mergedLegs} selectedLeg={selectedLeg} onSelectLeg={handleSelectLeg} />}

      <Carousel width="100%" autoMoveTo={firstCarouselBrand}>
        {mergedLegs[selectedLeg].brands
          .map((b, idx) => {
            if (!upsellFaresVisible[idx]) return null
            return (
              <FlightBrand
                key={`flight_brand_${idx}`}
                showAllServices={showAllServices}
                brand={b}
                {...{ isSingleCategory }}
              />
            )
          })
          .filter(it => it != null)}
      </Carousel>
    </div>
  )
}

export default SegmentBrands
