import React from "react"
import styles from "./AmenitiesPreview.styles"
import Amenities from "../Amenities/Amenities"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  wifi?: boolean
  parking?: boolean
  pool?: boolean
  receptionAllDay?: boolean
  onViewAmenities?: () => void
}

const AmenitiesPreview = ({ wifi, parking, pool, receptionAllDay, onViewAmenities }: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div className={styles.amenities}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{t("AmenitiesPreview.amenities")}</span>
        </div>
        <div className={styles.amenitiesComp}>
          <Amenities parking={parking} pool={pool} receptionAllDay={receptionAllDay} wifi={wifi} />
        </div>
      </div>
      {onViewAmenities && (
        <div className={styles.buttonContainer}>
          <Button classes={{ root: styles.button(theme) }} onClick={onViewAmenities}>
            {t("AmenitiesPreview.see_all")}
          </Button>
        </div>
      )}
    </div>
  )
}

export default AmenitiesPreview
