import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BagIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...rest}>
    <path
      fill={color}
      d="M10.2 3.8v-.6C10.2 2.537 9.663 2 9 2H6.6c-.663 0-1.2.537-1.2 1.2v.6C4.075 3.8 3 4.875 3 6.2v6.6c0 .663.537 1.2 1.2 1.2h7.2c.663 0 1.2-.537 1.2-1.2V6.2c0-1.325-1.075-2.4-2.4-2.4m-3.6-.6H9v.6H6.6v-.6m1.2 3L9 7.4 7.8 8.6 6.6 7.4l1.2-1.2m3.6 4.2H6v1.2h-.6v-1.2H4.2v-.6h7.2v.6z"
    />
  </svg>
)
