import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  highlighted: (theme: Theme) =>
    css({
      color: theme.colors.brand_darker
    }),
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "column",
      padding: "16px",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto"
    }),
  paxs: css({
    display: "flex",
    flexFlow: "column"
  }),
  flight: css({
    margin: "16px 0"
  }),
  info: (t: Theme) =>
    css({
      margin: "16px",
      display: "flex",
      justifyContent: "center",
      color: t.colors.text_primary
    })
}
