import React from "react"
import { Moment } from "moment"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { getLocale } from "@basset-la/components-products/dist/utils/helpers"
import styles from "./DatesMatrixHeader.styles"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  date: Moment
}

const DatesMatrixHeader: React.FC<Props> = ({ date }) => {
  const { i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const localizedDate = date.locale(locale)
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <span>{localizedDate.format("dddd")}</span>
      <span className={styles.bold}>{localizedDate.format("DD MMM")}</span>
    </div>
  )
}

export default DatesMatrixHeader
