import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary,
      width: "100%"
    }),
  title: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "16px",
    marginBottom: "8px",
    ">span": {
      display: "inline-flex",
      height: "fit-content"
    },
    ">span:first-child": {
      marginRight: "8px"
    }
  }),
  textField: css({
    "&.MuiTextField-root": {
      width: "100%"
    },
    "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
      cursor: "pointer"
    }
  }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    })
}
