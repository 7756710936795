import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  companionDataContainer: (theme: Theme, error: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      border: `solid 1px ${error ? theme.colors.error : "#ccc"}`,
      borderRadius: "8px",
      padding: "16px"
    }),
  head: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  headTitle: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),
  companionDataSubContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "16px",
    "&:last-of-type": {
      marginTop: "16px"
    }
  }),
  fieldInCompanion: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px",
    flexGrow: 0.5,
    flexBasis: "40%",
    "@media(max-width:768px)": {
      flexGrow: 1,
      flexBasis: "100%"
    }
  }),
  textFieldTitle: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontWeight: 300
    }),
  textField: (t: Theme) =>
    css({
      "&.MuiTextField-root .MuiOutlinedInput-root": {
        fontSize: "14px",
        height: "32px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ccc !important"
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: `${t.brand_colors.click_color} !important`
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${t.brand_colors.click_color} !important`
        }
      },
      "&.MuiTextField-root .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: `${t.colors.error} !important`
      },
      "&.MuiInputBase-root": {
        fontWeight: 300
      }
    }),
  closeButton: css({
    "&.MuiIconButton-root": {
      padding: "4px",
      float: "right"
    }
  }),
  closeIcon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "20px",
        color: t.colors.brand_primary
      }
    }),
  errorTextContainer: css({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    marginTop: "8px"
  }),
  infoIcon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.error
      }
    }),
  companionDataContainerRoot: css({
    display: "flex",
    flexDirection: "column"
  }),
  documentFieldInCompanion: css({
    flexGrow: 0.5,
    flexBasis: "40%",
    "@media(max-width:768px)": {
      flexGrow: 1,
      flexBasis: "100%"
    }
  })
}
