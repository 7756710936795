import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, isMobile: boolean) =>
    css({
      padding: "16px",
      fontFamily: "Roboto",
      boxSizing: "border-box",
      width: !isMobile ? "288px" : undefined,
      color: t.colors.text_primary,
      backgroundColor: t.colors.background_primary,
      borderRadius: "3px"
    }),
  pickerContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  picker: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  mainPicker: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  pickerLabelContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      ">span": {
        fontSize: "12px",
        lineHeight: "1.33",
        color: t.colors.text_variant
      },
      ">span:first-of-type": {
        fontSize: "14px",
        lineHeight: "1.14",
        color: t.colors.text_primary
      }
    }),
  item: (t: Theme) =>
    css({
      "&.MuiListItem-root": {
        fontSize: "14px",
        lineHeight: "1.14",
        color: t.colors.text_primary,
        width: "100%",
        padding: "8px",
        justifyContent: "start"
      }
    }),
  error: (t: Theme) =>
    css({
      fontSize: "10px",
      fontWeight: 300,
      color: t.colors.error,
      lineHeight: "1.6",
      letterSpacing: "0.11px",
      marginTop: "8px"
    })
}
