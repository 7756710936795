import React from "react"
import { useTranslation } from "react-i18next"
import { Category, MealPlan as MealPlanType } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import Address from "../Address/Address"
import Stars from "../Stars"
import Amenities from "../Amenities/Amenities"
import MealPlan from "./MealPlan"
import styles from "./SimpleAccommodationInfo.styles"
import noImage from "../../images/accommodation-image.svg"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  name: string
  category?: Category
  address: string
  stars: number
  onHotelSelect?: () => void
  onViewMap?: () => void
  location?: {
    latitude: number
    longitude: number
  }
  mealPlan?: MealPlanType
  photo?: string | null
  remaningRooms?: number
  freeCancel?: boolean
  // Amenities
  wifi?: boolean
  pool?: boolean
  parking?: boolean
  receptionAllDay?: boolean

  mini?: boolean
}

const SimpleAccommodationInfo: React.FunctionComponent<Props> = ({
  name,
  category,
  address,
  stars,
  onHotelSelect,
  onViewMap,
  photo,
  location,
  mealPlan,
  remaningRooms: remainingRooms,
  freeCancel,
  wifi,
  pool,
  parking,
  receptionAllDay,
  mini
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.root(mini, theme)}>
      {photo !== undefined && <img className={styles.photo(mini)} src={photo || noImage} alt={name} />}
      <div className={styles.infoContainer(mini, !!photo)}>
        <div className={styles.firstRow}>
          <div className={styles.nameContainer} style={{ cursor: onHotelSelect ? "pointer" : "default" }}>
            <div
              id={"simple-accommodation-info-name"}
              className={styles.name(mini, theme)}
              data-category={category?.name}
              onClick={() => onHotelSelect && onHotelSelect()}
            >
              {name}
            </div>
            <Stars amount={stars} />
          </div>
          <div className={styles.addressContainer}>
            <Address
              address={address}
              color={theme.colors.text_primary}
              location={location}
              onViewMap={() => onViewMap && onViewMap()}
            />
          </div>
        </div>
        <div className={styles.mealPlanAmenitiesContainer}>
          {mealPlan && mealPlan !== "ROOM_ONLY" && (
            <div className={styles.mealPlan}>
              <MealPlan mealPlan={mealPlan} />
            </div>
          )}
          <Amenities wifi={wifi} parking={parking} pool={pool} receptionAllDay={receptionAllDay} />
        </div>
        {(freeCancel || (remainingRooms && remainingRooms > 0)) && (
          <div className={styles.cancelAndLastRooms}>
            {freeCancel && <p className={styles.freeCancel}>{t("SimpleAccommodationInfo.freeCancel")}</p>}
            {remainingRooms && remainingRooms > 0 && (
              <p className={styles.lastRooms}>
                {t("SimpleAccommodationInfo.remainingRooms", { count: remainingRooms })}
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default SimpleAccommodationInfo
