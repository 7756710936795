import { useTheme } from "@basset-la/themed-components"
import React from "react"
import styles from "./UserMenu.styles"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import PersonIcon from "@material-ui/icons/Person"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import MenuItem from "@material-ui/core/MenuItem"

export interface Props {
  variant: "column" | "row"
  showBackOfficeOption: boolean
  onAccessBackOffice: () => void
  onLogout: () => void
}

const UserMenu: React.FC<Props> = ({ variant, showBackOfficeOption, onAccessBackOffice, onLogout }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root(theme, variant)}>
      {showBackOfficeOption && (
        <MenuItem className={styles.item(theme, variant)} onClick={onAccessBackOffice}>
          <PersonIcon />
          {t("UserMenu.accessBackOffice")}
        </MenuItem>
      )}
      <MenuItem className={styles.item(theme, variant)} onClick={onLogout}>
        <ExitToAppIcon />
        {t("UserMenu.logout")}
      </MenuItem>
    </div>
  )
}

export default UserMenu
