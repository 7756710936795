import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const CheckIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16px" height="8px" viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <defs>
      <filter x="-0.7%" y="-0.6%" width="101.4%" height="102.3%" filterUnits="objectBoundingBox" id="filter-1">
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
        <feColorMatrix
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0"
          type="matrix"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        ></feColorMatrix>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
          <feMergeNode in="SourceGraphic"></feMergeNode>
        </feMerge>
      </filter>
    </defs>
    <g id="desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.1_Desktop_map-view" transform="translate(-148.000000, -264.000000)">
        <g id="Group-13" filter="url(#filter-1)" transform="translate(120.000000, 168.000000)">
          <g id="Group-12">
            <g id="ic_check" transform="translate(30.000000, 94.000000)">
              <polygon id="Bounds" points="0 0 12 0 12 12 0 12"></polygon>
              <polygon
                id="Shape"
                fill="#FFFFFF"
                points="4.5 8.085 2.415 6 1.705 6.705 4.5 9.5 10.5 3.5 9.795 2.795"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
