import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./AccommodationZone.styles"
import { Zone } from "../../model/accommodationDetail"
import AccommodationPOISection from "./AccommodationPOISection"
import { colors } from "../../utils/constants/constants"
import HotelDetailMap from "../HotelDetailMap/HotelDetailMap"

interface Props {
  googleMapsApiKey?: string
  viewMap?: boolean
  address: string
  hotelName: string
  hotelCoordinates: {
    latitude: number
    longitude: number
  }
  hotelImg: string
  zone: Zone
  isMobile: boolean
}

const AccommodationZone: React.FC<Props> = ({
  address,
  zone,
  hotelName,
  hotelCoordinates,
  hotelImg,
  googleMapsApiKey,
  viewMap,
  isMobile
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const { pois, train_stations, metro_stations } = zone

  const [openMap, setOpenMap] = React.useState(false)

  const handleCloseMap = () => {
    setOpenMap(false)
  }

  const handleOpenMap = () => {
    setOpenMap(true)
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t("AccommodationZone.title")}</h3>
          <span className={styles.address}>{address}</span>
        </div>
        {viewMap && googleMapsApiKey && (
          <Button onClick={handleOpenMap} className={styles.viewMap(theme)}>
            {t("AccommodationZone.viewMap")}
          </Button>
        )}
      </div>
      <div className={styles.sectionContainer}>
        {pois && pois.length > 0 && (
          <div className={styles.section(theme)}>
            <AccommodationPOISection title={t("AccommodationZone.pois")} regions={pois} checkColor={colors.RED} />
          </div>
        )}
        {train_stations && train_stations.length > 0 && (
          <div className={styles.section(theme)}>
            <AccommodationPOISection
              title={t("AccommodationZone.trainStations")}
              regions={train_stations}
              checkColor={colors.YELLOW}
            />
          </div>
        )}
        {metro_stations && metro_stations.length > 0 && (
          <div className={styles.section(theme)}>
            <AccommodationPOISection
              title={t("AccommodationZone.trainStations")}
              regions={metro_stations}
              checkColor={colors.BLUE}
            />
          </div>
        )}
      </div>
      {viewMap && googleMapsApiKey && (
        <HotelDetailMap
          hotelCoordinates={hotelCoordinates}
          hotelImg={hotelImg}
          hotelName={hotelName}
          pois={pois}
          onClose={handleCloseMap}
          apiKey={googleMapsApiKey}
          trainStations={train_stations}
          subwayStations={metro_stations}
          open={openMap}
          isMobile={isMobile}
        />
      )}
    </div>
  )
}

export default AccommodationZone
