import React from "react"
import { Price, Policy } from "../../model/types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import PolicySection from "./PolicySection"
import styles from "./PolicyInfo.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  price: Price
  isTooltip: boolean
}

type Policies = {
  adults?: Policy
  infants?: Policy
  children?: Policy
  disabled?: Policy
  senior?: Policy
}

const groupByPsgrType = (
  price: Price,
  type: keyof Policies,
  exchangeBeforePolicy: Policies,
  exchangeafterPolicy: Policies,
  refundBeforePolicy: Policies,
  refundAfterPolicy: Policies
) => {
  if (price[type] && price[type].exchange_policy) {
    for (let p of price[type].exchange_policy) {
      if (p.applies === "BEFORE") {
        exchangeBeforePolicy[type] = p
      } else {
        exchangeafterPolicy[type] = p
      }
    }
  }

  if (price[type] && price[type].refund_policy) {
    for (let p of price[type].refund_policy) {
      if (p.applies === "BEFORE") {
        refundBeforePolicy[type] = p
      } else {
        refundAfterPolicy[type] = p
      }
    }
  }
}

const PolicyInfo: React.FC<Props> = ({ price, isTooltip }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const exchangeBeforePolicy: Policies = {}
  const exchangeafterPolicy: Policies = {}
  const refundBeforePolicy: Policies = {}
  const refundAfterPolicy: Policies = {}

  groupByPsgrType(price, "adults", exchangeBeforePolicy, exchangeafterPolicy, refundBeforePolicy, refundAfterPolicy)

  groupByPsgrType(price, "infants", exchangeBeforePolicy, exchangeafterPolicy, refundBeforePolicy, refundAfterPolicy)

  groupByPsgrType(price, "children", exchangeBeforePolicy, exchangeafterPolicy, refundBeforePolicy, refundAfterPolicy)

  groupByPsgrType(price, "disabled", exchangeBeforePolicy, exchangeafterPolicy, refundBeforePolicy, refundAfterPolicy)

  groupByPsgrType(price, "senior", exchangeBeforePolicy, exchangeafterPolicy, refundBeforePolicy, refundAfterPolicy)

  const getPolicyCurrency = (policy: Policies, priceCurrency: string): string => {
    if (policy.adults?.currency) {
      return policy.adults?.currency
    }

    if (policy.children?.currency) {
      return policy.children?.currency
    }

    if (policy.infants?.currency) {
      return policy.infants?.currency
    }

    if (policy.disabled?.currency) {
      return policy.disabled?.currency
    }

    if (policy.senior?.currency) {
      return policy.senior?.currency
    }

    return priceCurrency
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.policy(isTooltip)}>
        <p>{t("PolicyInfo.refund")}</p>
        <div className={styles.policySection(isTooltip)}>
          <PolicySection
            type="BEFORE"
            currency={getPolicyCurrency(refundBeforePolicy, price.currency)}
            adults={refundBeforePolicy.adults}
            children={refundBeforePolicy.children}
            disabled={refundBeforePolicy.disabled}
            infants={refundBeforePolicy.infants}
            senior={refundBeforePolicy.senior}
          />
          <PolicySection
            type="AFTER"
            currency={getPolicyCurrency(refundAfterPolicy, price.currency)}
            adults={refundAfterPolicy.adults}
            children={refundAfterPolicy.children}
            disabled={refundAfterPolicy.disabled}
            infants={refundAfterPolicy.infants}
            senior={refundAfterPolicy.senior}
          />
        </div>
      </div>
      <div className={styles.policy(isTooltip)}>
        <p>{t("PolicyInfo.exchange")}</p>
        <div className={styles.policySection(isTooltip)}>
          <PolicySection
            type="BEFORE"
            currency={getPolicyCurrency(exchangeBeforePolicy, price.currency)}
            adults={exchangeBeforePolicy.adults}
            children={exchangeBeforePolicy.children}
            disabled={exchangeBeforePolicy.disabled}
            infants={exchangeBeforePolicy.infants}
            senior={exchangeBeforePolicy.senior}
          />
          <PolicySection
            type="AFTER"
            currency={getPolicyCurrency(exchangeafterPolicy, price.currency)}
            adults={exchangeafterPolicy.adults}
            children={exchangeafterPolicy.children}
            disabled={exchangeafterPolicy.disabled}
            infants={exchangeafterPolicy.infants}
            senior={exchangeafterPolicy.senior}
          />
        </div>
      </div>
    </div>
  )
}

export default PolicyInfo
