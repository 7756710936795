import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    marginBottom: "8px"
  }),
  content: css({
    "&.MuiSnackbarContent-root": {
      display: "flex",
      flexFlow: "row nowrap"
    }
  }),
  success: (theme: Theme) =>
    css({
      "&.MuiSnackbarContent-root": {
        backgroundColor: theme.colors.success
      }
    }),
  warning: (theme: Theme) =>
    css({
      "&.MuiSnackbarContent-root": {
        backgroundColor: theme.colors.warning
      }
    }),
  error: (theme: Theme) =>
    css({
      "&.MuiSnackbarContent-root": {
        backgroundColor: theme.colors.error
      }
    }),
  info: (theme: Theme) =>
    css({
      "&.MuiSnackbarContent-root": {
        backgroundColor: theme.colors.info
      }
    }),
  icon: css({
    marginRight: "16px"
  }),
  message: css({
    display: "flex",
    alignItems: "center"
  })
}
