import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const RefundableIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333374 7.00004C0.333374 3.32004 3.32004 0.333374 7.00004 0.333374C10.68 0.333374 13.6667 3.32004 13.6667 7.00004C13.6667 10.68 10.68 13.6667 7.00004 13.6667C3.32004 13.6667 0.333374 10.68 0.333374 7.00004ZM7.94004 11.4467V11.06C9.10004 10.84 10.0134 10.1734 10.0267 8.95337C10.0267 7.27337 8.58671 6.69337 7.24004 6.34671C5.89337 6.00004 5.46004 5.63337 5.46004 5.07337C5.46004 4.43337 6.06004 3.98004 7.06004 3.98004C7.90004 3.98004 8.32004 4.30671 8.47337 4.81337C8.54004 5.05337 8.74004 5.22671 8.98671 5.22671H9.16004C9.55337 5.22671 9.83337 4.84004 9.70004 4.47337C9.44671 3.76671 8.86671 3.19337 7.94671 2.96671V2.55337C7.94671 2.06671 7.54671 1.66671 7.06004 1.66671H7.05337C6.56671 1.66671 6.16671 2.06671 6.16671 2.55337V2.95337C5.02004 3.20004 4.09337 3.94671 4.09337 5.09337C4.09337 6.46004 5.22671 7.14004 6.88004 7.54004C8.36671 7.89337 8.66004 8.41337 8.66004 8.96671C8.66004 9.37337 8.36671 10.0267 7.06004 10.0267C6.08671 10.0267 5.58004 9.68004 5.38671 9.18004C5.29337 8.94671 5.09337 8.78004 4.84671 8.78004H4.68671C4.28671 8.78004 4.00004 9.18671 4.15337 9.55337C4.48671 10.3734 5.27337 10.86 6.16004 11.0467V11.4467C6.16004 11.9334 6.56004 12.3334 7.04671 12.3334H7.05337C7.54004 12.3334 7.94004 11.9334 7.94004 11.4467Z"
      fill={color}
    />
  </svg>
)
