// @ts-nocheck
import React from "react"
import { CostEffectivenessBreakdown as CEB } from "../../model/types"
import Pricebox from "@basset-la/components-products/dist/components/Pricebox"
import PriceboxRow from "@basset-la/components-products/dist/components/PriceboxRow"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { colors } from "../../utils/constants/constants"
import { useTheme } from "@basset-la/themed-components"
import { getLocale, formatPrice } from "@basset-la/components-products/dist/utils/helpers"

export interface Props {
  costEffectivenessBreakdown: CEB
}

const PriceboxBreakdownCostEffectiveness: React.FC<Props> = ({ costEffectivenessBreakdown }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()
  const { total, currency, ownCostEffectiveness, resellerCeded, airlineCeded, agencyCeded } = costEffectivenessBreakdown
  return (
    <div>
      <Pricebox
        backgroundColor={colors.GREEN2}
        textColor={theme.colors.text_white}
        title={t("CostEffectivenessBreakdown.title")}
        totalLabel={t("CostEffectivenessBreakdown.total")}
        totalValue={formatPrice(total, locale, currency)}
        startCollapsed={true}
      >
        <PriceboxRow
          label={t("CostEffectivenessBreakdown.ownCostEffectiveness")}
          value={formatPrice(ownCostEffectiveness.total, locale, currency)}
          variant="collapsible"
        >
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.markup")}
            value={formatPrice(ownCostEffectiveness.markup, locale, currency)}
          />
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.operatingCost")}
            value={formatPrice(ownCostEffectiveness.operatingCost, locale, currency)}
          />
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.fees")}
            value={formatPrice(ownCostEffectiveness.fees, locale, currency)}
          />
          {ownCostEffectiveness.adjustment !== undefined && (
            <PriceboxRow
              label={t("CostEffectivenessBreakdown.adjustment")}
              value={formatPrice(ownCostEffectiveness.adjustment, locale, currency)}
            />
          )}
        </PriceboxRow>
        {resellerCeded !== undefined && (
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.resellerCeded")}
            value={formatPrice(resellerCeded, locale, currency)}
          />
        )}
        {airlineCeded !== undefined && (
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.airlineCeded")}
            value={formatPrice(airlineCeded.total, locale, currency)}
            variant="collapsible"
          >
            <PriceboxRow
              label={t("CostEffectivenessBreakdown.over")}
              value={formatPrice(airlineCeded.over, locale, currency)}
            />
            <PriceboxRow
              label={t("CostEffectivenessBreakdown.commission")}
              value={formatPrice(airlineCeded.commission, locale, currency)}
            />
          </PriceboxRow>
        )}
        {agencyCeded !== undefined && (
          <PriceboxRow
            label={t("CostEffectivenessBreakdown.agencyCeded")}
            value={`- ${formatPrice(agencyCeded.total, locale, currency)}`}
            variant="collapsible"
          >
            <PriceboxRow
              label={t("CostEffectivenessBreakdown.over")}
              value={`- ${formatPrice(agencyCeded.over, locale, currency)}`}
            />
            <PriceboxRow
              label={t("CostEffectivenessBreakdown.commission")}
              value={`- ${formatPrice(agencyCeded.commission, locale, currency)}`}
            />
          </PriceboxRow>
        )}
      </Pricebox>
    </div>
  )
}

export default PriceboxBreakdownCostEffectiveness
