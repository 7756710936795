import React from "react"
import IconButton from "@material-ui/core/IconButton"
import styles from "./CheckRadioButton.style"
import { CheckIcon } from "../../icons/CheckIcon"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  backgroundColor?: string
  onClick?: React.MouseEventHandler
  checked?: boolean
}

const CheckRadioButton: React.FunctionComponent<Props> = ({ backgroundColor, onClick, checked }) => {
  const theme = useTheme()

  return (
    <IconButton className={styles.root} onClick={onClick}>
      <div
        className={styles.icon}
        style={{ backgroundColor: checked ? backgroundColor : theme.colors.background_primary }}
      >
        <CheckIcon color={checked ? theme.colors.background_primary : theme.colors.background_variant} />
      </div>
    </IconButton>
  )
}

export default CheckRadioButton
