import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { colors } from "../../utils/constants/constants"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      width: "100%",
      borderRadius: "4px",
      border: `1px solid ${t.colors.background_darker}`,
      padding: "16px",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      boxSizing: "border-box"
    }),
  mealplan: (roomOnly: boolean) =>
    css({
      color: roomOnly ? undefined : colors.GREEN
    }),
  roomContainer: css({
    display: "flex",
    flex: "1",
    flexShrink: 0,
    "> span": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "1.5"
    }
  }),
  amenitiesContainer: css({
    display: "flex",
    flex: "2",
    flexShrink: 0,
    flexDirection: "column",
    ">span": {
      display: "flex",
      flexDirection: "row",
      marginBottom: "8px",
      alignItems: "center",
      fontSize: "14px",
      lineHeight: "1.14",
      ":last-of-type": {
        marginBottom: 0
      },
      "> span": {
        marginRight: "8px"
      }
    }
  })
}
