import React from "react"
import styles from "./ProductRate.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { formatPrice, getLocale } from "../../utils/helpers"
import { ProductRateModel } from "./types"

export interface Props {
  rate: ProductRateModel
  onClick: () => void
}

const ProductRate: React.FC<Props> = ({ rate, onClick }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  return (
    <div className={styles.root(theme)} onClick={onClick}>
      <Text size={10} variant="light">
        {rate.provider}
      </Text>
      <div className={styles.priceContainer}>
        <Text className={styles.price(theme)} size={12} variant="regular">
          {formatPrice(rate.fare.daily, locale, rate.fare.currency)}
        </Text>
        <Text size={18} variant="bold">
          {t("ClusterRates.total", { price: formatPrice(rate.fare.total, locale, rate.fare.currency) })}
        </Text>
      </div>
    </div>
  )
}

export default ProductRate
