import { css } from "@emotion/css"

export default {
  containerComponentStyle: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff"
  }),

  containerStyle: css({
    display: "flex"
  }),

  leftTextContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
    paddingRight: "16px"
  }),

  rightTextContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "4px",
    borderLeft: "2px solid #E9EFF4",
    paddingLeft: "16px"
  }),

  airplaneIconStyle: css({
    width: "16px !important",
    color: "#126aff",
    marginRight: "8px",
    transform: "rotate(-90deg)"
  })
}
