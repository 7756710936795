import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    marginBottom: "24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "16px"
  }),

  content: css({
    marginBottom: "8px"
  }),

  image: css({
    maxWidth: "95px"
  }),

  dialogTitle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }),

  closeIcon: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),

  dialogContent: css({
    marginBottom: "24px"
  })
}
