import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      display: "flex",
      flexDirection: "column"
    }),
  titleContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "16px"
  }),
  titleLabel: css({
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: 1.14,
    letterSpacing: "0.15px",
    textAlign: "center",
    margin: 0
  }),
  titlePrice: css({
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "1.33",
    letterSpacing: "0.25px",
    margin: 0,
    textAlign: "center"
  }),
  fareTypeContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0",
    marginBottom: "8px",
    borderBottom: "1px solid #eaeaea"
  }),
  fareTypeContent: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px"
  }),
  fareType: (t: Theme) =>
    css({
      color: t.colors.success,
      backgroundColor: `${t.colors.success}15`,
      borderRadius: "4px",
      padding: "0 8px",
      fontSize: 12
    }),
  accountsUsedIcon: (t: Theme) =>
    css({
      width: "16px",
      color: t.colors.success
    }),
  priceContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  priceBreakdown: css({
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    lineHeight: "1.33",
    letterSpacing: "0.13px",
    fontWeight: 300,
    padding: "8px 0px"
  }),
  divider: css({
    "&.MuiDivider-root": {
      marginBottom: "8px"
    }
  }),
  priceBreakdownRow: css({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  }),
  total: css({
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    letterSpacing: "0.13px",
    marginBottom: "8px"
  }),
  buyButton: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.brand_primary,
        textTransform: "unset",
        width: "100%",
        marginTop: "24px",
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "0.17px",
        color: theme.colors.background_variant,
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      }
    }),
  quoteButton: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        backgroundColor: theme.colors.background_variant,
        textTransform: "unset",
        width: "100%",
        marginTop: "16px",
        fontSize: "16px",
        lineHeight: "1.5",
        letterSpacing: "0.17px",
        color: theme.colors.brand_primary,
        borderColor: theme.colors.brand_primary
      }
    }),
  costEffectiveness: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "0 8px",
    alignItems: "center",
    backgroundColor: "#d2eae5",
    borderRadius: "4px",
    height: "16px",
    "> span": {
      fontSize: "12px",
      color: "#156555",
      lineHeight: "1.33"
    }
  })
}
