import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      padding: "16px 0"
    }),
  title: css({
    padding: "0 32px"
  }),
  content: css({
    margin: 0,
    padding: 0,
    listStyleType: "none"
  }),
  row: css({
    padding: "16px 32px"
  }),
  rowTitle: css({
    margin: "0 0 16px 0"
  }),
  rowContent: css({
    margin: 0,
    padding: 0,
    listStyleType: "none",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "flex-start"
  }),
  bank: css({
    flex: 1,
    minWidth: "144px",
    maxWidth: "144px",
    margin: "0 16px 16px 0"
  }),
  bankImg: css({
    maxWidth: "144px",
    height: "32px"
  })
}
