import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  textField: css({
    "&.MuiTextField-root": {
      width: "100%"
    },
    "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
      cursor: "pointer"
    }
  }),
  item: (t: Theme) =>
    css({
      "&.MuiListItem-root": {
        fontSize: "14px",
        lineHeight: "1.14",
        color: t.colors.text_primary
      }
    }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  })
}
