import React, { useState } from "react"
import { AdjustType } from "./types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import ToggleButton from "./ToggleButton"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Button from "@basset-la/components-commons/dist/components/Button"
import styles from "./PriceAdjuster.styles"
import { useTheme } from "@basset-la/themed-components"
import NumberFormat from "react-number-format"

export interface Props {
  maxDiscount: number
  adjustType: AdjustType
  value: number
  currency: string
  onSave: (v: number, type: AdjustType) => void
}

const PriceAdjuster: React.FC<Props> = ({ maxDiscount, adjustType, value, currency, onSave }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const [currentAdjustType, setCurrentAdjustType] = useState<AdjustType>(adjustType)
  const [currentValue, setCurrentValue] = useState<number>(value)
  const [isValid, setIsValid] = useState<boolean>(true)
  const sign = currentAdjustType === "ADD" ? "+" : "-"

  const validate = (): boolean => {
    if (currentAdjustType === "SUB" && currentValue > maxDiscount) {
      setIsValid(false)
      return false
    }

    return true
  }

  const handleOnChangeToggle = (v: AdjustType) => {
    setCurrentAdjustType(v)
    setIsValid(true)
  }

  const handleOnChangeInput = (e: any) => {
    const str = e.target.value

    const v = str.length > 0 ? parseFloat(str).toFixed(2) : "0"
    setCurrentValue(Math.abs(parseFloat(v)))
  }

  const handleOnSave = () => {
    if (validate()) {
      onSave(currentValue, currentAdjustType)
    }
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.priceContainer}>
        <ToggleButton value={currentAdjustType} onChange={handleOnChangeToggle} />
        <TextField
          className={styles.textField(theme)}
          value={`${currentValue}`}
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            startAdornment: <InputAdornment position={"start"}>{`${sign} ${currency}`}</InputAdornment>
          }}
          variant={"outlined"}
          onChange={handleOnChangeInput}
          error={!isValid}
          helperText={isValid ? "" : t("PriceAdjuster.errorMsj")}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button fullWidth variant="contained" onClick={handleOnSave}>
          {t("PriceAdjuster.save")}
        </Button>
      </div>
    </div>
  )
}

export default PriceAdjuster

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { value: string } }) => void
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values: any) => {
        onChange({
          target: {
            value: values.value
          }
        })
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
      fixedDecimalScale
      isNumericString
    />
  )
}
