import React from "react"
import AccommodationCarouselView from "./AccommodationCarouselView"
import AccommodationCarouselModal from "./AccommodationCarouselModal"

export interface Props {
  imgs: string[]
}

const AccommodationCarousel: React.FC<Props> = ({ imgs }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleOpenFullScreen = () => {
    setModalOpen(true)
  }

  const handleCloseFullScreen = () => {
    setModalOpen(false)
  }

  const images = imgs.length ? imgs : [""]

  return (
    <>
      <AccommodationCarouselView
        imgs={images}
        index={selectedIndex}
        onChangeIndex={setSelectedIndex}
        onFullScreen={handleOpenFullScreen}
      />
      <AccommodationCarouselModal
        imgs={images}
        onChangeIndex={setSelectedIndex}
        index={selectedIndex}
        onClose={handleCloseFullScreen}
        open={modalOpen}
      />
    </>
  )
}

export default AccommodationCarousel
