import React from "react"
import { Props as SimpleAccommodationInfoProps } from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import styles from "./AccommodationClusterCombinedWeb.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import { AccommodationFare } from "../../model"
import ClusterCombinedPriceWeb from "./ClusterCombinedPriceWeb"

export interface Props extends SimpleAccommodationInfoProps {
  isMobile: boolean
  nights: number
  guests: number
  taxesDisclaimers?: string[]
  accommodationFare: AccommodationFare
  onViewHotel: () => void
}

const AccommodationClusterCombinedWeb: React.FC<Props> = ({
  isMobile,
  nights,
  guests,
  accommodationFare,
  onViewHotel,
  taxesDisclaimers,
  ...rest
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const drawPriceComponent = () => {
    return (
      <ClusterCombinedPriceWeb
        isMobile={isMobile}
        accommodationFare={accommodationFare}
        taxesDisclaimers={taxesDisclaimers}
      />
    )
  }

  return (
    <div className={styles.root(theme)}>
      {isMobile && <div className={styles.mobPrice}>{drawPriceComponent()}</div>}
      <div className={styles.infoContainer}>
        <SimpleAccommodationInfo mini={isMobile} {...rest} />
      </div>
      <div className={styles.priceContainer(theme)}>
        {!isMobile && drawPriceComponent()}
        <Button onClick={onViewHotel} variant="contained" className={styles.button(theme)}>
          {t("AccommodationClusterWeb.view_hotel")}
        </Button>
      </div>
    </div>
  )
}

export default AccommodationClusterCombinedWeb
