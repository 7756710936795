import React, { useState } from "react"
import styles from "./HotelListMap.styles"
import Sticky from "react-stickynode"
import GoogleMap from "google-map-react"
import Marker from "./Marker"
import { formatPrice } from "@basset-la/components-products"
import { useTranslation } from "react-i18next"
import LaunchIcon from "@material-ui/icons/Launch"
import IconInfo from "@material-ui/icons/InfoRounded"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import { calculateHighlightedAmenities, convertAccommodationFareToAgencyPriceBreakdown } from "../../utils/helpers"
import { AccommodationModel } from "../../model"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  nights: number
  accommodations: AccommodationModel[]
  top: string
  bottomBoundary?: string
  viewPagination: boolean
  onViewMoreHotels: () => void
  onHotelSelect: (id: string) => void
}

const HotelListMap: React.FC<Props> = ({
  nights,
  top,
  accommodations,
  bottomBoundary,
  viewPagination,
  onViewMoreHotels,
  onHotelSelect
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
  const { i18n } = useTranslation()

  const handleSelect = (index: number) => {
    setSelectedIndex(index)
  }

  const handleClearSelect = () => {
    setSelectedIndex(null)
  }

  const handleLaunchSelected = (id: string) => {
    onHotelSelect(id)
  }

  const defaultCenter =
    accommodations && accommodations.length > 0
      ? {
          lat: accommodations[0].geo_location.latitude,
          lng: accommodations[0].geo_location.longitude
        }
      : { lat: 0, lng: 0 }

  return (
    <Sticky innerZ={10} top={top} bottomBoundary={bottomBoundary}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.mapContainer}>
            <GoogleMap
              defaultCenter={defaultCenter}
              onClick={handleClearSelect}
              onChange={handleClearSelect}
              defaultZoom={13}
              bootstrapURLKeys={{ key: "AIzaSyDGPT1jvVhMQtKD3dtZNFu_2iuxBeGH5ns" }}
            >
              {accommodations.map((e, i) => {
                const { baseRate } = convertAccommodationFareToAgencyPriceBreakdown(e.rates[0].fare!, 0, 0)
                const rateByNight = baseRate / nights
                const label = formatPrice(rateByNight, i18n.language, e.rates[0].currency)

                return (
                  <Marker
                    key={i}
                    lat={e.geo_location.latitude}
                    lng={e.geo_location.longitude}
                    width={label.length * 11}
                    label={label}
                    selected={i === selectedIndex}
                    onClick={() => handleSelect(i)}
                  />
                )
              })}
            </GoogleMap>
          </div>
          {selectedIndex !== null && (
            <div className={styles.hotel}>
              <LaunchIcon
                className={styles.icon}
                onClick={() => handleLaunchSelected(accommodations[selectedIndex!].id)}
              />
              <SimpleAccommodationInfo
                onHotelSelect={() => onHotelSelect(accommodations[selectedIndex!].id)}
                name={accommodations[selectedIndex!].name}
                address={accommodations[selectedIndex!].address}
                stars={accommodations[selectedIndex!].stars}
                photo={accommodations[selectedIndex!].images[0].url || null}
                //freeCancel={accommodations[selectedIndex!].rates[0].refundable}
                {...calculateHighlightedAmenities(accommodations[selectedIndex!].amenities || [])}
                mini={true}
              />
            </div>
          )}
        </div>
      </div>

      {viewPagination && (
        <div className={styles.mapPagination}>
          <IconInfo className={styles.infoIcon} /> <span>{t("HotelListMap.title")}</span>
          <span className={styles.link(theme)} onClick={() => onViewMoreHotels()}>
            {t("HotelListMap.more")}
          </span>
        </div>
      )}
    </Sticky>
  )
}

export default HotelListMap
