import { css } from "@emotion/css"

export default {
  root: css({
    marginBottom: "24px"
  }),
  header: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "8px"
  }),

  text: (color: string) =>
    css({
      marginLeft: "8px",
      color: color
    }),
  arrow: (color: string) =>
    css({
      "&.MuiSvgIcon-root": {
        color: color
      }
    })
}
