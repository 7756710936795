import * as React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"
import Select from "@basset-la/components-commons/dist/components/Select"
import styles from "./CheckoutDatePicker.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  title: string
  maxAge: number
  minAge: number
  day?: number
  month?: number
  year?: number
  errors?: string[]
  disabled?: boolean
  onChange: (type: "day" | "month" | "year", value: number) => void
  shrink?: boolean
  className?: string
}

const CheckoutDatePicker = ({
  title,
  maxAge,
  minAge,
  day,
  month,
  year,
  errors,
  disabled,
  onChange,
  shrink,
  className
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const currentYear = new Date().getFullYear()

  const handleSelectDay = (v: string[]) => {
    const d = parseInt(v[0])
    onChange("day", d)
  }

  const handleSelectMonth = (v: string[]) => {
    const m = parseInt(v[0])
    onChange("month", m)
  }

  const handleSelectYear = (v: string[]) => {
    const y = parseInt(v[0])
    onChange("year", y)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      <Text className={styles.title(theme)} size={14} variant="regular">
        {title}
      </Text>
      <div className={styles.content}>
        <div className={styles.item}>
          <Select
            className={`${styles.select} select-day`}
            variant="item"
            disabled={disabled}
            selected={day ? [`${day}`] : undefined}
            error={errors && errors.length > 0}
            label={t("CheckoutDatePicker.day")}
            values={Array.from({ length: 31 }, (_, i) => i + 1).map(i => {
              const value = `${i}`
              return { code: value, name: value }
            })}
            onSelect={handleSelectDay}
            shrink={shrink}
          ></Select>
        </div>
        <div className={styles.item}>
          <Select
            className={`${styles.select} select-month`}
            variant="item"
            disabled={disabled}
            selected={month ? [`${month}`] : undefined}
            error={errors && errors.length > 0}
            label={t("CheckoutDatePicker.month")}
            values={Array.from({ length: 12 }, (_, i) => i + 1).map(i => {
              const value = `${i}`
              return { code: value, name: t(`CheckoutDatePicker.months.${i}`) }
            })}
            onSelect={handleSelectMonth}
            shrink={shrink}
          ></Select>
        </div>
        <div className={styles.item}>
          <Select
            className={`${styles.select} select-year`}
            variant="item"
            disabled={disabled}
            selected={year ? [`${year}`] : undefined}
            error={errors && errors.length > 0}
            label={t("CheckoutDatePicker.year")}
            values={Array.from({ length: maxAge - minAge + 1 }, (_, i) => currentYear - (i + minAge)).map(i => {
              const value = `${i}`
              return { code: value, name: value }
            })}
            onSelect={handleSelectYear}
            shrink={shrink}
          ></Select>
        </div>
      </div>
      {errors && errors.length > 0 && (
        <div className={styles.errors}>
          {errors.map((err, i) => {
            return (
              <Text className={styles.error(theme)} size={12}>
                {err}
              </Text>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CheckoutDatePicker
