import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      backgroundColor: theme.colors.background_primary
    }),
  title: css({
    margin: 0,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5",
    marginBottom: "8px"
  }),
  subTitle: css({
    margin: 0,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    marginBottom: "8px"
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "8px",
    ":last-child": {
      marginBottom: 0
    }
  }),
  rowDescription: css({
    fontSize: "16px",
    lineHeight: "1.5",
    marginLeft: "8px"
  }),
  link: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        color: theme.colors.brand_primary,
        fontSize: "16px",
        lineHeight: "1.5",
        cursor: "pointer",
        textTransform: "none",
        fontWeight: "normal"
      }
    }),
  buttonsContainer: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  })
}
