import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_primary,
      padding: "24px"
    }),
  logoContainer: css({
    height: "48px"
  }),
  logo: css({
    maxHeight: "48px"
  }),
  reservationNo: (theme: Theme) =>
    css({
      height: "48px",
      textAlign: "center",
      color: theme.colors.text_primary
    }),
  reservationNoTitle: (theme: Theme) =>
    css({
      color: theme.colors.text_variant
    }),
  providerReservationNo: css({
    textAlign: "right",
    height: "48px"
  }),
  providerReservationNoDetail: (theme: Theme) =>
    css({
      display: "inline-block",
      backgroundColor: theme.colors.background_variant,
      padding: "10px 15px",
      marginLeft: "10px"
    }),
  providerReservationNoTitle: (theme: Theme) =>
    css({
      color: theme.colors.text_variant
    }),
  providerReservationNoId: (theme: Theme) =>
    css({
      color: theme.colors.brand_primary
    }),
  accommodation: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_variant,
      color: theme.colors.brand_primary,
      padding: "8px 12px",
      fontSize: "16px"
    }),
  accommodationIcon: css({
    float: "left",
    fontWeight: 300
  }),
  accommodationCityName: css({
    float: "right",
    fontWeight: 300
  }),
  accommodationData: (theme: Theme) =>
    css({
      width: "50%",
      border: `1px solid ${theme.colors.background_darker}`
    }),
  accommodationSubTitle: (theme: Theme) =>
    css({
      color: theme.colors.text_variant,
      fontWeight: 300
    }),
  accommodationValue: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 500
    }),
  checkin: (theme: Theme) =>
    css({
      width: "25%",
      textAlign: "center",
      borderRight: "none",
      border: `1px solid ${theme.colors.background_darker}`
    }),
  checkout: (theme: Theme) =>
    css({
      width: "25%",
      borderRight: "none",
      textAlign: "center",
      border: `1px solid ${theme.colors.background_darker}`
    }),
  disclamer: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_variant,
      padding: "20px 16px",
      margin: "8px 0"
    }),
  disclamerTitle: (theme: Theme) =>
    css({
      color: theme.colors.brand_primary,
      fontWeight: 500
    }),
  remarks: css({
    margin: "8px 0px"
  }),
  hr: (theme: Theme) =>
    css({
      borderTop: `1px solid ${theme.colors.background_darker}`,
      borderBottom: "none"
    }),
  width: (w: string) =>
    css({
      width: w
    }),
  hotelData: (theme: Theme) =>
    css({
      width: "50%",
      border: `1px solid ${theme.colors.background_darker}`,
      padding: "8px",
      marginTop: "16px"
    }),
  hotelDataTitle: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
      color: theme.colors.brand_primary,
      marginBottom: "16px"
    }),
  hotelInfo: css({
    display: "flex",
    flexDirection: "row"
  }),
  hotelImg: css({
    width: "314px",
    height: "144px"
  }),
  hotelDesc: css({
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    gap: "16px"
  })
}
