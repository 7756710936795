import React from "react"
import FilterBarButton from "@basset-la/components-products/dist/components/FilterBarButton"
import Popover from "@material-ui/core/Popover"
import FilterList from "@material-ui/icons/FilterList"
import AnimateHeight from "react-animate-height"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AirlineFilter, { AirlineFilterProps } from "../AirlineFilter"
import AirportFilter, { AirportFilterProps } from "../AirportFilter"
import StopsFilter, { Props as StopsFilterProps } from "../StopsFilter/StopsFilter"
import TimePickerFilter, { TimePickerFilterProps } from "../TimePickerFilter"
import styles from "./FlightsFilterBar.styles"
import { useTheme } from "@basset-la/themed-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  airlineFilterProps: AirlineFilterProps
  stopsFilterProps: StopsFilterProps
  airportFilterProps: AirportFilterProps
  timePickerFilterProps: TimePickerFilterProps
}

const FlightsFiltersBar: React.FC<Props> = ({
  airlineFilterProps,
  airportFilterProps,
  stopsFilterProps,
  timePickerFilterProps
}) => {
  const { t } = useTranslation(I18N_NS)
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [content, setContent] = React.useState<React.ReactElement | null>(null)
  const open = Boolean(anchorEl)

  const theme = useTheme()

  const handleClick = (c: React.ReactElement) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setContent(c)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.iconContainer}>
        <FilterList className={styles.icon(theme)} />
      </div>
      <div className={styles.barContainer}>
        <FilterBarButton
          onClick={handleClick(
            <div className={styles.popover}>
              <AirlineFilter {...airlineFilterProps} onDone={isMobile ? handleClose : undefined} />
            </div>
          )}
        >
          {t("FlightsFiltersBar.airlines")}
        </FilterBarButton>
        <FilterBarButton
          onClick={handleClick(
            <div className={styles.popover}>
              <StopsFilter {...stopsFilterProps} onDone={isMobile ? handleClose : undefined} />
            </div>
          )}
        >
          {t("FlightsFiltersBar.stops")}
        </FilterBarButton>
        <FilterBarButton
          onClick={handleClick(
            <div className={styles.popover}>
              <AirportFilter {...airportFilterProps} />
            </div>
          )}
        >
          {t("FlightsFiltersBar.airports")}
        </FilterBarButton>
        <FilterBarButton
          onClick={handleClick(
            <div className={styles.popover}>
              <TimePickerFilter {...timePickerFilterProps} />
            </div>
          )}
        >
          {t("FlightsFiltersBar.flightTime")}
        </FilterBarButton>
      </div>
      {!isMobile && content != null && (
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          {content}
        </Popover>
      )}
      {isMobile && (
        <AnimateHeight className={styles.mobileView(theme)} duration={300} height={open ? "auto" : 0}>
          {content != null && content}
        </AnimateHeight>
      )}
      {isMobile && open && content != null && <div className={styles.background} onClick={handleClose} />}
    </div>
  )
}
export default FlightsFiltersBar
