import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "inherited"
  }),
  section: (collapsible: boolean) =>
    css({
      padding: !collapsible ? "16px" : "0 0 16px 0",
      width: "100%",
      " > div": {
        backgroundColor: "inherited"
      },
      ":last-of-type": {
        paddingBottom: 0
      }
    }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        color: theme.colors.brand_primary,
        textTransform: "none",
        alignSelf: "flex-start",
        width: "fit-content",
        height: "fit-content",
        margin: "8px 0px 8px 8px"
      }
    }),
  container: css({
    padding: "0 8px"
  })
}
