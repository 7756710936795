import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const TimerIcon: React.FunctionComponent<Props> = ({ ...rest }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0015 1.5H14.0015C14.5515 1.5 15.0015 1.95 15.0015 2.5C15.0015 3.05 14.5515 3.5 14.0015 3.5H10.0015C9.4515 3.5 9.0015 3.05 9.0015 2.5C9.0015 1.95 9.4515 1.5 10.0015 1.5ZM13.0015 13.5C13.0015 14.05 12.5515 14.5 12.0015 14.5C11.4515 14.5 11.0015 14.05 11.0015 13.5V9.5C11.0015 8.95 11.4515 8.5 12.0015 8.5C12.5515 8.5 13.0015 8.95 13.0015 9.5V13.5ZM19.0315 7.89L19.7815 7.14C20.1615 6.76 20.1715 6.13 19.7815 5.74L19.7715 5.73C19.3815 5.34 18.7615 5.35 18.3715 5.73L17.6215 6.48C16.0715 5.24 14.1215 4.5 12.0015 4.5C7.2015 4.5 3.1215 8.46 3.0015 13.26C2.8715 18.34 6.9415 22.5 12.0015 22.5C16.9815 22.5 21.0015 18.47 21.0015 13.5C21.0015 11.38 20.2615 9.43 19.0315 7.89ZM5.0015 13.5C5.0015 17.37 8.1315 20.5 12.0015 20.5C15.8715 20.5 19.0015 17.37 19.0015 13.5C19.0015 9.63 15.8715 6.5 12.0015 6.5C8.1315 6.5 5.0015 9.63 5.0015 13.5Z"
      fill="#126AFF"
    />
  </svg>
)
