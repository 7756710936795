import React, { useRef, useState } from "react"
import styles from "./FlightStopsSelect.styles"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Menu from "@material-ui/core/Menu"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import Checkbox from "@basset-la/components-commons/dist/components/Checkbox"
import Text from "@basset-la/components-commons/dist/components/Text"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import { Stops } from "../Searchbox"
import { StopIcon } from "../../icons/StopIcon"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  value?: Stops
  onChange: (v: Stops) => void
}

const FlightStopsSelect: React.FC<Props> = ({ value, onChange }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [currentValue, setCurrentValue] = useState(value || "NO_VALUE")
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOptionClick = (value: Stops) => {
    onChange(value)
    setCurrentValue(value)
    if (!isMobile) {
      setOpen(false)
    }
  }

  const OPTIONS: Stops[] = ["NO_VALUE", "0", "1", "2"]

  const getText = (value: Stops): string => {
    if (value === "0") return t("StopsSelect.nonStop")
    if (value === "1") return t("StopsSelect.stop", { count: 1 })
    if (value === "2") return t("StopsSelect.stop", { count: 2 })
    return t("StopsSelect.allStops")
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("StopsSelect.label")}
        </Text>
        <SearchboxTextField
          value={getText(currentValue)}
          leftIcon={<StopIcon color={theme.colors.brand_primary} />}
          rightIcon={<ArrowIcon />}
          onClick={handleOpen}
          className={styles.textField}
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Menu className={styles.menu} open={open} onClose={handleClose} anchorEl={inputRef.current}>
          {OPTIONS.map((opt, idx) => (
            <MenuItem
              key={idx}
              value={opt}
              onClick={() => {
                handleOptionClick(opt)
              }}
            >
              {getText(opt)}
            </MenuItem>
          ))}
        </Menu>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          onClose={handleClose}
          onAction={handleClose}
          actionButtonText={t("StopsSelect.apply")}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              value={getText(currentValue)}
              leftIcon={<StopIcon color={theme.colors.brand_primary} />}
            />
          }
        >
          {OPTIONS.map((opt, idx) => (
            <div key={idx} className={styles.itemMobile} onClick={() => handleOptionClick(opt)}>
              <Checkbox checked={opt === currentValue} />
              <Text>{getText(opt)}</Text>
            </div>
          ))}
        </FullScreenDialog>
      )}
    </>
  )
}

export default FlightStopsSelect
