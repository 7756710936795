import React, { useState } from "react"
import { Cluster as ClusterModel } from "../../model/types"
import Cluster from "../Cluster/Cluster"
import styles from "./ClusterWeb.styles"
import ClusterPriceWeb from "./ClusterPriceWeb"
import { useTheme } from "@basset-la/themed-components"
import ClusterInstallmentsWeb from "./ClusterInstallmentsWeb"
import ClusterButtonWeb from "./ClusterButtonWeb"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Advertising, { AdvertisingData } from "../Advertising"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"

export interface Props {
  cluster: ClusterModel
  advertising?: AdvertisingData
  onSelectItinerary: (selectedOptions: number[], selectedBrand: number) => void
  customButtons?: CustomButtonText[]
  selectedBrand: number
  onOpenBrandSelectionDialog: (selectedOptions: number[], selectedBrand: number) => void
  key?: string | number
}

const ClusterWeb: React.FC<Props> = ({
  cluster,
  advertising,
  onSelectItinerary,
  customButtons,
  selectedBrand,
  onOpenBrandSelectionDialog,
  key
}) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>(new Array(cluster.segments.length).fill(0))

  const theme = useTheme()

  const handleOnSelect = (segmentIndex: number, optionIndex: number) => {
    let selectedOptionsCopy = [...selectedOptions]
    selectedOptionsCopy[segmentIndex] = optionIndex
    setSelectedOptions(selectedOptionsCopy)
  }

  const handleOnSelectItinerary = () => {
    onSelectItinerary(selectedOptions, selectedBrand)
  }

  const handleOpenBrandSelectionDialog = () => {
    onOpenBrandSelectionDialog(selectedOptions, selectedBrand)
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <>
      {isMobile && (
        <>
          <div className={styles.containerPriceMobile(theme)}>
            {advertising && (
              <Advertising
                data={advertising}
                position="CLUSTER"
                price={cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price.total : cluster.price.total}
                currency={cluster.price.currency}
              />
            )}
            <ClusterPriceWeb
              price={cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price}
              isMobile={true}
            />
            {cluster.form_of_payments && cluster.form_of_payments.length > 0 && (
              <ClusterInstallmentsWeb
                className={styles.installments(true)}
                isMobile={true}
                formOfPayments={cluster.form_of_payments}
              />
            )}
          </div>
          <div className={styles.root(theme, isMobile)}>
            <div className={styles.clusterContainer(isMobile)}>
              <Cluster
                variant="B2C"
                mini={isMobile}
                cluster={cluster}
                onSelect={handleOnSelect}
                selectedOptions={selectedOptions}
                selectedBrand={selectedBrand}
                onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog}
              />
            </div>
            <div className={styles.containerButtonMobile}>
              <ClusterButtonWeb key={key} customButtons={customButtons} onSelectItinerary={handleOnSelectItinerary} />
            </div>
          </div>
        </>
      )}
      {!isMobile && (
        <div className={styles.root(theme, isMobile)}>
          <div className={styles.clusterContainer(isMobile)}>
            <Cluster
              variant="B2C"
              mini={isMobile}
              cluster={cluster}
              onSelect={handleOnSelect}
              selectedOptions={selectedOptions}
              selectedBrand={selectedBrand}
              onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog}
            />
          </div>
          <div className={styles.priceContainer}>
            {advertising && (
              <Advertising
                data={advertising}
                position="CLUSTER"
                price={cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price.total : cluster.price.total}
                currency={cluster.price.currency}
              />
            )}
            <ClusterPriceWeb
              price={cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price}
              isMobile={false}
            />
            <ClusterButtonWeb key={key} customButtons={customButtons} onSelectItinerary={handleOnSelectItinerary} />
            {cluster.form_of_payments && cluster.form_of_payments.length > 0 && (
              <ClusterInstallmentsWeb
                className={styles.installments(false)}
                isMobile={false}
                formOfPayments={cluster.form_of_payments}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ClusterWeb
