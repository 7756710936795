import React from "react"
import MaterialSwitch from "@material-ui/core/Switch"
import { useTheme } from "@basset-la/themed-components"
import styles from "./Switch.styles"

export interface Props {
  checked?: boolean
  onChange: (checked: boolean) => void
}

const Switch: React.FC<Props> = ({ checked, onChange }) => {
  const theme = useTheme()

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return <MaterialSwitch className={styles.root(theme)} checked={checked} onChange={handleOnChange} />
}

export default Switch
