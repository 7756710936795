import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const InternetIconNotFound: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="14" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.371325 4.69331C0.711325 5.03331 1.25132 5.06665 1.61799 4.75998C4.73133 2.19998 9.25133 2.19998 12.3713 4.75331C12.7447 5.05998 13.2913 5.03331 13.6313 4.69331C14.0247 4.29998 13.998 3.64665 13.5647 3.29331C9.75799 0.17998 4.25132 0.17998 0.437991 3.29331C0.00465806 3.63998 -0.0286753 4.29331 0.371325 4.69331ZM5.54466 9.86665L6.52466 10.8466C6.78466 11.1066 7.20466 11.1066 7.46466 10.8466L8.44466 9.86665C8.75799 9.55331 8.69133 9.01331 8.29132 8.80665C7.47799 8.38665 6.50466 8.38665 5.68466 8.80665C5.30466 9.01331 5.23132 9.55331 5.54466 9.86665ZM4.27799 7.46665C3.89799 7.73998 3.38466 7.70665 3.05799 7.37998C2.65132 6.97331 2.67799 6.29998 3.13799 5.96665C5.43799 4.30665 8.56466 4.30665 10.858 5.96665C11.318 6.29331 11.3447 6.97331 10.9447 7.37331L10.938 7.37998C10.6113 7.70665 10.098 7.73331 9.71799 7.46665C8.09132 6.31331 5.90466 6.31331 4.27799 7.46665Z"
      fill="#9B9B9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C6.24 2 4 4.24 4 7C4 9.76 6.24 12 9 12C11.76 12 14 9.76 14 7C14 4.24 11.76 2 9 2ZM9 7.5C8.725 7.5 8.5 7.275 8.5 7V5C8.5 4.725 8.725 4.5 9 4.5C9.275 4.5 9.5 4.725 9.5 5V7C9.5 7.275 9.275 7.5 9 7.5ZM8.5 8.5V9.5H9.5V8.5H8.5Z"
      fill="#D0021B"
    />
  </svg>
)
