import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiTextField-root": {
        ":hover .MuiInputLabel-shrink": {
          color: `${t.colors.brand_primary} !important`
        },
        " .MuiInputLabel-outlined": {
          transform: "translate(14px, 14px) scale(1)"
        },
        " .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)"
        },
        " .MuiInputLabel-root": {
          color: t.colors.text_variant,
          "&.Mui-focused": {
            color: `${t.colors.brand_primary} !important`
          },
          "&.Mui-error": {
            color: t.colors.error
          }
        },
        " .MuiOutlinedInput-root": {
          color: t.colors.text_primary,
          fontSize: "14px",
          lineHeight: "1.14",
          letterSpacing: "0.15px",
          height: "40px",
          ":hover, &.Mui-focused": {
            " .MuiOutlinedInput-notchedOutline": {
              borderColor: t.colors.brand_primary
            }
          },
          "&.Mui-error": {
            borderColor: t.colors.error
          },
          borderColor: t.colors.text_variant
        }
      }
    })
}
