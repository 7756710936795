import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import styles from "./ClusterButtonWeb.styles"
import { I18N_NS } from "../../utils/constants"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"

export interface Props {
  onSelectItinerary: React.MouseEventHandler<HTMLButtonElement>
  customButtons?: CustomButtonText[]
  key?: string | number
}

const ClusterButtonWeb: React.FC<Props> = ({ onSelectItinerary, customButtons, key = 0 }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const customButtom = customButtons?.find(button => button.type === "RESERVE")

  return (
    <Button
      id={`flights-buy-btn-${key}`}
      variant="contained"
      className={styles.buyButton(theme)}
      onClick={onSelectItinerary}
    >
      {customButtom ? customButtom.value : t("ClusterPriceDesktop.buy")}
    </Button>
  )
}

export default ClusterButtonWeb
