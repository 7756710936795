import React from "react"
import Popper from "@material-ui/core/Popper"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { AutocompleteGroupedOption } from "./types"
import OptionList from "./OptionList"

export interface Props {
  groupedOptions: AutocompleteGroupedOption[]
  renderOption: (option: any) => React.ReactNode
  onClickAway: (event: React.MouseEvent<Document>) => void
  anchorEl: HTMLElement | null
  onSelectOption: (option: any) => void

  renderOptionTitle?: (type: string, label: string) => React.ReactNode
  renderOptionHeader?: () => React.ReactNode
  fetchingData?: boolean
}

const DesktopOptions: React.FC<Props> = ({
  groupedOptions,
  renderOption,
  onClickAway,
  anchorEl,
  renderOptionTitle,
  renderOptionHeader,
  onSelectOption,
  fetchingData
}) => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        style={{
          minWidth: anchorEl ? anchorEl.clientWidth : 0,
          zIndex: 2000,
          boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
        }}
        placement="bottom-start"
        open={anchorEl !== null}
        anchorEl={anchorEl}
      >
        <OptionList
          groupedOptions={groupedOptions}
          onSelectOption={onSelectOption}
          renderOption={renderOption}
          renderOptionTitle={renderOptionTitle}
          renderOptionHeader={renderOptionHeader}
          fetchingData={fetchingData}
        />
      </Popper>
    </ClickAwayListener>
  )
}

export default DesktopOptions
