import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiList-root": {
        padding: 0,
        backgroundColor: t.colors.background_primary,
        width: "100%",
        "> div >.MuiListItem-root": {
          width: "100%",
          justifyContent: "start",
          padding: "8px 16px"
        }
      }
    }),
  loading: (t: Theme) =>
    css({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      ">.MuiCircularProgress-root": {
        color: t.colors.brand_primary
      }
    })
}
