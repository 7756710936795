import React from "react"
import styles from "./StopsGraphic.style"
import { colors } from "../../utils/constants/constants"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  stops: number
}

const StopsGraphic: React.FunctionComponent<Props> = ({ stops }) => {
  const theme = useTheme()
  return (
    <div className={styles.root} style={{ backgroundColor: stops > 0 ? theme.colors.text_variant : colors.GREEN }}>
      <div className={styles.bulletContainer}>
        {Array.from(Array(stops)).map((e: any, i: number) => {
          return <span className={styles.bullet(theme)} key={i} />
        })}
      </div>
    </div>
  )
}

export default StopsGraphic
