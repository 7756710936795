import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    margin: "10px",
    " > p": {
      fontSize: "14px",
      lineHeight: 1.14,
      color: "#4a4a4a",
      marginBottom: "8px",
      fontWeight: 300
    },
    " > p:last-child": {
      marginBottom: 0
    },
    fontSize: "12px",
    lineHeight: 1.33
  }),
  mealPlanContainer: css({
    display: "flex"
  }),
  mealPlanNotIncluded: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      marginLeft: "4px"
    }),
  cancelPolicy: (theme: Theme) =>
    css({
      color: theme.brand_colors.highlight_color
    })
}
