import React, { ChangeEvent } from "react"
import MUISelect from "@material-ui/core/Select/Select"
import { MenuItem } from "@material-ui/core"
import { useTheme } from "@basset-la/themed-components"
import { KeyboardArrowDownOutlined } from "@material-ui/icons"
import styles from "./DatePickerSelect.styles"

export interface Props {
  selectedValue?: string | number
  values?: any[]
  onChange: (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => void
}

export const DatePickerSelect = ({ selectedValue, values = [], onChange }: Props) => {
  const theme = useTheme()
  const isNumeric = typeof values[0] === "number"

  return (
    <MUISelect
      className={styles.select(theme)}
      onChange={onChange}
      value={selectedValue}
      variant="outlined"
      IconComponent={KeyboardArrowDownOutlined}
    >
      {values?.map((label, value) => {
        return (
          <MenuItem key={label} className={styles.menuItem} value={isNumeric ? label : value}>
            {label}
          </MenuItem>
        )
      })}
    </MUISelect>
  )
}
export default DatePickerSelect
