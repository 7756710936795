import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    fontFamily: "roboto",
    fontSize: "14px",
    borderRadius: "4px",
    padding: "6px 16px",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "42px"
  }),
  icon: css({
    width: "24px",
    height: "24px",
    marginRight: "16px"
  }),
  message: (theme: Theme) =>
    css({
      flex: 9,
      color: theme.colors.text_primary
    }),
  action: css({
    flex: 1,
    display: "flex",
    justifyContent: "flex-end"
  }),
  success: (theme: Theme) =>
    css({
      color: theme.colors.success,
      backgroundColor: `${theme.colors.success}10`,
      border: `solid 1px ${theme.colors.success}`
    }),
  warning: (theme: Theme) =>
    css({
      color: theme.colors.warning,
      backgroundColor: `${theme.colors.warning}10`,
      border: `solid 1px ${theme.colors.warning}`
    }),
  error: (theme: Theme) =>
    css({
      color: theme.colors.error,
      backgroundColor: `${theme.colors.error}10`,
      border: `solid 1px ${theme.colors.error}`
    }),
  info: (theme: Theme) =>
    css({
      color: theme.colors.info,
      backgroundColor: `${theme.colors.info}10`,
      border: `solid 1px ${theme.colors.info}`
    })
}
