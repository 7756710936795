import React from "react"
import styles from "./ProductLoader.styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import FlightsIcon from "../../icons/FlightsIcon"
import AccommodationsIcon from "../../icons/AccommodationsIcon"
import CombinedProductsIcon from "../../icons/CombinedProductsIcon"
import CheckoutIcon from "../../icons/CheckoutIcon"
import Text from "../Text"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import CarsIcon from "../../icons/CarsIcon"
import InsurancesLoaderIcon from "../../icons/InsurancesLoaderIcon"

export interface Props {
  product_variant:
    | "FLIGHTS_RESULT"
    | "FLIGHTS_CHECKOUT"
    | "FLIGHTS_PAYMENT"
    | "ACCOMMODATIONS_RESULT"
    | "ACCOMMODATIONS_ROOM_RESULT"
    | "ACCOMMODATIONS_CHECKOUT"
    | "ACCOMMODATIONS_PAYMENT"
    | "CARS_RESULT"
    | "CARS_DETAILS"
    | "CARS_CHECKOUT"
    | "CARS_PAYMENT"
    | "INSURANCES_RESULT"
    | "INSURANCES_DETAILS"
    | "INSURANCES_CHECKOUT"
    | "INSURANCES_PAYMENT"
  variant: "WEB" | "DESKTOP"
  is_mobile?: boolean
}

const ProductLoader: React.FC<Props> = ({ product_variant, variant, is_mobile }) => {
  const { t } = useTranslation(I18N_NS)

  const getIcon = () => {
    switch (product_variant) {
      case "FLIGHTS_RESULT":
        return <FlightsIcon />

      case "CARS_RESULT":
      case "CARS_DETAILS":
        return <CarsIcon />

      case "INSURANCES_RESULT":
      case "INSURANCES_DETAILS":
        return <InsurancesLoaderIcon />

      case "FLIGHTS_PAYMENT":
      case "ACCOMMODATIONS_PAYMENT":
      case "CARS_PAYMENT":
      case "INSURANCES_PAYMENT":
        return <CheckoutIcon />

      case "FLIGHTS_CHECKOUT":
      case "ACCOMMODATIONS_CHECKOUT":
      case "CARS_CHECKOUT":
      case "INSURANCES_CHECKOUT":
        return <CombinedProductsIcon />

      default:
        return <AccommodationsIcon />
    }
  }

  return (
    <div className={styles.containerStyle(variant)}>
      <div className={styles.mainContainerStyle}>
        <div className={styles.imgContainerStyle}>{getIcon()}</div>
        <LinearProgress className={styles.linearStyle} />
      </div>

      <div className={styles.text(is_mobile)}>
        <Text size={16} variant="bold">
          {t(`ProductLoader.${product_variant}`)}
        </Text>
      </div>
    </div>
  )
}

export default ProductLoader
