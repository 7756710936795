import React from "react"
import { FlightType } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import styles from "./FlightTypeSelect.styles"
import Radio from "@basset-la/components-commons/dist/components/Radio"

export interface Props {
  value: FlightType
  onChange: (v: FlightType) => void
  disallowMultipleDestinationsSearch?: boolean
}

const FlightTypeSelect: React.FC<Props> = ({ value, onChange, disallowMultipleDestinationsSearch }) => {
  const { t } = useTranslation(I18N_NS)

  const handleOnChange = (v: FlightType) => {
    onChange(v)
  }

  const roundTripChecked = value === "ROUND_TRIP"

  const oneWayChecked = value === "ONE_WAY"

  const multipleDestinationsChecked = value === "MULTIPLE_DESTINATIONS"

  return (
    <div id={"flight-type-select-container"} className={styles.select}>
      <div
        id="flight-round_trip-type"
        data-flight-selected={roundTripChecked}
        className={styles.item}
        onClick={() => handleOnChange("ROUND_TRIP")}
      >
        <Radio checked={roundTripChecked} />
        {t("FlightTypeSelect.roundTrip")}
      </div>
      <div
        id="flight-one_way-type"
        data-flight-selected={oneWayChecked}
        className={styles.item}
        onClick={() => handleOnChange("ONE_WAY")}
      >
        <Radio checked={oneWayChecked} />
        {t("FlightTypeSelect.oneWay")}
      </div>
      {!disallowMultipleDestinationsSearch && (
        <div
          id="flight-multiple_destinations-type"
          data-flight-selected={multipleDestinationsChecked}
          className={styles.item}
          onClick={() => handleOnChange("MULTIPLE_DESTINATIONS")}
        >
          <Radio checked={multipleDestinationsChecked} />
          {t("FlightTypeSelect.multipleDestinations")}
        </div>
      )}
    </div>
  )
}

export default FlightTypeSelect
