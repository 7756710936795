import React from "react"
import styles from "./ItineraryNotFoundDialog.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"

export interface Props {
  open: boolean
  onClose: () => void
  onSearch: () => void
}

const ItineraryNotFoundDialog: React.FC<Props> = ({ open, onClose, onSearch }) => {
  const { t } = useTranslation(I18N_NS)

  const handleClose = () => {
    onClose()
  }

  const handleSearch = () => {
    onSearch()
  }

  return (
    <Dialog className={styles.dialog} open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle className={styles.dialogTitle}>
        <Text size={18} variant="bold">
          {t("ItineraryNotFoundDialog.title")}
        </Text>
        <IconButton id="itinerary-not-found-dialog-close-btn" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <Text size={16} variant="regular">
            {t("ItineraryNotFoundDialog.text")}
          </Text>
          <Button variant="contained" id="itinerary-not-found-dialog-search-btn" onClick={handleSearch}>
            {t("ItineraryNotFoundDialog.search")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ItineraryNotFoundDialog
