import React from "react"
import styles from "./LoadingButton.styles"

const LoadingButton = () => {
  return (
    <div>
      <div className={[styles.animatedBg, styles.loadingBar].join(" ")} />
      <div className={[styles.animatedBg, styles.loadingBar].join(" ")} />
      <div className={[styles.animatedBg, styles.button].join(" ")} />
    </div>
  )
}

export default LoadingButton
