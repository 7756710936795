import React from "react"
import { Amenity } from "../../model/accommodationDetail"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./AmenitiesDescription.styles"
import AnimateHeight from "react-animate-height"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"

interface Props {
  amenities: Amenity[]
  isMobile: boolean
}

const AmenitiesDescription: React.FC<Props> = ({ amenities, isMobile }) => {
  const [viewMore, setViewMore] = React.useState(false)
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)
  let height: string | number = "auto"
  if (isMobile && !viewMore) {
    height = 240
  }

  const handleOnClick = () => {
    setViewMore(!viewMore)
  }

  return (
    <div className={styles.root(theme)}>
      <h3 className={styles.title}>{t("AmenitiesDescription.title")}</h3>
      {isMobile ? (
        <AnimateHeight duration={300} height={height}>
          <ul className={styles.list}>
            {amenities.map(e => (
              <li key={`amentiy-${e.id}`}>{e.name}</li>
            ))}
          </ul>
        </AnimateHeight>
      ) : (
        <ul className={styles.list}>
          {amenities.map(e => (
            <li key={`amentiy-${e.id}`}>{e.name}</li>
          ))}
        </ul>
      )}
      {isMobile && (
        <Button onClick={handleOnClick} className={styles.button(theme)}>
          {t("AmenitiesDescription.viewMore", { context: `${!viewMore}` })}
        </Button>
      )}
    </div>
  )
}

export default AmenitiesDescription
