import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto"
  }),
  previewBg: css({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "all .3s ease"
  }),
  preview: (backgroundImg: string) =>
    css({
      height: "120px",
      display: "flex",
      position: "relative",
      flexDirection: "column",
      justifyContent: "center",
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      alignItems: "center",
      backgroundPosition: "center",
      "> .MuiIconButton-root": {
        visibility: "hidden"
      },
      ":hover": {
        "> div": {
          backgroundColor: "rgba(255, 255, 255, 0.30)"
        },
        "> .MuiIconButton-root": {
          visibility: "visible",
          transition: "all .3s ease",
          backgroundColor: colors.WHITE
        }
      }
    }),
  nameContainer: css({
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    position: "relative",
    marginTop: "8px",
    fontWeight: 300,
    fontSize: "14px",
    color: colors.LIGHTER_GREY
  }),
  name: css({
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    minWidth: 0,
    maxWidth: "100px",
    textAlign: "center"
  })
}
