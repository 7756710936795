import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (isSingleCategory: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      minWidth: isSingleCategory ? "500px" : "280px",
      borderWidth: "0px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#BBBBBB",
      borderRadius: "8px"
    }),

  title: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px 32px",
      color: t.colors.background_primary,
      background: t.colors.brand_primary,
      borderWidth: "1px 1px 0px 1px",
      borderStyle: "solid",
      borderColor: t.colors.brand_primary,
      borderRadius: "8px 8px 0px 0px"
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px",
    height: "100%"
  }),

  item: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    padding: "4px 0",
    borderBottom: "1px solid #EAEAEA"
  }),

  buttonContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer"
  }),

  buttonIcon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      width: "16px !important"
    }),

  buttonText: (t: Theme) =>
    css({
      color: `${t.colors.brand_primary} !important`
    })
}
