import { css, keyframes } from "@emotion/css"

const animation = keyframes({
  "0%": { backgroundPosition: "-468px 0" },
  "100%": { backgroundPosition: "468px 0" }
})

export default {
  container: (baseColor: string, effectColor: string, hasChildren: boolean) => {
    return hasChildren
      ? css({
          "& > *": {
            animation: `${animation} 1.2s infinite linear forwards`,
            backgroundColor: effectColor,
            background: "linear-gradient(to right," + baseColor + " 8%," + effectColor + " 18%," + baseColor + " 33%)",
            backgroundSize: "800px 104px"
          }
        })
      : css({
          "& ": {
            animation: `${animation} 1.2s infinite linear forwards`,
            backgroundColor: effectColor,
            background: "linear-gradient(to right," + baseColor + " 8%," + effectColor + " 18%," + baseColor + " 33%)",
            backgroundSize: "800px 104px"
          }
        })
  }
}
