import React from "react"
import styles from "./LastPlaces.styles"
import { MIN_LAST_PLACES } from "../../utils/constants/constants"
import { FireIcon } from "../../icons/FireIcon"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Tooltip from "@material-ui/core/Tooltip"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  variant: "mini" | "full" | "tooltip"
  places: number
}

const LastPlaces: React.FC<Props> = ({ variant, places }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  if (!places) {
    return <></>
  }

  if (places >= MIN_LAST_PLACES) {
    return <></>
  }

  const getPlacesIconText = (places: number): string | undefined => {
    if (places) {
      return `${places}`
    }

    return
  }

  const getPlacesText = (places: number): string => {
    if (places === 1) {
      return t("LastPlaces.text")
    }

    return t("LastPlaces.text_plural", { count: places })
  }

  const component = (variant: "mini" | "full") => {
    return (
      <div className={styles.root(variant)}>
        <FireIcon />
        <span>{variant === "mini" ? getPlacesIconText(places) : getPlacesText(places)}</span>
      </div>
    )
  }

  if (variant === "mini" || variant === "full") return component(variant)

  return (
    <Tooltip
      className={styles.rootTooltip}
      classes={{
        tooltip: styles.tooltip(theme),
        popper: styles.popper(theme)
      }}
      title={<React.Fragment>{component("full")}</React.Fragment>}
    >
      {component("mini")}
    </Tooltip>
  )
}

export default LastPlaces
