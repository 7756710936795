import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      backgroundColor: t.colors.background_primary
    }),
  detail: (t: Theme) =>
    css({
      borderTop: `solid 1px ${t.colors.background_darker}`,
      display: "flex",
      flex: "1"
    })
}
