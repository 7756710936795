import React from "react"
import themedStyles from "./Voucher.styles"
import styles from "./VoucherHeader.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  /**
   * Logo Image URL
   * @param logo
   * @value string
   * @required true
   */
  logo: string
  /**
   * basset Reservation ID
   * @param reservationId
   * @value string
   * @required true
   */
  reservationId: string
  /**
   * Airline Record Locator used to web check in
   * @param recordLocator
   * @value string
   * @required true
   */
  recordLocator: string

  airlineRecordLocator?: string
}

const VoucherHeader: React.FC<Props> = ({ logo, reservationId: orderNumber, recordLocator, airlineRecordLocator }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.header(theme)}>
      <div className={styles.headerLogo}>
        <img src={logo} alt="" />
      </div>
      <div className={styles.order}>
        <span>{t("VoucherHeader.orderNumber")} </span>
        <span className={styles.number(theme)}>{orderNumber}</span>
      </div>
      <div className={styles.checkInHeader}>
        <div className={styles.checkIn(theme)}>
          <span>{t("VoucherHeader.webCheckIn")} </span>
          <span className={themedStyles.highlighted(theme)}>{recordLocator}</span>
        </div>

        {airlineRecordLocator && (
          <div className={styles.checkIn(theme)}>
            <span>{t("VoucherHeader.airlineCheckIn")} </span>
            <span className={themedStyles.highlighted(theme)}>{airlineRecordLocator}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default VoucherHeader
