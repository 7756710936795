import React from "react"
import { I18N_NS } from "../../utils/constants/constants"
import { useTheme } from "@basset-la/themed-components"
import styles from "./BaggagesInfo.styles"
import { BagIcon } from "../../icons/BagIcon"
import { BagIconNotFounded } from "../../icons/BagIconNotFounded"
import { CarryOnIcon } from "../../icons/CarryOnIcon"
import { CarryOnIconNotFounded } from "../../icons/CarryOnIconNotFounded"
import { BaggageIcon } from "../../icons/BaggageIcon"
import { BaggageIconNotFounded } from "../../icons/BaggageIconNotFounded"
import { useTranslation } from "react-i18next"

export interface Props {
  bagIncluded?: number
  carryOnIncluded?: number
  baggageAmount?: number
  /** if `baggageAmount` > 0 `baggageWeight` is mandatory */
  baggageWeight?: number
}

const BaggagesInfo: React.FunctionComponent<Props> = ({
  bagIncluded,
  carryOnIncluded,
  baggageAmount,
  baggageWeight
}) => {
  const theme = useTheme()

  const { t } = useTranslation(I18N_NS)

  if (baggageAmount && baggageAmount > 0 && baggageWeight === undefined) {
    throw new Error("if baggageAmount is greater than 0 baggageWeight is mandatory")
  }

  return (
    <div className={styles.root(theme)}>
      {(bagIncluded !== undefined || carryOnIncluded !== undefined || baggageAmount !== undefined) && (
        <p className={styles.title(theme)}>{t("BaggagesTooltipView.title")}</p>
      )}
      <div className={styles.rowsContainer}>
        {bagIncluded !== undefined && (
          <div className={styles.row(theme)}>
            <div>
              <BagIcon color={bagIncluded > 0 ? theme.colors.brand_primary : theme.colors.text_variant} />
            </div>
            <div>
              <p className={bagIncluded ? styles.highlight(theme) : undefined}>
                {bagIncluded > 0 ? t("BaggagesTooltipView.bagText") : t("BaggagesTooltipView.noBagText")}
              </p>
            </div>
          </div>
        )}

        {carryOnIncluded !== undefined && (
          <div className={styles.row(theme)}>
            <div>
              <CarryOnIcon color={carryOnIncluded > 0 ? theme.colors.brand_primary : theme.colors.text_variant} />
            </div>
            <div>
              <p className={carryOnIncluded ? styles.highlight(theme) : undefined}>
                {carryOnIncluded > 0 ? t("BaggagesTooltipView.carryOnText") : t("BaggagesTooltipView.noCarryOnText")}
              </p>
            </div>
          </div>
        )}

        {baggageAmount !== undefined && (
          <div className={styles.row(theme)}>
            <div>
              <BaggageIcon color={baggageAmount > 0 ? theme.colors.brand_primary : theme.colors.text_variant} />
            </div>
            <div>
              <p className={baggageAmount ? styles.highlight(theme) : undefined}>
                {baggageAmount > 0 ? t("BaggagesTooltipView.baggagesText") : t("BaggagesTooltipView.noBaggagesText")}
              </p>
              {baggageAmount > 0 && (
                <p>
                  {t("BaggagesTooltipView.baggagesDescriptionCount", {
                    count: baggageAmount,
                    weight: baggageWeight || 23
                  })}
                </p>
              )}
              {baggageAmount === 0 && (
                <p className={styles.disclamer(theme)}>
                  {t("BaggagesTooltipView.noBaggagesDisclamer", { count: baggageAmount })}
                </p>
              )}
            </div>
          </div>
        )}
      </div>

      {(bagIncluded === undefined || carryOnIncluded === undefined || baggageAmount === undefined) && (
        <p className={styles.title(theme)}>{t("BaggagesTooltipView.noBaggageFound")}</p>
      )}

      <div className={styles.rowsContainer}>
        {bagIncluded === undefined && (
          <div className={styles.row(theme)}>
            <div>
              <BagIconNotFounded color={theme.colors.text_variant} />
            </div>
            <div>
              <p>{t("BaggagesTooltipView.bagText")}</p>
            </div>
          </div>
        )}

        {carryOnIncluded === undefined && (
          <div className={styles.row(theme)}>
            <div>
              <CarryOnIconNotFounded color={theme.colors.text_variant} />
            </div>
            <div>
              <p>{t("BaggagesTooltipView.carryOnText")}</p>
            </div>
          </div>
        )}

        {baggageAmount === undefined && (
          <div className={styles.row(theme)}>
            <div>
              <BaggageIconNotFounded color={theme.colors.text_variant} />
            </div>
            <div>
              <p>{t("BaggagesTooltipView.baggagesText")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default BaggagesInfo
