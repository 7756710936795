import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  menu: css({
    ".MuiPaper-root": {
      top: "0 !important",
      right: "0 !important",
      left: "unset !important",
      transitionDuration: "20ms !important",
      maxWidth: "172px"
    }
  }),
  icons: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  }),
  closeIcon: (t: Theme) =>
    css({
      color: t.colors.text_primary
    }),
  item: css({
    "&.MuiMenuItem-root:hover": {
      backgroundColor: "#00000010"
    },
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: "#ffffff"
    },
    "&.MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "#00000010"
    }
  })
}

export default styles
