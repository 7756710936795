import React, { useState } from "react"
import { Cluster as ClusterModel } from "../../model/types"
import Cluster from "../Cluster/Cluster"
import styles from "./FlightDetail.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import { ArrowRightIcon } from "../../icons/ArrowRightIcon"
import AnimateHeight from "react-animate-height"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { I18N_NS } from "../../utils/constants/constants"
import { useTranslation } from "react-i18next"

export interface Props {
  cluster: ClusterModel
  origin: string
  destination: string
  pnr?: string
  unfoldSegment?: boolean
  selectedBrand?: number
}

const FlightDetail: React.FC<Props> = ({ cluster, origin, destination, pnr, unfoldSegment, selectedBrand }) => {
  const [open, setOpen] = useState<boolean>(unfoldSegment ? true : false)
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root}>
      <div className={styles.header} onClick={() => !unfoldSegment && setOpen(!open)}>
        <Text variant="light" size={24}>
          {t(`FlightType.${cluster.flight_type}`)}
        </Text>

        {pnr && (
          <div className={styles.pnr}>
            <Text variant="light" size={24}>
              {t("PassengerNameRecord")}
            </Text>

            <Text variant="bold" size={24} className={styles.providerID}>
              {pnr}
            </Text>
          </div>
        )}

        <div className={styles.headerComponent}>
          <Text variant="bold" size={16} className={styles.text}>
            {origin}
          </Text>

          <ArrowRightIcon className={styles.ArrowIcon} />

          <Text variant="bold" size={16} className={styles.text}>
            {destination}
          </Text>

          {!unfoldSegment &&
            (open ? (
              <KeyboardArrowUp className={styles.showIcon} />
            ) : (
              <KeyboardArrowDown className={styles.showIcon} />
            ))}
        </div>
      </div>

      <AnimateHeight duration={300} height={open ? "auto" : 0}>
        <Cluster
          variant="B2C"
          cluster={cluster}
          mini={true}
          unfoldSegment={unfoldSegment}
          selectedBrand={selectedBrand}
        />
      </AnimateHeight>
    </div>
  )
}

export default FlightDetail
