import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ffffff",
    alignItems: "start",
    width: "100%",
    justifyContent: "space-between"
  }),
  messageContainer: css({
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    flex: 1
  }),
  baggageMessage: (color: string) =>
    css({
      marginLeft: "8px",
      color: color
    }),
  segmentsContainers: css({
    display: "flex",
    flexDirection: "column",
    flex: 2
  })
}
