import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const GenericServiceAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.39023 9.66334H7.43023C7.86356 9.66334 8.27023 9.45667 8.5169 9.10334L11.1502 5.39C11.3169 5.15667 11.3169 4.85 11.1502 4.61667L8.51023 0.89667C8.27023 0.543337 7.86356 0.33667 7.43023 0.33667H1.39023C0.850231 0.33667 0.536898 0.95667 0.850231 1.39667L3.43023 5.00334L0.850231 8.61C0.536898 9.05 0.850231 9.66334 1.39023 9.66334Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
