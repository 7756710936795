import React from "react"

export const FreeDinnerIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
    <g fill="#FFF" fillRule="evenodd">
      <path d="M5.55 8.553L4.08 9.982l-2.736 2.66-1.214 1.18a.278.278 0 0 0-.054.073.9.9 0 0 0-.072.468c.023.266.161.61.522.945a.269.269 0 0 0 .117.063c.014.042.037.08.072.113.358.337.71.452.977.456a.898.898 0 0 0 .462-.103.277.277 0 0 0 .07-.059l.299-.352c.733-.865 2.572-3.047 4.298-5.08l-.33-.455c-2.032 2.394-4.3 5.082-4.642 5.485a.47.47 0 0 1-.196.015c-.129-.015-.319-.076-.566-.306a.27.27 0 0 0-.117-.063.272.272 0 0 0-.072-.113c-.279-.264-.337-.465-.35-.595a.45.45 0 0 1 .008-.146c.388-.376 3.042-2.952 5.33-5.179l-.337-.436zm2.055-1.239l1.444-1.408a.273.273 0 0 0 .04-.34 2.532 2.532 0 0 1-.353-.982l.434-.439 2.096 1.959-.41.462a2.63 2.63 0 0 1-1.003-.285.273.273 0 0 0-.336.063c-.188.22-.737.865-1.46 1.717l.367.41c.646-.76 1.101-1.297 1.372-1.614a3.203 3.203 0 0 0 1.18.26.274.274 0 0 0 .203-.091l3.738-4.228a.273.273 0 1 0-.409-.361l-2.88 3.258-.839-.783 2.973-3.182a.273.273 0 1 0-.398-.372L10.39 4.54l-.838-.783L12.608.662a.273.273 0 1 0-.388-.383L8.256 4.295a.274.274 0 0 0-.078.207 3.19 3.19 0 0 0 .34 1.16l-1.232 1.2.319.452z" />
      <path d="M12.58 14.84c-.062.093-.16.195-.27.284-.148.12-.324.213-.459.25a.44.44 0 0 1-.156.02.135.135 0 0 1-.031-.006 760.273 760.273 0 0 1-3.334-4.743L6.938 8.651l-.569-.817a.27.27 0 0 0-.167-.112 4.842 4.842 0 0 1-1.064-.414 6.432 6.432 0 0 1-2.077-1.702C2.3 4.653 1.64 3.293 1.434 1.335c.52.673 1.38 1.774 2.447 3.107 2.38 2.973 5.79 7.09 8.768 10.144.01.015.013.022.015.044a.41.41 0 0 1-.084.21zm.475-.619c-2.956-3.03-6.37-7.149-8.748-10.12A233.114 233.114 0 0 1 1.535.57L1.319.29a.273.273 0 0 0-.49.173c.077 2.548.86 4.304 1.806 5.484A6.97 6.97 0 0 0 4.92 7.809c.492.249.88.37 1.06.42.452.65 3.535 5.081 5.266 7.512.011.016.024.03.038.043a.612.612 0 0 0 .357.153c.184.017.36-.028.528-.098.17-.07.333-.17.481-.289.197-.16.369-.347.474-.566a.825.825 0 0 0 .085-.361c0-.13-.046-.27-.136-.381l-.018-.02z" />
    </g>
  </svg>
)
