import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      flex: 1,
      fontFamily: "Roboto",
      backgroundColor: t.colors.background_primary
    }),
  baggageTitleContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px 16px 16px 16px"
  }),
  segmentOptionDetail: (t: Theme) =>
    css({
      padding: "8px 8px",
      borderTop: `solid ${t.colors.background_darker} 1px`
    }),
  segmentOption: (t: Theme) =>
    css({
      paddingBottom: "8px",
      marginBottom: "8px",
      borderBottom: `solid 1px ${t.colors.background_darker}`
    }),
  departureArrivalText: (t: Theme) =>
    css({
      margin: 0,
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      color: t.colors.text_primary
    }),
  brandedFareText: css({
    margin: "0 16px",
    padding: "8px 0",
    borderBottom: "1px solid #EAEAEA"
  })
}
