import React, { useState } from "react"
import StarsFilter, { StarsFilterOption } from "../StarsFilter"
import CategoryFilter, { CategoryFilterOption } from "../CategoryFilter"
import AmenitiesFilter, { AmenitiesFilterOption } from "../AmenitiesFilter"
import MealPlanFilter, { MealPlanFilterOption } from "../MealPlanFilter"
import PriceFilter from "../PriceFilter"
import NameFilter from "../NameFilter"
import styles from "./AccommodationFilters.styles"
import { AppliedFilters, AvailableFilters } from "./types"
import { useTheme } from "@basset-la/themed-components"
import ActiveFilters from "./ActiveFilters"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import RegionFilter, { RegionFilterOption } from "../RegionFilter"
import ProvidersFilter, { ProvidersFilterOption } from "../ProvidersFilter"
import { Option } from "../AmenitiesFilter/AmenitiesFilter"
import { Option as OptionRegion } from "../RegionFilter/RegionFilter"

export interface Props {
  availableFilters: AvailableFilters
  appliedFilters: AppliedFilters
  currencyCode: string
  isMobile: boolean
  openDialog: boolean
  onCloseDialog: () => void
  onFilterChange: (key: string, value: string[]) => void
}

enum Keys {
  Stars = "stars",
  Regions = "regions",
  Category = "category",
  Amenities = "amenities",
  MealPlan = "meal_plan",
  Providers = "providers"
}

const Filters: React.FC<Props> = ({
  availableFilters,
  appliedFilters,
  currencyCode,
  isMobile,
  openDialog,
  onCloseDialog,
  onFilterChange
}) => {
  const theme = useTheme()

  const { t } = useTranslation(I18N_NS)
  const [selectedAmenities, setSelectedAmenities] = useState<AmenitiesFilterOption[]>([])
  const [selectedRegions, setSelectedRegions] = useState<RegionFilterOption[]>([])

  const [filters, setFilters] = useState({
    [Keys.Stars]: appliedFilters.stars ?? [],
    [Keys.Amenities]: appliedFilters.amenities ?? [],
    [Keys.Category]: appliedFilters.category ?? [],
    [Keys.MealPlan]: appliedFilters.meal_plan ?? [],
    [Keys.Regions]: appliedFilters.regions ?? [],
    [Keys.Providers]: appliedFilters.providers ?? []
  })

  const starsOptions: Array<StarsFilterOption> = availableFilters.stars.map(s => {
    return {
      stars: parseInt(s.value),
      selected: !!filters[Keys.Stars].find(as => as === s.value)
    }
  })

  const categoryOptions: Array<CategoryFilterOption> = availableFilters.category.map(c => {
    return {
      category: { id: c.value, name: c.label },
      selected: !!filters[Keys.Category].find(ac => ac === c.value)
    }
  })

  const amenitiesOptions: Array<AmenitiesFilterOption> = availableFilters.amenities.map(c => {
    return {
      ...c,
      selected: !!filters[Keys.Amenities].find(aa => aa === c.value)
    }
  })

  const mealPlanOptions: Array<MealPlanFilterOption> = availableFilters.meal_plan.map(c => {
    return {
      mealPlan: { id: c.value, name: t(`MealPlan.${c.label}`) },
      selected: !!filters[Keys.MealPlan].find(am => am === c.value)
    }
  })

  const providerOptions: Array<ProvidersFilterOption> =
    availableFilters?.providers?.map(c => {
      return {
        provider: { id: c.value, name: c.label },
        selected: !!filters[Keys.Providers].find(p => p === c.value)
      }
    }) ?? []

  const regionOptions: Array<RegionFilterOption> = availableFilters.regions.map(r => {
    return {
      region: { id: r.value, name: r.label },
      selected: !!filters[Keys.Regions].find(ar => ar === r.value)
    }
  })

  const minPrice = parseFloat(availableFilters.price.find(p => p.label === "MIN")?.value || "0")
  const maxPrice = parseFloat(availableFilters.price.find(p => p.label === "MAX")?.value || "0")

  const handleDone = (key: string) => () => {
    const appliedForKey = filters[key] as string[]
    onFilterChange(key, appliedForKey)
  }

  const handleFilterChange = (key: string) => (
    value?: number,
    checked?: boolean,
    newSelectedItems?: Option[],
    regionSelected?: OptionRegion[]
  ) => {
    const newFilters = {
      [Keys.Stars]: [...filters[Keys.Stars]],
      [Keys.Amenities]: [...filters[Keys.Amenities]],
      [Keys.Category]: [...filters[Keys.Category]],
      [Keys.MealPlan]: [...filters[Keys.MealPlan]],
      [Keys.Regions]: [...filters[Keys.Regions]],
      [Keys.Providers]: [...filters[Keys.Providers]]
    }

    if (regionSelected?.length) {
      regionSelected.forEach(r => {
        updateFilter(newFilters, key, r.region.id, !!r.selected)
      })
      return setFilters(newFilters)
    }

    if (newSelectedItems?.length) {
      newSelectedItems.forEach(i => {
        updateFilter(newFilters, key, i.value as number, !!i.selected)
      })
      return setFilters(newFilters)
    }

    if (value) {
      updateFilter(newFilters, key, value, !!checked)
      return setFilters(newFilters)
    }
    // Clean all the previously selected values for this filter
    newFilters[key] = []
    setFilters(newFilters)
  }

  const updateFilter = (newFilters: any, key: string, value: number | string, selected: boolean) => {
    if (selected && (filters[key] as string[]).indexOf(value.toString()) < 0) {
      ;(newFilters[key] as string[]).push(value.toString())
    } else if (!selected && (filters[key] as string).indexOf(value.toString()) >= 0) {
      const filtersArray = newFilters[key] as string[]
      filtersArray.splice(filtersArray.indexOf(value.toString()), 1)
    }
  }

  const handlePriceFilterChange = (value: [number, number]) => {
    onFilterChange(
      "price",
      value.map(v => v.toString())
    )
  }

  const handleNameFilterChange = (value: string) => {
    onFilterChange("name", [value])
  }

  const handleActiveFilterChange = (key: string, value: string[]) => {
    onFilterChange(key, value)
  }

  const handleCloseDialog = () => {
    onCloseDialog()
  }

  const handleSelectAmenities = (selectedItems: Option[]) => {
    setSelectedAmenities(selectedItems)
  }

  const handleSelectRegions = (selectedItems: OptionRegion[]) => {
    setSelectedRegions(selectedItems)
  }

  const handleOnClearAll = () => {
    setSelectedAmenities([])
  }

  const handleOnClearAllRegions = () => {
    setSelectedRegions([])
  }

  const handleOnSelectAllInSearchRegions = (newSelectedItem: OptionRegion[]) => {
    setSelectedRegions(newSelectedItem)
  }

  const component = (
    <div className={styles.root}>
      <div className={styles.activeFilterContainer(theme)}>
        <ActiveFilters
          availableFilters={availableFilters}
          appliedFilters={appliedFilters}
          currency={currencyCode}
          onActiveFilterChange={handleActiveFilterChange}
        />
      </div>
      {starsOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <StarsFilter
            collapsible={!isMobile}
            options={starsOptions}
            onChange={handleFilterChange(Keys.Stars)}
            onDone={handleDone(Keys.Stars)}
          />
        </div>
      )}
      {regionOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <RegionFilter
            collapsible={!isMobile}
            options={regionOptions}
            onChange={handleFilterChange(Keys.Regions)}
            onDone={handleDone(Keys.Regions)}
            onChangeAutoComplete={handleSelectRegions}
            selectedItems={selectedRegions || []}
            onClearAllSelect={handleOnClearAllRegions}
            onSelectAllInSearch={handleOnSelectAllInSearchRegions}
            multiple
          />
        </div>
      )}
      {categoryOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <CategoryFilter
            collapsible={!isMobile}
            options={categoryOptions}
            onChange={handleFilterChange(Keys.Category)}
            onDone={handleDone(Keys.Category)}
          />
        </div>
      )}
      {amenitiesOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <AmenitiesFilter
            collapsible={!isMobile}
            options={amenitiesOptions}
            onChange={handleFilterChange(Keys.Amenities)}
            onDone={handleDone(Keys.Amenities)}
            onChangeAutoComplete={handleSelectAmenities}
            selectedItems={selectedAmenities || []}
            onClearAllSelect={handleOnClearAll}
            multiple
          />
        </div>
      )}
      {mealPlanOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <MealPlanFilter
            collapsible={!isMobile}
            options={mealPlanOptions}
            onChange={handleFilterChange(Keys.MealPlan)}
            onDone={handleDone(Keys.MealPlan)}
          />
        </div>
      )}
      {providerOptions.length > 0 && (
        <div className={styles.filterContainer(theme)}>
          <ProvidersFilter
            collapsible={!isMobile}
            options={providerOptions}
            onChange={handleFilterChange(Keys.Providers)}
            onDone={handleDone(Keys.Providers)}
          />
        </div>
      )}
      <div className={styles.filterContainer(theme)}>
        <PriceFilter
          collapsible={!isMobile}
          currency={currencyCode}
          min={minPrice}
          max={maxPrice}
          value={appliedFilters.price}
          onApply={handlePriceFilterChange}
        />
      </div>
      <div className={styles.filterContainer(theme)}>
        <NameFilter
          collapsible={!isMobile}
          value={appliedFilters.name && appliedFilters.name.length > 0 ? appliedFilters.name[0] : ""}
          onApply={handleNameFilterChange}
        />
      </div>
    </div>
  )

  if (isMobile) {
    return (
      <FullScreenDialog open={openDialog} onClose={handleCloseDialog}>
        {component}
      </FullScreenDialog>
    )
  }

  return component
}

export default Filters
