export enum DefinitionType {
  SLIDER = "SLIDER",
  MODULAR = "MODULAR",
  CARROUSEL = "CARROUSEL"
}

export enum Position {
  FIXED = "FIXED",
  TOP = "TOP",
  MIDDLE = "MIDDLE",
  BOTTOM = "BOTTOM"
}

export enum AdvertisingPage {
  HOME = "HOME",
  FLIGHTS_HOME = "FLIGHTS_HOME",
  FLIGHTS_RESULTS = "FLIGHTS_RESULTS",
  FLIGHTS_CHECKOUT = "FLIGHTS_CHECKOUT",
  ACCOMMODATIONS_HOME = "ACCOMODATIONS_HOME",
  ACCOMMODATIONS_RESULTS = "ACCOMODATIONS_RESULTS",
  ACCOMMODATIONS_CHECKOUT = "ACCOMODATIONS_CHECKOUT"
}

export interface BannerInfo {
  priority: number
  title: string
  image_url: string
  alt: string
  link: string
  date_from: string
  date_to: string
  position: Position
  page: AdvertisingPage
}

export interface BannerPositionInfo {
  type?: DefinitionType
  banners: BannerInfo[]
}
