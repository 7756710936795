import React from "react"
import { useDropzone, DropEvent } from "react-dropzone"
import styles from "./DropZone.styles"
import CloudUploadIcon from "../icons/CloudUploadIcon"
import { FileType } from "./model"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  maxFileSize: number
  maxFileCount: number
  acceptedFileTypes: FileType[]
  onDrop<T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent): void
  files: File[]
  onDeleteFile: (fileIndex: number) => void
}

const DropZone: React.FC<Props> = ({ maxFileSize, onDrop, acceptedFileTypes }) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
    maxSize: maxFileSize,
    accept: acceptedFileTypes
  })
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.root(theme)} {...getRootProps()}>
      <div>
        <input {...getInputProps()} />
        <div className={styles.content}>
          <CloudUploadIcon />
          <p>{t("DropFilesModal.drop_here")}</p>
        </div>
      </div>
    </div>
  )
}

export default DropZone
