import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: "24px",
      backgroundColor: t.colors.background_darker,
      borderRadius: "0px 0px 4px 4px",
      gap: "16px"
    })
}

export default styles
