import React from "react"

const CarsIcon = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3528 14.4623C33.4267 -4.6748 64.4027 -4.72381 83.5398 14.3528C102.674 33.4267 102.723 64.4027 83.6492 83.5397C64.5726 102.674 33.5967 102.723 14.4623 83.6492C-4.6748 64.5726 -4.72381 33.5967 14.3528 14.4623Z"
        fill="#00A5F4"
      />
      <mask id="mask0_807_12" type="alpha" maskUnits="userSpaceOnUse" x="10" y="11" width="79" height="75">
        <rect x="10.8064" y="11" width="77.9522" height="74.7741" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_807_12)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.0103 22.0322C70.1677 22.0322 71.9982 23.405 72.6519 25.3336L79.0915 43.8677C79.3203 44.5541 79.451 45.3059 79.451 46.0251V69.4296C79.451 72.1427 77.2282 74.3328 74.5478 74.3328C71.8347 74.3328 69.6447 72.11 69.6447 69.4296V67.7952H30.4192V69.4296C30.4192 72.1427 28.2291 74.3328 25.516 74.3328C22.8029 74.3328 20.6128 72.1427 20.6128 69.4296V46.0251C20.6128 45.2733 20.7435 44.5541 20.9724 43.8677L27.4119 25.3336C28.0983 23.405 29.8962 22.0322 32.0536 22.0322H68.0103ZM27.1499 53.0841C27.1499 55.7972 29.34 57.9873 32.0531 57.9873C34.7662 57.9873 36.9563 55.7972 36.9563 53.0841C36.9563 50.371 34.7662 48.1809 32.0531 48.1809C29.34 48.1809 27.1499 50.371 27.1499 53.0841ZM68.0106 57.9873C65.2975 57.9873 63.1074 55.7972 63.1074 53.0841C63.1074 50.371 65.2975 48.1809 68.0106 48.1809C70.7237 48.1809 72.9138 50.371 72.9138 53.0841C72.9138 55.7972 70.7237 57.9873 68.0106 57.9873ZM31.3013 29.1581L27.1499 41.6449H72.913L68.7616 29.1581C68.304 27.8506 67.0619 26.9353 65.6563 26.9353H34.4066C33.0011 26.9353 31.7589 27.8506 31.3013 29.1581Z"
          fill="#F9F9F9"
        />
      </g>
      <ellipse opacity="0.317922" cx="8.45519" cy="47.2705" rx="2.45519" ry="2.45161" fill="white" />
      <ellipse opacity="0.317922" cx="76.8" cy="18.9671" rx="2.45519" ry="2.45161" stroke="white" stroke-width="2" />
      <ellipse opacity="0.317922" cx="31.1101" cy="83.0323" rx="2.45519" ry="2.45161" stroke="white" stroke-width="2" />
      <g opacity="0.38">
        <path d="M34.9484 14.6426L30.831 12.5477" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M31.841 15.6504L33.9389 11.5389" stroke="white" stroke-width="2" stroke-linecap="round" />
      </g>
      <g opacity="0.35">
        <path d="M87.5789 52.549L90.7395 49.9009" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M87.8309 49.6456L90.4829 52.8016" stroke="white" stroke-width="2" stroke-linecap="round" />
      </g>
      <ellipse opacity="0.149778" cx="63.4516" cy="76.2737" rx="2.14829" ry="2.14516" fill="white" />
      <ellipse opacity="0.26" cx="72.9659" cy="85.7742" rx="1.22759" ry="1.2258" fill="white" />
      <ellipse opacity="0.26" cx="12.138" cy="34.4003" rx="1.22759" ry="1.2258" fill="white" />
    </svg>
  )
}

export default CarsIcon
