import { useTheme } from "@basset-la/themed-components"
import { Moment } from "moment"
import React, { SyntheticEvent } from "react"
import styles from "./HotelBookingSummary.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Divider from "@material-ui/core/Divider"
import { MealPlan as MealPlanType } from "../../model"
import MealPlan from "../SimpleAccommodationInfo/MealPlan"

import noImage from "../../images/accommodation-image.svg"
import Stars from "../Stars"
import Address from "../Address"

export interface Props {
  destination: string
  hotelName: string
  hotelImage: string
  adults: number
  childrenAges: Record<Age, number>
  checkin: Moment
  checkout: Moment
  roomsCount: number
  roomName: string
  provider?: string
  checkinTime: string
  checkoutTime: string
  mealPlan?: MealPlanType
  stars?: number
  refundable?: boolean
}

type Age = number

const HotelBookingSummary: React.FC<Props> = ({
  destination,
  hotelName,
  hotelImage,
  adults,
  childrenAges,
  checkin,
  checkout,
  roomName,
  roomsCount,
  provider,
  checkinTime,
  checkoutTime,
  mealPlan,
  stars,
  refundable
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const nightsCount = checkout.diff(checkin, "days")

  const tCheckin = checkinTime ? `(${t("HotelBookingSummary.from")} ${checkinTime})` : ""

  const tCheckout = checkoutTime ? `(${t("HotelBookingSummary.to")} ${checkoutTime})` : ""

  const tRoomTitle = roomsCount > 1 ? t("HotelBookingSummary.roomTitlePlural") : t("HotelBookingSummary.roomTitle")

  const showMealPlan = mealPlan && mealPlan !== "ROOM_ONLY"

  const handleImageError = (e: SyntheticEvent) => {
    const target = e.target as HTMLImageElement

    target.onerror = null

    target.src = noImage
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.destinationContainer}>
        <Text className={styles.destination} variant="bold" component="span" size={16}>
          {t("HotelBookingSummary.hotelIn", { destination: destination })}
        </Text>

        {provider && (
          <Text variant="regular" component="span" size={16}>
            ({provider})
          </Text>
        )}
      </div>
      <div className={styles.hotelDetail}>
        <div className={`${styles.hotelImg} ${styles.hotelImgContainer}`}>
          <img onError={handleImageError} src={hotelImage || noImage} alt={hotelName} />
        </div>
        <div className={styles.hotelNameContainer}>
          <Text variant={"regular"} size={18} className={styles.hotelName}>
            {hotelName}
          </Text>
          {stars && <Stars amount={stars} />}
          <Address address={destination} color={theme.colors.brand_primary} />
          {showMealPlan && (
            <div className={styles.mealPlan}>
              <MealPlan mealPlan={mealPlan!} />
            </div>
          )}
          {refundable && <p className={styles.refundable(theme)}>{t("HotelBookingSummary.freeCancel")}</p>}
        </div>
      </div>

      <Divider />

      <div className={`${styles.row} ${styles.dashBorder}`}>
        <div className={styles.passengersTitle}>
          <div className={styles.titleContainer}>
            <Text className={styles.rowTitle} variant="light" size={14}>
              {t("HotelBookingSummary.passengers")}
            </Text>
          </div>
        </div>
        <div className={styles.passengersContainer}>
          <Text className={styles.passengerText} size={14}>
            {t("HotelBookingSummary.adult", { count: adults })}
          </Text>
          {Object.keys(childrenAges).map(k => (
            <Text key={k} size={14} className={styles.passengerText}>{`${t("HotelBookingSummary.children", {
              count: childrenAges[k]
            })} (${t("HotelBookingSummary.age", { count: parseInt(k) })})`}</Text>
          ))}
        </div>
      </div>

      <div className={`${styles.row} ${styles.dashBorder}`}>
        <div className={styles.titleContainer}>
          <Text className={styles.rowTitle} variant="light" size={14}>
            {t("HotelBookingSummary.stay")}
          </Text>
        </div>
        <Text size={14}>{`${t("HotelBookingSummary.night", { count: nightsCount })}, ${t("HotelBookingSummary.room", {
          count: roomsCount
        })}`}</Text>
      </div>

      <div className={`${styles.row} ${styles.dashBorder}`}>
        <div className={styles.titleContainer}>
          <Text className={styles.rowTitle} variant="light" size={14}>
            {tRoomTitle}
          </Text>
        </div>
        <Text className={styles.roomName} size={14}>{`${roomsCount} x ${roomName}`}</Text>
      </div>

      <div className={`${styles.row} ${styles.dashBorder}`}>
        <div className={styles.titleContainer}>
          <Text className={styles.rowTitle} variant="light" size={14}>
            {t("HotelBookingSummary.checkin")}
          </Text>
        </div>
        <Text size={14}>{`${checkin.format("ddd DD/MM/YYYY")} ${tCheckin}`}</Text>
      </div>

      <div className={styles.row}>
        <div className={styles.titleContainer}>
          <Text className={styles.rowTitle} variant="light" size={14}>
            {t("HotelBookingSummary.checkout")}
          </Text>
        </div>
        <Text size={14}>{`${checkout.format("ddd DD/MM/YYYY")} ${tCheckout}`}</Text>
      </div>
    </div>
  )
}

export default HotelBookingSummary
