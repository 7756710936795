export type AutocompleteItem = {
  id: string
  type: AutocompleteItemType
  name: string
  iata_code?: string
  prioritized?: boolean
}

export const OrderedAutocompleteItemType = [
  "favorite",
  "multi_city_vicinity",
  "AIRPORT",
  "airline",
  "CITY",
  "city",
  "high_level_region",
  "neighborhood",
  "accommodation",
  "google_place",
  "country"
]

export type AutocompleteItemType = typeof OrderedAutocompleteItemType[number]
