import { css } from "@emotion/css"

const styles = {
  container: css({
    width: "100%",
    height: "100%",
    padding: 16,
    background: "white",
    borderRadius: 4,
    border: "1px #EAEAEA solid",
    flexDirection: "column",
    gap: 10,
    display: "inline-flex"
  }),
  titleContainer: css({
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: 16,
    display: "flex"
  }),
  innerContainer: css({
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 8,
    display: "flex"
  }),
  textContainer: css({
    color: "#4A4A4A",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 500,
    letterSpacing: 0.13,
    wordWrap: "break-word"
  }),
  text: css({
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    color: "#126AFF",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 400,
    lineHeight: 2,
    wordWrap: "break-word",
    flexGrow: 1
  }),
  actionContainer: css({
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex"
  }),
  refresh: css({
    color: "#126aff",
    cursor: "pointer",
    margin: "0 8px"
  })
}

export default styles
