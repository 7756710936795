import React, { useState } from "react"
import styles from "./ToggleButton.styles"

import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"

type Value = {
  key: string
  label: string
}

export interface Props {
  onSelect: (v: Value) => void
  values: Value[]
}

const ToggleButton: React.FC<Props> = ({ onSelect, values }) => {
  const theme = useTheme()
  const [selected, setSelected] = useState<Value>(values[0])

  return (
    <ButtonGroup className={styles.group}>
      {values.map((v, i) => (
        <Button
          key={i}
          color="primary"
          disabled={selected.key === v.key}
          className={`${selected.key === v.key && styles.selected(theme)} ${styles.button(theme)}`}
          onClick={() => {
            setSelected(v)
            onSelect(v)
          }}
        >
          {v.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default ToggleButton
