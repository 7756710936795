import React from "react"
import styles from "./AccommodationDesktopPrice.styles"
import PersonIcon from "@material-ui/icons/PersonRounded"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { AccommodationFare } from "../../model"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { convertAccommodationFareToAgencyPriceBreakdown } from "../../utils/helpers"
import CurrencyJS from "currency.js"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"

type LoadingTrueProps = {
  loading: true
  nightsCount?: number
  guestsCount?: number
  roomsCount?: number
  accommodationFare?: AccommodationFare
}

type LoadingFalseProps = {
  loading?: false
  nightsCount: number
  guestsCount: number
  roomsCount: number
  accommodationFare: AccommodationFare
}

export type Props = LoadingTrueProps | LoadingFalseProps

const AccommodationDesktopPrice: React.FC<Props> = ({
  loading = false,
  accommodationFare,
  guestsCount,
  nightsCount,
  roomsCount
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  if (loading) {
    return (
      <div className={styles.root(theme)}>
        <div className={styles.breakdownRow}>
          <Skeleton variant="text" width="100%" />
        </div>
        <div className={styles.breakdownRow}>
          <Skeleton variant="text" width="100%" />
        </div>
        <div className={styles.total(theme)}>
          <Skeleton variant="text" width="100%" />
        </div>
      </div>
    )
  }

  const { currency } = accommodationFare!

  const priceBreakdown = convertAccommodationFareToAgencyPriceBreakdown(accommodationFare!, roomsCount!, guestsCount!)

  let totalTaxes = priceBreakdown.extraCost
  if (priceBreakdown.taxes.pais)
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(priceBreakdown.taxes.pais)).value
  if (priceBreakdown.taxes.rg4815)
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(priceBreakdown.taxes.rg4815)).value
  if (priceBreakdown.taxes.rg5272)
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(priceBreakdown.taxes.rg5272)).value
  if (priceBreakdown.charges.totalFeeCharges)
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(priceBreakdown.charges.totalFeeCharges)).value

  priceBreakdown.taxes.taxes?.forEach(t => {
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(t.amount)).value
  })

  return (
    <div className={styles.root(theme)}>
      <div className={styles.breakdownRow}>
        <span>
          {guestsCount} <PersonIcon /> x {t("AccommodationClusterDesktop.night_count", { count: nightsCount })}
        </span>
        <span id="accommodation-total-price">{formatPrice(priceBreakdown.baseRate, locale, currency)}</span>
      </div>
      <div className={styles.breakdownRow}>
        <span>{t("AccommodationClusterDesktop.taxes")}</span>
        <span>{formatPrice(totalTaxes, locale, currency)}</span>
      </div>
      {priceBreakdown.taxes.pais && (
        <div className={styles.breakdownRow}>
          <span>{t("AccommodationClusterDesktop.paisTax")}</span>
          <span>{formatPrice(priceBreakdown.taxes.pais, locale, currency)}</span>
        </div>
      )}
      {priceBreakdown.taxes.rg4815 && (
        <div className={styles.breakdownRow}>
          <span>{t("AccommodationClusterDesktop.rg4815Tax")}</span>
          <span>{formatPrice(priceBreakdown.taxes.rg4815, locale, currency)}</span>
        </div>
      )}
      {priceBreakdown.taxes.rg5272 && (
        <div className={styles.breakdownRow}>
          <span>{t("AccommodationClusterDesktop.rg5272Tax")}</span>
          <span>{formatPrice(priceBreakdown.taxes.rg5272, locale, currency)}</span>
        </div>
      )}

      {priceBreakdown.taxes.taxes?.map(t => (
        <div className={styles.breakdownRow}>
          <span>{t.type}</span>
          <span>{formatPrice(t.amount, locale, currency)}</span>
        </div>
      ))}

      {priceBreakdown.charges.totalFeeCharges && (
        <div className={styles.breakdownRow}>
          <span>{t("AccommodationClusterDesktop.fees")}</span>
          <span>{formatPrice(priceBreakdown.charges.totalFeeCharges, locale, currency)}</span>
        </div>
      )}

      <div className={styles.total(theme)}>
        <span>{t("AccommodationClusterDesktop.total")}</span>
        <span id={"accommodation-total-price"}>{formatPrice(priceBreakdown.total, locale, currency)}</span>
      </div>
      {priceBreakdown.discountTotal && (
        <div className={styles.discount(theme)}>
          <span>{formatPrice(priceBreakdown.discountTotal, locale, currency)}</span>
        </div>
      )}
    </div>
  )
}

export default AccommodationDesktopPrice
