import { css } from "@emotion/css"

export const AvatarStyle = (size: string) => {
  return {
    default: css({
      color: "#f7f9ff"
    }),
    size: css({
      margin: "10",
      width: `${size} !important`,
      height: `${size} !important`
    })
  }
}
