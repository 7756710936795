import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiRadio-colorSecondary, &.MuiRadio-colorSecondary.Mui-checked": {
        padding: 0,
        color: t.colors.brand_primary
      },
      "&.MuiRadio-colorSecondary.Mui-disabled": {
        padding: 0,
        color: t.colors.background_darker
      }
    }),
  control: css({
    "&.MuiFormControlLabel-root": {
      margin: 0
    },
    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 8px"
    }
  })
}
