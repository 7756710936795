import React from "react"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/DeleteRounded"
import styles from "./FilePreview.styles"
import { FileType } from "./model"

interface Props {
  name: string
  fileType: FileType
  onDelete: () => void
}

const FileTypeMap = {
  "application/pdf": "https://statics.basset.la/files/icon-pdf.png",
  "image/png": "https://statics.basset.la/files/icon-picture.png"
}

const FilePreview: React.FC<Props> = ({ name, fileType, onDelete }) => {
  return (
    <div className={styles.root}>
      <div className={styles.preview(FileTypeMap[fileType])}>
        <div className={styles.previewBg} />
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
      <div datatype={fileType} className={styles.nameContainer}>
        <p className={styles.name}>{name}</p>
      </div>
    </div>
  )
}

export default FilePreview
