import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  pois: (theme: Theme) =>
    css({
      marginTop: "8px",
      " > p": {
        fontSize: "12px",
        fontWeight: 300,
        lineHeight: "1.33",
        color: theme.colors.text_primary,
        " > span": {
          color: theme.colors.text_variant
        }
      }
    }),
  sectionTitle: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      " > p": {
        fontSize: "16px",
        lineHeight: "1.5",
        color: theme.colors.text_primary,
        fontWeight: 300,
        marginLeft: "8px"
      }
    })
}
