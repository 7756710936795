import IconButton from "@basset-la/component-material-ui-wrapper/dist/IconButton"
import Radio from "@basset-la/component-material-ui-wrapper/dist/Radio"
import { useTheme } from "@basset-la/themed-components"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import React from "react"
import { SegmentOption, Price } from "../../model/types"
import SegmentOptionSummary from "../SegmentOptionSummary/SegmentOptionSummary"
import styles from "./SegmentOptionRow.styles"
import DesktopSegmentOptionSummary from "../DesktopSegmentOptionSummary/DesktopSegmentOptionSummary"

interface Props {
  segmentOption: SegmentOption
  onClick?: React.MouseEventHandler<HTMLDivElement>
  price: Price

  /**
   * set to `true` to force mobile L&F
   * @default false
   */
  mini?: boolean
  /**
   * Set `"selectable"` to render radio button on component.
   * If you set `type` to `"selectable"`, `OnSelect` and `selected` params are mandatory
   * @default "detail"
   */
  type?: "detail" | "selectable"
  variant?: "B2C" | "B2B"
  selected?: boolean
  onSelect?: React.MouseEventHandler<HTMLButtonElement>
  highlightDuration?: boolean
  open?: boolean
}

const SegmentOptionRow: React.FunctionComponent<Props> = ({
  segmentOption,
  price,
  onClick,
  // optionals
  highlightDuration = false,
  open = false,
  type = "detail",
  variant = "B2C",
  mini = false,
  onSelect,
  selected = false
}) => {
  const theme = useTheme()

  const selectable = type === "selectable"

  if (selectable && (!onSelect || selected === undefined)) {
    throw new Error("onSelect and selected props are required for type selectable")
  }
  return (
    <div className={styles.root(theme, mini)}>
      {selectable && (
        <Radio
          onClick={onSelect}
          className={styles.radio}
          checked={selected}
          backgroundColor={theme.colors.brand_primary}
        />
      )}
      <div className={styles.content(mini)} onClick={onClick}>
        <div className={styles.mainContent}>
          {variant === "B2C" && (
            <SegmentOptionSummary
              highlightDuration={highlightDuration}
              mini={mini}
              segmentOption={segmentOption}
              price={price}
            />
          )}
          {variant === "B2B" && <DesktopSegmentOptionSummary price={price} segmentOption={segmentOption} />}
        </div>
        <div className={styles.arrowRootContainer(mini)}>
          <div className={styles.arrowContainer}>
            <IconButton
              className={styles.arrowButton}
              borderColor={"transparent"}
              backgroundColor={theme.colors.brand_primary}
            >
              <KeyboardArrowDown
                className={`${styles.arrow(theme)} ${open ? styles.arrowOpened : styles.arrowClosed}`}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SegmentOptionRow
