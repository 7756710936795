import * as React from "react"
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip"
import { css } from "@emotion/css"

export interface Props extends TooltipProps {}

const TooltipComponent = ({ ...rest }: Props) => {
  return <Tooltip classes={{ popper: popperStyle, tooltip: tooltipStyle }} {...rest} />
}

const popperStyle = css({
  fontSize: "12px !important",
  margin: "4px 8px !important",
  borderRadius: "2px",
  backgroundColor: `#4a4a4a !important`,
  boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24)"
})

const tooltipStyle = css({
  fontSize: "12px !important",
  margin: "0px 8px !important",
  borderRadius: "2px",
  fontFamily: "Roboto",
  fontWeight: 500,
  backgroundColor: `#4a4a4a !important`
})

export default TooltipComponent
