import React from "react"
import styles from "./ActiveFilters.styles"
import { AppliedFilters, AvailableFilters } from "./types"
import { useTheme } from "@basset-la/themed-components"
import Chip from "@material-ui/core/Chip"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Stars from "../Stars"

export interface Props {
  availableFilters: AvailableFilters
  appliedFilters: AppliedFilters
  currency: string
  onActiveFilterChange: (key: string, value: string[]) => void
}

const ActiveFilters: React.FC<Props> = ({ availableFilters, appliedFilters, currency, onActiveFilterChange }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleRemoveFilter = (key: string, value: string) => {
    switch (key) {
      case "stars":
        onActiveFilterChange(
          key,
          appliedFilters.stars!.filter(s => s !== value)
        )
        break
      case "category":
        onActiveFilterChange(
          key,
          appliedFilters.category!.filter(c => c !== value)
        )
        break
      case "amenities":
        onActiveFilterChange(
          key,
          appliedFilters.amenities!.filter(a => a !== value)
        )
        break
      case "price":
        onActiveFilterChange(key, [])
        break
      case "meal_plan":
        onActiveFilterChange(
          key,
          appliedFilters.meal_plan!.filter(m => m !== value)
        )
        break
      case "providers":
        onActiveFilterChange(
          key,
          appliedFilters.providers!.filter(r => r !== value)
        )
        break
      case "regions":
        onActiveFilterChange(
          key,
          appliedFilters.regions!.filter(r => r !== value)
        )
        break
      case "name":
        onActiveFilterChange(key, [])
        break
    }
  }

  const EllipsisText = (props: { children: any }) => {
    const { children } = props

    return (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {children}
      </div>
    )
  }

  const chip = (key: string, value: any, i: number, content: any) => {
    return (
      <Chip
        id={`active-filters-chip-${key}`}
        key={key + i}
        label={<EllipsisText>{content}</EllipsisText>}
        onDelete={() => handleRemoveFilter(key, value)}
        classes={{
          outlined: styles.chip(theme),
          deleteIcon: `${styles.iconChip(theme)} deleteIcon`,
          label: styles.labelChip(theme)
        }}
        variant="outlined"
      />
    )
  }

  return (
    <div className={styles.root}>
      <div>{t("ActiveFilters.title")}</div>
      <div className={styles.container}>
        {appliedFilters.stars &&
          appliedFilters.stars.map((s, i) => chip("stars", s, i, <Stars amount={parseInt(s)} />))}
        {appliedFilters.category &&
          appliedFilters.category
            .filter(c => !!availableFilters.category.find(cat => cat.value === c))
            .map((c, i) => chip("category", c, i, availableFilters.category.find(cat => cat.value === c)!.label))}
        {appliedFilters.amenities &&
          appliedFilters.amenities
            .filter(a => !!availableFilters.amenities.find(am => am.value === a))
            .map((a, i) => chip("amenities", a, i, availableFilters.amenities.find(am => am.value === a)!.label))}
        {appliedFilters.price &&
          chip(
            "price",
            appliedFilters.price,
            0,
            `${currency} ${appliedFilters.price[0]} - ${currency} ${appliedFilters.price[1]}`
          )}
        {appliedFilters.meal_plan &&
          appliedFilters.meal_plan
            .filter(c => !!availableFilters.meal_plan.find(mea => mea.value === c))
            .map((c, i) =>
              chip("meal_plan", c, i, t(`MealPlan.${availableFilters.meal_plan.find(mea => mea.value === c)!.label}`))
            )}
        {appliedFilters.regions &&
          appliedFilters.regions
            .filter(r => !!availableFilters.regions.find(reg => reg.value === r))
            .map((r, i) => chip("regions", r, i, availableFilters.regions.find(reg => reg.value === r)!.label))}

        {appliedFilters.providers &&
          availableFilters.providers &&
          appliedFilters.providers
            .filter(r => !!availableFilters.providers!.find(prov => prov.value === r))
            .map((r, i) => chip("providers", r, i, availableFilters.providers!.find(reg => reg.value === r)!.label))}

        {appliedFilters.name && chip("name", appliedFilters.name, 0, appliedFilters.name)}
      </div>
    </div>
  )
}

export default ActiveFilters
