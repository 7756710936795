import React from "react"
import { StarIcon } from "../../icons/StarIcon"
import styles from "./Stars.styles"

export interface Props {
  amount: number
}

const Stars: React.FunctionComponent<Props> = ({ amount }) => {
  return (
    <div id="stars-count" data-count={amount} className={styles.root}>
      {Array.apply(null, Array(Math.floor(amount))).map((e: unknown, i: number) => (
        <StarIcon id="stars" key={`stars_${i}`} />
      ))}
    </div>
  )
}

export default Stars
