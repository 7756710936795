import React from "react"
import styles from "./TimeSelect.styles"
import MenuItem from "@material-ui/core/MenuItem"
import Popover from "@material-ui/core/Popover"

export interface Props {
  isMobile: boolean
  open: boolean
  timeInterval: [string, string]
  anchor: React.MutableRefObject<HTMLDivElement | null>
  onChange: (v: string) => void
  onClose: () => void
}

const TimeSelect: React.FC<Props> = ({ isMobile, open, timeInterval, anchor, onChange, onClose }) => {
  const handleOptionClick = (value: string) => {
    onChange(value)
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  const OPTIONS: string[] = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30"
  ]

  let initial = OPTIONS.indexOf(timeInterval[0])
  initial = initial === -1 ? 0 : initial

  let final = OPTIONS.indexOf(timeInterval[1])
  final = final === -1 ? OPTIONS.length - 1 : final

  const options = OPTIONS.slice(initial, final + 1)

  const optionsComponent = (
    <>
      {options.map((opt, idx) => (
        <MenuItem
          key={idx}
          value={opt}
          onClick={() => {
            handleOptionClick(opt)
          }}
        >
          {opt}
        </MenuItem>
      ))}
    </>
  )

  if (isMobile) {
    return optionsComponent
  }

  return (
    <Popover
      className={styles.menu}
      open={open}
      onClose={handleClose}
      anchorEl={anchor.current}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      {optionsComponent}
    </Popover>
  )
}

export default TimeSelect
