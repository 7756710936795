import React from "react"
import styles from "./ClusterCombinedPriceWeb.styles"
import PriceboxWeb from "@basset-la/components-products/dist/components/PriceboxWeb"
import { useTranslation } from "react-i18next"
import { AccommodationFare, I18N_NS } from "../.."

export interface Props {
  isMobile: boolean
  accommodationFare: AccommodationFare
  taxesDisclaimers?: string[]
}

const ClusterCombinedPriceWeb: React.FC<Props> = ({ isMobile, accommodationFare, taxesDisclaimers }) => {
  const { t } = useTranslation(I18N_NS)

  const { currency, discount, total } = accommodationFare

  const strikethrough = discount
    ? {
        discount: discount.discount,
        total: discount.total
      }
    : undefined

  return (
    <div className={styles.root}>
      <PriceboxWeb
        isMobile={isMobile}
        currency={currency}
        total={{
          label: t("AccommodationClusterWeb.priceTitle"),
          value: total
        }}
        breakdown={[
          {
            label: t("ClusterCombinedPriceWeb.total"),
            value: total
          }
        ]}
        taxesDisclaimers={taxesDisclaimers}
        strikethrough={strikethrough}
      />
    </div>
  )
}

export default ClusterCombinedPriceWeb
