import React from "react"

const AccommodationsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="65" viewBox="0 0 58 65">
      <g fill="none" fillRule="nonzero">
        <path fill="#FFF" d="M3.789 33.331h50.112v30.624H3.789zM3.789 11.909h50.112v18.637H3.789z" />
        <path
          fill="#0085C2"
          d="M33.64 37.97h-9.59a2.08 2.08 0 0 0-2.087 2.089v18.637h13.842V40.059c-.077-1.16-1.005-2.088-2.165-2.088z"
        />
        <path
          fill="#E6E8E9"
          d="M33.64 39.595h-9.59c-.618 0-1.082.464-1.082 1.082v4.486c0 .618.464 1.082 1.083 1.082h9.589c.619 0 1.083-.464 1.083-1.082v-4.486c-.078-.618-.542-1.082-1.083-1.082zM34.104 48.875h-2.552a.529.529 0 0 1-.541-.542c0-.309.232-.541.541-.541h2.552c.31 0 .541.232.541.541 0 .232-.232.542-.541.542zM56.299 30.547H1.392c-.619 0-1.083.464-1.083 1.082v2.784c0 .619.464 1.083 1.083 1.083h54.907c.618 0 1.082-.464 1.082-1.083V31.63c-.077-.618-.541-1.082-1.082-1.082zM56.299 58.696H1.392c-.619 0-1.083.464-1.083 1.083v3.17c0 .619.464 1.083 1.083 1.083h54.907c.618 0 1.082-.464 1.082-1.083v-3.17c-.077-.619-.541-1.083-1.082-1.083z"
        />
        <path
          fill="#9B9B9B"
          d="M55.216.696H2.475A2.08 2.08 0 0 0 .387 2.784v7.037a2.08 2.08 0 0 0 2.088 2.088h52.741a2.08 2.08 0 0 0 2.088-2.088V2.784A2.08 2.08 0 0 0 55.216.696z"
        />
        <g>
          <path
            fill="#E6E8E9"
            d="M12.219 14.152a5.38 5.38 0 0 0-5.414 5.413v5.414h10.827v-5.414a5.38 5.38 0 0 0-5.413-5.413z"
          />
          <path
            fill="#FAFAFA"
            d="M12.683 14.152h-.928v4.95H6.883v.927h4.949v4.95h.928v-4.95h4.95v-.464-.464h-4.95v-4.949h-.077z"
          />
          <path fill="#9B9B9B" d="M5.877 24.901H18.56v1.469H5.877z" />
          <path
            fill="#E6E8E9"
            d="M28.845 14.152a5.38 5.38 0 0 0-5.413 5.413v5.414h10.827v-5.414c0-3.016-2.475-5.413-5.414-5.413z"
          />
          <path
            fill="#FAFAFA"
            d="M29.31 14.152h-.929v4.95h-4.949v.927h4.95v4.95h.927v-4.95h4.95v-.464-.464h-4.95v-4.949z"
          />
          <path fill="#9B9B9B" d="M22.504 24.901h12.683v1.469H22.504z" />
          <path
            fill="#E6E8E9"
            d="M45.472 14.152a5.38 5.38 0 0 0-5.413 5.413v5.414h10.826v-5.414c0-3.016-2.474-5.413-5.413-5.413z"
          />
          <path
            fill="#FAFAFA"
            d="M45.936 14.152h-.928v4.95h-4.95v.927h4.95v4.95h.928v-4.95h4.95v-.464-.464h-4.95v-4.949z"
          />
          <path fill="#9B9B9B" d="M39.13 24.901h12.683v1.469H39.13z" />
          <path
            fill="#E6E8E9"
            d="M45.472 42.147a5.38 5.38 0 0 0-5.413 5.413v5.413h10.826V47.56c0-2.939-2.474-5.413-5.413-5.413z"
          />
          <path
            fill="#FAFAFA"
            d="M45.936 42.224h-.928v4.95h-4.95v.927h4.95v4.95h.928V48.1h4.95v-.464-.464h-4.95v-4.949z"
          />
          <path fill="#9B9B9B" d="M39.13 52.973h12.683v1.469H39.13z" />
          <path
            fill="#E6E8E9"
            d="M12.219 42.147a5.38 5.38 0 0 0-5.414 5.413v5.413h10.827V47.56c0-2.939-2.397-5.413-5.413-5.413z"
          />
          <path
            fill="#FAFAFA"
            d="M12.683 42.224h-.928v4.95H6.883v.927h4.949v4.95h.928V48.1h4.95v-.464-.464h-4.95v-4.949h-.077z"
          />
          <path fill="#9B9B9B" d="M5.877 52.973H18.56v1.469H5.877z" />
        </g>
        <g fill="#FAFAFA">
          <path d="M11.29 2.707V5.49h3.171V2.707h1.934v7.192H14.46V6.96h-3.17v3.016H9.357v-7.27zM26.525 6.264c0 2.397-1.701 3.79-4.176 3.79-2.552 0-4.021-1.625-4.021-3.713 0-2.165 1.624-3.789 4.176-3.789 2.552.077 4.021 1.701 4.021 3.712zm-6.186.077c0 1.392.773 2.398 2.088 2.398 1.314 0 2.01-1.083 2.01-2.475 0-1.237-.696-2.397-2.01-2.397-1.315 0-2.088 1.082-2.088 2.474zM29.696 4.099h-2.32V2.707h6.573v1.392h-2.32v5.8h-1.933zM40.677 6.883h-3.17v1.701h3.48v1.315h-5.414V2.707h5.259V4.02h-3.325v1.47h3.17zM42.997 2.707h1.934v5.8h3.402v1.392h-5.336z" />
        </g>
      </g>
    </svg>
  )
}

export default AccommodationsIcon
