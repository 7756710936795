import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      height: "40px",
      borderRadius: "4px",
      border: `1px solid ${t.colors.text_variant}`,
      width: "100%",
      overflow: "hidden"
    }),
  textFieldContainer: css({
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    padding: "8px",
    boxSizing: "border-box"
  }),
  textfield: (t: Theme) =>
    css({
      "&.MuiInputBase-root": {
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        " .MuiInputBase-input": {
          color: t.colors.text_primary
        }
      }
    }),
  button: (t: Theme) =>
    css({
      "&.MuiButtonBase-root": {
        backgroundColor: t.colors.brand_primary,
        height: "100%",
        width: "40px",
        " .MuiSvgIcon-root": {
          color: t.colors.text_white
        }
      }
    })
}
