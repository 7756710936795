import * as React from "react"
import Avatar, { AvatarProps } from "@material-ui/core/Avatar"
import { AvatarStyle } from "./AvatarStyle"
import AccountCircle from "@material-ui/icons/AccountCircle"

const DEFAULT_SIZE = "35px"

export interface Props extends AvatarProps {
  size?: string
}

const AvatarComponent = ({ size, src, ...rest }: Props) => {
  const styles = AvatarStyle(size || DEFAULT_SIZE)

  return (
    <>
      {src && (
        <Avatar
          src={src}
          classes={{
            root: styles.size
          }}
          {...rest}
        />
      )}
      {!src && <AccountCircle className={`${styles.size} ${styles.default}`} />}
    </>
  )
}

export default AvatarComponent
