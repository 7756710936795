import React from "react"
import moment, { Moment } from "moment"
import DatePickerSelect from "../../components/ProductDatePicker/components/DatePickerSelect/DatePickerSelect"
import styles from "./DatePickerMonthElement.styles"

export interface DatePickerMonthElementProps {
  month: Moment
  onMonthSelect: Function
  onYearSelect: Function
}

const DatePickerMonthElement = ({ month, onMonthSelect, onYearSelect }: DatePickerMonthElementProps) => {
  const years = Array.from({ length: 21 }, (_, i) => moment().year() - 10 + i)
  const displayMonths = month.localeData().months()

  return (
    <div className={styles.root()}>
      <div>
        <DatePickerSelect
          onChange={e => {
            onMonthSelect(month, e.target.value)
          }}
          selectedValue={month.month()}
          values={displayMonths}
        />
      </div>
      <div>
        <DatePickerSelect
          onChange={e => {
            onYearSelect(month, +(e.target.value as string))
          }}
          selectedValue={month.year()}
          values={years}
        />
      </div>
    </div>
  )
}

export default DatePickerMonthElement
