import React from "react"
import { SegmentOption as SegmentOptionModel, Price, Service } from "../../model/types"
import SegmentOptionRow from "./SegmentOptionRow"
import SegmentOptionDetail from "../SegmentOptionDetail/SegmentOptionDetail"
import styles from "./SegmentOption.styles"
import AnimateHeight from "react-animate-height"
import { I18N_NS } from "../../utils/constants/constants"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"

export interface Props {
  segmentOption: SegmentOptionModel
  price: Price
  services?: Service[]
  /** set true to force mobile L&F */
  mini?: boolean
  /**
   * Set `"selectable"` to render radio button on component.
   * If you set `type` to `"selectable"`, `OnSelect` and `selected` params are mandatory
   * @default "detail"
   */
  type?: "detail" | "selectable"
  selected?: boolean
  onSelect?: () => void
  highlightDuration?: boolean
  unfoldSegment?: boolean
  variant?: "B2C" | "B2B"
  /**
   * called when the SegmentOption detail is showed
   */
  onOpen?: (open: boolean) => void
  rootContainerId?: string
}

const SegmentOption: React.FunctionComponent<Props> = ({
  segmentOption,
  unfoldSegment,
  price,
  services,
  selected = false,
  onSelect,
  highlightDuration = false,
  type = "detail",
  variant = "B2C",
  mini = false,
  onOpen,
  rootContainerId
}) => {
  const { t } = useTranslation(I18N_NS)

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [open, setOpen] = React.useState<boolean>(false)

  const theme = useTheme()

  function handleCloseView() {
    setOpen(false)
  }

  function handleSelect() {
    if (isMobile) {
      setOpen(false)
    }
    if (onSelect) {
      onSelect()
    }
  }

  const desktopView = (
    <AnimateHeight duration={300} height={unfoldSegment ? "auto" : open ? "auto" : 0}>
      <div className={styles.detail(theme)}>
        <SegmentOptionDetail segmentOption={segmentOption} price={price} services={services} />
      </div>
    </AnimateHeight>
  )

  const mobileView = (
    <FullScreenDialog
      open={unfoldSegment ? true : open}
      title={t("SegmentOption.mobileTitle")}
      actionButtonText={t("SegmentOption.mobileButton")}
      onClose={handleCloseView}
      onAction={handleSelect}
    >
      <SegmentOptionDetail mini={true} segmentOption={segmentOption} price={price} services={services} />
    </FullScreenDialog>
  )

  return (
    <div id={rootContainerId} className={styles.root(theme)}>
      <SegmentOptionRow
        segmentOption={segmentOption}
        onClick={() => {
          const value = !open
          setOpen(value)
          if (onOpen) onOpen(value)
        }}
        price={price}
        variant={variant}
        onSelect={handleSelect}
        selected={selected}
        highlightDuration={highlightDuration}
        open={unfoldSegment ? true : open}
        type={type}
        mini={mini}
      />
      {!isMobile && desktopView}
      {isMobile && mobileView}
    </div>
  )
}

export default SegmentOption
