import React from "react"

import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import Collapse from "@material-ui/core/Collapse"
import styles from "./WhiteBox.styles"
import ItemForTitle, { ItemForTitleProps } from "./ItemForTitle"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  title?: string
  collapsable?: boolean
  collapsed?: boolean
  children?: JSX.Element | string
  hightlightItems?: ItemForTitleProps[]
}

const WhiteBox: React.FunctionComponent<Props> = ({ collapsable, collapsed, title, children, hightlightItems }) => {
  const [open, setOpen] = React.useState<boolean>(collapsed ? collapsed : false)
  const theme = useTheme()

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className={styles.root(theme)} style={{ cursor: collapsable ? "pointer" : "default" }}>
      <div onClick={handleOpen}>
        {!!title && (
          <p className={styles.titleContainer}>
            <span>{title}</span>
            {hightlightItems &&
              hightlightItems.map((item: ItemForTitleProps, i: number) => (
                <span className={styles.titleItems} key={i}>
                  <ItemForTitle title={item.title} value={item.value} />
                </span>
              ))}
            {children && collapsable && (
              <span className={styles.arrow}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</span>
            )}
          </p>
        )}
      </div>
      {children &&
        (collapsable ? (
          <Collapse component="div" in={open}>
            <div className={styles.childrenContainer}>{children}</div>
          </Collapse>
        ) : (
          <div className={styles.childrenContainer}>{children}</div>
        ))}
    </div>
  )
}

export default WhiteBox
