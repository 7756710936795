import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  header: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      fontSize: "12px",
      lineHeight: 2,
      color: t.colors.text_variant,
      backgroundColor: t.colors.background_primary
    }),
  headerLogo: css({
    flex: 1,
    marginRight: "24px",
    "&>img": {
      maxHeight: "48px"
    }
  }),
  order: css({
    flex: 1
  }),
  number: (t: Theme) =>
    css({
      color: t.colors.text_primary
    }),
  checkInHeader: css({
    display: "flex",
    flex: 1,
    flexDirection: "column"
  }),
  checkIn: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_variant,
      width: "100%",
      height: "24px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    })
}
