import React from "react"
import styles from "./Checkbox.styles"

import MaterialFormControlLabel from "@material-ui/core/FormControlLabel"
import MaterialCheckbox from "@material-ui/core/Checkbox"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  checked: boolean
  onCheck?: (c: boolean) => void
  label?: string
  labelPlacement?: "end" | "start" | "top" | "bottom"
  disabled?: boolean
  className?: string
}

const Checkbox: React.FC<Props> = ({ checked, onCheck, disabled, label, labelPlacement = "end", className }) => {
  const theme = useTheme()

  return (
    <MaterialFormControlLabel
      className={`${styles.control(labelPlacement)} ${className}`}
      control={
        onCheck ? (
          <MaterialCheckbox
            checked={checked}
            onChange={e => onCheck(e.target.checked)}
            disabled={disabled}
            className={styles.root(theme)}
          />
        ) : (
          <MaterialCheckbox checked={checked} disabled={disabled} className={styles.root(theme)} />
        )
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  )
}

export default Checkbox
