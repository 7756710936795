import * as React from "react"
import { switchStyle } from "./switchStyle"
import MaterialUISwitch, { SwitchProps } from "@material-ui/core/Switch"

const DEFAULT_COLOR = "#579b08"

export interface Props extends SwitchProps {
  backgroundColor?: string
}

const Switch = ({ backgroundColor, ...rest }: Props) => {
  const styles = switchStyle(backgroundColor || DEFAULT_COLOR)

  return (
    <MaterialUISwitch
      classes={{
        checked: styles.checkedStyle,
        root: styles.checkedStyle,
        track: styles.barStyle
      }}
      {...rest}
    />
  )
}

export default Switch
