import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    "&.MuiAlert-root": {
      display: "flex"
    }
  }),
  text: (t: Theme) =>
    css({
      "&.MuiAlert-message, &.MuiAlert-root": {
        display: "flex",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        alignItems: "center",
        letterSpacing: "0.13px",
        color: t.colors.text_primary,
        padding: "0px 16px"
      }
    }),
  outlined: (t: Theme) =>
    css({
      "&.MuiAlert-outlined, &.MuiAlert-outlinedWarning": {
        backgroundColor: `${t.colors.warning}40`,
        border: `1px solid ${t.colors.warning}`,
        boxZizing: "border-box",
        borderRadius: "4px"
      }
    }),
  icon: (t: Theme) =>
    css({
      "&.MuiAlert-icon, &.MuiSvgIcon-root": {
        color: `${t.colors.warning}200`
      }
    })
}
