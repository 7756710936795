import React from "react"

export const RoomOnly: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L14.164 0 14.164 16 0 16z" transform="translate(1 -.5)" />
      <path
        fill="#4A4A4A"
        d="M11.803 2H2.361v6.667c0 1.473 1.056 2.666 2.36 2.666h3.541c1.305 0 2.361-1.193 2.361-2.666v-2h1.18c.655 0 1.18-.594 1.18-1.334v-2c0-.74-.525-1.333-1.18-1.333zm0 3.333h-1.18v-2h1.18v2zM1.18 14h10.623v-1.333H1.18V14z"
        transform="translate(1 -.5)"
      />
      <path stroke="#E7F0FF" d="M0.443 0.5L13.721 15.5" transform="translate(1 -.5)" />
    </g>
  </svg>
)
