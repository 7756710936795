import React from "react"
import styles from "./Recommendation.styles"
import {
  ItineraryRecommendations as ItineraryRecommendationsModel,
  RecommendedItinerary,
  RecommendationOptions
} from "../../model"
import Text from "@basset-la/components-commons/dist/components/Text"
import { formatPrice, getLocale } from "@basset-la/components-products"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { StarIcon } from "../../icons/StarIcon"
import { MonetizationIcon } from "../../icons/MonetizationIcon"
import { TimerIcon } from "../../icons/TimerIcon"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  variant: RecommendationOptions
  recommendations: ItineraryRecommendationsModel
  onSelectItinerary: (itinerary: RecommendedItinerary) => void
}

const Recommendation: React.FC<Props> = ({ variant, recommendations, onSelectItinerary }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const r =
    variant === "recommended"
      ? recommendations.recommended
      : variant === "cheapest"
      ? recommendations.cheapest
      : recommendations.shortest_flight

  const icon = variant === "recommended" ? <StarIcon /> : variant === "cheapest" ? <MonetizationIcon /> : <TimerIcon />

  const handleClick = () => {
    onSelectItinerary(r)
  }

  return (
    <div className={styles.root(theme)} onClick={handleClick}>
      <div className={styles.title}>
        {icon}
        <Text size={16} variant="bold">
          {t(`Recommendation.${variant}`)}
        </Text>
      </div>
      <div className={styles.text}>
        <Text size={14} variant="regular">
          {`${formatPrice(r.price.total, locale, r.price.currency)} | ${r.duration}`}
        </Text>
      </div>
    </div>
  )
}

export default Recommendation
