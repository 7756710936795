import React from "react"
import { AccommodationPrice, Currency } from "../../model/types"

import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"

import {
  convertResellerAccommodationsPriceboxBreakdown,
  convertToResellerCostEffectivenessBreakdown,
  calculateResellerPayment
} from "../../utils/helpers/helpers"
import AccommodationsPricebox from "../PriceboxBreakdown/PriceboxBreakdown"
import PaymentDetailBreakdown from "../PriceboxBreakdownPaymentDetail/PriceboxBreakdownPaymentDetail"
import CostEffectivenessBreakdown from "../PriceboxBreakdownCostEffectiveness/PriceboxBreakdownCostEffectiveness"
import styles from "./PriceboxBreakdownReseller.syles"

export interface Props {
  accommodationPrice: AccommodationPrice
  rooms: number
  guests: number
  currencySelector?: Currency
  taxesDisclaimer?: string
}

const PriceboxBreakdownReseller: React.FC<Props> = ({
  accommodationPrice,
  rooms,
  guests,
  currencySelector,
  taxesDisclaimer
}) => {
  const priceBreakdown = convertResellerAccommodationsPriceboxBreakdown(accommodationPrice, rooms, guests)
  const costEffectivenessBreakdown = convertToResellerCostEffectivenessBreakdown(accommodationPrice)
  const costDetailBreakdown = calculateResellerPayment(accommodationPrice)
  return (
    <div className={`${styles.root}`}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <AccommodationsPricebox priceBreakdown={priceBreakdown} taxesDisclaimer={taxesDisclaimer} />
      <CostEffectivenessBreakdown costEffectivenessBreakdown={costEffectivenessBreakdown} />
      <PaymentDetailBreakdown paymentDetailBreakdown={costDetailBreakdown} />
    </div>
  )
}

export default PriceboxBreakdownReseller
