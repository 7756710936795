import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  searchInfoRow: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: "8px",
      " > button": {
        color: theme.brand_colors.click_color,
        cursor: "pointer",
        fontWeight: 300,
        fontSize: "12px",
        background: "none !important",
        border: "none",
        "&:focus": {
          outline: 0
        }
      }
    }),

  searchInfoItem: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),

  text: (theme: Theme) =>
    css({
      margin: "0px 8px",
      fontSize: "14px !important",
      color: theme.colors.brand_primary
    }),

  searchInfo: (theme: Theme) =>
    css({
      fontSize: "14px",
      color: theme.colors.brand_primary,
      lineHeight: "1.14",
      display: "flex",
      flexDirection: "column"
    }),

  addressLink: (theme: Theme) =>
    css({
      marginTop: "8px",
      marginBottom: "8px",
      fontSize: "14px",
      lineHeight: "1.14",
      fontWeight: 300,
      color: "#4a4a4a",
      " > span": {
        fontSize: "14px !important"
      },
      " > button": {
        color: theme.brand_colors.click_color,
        cursor: "pointer",
        background: "none !important",
        border: "none"
      },
      " > button:focus": {
        outline: 0
      },
      "@media(min-width:1200px)": {
        marginTop: "8px"
      }
    }),

  strikethrough: css({
    display: "inline-block",
    fontSize: "14px",
    fontWeight: 300,
    letterSpacing: "0.1px",
    textDecoration: "line-through",
    "& > *:first-child": {
      marginRight: "16px"
    }
  }),

  noAvailabilityContainer: css({
    display: "flex",
    flexDirection: "column",
    " >p": {
      fontSize: "12px",
      lineHeight: "2",
      fontWeight: 300,
      color: "#d0021b"
    },
    " >p:last-child": {
      lineHeight: "1.33",
      color: "#4a4a4a"
    }
  }),

  price: css({
    color: "#4a4a4a",
    " > span": {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "1.33",
      marginRight: "8px"
    },
    " > span > span:last-child": {
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: "1.71"
    }
  }),

  highlight: css({
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "1.71"
  }),

  hotelTitle: css({
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "1.5",
    color: "#4a4a4a",
    "@media(min-width:1200px)": {
      fontSize: "24px",
      lineHeight: "1.33"
    }
  }),

  priceDetailContainer: css({
    marginTop: "8px"
  }),

  disclamer: css({
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "1.33",
    color: "#9b9b9b"
  }),

  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
    "@media(min-width: 1024px)": {
      width: "1104px",
      maxHeight: "400px",
      flexDirection: "row"
    }
  }),

  summaryContainer: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    "@media(min-width:1024px)": {
      padding: "32px 24px",
      justifyContent: "space-between"
    }
  }),

  imgContainer: css({
    width: "100%",
    height: "375px",
    "@media(min-width:1200px)": {
      width: "664px",
      height: "400px"
    }
  }),

  viewRoomsButton: (theme: Theme) =>
    css({
      color: "#fff !important",
      marginTop: "16px !important",
      fontSize: "16px !important",
      fontWeight: 300,
      width: "100% !important",
      textTransform: "none",
      backgroundColor: `${theme.colors.brand_primary} !important`,
      "@media(min-width:1024px)": {
        width: "auto !important"
      }
    })
}

export default styles
