import React from "react"
import styles from "./FlightDetailInfo.styles"
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive"
import Text from "@basset-la/components-commons/dist/components/Text"
import { I18N_NS } from "../../utils/constants/constants"
import { useTranslation } from "react-i18next"

export interface Props {
  flight_info: {
    carrier: string
    number: string
  }
}

const FlightDetailInfo: React.FC<Props> = ({ flight_info }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div>
      <Text size={16} variant="bold">
        {t("FlightDetailInfo.title")}
      </Text>
      <div className={styles.containerComponentStyle}>
        <div className={styles.containerStyle}>
          <AirplanemodeActiveIcon className={styles.airplaneIconStyle} />
          <div className={styles.leftTextContainerStyle}>
            <Text size={14} variant="bold">
              {t("FlightDetailInfo.carrier")}
            </Text>
            <Text size={14}>{flight_info.carrier}</Text>
          </div>
        </div>
        <div className={styles.rightTextContainerStyle}>
          <Text size={14} variant="bold">
            {t("FlightDetailInfo.number")}
          </Text>
          <Text size={14}>{flight_info.number}</Text>
        </div>
      </div>
    </div>
  )
}

export default FlightDetailInfo
