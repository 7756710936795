import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const AirplaneIcon: React.FunctionComponent<Props> = ({ ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" {...rest}>
    <path
      fillRule="evenodd"
      d="M4.8 15.2h1.6l4-6.4h4.4c.664 0 1.2-.536 1.2-1.2 0-.664-.536-1.2-1.2-1.2h-4.4L6.4 0H4.8l2 6.4H2.4L1.2 4.8H0l.8 2.8-.8 2.8h1.2l1.2-1.6h4.4l-2 6.4z"
    />
  </svg>
)
