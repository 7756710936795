import React, { useState } from "react"

import { useTranslation } from "react-i18next"

import Text from "@basset-la/components-commons/dist/components/Text"
import { useTheme } from "@basset-la/themed-components"

import { Cluster as ClusterModel } from "../../model/types"
import { getProviderShortName } from "../../utils/helpers/helpers"
import Cluster from "../Cluster/Cluster"
import styles from "./ClusterDesktop.styles"
import ClusterInstallmentsDesktop from "./ClusterInstallmentsDesktop"
import ClusterPriceDesktop from "./ClusterPriceDesktop"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  cluster: ClusterModel
  onSelectItinerary: (selectedOptions: number[], selectedBrand: number) => void
  onAddToQuote: (selectedOptions: number[], selectedBrand: number) => void
  onAdjustPrice?: (selectedOptions: number[], selectedBrand: number, value: number) => void
  customButtons?: CustomButtonText[]
  selectedBrand: number
  onOpenBrandSelectionDialog: (selectedOptions: number[], selectedBrand: number) => void
}

const ClusterDesktop: React.FC<Props> = ({
  cluster,
  onSelectItinerary,
  onAddToQuote,
  onAdjustPrice,
  customButtons,
  selectedBrand,
  onOpenBrandSelectionDialog
}) => {
  const [selectedOptions, setSelectedOptions] = useState<number[]>(new Array(cluster.segments.length).fill(0))

  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleOnSelect = (segmentIndex: number, optionIndex: number) => {
    let selectedOptionsCopy = [...selectedOptions]
    selectedOptionsCopy[segmentIndex] = optionIndex
    setSelectedOptions(selectedOptionsCopy)
  }

  const handleOnSelectItinerary = () => {
    onSelectItinerary(selectedOptions, selectedBrand)
  }

  const handleOpenBrandSelectionDialog = () => {
    onOpenBrandSelectionDialog(selectedOptions, selectedBrand)
  }

  const handleOnAddToQuote = () => {
    onAddToQuote(selectedOptions, selectedBrand)
  }

  const handleOnAdjustPrice = (value: number) => {
    if (onAdjustPrice) onAdjustPrice(selectedOptions, selectedBrand, value)
  }

  const provider = getProviderShortName(cluster.provider_id, cluster.source, cluster.source_type)

  return (
    <div className={styles.root(theme)}>
      <div className={styles.clusterContainer}>
        <div className={styles.clusterInfoContainer}>
          <Cluster
            variant="B2B"
            cluster={cluster}
            onSelect={handleOnSelect}
            selectedOptions={selectedOptions}
            selectedBrand={selectedBrand}
            onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog}
          />
          <div className={styles.providerInfo}>
            <Text size={14} variant="light">
              {t("ClusterDesktop.provider")}
            </Text>{" "}
            <Text size={14} variant="bold">
              {t(`Provider.${provider}`)}
            </Text>
          </div>
        </div>
        <div className={styles.priceContainer}>
          <ClusterPriceDesktop
            price={cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price}
            fareType={cluster.fare_type || ""}
            accountCodesUsed={cluster.account_codes_used || []}
            onSelectItinerary={handleOnSelectItinerary}
            onAddToQuote={handleOnAddToQuote}
            onAdjustPrice={onAdjustPrice ? handleOnAdjustPrice : undefined}
            customButtons={customButtons}
          />
          {cluster.form_of_payments && cluster.form_of_payments.length > 0 && (
            <ClusterInstallmentsDesktop className={styles.installments} formOfPayments={cluster.form_of_payments} />
          )}
        </div>
      </div>
    </div>
  )
}

export default ClusterDesktop
