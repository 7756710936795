import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const DepartureIcon: React.FunctionComponent<Props> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 16" {...props}>
    <path
      fill="#4A4A4A"
      fillRule="nonzero"
      d="M4.52 14.394h14.99V16H4.52v-1.606zm15.44-7.518a1.179 1.179 0 0 0-1.451-.852l-4.19 1.14L8.877 2l-1.523.41 3.266 5.759L6.7 9.237 5.143 8 4 8.313l1.436 2.538.607 1.069L19.116 8.36c.64-.184 1.01-.843.845-1.485z"
    />
  </svg>
)
