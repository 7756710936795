import { css } from "@emotion/css"

export default {
  linearStyle: css({
    width: "100%",
    height: "8px !important",
    borderRadius: "4px",
    " > div": {
      backgroundColor: "#03a9f4 !important"
    }
  }),
  containerStyle: css({
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }),
  mainContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "136px"
  }),
  imgContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "98px",
    height: "98px",
    borderRadius: "50%",
    backgroundColor: "#00a5f4",
    " > img": {
      width: "58px",
      height: "65px"
    },
    marginBottom: "24px"
  })
}
