import React from "react"
import { CostPayment } from "../../model/types"
import Pricebox from "@basset-la/components-products/dist/components/Pricebox"
import PriceboxRow from "@basset-la/components-products/dist/components/PriceboxRow"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { colors } from "../../utils/constants/constants"
import { getLocale, formatPrice } from "@basset-la/components-products/dist/utils/helpers"

export interface Props {
  paymentDetailBreakdown: CostPayment
}

const PriceboxBreakdownPaymentDetail: React.FC<Props> = ({ paymentDetailBreakdown }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()
  const { total, currency, taxesAndCharges, commision, over, fare } = paymentDetailBreakdown
  return (
    <div>
      <Pricebox
        backgroundColor={colors.GREY}
        textColor={theme.colors.text_white}
        totalLabel={t("PaymentDetailBreakdown.total")}
        title={t("PaymentDetailBreakdown.title")}
        totalValue={formatPrice(total, locale, currency)}
        startCollapsed={true}
      >
        <PriceboxRow label={t("PaymentDetailBreakdown.rate")} value={formatPrice(fare, locale, currency)} />
        <PriceboxRow label={t("PaymentDetailBreakdown.taxes")} value={formatPrice(taxesAndCharges, locale, currency)} />
        <PriceboxRow label={t("PaymentDetailBreakdown.over")} value={`- ${formatPrice(over, locale, currency)}`} />
        <PriceboxRow
          label={t("PaymentDetailBreakdown.commission")}
          value={`- ${formatPrice(commision, locale, currency)}`}
        />
      </Pricebox>
    </div>
  )
}

export default PriceboxBreakdownPaymentDetail
