import { css } from "@emotion/css"

export default {
  root: (isSingleCategory: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      alignItems: isSingleCategory ? "center" : undefined
    }),

  dialog: css({
    ".MuiDialog-paperWidthMd": {
      maxWidth: "948px"
    }
  }),

  dialogTitle: css({
    "&.MuiDialogTitle-root": {
      borderBottom: "1px solid #eaeaea",
      padding: "8px 24px",
      " .MuiTypography-root": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center"
      }
    }
  }),

  selectTitle: css({
    fontWeight: 500,
    fontSize: "24px"
  }),

  titleRightColumn: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "end"
  }),

  footer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "32px"
  }),

  priceContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  })
}
