import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  nonRefundable: (t: Theme) =>
    css({
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "1.14",
      color: t.colors.text_primary
    }),
  refundable: (t: Theme) =>
    css({
      ontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "1.14",
      color: t.colors.brand_primary
    })
}
