import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const CheckCircleIcon: React.FC<Props> = ({ color, ...rest }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.333374 6.99998C0.333374 3.31998 3.32004 0.333313 7.00004 0.333313C10.68 0.333313 13.6667 3.31998 13.6667 6.99998C13.6667 10.68 10.68 13.6666 7.00004 13.6666C3.32004 13.6666 0.333374 10.68 0.333374 6.99998ZM2.80004 7.46665L5.19337 9.85998C5.45337 10.12 5.88004 10.12 6.13337 9.85998L11.1934 4.79998C11.4534 4.53998 11.4534 4.11998 11.1934 3.85998C10.9334 3.59998 10.5134 3.59998 10.2534 3.85998L5.66671 8.44665L3.74004 6.52665C3.48004 6.26665 3.06004 6.26665 2.80004 6.52665C2.67521 6.6512 2.60505 6.8203 2.60505 6.99665C2.60505 7.17299 2.67521 7.34209 2.80004 7.46665Z"
      fill={color}
    />
  </svg>
)

export default CheckCircleIcon
