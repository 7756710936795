import React from "react"
import Select, { SelectProps } from "@material-ui/core/Select"
import { useTheme } from "@basset-la/themed-components"
import styles from "./AgeSelect.styles"

export interface Props extends Omit<SelectProps, "ref"> {}

const AgeSelect = React.forwardRef<unknown, Props>(({ className, ...rest }, ref) => {
  const theme = useTheme()
  return <Select variant="outlined" className={`${styles.root(theme)} ${className}`} ref={ref} {...rest} />
})

export default AgeSelect
