import React, { useState } from "react"
import styles from "./AccommodationCarouselImage.styles"

export interface Props {
  img: string
}

const AccommodationCarouselImage: React.FC<Props> = ({ img }) => {
  const [hasError, setError] = useState(false)

  const handleImgError = () => {
    setError(true)
  }

  return (
    <>
      {hasError && (
        <img className={styles.img} src="https://statics.basset.la/accommodations/no-image-large.png" alt="" />
      )}
      {!hasError && <img className={styles.img} src={img} alt="" onError={handleImgError} />}
    </>
  )
}

export default AccommodationCarouselImage
