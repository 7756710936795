export interface ProductRateDesktopModel {
  provider: string
  fare: {
    currency: string
    total: number
    daily: number
    discount?: ProductDiscountDesktopModel
  }
  rate_code?: string
  change_office?: string
  discounted_rate?: string
}

export type ProductDiscountDesktopModel = {
  discount?: number
  base_rate?: number
  total?: number
  applied?: ProductDiscountAppliedDesktopModel[]
}

export class ProductDiscountAppliedDesktopModel {
  code?: string
  value?: string
}
