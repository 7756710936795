import React, { useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import Text from "@basset-la/components-commons/dist/components/Text"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import Popover from "@material-ui/core/Popover"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import PassengerIcon from "@material-ui/icons/PersonRounded"

import { I18N_NS } from "../../utils/constants"
import PassengerPicker from "./PassengerPicker"
import styles from "./PassengerSelect.styles"
import { Passengers } from "./types"

interface Props {
  passengers: Passengers
  maxPassengers: number
  onChange: (p: Passengers) => void
}

const PassengerSelect: React.FC<Props> = ({ passengers, maxPassengers, onChange }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const [open, setOpen] = useState<boolean>(false)
  const [pickerPassengers, setPickerPassengers] = useState(passengers)
  const [allPassengersError, setAllPassengersError] = useState<string | undefined>()
  const [childrenErrors, setChildrenErrors] = useState<string[]>([])

  const inputRef = useRef<HTMLDivElement>(null)

  const buildPassengersText = (p: Passengers): string => {
    const { adults, children, disableds, seniors } = p
    return t("PassengerSelect.persons", { count: adults + children.length + seniors + disableds })
  }

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setOpen(true)
  }

  const handleClose = () => {
    if (validate(pickerPassengers)) {
      onChange(pickerPassengers)
      setOpen(false)
    }
  }

  const handleOnChange = (p: Passengers) => {
    validate(p)
    setPickerPassengers(p)
  }

  const validate = (currentPassengers: Passengers): boolean => {
    const { adults, children, disableds, seniors } = currentPassengers

    let valid = true && validateChildren(currentPassengers)

    if (adults === 0 && seniors === 0 && disableds === 0 && children.length === 0) {
      valid = false
      setAllPassengersError(t("PassengerPicker.noPassengersError"))
    } else {
      setAllPassengersError(undefined)
    }

    return valid
  }

  const validateChildren = (psgrs: Passengers): boolean => {
    let valid = true
    const { children, adults, disableds, seniors } = psgrs
    const totalAdults = adults + disableds + seniors
    let errors: string[] = []
    const totalInfants = children.reduce((total, current) => {
      if (current === "INFANT_IN_ARMS") {
        return total + 1
      }
      return total
    }, 0)

    for (let i in children) {
      if (children[i] === "NO_SET") {
        errors[i] = t("PassengerPicker.childrenNoAgeError")
        valid = false
      } else if (children[i] === "INFANT_IN_ARMS" && totalInfants > totalAdults) {
        errors[i] = t("PassengerPicker.childrenInArmsError")
        valid = false
      }
    }

    setChildrenErrors(errors)

    return valid
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("PassengerSelect.label")}
        </Text>
        <SearchboxTextField
          id="select-searchbox-passenger-count"
          className={styles.root}
          onClick={handleOpen}
          rightIcon={<ArrowIcon className={styles.rightIcon} />}
          leftIcon={<PassengerIcon className={styles.icon(theme)} />}
          value={buildPassengersText(pickerPassengers)}
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Popover
          className={styles.popover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={open}
          onClose={handleClose}
          anchorEl={inputRef.current}
        >
          <span className={styles.title}>{t("PassengerPicker.title")}</span>
          <PassengerPicker
            maxPassengers={maxPassengers}
            allPassengersError={allPassengersError}
            childrenErrors={childrenErrors}
            onChange={handleOnChange}
            passengers={pickerPassengers}
            isMobile={isMobile}
          />
          <div className={styles.buttonContainer(theme)}>
            <Button onClick={handleClose} variant="text">
              {t("PassengerPicker.done")}
            </Button>
          </div>
        </Popover>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          actionButtonText={t("Searchbox.apply")}
          onClose={handleClose}
          onAction={handleClose}
          toolbarComponent={
            <SearchboxTextField
              id="select-searchbox-passenger-count"
              className={styles.toolbarTextField}
              InputProps={{ disabled: true }}
              leftIcon={<PassengerIcon className={styles.icon(theme)} />}
              value={buildPassengersText(pickerPassengers)}
            />
          }
        >
          <PassengerPicker
            maxPassengers={maxPassengers}
            allPassengersError={allPassengersError}
            childrenErrors={childrenErrors}
            onChange={handleOnChange}
            passengers={pickerPassengers}
            isMobile={isMobile}
          />
        </FullScreenDialog>
      )}
    </>
  )
}

export default PassengerSelect
