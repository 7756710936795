import { css } from "@emotion/css"

export const radioStyle = (color: string) => {
  return {
    root: css({
      color: `${color} !important`,
      padding: "0 !important"
    }),
    checked: css({
      color: `${color} !important`
    })
  }
}
