import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  mini: css({
    display: "flex",
    flexDirection: "row",
    padding: "1px 8px",
    backgroundColor: "#4A81E226 !important",
    color: "#4A81E2",
    borderRadius: "4px",
    fontSize: "12px",
    alignItems: "center",
    marginRight: "4px",
    ":last-of-type": {
      marginRight: 0
    }
  }),

  full: (color?: string) =>
    css({
      alignSelf: "center",
      flex: 1,
      flexDirection: "row",
      "> p": {
        margin: 0,
        textAlign: "left",
        fontWeight: 500,
        color: color,
        "@media screen and (max-width: 425px)": {
          textAlign: "left"
        }
      }
    }),

  rootTooltip: css({
    backgroundColor: "transparent !important"
  }),

  tooltip: (t: Theme) =>
    css({
      position: "relative",
      padding: "8px !important",
      backgroundColor: `${t.colors.background_primary} !important`,
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
    }),

  popper: (t: Theme) =>
    css({
      backgroundColor: "transparent !important",
      '&[x-placement*="bottom"] > div > .arrow': {
        top: 0,
        left: 0,
        marginTop: "-0.95em",
        width: "2em",
        height: "1em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${t.colors.background_primary} transparent`
        }
      },
      '&[x-placement*="top"] > div > .arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.95em",
        width: "2em",
        height: "1em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${t.colors.background_primary} transparent transparent transparent`
        }
      },
      '&[x-placement*="right"] > div > .arrow': {
        left: 0,
        marginLeft: "-0.95em",
        height: "2em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${t.colors.background_primary} transparent transparent`
        }
      },
      '&[x-placement*="left"] > div > .arrow': {
        right: 0,
        marginRight: "-0.95em",
        height: "2em",
        width: "1em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${t.colors.background_primary}`
        }
      }
    }),
  noClassIcon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        color: t.colors.error,
        marginLeft: "2px",
        fontSize: "16px"
      }
    })
}
