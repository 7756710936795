import { Auth0ContextInterface, IdToken, User } from "@auth0/auth0-react"
import React, { useContext, useEffect, useState } from "react"
import { AuthUserType, UserAgency, UserAgencies } from "../../models/auth"
import { Config } from "../../models/config"

export const AuthUserContext = React.createContext<AuthUserType>({} as AuthUserType)

export interface Props {
  auth0: Auth0ContextInterface<User>
  config: Config
  loginUrl?: string
  getUserAgencies: (userId: string, authorization: string, clientId: string) => Promise<UserAgencies>
  fallback?: React.ReactNode
  children?: React.ReactNode
}

const AuthUserProvider: React.FC<Props> = ({ auth0, config, loginUrl, getUserAgencies, fallback, children }) => {
  const [bassetId, setBassetId] = useState<string | null>(null)
  const [agency, setAgency] = useState<UserAgency | null>(null)

  useEffect(() => {
    const fillBassetId = async () => {
      try {
        const claims = await auth0.getIdTokenClaims()
        const userId = (claims as IdToken)["https://app.basset.la/app_metadata"]["basset_id"]

        const userAgencies = await getUserAgencies(userId, claims?.__raw || "", config.agency_id)
        const agency = userAgencies.agencies.find((a: UserAgency) => a.client_id === config.agency_id && !a.blocked)

        setBassetId(userId)
        setAgency(agency || null)
      } catch (e) {
        console.error("Error getting user agencies", e)
        auth0.logout({
          returnTo: loginUrl || `${window.location.origin}/auth/login`
        })
      }
    }

    if (auth0.isLoading) {
      return
    }

    if (config.require_authentication && !auth0.isAuthenticated) {
      window.location.href = `${window.location.origin}/auth/login`
      return
    }

    if (config.require_authentication && auth0.isAuthenticated && !bassetId) {
      fillBassetId()
    }
  }, [auth0, config, getUserAgencies, bassetId, loginUrl])

  const result: AuthUserType = {
    userId: bassetId,
    agency: agency
  }

  return (
    <AuthUserContext.Provider value={result}>
      {config.require_authentication && (auth0.isLoading || (auth0.isAuthenticated && !bassetId)) && fallback}
      {(!config.require_authentication || (auth0.isAuthenticated && bassetId)) && children}
    </AuthUserContext.Provider>
  )
}

export const useAuthUser = () => useContext(AuthUserContext)

export const withAuthUser = (Component: any) => {
  return function authComponent(props: any) {
    return <AuthUserContext.Consumer>{authUser => <Component {...props} {...authUser} />}</AuthUserContext.Consumer>
  }
}

export default AuthUserProvider
