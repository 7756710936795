import { css } from "@emotion/css"

export default {
  loaderContainer: css({
    display: "flex",
    height: "100%",
    width: "100%",
    paddingTop: "96px",
    flexDirection: "column",
    alignItems: "center",
    "> .MuiCircularProgress-root": {
      color: "#979797"
    }
  }),
  totalContainer: css({
    width: "100%",
    marginLeft: "24px"
  }),
  mainContainerStyle: css({
    width: "1104px",
    backgroundColor: "#eaeaea",
    padding: "16px 16px 16px 8px",
    display: "flex",
    flexDirection: "row"
  }),
  roomClustersContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    " > div": {
      marginBottom: "8px"
    },
    " > div:last-child": {
      marginBottom: 0
    }
  }),
  containerStyle: css({
    display: "flex",
    flexDirection: "column"
  }),
  currencySelectorContainer: css({
    justifyContent: "flex-end"
  }),
  currencyContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  exchangeRateContainer: css({
    justifyContent: "flex-end"
  }),
  exchangeRateText: css({
    fontSize: "10px",
    letterSpacing: "0.11px"
  })
}
