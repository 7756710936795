import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const SeatAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99935 6.66667V12C1.99935 13.1067 2.89268 14 3.99935 14H7.33268C7.69935 14 7.99935 14.3 7.99935 14.6667C7.99935 15.0333 7.69935 15.3333 7.33268 15.3333H3.99935C2.15935 15.3333 0.666016 13.84 0.666016 12V6.66667C0.666016 6.3 0.966016 6 1.33268 6C1.69935 6 1.99935 6.3 1.99935 6.66667ZM11.3327 16H12.3327C12.886 16 13.3327 16.4467 13.3327 17C13.3327 17.5533 12.886 18 12.3327 18H10.666C9.93268 18 9.33268 17.4 9.33268 16.6667V13.3333H4.66602C3.56602 13.3333 2.66602 12.4333 2.66602 11.3333V6H6.66602V10H9.99935C10.7327 10 11.3327 10.6 11.3327 11.3333V16Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23506 10.2518H13.7651C14.5351 10.2518 15.0151 9.4168 14.6301 8.7518L10.8651 2.2468C10.4801 1.5818 9.52006 1.5818 9.13506 2.2468L5.37006 8.7518C4.98506 9.4168 5.46506 10.2518 6.23506 10.2518ZM10.0001 6.7518C9.72506 6.7518 9.50006 6.5268 9.50006 6.2518V5.2518C9.50006 4.9768 9.72506 4.7518 10.0001 4.7518C10.2751 4.7518 10.5001 4.9768 10.5001 5.2518V6.2518C10.5001 6.5268 10.2751 6.7518 10.0001 6.7518ZM9.50006 8.7518H10.5001V7.7518H9.50006V8.7518Z"
      fill="#FDB43C"
    />
  </svg>
)
