import React from "react"
import SimpleAccommodationInfo, { SimpleAccommodationInfoProps } from "../SimpleAccommodationInfo"
import ProviderPrices, { ProviderPrice } from "./ProviderPrices"
import styles from "./AccommodationClusterDesktop.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import AddIcon from "@material-ui/icons/AddRounded"
import IconButton from "@material-ui/core/IconButton"
import FavFullIcon from "@material-ui/icons/FavoriteRounded"
import FavEmptyIcon from "@material-ui/icons/FavoriteBorderRounded"
import AccommodationClusterDesktopPriceBreakdown from "../AccommodationClusterDesktopPriceBreakdown/AccommodationClusterDesktopPriceBreakdown"
import CurrencySelector from "@basset-la/components-products/dist/components/CurrencySelector"
import { AccommodationFare, Currency, Promotion } from "../../model"
import { ExchangeRateText } from "@basset-la/components-products"

type LoadingTrueProps = {
  loadingFare: true
  provider?: string
  nightsCount?: number
  guestsCount?: number
  roomsCount?: number
  accommodationFare?: AccommodationFare
}

type LoadingFalseProps = {
  loadingFare?: false
  provider: string
  nightsCount: number
  guestsCount: number
  roomsCount: number
  accommodationFare: AccommodationFare
}

export type Props = SimpleAccommodationInfoProps & {
  loadingProviderPrice?: boolean
  onViewHotel: () => void
  otherProviders: ProviderPrice[]
  favorite: boolean
  onAddToFavorite: (fav: boolean) => void
  onAddToQuote?: () => void
  currencies?: string[]
  selectedCurrency?: string
  onSelectCurrency?: (newCurrency: string) => void
  currencySwitcher?: Currency
  promotions?: Promotion[]
} & (LoadingTrueProps | LoadingFalseProps)

const AccommodationClusterDesktop: React.FC<Props> = ({
  loadingFare = false,
  loadingProviderPrice = false,
  nightsCount,
  guestsCount,
  roomsCount,
  accommodationFare,
  onViewHotel,
  onAddToQuote,
  otherProviders,
  provider,
  favorite,
  onAddToFavorite,
  currencies,
  selectedCurrency = "ARS",
  onSelectCurrency,
  currencySwitcher,
  promotions,
  ...rest
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const handleOnFavorite = () => onAddToFavorite(!favorite)

  return (
    <div className={styles.root(theme)}>
      <IconButton className={styles.favoriteButton(theme)} onClick={handleOnFavorite}>
        {favorite && <FavFullIcon />}
        {!favorite && <FavEmptyIcon />}
      </IconButton>
      <div className={styles.container}>
        <div className={styles.infoContainer}>
          <SimpleAccommodationInfo {...rest} />
        </div>
        {loadingProviderPrice && (
          <div className={styles.moreProviders(theme)}>
            <ProviderPrices loading />
          </div>
        )}
        {!loadingProviderPrice && otherProviders.length > 1 && (
          <div className={styles.moreProviders(theme)}>
            <ProviderPrices providers={otherProviders} />
          </div>
        )}
      </div>
      <div className={styles.priceBreakdown(theme)}>
        <div className={styles.currencyContainer}>
          {onSelectCurrency && (
            <CurrencySelector
              containerClassName={styles.currencySelectorContainer}
              selectedCurrency={selectedCurrency}
              onSelectCurrency={onSelectCurrency}
              currencies={currencies}
            />
          )}
          {!loadingFare &&
            currencySwitcher &&
            currencySwitcher.currency !== currencySwitcher.originalCurrency.currency_code && (
              <ExchangeRateText
                currency={currencySwitcher.currency}
                originalCurrency={currencySwitcher.originalCurrency}
                direction="row"
                containerClassName={styles.exchangeRateContainer}
                labelClassName={styles.exchangeRateText}
                infoClassName={styles.exchangeRateText}
              />
            )}
        </div>
        {loadingFare && <AccommodationClusterDesktopPriceBreakdown loading provider={provider} />}
        {!loadingFare && (
          <AccommodationClusterDesktopPriceBreakdown
            nightsCount={nightsCount!}
            roomsCount={roomsCount!}
            guestsCount={guestsCount!}
            provider={provider!}
            accommodationFare={accommodationFare!}
            promotions={promotions}
          />
        )}
        <div className={styles.buttonContainer}>
          <Button onClick={onViewHotel} variant="contained" className={styles.onViewButton(theme)}>
            {t("AccommodationClusterDesktop.view_hotel")}
          </Button>
          {onAddToQuote && (
            <Button onClick={onAddToQuote} variant="outlined" className={styles.addToQuote(theme)}>
              <AddIcon /> {t("AccommodationClusterDesktop.add_to_quote")}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default AccommodationClusterDesktop
