import { useTheme } from "@basset-la/themed-components"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import React from "react"
import styles from "./QuickFilterTabBar.styles"

export interface Props {
  icon: React.ReactNode
  title: string
  tabs: Tab[]
  selectedValue?: unknown
}

export type Tab = {
  icon: React.ReactElement
  title: string
  value: unknown
  onClick?: React.ReactEventHandler<{}>
}

const QuickFilterTabBar: React.FunctionComponent<Props> = ({ icon, title, tabs, selectedValue }) => {
  const theme = useTheme()

  const [value, setValue] = React.useState<unknown>(selectedValue)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: unknown) => {
    setValue(newValue)
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.titleRoot(theme)}>
        <div className={styles.titleContainer}>
          <div>{icon}</div>
          <p className={styles.title}>{title}</p>
        </div>
      </div>
      <Tabs value={value} onChange={handleChange} classes={{ indicator: styles.indicator(theme) }}>
        {tabs.map((e, i) => {
          return (
            <Tab
              key={`tabFilter${i}`}
              className={styles.tab(theme)}
              label={e.title}
              icon={e.icon}
              onClick={e.onClick}
              value={e.value}
            />
          )
        })}
      </Tabs>
    </div>
  )
}

export default QuickFilterTabBar
