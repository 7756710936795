import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      position: "relative",
      width: "100%",
      height: "56px",
      backgroundColor: t.colors.background_primary,
      boxSizing: "border-box",
      borderBottom: `1px solid ${t.colors.background_darker}`,
      "@media screen and (max-width: 425px)": {
        width: "100vw"
      }
    }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        color: t.colors.text_primary
      }
    }),
  iconContainer: css({
    position: "absolute",
    zIndex: 3,
    left: 0,
    top: 0,
    height: "100%",
    width: "56px",
    background: "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 85%, rgba(255,255,255,0) 100%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }),
  barContainer: css({
    paddingLeft: "56px",
    position: "absolute",
    zIndex: 2,
    left: 0,
    top: 0,
    overflowY: "hidden",
    overflowX: "scroll",
    boxSizing: "border-box",
    width: "calc(100% - 56px)",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "> *": {
      marginRight: "16px",
      ":last-of-type": {
        marginRight: 0
      }
    }
  }),
  popover: css({
    height: "fit-content",
    width: "320px",
    padding: "16px",
    boxSizing: "border-box",
    "@media screen and (max-width:425px)": {
      padding: "8px",
      width: "100vw"
    }
  }),
  mobileView: (t: Theme) =>
    css({
      position: "absolute",
      backgroundColor: t.colors.background_primary,
      top: "56px",
      zIndex: 2
    }),
  background: css({
    position: "absolute",
    backgroundColor: colors.BLACK,
    opacity: 0.7,
    top: "56px",
    left: 0,
    right: 0,
    bottom: 0,
    width: "100vw",
    height: "100vh"
  })
}
