import React, { useState } from "react"
import CheckboxFilter, { CheckboxFilterOption } from "@basset-la/components-products/dist/components/CheckboxFilter"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./ProvidersFilter.styles"
import { Provider } from "../../model"
import AccommodationFiltersCollapsePanel from "../AccommodationFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  options: Option[]
  onChange: (value: number, checked: boolean) => void
  onDone?: () => void
}

export type Option = {
  provider: Provider
  selected?: boolean
}

const ProvidersFilter: React.FC<Props> = ({ collapsible, options, onChange, onDone }) => {
  const { t } = useTranslation(I18N_NS)
  const [allOptionsSelected, setAllOptionsSelected] = useState(false)

  const mapToCheckboxOptions = (options: Option[]): CheckboxFilterOption[] => {
    return [
      {
        title: t("ProvidersFilter.all"),
        value: undefined,
        selected: allOptionsSelected
      },
      ...options.map(o => ({
        title: o.provider.name,
        value: o.provider.id,
        selected: o.selected
      }))
    ]
  }

  const onFilterChange = (value: number, checked: boolean): void => {
    const newValue = value === undefined
    if (allOptionsSelected !== newValue) {
      setAllOptionsSelected(newValue)
    }
    onChange(value, checked)
  }

  return (
    <div className={styles.root}>
      {!collapsible && (
        <CheckboxFilter
          onChange={onFilterChange}
          title={t("ProvidersFilter.title")}
          options={mapToCheckboxOptions(options)}
          onDone={onDone}
          id={{
            doneBtn: "providers-filter-checkbox-done-btn"
          }}
        />
      )}
      {collapsible && (
        <AccommodationFiltersCollapsePanel
          renderSummary={<>{t("ProvidersFilter.title")}</>}
          renderDetails={
            <div className={styles.container}>
              <CheckboxFilter
                onChange={onFilterChange}
                options={mapToCheckboxOptions(options)}
                onDone={onDone}
                id={{
                  doneBtn: "providers-filter-checkbox-done-btn"
                }}
              />
            </div>
          }
        />
      )}
    </div>
  )
}

export default ProvidersFilter
