import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary
    }),
  textfield: (t: Theme) =>
    css({
      "&.MuiTextField-root": {
        ":hover .MuiInputLabel-shrink": {
          color: `${t.colors.brand_primary} !important`
        },
        " .MuiInputLabel-outlined": {
          transform: "translate(14px, 14px) scale(1)"
        },
        " .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)"
        },
        " .MuiInputLabel-root": {
          color: t.colors.text_variant,
          backgroundColor: t.colors.background_primary,
          "&.Mui-focused": {
            color: `${t.colors.brand_primary} !important`
          },
          "&.Mui-error": {
            color: t.colors.error
          }
        },
        " .MuiOutlinedInput-root": {
          color: t.colors.text_primary,
          ":hover, &.Mui-focused": {
            " .MuiOutlinedInput-notchedOutline": {
              borderColor: t.colors.brand_primary
            }
          },
          "&.Mui-error": {
            borderColor: t.colors.error
          },
          " .MuiOutlinedInput-notchedOutline": {
            borderColor: t.colors.text_variant
          }
        }
      }
    })
}
