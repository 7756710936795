import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_primary,
      border: `1px solid ${theme.colors.background_darker}`,
      borderRadius: "3px",
      padding: "16px",
      margin: "8px 0 8px 0",
      fontFamily: "Roboto",
      fontWeight: 300,
      color: theme.colors.text_primary
    }),
  titleItems: css({
    fontSize: "16px",
    "& > span": {
      fontFamily: "Roboto",
      fontWeight: 300,
      marginRight: "4px",
      textTransform: "uppercase"
    },

    "& > span:nth-child(2n)": {
      fontWeight: 500
    }
  }),
  arrow: css({
    display: " inline-flex",
    justifyContent: "flex-end"
  }),
  titleContainer: css({
    display: "flex",
    margin: 0,
    flexWrap: "wrap",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
    fontSize: "24px",
    paddingRight: "8px",
    "& > span": {
      flexGrow: 1
    }
  }),
  childrenContainer: css({
    margin: "8px 0"
  }),
  value: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  })
}
