import React from "react"
import { AdvertisingImageData } from "./types"
import styles from "./AdvImage.styles"

export interface Props {
  image: AdvertisingImageData
}

const AdvImage: React.FC<Props> = ({ image }) => {
  const handleClick = () => {
    if (image.link) {
      window.open(image.link, "_blank")
    }
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      <img src={image.url} alt={image.alt} />
    </div>
  )
}

export default AdvImage
