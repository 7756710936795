import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import React from "react"
import Sticky from "react-stickynode"
import styles from "./RoomsHeader.styles"
interface Props {
  bottomBoundary?: string | number
  top?: string | number
}

const RoomsHeader = ({ bottomBoundary, top }: Props) => {
  const { t } = useTranslation(I18N_NS)
  return (
    <Sticky innerZ={1000} top={top} bottomBoundary={bottomBoundary}>
      <div className={styles.containerStyle}>
        <div className={styles.rooms}>{t("RoomsHeader.rooms")}</div>
        <div className={styles.options}>{t("RoomsHeader.options")}</div>
        <div className={styles.perNight}>{t("RoomsHeader.per_night")}</div>
        <div className={styles.choose}>{t("RoomsHeader.choose")}</div>
        <div className={styles.total}>{t("RoomsHeader.total")}</div>
      </div>
    </Sticky>
  )
}

export default RoomsHeader
