import { css } from "@emotion/css"

export default {
  select: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "24px",
    rowGap: "8px"
  }),

  item: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  })
}
