import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiPaper-root": {
        backgroundColor: t.colors.background_primary,
        border: `solid 1px ${t.colors.background_darker}`,
        padding: "16px"
      },
      "&.MuiPaper-elevation1": {
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25), 0px 2px 2px rgba(0, 0, 0, 0.24)"
      },
      "&.MuiPaper-elevation1:hover": {
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.24)"
      }
    }),
  selected: {
    elevation: (t: Theme) =>
      css({
        "&.MuiPaper-root": {
          boxSizing: "border-box",
          border: `solid 2px ${t.colors.brand_primary}`
        }
      }),
    outlined: (t: Theme) =>
      css({
        "&.MuiPaper-root": {
          backgroundColor: t.colors.background_variant,
          borderColor: t.colors.background_variant
        }
      })
  }
}
