import React from "react"
import { AdvertisingBannerData } from "./types"
import AdvImage from "./AdvImage"
import styles from "./AdvBanner.styles"

export interface Props {
  banner: AdvertisingBannerData
}

const AdvBanner: React.FC<Props> = ({ banner }) => {
  return (
    <div className={styles.root}>
      <AdvImage image={banner.image} />
    </div>
  )
}

export default AdvBanner
