import React from "react"
import ProductAutocomplete, {
  ProductAutocompleteProps,
  AutocompleteItem
} from "@basset-la/components-products/dist/components/ProductAutocomplete"
import TextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { MobileDialogParams, TextFieldParams } from "@basset-la/components-commons/dist/components/Autocomplete"
import LocationIcon from "@material-ui/icons/LocationOnRounded"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./RegionAutocompleteField.styles"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props extends Pick<ProductAutocompleteProps, "getRegions" | "onSelectRegion"> {
  errorMessage?: string
  region: AutocompleteItem | null
  id?: string
}

const RegionAutocompleteField: React.FC<Props> = ({ getRegions, errorMessage, onSelectRegion, region, id }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleRenderInput = (params: TextFieldParams) => {
    return (
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("RegionAutocompleteField.label")}
        </Text>
        <TextField
          id={id}
          leftIcon={<LocationIcon className={styles.icon(theme)} />}
          error={!!errorMessage}
          helperText={errorMessage}
          {...params}
        />
      </div>
    )
  }

  const handleRenderMobileDialog = (params: MobileDialogParams) => {
    return (
      <FullScreenDialog
        open={params.open}
        onClose={params.onClose}
        toolbarComponent={
          <SearchboxTextField
            className={styles.toolbarTextField}
            value={params.inputValue}
            onChange={params.onInputChange}
            leftIcon={<LocationIcon className={styles.icon(theme)} />}
          />
        }
      >
        {params.children}
      </FullScreenDialog>
    )
  }

  return (
    <ProductAutocomplete
      region={region ? region : undefined}
      renderInput={handleRenderInput}
      renderMobileDialog={handleRenderMobileDialog}
      getRegions={getRegions}
      onSelectRegion={onSelectRegion}
    />
  )
}

export default RegionAutocompleteField
