import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { colors } from "../../utils/constants/constants"

export default {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),
  breakdownContainer: css({
    marginBottom: "16px"
  }),
  taxesIncluded: (t: Theme) =>
    css({
      marginTop: "8px",
      display: "flex",
      color: t.colors.text_primary,
      lineHeight: "1.33",
      fontSize: "12px",
      fontFamily: "Roboto"
    }),
  skeleton: css({
    "&.MuiSkeleton-root": {
      backgroundColor: colors.LIGHTEST_GREEN
    },
    borderRadius: "4px"
  })
}
