import React from "react"
import { useTranslation } from "react-i18next"

import { AllInclusiveIcon } from "../../icons/AllInclusiveIcon"
import { EnglishBreakfastIcon } from "../../icons/EnglishBreakfastIcon"
import { FreeBreakfastIcon } from "../../icons/FreeBreakfastIcon"
import { FreeDinnerIcon } from "../../icons/FreeDinnerIcon"
import { FullBreakfastIcon } from "../../icons/FullBreakfastIcon"

import { MealPlan as MealPlanType } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import styles from "./MealPlan.styles"
import { useTheme } from "@basset-la/themed-components"
import { FastFoodIcon } from "../../icons/FastFoodIcon"
import { UnlimitedServicesIcon } from "../../icons/UnlimitedServicesIcon"
import { OpenBarIcon } from "../../icons/OpenBarIcon"
import { FreeBrunchIcon } from "../../icons/FreeBrunchIcon"
import { FamilyPlanIcon } from "../../icons/FamilyPlanIcon"
import { DinnerAndLunchIcon } from "../../icons/DinnerAndLunchIcon"
import { CaribbeanBreakfastIcon } from "../../icons/CaribbeanBreakfastIcon"

export interface Props {
  mealPlan: MealPlanType
}

const mealPlanIcons: { [key in MealPlanType]: React.FC } = {
  FREE_BREAKFAST: FreeBreakfastIcon,
  BREAKFAST_FOR_1: FreeBreakfastIcon,
  BREAKFAST_FOR_2: FreeBreakfastIcon,
  CONTINENTAL_BREAKFAST: FreeBreakfastIcon,
  CONTINENTAL_BREAKFAST_FOR_2: FreeBreakfastIcon,
  HALF_BOARD: FreeBreakfastIcon,
  ALL_INCLUSIVE: AllInclusiveIcon,
  FULL_BREAKFAST: FullBreakfastIcon,
  BREAKFAST_BUFFET: FullBreakfastIcon,
  ENGLISH_BREAKFAST: EnglishBreakfastIcon,
  FREE_LUNCH: FreeDinnerIcon,
  FREE_DINNER: FreeDinnerIcon,
  ALL_MEALS: FreeDinnerIcon,
  FULL_BOARD: FreeDinnerIcon,
  FAST_FOOD: FastFoodIcon,
  OPEN_BAR: OpenBarIcon,
  UNLIMITED_SERVICES: UnlimitedServicesIcon,
  FREE_BRUNCH: FreeBrunchIcon,
  CARIBBEAN_BREAKFAST: CaribbeanBreakfastIcon,
  FAMILY_PLAN: FamilyPlanIcon,
  DINNER_AND_LUNCH: DinnerAndLunchIcon,
  ROOM_ONLY: () => <></>
}

const MealPlan: React.FunctionComponent<Props> = ({ mealPlan }) => {
  const { t } = useTranslation(I18N_NS)
  const description = t(`MealPlan.${mealPlan}`)
  const Icon = mealPlanIcons[mealPlan]
  const theme = useTheme()

  return (
    <div className={styles.root}>
      <Icon />
      <p id="mealplan-description" className={styles.description(theme)}>
        {description}
      </p>
    </div>
  )
}

export default MealPlan
