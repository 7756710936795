import React, { useEffect, useState } from "react"
import { BannerInfo } from "./types"
import styles from "./FullSizeBanner.styles"

const animationInterval = 6000

interface Props {
  images: BannerInfo[]
  onClick: (url: string) => void
}

const FullSizeBanner: React.FC<Props> = ({ images, onClick }) => {
  const [item, setItem] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      const i = item + 1
      setItem(i < images.length ? i : 0)
    }, animationInterval)
  }, [item, images.length])

  const handleClick = (url: string) => {
    if (!!url) {
      onClick(url)
    }
  }

  return (
    <div>
      {images.map((image, idx) => (
        <div key={idx} className={styles.container(idx !== item, image.link)} onClick={() => handleClick(image.link)}>
          <img className={styles.image} src={image.image_url} alt={image.alt} />
        </div>
      ))}
    </div>
  )
}

export default FullSizeBanner
