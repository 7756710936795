import { css } from "@emotion/css"

const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    " > p": {
      fontSize: "14px",
      lineHeight: "1.14",
      color: "#4a4a4a",
      marginBottom: "8px",
      fontWeight: 300
    },
    " > p:last-child": {
      marginBottom: 0
    }
  }),

  item: css({
    display: "flex",
    flexDireaction: "row",
    gap: "8px",
    alignItems: "center",
    marginBottom: "8px",
    fontSize: "14px",
    fontWeight: 500
  }),

  promotionName: css({
    color: "#ffffff",
    padding: "2px 4px",
    display: "inline-block",
    backgroundColor: "#A877D3",
    borderRadius: "4px",
    marginLeft: "5px",
    verticalAlign: "middle"
  })
}

export default styles
