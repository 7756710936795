import React, { useState } from "react"
import { AdvertisingFinancialData } from "./types"
import styles from "./AdvFinancial.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import Link from "@basset-la/components-commons/dist/components/Link"
import Card from "@basset-la/components-commons/dist/components/Card"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  price?: number
  currency?: string
  financial: AdvertisingFinancialData
}

const AdvFinancial: React.FC<Props> = ({ price, currency, financial }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const calculateInstallments = () => {
    const p = price || 0
    return (p + p * (financial.cft / 100)) / financial.installments
  }

  const handleLinkClick = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth={true} maxWidth="md">
        <DialogTitle>
          <div className={styles.dialogTitle}>
            {t("Advertising.financial.conditions")}
            <IconButton onClick={handleDialogClose}>
              <CloseIcon className={styles.closeIcon(theme)} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={styles.dialogContent} dangerouslySetInnerHTML={{ __html: financial.terms_and_conditions }} />
        </DialogContent>
      </Dialog>

      <Card variant="outlined" className={styles.root}>
        <Text className={styles.content} variant="light" size={10}>
          {t("Advertising.financial.title")}
        </Text>

        <div className={styles.content}>
          <img
            className={styles.image}
            src={`https://statics.basset.la/cards-logos/${financial.card_code}.svg`}
            alt={financial.card_code + financial.bank_code}
          />
        </div>

        {price && currency && (
          <>
            <Text variant="regular" size={10}>
              {financial.cft > 0
                ? t("Advertising.financial.installments", { installments: financial.installments })
                : t("Advertising.financial.installmentsNoCharge", { installments: financial.installments })}
            </Text>
            <Text className={styles.content} variant="bold" size={12}>
              {formatPrice(calculateInstallments(), locale, currency)}
            </Text>
          </>
        )}

        <Link
          TextProps={{ size: 12, variant: "bold" }}
          value={t("Advertising.financial.link")}
          onClick={handleLinkClick}
        />
      </Card>
    </>
  )
}

export default AdvFinancial
