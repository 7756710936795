import React from "react"

export const ErrorIcon: React.FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00004 0.333313C3.32004 0.333313 0.333374 3.31998 0.333374 6.99998C0.333374 10.68 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.68 13.6667 6.99998C13.6667 3.31998 10.68 0.333313 7.00004 0.333313ZM7.00004 7.66665C6.63337 7.66665 6.33337 7.36665 6.33337 6.99998V4.33331C6.33337 3.96665 6.63337 3.66665 7.00004 3.66665C7.36671 3.66665 7.66671 3.96665 7.66671 4.33331V6.99998C7.66671 7.36665 7.36671 7.66665 7.00004 7.66665ZM6.33337 8.99998V10.3333H7.66671V8.99998H6.33337Z"
      fill="#D0021B"
    />
  </svg>
)

export default ErrorIcon
