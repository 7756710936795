import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiSwitch-root": {
        "> .MuiSwitch-colorSecondary.Mui-checked": {
          color: t.colors.brand_primary
        },
        "> .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
          backgroundColor: t.colors.brand_variant
        }
      }
    })
}
