import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const FireIcon: React.FunctionComponent<Props> = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" {...props}>
      <g fill="none" fillRule="nonzero">
        <path d="M0 0h16v16H0z" />
        <path
          fill="#D0021B"
          d="M8.023 11.563c-.93 0-1.675-.735-1.675-1.638 0-.845.537-1.44 1.46-1.628.924-.187 1.879-.63 2.411-1.346.204.673.303 1.383.303 2.108a2.504 2.504 0 0 1-2.5 2.504zM8.957 2s.386 1.383.386 2.504c0 1.075-.705 1.946-1.78 1.946-1.08 0-1.893-.87-1.893-1.946l.015-.193A7.187 7.187 0 0 0 4 8.955a4.174 4.174 0 0 0 8.348 0c0-2.818-1.351-5.322-3.391-6.955z"
        />
      </g>
    </svg>
  )
}
