import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const LocalCafeIcon: React.FC<Props> = (props: Props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="ic_local_cafe">
      <path
        id="Shape"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3334 2H2.66671V8.66667C2.66671 10.14 3.86004 11.3333 5.33337 11.3333H9.33337C10.8067 11.3333 12 10.14 12 8.66667V6.66667H13.3334C14.0734 6.66667 14.6667 6.07333 14.6667 5.33333V3.33333C14.6667 2.59333 14.0734 2 13.3334 2ZM13.3334 5.33333H12V3.33333H13.3334V5.33333ZM1.33337 14H13.3334V12.6667H1.33337V14Z"
        fill="#1134D7"
      />
    </g>
  </svg>
)

export default LocalCafeIcon
