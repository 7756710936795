import { css } from "@emotion/css"

export default {
  radioGroup: css({
    padding: "16px"
  }),
  radio: css({
    paddingBottom: "8px"
  })
}
