import React, { useState, useEffect } from "react"
import { AutocompleteItem, AutocompleteItemType, OrderedAutocompleteItemType } from "./types"
import Autocomplete, {
  AutocompleteProps,
  AutocompleteGroupedOption
} from "@basset-la/components-commons/dist/components/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { useTheme } from "@basset-la/themed-components"
import styles from "./ProductAutocomplete.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AirportIcon from "@material-ui/icons/LocalAirportRounded"
import CityIcon from "@material-ui/icons/LocationCityRounded"
import AccommodationIcon from "@material-ui/icons/LocalHotelRounded"
import PlaceIcon from "@material-ui/icons/Place"

export interface Props extends Pick<AutocompleteProps, "renderInput" | "placeholder" | "renderMobileDialog"> {
  getRegions: (value: string) => Promise<AutocompleteItem[]>
  onSelectRegion: (region: AutocompleteItem) => void
  renderOptionHeader?: () => React.ReactNode
  renderOption?: (region: AutocompleteItem) => React.ReactNode
  region?: AutocompleteItem
}

type AutocompleteItemEx = AutocompleteItem | { type: "helper_text" }

type AutocompleteItems = Array<AutocompleteItemEx>

const groupByRegionType = (array: AutocompleteItems): Record<AutocompleteItemType, AutocompleteItemEx[]> => {
  return array.reduce((acc, item: AutocompleteItem) => {
    const { prioritized } = item
    if (prioritized) {
      if (!acc.favorite) {
        acc.favorite = []
      }
      acc.favorite.push(item)
      return acc
    }
    const { type: key } = item
    if (acc[key] === undefined) {
      acc[key] = []
    }

    acc[key].push(item)

    return acc
  }, {} as Record<AutocompleteItemType, AutocompleteItemEx[]>)
}

const ProductAutocomplete: React.FC<Props> = ({
  renderInput,
  renderOptionHeader,
  renderOption,
  onSelectRegion,
  placeholder,
  getRegions,
  region,
  renderMobileDialog
}) => {
  const [regions, setRegions] = useState<AutocompleteItems>([])
  const [inputValue, setInputValue] = useState<string>("")
  const [loadingRegion, setLoadingRegion] = useState<boolean>(false)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (region) {
      setInputValue(region.name)
    } else {
      setInputValue("")
    }
  }, [region])

  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const mapRegionsToGroupedOptions = (regions: AutocompleteItems): AutocompleteGroupedOption[] => {
    if (regions.length === 1 && regions[0].type === "helper_text") {
      return [{ label: "helper_text", options: [], type: "helper_text" }]
    }

    let opts: AutocompleteGroupedOption[] = []
    const grouped = groupByRegionType(regions)

    for (let key of OrderedAutocompleteItemType) {
      if (!grouped[key]) continue
      opts.push({
        label: key,
        type: key,
        options: grouped[key]
      })
    }

    return opts
  }

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value
    setInputValue(value)

    if (timer != null) {
      clearTimeout(timer!)
      setTimer(null)
    }

    if (value.length >= 3) {
      /** esto se puede mejorar usando el customHook useDebounce */
      const t = setTimeout(() => {
        fetchRegions(value)
      }, 1500)
      setTimer(t as any)
      /** ------------------------------------  */
      setLoadingRegion(true)
    } else {
      setLoadingRegion(false)
      setRegions([
        {
          type: "helper_text"
        }
      ])
    }
  }

  const fetchRegions = async (value: string) => {
    const resp = await getRegions(value)
    setLoadingRegion(false)
    setRegions(resp)
  }

  const handleOnSelectRegion = (region: AutocompleteItem) => {
    setInputValue(region.name)
    onSelectRegion(region)
  }

  const defaultRenderOption = (region: AutocompleteItem) => {
    const matches = match(region.name, inputValue)
    const parts = parse(region.name, matches)

    return (
      <div>
        {parts.map((part, index) => (
          <span key={index} className={styles.part(theme, part.highlight)}>
            {part.text}
          </span>
        ))}
      </div>
    )
  }

  const renderOptionTitle = (type: string) => {
    switch (type) {
      case "multi_city_vicinity":
      case "favorite":
        return
      case "helper_text":
        return <span className={styles.helperText(theme)}>{t("ProductAutocomplete.helper_text")}</span>
      default:
        return (
          <div className={styles.optionTitle(theme)}>
            {getItemTypeIcon(type)}
            <span>{t(`ProductAutocomplete.types.${type}`)}</span>
          </div>
        )
    }
  }

  const getItemTypeIcon = (type: string) => {
    switch (type) {
      case "airline":
      case "AIRPORT":
        return <AirportIcon />
      case "CITY":
      case "city":
      case "neighborhood":
        return <CityIcon />
      case "accommodation":
        return <AccommodationIcon />
      case "google_place":
        return <PlaceIcon />
    }
    return
  }

  return (
    <Autocomplete
      groupedOptions={mapRegionsToGroupedOptions(regions)}
      inputValue={inputValue}
      onInputChange={handleOnInputChange}
      renderInput={renderInput}
      onSelectOption={handleOnSelectRegion}
      placeholder={placeholder}
      renderOptionHeader={renderOptionHeader}
      renderOption={renderOption || defaultRenderOption}
      renderOptionTitle={renderOptionTitle}
      fetchingData={loadingRegion}
      renderMobileDialog={renderMobileDialog}
    />
  )
}

export default ProductAutocomplete
