import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./MapLeftColumn.style"
import POISection from "./POISection"
import { colors, I18N_NS } from "../../utils/constants/constants"
import { Region } from "../../model/accommodationDetail"
import { useTheme } from "@basset-la/themed-components"

export type Section = {
  items: Region[]
  onClick: () => void
  selected: boolean
}

interface Props {
  pois?: Section
  trainStations?: Section
  subwayStations?: Section
  airports?: Section
}

const MapLeftColumn: React.FC<Props> = ({ trainStations, pois, airports, subwayStations }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div>
      <p className={styles.subTitle(theme)}>Seleccione las listas que desea visualizar en el mapa</p>
      <div>
        {pois && pois.items.length > 0 && (
          <POISection
            title={t("MapLeftColumn.points_of_interest")}
            onClick={pois.onClick}
            checkColor={colors.RED}
            pois={pois.items}
            checked={pois.selected}
          />
        )}
        {trainStations && trainStations.items.length > 0 && (
          <POISection
            title={t("MapLeftColumn.train_stations")}
            onClick={trainStations.onClick}
            checkColor={colors.YELLOW}
            pois={trainStations.items}
            checked={trainStations.selected}
          />
        )}
        {subwayStations && subwayStations.items.length > 0 && (
          <POISection
            title={t("MapLeftColumn.subway_stations")}
            onClick={subwayStations.onClick}
            checkColor={colors.LIGHT_BLUE}
            pois={subwayStations.items}
            checked={subwayStations.selected}
          />
        )}
        {airports && airports.items.length && (
          <POISection
            title={t("MapLeftColumn.airports")}
            onClick={airports.onClick}
            checkColor={colors.LIGHT_GREEN}
            pois={airports.items}
            checked={airports.selected}
          />
        )}
      </div>
    </div>
  )
}

export default MapLeftColumn
