import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "column",
      margin: "0 24px",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto"
    }),
  header: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16 0px",
      fontSize: "12px",
      lineHeight: 2,
      color: t.colors.text_variant,
      backgroundColor: t.colors.background_primary
    }),
  headerLogo: css({
    flex: 1,
    marginRight: "24px",
    "&>img": {
      maxHeight: "48px"
    }
  }),
  divider: (t: Theme) =>
    css({
      width: "calc(100% - 8px)"
    }),
  tittle: css({
    fontSize: "16px",
    marginLeft: "8px",
    marginRight: "70px"
  }),
  titular: css({
    fontSize: "16px",
    margin: "8px"
  }),
  total: css({
    marginRight: "0px",
    display: "flex",
    justifyContent: "flex-end",
    fontSize: "24px",
    margin: "4px"
  }),
  currency: css({
    fontSize: "14px",
    margin: "4px",
    marginBottom: "0px"
  }),
  smallLetters: css({
    marginRight: "70px",
    fontSize: "14px"
  }),
  row: css({
    marginLeft: "8px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  rowMain: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  startBox: css({
    padding: "8px",
    width: "auto",
    background: "#EAEAEA",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    boxSizing: "border-box",
    borderRadius: "4px"
  }),
  col: css({
    display: "flex",
    flexDirection: "column",
    justifyItems: "center"
  }),
  headerComponent: css({
    marginLeft: "auto",
    display: "flex",
    alignItems: "center"
  }),
  text: css({
    color: `#1134D7 !important`
  }),
  icon: css({
    "&.MuiSvgIcon-root": {
      color: "#1134D7",
      fontSize: "16px",
      width: "16px",
      height: "16px"
    }
  }),

  providerID: css({
    marginLeft: "8px"
  }),

  showIcon: css({
    marginLeft: "8px",
    "&.MuiSvgIcon-root": {
      color: "#4a4a4a"
    }
  }),

  pnr: css({
    marginLeft: "32px"
  }),
  boxes: css({
    display: "flex",
    "& > div": {
      textTransform: "uppercase",
      border: "1px solid #e9eff4",
      borderRadius: "3px",
      backgroundColor: "white",
      height: "62px",
      marginRight: "16px",
      whiteSpace: "nowrap",
      alignItems: "center",
      display: "flex",
      "& > div": {
        padding: "16px",
        fontSize: "12px"
      }
    },
    "& > div:last-child": {
      flexGrow: 1,
      marginRight: 0
    }
  }),
  notExpanded: css({
    width: "0px",
    transition: "width 1s",
    overflow: "hidden",
    marginRight: "0px !important",
    paddingRight: "0px !important"
  }),
  expanded: css({
    width: "200px",
    transition: "width 1s",
    overflow: "hidden"
  }),

  seeRemarks: css({
    marginRight: "8px !important",
    cursor: "pointer",
    alignItems: "center",
    display: "inline-flex"
  }),

  infoAboutDates: css({
    display: "flex"
  }),

  expirationDate: css({
    borderLeft: "1px solid #e9eff4"
  }),

  agentArrow: css({
    marginRight: "8px !important",
    paddingRight: "8px !important"
  }),

  setAvatar: css({
    marginRight: "0px !important",
    paddingRight: "0px !important",
    "& > div ": {
      width: "32px",
      height: "32px"
    }
  }),

  sendPaymentStyle: css({
    borderRadius: "4px",
    border: "1px solid #126aff",
    "& > a ": {
      color: "#126aff !important"
    },
    padding: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  }),

  actionButtons: css({
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end"
  }),

  alert: css({
    display: "flex",
    flexDirection: "row",
    padding: "8px 16px",
    gap: "16px",
    backgroundColor: "rgba(253, 180, 60, 0.15)",
    border: "1px solid #FDB43C",
    borderRadius: "4px",
    marginTop: "16px",
    marginBottom: "16px",
    height: "24px"
  }),

  alertIcon: css({
    height: "16px",
    fill: "#FDB43C",
    width: "16px",
    marginRight: "15px"
  })
}
