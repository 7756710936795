import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const WifiIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...rest}>
    <defs>
      <rect id="b" width="688" height="200" rx="4" />
      <filter id="a" width="101.2%" height="105%" x="-.6%" y="-2%" filterUnits="objectBoundingBox">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
        />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="1" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        d="M12 17.6a.8.8 0 1 0 0 1.6.8.8 0 0 0 0-1.6M5.06 8.21a.801.801 0 0 0 1.08 1.18c3.177-2.903 8.543-2.903 11.72 0a.801.801 0 0 0 1.08-1.18c-3.826-3.497-10.053-3.497-13.88 0m2.382 3.217a.8.8 0 1 0 1.117 1.146c1.865-1.818 5.017-1.818 6.883 0a.798.798 0 0 0 1.131-.015.8.8 0 0 0-.014-1.13c-2.514-2.45-6.604-2.45-9.117 0m1.654 3.151a.799.799 0 1 0 1.008 1.242c1.028-.835 2.764-.835 3.792 0a.796.796 0 0 0 1.125-.117.799.799 0 0 0-.117-1.125c-1.6-1.3-4.208-1.3-5.808 0"
      />
      <path d="M0 0h24v24H0z" />
    </g>
  </svg>
)
