import React from "react"
import { Airline } from "../../model/types"
import CheckboxFilter from "@basset-la/components-products/dist/components/CheckboxFilter"
import AirlineLogo from "../AirlineLogo"
import styles from "./AirlineFilter.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import FlightsFiltersCollapsePanel from "../FlightsFiltersCollapsePanel"
import Button from "@material-ui/core/Button"

export interface Props {
  collapsible?: boolean
  options: Option[]
  onChange: (value: string, checked: boolean) => void
  onClick: () => void
  onDone?: () => void
}

export type Option = {
  airline: Airline
  selected?: boolean
}

const AirlineFilter: React.FC<Props> = ({ options, collapsible, onChange, onClick, onDone }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root}>
      {!collapsible ? (
        <>
          <CheckboxFilter
            title={t("AirlineFilter.title")}
            options={mapToCheckboxFilterOptions(options)}
            maxItems={options.length}
            onChange={onChange}
            onDone={onDone}
            id={{
              checkbox: "airline-filter-checkbox"
            }}
          />
        </>
      ) : (
        <>
          <FlightsFiltersCollapsePanel
            renderSummary={<>{t("AirlineFilter.title")}</>}
            renderDetails={
              <div className={styles.container}>
                <CheckboxFilter
                  options={mapToCheckboxFilterOptions(options)}
                  maxItems={options.length}
                  onChange={onChange}
                  onDone={onDone}
                  id={{
                    checkbox: "airline-filter-checkbox"
                  }}
                />
              </div>
            }
          />
        </>
      )}
      <Button id="airline-filter-apply-btn" variant="text" onClick={onClick} className={styles.button()}>
        {t("AirlineFilter.applyFilter")}
      </Button>
    </div>
  )
}

const mapToCheckboxFilterOptions = (options: Option[]) => {
  return options.map((e, i) => ({
    title: e.airline.name,
    value: e.airline.code,
    icon: <AirlineLogo airlineCode={e.airline.code} />,
    selected: e.selected
  }))
}

export default AirlineFilter
