import React from "react"

import MUIPagination from "@material-ui/lab/Pagination"
import styles from "./Pagination.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  page: number
  onChange: (page: number) => void
  size?: "small" | "medium" | "large"
  count: number
}

const Pagination: React.FC<Props> = ({ page, onChange, size, count }) => {
  const theme = useTheme()

  const handleOnChange = (_e: unknown, page: number) => {
    onChange(page)
  }

  return (
    <MUIPagination size={size} className={styles.root(theme)} page={page} onChange={handleOnChange} count={count} />
  )
}

export default Pagination
