import IconButton from "@material-ui/core/IconButton"
import * as React from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import { useTheme } from "@basset-la/themed-components"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import RoomDescription from "./RoomDescription"
import currency from "currency.js"
import moment from "moment"
import { I18N_NS } from "../../utils/constants"
import styles from "./RoomCluster.styles"
import { RoomClusterRate, RoomClusterRoom } from "../../model"
import RateDetail from "../RateDetail"

interface Props {
  rooms: RoomClusterRoom[]
  rates: RoomClusterRate[]
  clusterIndex: number
  onSelect: (ci: number, ri: number) => void
}

const RoomCluster: React.FC<Props> = ({ rooms, rates, clusterIndex, onSelect }: Props) => {
  const { i18n } = useTranslation(I18N_NS)
  const theme = useTheme()
  const locale = getLocale(i18n)
  const roomMap = {}

  rooms.forEach(r => {
    const key = `${r.id}:${r.name}`

    if (!roomMap[key]) {
      roomMap[key] = [r]
    } else {
      roomMap[key].push(r)
    }
  })

  const roomsToRender = []

  for (let key in roomMap) {
    const room = roomMap[key][0]
    roomsToRender.push(
      <RoomDescription
        key={key}
        amount={roomMap[key].length}
        beds={room.bed_configuration}
        capacity={room.capacity}
        name={room.name}
      />
    )
  }

  const getNightlyBasisPrice = (rateIndex: number): number => {
    const nightlyBasis = rates[rateIndex].fare?.nightly_basis || 0
    const charges = rates[rateIndex].fare?.charges ? rates[rateIndex].fare!.charges! : []
    let commission = currency(0)
    let markup = currency(0)

    const nights = moment(rates[rateIndex].checkout).diff(moment(rates[rateIndex].checkin), "days")

    for (const c of charges) {
      if ((c.collector === "PROVIDER" || c.collector === "AGENCY") && c.type === "commission") {
        commission = currency(c.amount).divide(nights)
      }
      if (c.collector === "AGENCY" && c.type === "markup") {
        markup = currency(c.amount).divide(nights)
      }
    }

    return currency(nightlyBasis)
      .add(commission)
      .add(markup).value
  }

  const roomSelectedIndex = rates.findIndex(r => r.selected)

  return (
    <div
      id={`accommodation-room-${clusterIndex}`}
      data-selected={roomSelectedIndex > -1}
      className={styles.containerStyle}
    >
      <div className={styles.roomsDescriptionContainerStyle}>{roomsToRender}</div>
      <div id="accommodation-room-options" className={styles.ratesContainerStyle}>
        {rates.map(
          (e, i) =>
            e.fare && (
              <div
                id="accommodation-room-option"
                data-selected={e.selected}
                key={i}
                className={styles.rateContainerStyle(e.selected || false, rates.length)}
                onClick={() => onSelect(clusterIndex, i)}
              >
                <div className={styles.rateDetailContainer}>
                  <RateDetail rate={e} />
                </div>
                <div className={styles.priceContainerStyle}>
                  <p>
                    <span id="accommodation-room-price-per-night">
                      {formatPrice(getNightlyBasisPrice(i), locale, e.fare.currency)}
                    </span>
                  </p>
                </div>
                <div className={styles.buttonContainerStyle}>
                  <IconButton id="accommodation-room-checkbox" data-room-index={clusterIndex}>
                    {e.selected && (
                      <CheckCircleIcon
                        style={{ fontSize: "24px" }}
                        className={styles.icon(theme.brand_colors.click_color)}
                      />
                    )}
                    {!e.selected && (
                      <CheckCircleOutlineIcon
                        style={{ fontSize: "24px" }}
                        className={styles.icon("rgba(0, 0, 0, 0.54)")}
                      />
                    )}
                  </IconButton>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  )
}

export default RoomCluster
