import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    fontFamily: "Roboto",
    flexDirection: "column",
    marginBottom: "8px",
    ":last-child": {
      marginBottom: "0px"
    }
  }),
  title: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  arrow: css({
    width: "16px",
    height: "16px"
  }),
  titleContainer: css({
    display: "flex",
    margin: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    alignItems: "center",
    paddingRight: "8px"
  }),
  childrenContainer: css({
    padding: "8px 0px 8px 8px",
    ":last-child": {
      paddingBottom: 0
    },
    "> *": {
      marginBottom: "8px",
      ":last-child": {
        marginBottom: 0
      }
    }
  }),
  value: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  })
}
