import { css } from "@emotion/css"

export const switchStyle = (color: string) => {
  return {
    checkedStyle: css({
      color: `${color} !important`
    }),
    barStyle: css({
      backgroundColor: `${color} !important`
    })
  }
}
