import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "2px",
    backgroundColor: colors.GREEN,
    height: "24px",
    boxSizing: "content-box",
    padding: "0px 4px",
    width: "fit-content"
  }),
  description: (theme: Theme) =>
    css({
      margin: 0,
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: 2,
      color: theme.colors.text_white,
      marginLeft: "4px",
      "@media screen and (max-width: 1250px)": {
        lineHeight: 1
      }
    })
}
