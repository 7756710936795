import React from "react"
import { useTheme } from "@basset-la/themed-components"
import { BaggageIcon } from "../../icons/BaggageIcon"
import { BaggageIconNotFounded } from "../../icons/BaggageIconNotFounded"
import { BagIcon } from "../../icons/BagIcon"
import { BagIconNotFounded } from "../../icons/BagIconNotFounded"
import { CarryOnIcon } from "../../icons/CarryOnIcon"
import { CarryOnIconNotFounded } from "../../icons/CarryOnIconNotFounded"
import styles from "./Baggages.styles"

export interface Props {
  carryOnIncluded?: number
  bagIncluded?: number
  baggageAmount?: number
}

const Baggages: React.FunctionComponent<Props> = ({ carryOnIncluded, bagIncluded, baggageAmount }) => {
  const theme = useTheme()

  const availabilityStatus = (partialId: string, state: number | undefined): string => {
    const availability = state ? "available" : "not-available"

    return `${partialId}-${availability}`
  }

  return (
    <div id="baggages-container" className={styles.root}>
      {bagIncluded !== undefined && (
        <BagIcon
          color={bagIncluded ? theme.colors.brand_primary : theme.colors.text_variant}
          id={availabilityStatus("baggages-personal", bagIncluded)}
        />
      )}

      {bagIncluded === undefined && (
        <BagIconNotFounded color={theme.colors.text_variant} id="baggages-personal-pending-verification" />
      )}

      {carryOnIncluded !== undefined && (
        <CarryOnIcon
          color={carryOnIncluded ? theme.colors.brand_primary : theme.colors.text_variant}
          id={availabilityStatus("baggages-carryon", carryOnIncluded)}
        />
      )}

      {carryOnIncluded === undefined && (
        <CarryOnIconNotFounded color={theme.colors.text_variant} id="baggages-carryon-pending-verification" />
      )}

      {baggageAmount !== undefined && (
        <BaggageIcon
          color={baggageAmount ? theme.colors.brand_primary : theme.colors.text_variant}
          id={availabilityStatus("baggages-bag", baggageAmount)}
        />
      )}

      {baggageAmount === undefined && (
        <BaggageIconNotFounded color={theme.colors.text_variant} id="baggages-bag-pending-verification" />
      )}
    </div>
  )
}

export default Baggages
