import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const PoolIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.5 9H6a.5.5 0 1 0 0-1H4.5a.5.5 0 1 0 0 1zM9 12h6v-1H9v1zm0-2h6V9H9v1zm0 3.5V13h6v.5a.5.5 0 1 0 1 0V6a1.001 1.001 0 0 1 2 0 .5.5 0 1 0 1 0c0-1.103-.897-2-2-2s-2 .897-2 2v2H9V6a1.001 1.001 0 0 1 2 0 .5.5 0 1 0 1 0c0-1.103-.897-2-2-2s-2 .897-2 2v7.5a.5.5 0 1 0 1 0zm10.5 1c-.86 0-1.307.537-1.635.93-.312.375-.493.57-.867.57s-.554-.195-.867-.57c-.327-.393-.775-.93-1.635-.93s-1.307.537-1.634.93c-.312.375-.492.57-.866.57-.373 0-.552-.195-.864-.57-.327-.393-.775-.93-1.634-.93-.859 0-1.306.537-1.633.93-.313.375-.493.57-.866.57s-.553-.195-.865-.57c-.327-.393-.775-.93-1.634-.93a.5.5 0 1 0 0 1c.373 0 .553.195.865.57.328.393.775.93 1.634.93.86 0 1.307-.537 1.634-.93.312-.375.492-.57.865-.57s.553.195.865.57c.327.393.774.93 1.633.93.86 0 1.307-.537 1.635-.93.312-.375.492-.57.865-.57.374 0 .555.195.868.57.327.393.775.93 1.634.93.86 0 1.308-.537 1.635-.93.313-.375.493-.57.867-.57a.5.5 0 1 0 0-1zm0 3c-.86 0-1.307.537-1.635.93-.312.375-.493.57-.867.57s-.554-.195-.867-.57c-.327-.393-.775-.93-1.635-.93s-1.307.537-1.634.93c-.312.375-.492.57-.866.57-.373 0-.552-.195-.864-.57-.327-.393-.775-.93-1.634-.93-.859 0-1.306.537-1.633.93-.313.375-.493.57-.866.57s-.553-.195-.865-.57c-.327-.393-.775-.93-1.634-.93a.5.5 0 1 0 0 1c.373 0 .553.195.865.57.328.393.775.93 1.634.93.86 0 1.307-.537 1.634-.93.312-.375.492-.57.865-.57s.553.195.865.57c.327.393.774.93 1.633.93.86 0 1.307-.537 1.635-.93.312-.375.492-.57.865-.57.374 0 .555.195.868.57.327.393.775.93 1.634.93.86 0 1.308-.537 1.635-.93.313-.375.493-.57.867-.57a.5.5 0 1 0 0-1zm-2-9a.5.5 0 0 0 .5.5h1.5a.5.5 0 1 0 0-1H18a.5.5 0 0 0-.5.5z"
    />
  </svg>
)
