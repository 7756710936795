import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { darken } from "@material-ui/core/styles"

export default {
  container: css({
    width: "fit-content",
    height: "fit-content",
    position: "relative"
  }),
  fullWidth: css({
    width: "100% !important"
  }),
  button: css({
    "&.MuiButton-root": {
      textTransform: "capitalize",
      transition: "background-color .3s linear",
      minHeight: "40px",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: 1.5
    }
  }),
  rectangular: css({
    "&.MuiButton-root": {
      borderRadius: "4px"
    }
  }),
  rounded: css({
    "&.MuiButton-root": {
      borderRadius: "24px"
    }
  }),
  text: (theme: Theme) =>
    css({
      "&.MuiButton-text": {
        color: theme.colors.brand_primary,
        minWidth: "160px"
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.colors.text_variant
      }
    }),
  outlined: (theme: Theme) =>
    css({
      "&.MuiButton-outlined": {
        color: theme.colors.brand_primary,
        borderColor: theme.colors.brand_primary,
        minWidth: "160px"
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.colors.text_variant
      }
    }),
  contained: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      },
      "&.MuiButton-contained": {
        color: theme.colors.text_white,
        backgroundColor: theme.colors.brand_primary,
        minWidth: "160px"
      },
      "&.Mui-disabled": {
        cursor: "not-allowed",
        color: theme.colors.text_variant,
        backgroundColor: `${theme.colors.background_darker} !important`
      }
    }),
  success: (theme: Theme) =>
    css({
      "&.MuiButton-text": {
        color: `${theme.colors.success} !important`
      },
      "&.MuiButton-contained": {
        color: `${theme.colors.text_white} !important`,
        backgroundColor: `${theme.colors.success} !important`,
        borderColor: `${theme.colors.success} !important`
      },
      "&.MuiButton-outlined": {
        color: `${theme.colors.success} !important`,
        borderColor: `${theme.colors.success} !important`
      },
      "&.MuiButton-text:hover, &.MuiButton-contained:hover, &.MuiButton-outlined:hover": {
        color: `${theme.colors.text_white} !important`,
        backgroundColor: `${darken(theme.colors.success, 0.2)} !important`
      }
    }),
  loading: (theme: Theme) =>
    css({
      "&.MuiCircularProgress-root": {
        color: theme.colors.success,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
      }
    })
}
