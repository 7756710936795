import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 300,
      lineHeight: "1.33",
      letterSpacing: "0.13px",
      color: t.colors.text_primary,
      backgroundColor: t.colors.background_primary
    }),
  title: css({
    margin: 0,
    marginBottom: "4px",
    textTransform: "uppercase"
  }),
  segmentOption: css({
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: 0
    }
  })
}
