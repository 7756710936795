import React from "react"
import Popover from "@material-ui/core/Popover"
import styles from "./PoiMarker.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  backgroundColor: string
  name: string
  lat: number
  lng: number
}

const PoiMarker: React.FunctionComponent<Props> = ({ backgroundColor, name }) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const theme = useTheme()

  function handleOpenPopover(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleClosePopover() {
    setAnchorEl(null)
  }

  return (
    <div>
      <div style={{ backgroundColor: backgroundColor }} onClick={handleOpenPopover} className={styles.root} />
      <Popover
        open={Boolean(anchorEl)}
        onClose={handleClosePopover}
        anchorEl={anchorEl as HTMLElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <p className={styles.popover(theme)}>{name}</p>
      </Popover>
    </div>
  )
}

export default PoiMarker
