import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    width: "16px",
    height: "16px",
    cursor: "pointer",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)"
  }),
  popover: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 300,
      textAlign: "center",
      fontSize: "12px",
      padding: "8px"
    })
}
