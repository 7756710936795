import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import styles from "./ClusterDesktopPriceBreakdown.styles"
import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"
import { Policy } from "../../model"

export type PassengerPrice = {
  quantity: number
  total: number
  policies?: PassengerPolicies
}

export interface PassengerPolicies {
  exchanges_policies?: Policy[]
  refund_policies?: Policy[]
}

export interface Props {
  adults?: PassengerPrice
  children?: PassengerPrice
  infants?: PassengerPrice
  disabled?: PassengerPrice
  senior?: PassengerPrice
  taxes: number
  charges: number
  total: number
  currency: string
}

const ClusterDesktopPriceBreakdown: React.FC<Props> = ({
  adults,
  children,
  infants,
  disabled,
  senior,
  taxes,
  charges,
  total,
  currency
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      {adults && adults.quantity > 0 && (
        <div className={styles.breakdownRow}>
          <span>{t("ClusterDesktopPriceBreakdown.adult", { count: adults.quantity })}</span>
          <span>{formatPrice(adults.total, locale, currency)}</span>
        </div>
      )}
      {disabled && disabled.quantity > 0 && (
        <div className={styles.breakdownRow}>
          <span>{t("ClusterDesktopPriceBreakdown.disabled", { count: disabled.quantity })}</span>
          <span>{formatPrice(disabled.total, locale, currency)}</span>
        </div>
      )}
      {senior && senior.quantity > 0 && (
        <div className={styles.breakdownRow}>
          <span>{t("ClusterDesktopPriceBreakdown.senior", { count: senior.quantity })}</span>
          <span>{formatPrice(senior.total, locale, currency)}</span>
        </div>
      )}
      {children && children.quantity > 0 && (
        <div className={styles.breakdownRow}>
          <span>{t("ClusterDesktopPriceBreakdown.child", { count: children.quantity })}</span>
          <span>{formatPrice(children.total, locale, currency)}</span>
        </div>
      )}
      {infants && infants.quantity > 0 && (
        <div className={styles.breakdownRow}>
          <span>{t("ClusterDesktopPriceBreakdown.infant", { count: infants.quantity })}</span>
          <span>{formatPrice(infants.total, locale, currency)}</span>
        </div>
      )}
      <div className={styles.breakdownRow}>
        <span>{t("ClusterDesktopPriceBreakdown.charges")}</span>
        <span>{formatPrice(charges, locale, currency)}</span>
      </div>
      <div className={styles.breakdownRow}>
        <span>{t("ClusterDesktopPriceBreakdown.taxes")}</span>
        <span>{formatPrice(taxes, locale, currency)}</span>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.total}>
        <span>{t("ClusterDesktopPriceBreakdown.total")}</span>
        <span>{formatPrice(total, locale, currency)}</span>
      </div>
    </div>
  )
}

export default ClusterDesktopPriceBreakdown
