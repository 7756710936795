import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  group: css({
    "&.MuiButtonGroup-root": {
      height: "40px"
    }
  }),
  button: (t: Theme) =>
    css({
      "&.MuiButton-outlinedPrimary": {
        backgroundColor: t.colors.background_variant,
        color: t.colors.text_variant,
        border: `solid 1px ${t.colors.background_darker}`
      },
      "&.MuiButton-outlinedPrimary:hover": {
        backgroundColor: t.colors.brand_primary,
        color: t.colors.text_white,
        border: `solid 1px ${t.colors.brand_primary}`
      },
      "& .MuiButton-label": {
        fontWeight: 300
      }
    }),
  selected: (t: Theme) =>
    css({
      "&.MuiButton-outlinedPrimary": {
        backgroundColor: `${t.colors.brand_primary} !important`,
        color: `${t.colors.text_white} !important`
      }
    })
}
