import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      padding: "24px",
      backgroundColor: t.colors.background_darker,
      borderRadius: "0px 0px 4px 4px",
      gap: "16px"
    }),
  showMore: (t: Theme) =>
    css({
      textAlign: "center",
      margin: "8px 0",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
      fontFamily: "Roboto",
      fontSize: "16px",
      "@media (max-width: 1366px)": {
        fontSize: "14px"
      },
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.17px",
      color: t.colors.brand_primary
    })
}

export default styles
