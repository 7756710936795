import React from "react"

const CombinedProductsIcon = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 102 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3529 16.3529C35.4874 -2.78431 66.5126 -2.78431 85.6498 16.3529C104.784 35.4874 104.784 66.5126 85.6498 85.6498C66.5126 104.784 35.4874 104.784 16.3529 85.6498C-2.78431 66.5126 -2.78431 35.4874 16.3529 16.3529"
        fill="#00A5F4"
      />
      <path
        d="M45.1851 41.027L40.4547 46.0451C37.6515 48.9503 33.0964 49.0383 30.2056 46.3092C27.3148 43.492 27.2272 38.9141 29.9428 36.0089L34.6731 30.9909C37.4763 28.0857 42.0315 27.9977 44.9223 30.7268C47.8131 33.5439 47.9007 38.1218 45.1851 41.027ZM32.2204 38.2098C30.6436 39.8825 30.7312 42.4356 32.308 44.0202C33.9723 45.6049 36.5127 45.5168 38.0895 43.9322L42.8199 38.9141C44.3967 37.2414 44.3091 34.6884 42.7323 33.1037C41.0679 31.5191 38.5275 31.6071 36.9507 33.1918L32.2204 38.2098Z"
        fill="#999999"
      />
      <path
        d="M81.9772 52.2078L53.0694 82.5802C49.4778 86.3658 43.4334 86.4538 39.6666 82.8443L23.4607 67.2619C19.6939 63.6525 19.6063 57.578 23.1979 53.7924L52.1058 23.4199C55.6973 19.6344 61.7417 19.5464 65.5085 23.1558L81.7144 38.7382C85.4812 42.3477 85.5688 48.4222 81.9772 52.2078Z"
        fill="#E6E8E9"
      />
      <path d="M26.2759 50.644L25.0654 51.917L54.896 80.5652L56.1064 79.2922L26.2759 50.644Z" fill="#999999" />
      <path d="M49.7223 26.0919L48.5117 27.3645L78.334 56.0168L79.5446 54.7442L49.7223 26.0919Z" fill="#999999" />
      <mask id="mask0_514_705" maskUnits="userSpaceOnUse" x="20" y="20" width="98" height="98">
        <path
          d="M81.9772 52.2078L53.0694 82.5802C49.4778 86.3658 43.4334 86.4538 39.6666 82.8443L23.4607 67.2619C19.6939 63.6525 19.6063 57.578 23.1979 53.7924L52.1058 23.4199C55.6973 19.6344 61.7417 19.5464 65.5085 23.1558L81.7144 38.7382C85.4812 42.3477 85.5688 48.4222 81.9772 52.2078Z"
          fill="#E6E8E9"
        />
      </mask>
      <g mask="url(#mask0_514_705)">
        <path
          d="M25.1251 56.1693C27.7531 58.7224 27.8407 62.8601 25.3003 65.5011C22.7599 68.1422 18.6428 68.2303 16.0148 65.6772C13.3868 63.1242 13.2992 58.9865 15.8396 56.3454C18.2924 53.7923 22.4972 53.7043 25.1251 56.1693Z"
          fill="#999999"
        />
        <path
          d="M50.5289 80.6434C53.1569 83.1965 53.2445 87.3342 50.7041 89.9753C48.1638 92.6163 44.0466 92.7044 41.4186 90.1513C38.7906 87.5983 38.703 83.4606 41.2434 80.8195C43.7838 78.2665 47.901 78.1784 50.5289 80.6434Z"
          fill="#999999"
        />
        <path
          d="M63.4938 15.8488C66.1218 18.4019 66.2094 22.5396 63.669 25.1806C61.1286 27.8217 57.0114 27.9098 54.3834 25.3567C51.7554 22.8037 51.6679 18.666 54.2082 16.0249C56.7486 13.3838 60.8658 13.2958 63.4938 15.8488Z"
          fill="#999999"
        />
        <path
          d="M88.985 40.3229C91.613 42.876 91.7006 47.0137 89.1602 49.6548C86.6198 52.2958 82.5026 52.3839 79.8746 49.8308C77.2467 47.2778 77.1591 43.1401 79.6995 40.499C82.1522 37.8579 86.357 37.7699 88.985 40.3229Z"
          fill="#999999"
        />
      </g>
      <path
        d="M55.1719 39.7066C56.7486 41.2032 56.8362 43.7563 55.2595 45.3409C53.7703 46.9256 51.2299 47.0136 49.6531 45.429C48.0763 43.9323 47.9887 41.3793 49.5655 39.7946C51.0547 38.298 53.5951 38.21 55.1719 39.7066Z"
        fill="#4B85C4"
      />
      <path
        d="M49.5651 39.8827C51.0543 38.298 53.5947 38.21 55.1715 39.7946C56.7483 41.2912 56.8359 43.8443 55.2591 45.4289C53.7699 47.0136 51.2295 47.1016 49.6527 45.517C48.1636 43.9323 48.076 41.4673 49.5651 39.8827ZM49.0396 39.2664C47.2 41.2032 47.2876 44.1964 49.2148 45.9571C51.1419 47.8059 54.1203 47.7179 55.8723 45.7811C57.7119 43.8443 57.6243 40.8511 55.6971 39.0903C53.7699 37.3296 50.7915 37.4176 49.0396 39.2664Z"
        fill="white"
      />
      <path d="M54.805 60.8864L52.0977 70.5471L58.0846 72.2416L60.7919 62.5809L54.805 60.8864Z" fill="#4B85C4" />
      <path
        d="M54.7335 60.8352L60.6902 62.5079L57.9747 72.1919L52.0179 70.5192L54.7335 60.8352ZM54.2955 59.9548L54.1203 60.6591L51.4047 70.3431L51.2295 71.0474L51.9303 71.2235L57.8871 72.8961L58.5879 73.0722L58.7631 72.3679L61.4786 62.684L61.6538 61.9797L60.953 61.8036L54.9963 60.1309L54.2955 59.9548Z"
        fill="white"
      />
      <path d="M71.26 35.5401L64.5234 38.5151L67.2723 44.8017L74.0088 41.8267L71.26 35.5401Z" fill="#4B85C4" />
      <path
        d="M64.5448 38.562L71.29 35.5688L74.0056 41.8193L67.348 44.8126L64.5448 38.562ZM63.4937 38.1218L63.8441 38.8261L66.5596 45.0767L66.91 45.781L67.6108 45.4288L74.356 42.4356L75.0568 42.0835L74.7064 41.3792L71.9908 35.1286L71.6404 34.4243L70.9396 34.7765L64.282 37.7697L63.4937 38.1218Z"
        fill="white"
      />
      <path
        d="M34.0603 43.7563L32.9215 44.9008L30.9067 47.1017L32.0455 48.1582L34.1479 45.9573L35.1991 44.8128L34.0603 43.7563Z"
        fill="#999999"
      />
      <path
        d="M45.7112 31.343L44.66 32.4875L42.5576 34.6884L43.6964 35.7448L45.7988 33.5439L46.85 32.3995L45.7112 31.343Z"
        fill="#999999"
      />
      <path
        d="M56.3103 78.0904C56.2227 78.0904 56.1351 78.0904 56.0475 78.0023L54.9087 76.9459C54.7335 76.7698 54.7335 76.5057 54.9087 76.4177C55.0839 76.2416 55.3467 76.2416 55.4343 76.4177L56.5731 77.4741C56.7483 77.6502 56.7483 77.9143 56.5731 78.0023C56.5731 78.0023 56.3979 78.0904 56.3103 78.0904Z"
        fill="#6D6E71"
      />
      <path
        d="M54.033 75.8895C53.9454 75.8895 53.8578 75.8895 53.7702 75.8014L52.6314 74.745C52.4562 74.5689 52.4562 74.3048 52.6314 74.2168C52.8066 74.0407 53.0694 74.0407 53.157 74.2168L54.2958 75.2732C54.471 75.4493 54.471 75.7134 54.2958 75.8014C54.2082 75.8895 54.1206 75.8895 54.033 75.8895Z"
        fill="#6D6E71"
      />
      <path
        d="M51.7556 73.6885C51.668 73.6885 51.5804 73.6885 51.4928 73.6005L50.3541 72.5441C50.1789 72.368 50.1789 72.1039 50.3541 72.0158C50.5293 71.8398 50.7921 71.8398 50.8797 72.0158L52.0184 73.0723C52.1936 73.2483 52.1936 73.5125 52.0184 73.6005C51.9309 73.6885 51.8432 73.6885 51.7556 73.6885Z"
        fill="#6D6E71"
      />
      <path
        d="M49.4778 71.4876C49.3902 71.4876 49.3026 71.4876 49.215 71.3996L48.0762 70.3431C47.901 70.1671 47.901 69.903 48.0762 69.8149C48.2514 69.6388 48.5142 69.6388 48.6018 69.8149L49.7406 70.8713C49.9158 71.0474 49.9158 71.3115 49.7406 71.3996C49.653 71.4876 49.5654 71.4876 49.4778 71.4876Z"
        fill="#6D6E71"
      />
      <path
        d="M47.2 69.3748C47.1124 69.3748 47.0248 69.3748 46.9372 69.2868L45.7984 68.2303C45.6232 68.0543 45.6232 67.7902 45.7984 67.7021C45.9736 67.5261 46.2364 67.5261 46.324 67.7021L47.4628 68.7586C47.638 68.9346 47.638 69.1987 47.4628 69.2868C47.3752 69.2868 47.2876 69.3748 47.2 69.3748Z"
        fill="#6D6E71"
      />
      <path
        d="M44.9226 67.1738C44.835 67.1738 44.7474 67.1738 44.6598 67.0857L43.521 65.9413C43.3458 65.7652 43.3458 65.5011 43.521 65.4131C43.6962 65.237 43.959 65.237 44.0466 65.4131L45.1854 66.4695C45.3606 66.6456 45.3606 66.9097 45.1854 66.9977C45.0978 67.0857 45.0102 67.1738 44.9226 67.1738Z"
        fill="#6D6E71"
      />
      <path
        d="M42.645 64.9729C42.5574 64.9729 42.4698 64.9729 42.3822 64.8848L41.1558 63.7403C40.9806 63.5643 40.9806 63.3002 41.1558 63.2121C41.331 63.0361 41.5938 63.0361 41.6814 63.2121L42.8202 64.2686C42.9954 64.4446 42.9954 64.7087 42.8202 64.7968C42.8202 64.9729 42.7326 64.9729 42.645 64.9729Z"
        fill="#6D6E71"
      />
      <path
        d="M40.2801 62.772C40.1925 62.772 40.1049 62.772 40.0173 62.684L38.8785 61.6276C38.7033 61.4515 38.7033 61.1874 38.8785 61.0993C39.0537 60.9233 39.3165 60.9233 39.4041 61.0993L40.5429 62.1558C40.7181 62.3318 40.7181 62.596 40.5429 62.684C40.5429 62.772 40.4553 62.772 40.2801 62.772Z"
        fill="#6D6E71"
      />
      <path
        d="M38.0022 60.5711C37.9146 60.5711 37.827 60.5711 37.7394 60.4831L36.6006 59.4266C36.4254 59.2506 36.4254 58.9864 36.6006 58.8984C36.7758 58.7223 37.0386 58.7223 37.1262 58.8984L38.265 59.9548C38.4402 60.1309 38.4402 60.395 38.265 60.4831C38.265 60.5711 38.1774 60.5711 38.0022 60.5711Z"
        fill="#6D6E71"
      />
      <path
        d="M35.7244 58.4583C35.6368 58.4583 35.5492 58.4583 35.4616 58.3703L34.3228 57.3138C34.1476 57.1378 34.1476 56.8737 34.3228 56.7856C34.498 56.6095 34.7608 56.6095 34.8484 56.7856L35.9872 57.8421C36.1624 58.0181 36.1624 58.2822 35.9872 58.3703C35.9872 58.3703 35.812 58.4583 35.7244 58.4583Z"
        fill="#6D6E71"
      />
      <path
        d="M33.4471 56.2574C33.3595 56.2574 33.2719 56.2574 33.1843 56.1693L32.0455 55.1129C31.8703 54.9368 31.8703 54.6727 32.0455 54.5847C32.2207 54.4086 32.4835 54.4086 32.5711 54.5847L33.7099 55.6411C33.8851 55.8172 33.8851 56.0813 33.7099 56.1693C33.7099 56.1693 33.5347 56.2574 33.4471 56.2574Z"
        fill="#6D6E71"
      />
      <path
        d="M31.1697 54.0564C31.0821 54.0564 30.9945 54.0564 30.9069 53.9683L29.7681 52.8238C29.5929 52.6478 29.5929 52.3837 29.7681 52.2956C29.9433 52.1196 30.2061 52.1196 30.2937 52.2956L31.4325 53.3521C31.6077 53.5281 31.6077 53.7922 31.4325 53.8803C31.3449 54.0564 31.2573 54.0564 31.1697 54.0564Z"
        fill="#6D6E71"
      />
      <path
        d="M28.8919 51.8555C28.8043 51.8555 28.7167 51.8555 28.6291 51.7675L27.4903 50.7111C27.3151 50.535 27.3151 50.2709 27.4903 50.1828C27.6655 50.0068 27.9283 50.0068 28.0159 50.1828L29.1547 51.2393C29.3299 51.4153 29.3299 51.6795 29.1547 51.7675C29.0671 51.8555 28.9795 51.8555 28.8919 51.8555Z"
        fill="#6D6E71"
      />
      <path
        d="M76.7215 56.7856C76.6339 56.7856 76.5463 56.7856 76.4587 56.6976L75.3199 55.5531C75.1447 55.377 75.1447 55.1129 75.3199 55.0249C75.4951 54.8488 75.7579 54.8488 75.8455 55.0249L76.9843 56.0813C77.1595 56.2574 77.1595 56.5215 76.9843 56.6095C76.8967 56.6976 76.8091 56.7856 76.7215 56.7856Z"
        fill="#6D6E71"
      />
      <path
        d="M74.4436 54.5845C74.356 54.5845 74.2684 54.5845 74.1808 54.4965L73.042 53.4401C72.8668 53.264 72.8668 52.9999 73.042 52.9118C73.2172 52.7358 73.48 52.7358 73.5676 52.9118L74.7064 53.9683C74.8816 54.1443 74.8816 54.4085 74.7064 54.4965C74.6188 54.5845 74.5312 54.5845 74.4436 54.5845Z"
        fill="#6D6E71"
      />
      <path
        d="M72.1658 52.3838C72.0782 52.3838 71.9906 52.3838 71.903 52.2958L70.7642 51.2394C70.589 51.0633 70.589 50.7992 70.7642 50.7112C70.9394 50.5351 71.2022 50.5351 71.2898 50.7112L72.4286 51.7676C72.6038 51.9437 72.6038 52.2078 72.4286 52.2958C72.341 52.3838 72.2534 52.3838 72.1658 52.3838Z"
        fill="#6D6E71"
      />
      <path
        d="M69.8885 50.2708C69.8009 50.2708 69.7133 50.2708 69.6257 50.1828L68.4869 49.1263C68.3117 48.9503 68.3117 48.6862 68.4869 48.5981C68.6621 48.422 68.9249 48.422 69.0125 48.5981L70.1513 49.6546C70.3265 49.8306 70.3265 50.0947 70.1513 50.1828C70.0637 50.1828 69.9761 50.2708 69.8885 50.2708Z"
        fill="#6D6E71"
      />
      <path
        d="M67.6111 48.0699C67.5235 48.0699 67.4359 48.0699 67.3483 47.9818L66.2095 46.9254C66.0343 46.7493 66.0343 46.4852 66.2095 46.3972C66.3847 46.2211 66.6475 46.2211 66.7351 46.3972L67.8739 47.4536C68.0491 47.6297 68.0491 47.8938 67.8739 47.9818C67.7863 47.9818 67.6987 48.0699 67.6111 48.0699Z"
        fill="#6D6E71"
      />
      <path
        d="M65.3333 45.8692C65.2457 45.8692 65.1581 45.8692 65.0705 45.7812L63.9317 44.7247C63.7565 44.5487 63.7565 44.2845 63.9317 44.1965C64.1069 44.0204 64.3697 44.0204 64.4573 44.1965L65.5961 45.2529C65.7713 45.429 65.7713 45.6931 65.5961 45.7812C65.5085 45.8692 65.4209 45.8692 65.3333 45.8692Z"
        fill="#6D6E71"
      />
      <path
        d="M62.9681 43.6683C62.8805 43.6683 62.7929 43.6683 62.7053 43.5802L61.5665 42.5238C61.3913 42.3477 61.3913 42.0836 61.5665 41.9956C61.7417 41.8195 62.0045 41.8195 62.0921 41.9956L63.2309 43.052C63.4061 43.2281 63.4061 43.4922 63.2309 43.5802C63.2309 43.6683 63.1433 43.6683 62.9681 43.6683Z"
        fill="#6D6E71"
      />
      <path
        d="M60.6902 41.4673C60.6026 41.4673 60.515 41.4673 60.4274 41.3793L59.2886 40.3229C59.1134 40.1468 59.1134 39.8827 59.2886 39.7947C59.4638 39.6186 59.7266 39.6186 59.8142 39.7947L60.953 40.8511C61.1282 41.0272 61.1282 41.2913 60.953 41.3793C60.953 41.4673 60.8654 41.4673 60.6902 41.4673Z"
        fill="#6D6E71"
      />
      <path
        d="M58.4129 39.3543C58.3253 39.3543 58.2377 39.3543 58.1501 39.2663L57.0113 38.2098C56.8361 38.0338 56.8361 37.7697 57.0113 37.6816C57.1865 37.5055 57.4493 37.5055 57.5369 37.6816L58.6757 38.65C58.8509 38.8261 58.8509 39.0902 58.6757 39.1782C58.6757 39.2663 58.5005 39.3543 58.4129 39.3543Z"
        fill="#6D6E71"
      />
      <path
        d="M56.1355 37.1534C56.0479 37.1534 55.9603 37.1534 55.8727 37.0653L54.7339 36.0089C54.5587 35.8328 54.5587 35.5687 54.7339 35.4807C54.9091 35.3046 55.1719 35.3046 55.2595 35.4807L56.3983 36.5371C56.5735 36.7132 56.5735 36.9773 56.3983 37.0653C56.3983 37.0653 56.2231 37.1534 56.1355 37.1534Z"
        fill="#6D6E71"
      />
      <path
        d="M53.8577 34.9527C53.7701 34.9527 53.6825 34.9527 53.5949 34.8647L52.4561 33.8082C52.2809 33.6321 52.2809 33.368 52.4561 33.28C52.6313 33.1039 52.8941 33.1039 52.9817 33.28L54.1205 34.3364C54.2957 34.5125 54.2957 34.7766 54.1205 34.8647C54.0329 34.9527 53.9453 34.9527 53.8577 34.9527Z"
        fill="#6D6E71"
      />
      <path
        d="M51.5799 32.7518C51.4923 32.7518 51.4047 32.7518 51.3171 32.6637L50.1783 31.6073C50.0031 31.4312 50.0031 31.1671 50.1783 31.0791C50.3535 30.903 50.6163 30.903 50.7039 31.0791L51.8427 32.1355C52.0179 32.3116 52.0179 32.5757 51.8427 32.6637C51.7551 32.7518 51.6675 32.7518 51.5799 32.7518Z"
        fill="#6D6E71"
      />
      <path
        d="M49.3025 30.5508C49.2149 30.5508 49.1273 30.5508 49.0397 30.4628L47.9009 29.4064C47.7257 29.2303 47.7257 28.9662 47.9009 28.8781C48.0761 28.7021 48.3389 28.7021 48.4265 28.8781L49.5653 29.9346C49.7405 30.1107 49.7405 30.3748 49.5653 30.4628C49.4777 30.5508 49.3901 30.5508 49.3025 30.5508Z"
        fill="#6D6E71"
      />
    </svg>
  )
}

export default CombinedProductsIcon
