import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme, isMobile: boolean) =>
    css({
      fontSize: "12px",
      minWidth: !isMobile ? 200 : undefined,
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_primary
    }),
  divisor: css({
    "&.MuiDivider-root": {
      margin: "8px 0"
    }
  }),
  bigLabel: (theme: Theme) =>
    css({
      fontSize: "24px",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.25px",
      textAlign: "center",
      color: theme.colors.text_primary,
      fontFamily: "Roboto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: 16,
      "& > div": {
        display: "flex",
        alignItems: "center",
        "& > .currency": {
          fontSize: "18px",
          lineHeight: 1.78,
          letterSpacing: "0.19px",
          textAlign: "center",
          color: theme.colors.text_primary,
          marginRight: 4
        },
        "& > .title": {
          fontSize: "14px",
          fontWeight: 300,
          lineHeight: 1.14,
          letterSpacing: "0.15px",
          color: theme.colors.text_primary
        },
        "& > .priceTotal": {
          fontWeight: 500
        }
      }
    }),
  internalPrices: css({
    "& > div": {
      marginBottom: 0
    }
  }),
  strikethrought: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      fontSize: "12px",
      fontWeight: 300,
      lineHeight: "1.33",
      letterSpacing: "0.1px",
      color: theme.colors.text_variant,
      textDecoration: "line-through"
    }),
  rows: (theme: Theme, higlighted: boolean, total: boolean, brandColor?: boolean) =>
    css({
      fontWeight: total ? 500 : higlighted ? 400 : 300,
      display: "flex",
      fontSize: total ? "16px" : "12px",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      lineHeight: "1.33",
      letterSpacing: " 0.13px",
      color: !brandColor ? theme.colors.text_primary : theme.colors.brand_primary
    }),
  price: css({
    display: "flex",
    alignItems: "initial",
    "& > span": {
      fontSize: "12px",
      marginTop: "4px"
    }
  }),
  OFF: css({
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "8px"
  }),
  arrow: (t: Theme, opened: boolean) =>
    css({
      top: !opened ? "-10px" : "-15px",
      position: "relative",
      color: t.colors.brand_primary,
      textAlign: "center"
    }),
  mobTotalContainer: css({
    cursor: "pointer",
    padding: "8px 0px"
  }),
  mobDividerContainer: css({
    height: "6px"
  }),
  mobDivider: (t: Theme, mobile: boolean) =>
    css({
      " > MuiDivider-root": {
        backgroundColor: mobile ? `${t.colors.brand_primary}20` : "#00000020"
      }
    })
}
