import React from "react"
import PriceboxRow from "@basset-la/components-products/dist/components/PriceboxRow"
import Pricebox from "@basset-la/components-products/dist/components/Pricebox"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { PriceBreakdown } from "../../model"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import CurrencyJS from "currency.js"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"

type LoadingTrueProps = {
  loading: true
  priceBreakdown?: PriceBreakdown
}

type LoadingFalseProps = {
  loading?: false
  priceBreakdown: PriceBreakdown
}

type Props = { taxesDisclaimer?: string } & (LoadingTrueProps | LoadingFalseProps)

const PriceboxBreakdownContainer: React.FC<Props> = ({ loading = false, priceBreakdown, taxesDisclaimer }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  if (loading) {
    return (
      <div>
        <Pricebox
          backgroundColor={theme.colors.brand_primary}
          textColor={theme.colors.text_white}
          title={t("AccommodationsPricebox.title")}
          totalLabel={t("AccommodationsPricebox.total")}
          totalValue=""
          startCollapsed={true}
        >
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="100%" />
        </Pricebox>
      </div>
    )
  }

  if (!priceBreakdown) {
    return null
  }

  const { total, currency, baseRate, rooms, guests, extraCost, taxes, charges } = priceBreakdown

  let totalTaxes = extraCost
  if (taxes.pais) totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(taxes.pais)).value
  if (taxes.rg4815) totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(taxes.rg4815)).value
  if (taxes.rg5272) totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(taxes.rg5272)).value
  if (charges.totalFeeCharges) totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(charges.totalFeeCharges)).value

  taxes.taxes?.forEach(t => {
    totalTaxes = CurrencyJS(totalTaxes).subtract(CurrencyJS(t.amount)).value
  })

  return (
    <div>
      <Pricebox
        backgroundColor={theme.colors.brand_primary}
        textColor={theme.colors.text_white}
        title={t("AccommodationsPricebox.title")}
        totalLabel={t("AccommodationsPricebox.total")}
        totalValue={formatPrice(total, locale, currency)}
        startCollapsed={true}
        taxesDisclaimer={taxesDisclaimer}
      >
        <PriceboxRow
          label={`${t("AccommodationsPricebox.room", { count: rooms })} -  ${t("AccommodationsPricebox.guest", {
            count: guests
          })}`}
          value={formatPrice(baseRate, locale, currency)}
        />

        <PriceboxRow label={t("AccommodationsPricebox.taxes")} value={formatPrice(totalTaxes, locale, currency)} />

        {taxes.pais && (
          <PriceboxRow label={t("AccommodationsPricebox.paisTax")} value={formatPrice(taxes.pais, locale, currency)} />
        )}
        {taxes.rg4815 && (
          <PriceboxRow
            label={t("AccommodationsPricebox.rg4815Tax")}
            value={formatPrice(taxes.rg4815, locale, currency)}
          />
        )}
        {taxes.rg5272 && (
          <PriceboxRow
            label={t("AccommodationsPricebox.rg5272Tax")}
            value={formatPrice(taxes.rg5272, locale, currency)}
          />
        )}
        {taxes.taxes?.map(t => (
          <PriceboxRow label={t.type} value={formatPrice(t.amount, locale, currency)} />
        ))}
        {charges.totalFeeCharges && (
          <PriceboxRow
            label={t("AccommodationsPricebox.fees")}
            value={formatPrice(charges.totalFeeCharges, locale, currency)}
          />
        )}
      </Pricebox>
    </div>
  )
}

export default PriceboxBreakdownContainer
