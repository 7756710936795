import React, { useEffect, useState } from "react"

import cloneDeep from "lodash/cloneDeep"
import { Moment } from "moment"
import { useTranslation } from "react-i18next"

import BassetButton from "@basset-la/components-commons/dist/components/Button"
import Link from "@basset-la/components-commons/dist/components/Link"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import {
  AutocompleteItem,
  ProductAutocompleteProps
} from "@basset-la/components-products/dist/components/ProductAutocomplete"
import ProductDatePicker from "@basset-la/components-products/dist/components/ProductDatePicker"
import TextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import AddIcon from "@material-ui/icons/AddCircleOutlineRounded"
import OriginIcon from "@material-ui/icons/GpsFixedRounded"
import DestinationIcon from "@material-ui/icons/LocationOnRounded"

import { FlightType } from "../../model/types"
import { I18N_NS } from "../../utils/constants"
import AirlinesSelect from "../AirlineSelect/AirlinesSelect"
import AirportAutocompleteField from "../AirportAutocompleteField"
import FlightClassSelect from "../FlightClassSelect"
import FlightStopsSelect from "../FlightStopsSelect"
import FlightTypeSelect from "./FlightTypeSelect"
import PassengerSelect from "./PassengerSelect"
import CurrencySelect from "../CurrencySelect"
import styles from "./Searchbox.styles"
import SearchboxMobileBar from "./SearchboxMobileBar"
import { FlightClass, Leg, Passengers, SearchboxConfig, SearchboxParams, Stops } from "./types"

export interface Props extends Pick<ProductAutocompleteProps, "getRegions"> {
  config: SearchboxConfig
  params: SearchboxParams
  wide?: boolean
  onSearch: (params: SearchboxParams) => void
  getAirlines: (value: string) => Promise<AutocompleteItem[]>
  currencies?: string[]
}

type LegError = {
  originError?: string
  destinationError?: string
  fromError?: string
  toError?: string
}

export const DefaultSearchParams: SearchboxParams = {
  flightType: "ROUND_TRIP",
  legs: [
    {
      destination: null,
      origin: null,
      from: null,
      to: null
    }
  ],
  passengers: {
    adults: 1,
    children: [],
    disableds: 0,
    seniors: 0
  },
  includeBaggage: false,
  includeCorporateRates: false
}

const Searchbox: React.FC<Props> = ({
  config,
  params,
  wide: forceWide,
  onSearch,
  getRegions,
  getAirlines,
  currencies
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const isMobile = useMediaQuery("(max-width: 1024px)")
  const [openDialog, setOpenDialog] = useState(false)

  const [currentParams, setCurrentParams] = useState<SearchboxParams>(params)
  const [legErrors, setLegErrors] = useState<LegError[]>([])

  const [selectedAirlines, setSelectedAirlines] = useState<AutocompleteItem[] | null>(null)

  const [showInputCorpRate, setShowInputCorpRate] = useState(false)

  useEffect(() => {
    const rebuildLegs = async () => {
      const legs = Object.assign([], params.legs) || []
      for (const leg of params.legs) {
        const originPromise = getRegions(leg.origin?.iata_code!)
        const destinationPromise = getRegions(leg.destination?.iata_code!)
        const [origins, destinations] = await Promise.all([originPromise, destinationPromise])
        leg.origin = origins.find((e: any) => e.iata_code === leg.origin?.iata_code!) || origins[0]
        leg.destination = destinations.find((e: any) => e.iata_code === leg.destination?.iata_code!) || destinations[0]
      }
      params.legs = legs
      setCurrentParams({ ...params })
    }

    var needRebuild = false
    for (const leg of params.legs) {
      if (leg.origin?.iata_code === leg.origin?.name) {
        needRebuild = true
      }
    }

    if (needRebuild) {
      rebuildLegs()
    }
  }, [params, getRegions])

  useEffect(() => {
    const rebuildAirlines = async () => {
      if (params.airlines) {
        try {
          const promises = params.airlines!.map(a => getAirlines(a))
          const airlineResults = await Promise.all(promises)
          const mapped = airlineResults.map((r, i) => r.find(item => item.iata_code === params.airlines![i])!)
          setSelectedAirlines(mapped)
        } catch (e) {
          setSelectedAirlines([])
        }
      }
    }
    if (!selectedAirlines) {
      rebuildAirlines()
    }
  }, [params, getAirlines, selectedAirlines])

  const handleOpen = () => {
    setOpenDialog(true)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleChangeFlightType = (v: FlightType) => {
    const leg: Leg = {
      origin: null,
      destination: null,
      from: null,
      to: v === "ROUND_TRIP" ? null : undefined
    }

    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.flightType = v
    copy.legs = [cloneDeep(leg)]

    if (v === "MULTIPLE_DESTINATIONS") copy.legs.push(cloneDeep(leg))

    setCurrentParams(copy)
    setLegErrors([])
  }

  const handleOnSelectRegion = (legIndex: number, field: "origin" | "destination") => (region: AutocompleteItem) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.legs[legIndex][field] = region

    if (field === "destination" && copy.flightType === "MULTIPLE_DESTINATIONS") {
      const limit = copy.legs.length - 1
      const nextIndex = legIndex + 1
      if (nextIndex <= limit) {
        if (copy.legs[nextIndex].origin === null) copy.legs[nextIndex].origin = region
      }
    }

    setCurrentParams(copy)

    if (legErrors[legIndex] && legErrors[legIndex][`${field}Error`]) {
      let errorsCopy: LegError[] = cloneDeep(legErrors)
      errorsCopy[legIndex][`${field}Error`] = undefined
      setLegErrors(errorsCopy)
    }
  }

  const handleOnDateChange = (legIndex: number) => (startDate: Moment | null, endDate?: Moment | null) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.legs[legIndex].from = startDate
    copy.legs[legIndex].to = endDate

    if (copy.flightType === "MULTIPLE_DESTINATIONS") {
      const limit = copy.legs.length - 1
      const nextIndex = legIndex + 1
      if (nextIndex <= limit) {
        if (copy.legs[nextIndex].from === null) copy.legs[nextIndex].from = startDate
      }
    }

    setCurrentParams(copy)

    if (legErrors[legIndex]) {
      let errorsCopy: LegError[] = cloneDeep(legErrors)
      if (errorsCopy[legIndex].fromError) {
        errorsCopy[legIndex].fromError = undefined
      }
      if (endDate && errorsCopy[legIndex].toError) {
        errorsCopy[legIndex].toError = undefined
      }

      setLegErrors(errorsCopy)
    }
  }

  const handleAddLeg = () => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.legs.push({
      origin: copy.legs[copy.legs.length - 1].destination,
      destination: null,
      from: copy.legs[copy.legs.length - 1].from
    })

    setCurrentParams(copy)
  }

  const handleOnPassengersChange = (psgrs: Passengers) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.passengers = psgrs
    setCurrentParams(copy)
  }

  const handleOnStopsChange = (s: Stops) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.stops = s
    setCurrentParams(copy)
  }

  const handleOnFlightClassChange = (fc: FlightClass) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.flightClass = fc
    setCurrentParams(copy)
  }

  const handleOnIncludeBaggageChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.includeBaggage = checked
    setCurrentParams(copy)
  }

  const handleOnIncludeCorporateRatesChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowInputCorpRate(checked)
    if (!checked) {
      let copy: SearchboxParams = cloneDeep(currentParams)
      copy.corporateRates = ""
      setCurrentParams(copy)
    }
  }

  const handleOnIncludeCorporateRatesValueChange = (value: string) => {
    let copy: SearchboxParams = cloneDeep(currentParams)
    copy.corporateRates = value
    setCurrentParams(copy)
  }

  const handleCurrencyChange = (v: string) => {
    const copy = cloneDeep(currentParams)

    copy.currency = v

    setCurrentParams(copy)
  }

  const handleOnSearch = () => {
    if (validateFields()) {
      if (isMobile) {
        setOpenDialog(false)
      }
      onSearch({
        ...currentParams,
        airlines: (selectedAirlines?.map(item => item.iata_code).filter(code => !!code) as string[]) || []
      })
    }
  }

  const validateFields = (): boolean => {
    let valid = true

    const { legs, flightType } = currentParams

    const errors: LegError[] = []

    for (let idx in legs) {
      let originError: string | undefined = undefined
      let destinationError: string | undefined = undefined
      let fromError: string | undefined = undefined
      let toError: string | undefined = undefined

      if (!legs[idx].origin) {
        originError = t("Searchbox.missingOrigin")
        valid = false
      }

      if (!legs[idx].destination) {
        destinationError = t("Searchbox.missingDestination")
        valid = false
      }

      if (!legs[idx].from) {
        fromError = t("Searchbox.missingFrom")
        valid = false
      }

      if (!legs[idx].to && flightType === "ROUND_TRIP") {
        toError = t("Searchbox.missingTo")
        valid = false
      }

      if (originError || destinationError || fromError || toError) {
        errors[idx] = {
          originError,
          destinationError,
          fromError,
          toError
        }
      }
    }

    setLegErrors(errors)

    return valid
  }

  const handleDeleteLeg = (idx: number) => () => {
    const copy = cloneDeep(currentParams)
    copy.legs = [...copy.legs.slice(0, idx), ...copy.legs.slice(idx + 1, copy.legs.length)]
    setCurrentParams(copy)
    if (legErrors[idx]) {
      const errorsCopy = cloneDeep(legErrors)
      errorsCopy[idx] = {}
      setLegErrors(errorsCopy)
    }
  }

  const handleSelectAirlines = (selectedItems: AutocompleteItem[]) => {
    setSelectedAirlines(selectedItems)
  }

  const handleOnSelectAllInSearch = (newSelectedItems: AutocompleteItem[]) => {
    setSelectedAirlines(newSelectedItems)
  }

  const handleOnClearAll = () => {
    setSelectedAirlines([])
  }

  if (!currentParams) {
    return <></>
  }

  const searchBox = (
    <div className={styles.root(theme)}>
      <span className={styles.title}>{t("Searchbox.title")}</span>
      <div className={styles.mainContent}>
        <FlightTypeSelect
          onChange={handleChangeFlightType}
          value={currentParams.flightType}
          disallowMultipleDestinationsSearch={config.disallowMultipleDestinationsSearch}
        />
        <div className={styles.legsContainer}>
          {currentParams.legs.map((l, idx) => {
            return (
              <div className={styles.leg} key={`leg_${idx}`}>
                {currentParams.flightType === "MULTIPLE_DESTINATIONS" && (
                  <span className={styles.legTitleContainer}>
                    <span className={styles.legTitle}>{t("Searchbox.flight", { number: idx + 1 })}</span>
                    {currentParams.legs.length > 1 && (
                      <Link
                        component="span"
                        TextProps={{ size: 10 }}
                        value={t("Searchbox.delete")}
                        onClick={handleDeleteLeg(idx)}
                      />
                    )}
                  </span>
                )}
                <div className={styles.legMainContent}>
                  <div className={styles.legOriginDestination}>
                    <AirportAutocompleteField
                      id={`searchbox-origin-${idx}`}
                      getRegions={getRegions}
                      region={l.origin}
                      icon={<OriginIcon className={styles.icon(theme)} />}
                      label={t("Searchbox.origin")}
                      placeholder={t("Searchbox.originPlaceholder")}
                      onSelectRegion={handleOnSelectRegion(idx, "origin")}
                      errorMessage={
                        legErrors[idx] && legErrors[idx].originError ? legErrors[idx].originError : undefined
                      }
                    />
                  </div>
                  <div className={styles.legOriginDestination}>
                    <AirportAutocompleteField
                      id={`searchbox-destination-${idx}`}
                      getRegions={getRegions}
                      icon={<DestinationIcon className={styles.icon(theme)} />}
                      label={t("Searchbox.destination")}
                      placeholder={t("Searchbox.destinationPlaceholder")}
                      region={l.destination}
                      onSelectRegion={handleOnSelectRegion(idx, "destination")}
                      errorMessage={
                        legErrors[idx] && legErrors[idx].destinationError ? legErrors[idx].destinationError : undefined
                      }
                    />
                  </div>
                  <div className={styles.legDates}>
                    <ProductDatePicker
                      inputClassName={styles.datePickerInput}
                      type={currentParams.flightType === "ROUND_TRIP" ? "round-trip" : "one-way"}
                      startDateLabel={t("Searchbox.from")}
                      endDateLabel={t("Searchbox.to")}
                      startDatePlaceholder={t("Searchbox.fromPlaceholder")}
                      endDatePlaceholder={t("Searchbox.toPlaceholder")}
                      onChangeDate={handleOnDateChange(idx)}
                      startDate={l.from}
                      endDate={l.to}
                      maxDate={config.maxDate}
                      minDate={config.minDate}
                      maximumNights={config.maximumNights}
                      minimumNights={0}
                      startDateError={legErrors[idx] && legErrors[idx].fromError ? legErrors[idx].fromError : undefined}
                      endDateError={legErrors[idx] && legErrors[idx].toError ? legErrors[idx].toError : undefined}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {currentParams.flightType === "MULTIPLE_DESTINATIONS" && config.maxSegments > currentParams.legs.length && (
          <Button onClick={handleAddLeg} className={styles.addFlightButton(theme)} variant="text">
            <AddIcon />
            {t("Searchbox.addFlight")}
          </Button>
        )}
        <Divider className={styles.divider} />
        <div className={styles.secondSection}>
          <div className={styles.leg}>
            <div className={styles.legMainContent}>
              <div className={styles.legDates}>
                <div className={styles.paxStopContainer}>
                  <div className={styles.passengersContainer}>
                    <PassengerSelect
                      passengers={currentParams.passengers}
                      maxPassengers={config.maxPassengers}
                      onChange={handleOnPassengersChange}
                    />
                  </div>
                  <div className={styles.stopsContainer}>
                    <FlightStopsSelect value={currentParams.stops} onChange={handleOnStopsChange} />
                  </div>
                </div>
              </div>
              <div className={styles.legOriginDestination}>
                <FlightClassSelect value={currentParams.flightClass} onChange={handleOnFlightClassChange} />
              </div>
              <div className={styles.legOriginDestination}>
                <AirlinesSelect
                  getAirlines={getAirlines}
                  onChange={handleSelectAirlines}
                  onClearAllSelect={handleOnClearAll}
                  onSelectAllInSearch={handleOnSelectAllInSearch}
                  selectedItems={selectedAirlines || []}
                  isMobile={isMobile}
                  multiple
                />
              </div>
            </div>
          </div>
        </div>

        {config.allowCurrency && (
          <div className={styles.secondSection}>
            <div className={styles.leg}>
              <div className={styles.legMainContent}>
                <div className={styles.legDates}>
                  <div className={styles.paxStopContainer}>
                    <div className={styles.stopsContainer}>
                      <CurrencySelect
                        onChange={handleCurrencyChange}
                        value={currentParams.currency}
                        currencies={currencies || []}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.includeBaggageContainer(theme)}>
          <FormControlLabel
            control={<Checkbox checked={!!currentParams.includeBaggage} onChange={handleOnIncludeBaggageChange} />}
            label={t("Searchbox.includeBaggage")}
          />
        </div>
        {currentParams.includeCorporateRates && (
          <div className={`${styles.includeBaggageContainer(theme)} ${styles.flexVerticalSeparatorHelper}`}>
            <FormControlLabel
              control={<Checkbox checked={!!showInputCorpRate} onChange={handleOnIncludeCorporateRatesChange} />}
              label={t("Searchbox.includeCorporateRates")}
            />
            {showInputCorpRate && (
              <div id="corporateRatesInput">
                <TextField
                  className={styles.inputCorporateRate}
                  placeholder={t("Searchbox.corporateRatesPlaceholder")}
                  onChange={e => {
                    handleOnIncludeCorporateRatesValueChange(e.target.value)
                  }}
                />
              </div>
            )}
          </div>
        )}

        <div
          className={`${styles.buttonContainer} ${
            currentParams.includeCorporateRates ? styles.searchButtonVerticalMarginHelper : ""
          }`}
        >
          <BassetButton fullWidth id="searchbox-search-btn" onClick={handleOnSearch} variant="contained">
            {t("Searchbox.search")}
          </BassetButton>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {isMobile && !forceWide && (
        <>
          <SearchboxMobileBar params={currentParams} onClick={handleOpen} />
          <FullScreenDialog open={openDialog} onClose={handleClose}>
            <div className={styles.mobileContent}>{searchBox}</div>
          </FullScreenDialog>
        </>
      )}
      {(!isMobile || forceWide) && <>{searchBox}</>}
    </>
  )
}

export default Searchbox
