import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      "&.MuiPagination-root": {
        " .MuiPagination-ul": {
          " .MuiPaginationItem-page": {
            "&.Mui-selected": {
              backgroundColor: `${t.colors.brand_primary}20`,
              color: t.colors.brand_primary
            },
            color: t.colors.text_primary
          }
        }
      }
    })
}
