import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.colors.background_primary,
      "@media screen and (max-width: 1024px)": {
        width: "100%",
        height: "100%",
        boxSizing: "border-box"
      }
    }),
  amenitiesCheckin: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: `1px solid ${theme.colors.background_darker}`,
      borderBottom: `1px solid ${theme.colors.background_darker}`,
      "@media screen and (max-width: 1024px)": {
        flexDirection: "column"
      }
    }),
  content: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box"
  }),
  accommodationInfo: css({
    display: "flex",
    flexDirection: "row",
    height: "400px",
    marginBottom: "16px",
    "@media screen and (max-width: 1024px)": {
      flexDirection: "column",
      height: "auto",
      borderRadius: "4px",
      overflow: "hidden",
      marginBottom: "8px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 0 0 rgba(0, 0, 0, 0.12)"
    }
  }),
  photos: css({
    borderRadius: "4px",
    flex: 2,
    maxWidth: "656px",
    marginRight: "16px",
    "@media screen and (max-width: 1024px)": {
      marginRight: 0,
      maxWidth: "100%",
      width: "100%",
      height: "128px",
      borderRadius: 0
    }
  }),
  infoContainer: (theme: Theme) =>
    css({
      display: "flex",
      flex: 1,
      boxSizing: "border-box",
      flexDirection: "column",
      border: `1px solid ${theme.colors.background_darker}`,
      borderRadius: "4px",
      padding: "24px",
      "@media screen and (max-width: 1024px)": {
        border: "unset",
        height: "112px",
        borderRadius: 0,
        padding: "16px"
      }
    }),
  info: css({
    display: "flex",
    flex: 1,
    flexDirection: "column"
  }),
  infoComponent: css({
    flex: 1,
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  }),
  section: (theme: Theme) =>
    css({
      padding: "40px 0",
      borderBottom: `1px solid ${theme.colors.background_darker}`,
      ":last-of-type": {
        borderBottom: "unset"
      },
      "@media screen and (max-width: 1024px)": {
        padding: "32px 0"
      }
    }),
  zone: (theme: Theme) =>
    css({
      borderRadius: "4px",
      border: `1px solid ${theme.colors.background_darker}`
    })
}
