import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const ParkingIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14.211 14.895h-3.609v-2.429h3.213c.24 0 .417-.061.532-.183a.666.666 0 0 0 .171-.475.638.638 0 0 0-.174-.469c-.117-.118-.293-.178-.53-.178h-3.212V9.066h3.489c.247 0 .43-.061.55-.183a.665.665 0 0 0 .18-.486.657.657 0 0 0-.18-.481c-.12-.122-.303-.184-.55-.184h-4.088c-.22 0-.4.037-.54.109a.652.652 0 0 0-.31.343c-.067.157-.1.36-.1.608v6.418c0 .373.074.643.222.81.148.167.39.25.727.25h4.209c.243 0 .427-.063.55-.19a.673.673 0 0 0 .185-.492.69.69 0 0 0-.185-.503c-.123-.127-.307-.19-.55-.19M12 19c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7m0-15c-4.412 0-8 3.589-8 8 0 4.412 3.588 8 8 8 4.411 0 8-3.588 8-8 0-4.411-3.589-8-8-8"
    />
  </svg>
)
