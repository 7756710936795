import React from "react"

const TwitterIcon = () => {
  return (
    <svg className="MuiSvgIcon-root" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M 17.638 4 L 20.761 4 L 13.904 11.808 L 21.915 22.4 L 15.628 22.4 L 10.705 15.963 L 5.07 22.4 L 1.947 22.4 L 9.213 14.049 L 1.54 4 L 7.984 4 L 12.431 9.879 L 17.638 4 Z M 16.546 20.568 L 18.277 20.568 L 7.073 5.765 L 5.212 5.765 L 16.546 20.568 Z" />
    </svg>
  )
}
export default TwitterIcon
