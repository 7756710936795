import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: theme.colors.text_primary
    }),
  title: css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5",
    margin: 0,
    marginBottom: "8px"
  }),
  content: css({
    display: "flex",
    flexDirection: "column"
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        marginTop: "8px",
        color: theme.colors.brand_primary,
        textTransform: "none",
        alignSelf: "flex-start",
        width: "fit-content",
        height: "fit-content"
      }
    }),
  container: css({
    paddingBottom: "16px",
    " > div": {
      backgroundColor: "inherit"
    }
  })
}
