import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    width: "80px",
    height: "1px",
    margin: "0 auto",
    boxSizing: "content-box"
  }),
  bulletContainer: css({
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    boxSizing: "content-box"
  }),
  bullet: (t: Theme) =>
    css({
      width: "6px",
      height: "6px",
      backgroundColor: t.colors.background_primary,
      border: `solid 1px ${t.colors.text_variant}`,
      borderRadius: "50%",
      display: "block",
      marginTop: "-4px",
      marginRight: "8px",
      boxSizing: "content-box",
      ":last-child": {
        marginRight: "0px"
      }
    })
}
