import * as React from "react"
import AnimatedBackgroundEffect from "@basset-la/components-commons/dist/components/AnimatedBackgroundEffect"
import FlightIcon from "@material-ui/icons/Flight"
import styles from "./FlightsResultsPlaceholder.styles"
import { useTheme } from "@basset-la/themed-components"

const FlightsResultsPlaceholder = () => {
  const theme = useTheme()

  return (
    <>
      {Array.apply(null, Array(5)).map((x: any, i: number) => (
        <div className={styles.content(theme)} key={i}>
          <div className={styles.mdCol}>
            {Array.apply(null, Array(2)).map((y: any, j: number) => (
              <div key={j} className={styles.topContainer}>
                <div className={styles.rowStyle}>
                  <div className={styles.smCol}>
                    <FlightIcon className={styles.icon(j)} />
                  </div>
                  <div className={styles.mdCol}>
                    <AnimatedBackgroundEffect>
                      <div className={styles.column1} />
                    </AnimatedBackgroundEffect>
                  </div>
                  <div className={styles.smCol} />
                  <div className={styles.smCol}>
                    <AnimatedBackgroundEffect>
                      <div className={styles.column2} />
                    </AnimatedBackgroundEffect>
                  </div>
                  <div className={styles.mdCol}>
                    <AnimatedBackgroundEffect>
                      <div className={styles.column3} />
                    </AnimatedBackgroundEffect>
                  </div>
                </div>
                {Array.apply(null, Array(2)).map((z: any, k: number) => (
                  <div key={k} className={styles.column4} />
                ))}
              </div>
            ))}
          </div>
          <div className={styles.smCol}>
            <div className={styles.clusterContainer}>
              <AnimatedBackgroundEffect>
                <div className={styles.cluster1} />
              </AnimatedBackgroundEffect>
              <br />
              <AnimatedBackgroundEffect>
                <div className={styles.cluster2} />
              </AnimatedBackgroundEffect>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default FlightsResultsPlaceholder
