import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (showTimePicker: boolean) =>
    css({
      display: "flex",
      flexDirection: "row",
      flexWrap: showTimePicker ? "wrap" : undefined,
      gap: "16px",
      justifyContent: "space-between"
    }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  toolbarTextFieldContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px"
  }),
  toolbarTextField: css({
    flex: "1 1 auto",
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  fieldContainer: (showTimePicker: boolean) =>
    css({
      display: "flex",
      flex: showTimePicker ? "1 1 auto" : "0 1 auto",
      gap: "16px",
      "@media (max-width: 1024px)": {
        width: showTimePicker ? "100%" : undefined,
        justifyContent: showTimePicker ? "space-between" : undefined
      }
    }),
  dateField: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px",
    "@media (max-width: 1024px)": {
      flex: "1"
    }
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  popover: css({
    minHeight: "310px"
  }),
  timePicker: css({
    maxWidth: "200px"
  })
}
