import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      "&.MuiSelect-root": {
        fontSize: "14px",
        color: theme.colors.text_primary,
        lineHeight: "1.71",
        letterSpacing: "0.15px",
        borderBottom: "none"
      }
    }),
  input: (theme: Theme) =>
    css({
      "&.MuiInput-underline": {
        borderBottom: "none",
        ":before, :focus, :hover, :after, :hover:not(.Mui-disabled):before": {
          borderBottom: "none"
        }
      },
      "> .MuiSelect-icon": {
        color: theme.colors.text_primary
      }
    }),
  item: (theme: Theme) =>
    css({
      "&.MuiMenuItem-root": {
        fontSize: "14px",
        fontWeight: "normal",
        color: theme.colors.text_primary
      }
    })
}
