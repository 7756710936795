import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "16px",
      backgroundColor: t.colors.background_primary,
      border: `1px solid ${t.colors.background_darker}`,
      borderRadius: "4px",
      gap: "8px"
    }),
  destinationContainer: css({
    display: "flex",
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0,
    marginBottom: "16px",
    justifyContent: "space-between"
  }),
  destination: css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }),
  roomName: css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }),
  hotelDetail: css({
    display: "flex",
    width: "368px",
    alignItems: "flex-start",
    gap: "8px"
  }),
  hotelName: css({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }),
  hotelImgContainer: css({
    height: "100%",
    display: "flex",
    " > img": {
      objectFit: "cover",
      width: "100%",
      height: "100%"
    }
  }),
  hotelImg: css({
    width: "128px",
    height: "152px",
    flexShrink: "0"
  }),
  hotelNameContainer: css({
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px",
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0,
    flexWrap: "nowrap",
    gap: "8px"
  }),
  passengersTitle: css({
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "flex-start"
  }),
  titleContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  row: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  rowTitle: css({
    display: "inline-flex",
    marginLeft: "8px"
  }),
  passengersContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  passengerText: css({
    textAlign: "right"
  }),
  icon: (theme: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        color: theme.colors.brand_primary,
        fontSize: "14px"
      }
    }),
  rotate: css({
    "&.MuiSvgIcon-root": {
      transform: "rotate(180deg)"
    }
  }),
  mealPlan: css({
    marginRight: "8px",
    display: "flex"
  }),
  refundable: (theme: Theme) =>
    css({
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "1.33",
      color: theme.brand_colors.click_color
    }),
  dashBorder: css({
    paddingBottom: "8px",
    borderBottom: "1px dashed #CCCCCC"
  })
}
