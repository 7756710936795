import React from "react"
import { AccommodationFee } from "../../model"
import Card from "@basset-la/components-commons/dist/components/Card"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import styles from "./NotIncludedFees.styles"

export interface Props {
  fees: AccommodationFee[]
  additionalCharges: string
}

const NotIncludedFees: React.FC<Props> = ({ fees, additionalCharges }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  return (
    <Card variant="outlined">
      <div className={styles.content}>
        <Text className={styles.title} variant="bold">
          {t("NotIncludedFees.title")}
        </Text>
        {fees.length > 0 &&
          fees.map((f, i) => (
            <Text key={i} size={14}>
              - {t(`NotIncludedFees.${f.type}`)}: {formatPrice(f.amount, locale, f.currency)}
            </Text>
          ))}
        {additionalCharges && <Text size={14}>{additionalCharges}</Text>}
      </div>
    </Card>
  )
}

export default NotIncludedFees
