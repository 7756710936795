import React, { useRef, useState } from "react"
import styles from "./AgeSelect.styles"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { SearchboxChildren } from "../Searchbox"

export interface Props {
  value: SearchboxChildren
  onChange: (v: SearchboxChildren) => void
}

const AgeSelect: React.FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const [currentValue, setCurrentValue] = useState(value !== "NO_VALUE" ? value : t("RoomPicker.NO_VALUE"))
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOptionClick = (value: number) => {
    onChange(value)
    setCurrentValue(value)
    setOpen(false)
  }

  return (
    <>
      <SearchboxTextField
        value={currentValue}
        rightIcon={<ArrowIcon className={styles.icon(theme)} />}
        className={styles.textField(theme)}
        onClick={handleOpen}
        ref={inputRef}
      />
      <Menu className={styles.menu} open={open} onClose={handleClose} anchorEl={inputRef.current}>
        <MenuItem disabled value={"NO_VALUE"}>
          {t("RoomPicker.NO_VALUE")}
        </MenuItem>
        {Array.apply(null, Array(18)).map((_v: null, i: number) => (
          <MenuItem
            key={i}
            value={i}
            onClick={() => {
              handleOptionClick(i)
            }}
          >
            {i}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default AgeSelect
