import React from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Slide from "@material-ui/core/Slide"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import CloseIcon from "@material-ui/icons/Close"
import BassetButton from "@basset-la/components-commons/dist/components/Button"
import styles from "./FullScreenDialog.styles"
import { useTheme } from "@basset-la/themed-components"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props {
  open: boolean
  title?: string
  actionButtonText?: string
  toolbarComponent?: React.ReactNode
  children?: React.ReactNode
  onClose: () => void
  onAction?: () => void
  id?: {
    actionButton?: string
  }
}

const FullScreenDialog: React.FC<Props> = ({
  open,
  title,
  actionButtonText,
  children,
  toolbarComponent,
  onClose,
  onAction,
  id
}) => {
  const theme = useTheme()

  const handleAction = () => {
    if (onAction) onAction()
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Slide}>
      <AppBar className={styles.appbar(theme)} position="static">
        <Toolbar className={styles.toolbar}>
          <div className={styles.backButton}>
            {!title && <ArrowBackIcon className={styles.backButtonIcon(theme)} onClick={onClose} />}
            {title && (
              <>
                <Text size={16} variant="regular">
                  {title}
                </Text>
                <CloseIcon className={styles.backButtonIcon(theme)} onClick={onClose} />
              </>
            )}
          </div>
          {toolbarComponent && <div className={styles.toolbarComponent}>{toolbarComponent}</div>}
        </Toolbar>
      </AppBar>
      <DialogContent className={styles.content}>{children}</DialogContent>
      {actionButtonText && (
        <DialogActions>
          <BassetButton
            id={id?.actionButton}
            className={styles.actionButton}
            fullWidth
            onClick={handleAction}
            variant="contained"
          >
            {actionButtonText}
          </BassetButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default FullScreenDialog
