import { css } from "@emotion/css"

export default {
  img: css({
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
    "@media screen and (max-width: 1024px)": {
      height: "400px"
    }
  })
}
