import React, { useState, useRef } from "react"
import HotelIcon from "@material-ui/icons/HotelRounded"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Popover from "@material-ui/core/Popover"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import styles from "./RoomSelect.styles"
import { useTheme } from "@basset-la/themed-components"
import RoomPicker from "./RoomPicker"
import Link from "@basset-la/components-commons/dist/components/Link"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import Text from "@basset-la/components-commons/dist/components/Text"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { SearchboxRoom } from "../Searchbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  room: SearchboxRoom
  roomIndex: number
  maxCapacity: number
  showDelete: boolean
  onChange: (r: SearchboxRoom) => void
  onDelete: () => void
}

const RoomSelect: React.FC<Props> = ({ room, maxCapacity, onChange, showDelete, roomIndex, onDelete }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const buildPassengerText = (r: SearchboxRoom) => {
    let parts: string[] = []
    if (r.adults) {
      parts.push(t("RoomSelect.adult", { count: r.adults }))
    }
    if (r.children.length) {
      parts.push(t("RoomSelect.child", { count: r.children.length }))
    }
    return parts.join(", ")
  }

  const [open, setOpen] = useState<boolean>(false)
  const [passengerText, setPassengerText] = useState<string>(buildPassengerText(room))
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault()
  }

  const handleOnChange = (p: SearchboxRoom) => {
    onChange(p)
    setPassengerText(buildPassengerText(p))
  }

  const handleOnDone = (p: SearchboxRoom) => {
    onChange(p)
    setPassengerText(buildPassengerText(p))
    setOpen(false)
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <div className={styles.root(theme)}>
      <div className={styles.title}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("RoomSelect.room", { number: roomIndex })}
        </Text>
        {showDelete && (
          <Link component="span" TextProps={{ size: 10 }} value={t("RoomSelect.delete")} onClick={onDelete} />
        )}
      </div>
      <SearchboxTextField
        className={styles.textField}
        InputProps={{
          disabled: true,
          inputProps: {
            id: `room-select-searchbox-room-${roomIndex}`
          }
        }}
        onClick={handleOpen}
        onFocus={handleOnFocus}
        rightIcon={<ArrowIcon />}
        leftIcon={<HotelIcon className={styles.icon(theme)} />}
        value={passengerText}
        ref={inputRef}
      />
      {!isMobile && (
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={open}
          onClose={handleClose}
          anchorEl={inputRef.current}
        >
          <RoomPicker
            title={t("RoomSelect.room", { number: roomIndex })}
            maxCapacity={maxCapacity}
            onChange={handleOnChange}
            onDone={handleOnDone}
            room={room}
          />
        </Popover>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          onClose={handleClose}
          onAction={handleClose}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              InputProps={{ disabled: true }}
              leftIcon={<HotelIcon className={styles.icon(theme)} />}
              value={passengerText}
            />
          }
        >
          <RoomPicker
            title={t("RoomSelect.room", { number: roomIndex })}
            maxCapacity={maxCapacity}
            onChange={handleOnChange}
            onDone={handleOnDone}
            room={room}
          />
        </FullScreenDialog>
      )}
    </div>
  )
}

export default RoomSelect
