import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    })
}
