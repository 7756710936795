import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
    "> *": {
      marginBottom: "8px",
      ":last-child": {
        marginBottom: 0
      }
    }
  }),
  costToPay: (theme: Theme) =>
    css({
      borderRadius: "3px",
      backgroundColor: colors.GREY,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "8px 16px",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "1",
      letterSpacing: "0.17px",
      color: theme.colors.text_white
    })
}
