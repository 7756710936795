import React from "react"

interface Props {
  children: React.ReactNode
  styles?: string
}

const Col: React.FC<Props> = ({ children, styles }) => <td className={styles}>{children}</td>

export default Col
