import React from "react"
import { Price as FlightPrice, Currency } from "../../model/types"
import { convertToAgencyPriceBreakdown, convertToAgencyCostEffectivenessBreakdown } from "../../utils/helpers/helpers"
import PriceboxBreakdown from "../PriceboxBreakdown/PriceboxBreakdown"

import PriceboxBreakdownCostEffectiveness from "../PriceboxBreakdownCostEffectiveness/PriceboxBreakdownCostEffectiveness"
import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"
import styles from "./PriceboxBreakdownAgency.styles"

import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import { getLocale, formatPrice } from "@basset-la/components-products/dist/utils/helpers"

export interface Props {
  flightPrice: FlightPrice
  currencySelector?: Currency
  ownerType?: "RESELLER" | "AGENCY"
}

const PriceboxBreakdownAgency: React.FC<Props> = ({ flightPrice, currencySelector, ownerType }) => {
  const priceBreakdown = convertToAgencyPriceBreakdown(flightPrice)
  const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(flightPrice)
  const costToPay = priceBreakdown.total - costEffectivenessBreakdown.total
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()
  return (
    <div className={styles.root}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <PriceboxBreakdown priceBreakdown={priceBreakdown} ownerType={ownerType} />
      <PriceboxBreakdownCostEffectiveness costEffectivenessBreakdown={costEffectivenessBreakdown} />
      <div className={styles.costToPay(theme)}>
        <span>{t("AgencyPricebox.costToPay")}</span>
        <span>{formatPrice(costToPay, locale, flightPrice.currency)}</span>
      </div>
    </div>
  )
}

export default PriceboxBreakdownAgency
