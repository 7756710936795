import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const ChangeIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.97467 2.76162V0.901617C5.97467 0.601617 5.61467 0.45495 5.408 0.668284L2.87467 3.19495C2.74134 3.32828 2.74134 3.53495 2.87467 3.66828L5.40134 6.19495C5.61467 6.40162 5.97467 6.25495 5.97467 5.95495V4.09495C8.46134 4.09495 10.428 6.37495 9.88134 8.95495C9.568 10.4683 8.34134 11.6883 6.83467 12.0016C4.45467 12.5016 2.33467 10.8683 2.01467 8.66162C1.968 8.34162 1.688 8.09495 1.36134 8.09495C0.961335 8.09495 0.641335 8.44828 0.694669 8.84828C1.108 11.775 3.89467 13.9416 7.048 13.3283C9.128 12.9216 10.8013 11.2483 11.208 9.16828C11.868 5.74828 9.268 2.76162 5.97467 2.76162Z"
      fill={color}
    />
  </svg>
)
