import { useTheme } from "@basset-la/themed-components"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import GoogleMap from "google-map-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./HotelDetailMap.styles"
import HotelMarker from "./HotelMarker"
import MapLeftColumn from "./MapLeftColumn"
import PoiMarker from "./PoiMarker"
import { Region } from "../../model/accommodationDetail"

export interface Props {
  hotelImg: string
  hotelCoordinates: {
    latitude: number
    longitude: number
  }
  hotelName: string
  open: boolean
  onClose: () => void
  apiKey: string

  pois?: Region[]
  airports?: Region[]
  subwayStations?: Region[]
  trainStations?: Region[]
  isMobile: boolean
}

const HotelDetailMap: React.FunctionComponent<Props> = ({
  hotelImg,
  hotelCoordinates,
  hotelName,
  pois,
  airports,
  subwayStations,
  trainStations,
  open,
  onClose,
  apiKey,
  isMobile
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [renderPois, setRenderPois] = React.useState<boolean>(true)
  const [renderTrainStations, setRenderTrainStations] = React.useState<boolean>(true)
  const [renderSubWayStations, setRenderSubWayStations] = React.useState<boolean>(true)
  const [renderAirports, setRenderAirports] = React.useState<boolean>(true)

  function handlePoiClick() {
    setRenderPois(!renderPois)
  }
  function handleTrainClick() {
    setRenderTrainStations(!renderTrainStations)
  }
  function handleSubwayClick() {
    setRenderSubWayStations(!renderSubWayStations)
  }
  function handleAirportClick() {
    setRenderAirports(!renderAirports)
  }

  const shouldRenderLeftColumn =
    (pois && pois.length > 0) ||
    (airports && airports.length > 0) ||
    (subwayStations && subwayStations.length > 0) ||
    (trainStations && trainStations.length > 0)

  return (
    <Dialog maxWidth={"xl"} open={open} onClose={onClose} fullScreen={isMobile}>
      <div className={styles.mobileNavBar(theme)}>
        <p>{t("HotelDetailMap.location")}</p>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.root}>
        {!isMobile && shouldRenderLeftColumn && (
          <div className={styles.leftColumn(theme)}>
            {
              <MapLeftColumn
                pois={
                  pois
                    ? {
                        items: pois,
                        onClick: handlePoiClick,
                        selected: renderPois
                      }
                    : undefined
                }
                trainStations={
                  trainStations
                    ? {
                        items: trainStations,
                        onClick: handleTrainClick,
                        selected: renderTrainStations
                      }
                    : undefined
                }
                subwayStations={
                  subwayStations
                    ? {
                        items: subwayStations,
                        onClick: handleSubwayClick,
                        selected: renderSubWayStations
                      }
                    : undefined
                }
                airports={
                  airports
                    ? {
                        items: airports,
                        onClick: handleAirportClick,
                        selected: renderAirports
                      }
                    : undefined
                }
              />
            }
          </div>
        )}
        <div className={styles.mapContainer}>
          <div style={{ width: "100%", height: "100%" }}>
            <GoogleMap
              defaultCenter={{ lat: hotelCoordinates.latitude, lng: hotelCoordinates.longitude }}
              defaultZoom={15}
              bootstrapURLKeys={{ key: apiKey }}
            >
              <HotelMarker
                name={hotelName}
                img={hotelImg}
                lat={hotelCoordinates.latitude}
                lng={hotelCoordinates.longitude}
              />
              {pois &&
                renderPois &&
                pois.map((e, i) => (
                  <PoiMarker
                    lat={e.location.latitude}
                    lng={e.location.longitude}
                    name={e.name}
                    key={i}
                    backgroundColor={"#e44652"}
                  />
                ))}
              {trainStations &&
                renderTrainStations &&
                trainStations.map((e, i) => (
                  <PoiMarker
                    lat={e.location.latitude}
                    lng={e.location.longitude}
                    name={e.name}
                    key={i}
                    backgroundColor={"#fdb43c"}
                  />
                ))}
              {subwayStations &&
                renderSubWayStations &&
                subwayStations.map((e, i) => (
                  <PoiMarker
                    lat={e.location.latitude}
                    lng={e.location.longitude}
                    name={e.name}
                    key={i}
                    backgroundColor={"#92bce3"}
                  />
                ))}
              {airports &&
                renderAirports &&
                airports.map((e, i) => (
                  <PoiMarker
                    lat={e.location.latitude}
                    lng={e.location.longitude}
                    name={e.name}
                    key={i}
                    backgroundColor={"#59c28d"}
                  />
                ))}
            </GoogleMap>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default HotelDetailMap
