import React from "react"

export const FullBreakfastIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        stroke="#FFF"
        strokeWidth=".3"
        d="M6.386 12.698H3.15a.768.768 0 0 1-.532-.211.7.7 0 0 1-.221-.508v-8.43l-.224-.022c-.377-.039-.672-.344-.672-.715V2.47a.7.7 0 0 1 .22-.508.768.768 0 0 1 .533-.211h.025l.025-.005h.002c.066-.012 1.5-.269 5.377-.269 1.803 0 3.144.069 4.034.136a26.576 26.576 0 0 1 1.24.124l.06.008.019.003.02.003h.02c.209 0 .395.08.532.21a.7.7 0 0 1 .221.51v.341c0 .371-.295.676-.672.715l-.224.022v2.277l.5-.543V3.96c.519-.147.896-.605.896-1.147V2.47c0-.652-.545-1.18-1.224-1.195C12.956 1.253 11.17 1 7.682 1c-3.803 0-5.29.245-5.46.275C1.543 1.292 1 1.82 1 2.47v.342c0 .542.377 1 .896 1.147v8.02c0 .66.56 1.196 1.253 1.196h3.299l-.062-.477z"
      />
      <path
        fill="#FFF"
        d="M5.25 5.58c.081 0 .147.066.147.146a.147.147 0 0 1-.293 0c0-.08.066-.145.147-.146m0 .873a.73.73 0 0 0 .733-.727.73.73 0 0 0-.733-.727.73.73 0 0 0-.734.727.73.73 0 0 0 .734.727M10.499 4.402a.265.265 0 0 1-.271-.259c0-.143.121-.258.27-.258.15 0 .271.115.272.258a.265.265 0 0 1-.271.259m0-.995c-.426 0-.771.33-.772.736 0 .407.346.736.772.736.426 0 .77-.33.77-.736 0-.406-.344-.736-.77-.736"
      />
      <path
        fill="#F0F0F0"
        d="M19.813 8.991c0 .127-.013.254-.04.376a.338.338 0 0 0 .01.173l-12.681.108c.169-.793.84-1.368 1.638-1.368.041 0 .082.002.123.005.1.007.198-.036.262-.117a.337.337 0 0 0 .061-.292 1.72 1.72 0 0 1-.053-.425c0-.91.708-1.649 1.579-1.649.633 0 1.203.393 1.452 1 .061.15.218.23.368.187a.326.326 0 0 0 .228-.355.995.995 0 0 1-.008-.12c0-.527.41-.955.914-.955.422 0 .787.3.887.73.03.128.13.225.256.245.125.02.249-.041.314-.154a1.12 1.12 0 0 1 .975-.578c.628 0 1.138.533 1.138 1.189l-.001.023-.002.033a.331.331 0 0 0 .125.275.3.3 0 0 0 .287.048c.17-.06.348-.092.528-.092.904 0 1.64.769 1.64 1.713m2.24-5.518a.303.303 0 0 0-.44 0L18.724 6.49a.329.329 0 0 0-.087.188 2.169 2.169 0 0 0-.807-.023c-.152-.855-.872-1.504-1.733-1.504-.438 0-.846.165-1.162.457-.28-.429-.749-.7-1.27-.7-.614 0-1.145.378-1.391.922a2.155 2.155 0 0 0-1.563-.679c-1.215 0-2.203 1.032-2.203 2.301 0 .063.003.125.007.188-1.042.104-1.887.927-2.051 2.014l-1.035.009a.32.32 0 0 0-.31.328c.002.18.142.324.313.324h.002l15.646-.133a6.521 6.521 0 0 1-.99 2.607c-1.3 1.983-3.652 3.116-6.805 3.275-2.784-.115-4.898-1.064-6.284-2.82-1.037-1.313-1.26-2.615-1.262-2.627a.314.314 0 0 0-.358-.268.325.325 0 0 0-.257.375c.01.06.244 1.461 1.373 2.906 1.876 2.4 4.72 3.003 6.777 3.086h.027c2.345-.117 5.516-.829 7.303-3.559 1.078-1.645 1.131-3.228 1.133-3.295a.334.334 0 0 0-.09-.237.309.309 0 0 0-.224-.098l-1.05.01c.003-.01.007-.017.008-.027.037-.169.055-.343.055-.518 0-.91-.495-1.7-1.218-2.096l2.834-2.96a.336.336 0 0 0 0-.462"
      />
      <path
        fill="#F0F0F0"
        d="M12.494 7.29a.313.313 0 0 0-.364.261.277.277 0 0 1-.035.098.335.335 0 0 0 .096.45.3.3 0 0 0 .167.05.309.309 0 0 0 .264-.15c.06-.097.1-.208.121-.328a.325.325 0 0 0-.25-.38M15.27 7.024a.324.324 0 0 0-.261-.372.314.314 0 0 0-.356.272c-.035.235.009.46.127.652.06.096.16.149.263.149a.3.3 0 0 0 .169-.052.335.335 0 0 0 .092-.45.279.279 0 0 1-.035-.199M15.108 14.532a.298.298 0 0 0 .069-.008c1.265-.294 2.305-.87 3.089-1.712a.336.336 0 0 0-.006-.461.303.303 0 0 0-.442.006c-.697.75-1.632 1.265-2.777 1.531a.326.326 0 0 0-.237.39.315.315 0 0 0 .304.254M18.665 12.073a.31.31 0 0 0 .268-.157c.06-.104.116-.21.165-.313a.333.333 0 0 0-.14-.437.306.306 0 0 0-.418.146 3.83 3.83 0 0 1-.141.266.334.334 0 0 0 .105.448c.05.032.106.047.161.047"
      />
    </g>
  </svg>
)
