import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  dialog: (isMobile: boolean) =>
    css({
      ".MuiDialog-paper": {
        padding: isMobile ? "16px" : "24px",
        margin: 0
      }
    }),
  dialogTitle: css({
    "&.MuiDialogTitle-root": {
      display: "flex",
      justifyContent: "center",
      padding: 0,
      marginBottom: "16px",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "24px"
    }
  }),
  dialogContent: (isMobile: boolean) =>
    css({
      "&.MuiDialogContent-root": {
        padding: isMobile ? 0 : "8px 24px"
      }
    }),
  dialogActions: (isMobile: boolean) =>
    css({
      "&.MuiDialogActions-root": {
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
        marginBottom: isMobile ? "19px" : 0
      },
      "&.MuiDialogActions-spacing > :not(:first-child)": {
        marginLeft: isMobile ? 0 : "8px"
      }
    }),
  closeContainer: (isMobile: boolean) =>
    css({
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: isMobile ? "16px" : "5px",
      marginTop: isMobile ? "16px" : 0
    }),
  acceptButton: (isMobile: boolean) =>
    css({
      marginLeft: isMobile ? 0 : undefined
    }),
  cancelButton: (isMobile: boolean) =>
    css({
      marginRight: isMobile ? undefined : "16px",
      marginBottom: isMobile ? "16px" : undefined
    }),
  priceContainer: (isMobile: boolean) =>
    css({
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: isMobile ? "stretch" : "center",
      justifyContent: isMobile ? "center" : "space-between",
      marginTop: "24px",
      marginBottom: "24px"
    }),
  oldPriceContainer: (theme: Theme, isMobile: boolean) =>
    css({
      border: `1px dashed ${theme.colors.error}`,
      marginRight: isMobile ? undefined : "24px",
      marginBottom: isMobile ? "8px" : undefined
    }),
  newPriceContainer: (theme: Theme) =>
    css({
      border: `1px dashed ${theme.colors.success}`
    }),
  priceTextContainer: css({
    display: "flex",
    flex: 1,
    padding: "16px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px"
  }),
  oldPriceText: (theme: Theme) =>
    css({
      color: `${theme.colors.error} !important`,
      textDecoration: "line-through",
      fontWeight: 500
    }),
  newPriceText: (theme: Theme) =>
    css({
      color: `${theme.colors.success} !important`,
      fontWeight: 500
    }),
  errorIcon: css({
    marginRight: "8px"
  }),
  checkIcon: (theme: Theme) =>
    css({
      color: theme.colors.success,
      marginRight: "8px"
    }),

  closeButton: (theme: Theme) =>
    css({
      "&.MuiIconButton-root": {
        color: theme.colors.brand_primary
      }
    }),
  info: css({
    position: "relative",
    top: "-15px",
    marginBottom: "8px"
  })
}
