import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      color: t.colors.text_primary,
      flexDirection: "column",
      fontFamily: "Roboto",
      width: "100%"
    }),
  breakdownRow: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "12px",
    lineHeight: "1.33",
    " .MuiSvgIcon-root": {
      fontSize: "12px",
      height: "12px",
      width: "12px"
    }
  }),
  total: (theme: Theme) =>
    css({
      borderTop: `1px solid ${theme.colors.text_primary}`,
      paddingTop: "8px",
      marginTop: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: "14px",
      lineHeight: "1.71",
      fontWeight: 500
    }),
  discount: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      color: theme.colors.text_variant,
      justifyContent: "flex-end",
      fontSize: "14px",
      textAlign: "right",
      textDecoration: "line-through"
    })
}
