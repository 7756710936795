import React from "react"
import styles from "./Installments.styles"
import Text from "@basset-la/components-commons/dist/components/Text"
import Divider from "@material-ui/core/Divider"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

export type Bank = {
  id: string
  creditCards: string[]
}

export interface InstallmentsData {
  free: Record<string, Bank[]>
  fixed: Record<string, Bank[]>
}

export interface Props {
  installments: InstallmentsData
  showTitle?: boolean
}

const Installments: React.FC<Props> = ({ installments, showTitle = true }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const showData = (data: Record<string, Bank[]>, id: string, titleKey: string) => {
    return (
      <>
        {Object.keys(data).map((installment, index) => (
          <div key={`${id}-${index}`}>
            <li className={styles.row}>
              <div className={styles.rowTitle}>
                <Text size={18} variant="bold" component="p">
                  {t(titleKey, { count: parseInt(installment) })}
                </Text>
              </div>
              <ul className={styles.rowContent}>
                {data[installment].map((bank, index: number) => (
                  <li key={index} className={styles.bank}>
                    <div>
                      <img
                        className={styles.bankImg}
                        src={`https://statics.basset.la/banks/${bank.id}.png`}
                        alt={bank.id}
                      />
                    </div>
                    <div>
                      {bank.creditCards.map((value, index) => (
                        <Text key={index} size={10} variant="bold">
                          {value}
                          {index + 1 < bank.creditCards.length && ", "}
                        </Text>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            </li>
            {index + 1 < Object.keys(data).length && <Divider />}
          </div>
        ))}
      </>
    )
  }

  return (
    <div className={styles.root(theme)}>
      {showTitle && (
        <div className={styles.title}>
          <Text size={24} variant="bold" component="p">
            {t("Installments.title")}
          </Text>
        </div>
      )}
      <ul className={styles.content}>
        {showData(installments.free, "free", "Installments.rowTitleFree")}
        {showData(installments.fixed, "fixed", "Installments.rowTitleFixed")}
      </ul>
    </div>
  )
}

export default Installments
