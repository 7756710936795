import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "8px",
      gap: "8px",
      border: "1px solid #BBBBBB",
      backgroundColor: t.colors.background_primary,
      borderRadius: "4px",
      cursor: "pointer",
      minWidth: "176px"
    }),

  title: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "8px"
  }),

  text: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  })
}
