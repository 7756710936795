import React from "react"

const CheckoutIcon = () => {
  return (
    <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_514_756" maskUnits="userSpaceOnUse" x="0" y="0" width="98" height="98">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.234375H95.7653V96H0V0.234375Z" fill="white" />
      </mask>
      <g mask="url(#mask0_514_756)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M95.7653 48.1172C95.7653 74.5618 74.3274 96.0001 47.8824 96.0001C21.4379 96.0001 0 74.5618 0 48.1172C0 21.6722 21.4379 0.234375 47.8824 0.234375C74.3274 0.234375 95.7653 21.6722 95.7653 48.1172"
          fill="#00A5F4"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M68.4603 47.4603C62.4069 53.5132 52.5931 53.5132 46.5397 47.4603C40.4868 41.4069 40.4868 31.5931 46.5397 25.5397C52.5931 19.4868 62.4069 19.4868 68.4603 25.5397C74.5132 31.5931 74.5132 41.4069 68.4603 47.4603"
        fill="#C9EEF7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M57.0002 22.3497C53.2463 22.3497 49.4937 23.7782 46.6359 26.6355C43.8672 29.4043 42.3428 33.0852 42.3428 37.0002C42.3428 40.9152 43.8672 44.5962 46.6359 47.3645C52.3516 53.0788 61.6501 53.0792 67.3644 47.3645C73.0792 41.6497 73.0792 32.3507 67.3644 26.6355C64.5075 23.7786 60.7532 22.3497 57.0002 22.3497M57.0002 53C52.9011 53 48.802 51.4397 45.6813 48.319C39.4396 42.0777 39.4396 31.9227 45.6813 25.681C51.9227 19.4397 62.0781 19.4397 68.319 25.681C74.5603 31.9227 74.5603 42.0777 68.319 48.319C65.1988 51.4397 61.0993 53 57.0002 53"
        fill="#8CC1D3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.4617 42C69.3818 42 69.301 41.9868 69.2224 41.959C68.8697 41.8348 68.6922 41.4656 68.8243 41.1347C70.5508 36.808 69.1348 33.5745 68.1374 32.0323L68.0965 31.9691C67.9036 31.6656 68.0089 31.2724 68.3322 31.0906C68.6545 30.9096 69.0726 31.0082 69.2656 31.3117L69.301 31.3668C70.4274 33.1075 72.0282 36.75 70.0991 41.5842C69.997 41.8416 69.7368 42 69.4617 42"
        fill="#8CC1D3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.9895 49.5872C47.4841 49.3455 45.6106 47.0924 45.2518 47.0004C44.893 46.9079 25.6926 63.4846 25.3826 63.9216C23.747 65.4662 23.4318 68.0391 25.1484 69.7902C26.8655 71.5413 29.2505 71.2768 30.8861 69.7321C31.0748 69.5541 31.2317 69.3528 31.3647 69.138C34.0696 65.7763 48.4433 49.8043 47.9895 49.5872"
        fill="#F9B964"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.9895 49.5872C47.4841 49.3455 45.6106 47.0924 45.2518 47.0004C44.893 46.9079 25.6926 63.4846 25.3826 63.9216C23.747 65.4662 23.4318 68.0391 25.1484 69.7902C26.8655 71.5413 29.2505 71.2768 30.8861 69.7321C31.0748 69.5541 31.2317 69.3528 31.3647 69.138C34.0696 65.7763 48.4433 49.8043 47.9895 49.5872Z"
        stroke="#F9B964"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.9353 50.8463C49.4729 51.3247 47.7965 50.6492 46.1902 49.3374C44.5839 48.0256 43.6565 46.5744 44.1189 46.096C44.5808 45.6172 45.077 47.0181 46.6833 48.3299C48.2895 49.6417 50.3972 50.3679 49.9353 50.8463"
        fill="#F9B964"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.9353 50.8463C49.4729 51.3247 47.7965 50.6492 46.1902 49.3374C44.5839 48.0256 43.6565 46.5744 44.1189 46.096C44.5808 45.6172 45.077 47.0181 46.6833 48.3299C48.2895 49.6417 50.3972 50.3679 49.9353 50.8463Z"
        stroke="#F9B964"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default CheckoutIcon
