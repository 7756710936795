import React from "react"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./ProviderPrices.styles"
import Popover from "@material-ui/core/Popover"
import ArrowUpIcon from "@material-ui/icons/ArrowDropUpRounded"
import ArrowDownIcon from "@material-ui/icons/ArrowDropDownRounded"
import { useTheme } from "@basset-la/themed-components"
import { AccommodationFare } from "../../model"
import { convertToAgencyCostEffectivenessBreakdown } from "../../utils/helpers/helpersV2"
import Skeleton from "@material-ui/lab/Skeleton/Skeleton"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"

export interface ProviderPrice {
  provider: string
  fare: AccommodationFare
  promotions?: boolean
}

type LoadingTrueProps = {
  loading: true
  providers?: ProviderPrice[]
}

type LoadingFalseProps = {
  loading?: false
  providers: ProviderPrice[]
}
export type Props = LoadingTrueProps | LoadingFalseProps

const ProviderPrices: React.FC<Props> = ({ loading = false, providers }) => {
  const { i18n, t } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

  const handleOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const providerPricesVisualLimit = 4

  if (loading) {
    return (
      <div className={styles.root(theme)}>
        <div className={styles.providersContainer}>
          <div className={`${styles.row} ${styles.visualRow}`}>
            <Skeleton width="100px" variant="text" />
            <Skeleton width="100px" variant="text" />
          </div>
          <div className={`${styles.row} ${styles.visualRow}`}>
            <Skeleton width="100px" variant="text" />
            <Skeleton width="100px" variant="text" />
          </div>
          <div className={`${styles.row} ${styles.visualRow}`}>
            <Skeleton width="100px" variant="text" />
            <Skeleton width="100px" variant="text" />
          </div>
          <div className={`${styles.row} ${styles.visualRow}`}>
            <Skeleton width="100px" variant="text" />
            <Skeleton width="100px" variant="text" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.providersContainer}>
        {providers!.slice(0, Math.min(providers!.length, providerPricesVisualLimit)).map((p, i) => {
          const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(p.fare)
          return (
            <div key={i} className={`${styles.row} ${styles.visualRow}`}>
              <p className={styles.providerName}>
                {p.provider}
                {p.promotions && (
                  <div className={styles.badge}>
                    <AttachMoneyIcon fontSize="small" className={styles.icon} />
                  </div>
                )}
              </p>
              <p className={styles.total}>{formatPrice(p.fare.total, locale, p.fare.currency)}</p>
              {costEffectivenessBreakdown.total > 0 && (
                <p className={styles.costEffectiveness}>
                  {formatPrice(costEffectivenessBreakdown.total, locale, p.fare.currency)}
                </p>
              )}
            </div>
          )
        })}
      </div>
      {providers!.length > providerPricesVisualLimit && (
        <div className={styles.moreProvidersContainer}>
          <span className={styles.moreProvidersButton(theme)} onClick={handleOpen}>
            {t("ProviderPrices.more_providers")} {open && <ArrowUpIcon viewBox={"0 0 16 16"} />}{" "}
            {!open && <ArrowDownIcon viewBox={"0 0 16 16"} />}
          </span>
          <Popover
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div className={styles.moreProviders(theme)}>
              {providers!.slice(providerPricesVisualLimit, providers!.length).map((p, i) => {
                const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(p.fare)
                return (
                  <div key={i} className={styles.row}>
                    <p className={styles.providerName}>{p.provider}</p>
                    <p className={styles.total}>{formatPrice(p.fare.total, locale, p.fare.currency)}</p>
                    {costEffectivenessBreakdown.total > 0 && (
                      <p className={styles.costEffectiveness}>
                        {formatPrice(costEffectivenessBreakdown.total, locale, p.fare.currency)}
                      </p>
                    )}
                  </div>
                )
              })}
            </div>
          </Popover>
        </div>
      )}
    </div>
  )
}

export default ProviderPrices
