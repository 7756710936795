import React from "react"
import { css } from "@emotion/css"

import Row from "./Row"
import Col from "./Col"
import { InfoData } from "../../model/types"
import { useTheme, Theme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

interface Props {
  checkin: InfoData
}

const CheckinInstructions: React.FC<Props> = ({ checkin }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  if (!checkin.instructions && !checkin.specialInstructions) {
    return null
  }

  return (
    <Row>
      <Col>
        <div className={styles.root(theme)}>
          <span className={styles.title(theme)}>{t("CheckinInstructions.title")}</span>
          <br />
          <div dangerouslySetInnerHTML={{ __html: checkin.instructions }} />
          <div dangerouslySetInnerHTML={{ __html: checkin.specialInstructions }} />
        </div>
      </Col>
    </Row>
  )
}

const styles = {
  root: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_variant,
      padding: "20px 16px",
      margin: "8px 0"
    }),
  title: (theme: Theme) =>
    css({
      color: theme.colors.brand_primary,
      fontWeight: 500
    })
}

export default CheckinInstructions
