import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BagIconNotFounded: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg {...rest} width="20" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66602 6.33333V7C10.1388 7 11.3327 8.19391 11.3327 9.66667V17C11.3327 17.7364 10.7357 18.3333 9.99935 18.3333H1.99935C1.26297 18.3333 0.666016 17.7364 0.666016 17V9.66667C0.666016 8.19391 1.85992 7 3.33268 7V6.33333C3.33268 5.59695 3.92964 5 4.66602 5H7.33268C8.06906 5 8.66602 5.59695 8.66602 6.33333ZM7.33259 6.33335H4.66592V7.00001H7.33259V6.33335ZM5.99925 9.66675L7.33259 11.0001L5.99925 12.3334L4.66592 11.0001L5.99925 9.66675ZM3.99929 14.3333H9.99929V13.6667H1.99929V14.3333H3.33263V15.6667H3.99929V14.3333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 1C8.24 1 6 3.24 6 6C6 8.76 8.24 11 11 11C13.76 11 16 8.76 16 6C16 3.24 13.76 1 11 1ZM11 6.5C10.725 6.5 10.5 6.275 10.5 6V4C10.5 3.725 10.725 3.5 11 3.5C11.275 3.5 11.5 3.725 11.5 4V6C11.5 6.275 11.275 6.5 11 6.5ZM10.5 7.5V8.5H11.5V7.5H10.5Z"
      fill="#D0021B"
    />
  </svg>
)
