import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  expansionPanel: css({
    "&.MuiExpansionPanel-root": {
      boxShadow: "none",
      backgroundColor: "inherit"
    },
    "&.MuiExpansionPanel-root.Mui-expanded": {
      margin: "0px"
    },
    "&.MuiExpansionPanel-root::before": {
      height: "0px"
    }
  }),
  expansionPanelSummary: (theme: Theme) =>
    css({
      "&.MuiExpansionPanelSummary-root": {
        width: "100%",
        padding: "0 8px"
      },
      "&.MuiExpansionPanelSummary-root.Mui-expanded": {
        minHeight: "48px",
        height: "48px"
      },
      " > .MuiExpansionPanelSummary-expandIcon": {
        color: theme.colors.brand_primary
      }
    }),
  expansionPanelDetails: css({
    "&.MuiExpansionPanelDetails-root": {
      padding: "0 8px",
      " > div": {
        backgroundColor: "inherit",
        width: "100%"
      }
    }
  })
}
