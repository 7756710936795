import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const EntertainmentIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00033 0H14.0003C14.7337 0 15.3337 0.6 15.3337 1.33333L15.327 9.33333C15.327 10.0667 14.7337 10.6667 14.0003 10.6667H10.667V11.3333C10.667 11.7 10.367 12 10.0003 12H6.00033C5.63366 12 5.33366 11.7 5.33366 11.3333V10.6667H2.00033C1.26699 10.6667 0.666992 10.0667 0.666992 9.33333V1.33333C0.666992 0.6 1.26699 0 2.00033 0ZM2.66699 9.33333H13.3337C13.7003 9.33333 14.0003 9.03333 14.0003 8.66667V2C14.0003 1.63333 13.7003 1.33333 13.3337 1.33333H2.66699C2.30033 1.33333 2.00033 1.63333 2.00033 2V8.66667C2.00033 9.03333 2.30033 9.33333 2.66699 9.33333Z"
      fill={color}
    />
  </svg>
)
