import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (isSingleCategory: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      width: isSingleCategory ? "500px" : "280px",
      borderWidth: "0px 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#BBBBBB",
      borderRadius: "8px"
    }),

  title: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px 32px",
      color: t.colors.background_primary,
      background: t.colors.brand_darker,
      borderWidth: "1px 1px 0px 1px",
      borderStyle: "solid",
      borderColor: t.colors.brand_darker,
      borderRadius: "8px 8px 0px 0px"
    }),

  content: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    height: "100%"
  }),

  cost: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }),

  button: css({
    marginTop: "16px"
  })
}
