import React from "react"
import { I18N_NS } from "../../utils/constants/constants"
import styles from "./BrandServicesInfo.styles"
import { useTranslation } from "react-i18next"
import { Service, BaggageAllowance, ServiceType } from "../../model"
import { useTheme } from "@basset-la/themed-components"
import BrandService from "../BrandService/BrandService"
import { getBaggageConfiguration } from "../../utils/helpers"
import { mapChargeType, ServiceState } from "../../utils/helpers/brands"

interface FilteredService {
  type: ServiceType
  state: ServiceState
}

export interface Props {
  baggageAllowances?: BaggageAllowance[]
  services?: Service[]
}

const BrandServicesInfo: React.FunctionComponent<Props> = ({ baggageAllowances = [], services = [] }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const { bagIncluded, carryOnIncluded, baggagesAmount } = getBaggageConfiguration(baggageAllowances)

  const dict = {}
  services.forEach(s => {
    if (dict[s.type]?.state === "INCLUDED") return

    dict[s.type] = { type: s.type, state: mapChargeType(s.charge_type) }
  })

  if (dict["HAND_ON_BAGGAGE"] === undefined) {
    dict["HAND_ON_BAGGAGE"] = {
      type: "HAND_ON_BAGGAGE",
      state: bagIncluded === undefined ? "VERIFY" : bagIncluded > 0 ? "INCLUDED" : "PAID"
    }
  }
  if (dict["CARRY_ON_BAGGAGE"] === undefined) {
    dict["CARRY_ON_BAGGAGE"] = {
      type: "CARRY_ON_BAGGAGE",
      state: carryOnIncluded === undefined ? "VERIFY" : carryOnIncluded > 0 ? "INCLUDED" : "PAID"
    }
  }
  if (dict["CHECKED_BAGGAGE"] === undefined) {
    dict["CHECKED_BAGGAGE"] = {
      type: "CHECKED_BAGGAGE",
      state: baggagesAmount === undefined ? "VERIFY" : baggagesAmount > 0 ? "INCLUDED" : "PAID"
    }
  }

  const included = Object.values<FilteredService>(dict).filter(s => s.state === "INCLUDED")
  const paid = Object.values<FilteredService>(dict).filter(s => s.state === "PAID")
  const verify = Object.values<FilteredService>(dict).filter(s => s.state === "VERIFY")

  return (
    <div className={styles.root(theme)}>
      {included.length > 0 && (
        <>
          <p className={styles.title(theme)}>{t("BrandServicesInfo.included")}</p>
          <div className={styles.content}>
            {included.map((s, idx) => {
              return (
                <BrandService
                  key={`brand_service_included_${idx}`}
                  type={s.type}
                  state={s.state}
                  showBorder={false}
                  fontSize={12}
                />
              )
            })}
          </div>
        </>
      )}

      {paid.length > 0 && (
        <>
          <p className={styles.title(theme)}>{t("BrandServicesInfo.paid")}</p>
          <div className={styles.content}>
            {paid.map((s, idx) => {
              return (
                <BrandService
                  key={`brand_service_paid_${idx}`}
                  type={s.type}
                  state={s.state}
                  showBorder={false}
                  fontSize={12}
                />
              )
            })}
          </div>
        </>
      )}

      {verify.length > 0 && (
        <>
          <p className={styles.title(theme)}>{t("BrandServicesInfo.verify")}</p>
          <div className={styles.content}>
            {verify.map((s, idx) => {
              return (
                <BrandService
                  key={`brand_service_verify_${idx}`}
                  type={s.type}
                  state={s.state}
                  showBorder={false}
                  fontSize={12}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default BrandServicesInfo
