import React, { useRef, useState } from "react"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import MenuItem from "@material-ui/core/MenuItem"
import styles from "./FlightClassSelect.styles"
import { FlightClassIcon } from "../../icons/FlightClassIcon"
import Menu from "@material-ui/core/Menu"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import Checkbox from "@basset-la/components-commons/dist/components/Checkbox"
import Text from "@basset-la/components-commons/dist/components/Text"
import { FlightClass } from "../Searchbox"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  value?: FlightClass
  onChange: (v: FlightClass) => void
}

const FlightClassSelect: React.FC<Props> = ({ value, onChange }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [currentValue, setCurrentValue] = useState(value || "NO_VALUE")
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOptionClick = (value: FlightClass) => {
    onChange(value)
    setCurrentValue(value)
    if (!isMobile) {
      setOpen(false)
    }
  }

  const OPTIONS: FlightClass[] = ["NO_VALUE", "Y", "W", "S", "C", "J", "F", "P"]

  const getText = (value: FlightClass): string => {
    switch (value) {
      case "Y":
        return t("FlightClassSelect.economy")
      case "W":
        return t("FlightClassSelect.economyStandard")
      case "S":
        return t("FlightClassSelect.premiumEconomy")
      case "C":
        return t("FlightClassSelect.business")
      case "J":
        return t("FlightClassSelect.premiumBusiness")
      case "F":
        return t("FlightClassSelect.first")
      case "P":
        return t("FlightClassSelect.premiumFirst")
      default:
        return t("FlightClassSelect.allClasses")
    }
  }

  const isMobile = useMediaQuery("(max-width: 1024px)")

  return (
    <>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("FlightClassSelect.label")}
        </Text>
        <SearchboxTextField
          value={getText(currentValue)}
          leftIcon={<FlightClassIcon color={theme.colors.brand_primary} />}
          rightIcon={<ArrowIcon />}
          className={styles.textField}
          onClick={handleOpen}
          id="flight-class-select-searchbox"
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Menu className={styles.menu} open={open} onClose={handleClose} anchorEl={inputRef.current}>
          {OPTIONS.map((opt, idx) => (
            <MenuItem
              key={idx}
              value={opt}
              onClick={() => {
                handleOptionClick(opt)
              }}
            >
              {getText(opt)}
            </MenuItem>
          ))}
        </Menu>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          onClose={handleClose}
          onAction={handleClose}
          actionButtonText={t("FlightClassSelect.apply")}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              value={getText(currentValue)}
              leftIcon={<FlightClassIcon color={theme.colors.brand_primary} />}
              id="flight-class-select-toolbar-searchbox"
            />
          }
        >
          {OPTIONS.map((opt, idx) => (
            <div key={idx} className={styles.itemMobile} onClick={() => handleOptionClick(opt)}>
              <Checkbox checked={opt === currentValue} />
              <Text>{getText(opt)}</Text>
            </div>
          ))}
        </FullScreenDialog>
      )}
    </>
  )
}

export default FlightClassSelect
