import React from "react"
import styles from "./VoucherPassengerList.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { Passenger } from "../../model"
import { useTheme } from "@basset-la/themed-components"

interface Props {
  /**
   * Array of passengers
   * @param passengerList
   * @value Passenger type
   * @required true
   */
  passengerList: Passenger[]
}

const VoucherPassengerList: React.FC<Props> = ({ passengerList }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <ul className={styles.list(theme)}>
      <li className={` ${styles.itemTitle(theme)} ${styles.item(theme)}`}>
        <div>{t("VoucherPassengerList.passengers")}</div>
        <div>{t("VoucherPassengerList.documentType")}</div>
        <div>{t("VoucherPassengerList.ticketNumber")}</div>
      </li>
      {passengerList.map((p, i) => (
        <li key={i} className={styles.item(theme)}>
          <div>{p.name}</div>
          <div>{p.document.number}</div>
          <div>{p.ticketNumber}</div>
        </li>
      ))}
    </ul>
  )
}

export default VoucherPassengerList
