import React from "react"
import SwipeableViews from "react-swipeable-views"
import styles from "./AccommodationCarouselView.styles"
import LeftArrow from "@material-ui/icons/KeyboardArrowLeftRounded"
import RightArrow from "@material-ui/icons/KeyboardArrowRightRounded"
import FullScreenIcon from "@material-ui/icons/FullscreenRounded"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import AccommodationCarouselImage from "./AccommodationCarouselImage"

export interface Props {
  imgs: string[]
  index: number
  onChangeIndex: (index: number) => void
  onFullScreen?: () => void
  className?: string
}

const AccommodationCarouselView: React.FC<Props> = ({ imgs, onFullScreen, index, onChangeIndex, className }) => {
  const theme = useTheme()

  const nextPhoto = () => {
    onChangeIndex(index + 1)
  }

  const prevPhoto = () => {
    onChangeIndex(index - 1)
  }

  const handleChangeIndex = (idx: number) => {
    onChangeIndex(idx)
  }

  return (
    <div className={`${styles.root} ${className}`}>
      {onFullScreen && (
        <Button onClick={onFullScreen} classes={{ root: styles.fullScreen(theme) }}>
          <FullScreenIcon />
        </Button>
      )}
      <Button
        disabled={index === 0}
        onClick={prevPhoto}
        className={styles.leftArrow}
        classes={{ root: styles.button(theme) }}
      >
        <LeftArrow />
      </Button>
      <SwipeableViews
        className={styles.slider}
        slideClassName={styles.slider}
        onChangeIndex={handleChangeIndex}
        index={index}
        enableMouseEvents
      >
        {imgs.map((img, i) => {
          return <AccommodationCarouselImage img={img} key={i} />
        })}
      </SwipeableViews>
      <Button
        disabled={index >= imgs.length - 1}
        onClick={nextPhoto}
        className={styles.rightArrow}
        classes={{ root: styles.button(theme) }}
      >
        <RightArrow />
      </Button>
    </div>
  )
}

export default AccommodationCarouselView
