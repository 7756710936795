import React from "react"
import styles from "./BrandService.styles"
import { ServiceType } from "../../model"
import BrandServiceIcon from "../BrandServiceIcon"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { ServiceState } from "../../utils/helpers/brands"

export interface Props {
  type: ServiceType
  state: ServiceState
  showBorder: boolean
  fontSize: 12 | 14 | 16
}

const BrandService: React.FC<Props> = ({ type, state, showBorder, fontSize }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root(showBorder)}>
      <BrandServiceIcon type={type} state={state} />
      <Text size={fontSize} variant="regular">
        {`${t(`BrandService.types.${type}`)}${state === "PAID" ? ` ${t("BrandService.withCost")}` : ""}`}
      </Text>
    </div>
  )
}

export default BrandService
