import Collapse from "@material-ui/core/Collapse"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"
import React from "react"
import styles from "./Pricebox.styles"

export interface Props {
  backgroundColor: string
  textColor: string
  title: string
  totalLabel: string
  totalValue: string
  children: React.ReactNode
  startCollapsed?: boolean
  taxesDisclaimer?: string
}

const Pricebox: React.FC<Props> = ({
  backgroundColor,
  textColor,
  title,
  totalLabel,
  totalValue,
  children,
  startCollapsed = true,
  taxesDisclaimer
}) => {
  const [open, setOpen] = React.useState<boolean>(!startCollapsed)

  return (
    <div className={styles.root(textColor, backgroundColor)}>
      <div className={styles.content}>
        <div className={styles.title} onClick={() => setOpen(!open)}>
          <span>{title}</span>
          <div className={styles.arrow}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</div>
        </div>
        <Collapse in={open}>
          <div className={styles.childrenContainer}>{children}</div>
        </Collapse>
      </div>
      <div className={styles.total(backgroundColor)}>
        <span>{totalLabel}</span>
        <span>{totalValue}</span>
      </div>
      {!!taxesDisclaimer && taxesDisclaimer !== "" && (
        <div className={styles.taxesDisclaimer(backgroundColor)}>{taxesDisclaimer}</div>
      )}
    </div>
  )
}

export default Pricebox
