import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    fontFamily: "Roboto",
    flexDirection: "row",
    width: "70vw",
    height: "70vh",
    "@media screen and (max-width: 425px)": {
      width: "100%",
      height: "100%",
      flexDirection: "column"
    }
  }),
  mapContainer: css({
    width: "100%",
    height: "100%"
  }),
  leftColumn: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      width: "376px",
      height: "100%",
      backgroundColor: theme.colors.background_primary,
      padding: "0px 24px",
      overflow: "scroll",
      boxSizing: "content-box"
    }),
  mobileNavBar: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      padding: "8px 24px",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: theme.colors.background_variant,
      " > p": {
        display: "flex",
        fontFamily: "Roboto",
        flexDirection: "row",
        alignItems: "center",
        fontSize: "18px",
        margin: 0,
        fontWeight: 300,
        lineHeight: "1.33",
        color: theme.colors.text_primary
      }
    })
}
