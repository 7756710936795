import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      padding: "8px",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      fontSize: "14px",
      fontWeight: 300,
      alignItems: "center",
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      height: mini ? "auto" : "48px",
      boxSizing: "border-box",
      "@media screen and (max-width:425px)": {
        height: "auto"
      }
    }),
  content: (mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: mini ? "column" : "row", // forcing mobile view
      justifyContent: "space-between",
      marginLeft: "8px",
      cursor: "pointer",
      boxSizing: "border-box",
      flex: 1,
      "@media screen and (max-width:425px)": {
        flexFlow: "column"
      }
    }),
  mainContent: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  radio: css({
    width: "24px !important",
    height: "24px !important"
  }),
  arrow: (t: Theme) =>
    css({
      color: `${t.colors.text_primary} !important`,
      transition: "transform .3s ease !important"
    }),
  arrowButton: css({
    alignSelf: "center",
    margin: "0px !important",
    padding: "0px !important",
    width: "24px !important",
    height: "24px !important"
  }),
  arrowContainer: css({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end"
  }),
  arrowClosed: css({
    transform: "rotate( 0deg)"
  }),
  arrowOpened: css({
    transform: "rotate( -180deg )"
  }),
  arrowRootContainer: (mini: boolean | undefined) =>
    css({
      display: mini ? "none" : "block",
      "@media screen and (max-width:425px)": {
        display: "none"
      }
    })
}
