import { Brand, Cluster, Segment, Leg, Price, ServiceType, UpSellFare, UpSellPrice, Service } from "../../model"

export type ServiceState = "NOT_INCLUDED" | "INCLUDED" | "PAID" | "VERIFY"

export interface MappedServiceState {
  type: ServiceType
  state: ServiceState
  count: number
  show: boolean
}

export interface BrandMappedServices {
  handOnBaggageState: MappedServiceState
  carryOnBaggageState: MappedServiceState
  baggageState: MappedServiceState
  seatState: MappedServiceState
  refundsState: MappedServiceState
  mealsState: MappedServiceState
  changesState: MappedServiceState
  mileageState: MappedServiceState
  internetState: MappedServiceState
}

export interface MergedBrand extends Brand {
  upsell_price: UpSellPrice
  price: Price
  selected: boolean
  mappedServices: BrandMappedServices
  sameMappedServices: boolean
}

export interface MergedLeg extends Leg {
  brands: MergedBrand[]
}

export interface MergedSegment extends Segment {
  legs: MergedLeg[]
}

const compareBrands = (leg1: MergedLeg, leg2: MergedLeg): boolean => {
  if (leg1.brands.length !== leg2.brands.length) {
    return false
  }

  for (const b1 of leg1.brands) {
    const b2 = leg2.brands.find(b => b1.name === b.name)
    if (b2 == null) {
      return false
    }

    for (const s1 of b1.services) {
      const s2 = b2.services.find(s => s1.type === s.type && s1.charge_type === s.charge_type)
      if (s2 == null) {
        return false
      }
    }
  }

  return true
}

const mergeLeg = (legs: MergedLeg[], pos: number, idx: number): [MergedLeg[], number] => {
  if (pos === legs.length - 1) {
    return [legs, idx]
  }

  if (!compareBrands(legs[pos], legs[pos + 1])) {
    return [legs, idx]
  }

  const newLegs = [
    ...legs.slice(0, pos),
    {
      ...legs[pos],
      destination: legs[pos + 1].destination
    },
    ...legs.slice(pos + 2)
  ]
  return mergeLeg(newLegs, pos, idx + 1)
}

const mergeLegs = (legs: MergedLeg[]): MergedLeg[] => {
  var pos = 0
  var idx = 0
  var end = legs.length - 1
  while (pos < end) {
    const [newLegs, newIdx] = mergeLeg(legs, pos, idx)

    legs = newLegs
    idx = newIdx + 1
    end = legs.length - 2
    pos++
  }
  return legs
}

export const mapLegs = (
  legs: Leg[],
  upsellFares: UpSellFare[],
  segmentIdx: number,
  selectedBrand: number
): MergedLeg[] => {
  const mergedLegs: MergedLeg[] = legs.map(leg => {
    const legBrands: MergedBrand[] = upsellFares.map((fare, jdx) => {
      const lb = fare.segments[segmentIdx].legs.find(l => l.id === leg.id)!.brand
      return {
        ...lb,
        upsell_price: fare.upsell_price,
        price: fare.price,
        selected: selectedBrand === jdx,
        mappedServices: mapBrandServices(lb.services),
        sameMappedServices: false
      }
    })
    return {
      ...leg,
      brands: legBrands
    }
  })

  return mergeLegs(mergedLegs)
}

export const calculateClusterPrice = (cluster: Cluster, selectedBrand: number): number => {
  const fares = cluster.upsell_fares
  return fares ? fares[selectedBrand].price.total : cluster.price.total
}

export const fillSelectedBrand = (cluster: Cluster) => {
  const fares = cluster.upsell_fares
  const pos = fares?.findIndex(f => f.price.total === cluster.price.total) || 0
  return pos > -1 ? pos : 0
}

export const fillSelectedBrandForBrandName = (cluster: Cluster, brandName: string) => {
  const fares = cluster.upsell_fares
  const pos = fares?.findIndex(f => f.segments[0].legs[0].brand.name === brandName) || 0
  return pos > -1 ? pos : 0
}

const checkServiceState = (allServices: Service[], type: ServiceType): MappedServiceState => {
  const services = allServices.filter(s => s.type === type)
  if (services.length === 0) {
    return { state: "VERIFY", count: 0, type: type, show: true }
  }

  const included = services.filter(s => s.charge_type === "INCLUDED")
  if (included.length > 0) {
    return { state: "INCLUDED", count: included.length, type: type, show: true }
  }

  const chargeable = services.filter(s => s.charge_type === "CHARGEABLE")
  if (chargeable.length > 0) {
    return { state: "PAID", count: chargeable.length, type: type, show: true }
  }

  return { state: "NOT_INCLUDED", count: 0, type: type, show: true }
}

const checkServicesState = (services: Service[], types: ServiceType[]): MappedServiceState => {
  const states = types.map(t => checkServiceState(services, t))

  const included = states.find(s => s[0] === "INCLUDED")
  const paid = states.find(s => s[0] === "PAID")
  const notIncluded = states.find(s => s[0] === "NOT_INCLUDED")

  return included ? included : paid ? paid : notIncluded ? notIncluded : states[0]
}

const mapBrandServices = (services: Service[]): BrandMappedServices => {
  return {
    handOnBaggageState: checkServicesState(services, ["HAND_ON_BAGGAGE"]),
    carryOnBaggageState: checkServicesState(services, ["CARRY_ON_BAGGAGE"]),
    baggageState: checkServicesState(services, ["CHECKED_BAGGAGE", "CHECKED_EXTRA_BAGGAGE"]),
    seatState: checkServicesState(services, [
      "SEAT_SELECTION",
      "BASIC_SEAT_RESERVATION",
      "PREMIUM_SEAT_RESERVATION",
      "SEAT_XL",
      "BASIC_SEAT",
      "PREMIUM_SEAT",
      "PRERESERVED_SEAT",
      "LIE_FLAT_SEAT"
    ]),
    refundsState: checkServicesState(services, [
      "REFUNDABLE_TICKET",
      "REFUND_AFTER_DEPARTURE",
      "REFUND_BEFORE_DEPARTURE"
    ]),
    mealsState: checkServicesState(services, ["MEAL", "ALCOHOLIC_DRINK", "SNACK", "BEVERAGE"]),
    changesState: checkServicesState(services, [
      "CHANGE_ANY_TIME",
      "CHANGE_AFTER_DEPARTURE",
      "CHANGE_BEFORE_DEPARTURE",
      "SAME_DAY_CHANGE",
      "CHANGEABLE_TICKET"
    ]),
    mileageState: checkServicesState(services, ["MILEAGE_ACCRUAL"]),
    internetState: checkServicesState(services, ["INTERNET_ACCESS", "WIFI_ACCESS", "POCKET_WIFI"])
  }
}

const softCompareBrandMappedServices = (s1: BrandMappedServices, s2: BrandMappedServices): boolean => {
  if (s1.baggageState.state !== s2.baggageState.state || s1.baggageState.count !== s2.baggageState.count) return false
  if (
    s1.carryOnBaggageState.state !== s2.carryOnBaggageState.state ||
    s1.carryOnBaggageState.count !== s2.carryOnBaggageState.count
  )
    return false
  if (s1.seatState.state !== s2.seatState.state || s1.seatState.count !== s2.seatState.count) return false
  if (s1.refundsState.state !== s2.refundsState.state || s1.refundsState.count !== s2.refundsState.count) return false
  if (s1.mealsState.state !== s2.mealsState.state || s1.mealsState.count !== s2.mealsState.count) return false
  if (s1.changesState.state !== s2.changesState.state || s1.changesState.count !== s2.changesState.count) return false
  if (s1.mileageState.state !== s2.mileageState.state || s1.mileageState.count !== s2.mileageState.count) return false
  if (s1.internetState.state !== s2.internetState.state || s1.internetState.count !== s2.internetState.count)
    return false

  return true
}

export const compareWithBrandMappedServicesAtPosition = (
  segments: MergedSegment[],
  idx: number,
  position: number
): boolean => {
  for (const s of segments) {
    for (const l of s.legs) {
      if (!softCompareBrandMappedServices(l.brands[position].mappedServices, l.brands[idx].mappedServices)) return false
    }
  }

  return true
}

export const compareWithPreviousBrandMappedServices = (segments: MergedSegment[], idx: number): boolean => {
  for (let i = 0; i < idx; i++) {
    if (compareWithBrandMappedServicesAtPosition(segments, idx, i)) {
      return true
    }
  }

  return false
}

export const mapChargeType = (chargeType: string): ServiceState => {
  switch (chargeType) {
    case "CHARGEABLE":
      return "PAID"
    case "INCLUDED":
      return "INCLUDED"
    case "NOT_INCLUDED":
      return "NOT_INCLUDED"
    default:
      return "VERIFY"
  }
}

export const hideUnlistedMappedServices = (legs: MergedLeg[]) => {
  const countListedMappedService = (leg: MergedLeg, name: string) => {
    return leg.brands
      .map(b => (b.mappedServices[name] as MappedServiceState).state)
      .map(s => (s !== "VERIFY" ? 1 : 0) as number)
      .reduce((count, s) => count + s)
  }

  const markMappedServiceAsNotShown = (leg: MergedLeg, name: string) => {
    leg.brands.forEach(b => {
      const s = b.mappedServices[name] as MappedServiceState
      s.show = false
    })
  }

  let carryOnCount = 0
  let baggageCount = 0
  let changeCount = 0
  let internetCount = 0
  let mealCount = 0
  let mileageCount = 0
  let refundCount = 0
  let seatCount = 0

  legs.forEach(leg => {
    carryOnCount += countListedMappedService(leg, "carryOnBaggageState")
    baggageCount += countListedMappedService(leg, "baggageState")
    changeCount += countListedMappedService(leg, "changesState")
    internetCount += countListedMappedService(leg, "internetState")
    mealCount += countListedMappedService(leg, "mealsState")
    mileageCount += countListedMappedService(leg, "mileageState")
    refundCount += countListedMappedService(leg, "refundsState")
    seatCount += countListedMappedService(leg, "seatState")
  })

  console.log(seatCount)

  legs.forEach(leg => {
    if (carryOnCount === 0) markMappedServiceAsNotShown(leg, "carryOnBaggageState")
    if (baggageCount === 0) markMappedServiceAsNotShown(leg, "baggageState")
    if (changeCount === 0) markMappedServiceAsNotShown(leg, "changesState")
    if (internetCount === 0) markMappedServiceAsNotShown(leg, "internetState")
    if (mealCount === 0) markMappedServiceAsNotShown(leg, "mealsState")
    if (mileageCount === 0) markMappedServiceAsNotShown(leg, "mileageState")
    if (refundCount === 0) markMappedServiceAsNotShown(leg, "refundsState")
    if (seatCount === 0) markMappedServiceAsNotShown(leg, "seatState")
  })
}
