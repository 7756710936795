import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      backgroundColor: "inherited"
    }),
  title: css({
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "1.5",
    margin: 0,
    marginBottom: "8px"
  }),
  subtitle: css({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.5",
    margin: 0,
    marginBottom: "8px"
  }),
  content: css({
    display: "flex",
    flexDirection: "column"
  }),
  times: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "> span": {
      fontSize: "14px",
      letterSpacing: "0.15px"
    }
  }),
  rail: (t: Theme) =>
    css({
      "&.MuiSlider-rail": {
        backgroundColor: t.colors.background_darker
      }
    }),
  track: (theme: Theme) =>
    css({
      "&.MuiSlider-track": {
        backgroundColor: theme.colors.brand_primary,
        color: theme.colors.brand_primary
      }
    }),
  thumb: (theme: Theme) =>
    css({
      "&.MuiSlider-thumb": {
        backgroundColor: "inherited",
        border: `1px solid ${theme.colors.brand_primary}`
      }
    }),
  buttons: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiFab-root": {
        backgroundColor: "inherited",
        border: `1px solid ${theme.colors.background_darker}`,
        borderRadius: "16px",
        padding: "0 8px",
        textTransform: "none",
        height: "32px",
        boxShadow: "none",
        fontSize: "12px",
        lineHeight: "1.33",
        letterSpacing: "0.13px",
        fontWeight: "normal",
        ":hover": {
          backgroundColor: theme.colors.brand_primary,
          border: `1px solid ${theme.colors.brand_primary}`,
          borderRadius: "16ppx",
          color: theme.colors.background_variant
        },
        "&.Mui-disabled": {
          backgroundColor: theme.colors.brand_primary,
          border: `1px solid ${theme.colors.brand_primary}`,
          borderRadius: "16px",
          color: theme.colors.background_variant
        }
      }
    })
}
