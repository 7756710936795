import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto"
  }),
  closeContainer: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      "> .MuiIconButton-root": {
        color: theme.colors.text_white
      }
    }),
  mainContainer: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1
  }),
  carousel: css({
    width: "75vw",
    height: "75vh",
    "@media screen and (max-width: 1024px)": {
      width: "100%"
    }
  }),
  counter: (theme: Theme) =>
    css({
      marginTop: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: theme.colors.text_white,
      fontSize: "14px",
      "> .MuiSvgIcon-root": {
        fontSize: "14px",
        marginRight: "4px"
      }
    })
}
