import React from "react"
import CheckboxFilter from "@basset-la/components-products/dist/components/CheckboxFilter"
import styles from "./SourceTypeFilters.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import FlightsFiltersCollapsePanel from "../FlightsFiltersCollapsePanel"

export type Option = {
  type: string
  selected?: boolean
}

export interface Props {
  options: Option[]
  onChange: (value: string, checked: boolean) => void
  onDone?: () => void
}

const SourceTypeFilters: React.FC<Props> = ({ options, onChange, onDone }) => {
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.root}>
      <FlightsFiltersCollapsePanel
        renderSummary={<>{t("SourceTypeFilters.title")}</>}
        renderDetails={
          <div className={styles.container}>
            <CheckboxFilter
              options={mapToCheckboxFilterOptions(options)}
              maxItems={options.length}
              onChange={onChange}
              onDone={onDone}
              id={{
                checkbox: "source-filter-checkbox"
              }}
            />
          </div>
        }
      />
    </div>
  )
}

const mapToCheckboxFilterOptions = (options: Option[]) => {
  return options.map(e => ({
    title: e.type,
    value: e.type,
    selected: e.selected
  }))
}

export default SourceTypeFilters
