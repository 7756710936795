import React from "react"
import Button from "@basset-la/components-commons/dist/components/Button"
import AddIcon from "@material-ui/icons/AddCircleOutlineRounded"
import CompanionDataContainer from "./CompanionDataContainer"
import styles from "./RoomSection.styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { CompanionType, RoomCompanionData } from "../../model/roomSection"
import { I18N_NS } from "../../utils/constants"
import { Delete } from "@material-ui/icons"
import Message from "@basset-la/components-commons/dist/components/Message"

interface Props {
  roomNumber: number
  roomCapacity: number
  companions: RoomCompanionData[]
  onAddCompanion: (roomNumber: number) => void
  onChangeCompanionData: (roomNumber: number, index: number, field: string, value: string) => void
  onDeleteAllCompanionInRoom: (roomNumber: number) => void
  onDeleteCompanionInRoom: (roomNumber: number, index: number) => void
  checkoutConfig?: any
  roomError?: string
}

const RoomCompanionsSection: React.FC<Props> = ({
  companions,
  roomNumber,
  roomCapacity,
  onAddCompanion,
  onChangeCompanionData,
  onDeleteAllCompanionInRoom,
  onDeleteCompanionInRoom,
  checkoutConfig,
  roomError
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const count = new Map<CompanionType, number>()
  count["ADULT"] = 1
  count["CHILD"] = 0

  return (
    <div className={styles.companionContainerStyle} key={`companionsContainer-${roomNumber}`}>
      <div className={styles.sectionTitleContainer}>
        <p className={styles.sectionTitle(theme)}>{`${t("RoomCompanionsSection.title")} ${roomNumber + 1}`}</p>
        <Button
          className={styles.addCompanionButton}
          onClick={() => onDeleteAllCompanionInRoom(roomNumber)}
          variant="text"
        >
          <Delete fontSize="small" className={styles.closeIcon(theme)} />
          <span className={styles.deleteAllText}>{t("RoomCompanionsSection.deleteAll")}</span>
        </Button>
      </div>
      {roomError && <Message open variant="fixed" action="error" message={roomError} />}
      {companions.map((companion, i) => {
        count[companion.companion_type]++

        return (
          <CompanionDataContainer
            key={`companion-${i}`}
            title={t(`RoomCompanionsSection.type.${companion.companion_type}`, {
              count: count[companion.companion_type]
            })}
            roomNumber={roomNumber}
            companion={companion}
            index={i}
            onChange={onChangeCompanionData}
            onDelete={onDeleteCompanionInRoom}
            checkoutConfig={checkoutConfig}
          />
        )
      })}
      <Button
        className={styles.addCompanionButton}
        onClick={() => onAddCompanion(roomNumber)}
        variant="text"
        disabled={companions.length === roomCapacity}
      >
        <AddIcon fontSize="small" />
        <span className={styles.addCompanionTitle}>{`${t("RoomCompanionsSection.add_companion")} ${
          companions.length
        }/${roomCapacity}`}</span>
      </Button>
    </div>
  )
}

export default RoomCompanionsSection
