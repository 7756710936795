import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const MultipleAirlinesIcon = React.forwardRef<any, Props>(function MultipleAirlinesIcon(props, ref) {
  const { color, ...rest } = props

  return (
    <svg
      ref={ref}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill={color ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.59249 0H3.45665L5.61706 3.45665H7.99351C8.35214 3.45665 8.64164 3.74615 8.64164 4.10478C8.64164 4.46341 8.35214 4.7529 7.99351 4.7529H5.61706L3.45665 8.20955H2.59249L3.6727 4.7529H1.29625L0.648123 5.61706H0L0.432082 4.10478L0 2.59249H0.648123L1.29625 3.45665H3.6727L2.59249 0Z"
        fill="#BBBBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48085 1.14579H4.34502L6.50543 4.60244H8.88188C9.2405 4.60244 9.53 4.89194 9.53 5.25056C9.53 5.60919 9.2405 5.89869 8.88188 5.89869H6.50543L4.34502 9.35534H3.48085L4.56106 5.89869H2.18461L1.53649 6.76285H0.888363L1.32044 5.25056L0.888363 3.73828H1.53649L2.18461 4.60244H4.56106L3.48085 1.14579Z"
        fill="#4A4A4A"
      />
    </svg>
  )
})
