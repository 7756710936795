import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "8px 16px",
      gap: "8px",
      backgroundColor: t.colors.background_primary,
      cursor: "pointer"
    }),
  providerContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
    justifyContent: "space-between"
  }),
  priceContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "baseline",
    justifyContent: "space-between",
    "@media (max-width: 1366px)": {
      gap: "0px"
    }
  }),
  price: (t: Theme) =>
    css({
      padding: "0 8px",
      "@media (max-width: 1366px)": {
        padding: "0 2px"
      },
      backgroundColor: `${t.colors.brand_primary}20`
    }),
  rateCodeContainer: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }),
  rateCodeIcon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary,
        marginRight: "8px"
      }
    }),
  rateCodeText: (t: Theme) =>
    css({
      color: t.colors.info,
      fontSize: "10px"
    }),
  discount: css({
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "0.13px",
    textAlign: "center",
    borderRadius: "4px",
    color: "#fff",
    height: "16px",
    boxSizing: "border-box",
    backgroundColor: "#579B08",
    padding: "0px 8px 0px 8px",
    display: "flex",
    width: "fit-content",
    alignSelf: "end"
  }),
  tooltip: css({
    "&.MuiTooltip-tooltip": {
      backgroundColor: "#4A4A4A",
      color: "#fff",
      padding: "0px, 8px, 0px, 8px",
      zIndex: 100,
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "0.13px",
      textAlign: "center",
      borderRadius: "4px",
      marginBottom: "5px"
    }
  }),
  icon: css({
    width: "12px !important",
    marginTop: "-4px",
    marginRight: "4px",
    color: "#fff"
  })
}

export default styles
