export const CHECK_ALL = "checkAll"
export const ROUND_TRIP = "ROUND_TRIP"
export const DEFAULT_DEPARTURE_TIME = [0, 24 * 60 - 1]

export const minutesToDuration = (minutes: number) => {
  let hh: any = Math.floor(minutes / 60)
  let mm: any = minutes % 60

  hh = hh < 10 ? `0${hh}` : hh
  mm = mm < 10 ? `0${mm}` : mm

  return `${hh}:${mm}`
}
