import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      width: "100%",
      padding: mini ? "16px 8px" : "0",
      "@media screen and (max-width:425px)": {
        padding: "16px 8px"
      }
    }),
  segment: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    ":last-child": {
      marginBottom: 0
    }
  }),
  segmentTitle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "8px"
  }),
  titleContainer: css({
    display: "flex",
    flexDirection: "row"
  }),
  title: css({
    margin: 0,
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: 1,
    marginLeft: "8px"
  }),
  date: css({
    margin: 0,
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "1.33",
    textAlign: "right"
  }),
  rotated: css({
    transform: "rotate(-180deg)"
  }),
  segmentOption: (t: Theme) =>
    css({
      transition: "all 0.3s ease",
      overflow: "hidden",
      backgroundColor: t.colors.background_primary,
      borderBottom: `1px solid ${t.colors.background_darker}`,
      ":first-child": {
        borderTop: `1px solid ${t.colors.background_darker}`
      },
      ":first-of-type": {
        borderTopLeftRadius: "3px",
        borderTopRightRadius: "3px"
      },
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 0 0 rgba(0, 0, 0, 0.12)",
      ":last-of-type": {
        borderBottom: "none",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
        borderBottomLeftRadius: "3px",
        borderBottomRightRadius: "3px"
      }
    }),
  openedSegmentOption: css({
    "@media screen and (min-width: 1024px)": {
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)",
      margin: "8px -8px"
    }
  }),
  brand: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    borderTop: "1px solid #EAEAEA",
    paddingTop: "16px"
  })
}
