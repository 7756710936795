import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@basset-la/components-commons/dist/components/Button"
import Text from "@basset-la/components-commons/dist/components/Text"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { useTheme } from "@basset-la/themed-components"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import CheckCircle from "@material-ui/icons/CheckCircle"
import CloseIcon from "@material-ui/icons/CloseRounded"
import Error from "@material-ui/icons/Error"
import { I18N_NS } from "../../utils/constants"
import styles from "./ChangedFareDialog.styles"
import { Message } from "@basset-la/components-commons"

export interface Props {
  open: boolean
  currency: string
  oldPrice: number
  newPrice: number
  handleAcceptNewFare: () => void
  handleRejectNewFare: () => void
  closeOnlyUserAction?: boolean
  isMobile?: boolean
}

const ChangedFareDialog: React.FC<Props> = ({
  open,
  currency,
  oldPrice,
  newPrice,
  handleAcceptNewFare,
  handleRejectNewFare,
  isMobile,
  closeOnlyUserAction
}) => {
  const theme = useTheme()

  const { t, i18n } = useTranslation(I18N_NS)

  const locale = getLocale(i18n)

  const difference = newPrice - oldPrice

  const formattedDifference = formatPrice(Math.abs(difference), locale, currency)

  const isRise = difference > 0

  return (
    <Dialog
      open={open}
      keepMounted={closeOnlyUserAction}
      fullScreen={isMobile}
      maxWidth={isMobile ? "xs" : "sm"}
      className={styles.dialog(isMobile || false)}
      onClose={closeOnlyUserAction ? undefined : handleRejectNewFare}
    >
      <div className={styles.closeContainer(isMobile || false)}>
        <IconButton className={styles.closeButton(theme)} size="small" onClick={handleRejectNewFare}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogTitle className={styles.dialogTitle}>
        <Text className={styles.dialogTitle} size={18} variant="light" component="p">
          {t("ChangeFareDialog.title")}
        </Text>
      </DialogTitle>

      <DialogContent className={styles.dialogContent(isMobile || false)}>
        <div>
          <Text size={16} component="p">
            {isRise
              ? t("ChangeFareDialog.riseFare", { change: formattedDifference })
              : t("ChangeFareDialog.decreaseFare", { change: formattedDifference })}
          </Text>

          <div className={styles.priceContainer(isMobile || false)}>
            <div className={`${styles.priceTextContainer} ${styles.oldPriceContainer(theme, isMobile || false)}`}>
              <Error className={styles.errorIcon} color="error" />
              <Text className={styles.oldPriceText(theme)} size={16} component="span">
                {t("ChangeFareDialog.total", {
                  total: formatPrice(oldPrice, locale, currency)
                })}
              </Text>
            </div>
            <div className={`${styles.priceTextContainer} ${styles.newPriceContainer(theme)}`}>
              <CheckCircle className={styles.checkIcon(theme)} />
              <Text className={styles.newPriceText(theme)} size={16} component="span">
                {t("ChangeFareDialog.total", {
                  total: formatPrice(newPrice, locale, currency)
                })}
              </Text>
            </div>
          </div>
          <div className={styles.info}>
            <Message open variant="fixed" action="info" message={t("ChangeFareDialog.info")} />
          </div>
          <Text size={16} component="p">
            {t("ChangeFareDialog.selectAction")}
          </Text>
        </div>
      </DialogContent>

      <DialogActions className={styles.dialogActions(isMobile || false)}>
        <Button
          className={styles.cancelButton(isMobile || false)}
          onClick={handleRejectNewFare}
          variant="outlined"
          fullWidth={isMobile}
        >
          {t("ChangeFareDialog.cancelLabel")}
        </Button>
        <Button
          className={styles.acceptButton(isMobile || false)}
          onClick={handleAcceptNewFare}
          variant="contained"
          fullWidth={isMobile}
        >
          {t("ChangeFareDialog.actionLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangedFareDialog
