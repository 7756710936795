import React from "react"

export const DinnerAndLunchIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="#FFF" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.233 6.76139C12.173 7.82139 10.7396 8.15472 9.71964 7.68139L8.73964 8.66139L12.8596 12.7814C13.1196 13.0414 13.1196 13.4614 12.8596 13.7214C12.5996 13.9814 12.1796 13.9814 11.9196 13.7214L7.79964 9.60139L3.67298 13.7147C3.41298 13.9747 2.99298 13.9747 2.73298 13.7147C2.47298 13.4547 2.47298 13.0347 2.73298 12.7747L8.77298 6.73472C8.29964 5.71472 8.63298 4.28139 9.69298 3.22139C10.973 1.94806 12.7996 1.70806 13.773 2.68139C14.753 3.66139 14.5063 5.48806 13.233 6.76139ZM7.08631 7.00139L5.19964 8.88806L2.40631 6.10139C1.58631 5.27472 1.41298 4.05472 1.88631 3.06139C2.08631 2.64806 2.63964 2.56139 2.95964 2.88139L7.08631 7.00139Z"
    />
  </svg>
)
