import React from "react"
import TextField, { OutlinedTextFieldProps } from "@material-ui/core/TextField"
import styles from "./SearchboxTextField.styles"
import { useTheme } from "@basset-la/themed-components"
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput"
import InputAdornment from "@material-ui/core/InputAdornment"

export interface Props extends Omit<OutlinedTextFieldProps, "variant" | "ref"> {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
}

const SearchboxTextField = React.forwardRef<any, Props>((props, ref) => {
  const theme = useTheme()

  const { className, leftIcon, rightIcon, InputProps, ...rest } = props

  const customInputProps: Partial<OutlinedInputProps> = {
    startAdornment: leftIcon ? <InputAdornment position="start">{leftIcon}</InputAdornment> : undefined,
    endAdornment: rightIcon ? <InputAdornment position="end">{rightIcon}</InputAdornment> : undefined
  }

  const mergedInputProps = Object.assign(InputProps ? InputProps : {}, customInputProps)

  return (
    <TextField
      InputProps={mergedInputProps}
      variant="outlined"
      className={`${styles.root(theme)} ${className}`}
      ref={ref}
      {...rest}
    />
  )
})

export default SearchboxTextField
