import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      width: "528px",
      boxSizing: "border-box",
      borderRadius: "8px",
      padding: "32px",
      display: "flex",
      flexDirection: "column"
    }),
  title: css({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "24px"
  }),
  content: css({
    display: "flex",
    flexDirection: "column"
  }),
  nameContainer: css({
    display: "flex",
    width: "100%",
    flexDirection: "row",
    marginBottom: "24px"
  }),
  nameTextField: css({
    flex: 1,
    marginRight: "8px",
    ":last-of-type": {
      marginRight: 0
    }
  }),
  email: css({
    width: "100%",
    marginBottom: "24px"
  }),
  documentContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  documentForm: css({
    flex: 1
  }),
  documentFormElements: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "flex-start",
    ">div": {
      boxSizing: "border-box",
      paddingRight: "8px",
      ":last-of-type": {
        paddingRight: 0
      }
    },
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: "0"
    }
  }),
  buttonIcon: (t: Theme) =>
    css({
      "&.MuiIconButton-root": {
        height: "24px",
        padding: 0,
        marginTop: "8px",
        marginLeft: "8px",
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary
        }
      }
    }),
  documentType: css({
    flex: 3,
    minWidth: 0
  }),
  documentNumber: css({
    flex: 5
  }),
  documentCountry: css({
    flex: 3,
    minWidth: 0
  }),
  documentValidThru: css({
    flex: 5,
    minWidth: 0
  }),
  addDocument: css({
    marginBottom: "24px !important"
  }),
  addDocumentContent: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }),
  birthDateContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px"
  }),
  birthDate: css({
    display: "flex",
    flexDirection: "row",
    ">div": {
      boxSizing: "border-box",
      paddingRight: "8px",
      ":last-of-type": {
        paddingRight: 0
      }
    }
  }),
  birthDateDay: css({
    flex: 1
  }),
  birthDateMonth: css({
    flex: 2
  }),
  birthDateYear: css({
    flex: 1
  }),
  textMargin: css({
    marginBottom: "8px"
  }),
  countryGenderContainer: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "24px",
    ">div": {
      boxSizing: "border-box",
      paddingRight: "8px",
      ":last-of-type": {
        paddingRight: 0
      }
    }
  }),
  nationality: css({
    flex: 2
  }),
  gender: css({
    flex: 1,
    minWidth: 0
  }),
  phoneNumberContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  phone: css({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "24px",
    ">div": {
      boxSizing: "border-box",
      paddingRight: "8px",
      ":last-of-type": {
        paddingRight: 0
      }
    }
  }),
  phoneType: css({
    flex: 3,
    flexShrink: 1,
    minWidth: 0
  }),
  areaCode: css({
    flex: 3,
    flexShrink: 0
  }),
  countryCode: css({
    flex: 3,
    flexShrink: 0
  }),
  phoneNumber: css({
    flex: 4,
    flexShrink: 0
  }),
  buttonContainer: css({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  })
}
