import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  content: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_darker,
      display: "flex",
      padding: 8,
      borderRadius: 10,
      marginTop: 12
    }),

  rowStyle: css({
    display: "flex"
  }),

  smCol: css({
    flex: 1
  }),

  mdCol: css({
    flex: 3
  }),

  topContainer: css({ marginBottom: 6 }),

  clusterContainer: css({
    paddingTop: 80,
    textAlign: "center"
  }),

  column1: css({
    width: 32,
    height: 16,
    borderRadius: 8,
    float: "right"
  }),

  column2: css({
    width: 32,
    height: 16,
    borderRadius: 8
  }),

  column3: css({
    width: 136,
    height: 16,
    borderRadius: 8,
    float: "right"
  }),

  column4: css({
    height: 48,
    backgroundColor: "#ffffff",
    borderBottom: "1px solid rgba(0, 0, 0, 0.14)"
  }),

  cluster1: css({
    height: 16,
    width: 101,
    maxWidth: "60%",
    display: "inline-block",
    borderRadius: 8
  }),

  cluster2: css({
    height: 16,
    width: 209,
    maxWidth: "90%",
    display: "inline-block",
    borderRadius: 8,
    marginTop: 48
  }),

  icon: (j: number) =>
    css({
      transform: j === 0 ? "rotate(90deg)" : "rotate(-90deg)",
      width: 20,
      height: 20,
      color: "#ccc"
    })
}
