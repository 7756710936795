import React from "react"
import styles from "./Footer.styles"
import { useTheme } from "@basset-la/themed-components"
import { I18N_NS } from "../../utils/constants"
import Link from "@material-ui/core/Link"
import SocialLink from "../SocialLink/SocialLink"
import { SocialLinkItem } from "../../models/types"
import { useTranslation } from "react-i18next"

export interface Props {
  emails?: string[]
  phones?: string[]
  socialNetworks?: SocialLinkItem[]
}

const Footer: React.FC<Props> = ({ emails, phones, socialNetworks }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleSocialLinkClick = (url: string) => {
    window.open(url, "_blank")?.focus()
  }

  const hasEmails = emails && emails.length > 0
  const hasPhones = phones && phones.length > 0
  const hasSocialNetworks = socialNetworks && socialNetworks.length > 0

  return (
    <div className={styles.root}>
      {(hasEmails || hasPhones || hasSocialNetworks) && (
        <div className={styles.main(theme.colors.brand_primary)}>
          <div className={styles.contactContainer}>
            {hasEmails && (
              <div className={styles.columnContainer}>
                <div className={styles.header}>{t("Footer.email")}</div>
                {emails!.map((e, i) => (
                  <Link key={i} className={`${styles.content} ${styles.link}`} href={`mailto:${e}`} underline="none">
                    {e}
                  </Link>
                ))}
              </div>
            )}
            {hasPhones && (
              <div className={styles.columnContainer}>
                <div className={styles.header}>{t("Footer.phone")}</div>
                {phones!.map((p, i) => (
                  <Link key={i} className={`${styles.content} ${styles.link}`} href={`tel:${p}`} underline="none">
                    {p}
                  </Link>
                ))}
              </div>
            )}
            {hasSocialNetworks && (
              <div className={styles.columnContainer}>
                <div className={styles.header}>{t("Footer.social")}</div>
                <div className={styles.socialContainer}>
                  {socialNetworks!.map((item, i) => (
                    <div key={i} className={styles.content}>
                      <SocialLink color={theme.colors.background_primary} item={item} onClick={handleSocialLinkClick} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      <div className={styles.main(theme.colors.brand_darker)}>
        <div className={styles.linkContainer}>
          <div className={styles.columnContainer}>
            <Link className={styles.link} href="/terms">
              {t("Footer.terms")}
            </Link>
          </div>
          <div className={styles.columnContainer}>
            <Link className={styles.link} href="/privacy">
              {t("Footer.privacy")}
            </Link>
          </div>
          <div className={styles.columnContainer}>
            <Link className={styles.link} href="/faq">
              {t("Footer.faq")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
