import React from "react"
import { CancelPenalty, MealPlan as MealPlanType } from "../../model"
import styles from "./AccommodationDetailPDFRateDetail.styles"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import i18next from "i18next"
import MealPlan from "../SimpleAccommodationInfo/MealPlan"
import { RoomOnly as RoomOnlyIcon } from "../../icons/RoomOnly"
import moment from "moment"

export interface Props {
  mealPlan: MealPlanType
  cancelPolicies: CancelPenalty[]
  refundable?: boolean
}

const AccommodationDetailPDFRateDetail: React.FC<Props> = ({ mealPlan, cancelPolicies, refundable }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const getCancelPolicyText = (cancelPolicies: CancelPenalty[]) => {
    if (!refundable) {
      return <p>• {t("AccommodationDetailPDFRateDetail.cannot_cancel")}</p>
    }
    return (
      <p>
        •{" "}
        <span className={styles.cancelPolicy(theme)}>
          {t("AccommodationDetailPDFRateDetail.free_cancel_until", {
            date: moment(cancelPolicies[0].start)
              .utc()
              .format("DD/MM/YYYY"),
            time: moment(cancelPolicies[0].start)
              .utc()
              .format("hh:mm")
          })}
        </span>
      </p>
    )
  }

  const getMealPlanText = (mealPlan: MealPlanType, t: i18next.TFunction) => {
    if (mealPlan && mealPlan === "ROOM_ONLY") {
      return (
        <p className={styles.mealPlanContainer}>
          • <RoomOnlyIcon />
          <span className={styles.mealPlanNotIncluded(theme)}>
            {t("AccommodationDetailPDFRateDetail.mealplan_not_included")}
          </span>
        </p>
      )
    }
    return (
      <p>
        <MealPlan mealPlan={mealPlan} />
      </p>
    )
  }

  return (
    <div className={styles.root}>
      {getMealPlanText(mealPlan, t)}
      {getCancelPolicyText(cancelPolicies)}
    </div>
  )
}

export default AccommodationDetailPDFRateDetail
