import React from "react"
import moment, { Moment } from "moment"

export interface ColorProps {
  primary: string
}

export interface AbstractDatePickerProps {
  minDate?: Moment
  maxDate?: Moment
  mobile?: boolean
  colors?: ColorProps
  numberOfMonths?: number
}

const MAX_DAYS = 330

abstract class AbstractDatePicker<P extends AbstractDatePickerProps> extends React.Component<P> {
  static defaultProps = {
    minDate: moment(),
    maxDate: moment().add(MAX_DAYS, "days")
  }

  isOutsideRange = (day: Moment) => {
    const { minDate, maxDate } = this.props
    const absMinDate = minDate?.local().startOf("day")
    const absMaxDate = maxDate?.local().startOf("day")
    const absDay = day.startOf("day")

    return absDay.isBefore(absMinDate) || absDay.isAfter(absMaxDate)
  }

  getOrientation = () => {
    const { mobile } = this.props
    return mobile ? "vertical" : "horizontal"
  }

  getNumberOfMonths = () => {
    const { mobile, numberOfMonths } = this.props

    if (numberOfMonths) return numberOfMonths

    return mobile ? 12 : 2
  }
}

export default AbstractDatePicker
