import React, { useState } from "react"
import CheckboxFilter, { CheckboxFilterOption } from "@basset-la/components-products/dist/components/CheckboxFilter"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Stars from "../Stars"
import styles from "./StarsFilter.styles"
import AccommodationFiltersCollapsePanel from "../AccommodationFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  options: Option[]
  onChange: (value: number, checked: boolean) => void
  onDone?: () => void
}

export type Option = {
  stars: number
  selected?: boolean
}

const StarsFilter: React.FC<Props> = ({ collapsible, options, onChange, onDone }) => {
  const { t } = useTranslation(I18N_NS)

  const [allOptionsSelected, setAllOptionsSelected] = useState(false)

  const mapToCheckboxOptions = (options: Option[]): CheckboxFilterOption[] => {
    return [
      {
        title: t("StarsFilter.all"),
        value: undefined,
        selected: allOptionsSelected
      },
      ...options.map(o => ({
        title: "",
        icon: (
          <div className={styles.stars}>
            <Stars amount={o.stars} />
          </div>
        ),
        value: o.stars,
        selected: o.selected
      }))
    ]
  }

  const onFilterChange = (value: number, checked: boolean): void => {
    const newValue = value === undefined
    if (allOptionsSelected !== newValue) {
      setAllOptionsSelected(newValue)
    }
    onChange(value, checked)
  }

  return (
    <div className={styles.root}>
      {!collapsible && (
        <CheckboxFilter
          maxItems={5}
          onChange={onFilterChange}
          title={t("StarsFilter.title")}
          options={mapToCheckboxOptions(options)}
          onDone={onDone}
          id={{
            doneBtn: "stars-filter-checkbox-filter-done-btn"
          }}
        />
      )}
      {collapsible && (
        <AccommodationFiltersCollapsePanel
          renderSummary={<>{t("StarsFilter.title")}</>}
          renderDetails={
            <div className={styles.container}>
              <CheckboxFilter
                maxItems={5}
                onChange={onFilterChange}
                options={mapToCheckboxOptions(options)}
                onDone={onDone}
                id={{
                  doneBtn: "stars-filter-checkbox-done-btn"
                }}
              />
            </div>
          }
        />
      )}
    </div>
  )
}

export default StarsFilter
