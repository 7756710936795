import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BaggageIconNotFounded: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg {...rest} width="20" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2578 4.78046C2.97071 4.78046 2.73714 5.00154 2.73714 5.27355V9.32692H7.07349V5.27355C7.07349 5.00154 6.84015 4.78046 6.55305 4.78046H3.2578ZM7.85437 5.23294V9.32692H8.56577C9.06699 9.32692 9.47687 9.71527 9.47687 10.1899V12V12V18.3504C9.47687 18.7932 9.06699 19.1557 8.56577 19.1557H7.85437V19.8697C7.85437 19.9413 7.78817 20 7.70711 20H6.96316C6.8821 20 6.81589 19.9413 6.81589 19.8697V19.1557H2.99496V19.8697C2.99496 19.9413 2.92854 20 2.84748 20H2.10374C2.02269 20 1.95626 19.9413 1.95626 19.8697V19.1557H1.24486C0.74387 19.1557 0.333984 18.7932 0.333984 18.3504V12V12V10.1899C0.333984 9.71527 0.74387 9.32692 1.24486 9.32692H1.95626V5.23294C1.95626 4.55313 2.54007 4 3.2578 4H6.55305C7.27057 4 7.85437 4.55313 7.85437 5.23294Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C6.24 2 4 4.24 4 7C4 9.76 6.24 12 9 12C11.76 12 14 9.76 14 7C14 4.24 11.76 2 9 2ZM9 7.5C8.725 7.5 8.5 7.275 8.5 7V5C8.5 4.725 8.725 4.5 9 4.5C9.275 4.5 9.5 4.725 9.5 5V7C9.5 7.275 9.275 7.5 9 7.5ZM8.5 8.5V9.5H9.5V8.5H8.5Z"
      fill="#D0021B"
    />
  </svg>
)
