import React from "react"
import { css } from "@emotion/css"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"

import { Accommodation } from "../../model/types"
import { useTheme, Theme } from "@basset-la/themed-components"

interface Props {
  accommodation: Accommodation
}

const Rooms: React.FC<Props> = ({ accommodation }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const buildGuestsText = (i: number) => {
    let adults = 0
    let childAges = {}
    const room = accommodation.rooms[i]
    const childAgesArr = room.child_ages || []

    adults += room.capacity - childAgesArr.length
    for (let j = 0; j < childAgesArr.length; j++) {
      const age = childAgesArr[j]
      if (!childAges[age]) {
        childAges[age] = 1
      } else {
        childAges[age] = childAges[age] + 1
      }
    }

    const adultText = t("Voucher.adult", { count: adults })
    const childrenText = Object.keys(childAges)
      .map(key => {
        const age = parseInt(key)
        const ageText = t("Voucher.age", { count: age })
        const totalText = t("Voucher.child", { count: childAges[age] })
        return `${totalText} (${ageText})`
      })
      .join(" / ")

    if (childrenText.length) {
      return [adultText, childrenText].join(" / ")
    }

    return adultText
  }

  return (
    <>
      {accommodation.rooms.map((room, i) => (
        <div key={i}>
          <div className={styles.rooms(theme)}>
            <table className={styles.container}>
              <tr className={styles.verticalAlign}>
                <td className={`${styles.hotelData} ${styles.customWidth("40%")}`}>
                  <span className={styles.primaryTitle(theme)}>{t("Rooms.title", { number: i + 1 })}</span>
                  <br />
                  <span className={styles.primaryTitle(theme)}>{room.name}</span>
                </td>

                <td className={`${styles.hotelData} ${styles.customWidth("30%")}`}>
                  <span className={styles.secondaryTitle(theme)}>{t("Rooms.guestTitle")}</span>
                  <br />
                  <span className={styles.secondaryTitle(theme)}>
                    {room.guest.firstname} {room.guest.lastname}
                  </span>
                </td>

                <td className={`${styles.hotelData} ${styles.customWidth("30%")}`}>
                  <span>&nbsp;</span>
                  <br />
                  <span className={styles.secondaryTitle(theme)}>
                    {room.guest.document.type} {room.guest.document.number}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div className={styles.rooms(theme)}>
            <div className={styles.containerGuest}>
              <span className={styles.secondaryTitle(theme)}>{t("Voucher.guestsTitle")}</span>
              <br />
              <span className={styles.secondaryTitle(theme)}>{buildGuestsText(i)}</span>
            </div>
          </div>
          {room.guest.companions?.length && (
            <div className={`${styles.rooms(theme)} ${styles.companionsContainer(theme)}`}>
              <span className={styles.secondaryTitle(theme)}>{t("Voucher.companionsTitle")}</span>
              <div className={styles.companionDataContainer}>
                {room.guest.companions.map(c => (
                  <div className={styles.companionData}>
                    <span className={styles.secondaryTitle(theme)}>
                      {c.firstname} {c.lastname}
                    </span>
                    <span className={styles.secondaryTitle(theme)}>
                      {c.document.type} {c.document.number}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  )
}

const styles = {
  rooms: (theme: Theme) =>
    css({
      border: `1px solid ${theme.colors.background_darker}`,
      margin: "8px 0"
    }),
  secondaryTitle: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 300
    }),
  container: css({
    width: "100%"
  }),
  containerGuest: css({
    width: "100%",
    marginRight: "10px",
    padding: "15px"
  }),
  primaryTitle: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 500
    }),
  childAges: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 100,
      fontSize: 12
    }),
  hotelData: css({
    alignSelf: "stretch",
    margin: "5px 0px",
    padding: "10px 10px",
    flexGrow: 1
  }),
  line: (theme: Theme) =>
    css({
      margin: "0 10px",
      borderTop: `1px dashed ${theme.colors.background_darker}`,
      borderBottom: "none"
    }),
  verticalAlign: css({
    verticalAlign: "top"
  }),
  customWidth: (width: string) =>
    css({
      width: width
    }),
  companionsContainer: (theme: Theme) =>
    css({
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      gap: "16px"
    }),
  companionDataContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  }),
  companionData: css({
    display: "flex",
    gap: "16px"
  })
}

export default Rooms
