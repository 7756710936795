import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Cancel"
import InfoIcon from "@material-ui/icons/Info"
import WarningIcon from "@material-ui/icons/Error"

export type Action = "success" | "warning" | "error" | "info"

export const actionIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}
