import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const CheckInAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.88 1.66659H10.6667C11.4 1.66659 12 2.26659 12 2.99992V12.3333C12 13.0666 11.4 13.6666 10.6667 13.6666H1.33333C0.6 13.6666 0 13.0666 0 12.3333V2.99992C0 2.26659 0.6 1.66659 1.33333 1.66659H4.12C4.4 0.893252 5.13333 0.333252 6 0.333252C6.86667 0.333252 7.6 0.893252 7.88 1.66659ZM6.66667 2.33325C6.66667 1.96659 6.36667 1.66659 6 1.66659C5.63333 1.66659 5.33333 1.96659 5.33333 2.33325C5.33333 2.69992 5.63333 2.99992 6 2.99992C6.36667 2.99992 6.66667 2.69992 6.66667 2.33325ZM6 4.33325C7.10667 4.33325 8 5.22659 8 6.33325C8 7.43992 7.10667 8.33325 6 8.33325C4.89333 8.33325 4 7.43992 4 6.33325C4 5.22659 4.89333 4.33325 6 4.33325ZM2 11.3999V12.3333H10V11.3999C10 10.0666 7.33333 9.33325 6 9.33325C4.66667 9.33325 2 10.0666 2 11.3999Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
