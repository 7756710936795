import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      backgroundColor: theme.colors.background_primary,
      justifyContent: "space-between",
      "@media(max-width: 425px)": {
        flexDirection: "column",
        justifyContent: "none"
      }
    }),
  titleContainer: css({
    display: "flex",
    flexDirection: "row"
  }),
  titleRoot: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
      maxWidth: "100%",
      alignItems: "center",
      transition: "all 0.3s ease",
      padding: "24px 0 24px 16px",
      "@media(max-width: 425px)": {
        padding: "8px",
        borderBottom: `1px solid ${theme.colors.background_variant}`,
        justifyContent: "space-between"
      }
    }),
  title: css({
    margin: 0,
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "1.33",
    marginLeft: "8px",
    overflow: "hidden",
    height: "24px"
  }),
  button: css({
    "&.MuiButtonBase-root": {
      "@media(max-width: 425px)": {
        flex: 1
      }
    }
  }),
  indicator: (theme: Theme) =>
    css({
      "&.MuiTabs-indicator": {
        backgroundColor: theme.colors.brand_darker
      }
    }),
  tab: (theme: Theme) =>
    css({
      "&.MuiTab-root": {
        textTransform: "none",
        fontWeight: "normal",
        flex: 1,
        padding: 0,
        display: "flex",
        borderLeft: `1px solid ${theme.colors.background_darker}`,
        "@media(max-width: 425px)": {
          minHeight: "54px",
          fontSize: "10px",
          borderLeft: "none",
          borderRight: `1px solid ${theme.colors.background_darker}`,
          ":last-of-type": {
            borderRight: "none"
          }
        }
      }
    })
}
