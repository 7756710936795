import React from "react"
import MaskedInput from "react-text-mask"

interface Props {
  inputRef: (ref: HTMLInputElement | null) => void
}

const CustomDatePickerInput: React.FC<Props> = (props: Props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      placeholderChar={"\u2000"}
      mask={[/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
      guide={false}
    />
  )
}

export default CustomDatePickerInput
