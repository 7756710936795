import React from "react"
import SnackbarMessage from "./SnackbarMessage"
import FixedMessage from "./FixedMessage"
import { Action } from "./types"

export type Variant = "snackbar" | "fixed"

export interface Props {
  /**
   * Param to manange the message display
   * @param open
   * @value boolean
   * @required true
   */
  open: boolean
  /**
   * Message to show
   * @param message
   * @value string
   * @required true
   */
  message: string
  /**
   * Action style for message
   * @param action
   * @value "success" | "warning" | "error" | "info"
   * @required true
   */
  action: Action
  /**
   * Variant for message
   * @param variant
   * @value "snackbar" | "fixed"
   * @required true
   */
  variant: Variant
  /**
   * Function to execute onClose message
   * @param onClose
   * @value function
   * @required false
   */
  onClose?: () => void
}

const Message: React.FunctionComponent<Props> = ({ open, message, action, variant, onClose }) => {
  switch (variant) {
    case "snackbar":
      return <SnackbarMessage open={open} message={message} action={action} onClose={onClose} />
    case "fixed":
      return <FixedMessage open={open} message={message} action={action} onClose={onClose} />
    default:
      return <></>
  }
}

export default Message
