import { css } from "@emotion/css"

export default {
  root: css({
    fontSize: "12px",
    fontFamily: "Roboto"
  }),
  title: css({
    fontSize: "24px"
  }),
  bigNumber: css({
    fontSize: "18px"
  }),
  passanger: css({
    span: { display: "inline-block" },
    "& > span::after ": {
      content: `", "`
    },
    " span:last-of-type::after ": {
      content: `""`
    }
  }),
  price: css({
    marginTop: "16px"
  })
}
