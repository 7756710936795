import React from "react"
import styles from "./SocialMenu.styles"
import { useTheme } from "@basset-la/themed-components"
import CloseIcon from "@material-ui/icons/Close"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import SocialLink from "../SocialLink/SocialLink"
import { SocialLinkItem } from "../../models/types"
import UserMenu from "./UserMenu"

interface Props {
  open: boolean
  anchor: Element
  socialNetworks: SocialLinkItem[]
  showBackOfficeOption: boolean
  isAuthenticated: boolean
  onClose: () => void
  onSelect: (url: string) => void
  onAccessBackOffice?: () => void
  onLogout?: () => void
}

const SocialMenu: React.FC<Props> = ({
  open,
  anchor,
  socialNetworks,
  isAuthenticated,
  showBackOfficeOption,
  onClose,
  onSelect,
  onAccessBackOffice,
  onLogout
}) => {
  const theme = useTheme()

  const handleClose = () => {
    onClose()
  }

  const handleClick = (url: string) => {
    onSelect(url)
  }

  const handleAccessBackOffice = () => {
    if (onAccessBackOffice) {
      onAccessBackOffice()
    }
  }

  const handleLogout = () => {
    if (onLogout) {
      onLogout()
    }
  }

  return (
    <Menu className={styles.menu} anchorEl={anchor} open={open} onClose={handleClose}>
      <MenuItem className={styles.item} onClick={handleClose}>
        <CloseIcon className={styles.closeIcon(theme)} />
      </MenuItem>
      <div className={styles.icons}>
        {socialNetworks.map((item, idx) => (
          <MenuItem key={idx} className={styles.item}>
            <SocialLink color={theme.colors.brand_primary} item={item} onClick={handleClick} />
          </MenuItem>
        ))}
      </div>
      {isAuthenticated && (
        <UserMenu
          variant="column"
          showBackOfficeOption={showBackOfficeOption}
          onLogout={handleLogout}
          onAccessBackOffice={handleAccessBackOffice}
        />
      )}
    </Menu>
  )
}

export default SocialMenu
