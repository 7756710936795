import React, { useState } from "react"
import { MatrixModel, MatrixStopModel } from "../../model/types"
import styles from "./PricesMatrix.styles"
import Table from "@material-ui/core/Table"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Fab from "@material-ui/core/Fab"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import Icon from "../../icons/Icon"
import { formatPrice } from "@basset-la/components-products/dist/utils/helpers/"
import { useTheme } from "@basset-la/themed-components"

const MAX_COLUMNS = 5

export interface CellInfo {
  airline?: string
  stops?: number
}

export interface Props {
  dataSource: Array<MatrixModel>
  onClick: (item: CellInfo) => void
}

const PricesMatrix: React.FC<Props> = ({ dataSource, onClick }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const theme = useTheme()
  const [initialColumn, setInitialColumn] = useState<number>(0)
  const [selectedItem, setSelectedItem] = useState<CellInfo | null>(null)

  const handleClick = (item: CellInfo) => {
    onClick(item)
  }

  const handleMouseOver = (item: CellInfo) => {
    setSelectedItem(item)
  }

  const handleMouseOut = () => {
    setSelectedItem(null)
  }

  const handlePrevPage = () => {
    if (initialColumn > 0) {
      setInitialColumn(initialColumn - 1)
    }
  }

  const handleNextPage = () => {
    if (initialColumn < MAX_COLUMNS - 1) {
      setInitialColumn(initialColumn + 1)
    }
  }

  const getCheapest = (): number => {
    const flatmap: Array<number> = []
    dataSource
      .map(d => d.stops)
      .forEach(stops => {
        const arr = Object.keys(stops)
        for (let i = 0; i < arr.length; i++) {
          const value = stops[arr[i]] as MatrixStopModel
          flatmap.push(value.total)
        }
      })
    const cheapest = flatmap.reduce((x, y) => (x < y ? x : y))
    return cheapest
  }

  const data = dataSource.filter((_, idx) => idx >= initialColumn && idx < initialColumn + MAX_COLUMNS)
  const cheapest = getCheapest()

  const buildRow = (stop: number, title: string) => {
    return (
      <TableRow>
        <TableCell
          className={styles.tableCellRoot(
            theme,
            true,
            false,
            selectedItem?.stops === stop && selectedItem?.airline === undefined
          )}
          onMouseOver={() => {
            handleMouseOver({ stops: stop })
          }}
          onClick={() => handleClick({ stops: stop })}
        >
          {title}
        </TableCell>
        {data.map((ds, idx) => (
          <TableCell
            key={idx}
            className={styles.tableCellRoot(
              theme,
              false,
              ds.stops[stop] && ds.stops[stop].total === cheapest,
              (selectedItem?.airline === ds.airline.code && selectedItem?.stops === undefined) ||
                (selectedItem?.airline === undefined && selectedItem?.stops === stop) ||
                (selectedItem?.airline === ds.airline.code && selectedItem?.stops === stop && ds.stops[stop])
            )}
            onMouseOver={() => {
              ds.stops[stop] ? handleMouseOver({ airline: ds.airline.code, stops: stop }) : handleMouseOut()
            }}
            onClick={() => {
              if (ds.stops[stop]) {
                handleClick({ airline: ds.airline.code, stops: stop })
              }
            }}
          >
            {ds.stops[stop] ? formatPrice(ds.stops[stop].total, i18n.language, ds.stops[stop].currency) : "-"}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  return (
    <div className={styles.container}>
      <Table
        className={styles.table}
        onMouseOut={() => {
          handleMouseOut()
        }}
      >
        <TableRow>
          <TableCell
            className={styles.tableCellRoot(theme, true, false, false)}
            onMouseOver={() => {
              handleMouseOut()
            }}
          >
            {t("FlightMatrix.airlines")}
          </TableCell>
          {data
            .map(ds => ds.airline)
            .map((a, idx) => (
              <TableCell
                key={idx}
                className={styles.tableCellRoot(
                  theme,
                  false,
                  false,
                  selectedItem?.airline === a.code && selectedItem?.stops === undefined
                )}
                onMouseOver={() => {
                  handleMouseOver({ airline: a.code })
                }}
                onClick={() => handleClick({ airline: a.code })}
              >
                <span>
                  <Icon icon={a.code} /> {a.name}
                </span>
              </TableCell>
            ))}
        </TableRow>
        {buildRow(0, t("FlightMatrix.non_stop"))}
        {buildRow(1, t("FlightMatrix.one_stop"))}
        {buildRow(2, t("FlightMatrix.two_more_stops"))}
      </Table>

      {dataSource.length > MAX_COLUMNS && (
        <>
          <Fab className={styles.buttonFab(theme, "left")} disabled={initialColumn === 0} onClick={handlePrevPage}>
            <KeyboardArrowLeftIcon className={styles.icon(theme, initialColumn !== 0)} />
          </Fab>
          <Fab
            className={styles.buttonFab(theme, "right")}
            disabled={initialColumn + MAX_COLUMNS === dataSource.length}
            onClick={handleNextPage}
          >
            <KeyboardArrowRightIcon className={styles.icon(theme, initialColumn + MAX_COLUMNS < dataSource.length)} />
          </Fab>
        </>
      )}
    </div>
  )
}

export default PricesMatrix
