import React from "react"
import styles from "./Voucher.styles"
import Divider from "@material-ui/core/Divider"
import VoucherHeader from "./VoucherHeader"
import SegmentOptionDetail from "../SegmentOptionDetail/SegmentOptionDetail"
import { SegmentOption, Passenger } from "../../model"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import VoucherPassengerList from "./VoucherPassengerList"
import VoucherSegmentTitle from "./VoucherSegmentTitle"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  /**
   * Logo Image URL
   * @param logo
   * @value string
   * @required true
   */
  logo: string
  /**
   * basset Reservation ID
   * @param reservationId
   * @value string
   * @required true
   */
  reservationId: string
  /**
   * Airline Record Locator used to web check in
   * @param recordLocator
   * @value string
   * @required true
   */
  recordLocator: string
  /**
   * Array of SegmentOption
   * @param segmentOption
   * @value SegmentOption type
   * @required true
   */
  segmentOption: SegmentOption[]
  /**
   * Array of passengers
   * @param passengerList
   * @value Passenger type
   * @required true
   */
  passengerList: Passenger[]
  /**
   * Client text used to add extra information to voucher
   * @param extraInformation
   * @value string
   * @required false
   */
  extraInformation?: string

  airlineRecordLocator?: string
}

const Voucher: React.FC<Props> = ({
  logo,
  reservationId,
  recordLocator,
  segmentOption,
  passengerList,
  extraInformation,
  airlineRecordLocator
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  return (
    <div className={styles.root(theme)}>
      <VoucherHeader
        logo={logo}
        reservationId={reservationId}
        recordLocator={recordLocator}
        airlineRecordLocator={airlineRecordLocator}
      />
      <Divider />
      <div className={styles.paxs}>
        <VoucherPassengerList passengerList={passengerList} />
      </div>
      <Divider />
      <div className={styles.flight}>
        {segmentOption.map((s, i) => (
          <div key={i}>
            <VoucherSegmentTitle
              origin={s.legs[0].origin.code}
              destination={s.legs[s.legs.length - 1].destination.code}
            />
            <SegmentOptionDetail key={i} segmentOption={s} />
          </div>
        ))}
      </div>
      <Divider />
      <div className={styles.info(theme)}>{t("Voucher.airportAlert")}</div>
      {extraInformation && (
        <>
          <Divider />
          <div className={styles.info(theme)}>{extraInformation}</div>
        </>
      )}
    </div>
  )
}

export default Voucher
