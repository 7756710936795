import React from "react"
import { Price, ChargeType } from "../../model/types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Currency from "currency.js"
import PriceboxWeb from "@basset-la/components-products/dist/components/PriceboxWeb"
import { PriceItem } from "@basset-la/components-products/dist/components/PriceboxWeb/PriceboxWeb"

const PAISTaxType = "O5"

export interface Props {
  price: Price
  isMobile: boolean
}

const calculatePAISTax = (price: Price): number => {
  let paisTax = Currency(0)

  if (price.adults && price.adults.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.adults.taxes))
  } else if (price.seniors && price.seniors.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.seniors.taxes))
  } else if (price.disabled && price.disabled.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.disabled.taxes))
  } else if (price.children && price.children.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.children.taxes))
  } else if (price.infants && price.infants.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.infants.taxes))
  }
  return paisTax.value
}

const PAISTax = (taxes?: ChargeType[]): number => {
  if (!taxes) {
    return 0
  }
  const pais = taxes.filter(t => t.type === PAISTaxType)
  return pais.length > 0 ? pais[0].total : 0
}

const ClusterPriceWeb: React.FC<Props> = ({ price, isMobile }) => {
  const { t } = useTranslation(I18N_NS)

  const { adults, children, infants, seniors, disabled, currency, total, charges } = price

  const mainFare: { label: string; fare: number } = { fare: 0, label: "" }

  const paisTax = calculatePAISTax(price)
  const taxes = Currency(price.taxes).subtract(paisTax).value

  if (adults && adults.quantity > 0) {
    mainFare.fare = adults.fare / adults.quantity
    mainFare.label = t("ClusterPriceDesktop.adult_fare")
  } else if (seniors && seniors.quantity > 0) {
    mainFare.fare = seniors.fare / seniors.quantity
    mainFare.label = t("ClusterPriceDesktop.senior_fare")
  } else if (disabled && disabled.quantity > 0) {
    mainFare.fare = disabled.fare / disabled.quantity
    mainFare.label = t("ClusterPriceDesktop.disabled")
  }

  const getBreakdown = () => {
    const breakdown: PriceItem[] = []

    if (adults && adults.quantity > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.adult_count", { count: adults.quantity }),
        value: adults.fare
      })
    }
    if (seniors && seniors.quantity > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.senior_count", { count: seniors.quantity }),
        value: seniors.fare
      })
    }
    if (disabled && disabled.quantity > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.disabled_count", { count: disabled.quantity }),
        value: disabled.fare
      })
    }
    if (children && children.quantity > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.children_count", { count: children.quantity }),
        value: children.fare
      })
    }
    if (infants && infants.quantity > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.infants_count", { count: infants.quantity }),
        value: infants.fare
      })
    }
    if (taxes > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.taxes"),
        value: taxes
      })
    }
    if (charges >= 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.charges"),
        value: charges
      })
    } else {
      breakdown.push({
        label: t("ClusterPriceDesktop.discount"),
        value: charges
      })
    }
    if (paisTax > 0) {
      breakdown.push({
        label: t("ClusterPriceDesktop.paisTax"),
        value: paisTax
      })
    }

    return breakdown
  }

  const getTotal = () => {
    return {
      label: t("ClusterPriceDesktop.total"),
      value: total
    }
  }

  return (
    <PriceboxWeb
      isMobile={isMobile}
      currency={currency}
      total={getTotal()}
      breakdown={getBreakdown()}
      id={{
        totalPrice: "cluster-total-price"
      }}
    />
  )
}

export default ClusterPriceWeb
