import { css } from "@emotion/css"
import React from "react"

interface Props {
  value: string
  bold?: boolean
}

const StrikethroughPrice = ({ value, bold }: Props) => <p className={`${textStyle(bold)}`}>{value}</p>

const textStyle = (bold?: boolean) =>
  css({
    display: "inline-block",
    fontSize: bold ? "18px" : "14px",
    fontWeight: bold ? "400" : "300",
    lineHeight: bold ? "1.33" : "1.14",
    letterSpacing: bold ? "0.6px" : "0.1px",
    color: "#9b9b9b",
    textDecoration: "line-through"
  })

export default StrikethroughPrice
