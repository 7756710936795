import React from "react"
import { Price, SegmentOption, Service } from "../../model/types"
import { I18N_NS } from "../../utils/constants/constants"
import BaggagesInfo from "../BaggagesInfo/BaggagesInfo"
import styles from "./SegmentOptionDetail.styles"
import LegStopDetail from "./LegStopDetail"
import LegDetailSection from "./LegDetailSection"
import { DepartureIcon } from "../../icons/DepartureIcon"
import moment from "moment"
import { ArrivalIcon } from "../../icons/ArrivalIcon"
import { getDefaultLanguage, getBaggageConfiguration } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import SegmentOptionSummary from "../SegmentOptionSummary"
import { useTheme } from "@basset-la/themed-components"
import PolicyInfo from "../PolicyInfo"
import Text from "@basset-la/components-commons/dist/components/Text"
import BrandServicesInfo from "../BrandServicesInfo"

export interface Props {
  segmentOption: SegmentOption
  price?: Price
  services?: Service[]

  showSummary?: boolean

  /**
   * set `true` to force mobile L&F
   * @param {boolean} mini
   * @default false
   * */
  mini?: boolean
}

const SegmentOptionDetail: React.FunctionComponent<Props> = ({
  segmentOption,
  price,
  services,
  mini = false,
  showSummary = false
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const theme = useTheme()

  const lng = getDefaultLanguage(i18n)
  const from = moment(segmentOption.legs[0].departure_date)
    .locale(lng)
    .format(t("SegmentOptionDetail.dateFormat"))
  const to = moment(segmentOption.legs[segmentOption.legs.length - 1].arrival_date)
    .locale(lng)
    .format(t("SegmentOptionDetail.dateFormat"))
  const { bagIncluded, baggagesAmount, baggagesWeight, carryOnIncluded } = getBaggageConfiguration(
    segmentOption.baggage_allowances
  )

  const hasPolicyInfo =
    price &&
    ((price.adults && price.adults.exchange_policy) ||
      (price.infants && price.infants.exchange_policy) ||
      (price.children && price.children.exchange_policy) ||
      (price.seniors && price.seniors.exchange_policy) ||
      (price.disabled && price.disabled.exchange_policy))

  const brandedFare = segmentOption.legs.find(l => l.brand?.name)?.brand!.name || undefined

  return (
    <div className={styles.root(theme)}>
      {brandedFare && (
        <Text className={styles.brandedFareText} color="primary" size={14} variant="regular">
          {t("SegmentOptionDetail.brandedFare", { name: brandedFare })}
        </Text>
      )}
      <div className={styles.baggageTitleContainer}>
        {(!services || services.length === 0) && (
          <BaggagesInfo
            bagIncluded={bagIncluded}
            carryOnIncluded={carryOnIncluded}
            baggageAmount={baggagesAmount}
            baggageWeight={baggagesWeight}
          />
        )}
        {services && services.length > 0 && (
          <BrandServicesInfo baggageAllowances={segmentOption.baggage_allowances} services={services} />
        )}
      </div>
      {mini && hasPolicyInfo && <PolicyInfo isTooltip={false} price={price!} />}
      <div className={styles.segmentOptionDetail(theme)}>
        {showSummary && (
          <div className={styles.segmentOption(theme)}>
            <SegmentOptionSummary segmentOption={segmentOption} max={true} />
          </div>
        )}

        <LegDetailSection
          mini={mini}
          icon={<DepartureIcon />}
          firstSection={
            <p className={styles.departureArrivalText(theme)}>
              {t("SegmentOptionDetail.departureText", { from: from })}
            </p>
          }
        />
        {segmentOption.legs.map((e, i) => {
          return <LegStopDetail mini={mini} leg={e} nextLeg={segmentOption.legs[i + 1]} key={i} />
        })}
        <LegDetailSection
          mini={mini}
          icon={<ArrivalIcon />}
          firstSection={
            <p className={styles.departureArrivalText(theme)}>{t("SegmentOptionDetail.arrivalText", { to: to })}</p>
          }
        />
      </div>
    </div>
  )
}

export default SegmentOptionDetail
