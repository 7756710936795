import React from "react"
import { useTranslation } from "react-i18next"
import Button from "@basset-la/components-commons/dist/components/Button"
import ButtonMui from "@material-ui/core/Button"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTheme } from "@basset-la/themed-components"
import { CircularProgress, ListItem } from "@material-ui/core"
import { I18N_NS } from "../../utils/constants"
import styles from "./RegionsOptionsList.style"
import Checkbox from "@basset-la/component-material-ui-wrapper/dist/Checkbox"
import { Option } from "./RegionFilter"

interface RegionsOptionListProps {
  items: Option[]
  onSelectItem: (item: Option) => void
  onDone?: () => void
  loading?: boolean
  isMobile?: boolean
  multiple?: boolean
  disableSelectAll?: boolean
  disableClearAll?: boolean
  onSelectAll?: (items: Option[]) => void
  onClearAll?: () => void
}

const RegionsOptionsList: React.FC<RegionsOptionListProps> = ({
  items,
  multiple = false,
  onSelectItem,
  onDone,
  loading = false,
  isMobile = false,
  disableClearAll = false,
  disableSelectAll = false,
  onSelectAll,
  onClearAll
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleChange = (value: Option) => (event: React.ChangeEvent<HTMLInputElement>) => {
    value.selected = event.target.checked
    onSelectItem(value)
  }
  const handleSelectAll = () => {
    items.forEach(o => {
      o.selected = true
    })
    onSelectAll && onSelectAll(items)
  }

  const handleClearAll = () => {
    items.forEach(o => {
      o.selected = false
    })
    onClearAll && onClearAll()
  }

  return (
    <ul className={styles.list}>
      {loading && (
        <div className={styles.loadingContainer}>
          <CircularProgress className={styles.progress(theme)} size={24} />
        </div>
      )}
      {!loading && (
        <>
          {multiple && !!items.length && (
            <div className={styles.optionHeaderContainer}>
              <Button
                className={`${styles.optionHeaderButton} ${styles.optionHeaderLeftButton}`}
                variant="text"
                onClick={handleClearAll}
                disabled={disableClearAll}
              >
                {t("AmenitiesSelect.clearSelection")}
              </Button>
              <Button
                className={`${styles.optionHeaderButton} ${styles.optionHeaderRightButton}`}
                variant="text"
                onClick={handleSelectAll}
                disabled={disableSelectAll}
              >
                {t("AmenitiesSelect.selectAll")}
              </Button>
            </div>
          )}
          {items.map((o, i) => {
            return (
              <ListItem key={i} className={styles.option(theme)} disableGutters button>
                <div className={styles.optionItem}>
                  {multiple && (
                    <Checkbox
                      onChange={handleChange(o)}
                      className={styles.optionCheckbox(isMobile)}
                      checked={!!o.selected}
                    />
                  )}
                  <Text size={isMobile ? 16 : 14} variant="regular">
                    {o.region.name}
                  </Text>
                </div>
              </ListItem>
            )
          })}
          {items.length && onDone && (
            <ButtonMui className={styles.link(theme)} onClick={onDone}>
              {t("CheckboxFilter.done")}
            </ButtonMui>
          )}
        </>
      )}
    </ul>
  )
}

export default RegionsOptionsList
