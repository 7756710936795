import React, { useEffect, useRef, useState } from "react"
import styles, { CARD_GAP } from "./Carousel.styles"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useTheme } from "@basset-la/themed-components"

export type CardMovement = "no" | "prev" | "next"
const animationTimeout = 500
const buttonSize = 40

export interface Props {
  width: string
  children: React.ReactNode
  autoMoveTo?: number
  onMoved?: (first: number) => void
}

const Carousel: React.FC<Props> = ({ width, children, autoMoveTo, onMoved }) => {
  const theme = useTheme()

  const [items, setItems] = useState(React.Children.toArray(children))
  const [first, setFirst] = useState(0)
  const [movement, setMovement] = useState<CardMovement>("no")
  const [maxVisibleItems, setMaxVisibleItems] = useState(1)
  const [cardWidth, setCardWidth] = useState(0)
  const [hideButtons] = useState(autoMoveTo !== undefined)
  const [prevButtonPos, setPrevButtonPos] = useState([0, 0])
  const [nextButtonPos, setNextButtonPos] = useState([0, 0])
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const [windowScroll, setWindowScroll] = useState({ x: 0, y: 0 })

  const contentRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    const onScroll = () => {
      setWindowScroll({ x: window.scrollX, y: window.scrollY })
    }
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    const arr = React.Children.toArray(children)
    setItems(arr)
    setFirst(prev => (prev >= arr.length ? 0 : prev))
  }, [children])

  useEffect(() => {
    if (contentRef && contentRef!.current) {
      let elem: HTMLElement = contentRef!.current
      let visibleHeight = elem.clientHeight

      while (elem.parentElement) {
        if (elem.clientHeight < visibleHeight) {
          visibleHeight = elem.clientHeight
        }
        elem = elem.parentElement
      }

      const rect = contentRef!.current!.getBoundingClientRect()
      const top = rect.top + visibleHeight / 2 - buttonSize / 2
      const left = rect.right - buttonSize

      setPrevButtonPos([top, rect.left])
      setNextButtonPos([top, left])

      if (cardRef && cardRef!.current) {
        const max = Math.floor(contentRef!.current!.clientWidth / (cardRef!.current!.clientWidth + CARD_GAP))

        setMaxVisibleItems(max)
        setCardWidth(cardRef!.current!.clientWidth)
      }
    } else {
      setCardWidth(0)
    }
  }, [contentRef, cardRef, items.length, windowSize, windowScroll])

  useEffect(() => {
    // TIP: Movement must be reset to "no" after any change.
    if (movement !== "no") {
      setTimeout(() => {
        setMovement("no")
      }, animationTimeout - 50)
    }
  }, [movement])

  useEffect(() => {
    if (autoMoveTo === undefined || autoMoveTo === first) return
    setFirst(autoMoveTo)
    setMovement(autoMoveTo < first ? "prev" : "next")
  }, [autoMoveTo, first])

  const getItems = () => {
    const selectedItems = []
    if (movement === "next") {
      selectedItems.push(items[first - 1])
    }
    for (var i = first; i < Math.min(items.length, first + maxVisibleItems + 1); i++) {
      selectedItems.push(items[i])
    }
    if (movement === "prev" && first + maxVisibleItems + 1 < items.length) {
      selectedItems.push(items[first + maxVisibleItems + 1])
    }
    return selectedItems
  }

  const handlePrevButtonClick = () => {
    const n = first - 1
    setFirst(n)
    setMovement("prev")
    if (onMoved) onMoved(n)
  }

  const handleNextButtonClick = () => {
    const n = first + 1
    setFirst(n)
    setMovement("next")
    if (onMoved) onMoved(n)
  }

  const showNext = first < items.length - 1 - maxVisibleItems

  return (
    <div className={styles.root(width)}>
      {!hideButtons && first > 0 && (
        <div className={styles.prevButton(prevButtonPos[0], prevButtonPos[1])} onClick={handlePrevButtonClick}>
          <ArrowBackIosIcon className={styles.icon(theme)} />
        </div>
      )}
      <div ref={contentRef} className={styles.content}>
        {getItems().map((it, i) => (
          <div key={i} ref={cardRef} className={styles.card(movement, cardWidth, animationTimeout)}>
            {it}
          </div>
        ))}
      </div>
      {!hideButtons && showNext && (
        <div className={styles.nextButton(nextButtonPos[0], nextButtonPos[1])} onClick={handleNextButtonClick}>
          <ArrowForwardIosIcon className={styles.icon(theme)} />
        </div>
      )}
    </div>
  )
}

export default Carousel
