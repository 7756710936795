import * as React from "react"
import { checkBoxStyle } from "./checkboxStyle"
import MaterialUICheckbox, { CheckboxProps } from "@material-ui/core/Checkbox"

const DEFAULT_COLOR = "#126aff"

export interface Props extends CheckboxProps {
  backgroundColor?: string
}

const Checkbox = ({ backgroundColor, ...rest }: Props) => {
  const styles = checkBoxStyle(backgroundColor || DEFAULT_COLOR)

  return (
    <MaterialUICheckbox
      classes={{
        root: styles.rootStyle,
        checked: styles.checkedStyle
      }}
      {...rest}
    />
  )
}

export default Checkbox
