import { css } from "@emotion/css"

export default {
  root: (backgroundColor: string) =>
    css({
      display: "flex",
      flexDirection: "row",
      padding: "8px",
      backgroundColor: backgroundColor
    }),
  iconContainer: css({
    width: "16px",
    height: "16px"
  }),
  sectionsContainer: (mini: boolean | undefined) =>
    css({
      marginLeft: "8px",
      display: "flex",
      flexDirection: "row",
      flex: 1,
      flexWrap: mini ? "wrap" : "nowrap",
      "> div": {
        display: "flex",
        flex: 1,
        minWidth: mini ? "256px" : "auto"
      },
      "@media screen and (max-width:425px)": {
        flexWrap: "wrap",
        "> div": {
          minWidth: "256px"
        }
      }
    })
}
