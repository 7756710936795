// @ts-nocheck
import React from "react"
import { PriceBreakdown } from "../../model/types"
import Pricebox from "@basset-la/components-products/dist/components/Pricebox"
import PriceboxRow from "@basset-la/components-products/dist/components/PriceboxRow"
import { useTheme } from "@basset-la/themed-components"

import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { getLocale, formatPrice } from "@basset-la/components-products/dist/utils/helpers"

interface Props {
  priceBreakdown: PriceBreakdown
}

const PriceboxContainer: React.FC<Props> = ({ priceBreakdown }) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const { currency, total, rate, taxes, charges } = priceBreakdown
  return (
    <div>
      <Pricebox
        backgroundColor={theme.colors.brand_primary}
        textColor={theme.colors.text_white}
        title={t("FlightsPricebox.title")}
        totalLabel={t("FlightsPricebox.total")}
        totalValue={formatPrice(total, locale, currency)}
        startCollapsed={true}
      >
        <PriceboxRow
          label={t("FlightsPricebox.rate")}
          value={formatPrice(rate.total, locale, currency)}
          variant="collapsible"
        >
          {rate.adult && (
            <PriceboxRow
              label={t("FlightsPricebox.adult", { count: rate.adult.quantity })}
              value={formatPrice(rate.adult.total, locale, currency)}
            />
          )}
          {rate.children && (
            <PriceboxRow
              label={t("FlightsPricebox.child", { count: rate.children.quantity })}
              value={formatPrice(rate.children.total, locale, currency)}
            />
          )}
          {rate.infants && (
            <PriceboxRow
              label={t("FlightsPricebox.infant", { count: rate.infants.quantity })}
              value={formatPrice(rate.infants.total, locale, currency)}
            />
          )}
          {rate.seniors && (
            <PriceboxRow
              label={t("FlightsPricebox.senior", { count: rate.seniors.quantity })}
              value={formatPrice(rate.seniors.total, locale, currency)}
            />
          )}
          {rate.disableds && (
            <PriceboxRow
              label={t("FlightsPricebox.disabled", { count: rate.disableds.quantity })}
              value={formatPrice(rate.disableds.total, locale, currency)}
            />
          )}
        </PriceboxRow>
        <PriceboxRow
          label={t("FlightsPricebox.taxes")}
          value={formatPrice(taxes.total, locale, currency)}
          variant="collapsible"
        >
          {taxes.agencyVAT !== undefined && (
            <PriceboxRow
              label={t("FlightsPricebox.agencyVAT")}
              value={formatPrice(taxes.agencyVAT, locale, currency)}
            />
          )}
          <PriceboxRow
            label={t("FlightsPricebox.resellerVAT")}
            value={formatPrice(taxes.resellerVAT, locale, currency)}
          />
          {taxes.paisTax !== undefined && (
            <PriceboxRow label={t("FlightsPricebox.paisTax")} value={formatPrice(taxes.paisTax, locale, currency)} />
          )}
          <PriceboxRow
            label={t("FlightsPricebox.extraTaxes")}
            value={formatPrice(taxes.extraTaxes, locale, currency)}
          />
        </PriceboxRow>
        <PriceboxRow
          label={t("FlightsPricebox.charges")}
          value={formatPrice(charges.total, locale, currency)}
          variant="collapsible"
        >
          <PriceboxRow
            label={t("FlightsPricebox.resellerCharges")}
            value={formatPrice(charges.resellerCharges.total, locale, currency)}
            variant="collapsible"
          >
            <PriceboxRow
              label={t("FlightsPricebox.markup")}
              value={formatPrice(charges.resellerCharges.markup, locale, currency)}
            />
            <PriceboxRow
              label={t("FlightsPricebox.operatingCost")}
              value={formatPrice(charges.resellerCharges.operatingCost, locale, currency)}
            />
            <PriceboxRow
              label={t("FlightsPricebox.fees")}
              value={formatPrice(charges.resellerCharges.fee, locale, currency)}
            />
          </PriceboxRow>
          {charges.agencyCharges !== undefined && (
            <PriceboxRow
              label={t("FlightsPricebox.agencyCharges")}
              value={formatPrice(charges.agencyCharges.total, locale, currency)}
              variant="collapsible"
            >
              <PriceboxRow
                label={t("FlightsPricebox.markup")}
                value={formatPrice(charges.agencyCharges.markup, locale, currency)}
              />
              <PriceboxRow
                label={t("FlightsPricebox.operatingCost")}
                value={formatPrice(charges.agencyCharges.operatingCost, locale, currency)}
              />
              <PriceboxRow
                label={t("FlightsPricebox.fees")}
                value={formatPrice(charges.agencyCharges.fee, locale, currency)}
              />
              {charges.agencyCharges.adjustment !== undefined && (
                <PriceboxRow
                  label={t("FlightsPricebox.adjustment")}
                  value={formatPrice(charges.agencyCharges.adjustment, locale, currency)}
                />
              )}
            </PriceboxRow>
          )}
        </PriceboxRow>
      </Pricebox>
    </div>
  )
}

export default PriceboxContainer
