import React from "react"

import { useTranslation } from "react-i18next"

import Text from "@basset-la/components-commons/dist/components/Text"
import { useTheme } from "@basset-la/themed-components"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import Select from "@material-ui/core/Select/Select"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"

import { I18N_NS } from "../../utils/constants/constants"
import styles from "./CurrencySelector.styles"

export interface Props {
  containerClassName?: string
  selectedCurrency?: string
  currencies?: string[]
  onSelectCurrency: (currency: string) => void
}

const CurrencySelector: React.FC<Props> = ({
  containerClassName,
  selectedCurrency = "ARS",
  currencies = ["ARS", "USD", "EUR", "GBP"],
  onSelectCurrency
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const handleOnChange = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: string
    }>
  ) => {
    onSelectCurrency(event.target.value)
  }

  return (
    <div className={`${styles.root}${containerClassName ? ` ${containerClassName}` : ""}`}>
      <Text component="span" size={14} variant="regular">
        {t("CurrencySelector.currency")}
      </Text>
      <Select
        className={styles.select(theme)}
        onChange={handleOnChange}
        value={selectedCurrency}
        variant="outlined"
        IconComponent={KeyboardArrowDownOutlined}
      >
        {currencies.map((currency, index) => (
          <MenuItem key={index} className={styles.menuItem} value={currency}>
            {currency}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default CurrencySelector
