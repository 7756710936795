import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),
  stop: (t: Theme) =>
    css({
      marginTop: "8px",
      " p": {
        alignSelf: "center",
        height: "fit-content",
        width: "100%",
        margin: 0,
        fontSize: "14px",
        lineHeight: "1.14",
        letterSpacing: "0.15px",
        color: t.colors.text_primary
      }
    }),
  stopDuration: (mini: boolean | undefined) =>
    css({
      fontWeight: 500,
      textAlign: mini ? "left" : "center",
      marginTop: mini ? "8px !important" : 0,
      "@media screen and (max-width: 425px)": {
        textAlign: "left",
        marginTop: "8px !important"
      }
    }),
  bold: css({
    fontWeight: 500
  }),
  airportChangeContainer: css({
    marginTop: "8px"
  }),
  airportChange: css({
    display: "flex",
    flexDirection: "column",
    paddingLeft: "8px",
    " p": {
      margin: 0
    }
  }),
  airportChangeTitle: css({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    letterSpacing: "0.15px",
    color: colors.YELLOW
  }),
  airportChangeFromTo: (t: Theme) =>
    css({
      fontSize: "13px",
      lineHeight: "1.23",
      letterSpacing: "0.14px",
      color: t.colors.text_primary
    }),
  airportChangeDisclamer: (t: Theme) =>
    css({
      fontSize: "12px",
      lineHeight: "1.33",
      letterSpacing: "0.15px",
      color: t.colors.text_variant
    })
}
