import React from "react"
import styles from "./QuotedPDFHeader.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import { Airport, FlightType } from "../../model"
import { sanitizeAirportName } from "../../utils/helpers"
import CalendarIcon from "@material-ui/icons/CalendarTodayRounded"
import PersonIcon from "@material-ui/icons/PersonRounded"
import moment from "moment"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  total: number
  itineraryNumber: number
  currency: string
  origin: Airport
  destination: Airport
  from: string
  to: string
  flightType: FlightType
  adults?: number
  children?: number
  infants?: number
  disabled?: number
  senior?: number
}

const QuotedPDFHeader: React.FC<Props> = ({
  itineraryNumber,
  total,
  currency,
  flightType,
  from,
  to,
  origin,
  destination,
  adults,
  children,
  disabled,
  infants,
  senior
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()
  const formattedTotal = formatPrice(total, locale, currency, { hideCurrency: true })
  const formattedFrom = moment(from, "YYYY-MM-DD")
    .locale(locale)
    .format("DD MMM YYYY")
  const formattedTo = moment(to, "YYYY-MM-DD")
    .locale(locale)
    .format("DD MMM YYYY")

  let passengers: string[] = []

  if (adults) {
    passengers.push(t("QuotedPDFHeader.adult_count", { count: adults }))
  }
  if (senior) {
    passengers.push(t("QuotedPDFHeader.senior_count", { count: senior }))
  }
  if (disabled) {
    passengers.push(t("QuotedPDFHeader.disabled_count", { count: disabled }))
  }
  if (children) {
    passengers.push(t("QuotedPDFHeader.children_count", { count: children }))
  }
  if (infants) {
    passengers.push(t("QuotedPDFHeader.infants_count", { count: infants }))
  }

  const formattedPassengers = passengers.join(", ")

  let originDest = t("QuotedPDFHeader.multiple_destinations")

  if (flightType !== "MULTIPLE_DESTINATIONS") {
    originDest = `(${origin.code}) ${sanitizeAirportName(origin.name)} - (${destination.code}) ${sanitizeAirportName(
      destination.name
    )}`
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.detail}>
        <span className={styles.title}>{t("QuotedPDFHeader.title", { count: itineraryNumber })}</span>
        <span className={styles.originDest}>{originDest}</span>
        <span className={styles.datePassContainer}>
          <span className={styles.dateContainer}>
            <CalendarIcon className={styles.icon(theme)} />{" "}
            <span className={styles.date}>{`${formattedFrom} - ${formattedTo}`}</span>
          </span>
          <span className={styles.passengersContainer}>
            <PersonIcon className={styles.icon(theme)} />
            <span className={styles.passengers}>{formattedPassengers}</span>
          </span>
        </span>
      </div>
      <div className={styles.price(theme)}>
        <span className={styles.totalLabel}>{t("QuotedPDFHeader.total")}</span>
        <span className={styles.total}>
          <span className={styles.currency}>{currency}</span>
          <span>{formattedTotal}</span>
        </span>
      </div>
    </div>
  )
}

export default QuotedPDFHeader
