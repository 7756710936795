import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    position: "relative",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "center"
  }),
  slider: css({
    width: "100%",
    height: "100%",
    overflow: "hidden !important",
    "> .react-swipeable-view-container": {
      width: "100%",
      height: "100%"
    }
  }),
  leftArrow: css({
    "&.MuiButton-root": {
      position: "absolute",
      left: 0,
      top: "50%",
      transform: "translateY(-50%)"
    }
  }),
  rightArrow: css({
    "&.MuiButton-root": {
      position: "absolute",
      right: 0,
      //top: "50%",
      transform: "traslateY(-50%)"
    }
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        borderRadius: "unset",
        zIndex: 2,
        color: theme.colors.text_white,
        padding: "8px 0",
        minWidth: "unset",
        width: "40px",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.7)"
        }
      }
    }),
  fullScreen: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        position: "absolute",
        zIndex: 2,
        color: theme.colors.text_white,
        minWidth: "unset",
        top: "8px",
        right: "8px",
        padding: 0,
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.7)"
        }
      }
    })
}
