import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      color: t.colors.text_primary,
      fontFamily: "Roboto",
      fontSize: "10px",
      letterSpacing: "0.11px",
      fontWeight: 300,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: t.colors.background_variant,
      height: "100%",
      width: "100%"
    }),
  bold: css({
    fontWeight: 500
  })
}
