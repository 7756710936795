import React, { useState } from "react"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import styles from "./RoomPicker.styles"
import Counter from "@basset-la/components-commons/dist/components/Counter"
import Button from "@material-ui/core/Button"
import cloneDeep from "lodash/cloneDeep"
import AgeSelect from "./AgeSelect"
import { SearchboxChildren, SearchboxRoom } from "../Searchbox"

export interface Props {
  title: string
  room: SearchboxRoom
  maxCapacity: number
  onChange: (room: SearchboxRoom) => void
  onDone: (room: SearchboxRoom) => void
}

const RoomPicker: React.FC<Props> = ({ title, room, maxCapacity, onChange, onDone }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const [currentRoom, setCurrentRoom] = useState<SearchboxRoom>(room)
  const [childrenErrors, setChildrenErrors] = useState<(string | undefined)[]>([])

  const maxGuests = maxCapacity - (currentRoom.adults - currentRoom.children.length)

  const handleOnChangeAdults = (v: number) => {
    const copy = cloneDeep(currentRoom)
    copy.adults = v
    setCurrentRoom(copy)
    if (validate(copy)) {
      onChange(copy)
    }
  }

  const handleOnChangeChildren = (v: number) => {
    const copy = cloneDeep(currentRoom)
    if (copy.children.length > v) {
      copy.children = copy.children.slice(0, v)
    } else if (copy.children.length < v) {
      copy.children.push("NO_VALUE")
    }

    setCurrentRoom(copy)
    if (validate(copy)) {
      onChange(copy)
    }
  }

  const handleChildrenAgeChange = (idx: number) => (value: SearchboxChildren) => {
    const copy: SearchboxRoom = cloneDeep(currentRoom)
    copy.children[idx] = value

    if (childrenErrors[idx]) {
      const errorCopy: (string | undefined)[] = childrenErrors
      errorCopy[idx] = undefined
      setChildrenErrors(errorCopy)
    }

    setCurrentRoom(copy)
    if (validate(copy)) {
      onChange(copy)
    }
  }

  const validate = (r: SearchboxRoom): boolean => {
    let errors: string[] = []
    let valid = true
    for (let idx in r.children) {
      if (r.children[idx] === "NO_VALUE") {
        errors[idx] = t("RoomPicker.missingChildrenError")
        valid = false
      }
    }
    setChildrenErrors(errors)
    return valid
  }

  const handleOnDone = () => {
    if (validate(currentRoom)) {
      onDone(currentRoom)
    }
  }

  return (
    <div className={styles.root(theme)}>
      <span className={styles.title}>{title}</span>
      <div className={styles.pickerContainer}>
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("RoomPicker.adultsTitle")}</span>
            </div>
            <Counter
              onChange={handleOnChangeAdults}
              maxValue={maxGuests + currentRoom.adults}
              value={currentRoom.adults}
              minValue={1}
            />
          </div>
        </div>
        <div className={styles.picker}>
          <div className={styles.mainPicker}>
            <div className={styles.pickerLabelContainer(theme)}>
              <span>{t("RoomPicker.childrenTitle")}</span>
              <span>{t("RoomPicker.childrenDisclamer")}</span>
            </div>
            <Counter
              onChange={handleOnChangeChildren}
              maxValue={maxGuests + currentRoom.children.length}
              value={currentRoom.children.length}
              minValue={0}
            />
          </div>
        </div>
        {currentRoom.children.map((c, idx) => (
          <div key={`chd_${idx}`} className={styles.picker}>
            <div className={styles.mainPicker}>
              <div className={styles.pickerLabelContainer(theme)}>
                <span>{t("RoomPicker.childrenAgePickerTitle", { number: idx + 1 })}</span>
                <span>{t("RoomPicker.childrenAgePickerDisclamer")}</span>
              </div>
              <AgeSelect value={c} onChange={handleChildrenAgeChange(idx)} />
            </div>
            {childrenErrors[idx] && <span className={styles.error(theme)}>{childrenErrors[idx]}</span>}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer(theme)}>
        <Button onClick={handleOnDone} variant="text">
          {t("RoomPicker.done")}
        </Button>
      </div>
    </div>
  )
}

export default RoomPicker
