import { useTheme } from "@basset-la/themed-components"
import Tooltip from "@material-ui/core/Tooltip"
import React from "react"
import { useTranslation } from "react-i18next"
import { ParkingIcon } from "../../icons/ParkingIcon"
import { PoolIcon } from "../../icons/PoolIcon"
import { ReceptionIcon } from "../../icons/ReceptionIcon"
import { WifiIcon } from "../../icons/WifiIcon"
import { I18N_NS } from "../../utils/constants"
import styles from "./Amenities.styles"

export interface Props {
  wifi?: boolean
  pool?: boolean
  parking?: boolean
  receptionAllDay?: boolean
}

const Amenities: React.FunctionComponent<Props> = ({ wifi, pool, parking, receptionAllDay }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  if (!wifi && !pool && !parking && !receptionAllDay) {
    return <></>
  }

  return (
    <div className={styles.root}>
      {wifi && (
        <Tooltip title={<>{t("Amenities.wifi")}</>} placement={"bottom"}>
          <div>
            <WifiIcon color={theme.colors.brand_darker} />
          </div>
        </Tooltip>
      )}
      {pool && (
        <Tooltip title={<>{t("Amenities.pool")}</>} placement={"bottom"}>
          <div>
            <PoolIcon color={theme.colors.brand_darker} />
          </div>
        </Tooltip>
      )}
      {parking && (
        <Tooltip title={<>{t("Amenities.parking")}</>} placement={"bottom"}>
          <div>
            <ParkingIcon color={theme.colors.brand_darker} />
          </div>
        </Tooltip>
      )}
      {receptionAllDay && (
        <Tooltip title={<>{t("Amenities.reception")}</>} placement={"bottom"}>
          <div>
            <ReceptionIcon color={theme.colors.brand_darker} />
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default Amenities
