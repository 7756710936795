import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  container: css({
    position: "relative",
    padding: "8px"
  }),

  table: css({
    "&.MuiTable-root": {
      tableLayout: "fixed",
      boxShadow: "2px 2px 2px 0 rgba(0,0,0,0.24), 0 0 2px 0 rgba(0,0,0,0.12)"
    }
  }),

  tableCellRoot: (t: Theme, isHeader: boolean, isCheapest: boolean, isHighlighted: boolean) =>
    css({
      "&.MuiTableCell-root": {
        fontSize: "12px",
        textAlign: isHeader ? "left" : "center",
        cursor: "pointer",
        whiteSpace: "nowrap",
        lineHeight: "24px",
        overflow: "hidden",
        padding: "4px 8px",
        textOverflow: "ellipsis",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
        color: isCheapest ? t.colors.brand_primary : undefined,
        fontWeight: isCheapest ? "bold" : "lighter",
        backgroundColor: isHighlighted
          ? `${t.colors.brand_primary}20`
          : isHeader
          ? t.colors.background_variant
          : t.colors.background_primary
      }
    }),

  buttonFab: (t: Theme, position: "left" | "right") =>
    css({
      "&.MuiFab-root": {
        backgroundColor: t.colors.background_primary,
        width: "24px",
        height: "24px",
        minHeight: "24px",
        top: "50%",
        margin: "-12px 20px 0 16px",
        position: "absolute",
        borderRadius: "12px",
        left: position === "left" ? "18%" : undefined,
        right: position === "right" ? "2%" : undefined,
        ":hover": {
          backgroundColor: t.colors.background_primary
        }
      },
      "&.MuiFab-root.Mui-disabled": {
        backgroundColor: t.colors.background_variant
      }
    }),

  icon: (t: Theme, enabled: boolean) =>
    css({
      color: enabled ? t.colors.brand_primary : undefined
    })
}
