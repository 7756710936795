import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    backgroundColor: "inherit"
  }),
  container: css({
    paddingBottom: "16px",
    " > div": {
      backgroundColor: "inherit"
    }
  }),
  button: () =>
    css({
      ".MuiButton-root": {
        textTransform: "none",
        alignSelf: "flex-start",
        width: "fit-content",
        height: "fit-content",
        margin: "8px 0px 8px 8px"
      },
      ".MuiButton-text": {
        minWidth: "100px!important",
        fontSize: "14px",
        fontWeight: 500
      }
    })
}
