import React, { useState } from "react"
import { Price, ChargeType } from "../../model/types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import styles from "./ClusterPriceDesktop.styles"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import AddIcon from "@material-ui/icons/AddRounded"
import Divider from "@material-ui/core/Divider"
import { taxes as TaxesTypes } from "../../utils/constants/constants"
import Currency from "currency.js"
import CostEffectivenessDetail, {
  AdjustType
} from "@basset-la/components-products/dist/components/CostEffectivenessDetail"
import { convertToAgencyCostEffectivenessBreakdown } from "../../utils/helpers/helpers"
import { CustomButtonText } from "@basset-la/components-commons/dist/models/types"
import StarRateIcon from "@material-ui/icons/StarRate"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props {
  price: Price
  fareType: string
  accountCodesUsed: string[]
  onSelectItinerary: React.MouseEventHandler<HTMLButtonElement>
  onAdjustPrice?: (v: number) => void
  onAddToQuote?: React.MouseEventHandler<HTMLButtonElement>
  customButtons?: CustomButtonText[]
}

const calculatePAISTax = (price: Price): number => {
  let paisTax = Currency(0)

  if (price.adults && price.adults.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.adults.taxes))
  } else if (price.seniors && price.seniors.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.seniors.taxes))
  } else if (price.disabled && price.disabled.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.disabled.taxes))
  } else if (price.children && price.children.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.children.taxes))
  } else if (price.infants && price.infants.quantity > 0) {
    paisTax = paisTax.add(PAISTax(price.infants.taxes))
  }
  return paisTax.value
}

const PAISTax = (taxes?: ChargeType[]): number => {
  if (!taxes) {
    return 0
  }
  const pais = taxes.filter(t => t.type === TaxesTypes.PAIS)
  return pais.length > 0 ? pais[0].total : 0
}

const getUserPriceAdjustment = (price: Price): number => {
  if (price.fees) {
    const fee = price.fees.find((f: ChargeType) => f.type === "USER_PRICE_ADJUSTMENT")
    if (fee) {
      return fee.total
    }
  }

  return 0
}

const ClusterPriceDesktop: React.FC<Props> = ({
  price,
  fareType,
  accountCodesUsed,
  onSelectItinerary,
  onAddToQuote,
  onAdjustPrice,
  customButtons
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const adjust = getUserPriceAdjustment(price)

  const [adjustType, setAdjustType] = useState<AdjustType>(adjust >= 0 ? "ADD" : "SUB")

  const handleOnAdjustPrice = (v: number, t: AdjustType) => {
    if (onAdjustPrice) {
      setAdjustType(t)

      if (t === "SUB") {
        onAdjustPrice(v * -1)
      } else {
        onAdjustPrice(v)
      }
    }
  }

  const { adults, children, infants, seniors, disabled, currency, total, charges, agency_cost_effectiveness } = price

  const mainFare: { label: string; fare: number } = { fare: 0, label: "" }

  const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(price)
  const paisTax = calculatePAISTax(price)
  const taxes = Currency(price.taxes).subtract(paisTax).value

  if (adults && adults.quantity > 0) {
    mainFare.fare = adults.fare / adults.quantity
    mainFare.label = t("ClusterPriceDesktop.adult_fare")
  } else if (seniors && seniors.quantity > 0) {
    mainFare.fare = seniors.fare / seniors.quantity
    mainFare.label = t("ClusterPriceDesktop.senior_fare")
  } else if (disabled && disabled.quantity > 0) {
    mainFare.fare = disabled.fare / disabled.quantity
    mainFare.label = t("ClusterPriceDesktop.disabled")
  }

  const customButtom = customButtons?.find(button => button.type === "RESERVE")

  return (
    <div className={styles.root(theme)}>
      <div className={styles.titleContainer}>
        <div className={styles.fareTypeContainer}>
          <div className={styles.fareTypeContent}>
            <Text size={12} variant="bold">
              {t("ClusterPriceDesktop.fareType")}
            </Text>
            {fareType.split(",").map((ft, idx) => (
              <div key={`fare_type_${idx}`} className={styles.fareType(theme)}>
                {ft}
              </div>
            ))}
          </div>
          {accountCodesUsed.length > 0 && <StarRateIcon className={styles.accountsUsedIcon(theme)} />}
        </div>
        <p className={styles.titleLabel}>{mainFare.label}</p>
        <p className={styles.titlePrice}>{formatPrice(mainFare.fare, locale, currency)}</p>
      </div>
      {/*this logic could be replaced with ClusterDesktopPriceBreakdown*/}
      <div className={styles.priceContainer}>
        <div className={styles.priceBreakdown}>
          {adults && adults.quantity > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.adult_count", { count: adults.quantity })}</span>
              <span>{formatPrice(adults.fare, locale, currency)}</span>
            </div>
          )}
          {seniors && seniors.quantity > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.senior_count", { count: seniors.quantity })}</span>
              <span>{formatPrice(seniors.fare, locale, currency)}</span>
            </div>
          )}
          {disabled && disabled.quantity > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.disabled_count", { count: disabled.quantity })}</span>
              <span>{formatPrice(disabled.fare, locale, currency)}</span>
            </div>
          )}
          {children && children.quantity > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.children_count", { count: children.quantity })}</span>
              <span>{formatPrice(children.fare, locale, currency)}</span>
            </div>
          )}
          {infants && infants.quantity > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.infants_count", { count: infants.quantity })}</span>
              <span>{formatPrice(infants.fare, locale, currency)}</span>
            </div>
          )}
          {taxes > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.taxes")}</span>
              <span>{formatPrice(taxes, locale, currency)}</span>
            </div>
          )}
          <div className={styles.priceBreakdownRow}>
            <span>{charges >= 0 ? t("ClusterPriceDesktop.charges") : t("ClusterPriceDesktop.discount")}</span>
            <span>{formatPrice(charges, locale, currency)}</span>
          </div>
          {paisTax > 0 && (
            <div className={styles.priceBreakdownRow}>
              <span>{t("ClusterPriceDesktop.paisTax")}</span>
              <span>{formatPrice(paisTax, locale, currency)}</span>
            </div>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.total}>
          <span>{t("ClusterPriceDesktop.total")}</span>
          <span>{formatPrice(total, locale, currency)}</span>
        </div>
        <Divider className={styles.divider} />
        {onAdjustPrice ? (
          <CostEffectivenessDetail
            adjustType={adjustType}
            adjustValue={adjust}
            total={price.agency_cost_effectiveness}
            currency={costEffectivenessBreakdown.currency}
            onAdjust={handleOnAdjustPrice}
            ceded={costEffectivenessBreakdown.resellerCeded}
          />
        ) : (
          <div className={styles.costEffectiveness}>
            <span>{t("ClusterPriceDesktop.costEffectiveness")}</span>
            <span>{formatPrice(agency_cost_effectiveness, locale, currency)}</span>
          </div>
        )}
      </div>
      <Button variant="contained" className={styles.buyButton(theme)} onClick={onSelectItinerary}>
        {customButtom ? customButtom.value : t("ClusterPriceDesktop.buy")}
      </Button>
      {onAddToQuote && (
        <Button variant="outlined" className={styles.quoteButton(theme)} onClick={onAddToQuote}>
          <AddIcon /> {t("ClusterPriceDesktop.quote")}
        </Button>
      )}
    </div>
  )
}

export default ClusterPriceDesktop
