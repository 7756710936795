import React, { useState, useEffect } from "react"
import { Link, Text } from "@basset-la/components-commons"
import Installments from "@basset-la/components-products/dist/components/Installments"
import { FormOfPayment } from "../../model/types"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import { InstallmentsData } from "@basset-la/components-products/dist/components/Installments/Installments"
import { getInstallmentsDataMap } from "../../utils/helpers/installments"

interface Props {
  formOfPayments: FormOfPayment[]
  isMobile: boolean
  className?: string
}

const ClusterInstallmentsWeb: React.FC<Props> = ({ formOfPayments, isMobile, className }) => {
  const { t } = useTranslation(I18N_NS)

  const [open, setOpen] = useState<boolean>(false)
  const [data, setData] = useState<InstallmentsData>({ free: {}, fixed: {} })
  const [higher, setHigher] = useState<number>(0)

  useEffect(() => {
    const [data, max] = getInstallmentsDataMap(formOfPayments)
    setData(data)
    setHigher(max)
  }, [formOfPayments])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={className}>
      <Text size={14} variant="light" component="p" color="variant">
        {t("ClusterInstallmentsDesktop.label", { count: higher })}
      </Text>
      <Link
        component="p"
        value={t("ClusterInstallmentsDesktop.link")}
        onClick={() => setOpen(true)}
        TextProps={{ size: 14, variant: "bold" }}
      />
      {isMobile && (
        <FullScreenDialog open={open} title={t("ClusterInstallmentsWeb.dialogTitle")} onClose={handleClose}>
          <Installments showTitle={false} installments={data} />
        </FullScreenDialog>
      )}
      {!isMobile && (
        <Dialog keepMounted open={open} scroll="paper" maxWidth="md" fullWidth onClose={handleClose}>
          <DialogContent dividers={true}>
            <Installments installments={data} />
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default ClusterInstallmentsWeb
