import { useTheme } from "@basset-la/themed-components"
import React from "react"
import styles from "./AccommodationRoomSelect.styles"
import RoomSelect from "./RoomSelect"
import Button from "@material-ui/core/Button"
import AddIcon from "@material-ui/icons/AddCircleOutlineRounded"
import { I18N_NS } from "../../utils/constants"
import { useTranslation } from "react-i18next"
import { SearchboxRoom } from "../.."

export interface Props {
  rooms: SearchboxRoom[]
  maxRooms: number
  maxRoomCapacity: number
  onRoomChange: (idx: number, r: SearchboxRoom) => void
  onAddRoom: () => void
  onDeleteRoom: (idx: number) => void
}

const AccommodationRoomSelect = ({
  rooms,
  maxRooms,
  maxRoomCapacity,
  onRoomChange,
  onAddRoom,
  onDeleteRoom
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleOnRoomChange = (idx: number, r: SearchboxRoom) => {
    onRoomChange(idx, r)
  }

  const handleAddRoom = () => {
    onAddRoom()
  }

  const handleOnDeleteRoom = (idx: number) => {
    if (onDeleteRoom) {
      onDeleteRoom(idx)
    }
  }

  const addRoomButton = (
    <span className={styles.addRoomButton(theme)}>
      <Button onClick={handleAddRoom} variant="text">
        <AddIcon />
        {t("AccommodationRoomSelect.addRoom")}
      </Button>
    </span>
  )

  return (
    <div className={styles.roomsPickerContainer}>
      <div className={styles.roomsContainer}>
        <div className={styles.roomsRow}>
          {rooms.slice(0, Math.min(maxRooms, rooms.length)).map((r, idx) => (
            <div className={styles.room} key={`room_${idx}`}>
              <RoomSelect
                maxCapacity={maxRoomCapacity}
                onChange={r => handleOnRoomChange(idx, r)}
                room={r}
                roomIndex={idx + 1}
                showDelete={rooms.length > 1}
                onDelete={() => handleOnDeleteRoom(idx)}
              />
            </div>
          ))}
          {rooms.length < maxRooms && addRoomButton}
        </div>
      </div>
    </div>
  )
}

export default AccommodationRoomSelect
