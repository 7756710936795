import React from "react"

import _ from "lodash"
import { useTranslation } from "react-i18next"

import { useTheme } from "@basset-la/themed-components"
import Chip from "@material-ui/core/Chip"

import { Airline, FlightClusterFilters, FlightsClusterActiveAvailableFilters, Stop } from "../../model"
import { I18N_NS } from "../../utils/constants"
import styles from "./ActiveFilters.styles"
import { CHECK_ALL, DEFAULT_DEPARTURE_TIME, minutesToDuration } from "./utils"

interface Props {
  availableFilters: FlightsClusterActiveAvailableFilters
  activeFilters: FlightClusterFilters
  onRemoveFilter: (k: string, v: any) => void
}

const ActiveFilters: React.FC<Props> = ({ activeFilters, availableFilters, onRemoveFilter }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const initialState: FlightsClusterActiveAvailableFilters = {
    airlines: [],
    outbound_airports: {},
    inbound_airports: {},
    stops: [],
    sourcesType: []
  }

  const [stateAvailableFilters, setAvailableFilters] = React.useState<FlightsClusterActiveAvailableFilters>(
    initialState
  )

  React.useEffect(() => {
    if (!stateAvailableFilters && availableFilters && Object.keys(availableFilters).length) {
      setAvailableFilters(availableFilters)
    }
  }, [availableFilters, stateAvailableFilters])

  const removeFilter = (key: string, val: any) => {
    onRemoveFilter(key, val)
  }

  const chip = (key: string, content: any, val?: string, i?: number) => {
    return (
      <Chip
        id="active-filters-chips"
        key={key + (i ? i : "")}
        label={content}
        onDelete={() => removeFilter(key, val)}
        classes={{
          outlined: styles.chip(theme),
          deleteIcon: `${styles.iconChip(theme)} deleteIcon`,
          label: styles.labelChip(theme)
        }}
        variant="outlined"
      />
    )
  }

  const chips = (key: string, val: any) => {
    val = Array.from(val)
    const { airlines, stops, outbound_airports, inbound_airports, sourcesType = [] } = availableFilters
    switch (key) {
      case "airlines":
        const filteredAirlines = val.filter((v: any) => airlines.find((airline: any) => airline.code === v))
        if (stateAvailableFilters?.airlines?.length > 1 && filteredAirlines.length === airlines.length - 1) {
          return chip(key, airlines.find((airline: Airline) => airline.code === CHECK_ALL)!.name, CHECK_ALL)
        }
        return filteredAirlines.map((v: any, i: number) =>
          chip(key, airlines.find((airline: any) => airline.code === v)!.name, v, i)
        )
      case "sourceType":
        const filteredSourceTypes = val.filter((v: any) => sourcesType.find((sourceType: string) => sourceType === v))
        if (stateAvailableFilters?.sourcesType?.length > 1 && filteredSourceTypes.length === sourcesType.length - 1) {
          return chip(key, t("SourceTypeFilters.all_sourcesType"), CHECK_ALL)
        }
        return filteredSourceTypes.map((v: any, i: number) =>
          chip(
            key,
            sourcesType.find((sourceType: string) => sourceType === v),
            v,
            i
          )
        )

      case "stops":
        if (stateAvailableFilters?.stops?.length > 1 && val.length === stops.length - 1) {
          return chip(key, stops.find((stop: any) => `${stop.value}` === CHECK_ALL)!.label, CHECK_ALL)
        }
        return val
          .filter((v: any) => stops.find((stop: Stop) => `${stop.value}` === v))
          .map((v: any, i: number) => chip(key, stops.find((stop: any) => `${stop.value}` === v)!.label, v, i))

      case "outboundDepartureAirports":
      case "outboundArrivalAirports":
        const outboundAirports = _.reduce(outbound_airports, (memo: any, outboundAirportArr: any) =>
          memo.concat(outboundAirportArr)
        )
        return val
          .filter((v: any) => outboundAirports!.find(airport => airport.code === v))
          .map((v: any, i: number) =>
            chip(
              key,
              t("ActiveFilters.departure") + `: ${outboundAirports!.find(airport => airport.code === v)!.name}`,
              v,
              i
            )
          )

      case "inboundDepartureAirports":
      case "inboundArrivalAirports":
        const inboundAirports = _.reduce(inbound_airports, (memo: any, inboundAirportArr: any) =>
          memo.concat(inboundAirportArr)
        )
        return val
          .filter((v: any) => inboundAirports!.find(airport => airport.code === v))
          .map((v: any, i: number) =>
            chip(
              key,
              t("ActiveFilters.return") + `: ${inboundAirports!.find(airport => airport.code === v)!.name}`,
              v,
              i
            )
          )

      case "outboundDepartureTime":
        if (val && val.length === 2 && !_.isEqual(val, DEFAULT_DEPARTURE_TIME)) {
          return chip(
            key,
            t("ActiveFilters.departure_from_to_filter", {
              from: minutesToDuration(val[0]),
              to: minutesToDuration(val[1])
            })
          )
        }
        break

      case "inboundDepartureTime":
        if (val && val.length === 2 && !_.isEqual(val, DEFAULT_DEPARTURE_TIME)) {
          return chip(
            key,
            t("ActiveFilters.return_from_to_filter", {
              from: minutesToDuration(val[0]),
              to: minutesToDuration(val[1])
            })
          )
        }
        break

      default:
        return null
    }
    return null
  }

  const chipList = _.map(activeFilters, (val: any, key: any) => chips(key, val || []))

  return (
    <div className={styles.root}>
      <div>{t("ActiveFilters.title")}</div>
      {chipList.length > 0 && <div className={styles.container}>{chipList}</div>}
    </div>
  )
}

export default ActiveFilters
