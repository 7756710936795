import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BoardingAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00033 2.16659C7.00033 2.89992 6.40033 3.49992 5.66699 3.49992C4.93366 3.49992 4.33366 2.89992 4.33366 2.16659C4.33366 1.43325 4.93366 0.833252 5.66699 0.833252C6.40033 0.833252 7.00033 1.43325 7.00033 2.16659ZM1.49366 14.3733L3.20033 5.76659L2.00033 6.23325V7.83325C2.00033 8.19992 1.70033 8.49992 1.33366 8.49992C0.966992 8.49992 0.666992 8.19992 0.666992 7.83325V6.25325C0.666992 5.71325 0.986992 5.23325 1.48033 5.01992L4.03366 3.93992C4.68033 3.66659 5.42699 3.90659 5.80032 4.49992L6.46699 5.56659C6.91366 6.35325 7.75366 6.95325 8.76699 7.11992C9.09366 7.17325 9.33366 7.44659 9.33366 7.77325C9.33366 8.17992 8.97366 8.49992 8.57366 8.43992C7.41366 8.25325 6.38033 7.65992 5.66699 6.83325L5.26699 8.83325L6.25366 9.77325C6.52033 10.0266 6.66699 10.3733 6.66699 10.7399V14.4999C6.66699 14.8666 6.36699 15.1666 6.00033 15.1666C5.63366 15.1666 5.33366 14.8666 5.33366 14.4999V11.1666L3.93366 9.83325L2.85366 14.6466C2.78033 14.9533 2.51366 15.1666 2.20033 15.1666H2.14699C1.72699 15.1666 1.40699 14.7799 1.49366 14.3733Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
