import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  locationIcon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      paddingRight: "8px"
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  input: css({
    ".MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    }
  })
}
