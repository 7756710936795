import React from "react"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import styles from "./SortSelect.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  value: unknown
  options: Option[]
  onChange: (value: Value) => void
}

export type Option = {
  label: string
  value: Value
}

export type Value = number | string

const SortSelect: React.FC<Props> = ({ value, options, onChange }) => {
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: Value }>) => {
    onChange(event.target.value)
  }
  const theme = useTheme()

  return (
    <Select
      className={styles.input(theme)}
      classes={{ root: styles.root(theme) }}
      value={value}
      onChange={handleChange}
    >
      {options.map((e, i) => {
        return (
          <MenuItem className={styles.item(theme)} value={e.value} key={i}>
            {e.label}
          </MenuItem>
        )
      })}
    </Select>
  )
}

export default SortSelect
