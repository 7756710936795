import { css } from "@emotion/css"

export default {
  root: () =>
    css({
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      position: "relative",
      top: "-4px",
      left: "-2px"
    })
}
