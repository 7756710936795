import { css } from "@emotion/css"

export default {
  container: css({
    display: "flex",
    flexDirection: "column",
    margin: "8px"
  }),
  noOptions: css({
    paddingLeft: "40px",
    paddingTop: "8px"
  })
}
