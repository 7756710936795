import React from "react"
import { Props as SimpleAccommodationInfoProps } from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import styles from "./AccommodationClusterWeb.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "@material-ui/core/Button"
import { useTheme } from "@basset-la/themed-components"
import { AccommodationFare } from "../../model"
import ClusterPriceWeb from "./ClusterPriceWeb"

export interface Props extends SimpleAccommodationInfoProps {
  isMobile: boolean
  nights: number
  guests: number
  taxesDisclaimers?: string[]
  accommodationFare: AccommodationFare
  onViewHotel: () => void
  index?: string | number
}

const AccommodationClusterWeb: React.FC<Props> = ({
  isMobile,
  nights,
  guests,
  accommodationFare,
  onViewHotel,
  taxesDisclaimers,
  index,
  ...rest
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const drawPriceComponent = () => {
    return (
      <ClusterPriceWeb
        isMobile={isMobile}
        guests={guests}
        nights={nights}
        accommodationFare={accommodationFare}
        taxesDisclaimers={taxesDisclaimers}
      />
    )
  }

  return (
    <div className={styles.root(theme)} id="accommodation-result">
      {isMobile && <div className={styles.mobPrice}>{drawPriceComponent()}</div>}
      <div className={styles.infoContainer}>
        <SimpleAccommodationInfo mini={isMobile} {...rest} />
      </div>
      <div className={styles.priceContainer(theme)}>
        {!isMobile && drawPriceComponent()}
        <Button
          onClick={onViewHotel}
          variant="contained"
          id="accommodation-view-hotel-btn"
          className={`${styles.button(theme)} accommodation-result-${index}`}
        >
          {t("AccommodationClusterWeb.view_hotel")}
        </Button>
      </div>
    </div>
  )
}

export default AccommodationClusterWeb
