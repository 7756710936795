import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import GuestDataContainer from "./GuestDataContainer"
import styles from "./RoomSection.styles"
import { I18N_NS } from "../../utils/constants"
import { RoomCompanionData, RoomGuestData } from "../../model/roomSection"
import RoomCompanionsSection from "./RoomCompanionsSection"

export interface Props {
  rooms: RoomGuestData[]
  roomCapacities: number[]
  roomCompanionsMap: Record<number, RoomCompanionData[]>
  onChangeGuestData: (index: number, field: string, value: string) => void
  onAddCompanion: (roomNumber: number) => void
  onChangeCompanionData: (roomNumber: number, index: number, field: string, value: string) => void
  onDeleteAllCompanionsInRoom: (roomNumber: number) => void
  onDeleteCompanionInRoom: (roomNumber: number, index: number) => void
  checkoutConfig?: any
  roomCompanionErrors?: string[]
}

const RoomSection: React.FC<Props> = ({
  rooms,
  roomCapacities,
  roomCompanionsMap,
  onChangeGuestData,
  onAddCompanion,
  onChangeCompanionData,
  onDeleteAllCompanionsInRoom,
  onDeleteCompanionInRoom,
  checkoutConfig,
  roomCompanionErrors = []
}: Props) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  return (
    <div className={styles.roomContainer}>
      {rooms?.map((room, roomNumber) => (
        <>
          <div className={styles.roomGuestContainer} key={`roomGuestContainer-${roomNumber}`}>
            <p className={styles.sectionTitle(theme)}>{t("RoomSection.room_number", { count: roomNumber + 1 })}</p>
            <p className={styles.disclaimerText}>{t("RoomSection.room_disclamer")}</p>
            <GuestDataContainer
              index={roomNumber}
              room={room}
              onChange={onChangeGuestData}
              checkoutConfig={checkoutConfig}
            />
          </div>
          <RoomCompanionsSection
            roomNumber={roomNumber}
            roomCapacity={roomCapacities[roomNumber]}
            companions={roomCompanionsMap[roomNumber]}
            onAddCompanion={onAddCompanion}
            onChangeCompanionData={onChangeCompanionData}
            checkoutConfig={checkoutConfig}
            onDeleteCompanionInRoom={onDeleteCompanionInRoom}
            onDeleteAllCompanionInRoom={onDeleteAllCompanionsInRoom}
            roomError={roomNumber < roomCompanionErrors.length ? roomCompanionErrors[roomNumber] : undefined}
          />
        </>
      ))}
    </div>
  )
}

export default RoomSection
