import React from "react"
import Modal from "@material-ui/core/Modal"
import AccommodationCarouselView from "./AccommodationCarouselView"
import CollectionsIcon from "@material-ui/icons/CollectionsRounded"
import CloseIcon from "@material-ui/icons/CloseRounded"
import styles from "./AccommodationCarouselModal.styles"
import IconButton from "@material-ui/core/IconButton"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  imgs: string[]
  open: boolean
  onClose: () => void
  index: number
  onChangeIndex: (index: number) => void
}

const AccommodationCarouselModal: React.FC<Props> = ({ imgs, open, index, onChangeIndex, onClose }) => {
  const theme = useTheme()

  return (
    <Modal open={open}>
      <div className={styles.root}>
        <div className={styles.closeContainer(theme)}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.carousel}>
            <AccommodationCarouselView onChangeIndex={onChangeIndex} imgs={imgs} index={index} />
          </div>
          <div className={styles.counter(theme)}>
            <CollectionsIcon />
            <span>{`${index + 1}/${imgs.length}`}</span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AccommodationCarouselModal
