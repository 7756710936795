import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (direction: "column" | "row") =>
    css({
      display: "flex",
      alignItems: direction === "column" ? "flex-start" : "center",
      flexDirection: direction,
      gap: direction === "row" ? "6px" : undefined,
      fontFamily: "Roboto",
      fontSize: "12px",
      letterSpacing: "0.43px"
    }),
  label: (theme: Theme) =>
    css({
      fontWeight: 300,
      color: theme.colors.text_primary
    }),
  info: (theme: Theme) =>
    css({
      fontWeight: 500,
      color: theme.colors.text_primary
    })
}
