import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "16px"
  }),

  item: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "16px",
    alignItems: "center"
  })
}
