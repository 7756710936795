import React from "react"
import styles from "./Segment.styles"
import { AirplaneIcon } from "../../icons/AirplaneIcon"
import { useTheme } from "@basset-la/themed-components"
import { Cluster as ClusterModel } from "../../model/types"
import Text from "@basset-la/components-commons/dist/components/Text"
import ArrowRight from "@material-ui/icons/ArrowRightAltSharp"
import BaggagesInfo from "../BaggagesInfo"
import { getBaggageConfiguration } from "../../utils/helpers"
interface Props {
  cluster: ClusterModel
  segmentIdx: number
  selectedBrand?: number
}

const Segment: React.FC<Props> = ({ cluster, segmentIdx, selectedBrand }) => {
  const theme = useTheme()

  const segment = cluster.segments[segmentIdx]

  const sanitizeAirportName = (name: string) => {
    return name.replace(/ *\([^)]*\) */g, "")
  }

  const { bagIncluded, baggagesAmount, baggagesWeight, carryOnIncluded } = getBaggageConfiguration(
    selectedBrand !== undefined && cluster.upsell_fares
      ? cluster.upsell_fares[selectedBrand].segments[segmentIdx].baggage_allowances
      : segment.options[0].baggage_allowances
  )

  const from = `${segment.origin.code} (${sanitizeAirportName(segment.origin.name)})`

  const to = `${segment.destination.code} (${sanitizeAirportName(segment.destination.name)})`

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AirplaneIcon width={18} height={18} color={theme.colors.brand_primary} />

        <Text className={styles.text(theme.colors.text_primary)} size={16}>
          {from}
        </Text>

        <ArrowRight className={styles.arrow(theme.colors.brand_primary)} />

        <Text className={styles.text(theme.colors.text_primary)} size={16}>
          {to}
        </Text>
      </div>

      <BaggagesInfo
        bagIncluded={bagIncluded}
        baggageAmount={baggagesAmount}
        baggageWeight={baggagesWeight}
        carryOnIncluded={carryOnIncluded}
      />
    </div>
  )
}

export default Segment
