import * as React from "react"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { ArrowDropDownStyle } from "./ArrowDropDownStyle"
import { SvgIconProps } from "@material-ui/core/SvgIcon"

const DEFAULT_COLOR = "#126aff"

export interface Props extends SvgIconProps {
  backgroundColor?: string
}

const ArrowDropDown = ({ backgroundColor, ...rest }: Props) => {
  const styles = ArrowDropDownStyle(backgroundColor || DEFAULT_COLOR)
  return (
    <ArrowDropDownIcon
      {...rest}
      classes={{
        root: styles.rootStyle
      }}
    />
  )
}

export default ArrowDropDown
