import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const SeatIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.99935 0.666667V6C1.99935 7.10667 2.89268 8 3.99935 8H7.33268C7.69935 8 7.99935 8.3 7.99935 8.66667C7.99935 9.03333 7.69935 9.33333 7.33268 9.33333H3.99935C2.15935 9.33333 0.666016 7.84 0.666016 6V0.666667C0.666016 0.3 0.966016 0 1.33268 0C1.69935 0 1.99935 0.3 1.99935 0.666667ZM11.3327 10H12.3327C12.886 10 13.3327 10.4467 13.3327 11C13.3327 11.5533 12.886 12 12.3327 12H10.666C9.93268 12 9.33268 11.4 9.33268 10.6667V7.33333H4.66602C3.56602 7.33333 2.66602 6.43333 2.66602 5.33333V0H6.66602V4H9.99935C10.7327 4 11.3327 4.6 11.3327 5.33333V10Z"
      fill={color}
    />
  </svg>
)
