import React from "react"
import styles from "./QuotedPDF.styles"
import QuotedPDFHeader from "./QuotedPDFHeader"
import SegmentOptionDetail from "../SegmentOptionDetail/SegmentOptionDetail"
import QuotedPDFSegmentTitle from "./QuotedPDFSegmentTitle"
import { Itinerary } from "./types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  number: number
  itinerary: Itinerary
}

const QuotedPDF: React.FC<Props> = ({ itinerary, number }) => {
  const { t } = useTranslation(I18N_NS)

  const lastLeg = itinerary.segmentOptions[0].legs[itinerary.segmentOptions[0].legs.length - 1]

  const fistLeg = itinerary.segmentOptions[0].legs[0]

  const origin = fistLeg.origin
  const destination = lastLeg.destination
  const from = itinerary.segmentOptions[0].departure_date
  const to = itinerary.segmentOptions[itinerary.segmentOptions.length - 1].departure_date

  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div>
        <QuotedPDFHeader
          flightType={itinerary.flightType}
          adults={itinerary.adults}
          children={itinerary.children}
          infants={itinerary.infants}
          senior={itinerary.senior}
          disabled={itinerary.disabled}
          total={itinerary.total}
          destination={destination}
          from={from}
          to={to}
          origin={origin}
          itineraryNumber={number}
          currency={itinerary.currency}
        />
        <div>
          {itinerary.segmentOptions.map((s, i) => (
            <div key={i} className={styles.segment(theme)}>
              <QuotedPDFSegmentTitle
                origin={s.legs[0].origin.code}
                destination={s.legs[s.legs.length - 1].destination.code}
              />
              <SegmentOptionDetail key={i} segmentOption={s} services={s.legs[0].brand?.services} showSummary />
            </div>
          ))}
        </div>
      </div>
      <p className={styles.disclamer(theme)}>{t("QuotedPDF.disclamer")}</p>
    </div>
  )
}

export default QuotedPDF
