import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "24px",
      fontFamily: "Roboto",
      color: theme.colors.text_primary
    }),
  header: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  }),
  titleContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  title: css({
    fontSize: "32px",
    fontWeight: 500,
    lineHeight: 1,
    margin: 0,
    "@media screen and (max-width: 1024px)": {
      fontSize: "18px"
    }
  }),
  address: css({
    fontSize: "14px",
    lineHeight: 1.14
  }),
  viewMap: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        fontSize: "14px",
        fontWeight: 300,
        textTransform: "unset",
        color: theme.colors.brand_primary
      }
    }),
  sectionContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  section: (theme: Theme) =>
    css({
      width: "100%",
      padding: "16px 0",
      borderBottom: `1px solid ${theme.colors.background_darker}`,
      ":last-of-type": {
        borderBottom: "unset"
      }
    })
}
