import * as React from "react"

import MaterialInput, { InputProps } from "@material-ui/core/Input"
import { css } from "@emotion/css"

export interface Props extends InputProps {
  highlightColor?: string
  initialColor?: string
}

const DEFAULT_COLOR = "#126aff"

const Input = ({ highlightColor, initialColor, ...rest }: Props) => {
  const underlineColor = highlightColor ? highlightColor : DEFAULT_COLOR
  initialColor = initialColor ? initialColor : DEFAULT_COLOR

  const underlineCsss = css({
    "&&&&:hover:before": {
      borderBottomColor: `${underlineColor} !important`
    },
    "&:after": {
      borderBottomColor: `${underlineColor} !important`
    },
    "&:before": {
      borderBottomColor: `${initialColor} !important`
    }
  })

  return <MaterialInput classes={{ underline: underlineCsss }} {...rest} />
}

export default Input
