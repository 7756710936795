import React from "react"

import { useTranslation } from "react-i18next"

import Button from "@basset-la/components-commons/dist/components/Button"
import Checkbox from "@basset-la/components-commons/dist/components/Checkbox"
import Text from "@basset-la/components-commons/dist/components/Text"
import { AutocompleteItem } from "@basset-la/components-products"
import { useTheme } from "@basset-la/themed-components"
import { CircularProgress, ListItem } from "@material-ui/core"

import { I18N_NS } from "../../utils/constants"
import AirlineLogo from "../AirlineLogo"
import styles from "./AirlineOptionList.styles"

interface AirlineOptionListProps {
  items: AutocompleteItem[]
  selectedItems: AutocompleteItem[]
  onSelectItem: (item: AutocompleteItem) => void
  loading?: boolean
  isMobile?: boolean
  multiple?: boolean
  disableSelectAll?: boolean
  disableClearAll?: boolean
  onSelectAll?: () => void
  onClearAll?: () => void
}

const AirlineOptionList: React.FC<AirlineOptionListProps> = ({
  items,
  selectedItems,
  multiple = false,
  onSelectItem,
  loading = false,
  isMobile = false,
  disableClearAll = false,
  disableSelectAll = false,
  onClearAll = () => {},
  onSelectAll = () => {}
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <ul className={styles.list}>
      {loading && (
        <div className={styles.loadingContainer}>
          <CircularProgress className={styles.progress(theme)} size={24} />
        </div>
      )}
      {!loading && (
        <>
          {multiple && !!items.length && (
            <div className={styles.optionHeaderContainer}>
              <Button
                className={`${styles.optionHeaderButton} ${styles.optionHeaderLeftButton}`}
                variant="text"
                onClick={onClearAll}
                disabled={disableClearAll}
              >
                {t("AirlinesSelect.clearSelection")}
              </Button>
              <Button
                className={`${styles.optionHeaderButton} ${styles.optionHeaderRightButton}`}
                variant="text"
                onClick={onSelectAll}
                disabled={disableSelectAll}
              >
                {t("AirlinesSelect.selectAll")}
              </Button>
            </div>
          )}
          {items.map(option => {
            const selected = !!selectedItems.find(item => item.iata_code === option.iata_code)
            return (
              <ListItem
                key={option.id}
                className={styles.option(theme)}
                onClick={() => onSelectItem(option)}
                selected={selected}
                disableGutters
                button
              >
                <div className={styles.optionItem}>
                  {multiple && <Checkbox className={styles.optionCheckbox(isMobile)} checked={selected} />}
                  {option.iata_code && (
                    <AirlineLogo className={styles.airlineLogo(isMobile)} airlineCode={option.iata_code} />
                  )}
                  <Text size={isMobile ? 16 : 14} variant="regular">
                    {option.name}
                  </Text>
                </div>
              </ListItem>
            )
          })}
        </>
      )}
    </ul>
  )
}

export default AirlineOptionList
