import * as React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"
import Select from "@basset-la/components-commons/dist/components/Select"
import styles from "./CheckoutDocument.styles"
import { useTheme } from "@basset-la/themed-components"
import TextField from "@basset-la/components-commons/dist/components/TextField"

export interface Props {
  title: string
  documentType: string
  documentNumber: string
  documentExpiration: string
  documentCountry: string
  countries: Array<{ code: string; name: string }>
  errors?: string[]
  disabled?: boolean
  onChange: (type: "type" | "number" | "expiration" | "country", value: string) => void
}

const CheckoutDocument = ({
  title,
  documentType,
  documentNumber,
  documentExpiration,
  documentCountry,
  countries,
  errors,
  disabled,
  onChange
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleSelectType = (v: string[]) => {
    onChange("type", v[0])
  }

  const handleChangeNumber = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange("number", evt.target.value)
  }

  const handleSelectCountry = (v: string[]) => {
    onChange("country", v[0])
  }

  const replaceAll = (s: string, text: string, replaceBy: string): string => {
    while (s.indexOf(text) !== -1) {
      s = s.replace(text, replaceBy)
    }

    return s
  }

  const handleChangeExpiration = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = replaceAll(evt.target.value, "-", "")
    const originalValue = replaceAll(documentExpiration, "-", "")
    const regex = /^[\d]*$/

    if (value !== "" && !regex.test(value)) {
      value = originalValue
    }

    if (value.length >= 9) {
      value = originalValue
    }

    if (value.length >= 4) {
      value = `${value.substr(0, 4)}-${value.substr(4)}`
    }

    if (value.length >= 7) {
      value = `${value.substr(0, 7)}-${value.substr(7)}`
    }

    onChange("expiration", value)
  }

  return (
    <div className={styles.root}>
      <Text className={styles.title(theme)} size={14} variant="regular">
        {title}
      </Text>
      <div className={styles.content}>
        <div className={styles.item}>
          <Select
            className={styles.select}
            variant="item"
            disabled={disabled}
            selected={[`${documentType || ""}`]}
            error={errors && errors.length > 0}
            label={t("CheckoutDocument.type")}
            values={[
              { code: "PAS", name: t("CheckoutDocument.types.PAS") },
              { code: "IC", name: t("CheckoutDocument.types.IC") },
              { code: "DL", name: t("CheckoutDocument.types.DL") }
            ]}
            onSelect={handleSelectType}
          ></Select>
        </div>
        <div className={styles.item}>
          <TextField
            className={styles.textField(theme, errors && errors.length > 0 ? true : false)}
            variant="text"
            disabled={disabled}
            label={t("CheckoutDocument.number")}
            value={documentNumber}
            error={errors && errors.length > 0}
            onChange={handleChangeNumber}
          />
        </div>
        <div className={styles.item}>
          <TextField
            className={styles.textField(theme, errors && errors.length > 0 ? true : false)}
            variant="text"
            disabled={disabled}
            label={t("CheckoutDocument.expiration")}
            placeholder="YYYY-MM-DD"
            value={documentExpiration}
            error={errors && errors.length > 0}
            onChange={handleChangeExpiration}
          />
        </div>
        <div className={styles.item}>
          <Select
            className={styles.select}
            variant="item"
            disabled={disabled}
            selected={[documentCountry]}
            error={errors && errors.length > 0}
            label={t("CheckoutDocument.country")}
            values={countries}
            onSelect={handleSelectCountry}
          ></Select>
        </div>
      </div>
      {errors && errors.length > 0 && (
        <div className={styles.errors}>
          {errors.map((err, i) => {
            return (
              <Text className={styles.error(theme)} size={12}>
                {err}
              </Text>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default CheckoutDocument
