import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
    "> *": {
      marginBottom: "8px",
      ":last-child": {
        marginBottom: 0
      }
    }
  })
}
