import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      fontSize: "12px",
      maxWidth: 200,
      fontFamily: "Roboto",
      backgroundColor: theme.colors.background_primary
    }),
  divisor: css({
    "&.MuiDivider-root": {
      margin: "8px 0"
    }
  }),
  provider: (theme: Theme) =>
    css({
      fontSize: "16px",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.25px",
      textAlign: "left",
      color: theme.colors.text_primary,
      marginBottom: 8
    }),
  internalPrices: css({
    "& > div": {
      marginBottom: 0
    }
  }),
  strikethrought: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "1.33",
    letterSpacing: "0.1px",
    color: "#9b9b9b",
    textDecoration: "line-through"
  }),
  costEffectiveness: css({
    "& > div": {
      color: "#39a28d",
      margin: "8px 0",
      fontSize: "12px"
    }
  }),
  rows: (theme: Theme, higlighted?: boolean) =>
    css({
      fontWeight: higlighted ? 500 : 300,
      display: "flex",
      fontSize: higlighted ? "16px" : "12px",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      lineHeight: "1.33",
      letterSpacing: " 0.13px",
      color: theme.colors.text_primary
    }),
  bigPrice: css({
    display: "flex",
    alignItems: "initial",
    "& > span": {
      fontSize: "12px",
      marginTop: "4px"
    }
  }),
  OFF: (theme: Theme) =>
    css({
      fontSize: "12px",
      fontWeight: 300,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "0.13px",
      display: "flex",
      justifyContent: "flex-end",
      margin: "8px 0px",
      "& > span": {
        textAlign: "center",
        borderRadius: "8px",
        color: theme.colors.text_white,
        backgroundColor: "#02ae02",
        padding: "2px 4px"
      }
    })
}
