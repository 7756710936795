import React from "react"
import { Currency, AccommodationFare } from "../../model/types"
import PriceboxBreakdownContainer from "./PriceboxBreakdownContainer"
import { convertAccommodationFareToAgencyPriceBreakdown } from "../../utils/helpers/helpersV2"
import styles from "./PriceboxBreakdownCheckoutWeb.styles"
import CurrencySelector from "@basset-la/components-products/dist/components/CurrencySelector"
import ExchangeRateText from "@basset-la/components-products/dist/components/ExchangeRateText"

type LoadingTrueProps = {
  loading: true
  accommodationFare?: AccommodationFare
  rooms?: number
  guests?: number
}

type LoadingFalseProps = {
  loading?: false
  accommodationFare: AccommodationFare
  rooms: number
  guests: number
}

export type Props = {
  taxesDisclaimer?: string
  currencySwitcher?: Currency
  currencies?: string[]
  selectedCurrency?: string
  onSelectCurrency?: (newCurrency: string) => void
} & (LoadingTrueProps | LoadingFalseProps)

const PriceboxBreakdownCheckoutWeb: React.FC<Props> = ({
  loading = false,
  accommodationFare,
  rooms,
  guests,
  currencySwitcher,
  taxesDisclaimer,
  currencies,
  selectedCurrency = "ARS",
  onSelectCurrency
}) => {
  const priceBreakdown = loading
    ? undefined
    : convertAccommodationFareToAgencyPriceBreakdown(accommodationFare!, rooms!, guests!)

  return (
    <div className={styles.root}>
      {onSelectCurrency && (
        <CurrencySelector
          containerClassName={styles.currencySelector}
          onSelectCurrency={onSelectCurrency}
          selectedCurrency={selectedCurrency}
          currencies={currencies}
        />
      )}
      {!loading &&
        currencySwitcher &&
        currencySwitcher.currency !== currencySwitcher.originalCurrency.currency_code && (
          <ExchangeRateText
            currency={currencySwitcher.currency}
            originalCurrency={currencySwitcher.originalCurrency}
            direction="row"
            containerClassName={styles.exchangeRateContainer}
            labelClassName={styles.exchangeRateText}
            infoClassName={styles.exchangeRateText}
          />
        )}
      {loading && <PriceboxBreakdownContainer loading={loading} />}
      {!loading && <PriceboxBreakdownContainer priceBreakdown={priceBreakdown!} taxesDisclaimer={taxesDisclaimer} />}
    </div>
  )
}

export default PriceboxBreakdownCheckoutWeb
