import { css } from "@emotion/css"

export default {
  root: (showBorder: boolean) =>
    css({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
      padding: showBorder ? "4px 0" : undefined,
      borderBottom: showBorder ? "1px solid #EAEAEA" : undefined
    })
}
