import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme) =>
    css({
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      backfaceVisibility: "hidden",
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)",
      height: 43,
      lineHeight: "43px",
      backgroundColor: t.colors.background_primary,
      color: t.colors.brand_primary
    }),
  button: (t: Theme) =>
    css({
      "&.MuiButton-root": {
        color: t.colors.brand_primary
      }
    }),
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      fill: t.colors.brand_primary,
      width: 20,
      height: 20,
      marginRight: 8,
      verticalAlign: "middle",
      marginTop: -2,
      transform: "rotate(270deg)"
    })
}
