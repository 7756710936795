import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  list: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      margin: 0,
      padding: "16px"
    }),
  itemTitle: (t: Theme) =>
    css({
      color: `${t.colors.text_variant} !important`
    }),
  item: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      color: t.colors.text_primary,
      "&>div": {
        flex: 1,
        margin: "8px 8px 8px 0"
      }
    })
}
