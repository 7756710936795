import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),
  stars: css({
    alignSelf: "center"
  }),
  container: css({
    paddingBottom: "16px",
    " > div": {
      backgroundColor: "inherit"
    }
  })
}
