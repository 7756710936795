import React from "react"
import { css } from "@emotion/css"

import Row from "./Row"
import Col from "./Col"
import { useTheme, Theme } from "@basset-la/themed-components"

interface Props {
  text: string
}

const Disclamer: React.FC<Props> = ({ text }) => {
  const theme = useTheme()

  return (
    <Row>
      <Col>
        <div className={styles(theme)}>{text}</div>
      </Col>
    </Row>
  )
}

const styles = (theme: Theme) =>
  css({
    fontFamily: "Roboto",
    backgroundColor: theme.colors.background_variant,
    padding: "20px 16px",
    margin: "8px 0"
  })

export default Disclamer
