import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "column",
      backgroundColor: t.colors.background_primary,
      fontFamily: "Roboto"
    }),
  segment: (t: Theme) =>
    css({
      borderRadius: "4px",
      border: `solid 1px ${t.colors.background_darker}`,
      margin: "16px"
    }),
  disclamer: (t: Theme) =>
    css({
      fontSize: "14px",
      letterSpacing: "0.15px",
      color: t.colors.text_primary,
      margin: 0,
      padding: "16px",
      borderTop: `1px solid ${t.colors.background_darker}`
    })
}
