import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      padding: "16px",
      fontFamily: "Roboto",
      boxSizing: "border-box",
      width: "100%",
      color: t.colors.text_primary,
      backgroundColor: t.colors.background_primary,
      borderRadius: "3px"
    }),
  title: css({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14"
  }),
  pickerContainer: css({
    display: "flex",
    flexDirection: "column"
  }),
  picker: css({
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    ":last-of-type": {
      marginBottom: 0
    }
  }),
  mainPicker: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }),
  pickerLabelContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      ">span": {
        fontSize: "12px",
        lineHeight: "1.33",
        color: t.colors.text_variant
      },
      ">span:first-of-type": {
        fontSize: "14px",
        lineHeight: "1.14",
        color: t.colors.text_primary
      }
    }),
  select: (t: Theme) =>
    css({
      "&.MuiOutlinedInput-root": {
        height: "32px",
        boxSizing: "border-box",
        borderRadius: "4px",
        width: "104px",
        backgroundColor: t.colors.background_variant,
        fontSize: "14px",
        lineHeight: "1.14",
        textAlign: "center",
        color: t.colors.text_primary,
        ">.MuiSelect-root": {
          padding: "6.5px 25px 6.5px 8px"
        },
        ">.MuiSelect-icon": {
          color: t.colors.brand_primary
        },
        ">.MuiOutlinedInput-notchedOutline": {
          border: "none"
        }
      }
    }),
  item: (t: Theme) =>
    css({
      ".MuiPopover-root > .MuiList-root": {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        padding: "8px"
      },
      "&.MuiListItem-root": {
        width: "100%",
        padding: "8px",
        fontSize: "14px",
        lineHeight: "1.14",
        justifyContent: "start",
        color: t.colors.text_primary
      }
    }),
  error: (t: Theme) =>
    css({
      fontSize: "10px",
      fontWeight: 300,
      color: t.colors.error,
      lineHeight: "1.6",
      letterSpacing: "0.11px",
      marginTop: "8px"
    }),
  buttonContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: "8px",
      ">.MuiButton-root": {
        fontSize: "14px",
        color: t.colors.brand_primary,
        alignSelf: "flex-end"
      }
    })
}
