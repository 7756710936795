import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import Input from "@basset-la/component-material-ui-wrapper/dist/Input"
import Button from "@material-ui/core/Button"
import styles from "./NameFilter.styles"
import AccommodationFiltersCollapsePanel from "../AccommodationFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  value?: string
  onApply: (value: string) => void
}

const NameFilter: React.FC<Props> = ({ collapsible, value, onApply }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const [currentValue, setCurrentValue] = React.useState<string>(value ? value : "")

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCurrentValue(e.target.value)
  }

  const handleClick = () => {
    onApply(currentValue)
  }

  const component = (
    <div className={styles.content}>
      <Input
        initialColor={theme.colors.background_darker}
        value={currentValue}
        onChange={handleChange}
        highlightColor={theme.colors.brand_primary}
      />
      <Button id="name-filter-apply-btn" onClick={handleClick} className={styles.button(theme)}>
        {t("NameFilter.applyFilter")}
      </Button>
    </div>
  )

  return (
    <div className={styles.root(theme)}>
      {!collapsible && (
        <>
          <p className={styles.title}>{t("NameFilter.title")}</p>
          {component}
        </>
      )}
      {collapsible && (
        <AccommodationFiltersCollapsePanel renderSummary={<>{t("NameFilter.title")}</>} renderDetails={component} />
      )}
    </div>
  )
}

export default NameFilter
