import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const ChangeIconNotFound: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.28583 6.76174V4.90174C5.28583 4.60174 4.92583 4.45507 4.71916 4.66841L2.18583 7.19507C2.05249 7.32841 2.05249 7.53507 2.18583 7.66841L4.71249 10.1951C4.92583 10.4017 5.28583 10.2551 5.28583 9.95507V8.09507C7.77249 8.09507 9.73916 10.3751 9.19249 12.9551C8.87916 14.4684 7.65249 15.6884 6.14583 16.0017C3.76583 16.5017 1.64583 14.8684 1.32583 12.6617C1.27916 12.3417 0.999159 12.0951 0.672493 12.0951C0.272493 12.0951 -0.0475074 12.4484 0.00582596 12.8484C0.419159 15.7751 3.20583 17.9417 6.35916 17.3284C8.43916 16.9217 10.1125 15.2484 10.5192 13.1684C11.1792 9.74841 8.57916 6.76174 5.28583 6.76174Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2C6.24 2 4 4.24 4 7C4 9.76 6.24 12 9 12C11.76 12 14 9.76 14 7C14 4.24 11.76 2 9 2ZM9 7.5C8.725 7.5 8.5 7.275 8.5 7V5C8.5 4.725 8.725 4.5 9 4.5C9.275 4.5 9.5 4.725 9.5 5V7C9.5 7.275 9.275 7.5 9 7.5ZM8.5 8.5V9.5H9.5V8.5H8.5Z"
      fill="#D0021B"
    />
  </svg>
)
