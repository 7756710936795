import React from "react"
import styles from "./ClusterCombinedPriceWeb.styles"
import PriceboxWeb from "@basset-la/components-products/dist/components/PriceboxWeb"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../.."
import { Price } from "../../model"

export interface Props {
  isMobile: boolean
  price: Price
}

const ClusterCombinedPriceWeb: React.FC<Props> = ({ isMobile, price }) => {
  const { t } = useTranslation(I18N_NS)

  const { currency, total } = price

  return (
    <div className={styles.root}>
      <PriceboxWeb
        isMobile={isMobile}
        currency={currency}
        total={{
          label: t("ClusterPriceDesktop.total"),
          value: total
        }}
        breakdown={[
          {
            label: t("ClusterCombinedPriceWeb.total"),
            value: total
          }
        ]}
        id={{
          totalPrice: "flight-combined-total-price"
        }}
      />
    </div>
  )
}

export default ClusterCombinedPriceWeb
