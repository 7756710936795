import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      padding: "8px",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      backgroundColor: t.colors.background_primary
    }),
  policy: (isTooltip: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      borderTop: !isTooltip ? "1px solid #00000017" : undefined,
      paddingTop: !isTooltip ? "8px" : undefined,
      paddingBottom: "16px",
      ":last-of-type": {
        marginBottom: 0
      },
      "> p": {
        margin: 0,
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "1.14",
        letterSpacing: "0.15px",
        marginBottom: "8px"
      }
    }),
  policySection: (isTooltip: boolean) =>
    css({
      display: "flex",
      flexDirection: "column",
      "> *": {
        marginBottom: "8px",
        ":last-of-type": {
          marginBottom: 0
        }
      },
      paddingTop: !isTooltip ? "8px" : undefined,
      borderTop: !isTooltip ? "1px solid #4a4a4a" : undefined
    })
}
