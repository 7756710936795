import React from "react"
import { FileType } from "./model"
import FilePreview from "./FilePreview"
import styles from "./DropZoneContainer.styles"
import { DropEvent } from "react-dropzone"
import DropZone from "./DropZone"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Button from "../Button"

export interface Props {
  maxFileSize: number
  maxFileCount: number
  acceptedFileTypes: FileType[]
  onDrop<T extends File>(acceptedFiles: T[], rejectedFiles: T[], event: DropEvent): void
  files: File[]
  onDeleteFile: (fileIndex: number) => void
  onSave: (files: File[]) => void
}

const DropZoneContainer: React.FC<Props> = ({
  maxFileCount,
  maxFileSize,
  onDrop,
  files,
  acceptedFileTypes,
  onDeleteFile,
  onSave
}) => {
  const { t } = useTranslation(I18N_NS)
  return (
    <div className={styles.root}>
      <div className={styles.items(files.length)}>
        <DropZone
          files={files}
          onDrop={onDrop}
          maxFileCount={maxFileCount}
          maxFileSize={maxFileSize}
          acceptedFileTypes={acceptedFileTypes}
          onDeleteFile={onDeleteFile}
        />

        {files.length > 0 &&
          files.map((f, i) => (
            <FilePreview key={i} fileType={f.type as FileType} name={f.name} onDelete={() => onDeleteFile(i)} />
          ))}
      </div>
      <Button
        variant={"contained"}
        disabled={false}
        onClick={() => {
          onSave(files)
        }}
      >
        {t("DropFilesModal.save")}
      </Button>
    </div>
  )
}

export default DropZoneContainer
