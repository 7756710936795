import React, { useState } from "react"
import styles from "./CostEffectivenessDetail.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import IconButton from "@material-ui/core/IconButton"
import VisibilityIcon from "@material-ui/icons/VisibilityRounded"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffRounded"
import { formatPrice, getLocale } from "../../utils/helpers"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import PriceAdjuster from "./PriceAdjuster"
import { useTheme } from "@basset-la/themed-components"
import { AdjustType } from "./types"

export interface Props {
  total: number
  currency: string
  adjustValue: number
  adjustType: AdjustType
  onAdjust: (v: number, t: AdjustType) => void
  ceded?: number
  readOnly?: boolean
}

const CostEffectivenessDetail: React.FC<Props> = ({
  total,
  currency,
  adjustValue,
  adjustType,
  onAdjust,
  ceded = 0,
  readOnly = false
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const [hidden, setHidden] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const handleHide = () => {
    setHidden(true)
  }

  const handleShow = () => {
    setHidden(false)
  }

  const handleChange = () => {
    if (!readOnly) setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOnSave = (v: number, t: AdjustType) => {
    onAdjust(v, t)
    handleClose()
  }

  return (
    <div className={styles.root}>
      {hidden && (
        <div className={styles.showButtonContainer(theme)}>
          <IconButton className={styles.showIconButton} onClick={handleShow}>
            <VisibilityIcon />
          </IconButton>
        </div>
      )}
      {!hidden && (
        <div className={styles.mainContent}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{t("CostEffectivenessDetail.title")}</span>
            <IconButton className={styles.iconButton} onClick={handleHide}>
              <VisibilityOffIcon />
            </IconButton>
          </div>
          <div className={styles.table}>
            {ceded > 0 && (
              <div className={styles.tableRow}>
                <span>{t("CostEffectivenessDetail.ceded")}</span>
                <span>{formatPrice(ceded, locale, currency)}</span>
              </div>
            )}
            <div className={styles.tableRow}>
              <Accordion className={styles.accordion} expanded={open} onChange={handleChange}>
                <AccordionSummary className={styles.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                  <div className={styles.adjustRow}>
                    <span>{t("CostEffectivenessDetail.adjustment")}</span>
                    <div>
                      {(adjustValue || readOnly) && (
                        <span>
                          {adjustType === "SUB" ? "-" : ""}
                          {formatPrice(!readOnly ? Math.abs(adjustValue) : total, locale, currency)}
                        </span>
                      )}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <PriceAdjuster
                    maxDiscount={total}
                    adjustType={adjustType}
                    value={Math.abs(adjustValue)}
                    currency={currency}
                    onSave={handleOnSave}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={styles.totalRow}>
              <span>{t("CostEffectivenessDetail.total")}</span>
              <span>{formatPrice(total + adjustValue, locale, currency)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CostEffectivenessDetail
