import { css } from "@emotion/css"

export default {
  menu: css({
    ".MuiList-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: "8px"
    },
    ".MuiList-root > .MuiMenuItem-root": {
      fontSize: "14px",
      lineHeight: "1.14",
      width: "100%",
      padding: "8px",
      justifyContent: "start"
    }
  })
}
