import * as React from "react"
import IconButtonMUI, { IconButtonProps } from "@material-ui/core/IconButton"

import { css } from "@emotion/css"

const DEFAULT_COLOR = "#126aff"

export interface Props extends IconButtonProps {
  backgroundColor?: string
  borderColor?: string
}

const IconButton = ({ backgroundColor = DEFAULT_COLOR, borderColor = DEFAULT_COLOR, ...rest }: Props) => {
  const styles = style(backgroundColor, borderColor)

  return (
    <IconButtonMUI
      aria-label="More"
      aria-haspopup="true"
      {...rest}
      classes={{
        root: styles.rootStyle
      }}
    />
  )
}

const style = (color: string, borderColor: string) => {
  return {
    rootStyle: css({
      color: `${color} !important`,
      padding: "0px !important",
      border: `1px solid ${borderColor} !important`,
      margin: "0px !important"
    })
  }
}

export default IconButton
