import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  subTitle: (theme: Theme) =>
    css({
      fontSize: "12px",
      color: theme.colors.text_variant,
      lineHeight: "1.33",
      fontWeight: 300
    })
}
