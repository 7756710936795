import React from "react"
import styles from "./AccommodationDetailSummary.styles"
import { useTheme } from "@basset-la/themed-components"
import { Moment } from "moment"
import { useTranslation } from "react-i18next"
import EventIcon from "@material-ui/icons/Event"
import HotelIcon from "@material-ui/icons/Hotel"
import PersonIcon from "@material-ui/icons/Person"
import Text from "@basset-la/components-commons/dist/components/Text"
import Button from "@basset-la/components-commons/dist/components/Button"
import LoadingButton from "./LoadingButton"
import { formatPrice, getLocale } from "@basset-la/components-products"
import DiscountLabel from "@basset-la/components-products/dist/components/DiscountLabel"
import { I18N_NS } from "../../utils/constants"
import { RoomClusterRate } from "../../model"

export interface Props {
  loading: boolean
  rate: RoomClusterRate | null
  availability: boolean
  checkin: Moment | null
  checkout: Moment | null
  totalRooms: number
  totalGuests: number
  taxesDisclaimers?: string[]
  variant: "WEB" | "DESKTOP"
  onOpenSearch: () => void
  onViewRooms: () => void
}

const AccommodationDetailSummary: React.FC<Props> = ({
  loading,
  rate,
  availability,
  checkin,
  checkout,
  totalRooms,
  totalGuests,
  taxesDisclaimers,
  variant,
  onOpenSearch,
  onViewRooms
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const handleOpenSearch = () => {
    onOpenSearch()
  }

  const handleViewRooms = () => {
    onViewRooms()
  }

  const fare = rate?.fare

  const strikethrough = fare?.discount || null

  const currency = fare?.currency || ""

  if (strikethrough && fare?.total && strikethrough?.discount) {
    const total = fare.total

    const percentage = strikethrough.discount

    strikethrough.total = total / ((100 - percentage) / 100)
  }
  return (
    <>
      {checkin && checkout && (
        <div className={styles.searchInfo(theme)}>
          <div className={styles.searchInfoRow(theme)}>
            <EventIcon />
            <Text className={styles.text(theme)} variant="bold" size={14}>
              {t("AccommodationDetailSummary.dates")}
            </Text>
            <Text color="primary" variant="regular" size={14}>
              {checkin.format("DD MMM YYYY")} - {checkout.format("DD MMM YYYY")}
            </Text>
          </div>
          <div className={styles.searchInfoRow(theme)}>
            <div className={styles.searchInfoItem}>
              <HotelIcon />
              <Text className={styles.text(theme)} variant="bold" size={14}>
                {t("AccommodationDetailSummary.rooms")}
              </Text>
              <Text id="accommodation-detail-summary-rooms" color="primary" variant="regular" size={14}>
                {totalRooms}
              </Text>
            </div>
            <div className={styles.searchInfoItem}>
              <PersonIcon style={{ marginLeft: "16px" }} />
              <Text className={styles.text(theme)} variant="bold" size={14}>
                {t("AccommodationDetailSummary.guests")}
              </Text>
              <Text id="accommodation-detail-summary-guests" color="primary" variant="regular" size={14}>
                {totalGuests}
              </Text>
            </div>
          </div>
          <div className={styles.searchInfoRow(theme)}>
            <button onClick={handleOpenSearch}>{t("AccommodationDetailSummary.edit_search")}</button>
          </div>
        </div>
      )}

      {loading && <LoadingButton />}

      {rate && rate.fare && availability && (
        <div className={styles.priceDetailContainer}>
          {strikethrough && (
            <div className={styles.strikethrough}>
              {formatPrice(strikethrough.total, locale, currency)}
              <DiscountLabel value={Math.floor(strikethrough.discount)} />
            </div>
          )}
          <div className={styles.price}>
            <span id="accommodation-detail-summary-total-price">
              {`${formatPrice(rate.fare!.total, locale, currency)} `}
            </span>
            <span>{t("AccommodationDetailSummary.total_price")}</span>
          </div>
          {taxesDisclaimers &&
            taxesDisclaimers.map((d: string, index: number) => (
              <div key={index} className={styles.disclamer}>
                {d}
              </div>
            ))}
          <Button variant="contained" onClick={handleViewRooms} className={styles.viewRoomsButton(theme)}>
            {t("AccommodationDetailSummary.see_rooms")}
          </Button>
        </div>
      )}
    </>
  )
}

export default AccommodationDetailSummary
