import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"

export default {
  root: css({
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: 400,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.33",
    letterSpacing: "0.13px",
    textAlign: "center",
    borderRadius: "8px",
    color: colors.WHITE,
    height: "16px",
    boxSizing: "border-box",
    backgroundColor: colors.GREEN2,
    padding: "1px 8px"
  })
}
