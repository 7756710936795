import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  buttonStyle: (theme: Theme, disabled?: boolean) =>
    css({
      "&.MuiButton-root": {
        textTransform: "capitalize",
        color: disabled ? undefined : "#fff",
        fontSize: "16px",
        fontWeight: "300",
        backgroundColor: disabled ? undefined : theme.brand_colors.click_color,
        width: "100%",
        margin: "0 auto",
        height: "32px",
        padding: "0",
        marginTop: "16px",
        ":hover": {
          backgroundColor: theme.colors.brand_primary
        }
      }
    }),
  quotedButton: (theme: Theme) =>
    css({
      "&.MuiButton-outlined": {
        color: theme.colors.brand_primary,
        fontSize: "16px ",
        fontWeight: "300",
        textTransform: "capitalize",
        borderColor: theme.colors.brand_primary,
        width: "100%",
        margin: "0 auto",
        height: "32px",
        padding: "0",
        marginTop: "16px"
      }
    }),
  breakdownContainer: css({
    marginTop: "16px",
    width: "100%"
  }),
  discountStyle: css({
    alignSelf: "flex-end"
  }),
  priceDetailStyle: css({
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }),
  discountLabel: css({
    marginBottom: "16px"
  }),
  packageRateStyle: (theme: Theme) =>
    css({
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "1.33",
      letterSpacing: "0.1px",
      textAlign: "center",
      color: theme.brand_colors.click_color
    }),
  priceStyle: css({
    fontSize: "24px",
    fontWeight: "400",
    lineHeight: "1.33",
    letterSpacing: "1.5px",
    textAlign: "center",
    color: "#000",
    marginBottom: "16px"
  }),
  nightsTextStyle: css({
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.71",
    letterSpacing: "0.5px",
    textAlign: "center",
    color: "#000",
    marginBottom: "24px"
  }),
  containerStyle: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%"
  }),
  disclaimerContainer: css({
    width: "100%",
    marginTop: "16px",
    fontSize: "12px",
    color: "#9b9b9b",
    textAlign: "left"
  })
}
