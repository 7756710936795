import React, { useState } from "react"
import { Cluster } from "../../model"
import SegmentBrands from "./SegmentBrands"
import styles from "./ClusterBrands.styles"
import Divider from "@material-ui/core/Divider"
import Button from "@basset-la/components-commons/dist/components/Button"
import Text from "@basset-la/components-commons/dist/components/Text"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { getLocale, formatPrice } from "@basset-la/components-products"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import {
  calculateClusterPrice,
  compareWithPreviousBrandMappedServices,
  hideUnlistedMappedServices,
  mapLegs,
  MergedSegment
} from "../../utils/helpers/brands"
import FlightBrandSelect from "./FlightBrandSelect"
import Carousel from "@basset-la/components-commons/dist/components/Carousel"

export interface Props {
  cluster: Cluster
  selectedOptions: number[]
  selectedBrand: number
  openDialog: boolean
  showAllServices?: boolean
  onClose: () => void
  onChange: (brandIdx: number) => void
}

const ClusterBrands: React.FC<Props> = ({
  cluster,
  selectedOptions,
  selectedBrand,
  openDialog,
  showAllServices,
  onClose,
  onChange
}) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const [brandSelection, setBrandSelection] = useState(selectedBrand)
  const [firstCarouselBrand, setFirstCarouselBrand] = useState(0)

  const handleSelectBrand = (brandIdx: number) => {
    setBrandSelection(brandIdx)
  }

  const onCarouselMoved = (first: number) => {
    setFirstCarouselBrand(first)
  }

  const handleClose = () => {
    setFirstCarouselBrand(0)
    onClose()
  }

  const handleAccept = () => {
    setFirstCarouselBrand(0)
    onChange(brandSelection)
  }

  const currency = cluster.price.currency
  const upsellFares = cluster.upsell_fares || []
  const isSingleCategory = upsellFares.length === 1
  const clusterPrice = calculateClusterPrice(cluster, brandSelection)
  const mergedSegments: MergedSegment[] = cluster.segments.map((s, idx) => {
    const legs = mapLegs(s.options[selectedOptions[idx]].legs, upsellFares, idx, selectedBrand)
    hideUnlistedMappedServices(legs)
    return {
      ...s,
      legs: legs
    }
  })
  const upsellFaresVisible = upsellFares.map((_, idx) => {
    if (showAllServices) return true
    if (idx === 0) return true
    return !compareWithPreviousBrandMappedServices(mergedSegments, idx)
  })
  let counter = 0

  const component = (
    <div className={styles.root(isSingleCategory)}>
      <Carousel width="100%" onMoved={onCarouselMoved}>
        {upsellFares
          .map((f, idx) => {
            if (!upsellFaresVisible[idx]) return null

            counter++
            return (
              <FlightBrandSelect
                key={`select_${idx}`}
                idx={counter}
                fare={f}
                selected={brandSelection === idx}
                onSelect={() => handleSelectBrand(idx)}
                {...{ isSingleCategory }}
              />
            )
          })
          .filter(it => it != null)}
      </Carousel>

      {mergedSegments.map((s, idx) => (
        <>
          <SegmentBrands
            key={`segment_${idx}`}
            segment={s}
            upsellFaresVisible={upsellFaresVisible}
            selectedOption={selectedOptions[idx]}
            firstCarouselBrand={firstCarouselBrand}
            showAllServices={showAllServices || false}
            {...{ isSingleCategory }}
          />
          <Divider />
        </>
      ))}
    </div>
  )

  return (
    <Dialog className={styles.dialog} open={openDialog} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle className={styles.dialogTitle}>
        <span className={styles.selectTitle}>{t("ClusterBrands.select")}</span>
        <div className={styles.titleRightColumn}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <div className={styles.footer}>
            <div className={styles.priceContainer}>
              <Text size={14}>{t("ClusterBrands.finalPrice")}</Text>
              <div>
                <Text size={18} variant="regular">
                  {`${currency} `}
                </Text>
                <Text size={24} variant="bold">
                  {formatPrice(clusterPrice, locale, currency, { hideCurrency: true })}
                </Text>
              </div>
            </div>
            <Button variant="contained" onClick={handleAccept}>
              {t("ClusterBrands.accept")}
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>{component}</DialogContent>
    </Dialog>
  )
}

export default ClusterBrands
