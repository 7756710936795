import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const BagAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.2578 4.78046C2.97071 4.78046 2.73714 5.00154 2.73714 5.27355V9.32692H7.07349V5.27355C7.07349 5.00154 6.84015 4.78046 6.55305 4.78046H3.2578ZM7.85437 5.23294V9.32692H8.56577C9.06699 9.32692 9.47687 9.71527 9.47687 10.1899V12V12V18.3504C9.47687 18.7932 9.06699 19.1557 8.56577 19.1557H7.85437V19.8697C7.85437 19.9413 7.78817 20 7.70711 20H6.96316C6.8821 20 6.81589 19.9413 6.81589 19.8697V19.1557H2.99496V19.8697C2.99496 19.9413 2.92854 20 2.84748 20H2.10374C2.02269 20 1.95626 19.9413 1.95626 19.8697V19.1557H1.24486C0.74387 19.1557 0.333984 18.7932 0.333984 18.3504V12V12V10.1899C0.333984 9.71527 0.74387 9.32692 1.24486 9.32692H1.95626V5.23294C1.95626 4.55313 2.54007 4 3.2578 4H6.55305C7.27057 4 7.85437 4.55313 7.85437 5.23294Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.235 11.2519H12.765C13.535 11.2519 14.015 10.4169 13.63 9.75192L9.865 3.24692C9.48 2.58192 8.52 2.58192 8.135 3.24692L4.37 9.75192C3.985 10.4169 4.465 11.2519 5.235 11.2519ZM9 7.75192C8.725 7.75192 8.5 7.52692 8.5 7.25192V6.25192C8.5 5.97692 8.725 5.75192 9 5.75192C9.275 5.75192 9.5 5.97692 9.5 6.25192V7.25192C9.5 7.52692 9.275 7.75192 9 7.75192ZM8.5 9.75192H9.5V8.75192H8.5V9.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
