import React from "react"

export const AllInclusiveIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M8.257 14.389v.008a1.09 1.09 0 0 1-1.094 1.094H2.35a1.09 1.09 0 0 1-1.094-1.094v-.009L.462 4.062c0-.301.122-.572.32-.77.198-.198.47-.32.773-.32h4.313L4.042 13.22a.229.229 0 0 0 .188.268l.833.143a.231.231 0 0 0 .267-.187L7.196 2.972h.761A1.09 1.09 0 0 1 9.051 4.06l-.794 10.329zM6.777.499l.379.067-.168.943-.379-.068.168-.943zm.085 1.719l-.38-.068.046-.254.38.067-.046.255zm-1.946 10.92l-.38-.065L6.402 2.604l.379.068-1.865 10.466zM7.957 2.51h-.679L7.651.42a.23.23 0 0 0-.187-.267l-.833-.15a.23.23 0 0 0-.268.186L5.95 2.511H1.555C.696 2.51 0 3.206 0 4.066v.008l.795 10.341c.01.85.702 1.537 1.555 1.538h4.813c.853 0 1.544-.687 1.554-1.538l.796-10.34v-.01c0-.859-.696-1.555-1.556-1.555z"
    />
  </svg>
)
