import React from "react"
import styles from "./AppBar.styles"

import MaterialAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import { useTheme } from "@basset-la/themed-components"
import Text from "../Text/Text"

export interface Props {
  title: string
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative"
  rightButton?: React.ReactNode
  children?: React.ReactNode
}

const AppBar: React.FC<Props> = ({ title, children, rightButton, position }) => {
  const theme = useTheme()

  return (
    <MaterialAppBar position={position} className={styles.root(theme)}>
      <Toolbar className={styles.toolbar(theme)}>
        <div className={styles.title}>
          <Text variant="light" size={18}>
            {title}
          </Text>
          {rightButton}
        </div>
        {children !== undefined && <div className={styles.children(theme)}>{children}</div>}
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
