import { useTheme } from "@basset-la/themed-components"
import React from "react"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../.."
import styles from "./AccommodationRemarks.styles"
import { Text } from "@basset-la/components-commons"
import InfoIcon from "../../icons/InfoIcon"

export interface Props {
  title?: string
  remarks: string
}

const AccommodationRemarks: React.FC<Props> = ({ title, remarks }) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  remarks = remarks
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;lt;/g, "<")
    .replace(/&amp;gt;/g, ">")
    .replace(/\\\\r/g, "<br/>")
    .replace(/\\\\n/g, "<br/>")

  return (
    <div className={styles.root(theme)}>
      <div className={styles.titleContainer(theme)}>
        <InfoIcon color={theme.colors.brand_primary} />
        <Text variant="bold" size={16}>
          {title || t("AccommodationRemarks.title")}
        </Text>
      </div>
      <Text variant="regular" size={14}>
        <span dangerouslySetInnerHTML={{ __html: remarks }} />
      </Text>
    </div>
  )
}

export default AccommodationRemarks
