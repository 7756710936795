import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { colors } from "../../utils/constants/constants"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontFamily: "Roboto",
      justifyContent: "space-between",
      alignItems: "end",
      gap: "8px"
    }),
  title: css({
    fontSize: "12px",
    lineHeight: "1.33",
    marginBottom: "8px"
  }),
  priceContainer: css({
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    flex: "1 1 auto"
  }),
  buttonContainer: css({
    alignSelf: "flex-end",
    marginTop: "8px",
    flex: "1 1 auto",
    ">div>.MuiButton-root.MuiButton-contained": {
      minWidth: "0px"
    }
  }),
  textField: (t: Theme) =>
    css({
      "&.MuiFormControl-root": {
        ">.MuiOutlinedInput-root": {
          fontSize: "14px",
          lineHeight: "1.14",
          letterSpacing: "0.15px",
          color: t.colors.text_primary,
          backgroundColor: t.colors.background_primary,
          "&.Mui-focused": {
            ">fieldset": {
              border: `2px solid ${t.colors.brand_primary}`
            },
            ":hover": {
              ">fieldset": {
                border: `2px solid ${t.colors.brand_primary}`
              }
            }
          },
          ":hover": {
            ">fieldset": {
              border: `2px solid ${t.colors.text_variant}`
            }
          },
          ">fieldset": {
            border: `1px solid ${t.colors.text_variant}`
          },
          "> .MuiOutlinedInput-input": {
            padding: "12px 8px"
          },
          ">.MuiInputAdornment-root>p": {
            fontSize: "14px",
            width: "max-content",
            color: t.colors.text_primary,
            lineHeight: "1.14",
            letterSpacing: "0.15px"
          }
        }
      }
    }),
  toggle: css({
    "& .MuiButtonBase-root.Mui-disabled": {
      borderColor: colors.GREEN3,
      backgroundColor: colors.GREEN3
    }
  })
}
