import React from "react"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import styles from "./PriceTextField.styles"
import Icon from "@material-ui/icons/AttachMoneyRounded"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props {
  value?: number
  onChange: (v: number) => void
}

const PriceTextField: React.FC<Props> = ({ value, onChange }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(parseInt(event.target.value))
  }

  return (
    <div className={styles.field}>
      <Text className={styles.labelText(theme)} variant="regular" size={14}>
        {t("PriceTextField.label")}
      </Text>
      <SearchboxTextField
        value={value}
        onChange={handleOnChange}
        style={{ width: "100%" }}
        leftIcon={<Icon className={styles.icon(theme)} />}
        type="number"
      />
    </div>
  )
}

export default PriceTextField
