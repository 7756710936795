import React from "react"
import { DayPickerSingleDateController } from "react-dates"
import { Moment } from "moment"

import { datepickerStyle } from "../datepickerStyle"
import AbstractDatePicker, { AbstractDatePickerProps, ColorProps } from "../AbstractDatePicker"

export interface Props extends AbstractDatePickerProps {
  selectedDate?: Moment | null
  onDateChange: (date: Moment | null) => void
  onFocusChange: (focused: boolean) => void
  colors?: ColorProps
}

class SingleDatePicker extends AbstractDatePicker<Props> {
  static defaultProps = {
    ...AbstractDatePicker.defaultProps,
    selectedDate: null
  }

  onFocusChanged = (args: { focused: boolean | null }) => {
    const { onFocusChange } = this.props
    onFocusChange(!!args.focused)
  }

  render() {
    const { selectedDate, onDateChange, colors } = this.props

    return (
      <div className={`${datepickerStyle(colors)}`}>
        <DayPickerSingleDateController
          date={selectedDate!}
          onDateChange={onDateChange}
          focused={true}
          numberOfMonths={this.getNumberOfMonths()}
          onFocusChange={this.onFocusChanged}
          orientation={this.getOrientation()}
          firstDayOfWeek={1}
          isOutsideRange={this.isOutsideRange}
          hideKeyboardShortcutsPanel={true}
        />
      </div>
    )
  }
}

export default SingleDatePicker
