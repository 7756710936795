import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

const TwinBedIcon = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" {...props}>
      <g fill="#4A4A4A" fill-rule="evenodd">
        <path d="M1.173.088C1.084.174 1.07.455 1.07 2.34c0 1.854.013 2.15.092 2.12.051-.02.304-.11.57-.206l.478-.17v-.551c0-.62.104-.856.5-1.122 1.152-.777 4.303-.777 5.455 0 .395.266.5.502.5 1.122v.55l.478.171.573.206c.083.03.092-.237.076-2.149C9.783 1.11 9.75.105 9.72.075c-.03-.03-1.94-.06-4.25-.07C1.704-.008 1.264 0 1.173.089" />
        <path d="M3.761 4.455C1.932 4.75.581 5.466.167 6.363c-.136.296-.14.373-.158 2.842-.021 2.911-.053 2.747.537 2.747.595 0 .589.012.589-1.14V9.8h8.607v1.012c0 1.152-.006 1.14.595 1.14.584 0 .552.164.531-2.747-.018-2.456-.023-2.548-.155-2.836-.385-.835-1.496-1.487-3.123-1.83-.811-.172-2.987-.22-3.829-.084" />
      </g>
    </svg>
  )
}

export default TwinBedIcon
