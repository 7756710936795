import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    height: "60px"
  }),
  select: (t: Theme) =>
    css({
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: t.colors.brand_primary
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: t.colors.brand_primary
      },
      "&.MuiOutlinedInput-root": {
        borderRadius: "1px",
        background: "#fff",
        width: "90px"
      },
      ">.MuiSelect-select": {
        padding: "8px",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 500
      },
      " >.MuiSelect-select": {
        border: "none"
      },
      ">.MuiSelect-select.MuiSelect-select": {
        paddingLeft: "8px",
        paddingRight: "40px"
      },
      "&.MuiInput-underline": {
        width: "fit-content",
        ">.MuiSvgIcon-root": {
          width: "24px",
          height: "24px"
        }
      },
      ".MuiSelect-iconOutlined": {
        width: "24px",
        height: "24px"
      },
      " .MuiOutlinedInput-notchedOutline": {
        borderRadius: "1px"
      }
    }),
  menuItem: css({
    "&.MuiMenuItem-root": {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 500
    }
  })
}
