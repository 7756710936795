import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme, mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: mini ? "column" : "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      fontSize: "14px",
      fontWeight: 300,
      flex: 1,
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      "@media screen and (max-width: 425px)": {
        flexDirection: "column"
      }
    }),
  airlineContainer: (mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      flex: 1,
      flexShrink: 0,
      marginBottom: mini ? "4px" : 0,
      "@media screen and (max-width: 425px)": {
        marginBottom: "4px",
        width: "100%"
      }
    }),
  airline: (t: Theme, mini: boolean | undefined, showAirlineCode: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "row",
      flex: 1,
      flexShrink: 1,
      minWidth: 0,
      alignSelf: "center",
      "> p": {
        width: mini ? "100%" : showAirlineCode ? "fit-content" : "75%",
        minWidth: 0,
        margin: 0,
        marginLeft: "8px",
        color: t.colors.text_primary,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "@media screen and (max-width: 425px)": {
          width: "100%"
        }
      }
    }),
  departureArrival: css({
    display: "flex",
    flex: 4,
    // flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    justifyContent: "space-between"
  }),
  airportTime: (t: Theme) =>
    css({
      display: "flex",
      margin: 0,
      minWidth: "80px",
      alignSelf: "center",
      color: t.colors.text_primary
    }),
  hour: css({
    fontWeight: 500,
    marginLeft: "4px"
  }),
  daysDiff: css({
    fontSize: "8px",
    lineHeight: 1,
    letterSpacing: "0.08px",
    color: colors.RED,
    marginLeft: "4px"
  }),
  legGraphic: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "16px",
    alignSelf: "center"
  })
}
