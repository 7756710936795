import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const DepartureAirplaneIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16" {...rest}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2 0.3H9.6L5.6 6.7L1.2 6.7C0.536 6.7 0 7.236 0 7.9C0 8.564 0.536 9.1 1.2 9.1H5.6L9.6 15.5H11.2L9.2 9.1L13.6 9.1L14.8 10.7H16L15.2 7.9L16 5.1H14.8L13.6 6.7H9.2L11.2 0.3Z"
    />
  </svg>
)
