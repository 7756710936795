import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),

  activeFilterContainer: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_variant,
      padding: "8px 0"
    }),

  filterContainer: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_variant,
      padding: "8px 0",
      borderTop: `1px solid rgba(0, 0, 0, 0.12)`
    })
}

export default styles
