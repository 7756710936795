import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "flex-start",
      backgroundColor: t.colors.background_primary
    }),
  label: css({
    textTransform: "uppercase",
    marginRight: "4px"
  }),
  description: css({
    textTransform: "capitalize"
  }),
  onClick: css({
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start"
  }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        color: t.colors.brand_primary,
        height: "16px"
      }
    })
}
