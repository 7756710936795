import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const StopIcon: React.FunctionComponent<Props> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="16px" viewBox="0 0 24 16" {...props}>
    <path
      fill={props.color ? props.color : "#4A4A4A"}
      fillRule="nonzero"
      d="M4 7.6h5.736A2.392 2.392 0 0 1 12 6c1.048 0 1.936.664 2.264 1.6H20v1.6h-5.736A2.392 2.392 0 0 1 12 10.8a2.392 2.392 0 0 1-2.264-1.6H4V7.6z"
    />
  </svg>
)
