import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const AlertIcon: React.FunctionComponent<Props> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="16px" viewBox="0 0 24 16" {...props}>
    <path
      fill="#DDA800"
      fillRule="evenodd"
      d="M4 15h16L12 1 4 15zm8.727-2.21h-1.454v-1.474h1.454v1.473zm0-2.948h-1.454V6.895h1.454v2.947z"
    />
  </svg>
)
