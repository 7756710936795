import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      backgroundColor: t.colors.background_primary,
      display: "flex",
      flexDirection: "column",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      color: t.colors.text_primary,
      fontWeight: "normal"
    }),
  airlineContainer: css({
    paddingBottom: "0 !important"
  }),
  airline: css({
    margin: 0,
    alignSelf: "center"
  }),
  bold: css({
    fontWeight: 500
  }),
  destinationInfo: (mini: boolean | undefined) =>
    css({
      display: "flex",
      flexDirection: "column",
      marginBottom: mini ? "8px" : 0,
      "> p": {
        margin: 0
      },
      "@media screen and (max-width: 425px)": {
        marginBottom: "8px"
      }
    }),
  duration: (mini: boolean | undefined) =>
    css({
      width: "100%",
      margin: 0,
      alignSelf: "center",
      fontWeight: 500,
      textAlign: mini ? "left" : "center",
      marginBottom: mini ? "8px" : 0,
      "@media screen and (max-width: 425px)": {
        textAlign: "left",
        marginBottom: "8px"
      }
    }),
  cabin: (mini: boolean | undefined) =>
    css({
      alignSelf: "center",
      flex: 1,
      flexDirection: "row",
      "> p": {
        margin: 0,
        textAlign: mini ? "left" : "center",
        fontWeight: 500,
        color: colors.LIGHT_BLUE,
        "@media screen and (max-width: 425px)": {
          textAlign: "left"
        }
      }
    }),
  technicalStop: (t: Theme) =>
    css({
      " p": {
        fontSize: "14px",
        lineHeight: "1.14",
        letterSpacing: "0.15px",
        color: t.colors.text_primary
      }
    }),
  technicalStopDescription: (mini: boolean | undefined) =>
    css({
      margin: 0,
      marginBottom: mini ? "8px" : 0,
      ":last-child": {
        marginBottom: 0
      },
      "@media screen and (max-width:425px)": {
        marginBottom: "8px"
      }
    }),
  technicalStopIcon: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "16px",
    width: "16px"
  }),
  technicalStopDuration: (mini: boolean | undefined) =>
    css({
      width: "100%",
      textAlign: mini ? "left" : "center",
      margin: 0,
      fontWeight: 500,
      alignSelf: "center",
      marginTop: mini ? "8px" : 0,
      "@media screen and (max-width:425px)": {
        textAlign: "left",
        marginTop: "8px"
      }
    })
}
