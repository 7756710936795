import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexFlow: "row nowrap",
      width: "100%",
      justifyContent: "space-between",
      backgroundColor: t.colors.background_primary
    }),
  content: css({
    display: "flex",
    alignItems: "center"
  }),
  label: (v: "oneRow" | "twoRows") =>
    css({
      flexShrink: 0,
      display: "flex",
      justifyContent: v === "oneRow" ? "center" : "flex-start",
      alignSelf: v === "oneRow" ? "center" : "flex-start",
      margin: v === "oneRow" ? "0 16px 0 0" : "0 16px 16px 0",
      textTransform: "capitalize"
    }),
  oneRow: css({
    flexFlow: "row nowrap"
  }),
  twoRows: css({
    flexFlow: "column"
  }),
  children: css({
    width: "100%",
    display: "flex",
    flexFlow: "row wrap",
    "& > div": {
      marginRight: "32px"
    }
  }),
  icon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      cursor: "pointer"
    })
}
