import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto"
  }),
  currencySelector: css({
    justifyContent: "flex-end",
    marginBottom: "0px"
  }),
  exchangeRateContainer: css({
    marginBottom: "16px",
    justifyContent: "flex-end"
  }),
  exchangeRateText: css({
    fontSize: "10px",
    letterSpacing: "0.11px"
  })
}
