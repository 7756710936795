import React from "react"
import { DocumentOption, RoomCompanionData } from "../../model/roomSection"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { I18N_NS } from "../../utils/constants"
import DocumentField from "./DocumentField"
import TextField from "@basset-la/components-commons/dist/components/TextField"
import Text from "@basset-la/components-commons/dist/components/Text"
import styles from "./CompanionDataContainer.styles"
import { IconButton } from "@material-ui/core"
import Close from "@material-ui/icons/Close"
import Info from "@material-ui/icons/Info"

interface Props {
  title: string
  roomNumber: number
  index: number
  companion: RoomCompanionData
  onChange: (roomNumber: number, index: number, field: string, value: string) => void
  onDelete: (roomNumber: number, index: number) => void
  checkoutConfig?: any
}

const CompanionDataContainer: React.FC<Props> = ({
  title,
  roomNumber,
  index,
  companion,
  onChange,
  onDelete,
  checkoutConfig
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()

  const handleOnChange = (index: number, field: string, value: string) => {
    onChange(roomNumber, index, field, value)
  }

  const handleOnDelete = () => {
    onDelete(roomNumber, index)
  }

  const onChangeWithField = (e: any) => {
    const field = e.target.name.split("-")[0]
    const i = Number(e.target.name.split("-")[1])
    onChange(roomNumber, i, field, e.target.value)
  }

  const documentTypes: string[] | undefined = checkoutConfig && checkoutConfig["FLIGHTS"].passengers.documentTypes
  const documentOptions: DocumentOption[] | undefined =
    documentTypes &&
    documentTypes.map((type: string) => {
      return {
        code: type,
        name: t(`DocumentField.documentType.${type}`)
      }
    })

  return (
    <div className={styles.companionDataContainerRoot}>
      <div className={styles.companionDataContainer(theme, !!companion.general_error)}>
        <div className={styles.head}>
          <Text className={styles.headTitle(theme)} size={14} variant="bold">
            {title}
          </Text>
          <IconButton className={styles.closeButton} onClick={handleOnDelete}>
            <Close className={styles.closeIcon(theme)} />
          </IconButton>
        </div>
        <div className={styles.companionDataSubContainer}>
          <div className={`${styles.fieldInCompanion}`}>
            <Text className={styles.textFieldTitle(theme)} size={14} variant="regular">
              {t("RoomSection.firstNameField.title")}
            </Text>
            <TextField
              variant="text"
              className={styles.textField(theme)}
              placeholder={t("RoomSection.firstNameField.placeholder")}
              value={companion.firstName}
              onChange={e => handleOnChange(index, "firstName", e.target.value)}
              error={!!companion.firstName_error}
              helperText={companion.firstName_error && companion.firstName_error}
              fullWidth
            />
          </div>
          <div className={`${styles.fieldInCompanion}`}>
            <Text className={styles.textFieldTitle(theme)} size={14} variant="regular">
              {t("RoomSection.lastNameTextField.title")}
            </Text>
            <TextField
              variant="text"
              className={styles.textField(theme)}
              placeholder={t("RoomSection.lastNameTextField.placeholder")}
              value={companion.lastName}
              onChange={e => handleOnChange(index, "lastName", e.target.value)}
              error={!!companion.lastName_error}
              helperText={companion.lastName_error && companion.lastName_error}
              fullWidth
            />
          </div>
        </div>
        <div className={styles.companionDataSubContainer}>
          <DocumentField
            className={styles.documentFieldInCompanion}
            documentOptions={documentOptions}
            documentNumberName={`documentNumber-${index}`}
            documentNumberErrorText={companion.documentNumber_error}
            documentNumberValue={companion.documentNumber}
            documentTypeName={`documentType-${index}`}
            documentTypeValue={companion.documentType}
            documentTypeErrorText={companion.documentType_error}
            onChange={onChangeWithField}
          />
        </div>
      </div>
      {companion.general_error && (
        <div className={styles.errorTextContainer}>
          <Info className={styles.infoIcon(theme)}></Info>
          <Text variant="regular" size={12} color={"error"}>
            {companion.general_error}
          </Text>
        </div>
      )}
    </div>
  )
}

export default CompanionDataContainer
