import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const InternetAlertIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg width="14" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.371325 4.69331C0.711325 5.03331 1.25132 5.06665 1.61799 4.75998C4.73133 2.19998 9.25133 2.19998 12.3713 4.75331C12.7447 5.05998 13.2913 5.03331 13.6313 4.69331C14.0247 4.29998 13.998 3.64665 13.5647 3.29331C9.75799 0.17998 4.25132 0.17998 0.437991 3.29331C0.00465806 3.63998 -0.0286753 4.29331 0.371325 4.69331ZM5.54466 9.86665L6.52466 10.8466C6.78466 11.1066 7.20466 11.1066 7.46466 10.8466L8.44466 9.86665C8.75799 9.55331 8.69133 9.01331 8.29132 8.80665C7.47799 8.38665 6.50466 8.38665 5.68466 8.80665C5.30466 9.01331 5.23132 9.55331 5.54466 9.86665ZM4.27799 7.46665C3.89799 7.73998 3.38466 7.70665 3.05799 7.37998C2.65132 6.97331 2.67799 6.29998 3.13799 5.96665C5.43799 4.30665 8.56466 4.30665 10.858 5.96665C11.318 6.29331 11.3447 6.97331 10.9447 7.37331L10.938 7.37998C10.6113 7.70665 10.098 7.73331 9.71799 7.46665C8.09132 6.31331 5.90466 6.31331 4.27799 7.46665Z"
      fill="#9B9B9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.235 10.2519H14.765C15.535 10.2519 16.015 9.41692 15.63 8.75192L11.865 2.24692C11.48 1.58192 10.52 1.58192 10.135 2.24692L6.37 8.75192C5.985 9.41692 6.465 10.2519 7.235 10.2519ZM11 6.75192C10.725 6.75192 10.5 6.52692 10.5 6.25192V5.25192C10.5 4.97692 10.725 4.75192 11 4.75192C11.275 4.75192 11.5 4.97692 11.5 5.25192V6.25192C11.5 6.52692 11.275 6.75192 11 6.75192ZM10.5 8.75192H11.5V7.75192H10.5V8.75192Z"
      fill="#FDB43C"
    />
  </svg>
)
