import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "32px",
      width: "104px",
      boxSizing: "border-box",
      borderRadius: "4px",
      backgroundColor: t.colors.background_variant,
      alignItems: "center",
      fontFamily: "Roboto",
      color: t.colors.text_primary,
      fontSize: "14px",
      lineHeight: "1.14"
    }),
  button: (t: Theme) =>
    css({
      "&.MuiIconButton-root": {
        padding: "8px",
        "&.Mui-disabled": {
          " .MuiSvgIcon-root": {
            color: t.colors.text_variant
          }
        }
      },
      " .MuiSvgIcon-root": {
        color: t.colors.brand_primary,
        fontSize: "16px"
      }
    })
}
