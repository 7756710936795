import React from "react"
import { AccommodationCatalog } from "../../model/accommodationDetail"
import styles from "./AccommodationDetail.styles"
import AccommodationCarousel from "../AccommodationCarousel"
import SimpleAccommodationInfo from "../SimpleAccommodationInfo/SimpleAccommodationInfo"
import AccommodationInfo from "./AccommodationInfo"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import AmenitiesDescription from "./AmenitiesDescription"
import AccommodationZone from "./AccommodationZone"
import animateScrollTo from "animated-scroll-to"
import { calculateHighlightedAmenities } from "../../utils/helpers/helpers"
import { useTheme } from "@basset-la/themed-components"
import Checkin from "./Checkin"
import AmenitiesPreview from "./AmenitiesPreview"
import useMediaQuery from "@material-ui/core/useMediaQuery"

export interface Props {
  accomomdation: AccommodationCatalog
  googleMapsApiKey: string
  infoComponent?: React.ReactNode
  detailComponent?: React.ReactNode
}

const AccommodationDetail: React.FC<Props> = ({ accomomdation, infoComponent, googleMapsApiKey, detailComponent }) => {
  const { t } = useTranslation(I18N_NS)
  const { address, name, stars, description, amenities, location, zone, images, checkin, checkout } = accomomdation

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const highlightedAmenities = calculateHighlightedAmenities(amenities)

  let checkinInstructions = ""

  if (checkin) {
    if (checkin.instructions) checkinInstructions = checkinInstructions + checkin.instructions
    if (checkin.special_instructions) checkinInstructions = checkinInstructions + checkin.special_instructions
  }

  const handleViewAmenities = () => {
    animateScrollTo(document.querySelector("#amenitiesDescription")! as HTMLElement)
  }

  const theme = useTheme()

  return (
    <div className={styles.root(theme)}>
      <div className={styles.content}>
        <div className={styles.accommodationInfo}>
          <div className={styles.photos}>
            <AccommodationCarousel imgs={images.map(i => i.url)} />
          </div>
          <div className={styles.infoContainer(theme)}>
            <div className={styles.info}>
              <SimpleAccommodationInfo address={address} stars={stars} name={name} {...highlightedAmenities} />
            </div>
            <div className={styles.infoComponent}>{infoComponent}</div>
          </div>
        </div>
        <div className={styles.amenitiesCheckin(theme)}>
          <AmenitiesPreview onViewAmenities={handleViewAmenities} {...highlightedAmenities} />
          <Checkin
            checkinTime={checkin && checkin.time ? checkin.time : ""}
            checkoutTime={checkout && checkout.time ? checkout.time : ""}
          />
        </div>
        {detailComponent}
        {description && (
          <div className={styles.section(theme)}>
            <AccommodationInfo
              description={description}
              title={t("AccommodationDetail.descriptionTitle")}
              isMobile={isMobile}
            />
          </div>
        )}
        <div id={"amenitiesDescription"} className={styles.section(theme)} style={{ borderBottom: "unset" }}>
          <AmenitiesDescription amenities={amenities} isMobile={isMobile} />
        </div>
        {zone && (
          <div className={styles.zone(theme)}>
            <AccommodationZone
              address={address}
              hotelName={name}
              hotelCoordinates={location}
              hotelImg={images[0].url}
              zone={zone}
              googleMapsApiKey={googleMapsApiKey}
              viewMap={true}
              isMobile={isMobile}
            />
          </div>
        )}
        {checkinInstructions !== "" && (
          <div className={styles.section(theme)}>
            <AccommodationInfo
              description={checkinInstructions}
              title={t("AccommodationDetail.checkinInstructions")}
              isMobile={isMobile}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AccommodationDetail
