import { useTheme } from "@basset-la/themed-components"
import React from "react"
import styles from "./AccommodationFiltersCollapsePanel.styles"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

export interface Props {
  renderSummary?: React.ReactNode
  renderDetails?: React.ReactNode
}

const AccommodationFiltersCollapsePanel: React.FunctionComponent<Props> = ({ renderSummary, renderDetails }) => {
  const theme = useTheme()

  return (
    <ExpansionPanel className={styles.expansionPanel} defaultExpanded={true}>
      <ExpansionPanelSummary className={styles.expansionPanelSummary(theme)} expandIcon={<ExpandMoreIcon />}>
        {renderSummary}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={styles.expansionPanelDetails}>{renderDetails}</ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default AccommodationFiltersCollapsePanel
