import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
export default {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),
  container: css({
    paddingBottom: "16px",
    " > div": {
      backgroundColor: "inherit",
      marginTop: "-15px"
    }
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  popover: (width: number | undefined) =>
    css({
      width: width ? `calc(${width}px)` : 0,
      zIndex: 2000,
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12)"
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  dialogContainer: css({
    padding: "16px 8px 0 8px"
  }),
  divCheckFilter: css({
    marginTop: "20px"
  })
}
