import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

export const LocationIcon: React.FunctionComponent<Props> = ({ color, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" {...rest}>
    <g fill="none" fillRule="evenodd">
      <path fill="#FFF" fillOpacity=".008" d="M0 .923h14.77v14.77H0z" />
      <path
        fill={color}
        d="M7.385 2.154a4.305 4.305 0 0 0-4.308 4.308c0 3.23 4.308 8 4.308 8s4.307-4.77 4.307-8a4.305 4.305 0 0 0-4.307-4.308zm0 5.846a1.539 1.539 0 1 1 0-3.078 1.539 1.539 0 0 1 0 3.078z"
      />
    </g>
  </svg>
)
