import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "24px"
  }),
  image: (link: string) =>
    css({
      objectFit: "fill",
      width: "300px",
      height: "216px",
      borderRadius: "4px",
      cursor: !!link ? "pointer" : undefined
    })
}

export default styles
