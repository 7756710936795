import React from "react"
import styles from "./PriceboxQuoted.styles"
import { formatPrice, getLocale } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  total: number
  currency: string
  adults?: number
  children?: number
  seniors?: number
  infants?: number
  disabled?: number
}

const PriceboxQuoted: React.FC<Props> = ({ total, currency, adults, children, seniors, infants, disabled }: Props) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const priceToString = (value: number, currency?: string): string => {
    return `${priceFormated(value)}`
  }

  const priceFormated = (value: number) => {
    return formatPrice(value, locale, currency, { hideCurrency: true })
  }

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.title}`}>{t("PriceboxQuoted.title")}</div>
      <div className={`${styles.passanger}`}>
        {!!adults && adults !== 0 && (
          <span>
            {t("PriceboxQuoted.adults", {
              count: adults
            })}
          </span>
        )}
        {!!children && children !== 0 && (
          <span>
            {t("PriceboxQuoted.children", {
              count: children
            })}
          </span>
        )}
        {!!seniors && seniors !== 0 && (
          <span>
            {t("PriceboxQuoted.seniors", {
              count: seniors
            })}
          </span>
        )}
        {!!infants && infants !== 0 && (
          <span>
            {t("PriceboxQuoted.infants", {
              count: infants
            })}
          </span>
        )}
        {!!disabled && disabled !== 0 && (
          <span>
            {t("PriceboxQuoted.disabled", {
              count: disabled
            })}
          </span>
        )}
      </div>
      <div className={`${styles.price}`}>
        {currency}
        <span className={`${styles.bigNumber}`}>{priceToString(total)}</span>
      </div>
    </div>
  )
}

export default PriceboxQuoted
