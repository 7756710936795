import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (mini: boolean | undefined, theme: Theme) =>
    css({
      display: "flex",
      flex: 1,
      flexShrink: 1,
      flexGrow: 1,
      minWidth: 0,
      position: "relative",
      backgroundColor: theme.colors.background_primary,
      flexDirection: mini ? "column" : "row",
      fontFamily: "Roboto",
      "@media screen and (max-width:425px)": {
        flexDirection: "column"
      }
    }),
  photo: (mini: boolean | undefined) =>
    css({
      ":before": {
        content: "' '",
        display: "flex",
        position: "absolute",
        width: mini ? "100%" : "304px",
        height: mini ? "128px" : "100%",
        "@media screen and (max-width:425px)": {
          width: "100%",
          height: "128px",
          maxHeight: "312px"
        }
      },
      display: "flex",
      boxSizing: "border-box",
      width: mini ? "100%" : "304px",
      height: mini ? "128px" : "auto",
      maxHeight: mini ? "312px" : "218px",
      "@media screen and (max-width:425px)": {
        width: "100%",
        height: "128px",
        maxHeight: "312px"
      }
    }),
  infoContainer: (mini: boolean | undefined, hasPhoto: boolean) =>
    css({
      display: "flex",
      flex: 1,
      flexDirection: "column",
      minHeight: "112px",
      flexShrink: 1,
      minWidth: 0,
      flexGrow: 1,
      justifyContent: "space-between",
      padding: hasPhoto ? (mini ? "8px" : "16px") : 0,
      "@media screen and (max-width:425px)": {
        padding: hasPhoto ? "8px" : 0,
        minHeight: "152px"
      },
      "> div": {
        flex: 1
      }
    }),
  nameContainer: css({
    display: "flex",
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0,
    flexDirection: "row",
    alignItems: "center"
  }),
  name: (mini: boolean | undefined, theme: Theme) =>
    css({
      margin: 0,
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "1.33",
      color: theme.colors.text_primary,
      whiteSpace: "initial",
      wordWrap: "break-word",
      marginRight: mini ? "8px" : "16px",
      "@media screen and (max-width:425px)": {
        marginRight: "8px"
      }
    }),
  firstRow: css({
    display: "flex",
    flexShrink: 1,
    flexGrow: 1,
    minWidth: 0,
    flexDirection: "column"
  }),
  addressContainer: css({
    display: "flex"
  }),
  mealPlanAmenitiesContainer: css({
    display: "flex",
    flexDirection: "row",
    padding: "16px 0",
    "@media screen and (max-width: 425px)": {
      padding: 0,
      marginTop: "8px"
    },
    "@media screen and (max-width: 1395px)": {
      flexDirection: "column"
    }
  }),
  mealPlan: css({
    marginRight: "8px",
    display: "flex"
  }),
  rating: css({}),
  cancelAndLastRooms: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: "16px"
  }),
  freeCancel: css({
    margin: 0,
    fontSize: "12px",
    lineHeight: "1.33",
    textAlign: "center",
    borderRadius: "4px",
    padding: "1px 4px",
    backgroundColor: colors.LIGHT_BLUE2,
    color: colors.BLUE
  }),
  lastRooms: css({
    margin: 0,
    fontSize: "12px",
    lineHeight: "1.33",
    textAlign: "center",
    borderRadius: "4px",
    padding: "1px 4px",
    backgroundColor: colors.LIGHT_RED,
    color: colors.RED2
  })
}
