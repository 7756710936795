import i18next from "i18next"

export const formatPrice = (
  price: number,
  locale: string,
  currency: string,
  opts?: { hideCurrency?: boolean; minimumFractionDigits?: number; maximumFractionDigits?: number }
): string => {
  if (currency === "CLP") {
    return formatChileanPesos(price)
  }

  const hideCurrency = opts && opts.hideCurrency
  const minimumFractionDigits = opts ? opts.minimumFractionDigits : undefined
  const maximumFractionDigits = opts ? opts.maximumFractionDigits : undefined

  const priceFormatter = new Intl.NumberFormat(locale, {
    currency: currency,
    currencyDisplay: "code",
    style: "currency",
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits
  })
  const formattedPrice = priceFormatter.format(price)
  if (hideCurrency) {
    // " " is not a blank space, is an special char
    return formattedPrice.replace(" ", "").replace(`${currency}`, "")
  }
  return formattedPrice
}

export const getLocale = (i18n: i18next.i18n): string => {
  if (i18n.language) {
    return i18n.language
  }
  if ((i18n.options?.fallbackLng as i18next.FallbackLng)?.length) {
    return i18n.options.fallbackLng?.[0]
  }
  return "es"
}

const formatChileanPesos = (value: number): string => {
  const price = value.toLocaleString("es-CL", {
    style: "currency",
    currency: "CLP"
  })

  return price.replace("$", "$ ")
}
