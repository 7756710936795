import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      width: "100%",
      "@media screen and (max-width: 1024px)": {
        flexDirection: "column"
      }
    }),
  titleContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    "@media screen and (max-width: 1024px)": {
      flexGrow: "unset"
    }
  }),
  title: css({
    fontSize: "16px",
    lineHeight: "1.5",
    marginLeft: "8px"
  }),
  regionsContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: "3",
    "@media screen and (max-width: 1024px)": {
      flexDirection: "column",
      flexGrow: "unset"
    }
  }),
  region: css({
    display: "flex",
    flexDirection: "row",
    width: "50%",
    fontSize: "12px",
    lineHeight: "1.33",
    "@media screen and (max-width: 1024px)": {
      justifyContent: "space-between",
      width: "100%"
    }
  }),
  regionName: css({
    width: "70%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "@media screen and (max-width: 1024px)": {
      width: "auto",
      maxWidth: "80%"
    }
  }),
  distance: css({
    fontWeight: 500
  })
}
