import React from "react"
import styles from "./CurrencySwitcher.styles"
import { useTheme } from "@basset-la/themed-components"
import ExchangeRateText from "../ExchangeRateText"
export interface OriginalCurrency {
  currency_code: string
  ratio: number
}

export interface Props {
  containerClassName?: string
  currency: string
  originalCurrency: OriginalCurrency
  selectedCurrency?: (selected: string) => void
  enabledSwitch: boolean
  direction?: "row" | "column"
}

const CurrencySwitcher: React.FC<Props> = ({
  containerClassName,
  currency,
  originalCurrency,
  selectedCurrency,
  enabledSwitch,
  direction = "column"
}) => {
  const [active, setSwitch] = React.useState<boolean>(true)
  const handleSwitch = () => {
    setSwitch(active ? false : true)
    if (selectedCurrency) {
      selectedCurrency(active ? originalCurrency.currency_code : currency)
    }
  }
  const theme = useTheme()

  const rootClassName = containerClassName ? `${containerClassName} ${styles.root(theme)}` : styles.root(theme)
  return (
    <div className={rootClassName}>
      <ExchangeRateText currency={currency} originalCurrency={originalCurrency} direction={direction} />
      {enabledSwitch && originalCurrency.currency_code !== currency && (
        <div className={`${styles.switcher(theme)}`}>
          <div className={`${styles.active(active, theme)}`} onClick={handleSwitch}>
            {originalCurrency.currency_code}
          </div>
          <div className={`${styles.active(!active, theme)}`} onClick={handleSwitch}>
            {currency}
          </div>
        </div>
      )}
    </div>
  )
}

export default CurrencySwitcher
