import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      boxSizing: "border-box",
      justifyContent: "space-between",
      flex: 1,
      padding: "16px 24px",
      borderRight: `1px solid ${theme.colors.background_darker}`,
      "@media screen and (max-width: 1024px)": {
        padding: "8px",
        width: "100%",
        borderBottom: `1px solid ${theme.colors.background_darker}`,
        borderRight: "unset"
      }
    }),
  titleContainer: css({
    display: "flex",
    flex: 1
  }),
  amenities: css({
    display: "flex",
    flex: 1,
    boxSizing: "border-box",
    flexDirection: "row",
    alignItems: "center",
    "@media screen and (max-width: 1024px)": {
      flex: 2
    }
  }),
  title: css({
    fontSize: "18px",
    fontWeight: 300,
    lineHeight: "1.33"
  }),
  amenitiesComp: css({
    flex: 2,
    padding: "0 16px",
    "@media screen and (max-width: 1024px)": {
      padding: 0
    }
  }),
  buttonContainer: css({
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media screen and (max-width: 1024px)": {
      justifyContent: "flex-end"
    }
  }),
  button: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        fontSize: "14px",
        fontWeight: 300,
        textTransform: "unset",
        lineHeight: "1.71",
        color: theme.colors.brand_primary
      }
    })
}
