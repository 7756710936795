import React from "react"

const InsurancesIcon = () => {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.57742 0L4.24409 1.33333V2.66667H2.24409C1.47742 2.66667 0.990755 3.33333 0.910755 4L0.244088 10.6667C0.164088 11.3333 0.604088 12 1.57742 12H12.2441C13.2174 12 13.6574 11.3333 13.5774 10.6667L12.9108 4C12.8308 3.33333 12.2841 2.66667 11.5774 2.66667H9.57742V1.33333L8.24409 0H5.57742ZM5.57742 1.33333H8.24409V2.66667H5.57742V1.33333ZM6.24409 4.66667H7.57742V6.66667H9.57742V8H7.57742V10H6.24409V8H4.24409V6.66667H6.24409V4.66667Z"
        fill="white"
      />
    </svg>
  )
}

export default InsurancesIcon
