import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

const CloudUploadIcon: React.FC<Props> = props => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="43" height="39" viewBox="0 0 43 39">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#989898"
          stroke="#FFF"
          strokeWidth=".3"
          d="M33.087 32.148c-.937.308-1.938-.232-2.234-1.2-.296-.968.226-1.999 1.165-2.304 4.59-1.49 6.919-5.312 6.919-11.363 0-6.952-5.484-12.606-12.228-12.606-5.861 0-10.908 4.307-12.004 10.24l-.363 1.973-1.874-.55a6.423 6.423 0 0 0-1.806-.265c-3.639 0-6.599 3.051-6.599 6.8 0 3.75 2.96 6.803 6.599 6.803l.363-.028a1.805 1.805 0 0 1 1.874 1.737c.053 1.015-.7 1.879-1.685 1.933l-.119.01a5.331 5.331 0 0 1-.433.02C5.059 33.349.5 28.65.5 22.874s4.559-10.476 10.162-10.476c.324 0 .648.018.972.05C13.657 5.718 19.74 1 26.71 1 35.417 1 42.5 8.304 42.5 17.28c0 7.73-3.254 12.872-9.413 14.868zM20.359 18.876c.006-.005.008-.015.014-.02a1.756 1.756 0 0 1 2.528 0c.004.003.004.01.01.017l4.918 5.069a1.879 1.879 0 0 1 0 2.599 1.755 1.755 0 0 1-1.261.538c-.457 0-.911-.18-1.26-.538l-1.885-1.944v11.565c0 1.015-.797 1.838-1.783 1.838-.985 0-1.782-.823-1.782-1.838V24.59l-1.893 1.952a1.748 1.748 0 0 1-1.26.538c-.455 0-.912-.18-1.259-.538a1.875 1.875 0 0 1 0-2.599l4.913-5.066z"
        />
        <path d="M-2.5-5h48v48h-48z" />
      </g>
    </svg>
  )
}

export default CloudUploadIcon
