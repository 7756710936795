import React from "react"
import styles from "./ContentBox.styles"
import { useTheme } from "@basset-la/themed-components"

import Card from "../Card"
import Text from "../Text"
import Label from "../Label"
import Create from "@material-ui/icons/Create"

export interface Props {
  label: string
  children: React.ReactElement<typeof Label>[] | React.ReactElement<typeof Label>
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  variant?: "oneRow" | "twoRows"
}

const ContentBox: React.FC<Props> = ({ label, variant = "oneRow", onClick, children }) => {
  const theme = useTheme()

  return (
    <Card>
      <div className={styles.root(theme)}>
        <div className={`${styles.content} ${styles[variant]}`}>
          <div className={styles.label(variant)}>
            <Text size={14}>{label}</Text>
          </div>
          <div className={styles.children}>{children}</div>
        </div>
        <div onClick={onClick}>
          <Create className={styles.icon(theme)} />
        </div>
      </div>
    </Card>
  )
}

export default ContentBox
