import React, { useEffect, useRef, useState } from "react"

import { useTranslation } from "react-i18next"

import Text from "@basset-la/components-commons/dist/components/Text"
import { AutocompleteItem, FullScreenDialog } from "@basset-la/components-products"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import Popper from "@material-ui/core/Popper/Popper"
import ArrowIcon from "@material-ui/icons/ArrowDropDownRounded"

import { DepartureAirplaneIcon } from "../../icons/DepartureAirplaneIcon"
import { I18N_NS } from "../../utils/constants"
import AirlineSearchInput from "./AirlineSearchInput"
import styles from "./AirlinesSelect.styles"

export interface AirlinesSelectProps {
  isMobile?: boolean
  multiple?: boolean
  getAirlines: (searchText: string) => Promise<AutocompleteItem[]>
  onChange: (value: AutocompleteItem[]) => void
  selectedItems: AutocompleteItem[]
  onClearAllSelect?: () => void
  onSelectAllInSearch?: (newSelectedItems: AutocompleteItem[]) => void
}

const AirlinesSelect: React.FC<AirlinesSelectProps> = ({
  isMobile = false,
  multiple = false,
  getAirlines,
  onChange,
  selectedItems,
  onClearAllSelect,
  onSelectAllInSearch
}) => {
  const { t } = useTranslation(I18N_NS)
  const theme = useTheme()
  const [inputValue, setInputValue] = useState("")
  const [items, setItems] = useState<AutocompleteItem[]>([])
  const [countSelectedInSearch, setCountSelectedInSearch] = useState(0)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const inputRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (open) {
      searchInputRef?.current?.focus()
    }
  }, [open])

  const fetchAirlines = async (searchText: string) => {
    if (searchText.length > 1) {
      setLoading(true)
      setItems([])
      try {
        const items = await getAirlines(searchText)
        setItems(items)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    }
  }

  const onCountSelectedInSearchChange = (value: number) => {
    setCountSelectedInSearch(value)
  }

  const handleOnInputChange = (value: string) => {
    setInputValue(value)
    setCountSelectedInSearch(0)
    fetchAirlines(value)
  }

  const internalHandleOnChange = (value: AutocompleteItem[]) => {
    onChange(value)
    if (!multiple) {
      setOpen(false)
    }
  }

  const internalSelectAll = () => {
    const difference = selectedItems.filter(item => !items.find(i => i.iata_code === item.iata_code))
    const allItems = [...difference, ...items]
    if (onSelectAllInSearch) {
      onSelectAllInSearch(allItems)
    }
    setCountSelectedInSearch(allItems.length)
  }

  const internalClearAll = () => {
    if (onClearAllSelect) {
      onClearAllSelect()
    }
    setCountSelectedInSearch(0)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleOnClickAway = () => {
    setOpen(false)
  }

  const buildAirlinesText = (selectedItems: AutocompleteItem[]) => {
    if (selectedItems.length === 0) {
      return t("AirlinesSelect.allAirlines")
    }
    if (selectedItems.length === 1) {
      return selectedItems[0].name
    }
    return t("AirlinesSelect.multipleAirlines", { count: selectedItems.length })
  }

  return (
    <div>
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {t("AirlinesSelect.airlines")}
        </Text>
        <SearchboxTextField
          id="select-searchbox-airlines"
          className={styles.root}
          onClick={handleOpen}
          rightIcon={<ArrowIcon />}
          leftIcon={<DepartureAirplaneIcon color={theme.colors.brand_primary} />}
          value={buildAirlinesText(selectedItems)}
          ref={inputRef}
        />
      </div>
      {!isMobile && (
        <Popper
          className={styles.popover(inputRef.current?.offsetWidth)}
          placement="bottom-start"
          open={open}
          anchorEl={inputRef.current}
          disablePortal
        >
          <AirlineSearchInput
            ref={searchInputRef}
            items={items}
            selectedItems={selectedItems}
            countSelectedInSearch={countSelectedInSearch}
            inputValue={inputValue}
            onChange={internalHandleOnChange}
            onClearAll={internalClearAll}
            onInputChange={handleOnInputChange}
            onSelectAll={internalSelectAll}
            onCountSelectedInSearchChange={onCountSelectedInSearchChange}
            onClickAway={handleOnClickAway}
            loading={loading}
            multiple={multiple}
          />
        </Popper>
      )}
      {isMobile && (
        <FullScreenDialog
          open={open}
          actionButtonText={t("Searchbox.apply")}
          onClose={handleOpen}
          onAction={handleOpen}
          toolbarComponent={
            <SearchboxTextField
              className={styles.toolbarTextField}
              InputProps={{ disabled: true }}
              leftIcon={<DepartureAirplaneIcon color={theme.colors.brand_primary} />}
              value={buildAirlinesText(selectedItems)}
            />
          }
        >
          <div className={styles.dialogContainer}>
            <AirlineSearchInput
              ref={searchInputRef}
              items={items}
              selectedItems={selectedItems}
              countSelectedInSearch={countSelectedInSearch}
              inputValue={inputValue}
              onChange={internalHandleOnChange}
              onClearAll={internalClearAll}
              onInputChange={handleOnInputChange}
              onSelectAll={internalSelectAll}
              onCountSelectedInSearchChange={onCountSelectedInSearchChange}
              loading={loading}
              multiple={multiple}
              isMobile
            />
          </div>
        </FullScreenDialog>
      )}
    </div>
  )
}

export default AirlinesSelect
