import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    margin: 0,
    padding: 0,
    cursor: "pointer"
  }),
  text: (theme: Theme, disabled: boolean | undefined) =>
    css({
      color: disabled ? `${theme.colors.text_variant} !important` : `${theme.colors.brand_primary} !important`
    })
}
