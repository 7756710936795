import React from "react"
import { useTranslation } from "react-i18next"
import { AccommodationFare, I18N_NS } from "../.."
import PriceboxWeb from "@basset-la/components-products/dist/components/PriceboxWeb"

export interface Props {
  isMobile: boolean
  nights: number
  guests: number
  accommodationFare: AccommodationFare
  taxesDisclaimers?: string[]
}

const ClusterPriceWeb: React.FC<Props> = ({ isMobile, nights, guests, accommodationFare, taxesDisclaimers }) => {
  const { t } = useTranslation(I18N_NS)

  const { currency, discount, total } = accommodationFare

  const totalLabel = `${t("AccommodationClusterWeb.night_count", {
    count: nights
  })}, ${t("AccommodationClusterWeb.guest_count", { count: guests })}`
  const strikethrough = discount
    ? {
        discount: discount.discount,
        total: discount.total
      }
    : undefined

  return (
    <PriceboxWeb
      isMobile={isMobile}
      currency={currency}
      title={totalLabel}
      total={{
        label: t("AccommodationClusterWeb.priceTitle"),
        value: total
      }}
      breakdown={[]}
      taxesDisclaimers={taxesDisclaimers}
      strikethrough={strikethrough}
      id={{
        title: "accommodation-price-title",
        totalPrice: "accommodation-total-price"
      }}
    />
  )
}

export default ClusterPriceWeb
