import React from "react"
import styles from "./DiscountLabel.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"

export interface Props {
  value: number
}

const DiscountLabel: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation(I18N_NS)

  return <span className={styles.root}>{t("DiscountLabel.discount", { value: value })}</span>
}

export default DiscountLabel
