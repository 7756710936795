import React from "react"
import { AccommodationPrice, Currency } from "../../model/types"
import {
  convertAgencyAccommodationsPriceBreakdown,
  convertToAgencyCostEffectivenessBreakdown,
  calculateAgencyCostToPay
} from "../../utils/helpers/helpers"
import AccommodationsPricebox from "../PriceboxBreakdown/PriceboxBreakdown"
import { useTranslation } from "react-i18next"
import CostEffectivenessBreakdown from "../PriceboxBreakdownCostEffectiveness/PriceboxBreakdownCostEffectiveness"
import styles from "./PriceboxBreakdownAgency.syles"
import { I18N_NS } from "../../utils/constants"
import { formatPrice, getLocale } from "@basset-la/components-products/dist/utils/helpers"
import CurrencySwitcher from "@basset-la/components-products/dist/components/CurrencySwitcher"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  accommodationPrice: AccommodationPrice
  rooms: number
  guests: number
  currencySelector?: Currency
  taxesDisclaimer?: string
}

const PriceboxBreakdownAgency: React.FC<Props> = ({
  accommodationPrice,
  rooms,
  guests,
  currencySelector,
  taxesDisclaimer
}) => {
  const priceBreakdown = convertAgencyAccommodationsPriceBreakdown(accommodationPrice, rooms, guests)
  const costEffectivenessBreakdown = convertToAgencyCostEffectivenessBreakdown(accommodationPrice)
  const costToPay = calculateAgencyCostToPay(
    priceBreakdown.total,
    priceBreakdown.charges.agencyCharge ? priceBreakdown.charges.agencyCharge.markup : 0,
    costEffectivenessBreakdown.resellerCeded ? costEffectivenessBreakdown.resellerCeded : 0,
    priceBreakdown.taxes.agencyVAT ? priceBreakdown.taxes.agencyVAT : 0
  )

  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  return (
    <div className={`${styles.root}`}>
      {currencySelector && currencySelector.currency !== currencySelector.originalCurrency.currency_code && (
        <CurrencySwitcher
          currency={currencySelector.currency}
          originalCurrency={currencySelector.originalCurrency}
          enabledSwitch={currencySelector.enabledSwitch}
        />
      )}
      <AccommodationsPricebox priceBreakdown={priceBreakdown} taxesDisclaimer={taxesDisclaimer} />
      <CostEffectivenessBreakdown costEffectivenessBreakdown={costEffectivenessBreakdown} />
      <div className={styles.costToPay(theme)}>
        <span>{t("AgencyPricebox.costToPay")}</span>
        <span>{formatPrice(costToPay, locale, accommodationPrice.currency)}</span>
      </div>
    </div>
  )
}

export default PriceboxBreakdownAgency
