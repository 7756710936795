import React from "react"

interface HotelIconProps {
  color?: string
}

export const HotelIcon: React.FC<HotelIconProps> = ({ color = "#126AFF" }) => {
  return (
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C5.10667 5 6 4.10667 6 3C6 1.89333 5.10667 1 4 1C2.89333 1 2 1.89333 2 3C2 4.10667 2.89333 5 4 5ZM12.2727 1.33333H6.81818V6H1.36364V0H0V10H1.36364V8H13.6364V10H15V4C15 2.52667 13.7795 1.33333 12.2727 1.33333Z"
        fill={color}
      />
    </svg>
  )
}
