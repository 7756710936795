import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  appBar: (t: Theme) =>
    css({
      "&.MuiAppBar-colorPrimary": {
        backgroundColor: t.colors.background_primary
      }
    }),
  toolbar: css({
    display: "flex",
    flexDirection: "column",
    "&.MuiToolbar-root": {
      alignItems: "start",
      paddingBottom: "16px"
    }
  }),
  content: css({
    marginTop: "120px"
  }),
  textField: (theme: Theme) =>
    css({
      "&.MuiTextField-root": {
        width: "100%",
        ">.MuiOutlinedInput-adornedEnd": {
          paddingRight: "8px"
        },
        "&.MuiOutlinedInput-root.Mui-focused": {
          borderColor: undefined,
          borderWidth: undefined
        }
      }
    }),
  locationIcon: (t: Theme) =>
    css({
      color: t.colors.brand_primary,
      paddingRight: "8px"
    }),
  clearButton: css({
    "&.MuiIconButton-root": {
      paddingLeft: "8px"
    }
  })
}
