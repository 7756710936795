import { css } from "@emotion/css"

export default {
  root: css({
    padding: "0 !important",
    width: "32px !important",
    height: "32px !important"
  }),
  icon: css({
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "solid 1px #cccccc",
    " > span": {
      display: "block",
      width: "12px",
      height: "12px",
      backgroundRepeat: "no-repeat",
      marginTop: "3px",
      marginLeft: "-4px"
    }
  })
}
