import React from "react"
import styles from "./PriceboxRow.styles"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"
import Collapse from "@material-ui/core/Collapse"

export interface Props {
  label: string
  value: string
  variant?: "text" | "collapsible"
  highlighted?: boolean
  startCollapsed?: boolean
  className?: string
}

const PriceboxRow: React.FC<Props> = ({
  label,
  value,
  variant = "text",
  children,
  highlighted,
  startCollapsed = false,
  className
}) => {
  const [open, setOpen] = React.useState<boolean>(!startCollapsed)

  return (
    <div className={`${styles.root} components-products-PriceboxRow`}>
      <div
        style={{
          cursor: variant === "collapsible" ? "pointer" : "auto",
          fontWeight: highlighted ? 900 : "normal",
          fontSize: highlighted ? "16px" : "inherit"
        }}
        className={styles.title}
        onClick={() => setOpen(!open)}
      >
        <p className={`${styles.titleContainer} ${className}`}>
          <span>{label}</span>
          <span className={styles.value}>{value}</span>
        </p>
        {variant === "collapsible" && (
          <span className={styles.arrow}>{open ? <ArrowDropUp /> : <ArrowDropDown />}</span>
        )}
      </div>
      {variant === "collapsible" && (
        <Collapse component="div" in={open}>
          <div className={styles.childrenContainer}>{children}</div>
        </Collapse>
      )}
    </div>
  )
}

export default PriceboxRow
