import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: theme.colors.background_primary,
      color: theme.colors.text_primary,
      fontFamily: "Roboto",
      padding: "16px",
      boxSizing: "border-box",
      height: "100%"
    }),
  providersContainer: css({
    flex: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  }),
  visualRow: css({
    marginRight: "16px"
  }),
  row: css({
    flex: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  }),
  providerName: css({
    fontSize: "10px",
    fontWeight: 300,
    lineHeight: 1.6,
    margin: 0,
    display: "flex",
    alignItems: "center"
  }),
  badge: css({
    display: "inline-block",
    backgroundColor: "#A877D3",
    borderRadius: "50%",
    padding: "1px",
    marginLeft: "5px",
    verticalAlign: "middle"
  }),
  icon: css({
    color: "#fff",
    verticalAlign: "middle"
  }),
  total: css({
    fontSize: "16px",
    lineHeight: 1.5,
    margin: 0
  }),
  costEffectiveness: css({
    fontSize: "10px",
    lineHeight: 1.6,
    borderRadius: "4px",
    color: colors.GREEN2,
    backgroundColor: colors.LIGHTEST_GREEN,
    width: "fit-content",
    margin: 0,
    padding: "0 4px"
  }),
  moreProvidersContainer: css({
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  }),
  moreProviders: (theme: Theme) =>
    css({
      display: "flex",
      fontFamily: "Roboto",
      color: theme.colors.text_primary,
      flexDirection: "column",
      alignItems: "center",
      padding: "16px",
      "> div": {
        marginBottom: "16px",
        ":last-of-type": {
          marginBottom: 0
        }
      }
    }),
  moreProvidersButton: (theme: Theme) =>
    css({
      minWidth: "72px",
      color: theme.colors.brand_primary,
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "0.15px",
      textAlign: "center",
      cursor: "pointer",
      "> .MuiSvgIcon-root": {
        fontSize: "16px"
      }
    })
}
