import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: (t: Theme, wide: boolean) =>
    css({
      color: t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary,
      fontFamily: "Roboto",
      display: "flex",
      flexDirection: "column",
      padding: wide ? "32px" : "16px",
      backgroundColor: t.colors.searchbox ? t.colors.searchbox.background : t.colors.background_primary,
      minWidth: "320px",
      boxSizing: "border-box",
      borderRadius: "4px",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      ".MuiFormControl-root": {
        backgroundColor: t.colors.background_primary,
        borderRadius: "4px"
      },
      ".MuiInputLabel-root": {
        backgroundColor: t.colors.background_primary,
        padding: "2px 4px",
        borderRadius: "4px"
      }
    }),
  title: css({
    fontSize: "20px",
    lineHeight: "1.2",
    marginBottom: "24px"
  }),
  destinationDatesContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "16px"
  }),
  destinationContainer: css({
    marginBottom: 0,
    flex: "2 1 auto",
    ">.MuiTextField-root": {
      width: "100%"
    }
  }),
  datesContainer: css({
    "@media(min-width: 1024px)": {
      maxWidth: "288px"
    },
    flex: "1 1 auto"
  }),
  divider: css({
    "&.MuiDivider-root": {
      marginTop: "16px",
      marginBottom: "16px"
    }
  }),
  filtersContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "16px",
    ">*": {
      width: "100%",
      "@media(min-width: 1024px)": {
        maxWidth: "288px"
      },
      ":last-of-type": {
        margin: 0
      }
    }
  }),
  freeCancelContainer: (t: Theme) =>
    css({
      marginBottom: 0,
      " .MuiFormControlLabel-root": {
        margin: 0
      },
      " .MuiCheckbox-root": {
        padding: 0,
        marginRight: "8px",
        marginLeft: "-3px",
        borderColor: t.colors.brand_primary,
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary
        }
      }
    }),
  packageRatesContainer: (t: Theme) =>
    css({
      paddingTop: "24px",
      " .MuiFormControlLabel-root": {
        margin: 0
      },
      " .MuiCheckbox-root": {
        padding: 0,
        marginRight: "8px",
        marginLeft: "-3px",
        borderColor: t.colors.brand_primary,
        " .MuiSvgIcon-root": {
          color: t.colors.brand_primary
        }
      }
    }),
  footer: (buttonOnly: boolean) =>
    css({
      display: "flex",
      alignItems: "center",
      justifyContent: buttonOnly ? "flex-end" : "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "16px"
    }),
  searchButtonContainer: css({
    " .MuiButton-contained": {
      minWidth: "136px"
    },
    width: "288px",
    "@media(max-width: 1024px)": {
      width: "100% !important"
    }
  }),
  mobileContent: css({
    padding: "16px"
  })
}
