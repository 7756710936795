import React from "react"
import InputBase from "@material-ui/core/InputBase"
import ButtonBase from "@material-ui/core/ButtonBase"
import SearchIcon from "@material-ui/icons/SearchRounded"
import styles from "./SearchTextField.styles"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  onSearch: () => void
  placeholder?: string
  value?: string | number
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const SearchTextField: React.FC<Props> = ({ onSearch, placeholder, value, onChange }) => {
  const theme = useTheme()

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      onSearch()
    }
  }

  return (
    <div className={styles.root(theme)}>
      <div className={styles.textFieldContainer}>
        <InputBase
          className={styles.textfield(theme)}
          onKeyPress={handleOnKeyPress}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
        />
      </div>
      <ButtonBase onClick={onSearch} className={styles.button(theme)}>
        <SearchIcon />
      </ButtonBase>
    </div>
  )
}

export default SearchTextField
