import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"
import { DatesMatrixCellType, Highlight } from "./DatesMatrixCell"
import { colors } from "../../utils/constants/constants"

export default {
  root: (t: Theme, type: DatesMatrixCellType | undefined, highlight: Highlight | undefined) => {
    let backgroundColor = t.colors.background_primary
    let textColor = t.colors.text_primary
    let highlightColor = t.colors.text_primary
    let hoverBackground = colors.BLUE2
    let border = `1px solid ${t.colors.background_darker}`
    let cursor = "pointer"

    switch (type) {
      case "SELECTED":
        backgroundColor = t.colors.background_primary
        textColor = colors.BLUE
        highlightColor = colors.BLUE
        border = `1px double ${colors.BLUE} !important`
        break
      case "NO_RESULT":
        cursor = "default"
        hoverBackground = t.colors.background_primary
        break
    }

    switch (highlight) {
      case "MOST_EXPENSIVE":
        backgroundColor = colors.RED2
        textColor = colors.RED
        highlightColor = colors.RED
        break
      case "CHEAPEST":
        backgroundColor = colors.GREEN3
        textColor = colors.GREEN
        highlightColor = colors.GREEN
        break
    }

    return css({
      "> div": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        cursor: cursor
      },
      fontFamily: "Roboto",
      color: textColor,
      width: "120px",
      height: "44px",
      backgroundColor: backgroundColor,
      border: border,
      ":hover": {
        backgroundColor: hoverBackground,
        color: highlightColor
      }
    })
  },
  dataCellContainer: css({
    display: "flex"
  }),
  priceContainer: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    marginLeft: "8px"
  }),
  price: css({
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0.13px"
  }),
  days: css({
    fontSize: "10px",
    letterSpacing: "0.11px",
    fontWeight: 400,
    lineHeight: "14px"
  }),
  loading: (t: Theme) =>
    css({
      "&.MuiCircularProgress-root": {
        color: t.colors.brand_primary
      }
    })
}
