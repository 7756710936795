import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  root: css({
    "&.MuiTextField-root": {
      width: "100%"
    },
    "&.MuiTextField-root, >.MuiInputBase-root, >.MuiInputBase-root input": {
      cursor: "pointer"
    },
    ".MuiOutlinedInput-adornedStart": {
      paddingLeft: "8px"
    },
    ".MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px"
    },
    ".MuiInputAdornment-positionEnd": {
      marginLeft: 0
    }
  }),
  icon: (t: Theme) =>
    css({
      "&.MuiSvgIcon-root": {
        fontSize: "16px",
        color: t.colors.brand_primary
      }
    }),
  rightIcon: css({
    "&.MuiSvgIcon-root": {
      height: "24px",
      width: "24px"
    }
  }),
  field: css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: "8px"
  }),
  labelText: (t: Theme) =>
    css({
      color: `${t.colors.searchbox ? t.colors.searchbox.text : t.colors.text_primary} !important`
    }),
  toolbarTextField: css({
    "&.MuiFormControl-root": {
      width: "100%"
    }
  }),
  popover: css({
    ">.MuiPopover-paper": {
      padding: "16px"
    }
  }),
  title: css({
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "1.14",
    marginLeft: "16px"
  }),
  buttonContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginRight: "16px",
      ">.MuiButton-root": {
        fontSize: "14px",
        color: t.colors.brand_primary,
        alignSelf: "flex-end"
      }
    })
}
