import { css } from "@emotion/css"

export default {
  root: css({
    display: "flex",
    flexDirection: "column",
    gap: "24px"
  }),

  header: css({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "8px"
  }),

  airline: css({
    display: "flex",
    flexDirection: "row",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    gap: "8px",
    alignItems: "center"
  }),

  title: css({
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px"
  })
}
