import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  checkbox: css({
    "& .MuiCheckbox-root": {
      margin: "0 8px 0 0"
    }
  }),
  select: (t: Theme, fullWidth: boolean | undefined, selected?: boolean) =>
    css({
      "& .MuiSelect-root": {
        minWidth: fullWidth ? 0 : "192px",
        paddingTop: "11px",
        paddingBottom: "10px"
      },
      "&.MuiTextField-root": {
        ":hover .MuiInputLabel-shrink": {
          color: `${t.colors.brand_primary} !important`
        },
        " .MuiInputLabel-outlined": {
          transform: "translate(14px, 14px) scale(1)"
        },
        " .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)"
        },
        " .MuiInputLabel-root": {
          color: t.colors.text_variant,
          "&.Mui-focused": {
            color: `${t.colors.brand_primary} !important`
          },
          "&.Mui-error": {
            color: t.colors.error
          },
          "&[data-shrink='false']": {
            display: selected ? "none" : ""
          }
        },
        " .MuiOutlinedInput-root": {
          color: t.colors.text_primary,
          height: "40px",
          ":hover, &.Mui-focused": {
            " .MuiOutlinedInput-notchedOutline": {
              borderColor: t.colors.brand_primary
            }
          },
          "&.Mui-error": {
            borderColor: t.colors.error
          },
          borderColor: t.colors.text_variant
        }
      }
    }),
  autocomplete: (t: Theme, fullWidth: boolean | undefined) =>
    css({
      ".MuiFormControl-root": {
        ":hover .MuiInputLabel-shrink": {
          color: `${t.colors.brand_primary} !important`
        },
        " .MuiInputLabel-outlined": {
          transform: "translate(14px, 14px) scale(1)"
        },
        " .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)"
        },
        " .MuiInputLabel-root": {
          color: t.colors.text_variant,
          "&.Mui-focused": {
            color: `${t.colors.brand_primary} !important`
          },
          "&.Mui-error": {
            color: t.colors.error
          }
        },
        " .MuiInputBase-root": {
          minWidth: fullWidth ? 0 : "240px"
        },
        " .MuiOutlinedInput-root": {
          paddingTop: 0,
          color: t.colors.text_primary,
          height: "40px",
          ":hover, &.Mui-focused": {
            " .MuiOutlinedInput-notchedOutline": {
              borderColor: t.colors.brand_primary
            }
          },
          "&.Mui-error": {
            borderColor: t.colors.error
          },
          borderColor: t.colors.text_variant
        }
      }
    })
}
