import React from "react"
import styles from "./PriceboxDesktop.styles"
import { formatPrice, getLocale } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"

type Strikethrough = {
  total: number
  discount: number
}
export interface Props {
  fare: number
  taxes: number
  charges: number
  total: number
  currency: string
  provider: string
  strikethrough?: Strikethrough
  costEffectiveness?: number
}

const PriceboxDesktop: React.FC<Props> = ({
  provider,
  fare,
  taxes,
  charges,
  total,
  currency,
  strikethrough,
  costEffectiveness
}: Props) => {
  const { i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const priceToString = (value: number): string => {
    return priceFormated(value)
  }

  const priceFormated = (value: number) => {
    return formatPrice(value, locale, currency)
  }

  const priceboxRow = (label: string, value: string, highlighted: boolean) => (
    <div className={styles.rows(theme, highlighted)}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  )
  return (
    <div className={styles.root(theme)}>
      {strikethrough && (
        <div className={styles.OFF(theme)}>
          <span>{strikethrough.discount}% OFF</span>
        </div>
      )}

      <div className={styles.provider(theme)}>
        <div>
          <span className={"title"}>{provider}</span>
        </div>
      </div>

      <div className={styles.internalPrices}>
        {priceboxRow("Fare", priceToString(fare), false)}
        {priceboxRow("Taxes", priceToString(taxes), false)}
        {priceboxRow("Charges", priceToString(charges), false)}
      </div>

      <Divider className={styles.divisor} />

      {priceboxRow("Total", priceToString(total), true)}

      {strikethrough && <div className={styles.strikethrought}>{priceToString(strikethrough.total)}</div>}

      {costEffectiveness && (
        <div className={styles.costEffectiveness}>
          {priceboxRow("Cost effectiveness", priceToString(costEffectiveness), false)}
        </div>
      )}
    </div>
  )
}

export default PriceboxDesktop
