import React from "react"
import moment from "moment"
import Slider from "@material-ui/core/Slider"
import Fab from "@material-ui/core/Fab"
import { useTheme } from "@basset-la/themed-components"
import styles from "./TimePicker.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"

interface Props {
  title: string
  subtitle?: string
  from: Date
  to: Date
  onChange: (from: Date, to: Date) => void
  id?: string
}

const DATE_FORMAT = "HH:mm"
const MINUTES_IN_HOUR = 60
const MINUTES_IN_A_DAY = 24 * MINUTES_IN_HOUR
const STEP_SIZE = 100 / MINUTES_IN_A_DAY // step size is 1 minutes
const options: Option[] = [
  { value: "early_morning", hours: ["00:00", "05:00"] },
  { value: "morning", hours: ["05:00", "12:00"] },
  { value: "afternoon", hours: ["12:00", "19:00"] },
  { value: "night", hours: ["19:00", "23:59"] }
]

type Option = {
  value: string
  hours: string[]
}

const TimePicker: React.FC<Props> = ({ title, subtitle, from, to, onChange, id }) => {
  const mFrom = moment(from)
  const mTo = moment(to)
  const formattedFrom = mFrom.format(DATE_FORMAT)
  const formattedTo = mTo.format(DATE_FORMAT)
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleOnChange = (event: React.ChangeEvent<{}>, value: number[]) => {
    onChange(valueToDate(value[0]), valueToDate(value[1]))
  }

  const handleOnClick = (hours: string[]) => () => {
    const from = moment(hours[0], DATE_FORMAT)
    const to = moment(hours[1], DATE_FORMAT)
    onChange(from.toDate(), to.toDate())
  }

  return (
    <div className={styles.root(theme)}>
      <p className={styles.title}>{title}</p>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      <div className={styles.content}>
        <div className={styles.times}>
          <span>{formattedFrom}</span>
          <span>{formattedTo}</span>
        </div>
        <div id={id}>
          <Slider
            id="time-picker-slider"
            classes={{
              rail: styles.rail(theme),
              track: styles.track(theme),
              thumb: styles.thumb(theme)
            }}
            step={STEP_SIZE}
            value={[dateToValue(mFrom), dateToValue(mTo)]}
            onChange={handleOnChange}
          />
        </div>
        <div className={styles.buttons}>
          {options.map((o, i) => (
            <Fab
              key={i}
              disabled={formattedFrom === o.hours[0] && formattedTo === o.hours[1]}
              classes={{ root: styles.button(theme) }}
              variant={"extended"}
              onClick={handleOnClick(o.hours)}
            >
              <>{t(`TimePicker.${o.value}`)}</>
            </Fab>
          ))}
        </div>
      </div>
    </div>
  )
}

const valueToDate = (value: number): Date => {
  const totalMinutes = Math.round((value * MINUTES_IN_A_DAY) / 100)
  const hours = Math.floor(totalMinutes / MINUTES_IN_HOUR)
  const minutes = totalMinutes - hours * MINUTES_IN_HOUR
  const date = moment({ minutes: minutes, hours: hours })
  return date.toDate()
}

const dateToValue = (date: moment.Moment): number => {
  const minutes = date.get("hours") * 60 + date.get("minutes")
  const value = Math.round((minutes * 100) / MINUTES_IN_A_DAY)
  return value
}

export default TimePicker
