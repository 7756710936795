import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  list: css({
    listStyle: "none",
    margin: 0,
    marginTop: 8,
    padding: 0
  }),
  loadingContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }),
  progress: (theme: Theme) =>
    css({
      "&.MuiCircularProgress-root": {
        color: theme.colors.brand_primary
      }
    }),
  optionHeaderContainer: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  }),
  optionHeaderButton: css({
    maxWidth: "100px",
    ".MuiButton-root": {
      textTransform: "none",
      maxWidth: "100px"
    },
    ".MuiButton-text": {
      padding: 0,
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
      minWidth: "100px!important"
    },
    ".MuiTouchRipple-root": {
      display: "none"
    },
    ".MuiButton-root:hover": {
      backgroundColor: "transparent"
    }
  }),
  optionHeaderLeftButton: css({
    ".MuiButton-root": {
      justifyContent: "flex-start",
      alignItems: "center"
    }
  }),
  optionHeaderRightButton: css({
    ".MuiButton-root": {
      justifyContent: "flex-end",
      alignItems: "center"
    }
  }),
  option: (theme: Theme) =>
    css({
      "&.MuiListItem-root": {
        paddingRight: 10,
        paddingLeft: 10
      },
      ".MuiTouchRipple-root": {
        display: "none"
      },
      "&.MuiListItem-button:hover": {
        backgroundColor: "#D2EAE5"
      }
    }),
  optionItem: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Roboto"
  }),
  optionCheckbox: (isMobile: boolean) =>
    css({
      ".MuiSvgIcon-root": {
        height: isMobile ? undefined : "20px",
        width: isMobile ? undefined : "20px"
      }
    }),
  link: (theme: Theme) =>
    css({
      "&.MuiButton-root": {
        color: theme.colors.brand_primary,
        fontSize: "16px",
        lineHeight: "1.5",
        cursor: "pointer",
        textTransform: "none",
        fontWeight: "normal"
      }
    })
}
