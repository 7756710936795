import { css } from "@emotion/css"

const styles = {
  root: css({
    display: "flex",
    flexDirection: "column"
  }),

  main: (backgroundColor: string) =>
    css({
      display: "flex",
      justifyContent: "center",
      backgroundColor: backgroundColor
    }),

  contactContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    color: "#ffffff",
    width: "100%",
    maxWidth: "1344px",
    padding: "32px",
    "@media (max-width: 1024px)": {
      padding: "24px 16px"
    }
  }),

  linkContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    color: "#ffffff",
    width: "100%",
    maxWidth: "1344px",
    padding: "32px",
    "@media (max-width: 1024px)": {
      padding: "24px 16px"
    }
  }),

  columnContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    "@media (max-width: 1024px)": {
      width: "100%",
      alignItems: "center",
      padding: "16px 0"
    }
  }),

  header: css({
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    textTransform: "uppercase"
  }),

  content: css({
    fontSize: "16px",
    lineHeight: "24px",
    marginRight: "16px"
  }),

  socialContainer: css({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "136px"
  }),

  link: css({
    color: `#ffffff !important`
  })
}

export default styles
