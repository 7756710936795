import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: "4px"
  }),
  address: (color: string) =>
    css({
      marginLeft: "2px",
      fontFamily: "Roboto",
      fontSize: "14px",
      color: color
    }),

  link: css({
    fontFamily: "Roboto",
    fontSize: "14px",
    marginLeft: "8px"
  }),

  buttonLink: (theme: Theme) =>
    css({
      background: "none !important",
      border: "none",
      cursor: "pointer",
      color: theme.brand_colors.click_color,
      padding: 0,
      marginLeft: "8px"
    })
}
