import React from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import PersonIcon from "@material-ui/icons/Person"
import StandardBedIcon from "../../icons/StandardBedIcon"
import TwinBedIcon from "../../icons/TwinBedIcon"
import { I18N_NS } from "../../utils/constants"
import styles from "./RoomDescription.styles"

interface Props extends WithTranslation {
  amount: number
  name: string
  beds: {
    type: string
    size: string
    count: number
  }[]
  capacity: number
}

const RoomDescription = ({ amount, name, beds, capacity, t }: Props) => {
  return (
    <div className={styles.containerStyle}>
      <p id="room-description-type" className={styles.titleStyle}>{`${amount} x ${name}`}</p>
      <div className={styles.bedContainerStyle}>
        {beds.map((e, i) => (
          <div key={i} className={styles.bedStyle}>
            {Array.apply(null, Array(e.count)).map((b: any, i: number) =>
              e.size === "Twin" ? (
                <TwinBedIcon className={styles.bedIcon} />
              ) : (
                <StandardBedIcon className={styles.bedIcon} />
              )
            )}
            <p>{`${t(`RoomDescription.bedTypes.${e.type}`, { count: e.count })}`}</p>
          </div>
        ))}
      </div>
      <div className={styles.capacityStyle}>
        <span>{capacity}</span>
        <PersonIcon style={{ width: "16px", height: "16px" }} />
        <span className={styles.roomAmountStyle}>{t("RoomDescription.for_room", { count: amount })}</span>
      </div>
    </div>
  )
}

export default withTranslation(I18N_NS)(RoomDescription)
