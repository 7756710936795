import React from "react"

import { MobileDialogParams, TextFieldParams } from "@basset-la/components-commons/dist/components/Autocomplete"
import Text from "@basset-la/components-commons/dist/components/Text"
import FullScreenDialog from "@basset-la/components-products/dist/components/FullScreenDialog"
import ProductAutocomplete, {
  AutocompleteItem,
  ProductAutocompleteProps
} from "@basset-la/components-products/dist/components/ProductAutocomplete"
import SearchboxTextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import TextField from "@basset-la/components-products/dist/components/SearchboxTextField"
import { useTheme } from "@basset-la/themed-components"
import MyLocationIcon from "@material-ui/icons/MyLocation"

import styles from "./AirportAutocompleteField.styles"

export interface Props extends Pick<ProductAutocompleteProps, "getRegions" | "onSelectRegion"> {
  errorMessage?: string
  label: string
  placeholder?: string
  icon: React.ReactNode
  region: AutocompleteItem | null
  id?: string
}

const AirportAutocompleteField: React.FC<Props> = ({
  label,
  placeholder,
  getRegions,
  errorMessage,
  icon,
  onSelectRegion,
  region,
  id
}) => {
  const theme = useTheme()

  const handleRenderInput = (params: TextFieldParams) => {
    return (
      <div className={styles.field}>
        <Text className={styles.labelText(theme)} variant="regular" size={14}>
          {label}
        </Text>
        <TextField
          {...params}
          id={id}
          className={styles.input}
          leftIcon={icon}
          placeholder={placeholder}
          error={!!errorMessage}
          helperText={errorMessage}
        />
      </div>
    )
  }

  const handleRenderMobileDialog = (params: MobileDialogParams) => {
    return (
      <FullScreenDialog
        open={params.open}
        onClose={params.onClose}
        toolbarComponent={
          <SearchboxTextField
            className={styles.toolbarTextField}
            value={params.inputValue}
            onChange={params.onInputChange}
            leftIcon={<MyLocationIcon className={styles.locationIcon(theme)} />}
          />
        }
      >
        {params.children}
      </FullScreenDialog>
    )
  }

  return (
    <ProductAutocomplete
      region={region ? region : undefined}
      renderInput={handleRenderInput}
      getRegions={getRegions}
      onSelectRegion={onSelectRegion}
      renderMobileDialog={handleRenderMobileDialog}
    />
  )
}

export default AirportAutocompleteField
