import { Theme } from "@basset-la/themed-components"
import { css } from "@emotion/css"

export default {
  taxDisclamerStyle: css({
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    color: "#9b9b9b"
  }),
  priceStyle: css({
    fontSize: "18px",
    lineHeight: "1.33",
    letterSpacing: "1px",
    color: "#000",
    " > span": {
      fontWeight: "500"
    }
  }),
  priceContainerStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "16px",
    alignItems: "center"
  }),
  rateStyle: css({
    border: "1px solid #eaeaea",
    borderRadius: "3px",
    padding: "16px 8px"
  }),
  ratesContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    " > div": {
      marginBottom: "8px"
    },
    " > div:last-child": {
      marginBottom: "0px"
    }
  }),
  roomsContainerStyle: css({
    padding: "8px",
    " > div": {
      marginBottom: "16px"
    },
    " > div:last-child": {
      marginBottom: "0px"
    }
  }),
  roomClusterStyle: css({
    padding: "8px",
    borderRadius: "3px",
    backgroundColor: "#fff",
    marginBottom: "8px",
    "&:last-child": {
      marginBottom: 0
    }
  }),
  roomClustersContainerStyle: css({
    display: "flex",
    flexDirection: "column",
    marginTop: "8px"
  }),
  rateInformationStyle: css({
    fontSize: "14px",
    fontWeight: "300",
    lineHeight: "1.71",
    color: "#4a4a4a",
    margin: "0 8px"
  }),
  titleStyle: css({
    fontWeight: "300",
    fontSize: "18px",
    lineHeight: "1.33",
    color: "#4a4a4a",
    margin: "0 8px"
  }),
  containerStyle: css({
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    backgroundColor: "#eaeaea"
  }),
  highlightedStyle: (theme: Theme) =>
    css({
      boxShadow: "0 4px 4px 0 rgba(74, 144, 226, 0.24), 0 0 4px 0 rgba(74, 144, 226, 0.12)",
      border: `solid 2px ${theme.brand_colors.selection_color}`
    }),
  buttonStyle: (theme: Theme) =>
    css({
      color: "#fff !important",
      backgroundColor: `${theme.brand_colors.click_color} !important`,
      width: "112px !important",
      height: "32px !important",
      textTransform: "capitalize",
      fontWeight: "300 !important",
      fontSize: "18px !important",
      padding: "0px !important",
      margin: "0px !important",
      minHeight: "0px !important",
      minWidth: "0px !important"
    })
}
