import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (theme: Theme) =>
    css({
      zIndex: 100,
      width: "44px",
      height: "44px",
      padding: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      position: "absolute",
      transform: "translate(-50%, -50%)",
      borderRadius: "50%",
      boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)",
      backgroundColor: theme.colors.background_primary,
      " > img": {
        width: "40px",
        height: "40px",
        borderRadius: "50%"
      }
    }),
  popoverStyle: (theme: Theme) =>
    css({
      color: theme.colors.text_primary,
      fontWeight: 300,
      textAlign: "center",
      fontSize: "12px",
      padding: "8px"
    })
}
