import React from "react"

export interface ItemForTitleProps {
  title: string
  value: string
}

const ItemForTitle: React.FunctionComponent<ItemForTitleProps> = ({ title, value }) => (
  <>
    <span>{title}</span>
    <span>{value}</span>
  </>
)

export default ItemForTitle
