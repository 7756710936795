import React from "react"
import { css } from "@emotion/css"
import { I18N_NS } from "../../utils/constants/constants"
import { AirplaneIcon } from "../../icons/AirplaneIcon"
import { useTheme, Theme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"

interface Props {
  origin: string
  destination: string
}

const VoucherSegmentTitle: React.FC<Props> = ({ origin, destination }) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  return (
    <div className={styles.container(theme)}>
      <span>
        <AirplaneIcon color={theme.colors.brand_darker} /> {t("VoucherSegmentTitle.title")}
      </span>
      <span>
        {origin} - {destination}
      </span>
    </div>
  )
}

const styles = {
  container: (theme: Theme) =>
    css({
      display: "flex",
      flexFLow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 300,
      lineHeight: 1.14,
      color: theme.colors.brand_darker,
      backgroundColor: theme.colors.background_variant,
      padding: "8px 16px"
    })
}

export default VoucherSegmentTitle
