import { css } from "@emotion/css"

export default {
  titleStyle: css({
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#000",
    marginTop: 0
  }),
  containerStyle: css({
    display: "flex",
    flexDirection: "column",
    " > *": {
      marginBottom: "8px"
    },
    " > *:last-child": {
      marginBottom: "8px"
    }
  }),
  roomAmountStyle: css({
    borderRadius: "3px",
    fontWeight: "300",
    backgroundColor: "#f0f0f0",
    padding: "1px 8px",
    marginLeft: "8px"
  }),
  capacityStyle: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: 0,
    marginBottom: 0,
    color: "#4a4a4a"
  }),
  personStyle: css({
    color: "#4a4a4a !important",
    height: "16px",
    width: "16px"
  }),
  bedStyle: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "8px",
    "&:last-child": {
      marginBottom: 0
    },
    " > p": {
      fontSize: "14px",
      lineHeight: "1.14",
      color: "#4a4a4a",
      fontWeight: "300",
      marginTop: 0,
      marginBottom: 0
    }
  }),
  bedIcon: css({
    marginRight: "8px"
  }),
  bedContainerStyle: css({
    display: "flex",
    flexDirection: "column"
  })
}
