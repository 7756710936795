import React from "react"
import { Airport } from "../../model/types"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants/constants"
import styles from "./AirportFilter.styles"
import CheckboxFilter from "@basset-la/components-products/dist/components/CheckboxFilter"
import Divider from "@material-ui/core/Divider"
import FlightsFiltersCollapsePanel from "../FlightsFiltersCollapsePanel"

export interface Props {
  collapsible?: boolean
  outboundAirports: Airports
  inboundAirports?: Airports
  onChange: (value: string, checked: boolean, filterType: FilterType) => void
}

export type FilterType = "OutboundFrom" | "OutboundTo" | "InboundFrom" | "InboundTo"

export type Option = {
  airport: Airport
  selected?: boolean
}

type Airports = {
  from: Option[]
  to: Option[]
}

const AirportFilter: React.FC<Props> = ({ outboundAirports, inboundAirports, collapsible, onChange }) => {
  const { t } = useTranslation(I18N_NS)

  const outboundFrom = mapToCheckboxOptions(outboundAirports.from)
  const outboundTo = mapToCheckboxOptions(outboundAirports.to)
  const inboundFrom = inboundAirports ? mapToCheckboxOptions(inboundAirports.from) : []
  const inboundTo = inboundAirports ? mapToCheckboxOptions(inboundAirports.to) : []

  const handleOnChange = (filterType: FilterType) => (value: string, checked: boolean) => {
    onChange(value, checked, filterType)
  }

  const outboundComponent = (
    <div className={styles.section(collapsible || false)}>
      <CheckboxFilter
        title={!collapsible ? t("AirportFilter.departureAiports") : ""}
        subTitle={t("AirportFilter.iWantToDepartureFrom")}
        options={outboundFrom}
        maxItems={outboundFrom.length}
        onChange={handleOnChange("OutboundFrom")}
        id={{
          checkbox: "airport-outbound-from-filter-checkbox"
        }}
      />
      <Divider />
      <CheckboxFilter
        subTitle={t("AirportFilter.iWantToArrivaTo")}
        options={outboundTo}
        maxItems={outboundTo.length}
        onChange={handleOnChange("OutboundTo")}
        id={{
          checkbox: "airport-outbound-to-filter-checkbox"
        }}
      />
    </div>
  )

  const inboundComponent = (
    <>
      {inboundAirports && (
        <div className={styles.section(collapsible || false)}>
          <CheckboxFilter
            title={!collapsible ? t("AirportFilter.returningAirports") : ""}
            subTitle={t("AirportFilter.iWantToDepartureFrom")}
            options={inboundFrom}
            maxItems={inboundFrom.length}
            onChange={handleOnChange("InboundFrom")}
            id={{
              checkbox: "airport-inbound-from-filter-checkbox"
            }}
          />
          <Divider />
          <CheckboxFilter
            subTitle={t("AirportFilter.iWantToArrivaTo")}
            options={inboundTo}
            maxItems={inboundTo.length}
            onChange={handleOnChange("InboundTo")}
            id={{
              checkbox: "airport-inbound-to-filter-checkbox"
            }}
          />
        </div>
      )}
    </>
  )

  return (
    <>
      {!collapsible ? (
        <div className={styles.root}>
          {outboundComponent}
          {inboundAirports && (
            <>
              <Divider />
              {inboundComponent}
            </>
          )}
        </div>
      ) : (
        <>
          <FlightsFiltersCollapsePanel
            renderSummary={<>{t("AirportFilter.departureAiports")}</>}
            renderDetails={outboundComponent}
          />
          <Divider />
          <FlightsFiltersCollapsePanel
            renderSummary={<>{t("AirportFilter.returningAirports")}</>}
            renderDetails={inboundComponent}
          />
        </>
      )}
    </>
  )
}

const mapToCheckboxOptions = (options: Option[]) => {
  return options.map((e, i) => ({
    title: e.airport.name,
    value: e.airport.code,
    selected: e.selected
  }))
}

export default AirportFilter
