import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {
  color: string
}

const CheckoutBaggageIcon: React.FunctionComponent<Props> = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      fillOpacity=".400"
      fillRule="nonzero"
      fill={color}
      d="M2 5h11a2 2 0 0 1 2 2c.003 4.61.003 6.944 0 7-.003.06-2.549.063-7.638.01a1 1 0 0 0-1.01 1V23H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2z"
    />
    <path
      fill={color}
      d="M5.25 23.25H1.5c-.827 0-1.5-.673-1.5-1.5V6c0-.827.673-1.5 1.5-1.5h12c.827 0 1.5.673 1.5 1.5v4.5h-.75V6a.75.75 0 0 0-.75-.75h-12A.75.75 0 0 0 .75 6v15.75c0 .414.336.75.75.75h3.75v.75z"
    />
    <path
      fill={color}
      d="M1.125 24h.75v-1.125h-.75zM5.625 7.5h.75v-.75h-.75zM7.125 7.5h.75v-.75h-.75zM8.625 7.5h.75v-.75h-.75zM9.75 4.875h.75v-3h-.75zM4.5 4.875h.75v-3H4.5z"
    />
    <path
      fill={color}
      d="M5.25 1.5h4.5V.75h-4.5v.75zm-.75.75h6V0h-6v2.25zM7.125 14.25a.376.376 0 0 0-.375.375v8.25c0 .207.168.375.375.375h12.75a.376.376 0 0 0 .375-.375v-8.25a.376.376 0 0 0-.375-.375H7.125zM19.875 24H7.125C6.505 24 6 23.495 6 22.875v-8.25c0-.62.505-1.125 1.125-1.125h12.75c.62 0 1.125.505 1.125 1.125v8.25c0 .62-.505 1.125-1.125 1.125z"
    />
    <path
      fill={color}
      d="M16.125 13.875h-.75v-1.5A.376.376 0 0 0 15 12h-3a.376.376 0 0 0-.375.375v1.5h-.75v-1.5c0-.62.505-1.125 1.125-1.125h3c.62 0 1.125.505 1.125 1.125v1.5zM20.625 16.125H19.5c-.62 0-1.125-.505-1.125-1.125v-1.125h.75V15c0 .207.168.375.375.375h1.125v.75zM7.5 16.125H6.375v-.75H7.5A.376.376 0 0 0 7.875 15v-1.125h.75V15c0 .62-.505 1.125-1.125 1.125M19.125 23.625h-.75V22.5c0-.62.505-1.125 1.125-1.125h1.125v.75H19.5a.376.376 0 0 0-.375.375v1.125zM8.625 23.625h-.75V22.5a.376.376 0 0 0-.375-.375H6.375v-.75H7.5c.62 0 1.125.505 1.125 1.125v1.125zM9.375 22.125h8.25v-.75h-8.25zM12.75 10.5H12V9c0-1.24 1.01-2.25 2.25-2.25h.375v.75h-.375c-.827 0-1.5.673-1.5 1.5v1.5zM.75 21H.375v-.75H.75c.827 0 1.5-.673 1.5-1.5V9c0-.827-.673-1.5-1.5-1.5H.375v-.75H.75C1.99 6.75 3 7.76 3 9v9.75C3 19.99 1.99 21 .75 21"
    />
  </svg>
)

export default CheckoutBaggageIcon
