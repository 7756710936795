import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  root: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "column",
      borderRadius: "3px",
      padding: "16px",
      gap: "8px",
      backgroundColor: `${t.colors.brand_primary}10`
    }),

  titleContainer: (t: Theme) =>
    css({
      display: "flex",
      flexDirection: "row",
      borderRadius: "3px",
      gap: "8px",
      "> *": {
        color: `${t.colors.brand_primary} !important`
      }
    })
}
