import React, { useState } from "react"
import { SearchboxAmenities } from "../Searchbox/types"
import { useTheme } from "@basset-la/themed-components"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import styles from "./AmenitiesPicker.styles"
import Button from "@material-ui/core/Button"
import cloneDeep from "lodash/cloneDeep"

export interface Props {
  amenities: SearchboxAmenities
  onChange: (a: SearchboxAmenities) => void
  onDone: (a: SearchboxAmenities) => void
}

const AmenitiesPicker: React.FC<Props> = ({ amenities, onChange, onDone }) => {
  const [currentAmenities, setCurrentAmenities] = useState<SearchboxAmenities>(amenities)
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const handleOnChange = (key: keyof SearchboxAmenities) => (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const copy = cloneDeep(currentAmenities)
    copy[key] = checked
    setCurrentAmenities(copy)
    onChange(copy)
  }

  const handleDone = () => {
    onDone(currentAmenities)
  }

  return (
    <div className={styles.root(theme)}>
      <span className={styles.title}>{t("AmenitiesPicker.title")}</span>
      <div className={styles.checkboxContainer(theme)}>
        <FormControlLabel
          control={<Checkbox checked={!!currentAmenities.parking} onChange={handleOnChange("parking")} />}
          label={t("AmenitiesPicker.parking")}
        />
        <FormControlLabel
          control={<Checkbox checked={!!currentAmenities.wifi} onChange={handleOnChange("wifi")} />}
          label={t("AmenitiesPicker.wifi")}
        />
        <FormControlLabel
          control={<Checkbox checked={!!currentAmenities.swimmingPool} onChange={handleOnChange("swimmingPool")} />}
          label={t("AmenitiesPicker.swimmingPool")}
        />
        <FormControlLabel
          control={<Checkbox checked={!!currentAmenities.allDayCheckin} onChange={handleOnChange("allDayCheckin")} />}
          label={t("AmenitiesPicker.allDayCheckin")}
        />
      </div>
      <div className={styles.buttonContainer(theme)}>
        <Button onClick={handleDone} variant="text">
          {t("AmenitiesPicker.done")}
        </Button>
      </div>
    </div>
  )
}

export default AmenitiesPicker
