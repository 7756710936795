import { useTheme } from "@basset-la/themed-components"
import React from "react"
import { SegmentOption } from "../../model"
import { Price } from "../../model/types"
import { formatDuration, getBaggageConfiguration } from "../../utils/helpers"
import BaggagesWithTooltip from "../BaggagesWithTooltip/BaggagesWithTooltip"
import SegmentOptionBrief from "../SegmentOptionBrief/SegmentOptionBrief"
import styles from "./DesktopSegmentOption.styles"
import InfoIconWithPolicyTooltip from "../InfoIconWithPolicyTooltip/InfoIconWithPolicyTooltip"
import LastPlaces from "../LastPlaces"
import CabinType from "../CabinType"

export interface Props {
  segmentOption: SegmentOption
  price: Price
}

const DesktopSegmentOptionSummary: React.FC<Props> = ({ segmentOption, price }) => {
  const theme = useTheme()

  const duration = formatDuration(segmentOption.duration)
  const lastPlaces = Math.min(...segmentOption.legs.map(l => l.cabin_type.quantity))

  const { bagIncluded, baggagesAmount, baggagesWeight, carryOnIncluded } = getBaggageConfiguration(
    segmentOption.baggage_allowances
  )

  const hasPolicyInfo =
    (price.adults && price.adults.exchange_policy) ||
    (price.infants && price.infants.exchange_policy) ||
    (price.children && price.children.exchange_policy) ||
    (price.seniors && price.seniors.exchange_policy) ||
    (price.disabled && price.disabled.exchange_policy)

  return (
    <div className={styles.root(theme)}>
      <div className={styles.brief}>
        <SegmentOptionBrief showAirlineCode segmentOption={segmentOption} />
      </div>
      <div className={styles.otherInfo}>
        <div>
          <p id="segment-option-summary-total-hours-container" className={styles.duration}>
            {duration}
          </p>
        </div>
        <div>
          {segmentOption.legs.map((l, i) => (
            <CabinType key={`cabin${i}`} variant="tooltip" cabin={l.cabin_type} />
          ))}
        </div>
        <div>
          <BaggagesWithTooltip
            bagIncluded={bagIncluded}
            baggageAmount={baggagesAmount}
            carryOnIncluded={carryOnIncluded}
            baggageWeight={baggagesWeight}
          />
        </div>
        <div className={styles.infoContainer}>
          <LastPlaces variant="tooltip" places={lastPlaces} />
        </div>
        <div className={styles.infoContainer}>{hasPolicyInfo && <InfoIconWithPolicyTooltip price={price} />}</div>
      </div>
    </div>
  )
}

export default DesktopSegmentOptionSummary
